import React, { useState } from "react";

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faImage, faArrowsAlt} from '@fortawesome/free-solid-svg-icons';

import { 
    Button,
    Card,
    CardImg,
    CardTitle,
  } from 'reactstrap';

import { templates} from "../webstory-config";

const defaultAlertState = {status:false, msg:null,color:'success'};

const Reorder = ({pagesArr, close, finish, getDefaultWidget, changePage, pageNo, pageTemplate, showAuthor, webstoryArticle, defaultFont}) => {

    const [reorderPages, setReorderPages] = useState(pagesArr);
    const [isTouched, setIsTouched] = useState(false);

    
    const onDragEnd = async (e) => {
        if(!isTouched) {
            setIsTouched(true);
        }

        console.log(e)
        const source = e.source.index;
        const destination = e.destination.index;
        console.log(source);
        console.log(destination);
        const tempArr = [...reorderPages];
        let temp = tempArr[source];
        tempArr[source] = tempArr[destination];
        tempArr[destination] = temp;
        console.log(tempArr);
        setReorderPages(tempArr);
        
    }
    // const assignNewOrder = (obj, index) => {
    //     obj.order = index;
    //     return obj;
    // }

    const finishReorder = async() => {
        let newOrderedList = reorderPages.map(e=>e.order);
        finish(newOrderedList, reorderPages);
    }
    const getSmallCardFor = (reorderPage, index, reorderIcon=true) => {
        return (
                <div className="small-preview-card" 
                style={(index==pageNo-1)?{opacity:1}:null}
                onClick={()=>{changePage(index+1)}}>
                    {/* <style>
                        {`\
                            .small-preview-card .ql-editor p{\
                            font-family:${reorderPage.font? reorderPage.font : defaultFont};
                            }\
                        `}
                    </style> */}
                    {reorderIcon?<FontAwesomeIcon className="reorder-icon" icon={faArrowsAlt} />:null}
                    <div  
                    className='w-100 h-100'
                    >
                        <div  
                    className="position-relative h-100" 
                    style={reorderPage.background_color?{background:reorderPage.background_color, filter:`brightness(${reorderPage.background_options?reorderPage.background_options.brightness:1}) contrast(${reorderPage.background_options?reorderPage.background_options.contrast:1})`}:null} 
                    >
                            {reorderPage.background_image? 
                            <CardImg
                                    alt="Card image cap"
                                    src={reorderPage.background_image}
                                    top
                                    height="100%"
                                    width="100%"
                                    style={{objectFit:'cover', borderRadius:2, filter:`brightness(${reorderPage.background_options?reorderPage.background_options.brightness:1}) contrast(${reorderPage.background_options?reorderPage.background_options.contrast:1})`}}
                            />:null}
                            {reorderPage.background_image_by_url? 
                            <CardImg
                                    alt="Card image cap"
                                    src={reorderPage.background_image_by_url}
                                    top
                                    height="100%"
                                    width="100%"
                                    style={{objectFit:'cover', borderRadius:2, filter:`brightness(${reorderPage.background_options?reorderPage.background_options.brightness:1}) contrast(${reorderPage.background_options?reorderPage.background_options.contrast:1})`}}
                            />:null}
                            {reorderPage.background_video? 
                            <video key={reorderPage.background_video} 
                            style={{objectFit:'cover', height:'100%', width:'100%', borderRadius:2, filter:`brightness(${reorderPage.background_options?reorderPage.background_options.brightness:1}) contrast(${reorderPage.background_options?reorderPage.background_options.contrast:1})`}} 
                            className='videoTag'>
                            <source src={reorderPage.background_video} type='video/mp4' />
                            </video>:null}
                            {reorderPage.blockquote? <div className="embed-container">
                                {getDefaultWidget('Twitter')}
                            </div>:null}
                            {(!reorderPage.imageId && !reorderPage.background_color && !reorderPage.background_video)? 
                            <div className="no_image_available">
                                <FontAwesomeIcon  className="m-1" style={{cursor:'pointer',fontSize: 43}} icon={faImage} />
                                <CardTitle tag='h6' style={{marginTop:9}}>No Image Available</CardTitle>
                            </div>:null}
                            
                            {(index===0 && showAuthor)?<div className="webstory-author-name">
                                by {webstoryArticle?.author}
                            </div>:null}
                        </div>
                        <div className="story-content-container" style={templates[pageTemplate]?.css.containerCss}>
                            <div className='ql-snow' style={templates[pageTemplate]?.css.qlSnowCorrectionCss? templates[pageTemplate]?.css.qlSnowCorrectionCss:{}}>
                                <div  style={{fontFamily:reorderPage.font?reorderPage.font:defaultFont, ...(reorderPage.order>1 ? templates[pageTemplate]?.css.descriptionCss:templates[pageTemplate]?.css.headlinesCss) }}
                                        dangerouslySetInnerHTML={{__html:(reorderPage.description || reorderPage.headlines)? (reorderPage.order>1? reorderPage.description : reorderPage.headlines):'' }}
                                        className="ql-editor">
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
           
        );
    }

    return (
        <div style={{position:'relative'}}>
            
            <DragDropContext onDragEnd={onDragEnd} >
                
                <Droppable  droppableId="droppable" direction="horizontal" >
                {(provided, snapshot) => (
                    <div
                    ref={provided.innerRef}
                    // style={getListStyle(snapshot.isDraggingOver)}
                    {...provided.droppableProps}
                    className="d-flex small-preview-card-container "
                    // style={{background:'white', boxShadow:'1px 1px 1px gray', border:'1px solid gray', borderRadius:'8px 8px 0 0'}}

                    >
                    <div className="m-1">
                        {getSmallCardFor(pagesArr[0], 0, false)}
                    </div> 
                    {reorderPages.map((reorderPage, index) => {
                        if(index>0){
                             return (
                                <Draggable key={reorderPage._id} 
                                    draggableId={reorderPage._id} 
                                    index={index}
                                >
                                {(provided, snapshot) => (
                                    <div
                                    ref={provided.innerRef} 
                                    className=" m-1"
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    // style={getItemStyle(
                                    //   snapshot.isDragging,
                                    //   provided.draggableProps.style
                                    // )}
                                    >
                                      { getSmallCardFor(reorderPage, index)}
                                        </div>
                                )}
                                </Draggable>
                            )}
                       }              
                    )}
                    {provided.placeholder}
                    </div>
                )}
                </Droppable>
            </DragDropContext>
            <Button className="btn btn-success m-0" style={{"borderRadius":"0.4vw","position":"absolute","bottom":"14px","width":"fit-content","right":"50px","fontSize":"0.8vw","padding":"0.6vw 1.2vw"}}   onClick={finishReorder} disabled={!isTouched}>Finish</Button> 
            
        </div>
    )
}


export default Reorder;
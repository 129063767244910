import React,{useState,useEffect} from 'react'
import { Table,Spinner,Card, CardBody } from 'reactstrap'
import allListingService from '../../services/api/Jwplayer/allListing-service'

import moment from 'moment';
const unit= ["KB","MB","GB","TB","PB"];
let i=0;
const JwplayerUsage = ({projectId}) => {
const [usageData,setusageData] = useState([])
const [showLoader,setShowLoader] = useState(false);


const [height, setHeight] = useState(0);
    useEffect(()=>{
        (async()=>{
          setHeight(window.innerHeight);
          setShowLoader(true);
          const currentDate = moment(new Date()).format("YYYY-MM");
          const lastYearDate = moment(currentDate).subtract(1, "years").format("YYYY-MM");
            const {data} = await allListingService.jwplayerUsage(currentDate,lastYearDate, projectId)
            const val = data?.data?.data?.metadata?.months;

           
            setusageData(val);
            setShowLoader(false)
        })()
    },[])


 

    const convert = (data)=>{
     
      if(data<1000){
        let s = i;
        i=0;
        return ((data.toFixed(2).toString())+ " " + unit[s-1]);
      }
      
      
       i++;
        return convert(data/1000);
      
    
      
    }

  return (
    <>
     {/* <Card className="m-4 myCard" style={{ height: height + "px" }}> */}
    
     <div className="content be-jwCont">
      <div className="container">
        <div className='row'>
          <div className="col-md-12">
            <div className="dp-box videoContnr">
              <div className="card">
                <div className="card-body">
                  <div className="tBdata-list mt-30">
                    <Table responsive >
                      <thead>
                          <th title='Last 1 year record' className='tdCenter'>Summary Date</th>
                          <th className='tdCenter'>Max Stored</th>
                          <th className='tdCenter'>Vod Delivered</th>
                          <th className='tdCenter'>Live Minutes Delivered</th>
                          <th className='tdCenter'>Total Plays</th>
                          <th className='tdCenter'>AD Impressions</th>
                      </thead>
                      
                      {showLoader&&
                      <tbody>
                        <tr>
                        <td colSpan="6" className="text-center">
                            <Spinner animation="border" role="status">
                            
                            </Spinner>
                        </td>
                        </tr>

                      </tbody>
                    
                      }
                      
                      <tbody>

                          {usageData && usageData!=[]?
                          usageData.map((e,index)=>{
                            

                            if((e.bytes_stored!==0 && e.bytes_streamed===0) || (e.bytes_stored===0 && e.bytes_streamed!==0) || (e.bytes_stored!==0 && e.bytes_streamed!==0)){
                              return <tr key={index}>
                                
                              <td className='tdCenter'>{ moment(e.date).format('MMMM YYYY')}</td>
                            {(()=>{
                              if(e.bytes_stored==0){
                              return <td className='tdCenter'>{e.bytes_stored} KB</td>
                              }
                              else{
                              return  <td className='tdCenter'>{convert(e.bytes_stored)}</td>
                              }
                            })()}
                            
                            {(()=>{
                              if(e.bytes_streamed==0){
                              return <td className='tdCenter'>{e.bytes_streamed} KB</td>
                              }
                              else{
                              return  <td className='tdCenter'>{convert(e.bytes_streamed)}</td>
                              }
                            })()}

                
                
                  <td className='tdCenter'>{e.live_seconds_delivered}</td>
                  
                  <td className='tdCenter'> {e.plays}</td>
                  <td className='tdCenter'>{e.ad_impressions}</td>
                  
              </tr>
                }
              
                  
              }):""
              }
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
      
     
    </>
  )
}

export default JwplayerUsage

import axios from 'axios';

const getProjectFeaturesInfo = async (projectId) => {
    let formdata ={
        projectId
    }
    const response = await axios.post('/api/features/get-project-features',formdata);
    return response.data;
}
const updateProjectSettingFeature = async (projectId, feature_code, feature_value) => {
    const formdata = {
        projectId ,
        feature_code ,
        feature_value
    }
    const response = await axios.post('/api/features/update-project-setting-feature',formdata)
    return response.data;
} 
const getFeatureDependentFieldSchema = async (feature_code, project_id) => {
    let formdata ={
        feature_code
    }
    const response = await axios.post('/api/features/get-dependent-fields-schema',formdata);
    return response.data;
}
const saveFeatureDependentFieldConfig = async (project_id, field_path, form_details) => {
    let formdata ={
        project_id,
        field_path,
        form_details
    }
    const response = await axios.post('/api/features/save-dependent-fields',formdata);
    return response.data;
}
const getFeatureStatus = async (featureCode, projectId) => {
    let formdata = {
        featureCode,
        projectId
    }
    const response = await axios.post('/api/features/get-feature-status',formdata);
    return response.data;
}

export default {
    getProjectFeaturesInfo,
    updateProjectSettingFeature,
    getFeatureDependentFieldSchema,
    saveFeatureDependentFieldConfig,
    getFeatureStatus
}
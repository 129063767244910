import React,{useState,useEffect,useContext} from 'react';
import planService from '../../services/api/plan-service';
import ReactBSAlert from "react-bootstrap-sweetalert";
import SubscriptionService from '../../services/api/plan-service'
import {useHistory} from 'react-router-dom';
import moment from 'moment';
import ProjectContext from "../../ProjectContext";
import SubscriptionTransactions from './SubscriptionTransactions';
import {
    Label,
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Container,
    Row,
    Badge,
    Col,
    Spinner,
    Form,
    FormGroup,   
} from "reactstrap";
import Moment from "react-moment";
import SubscriptionUnpaid from './SubscriptionUnpaid';
const SubscriptionDetails = (props)=>{ 

    const history = new useHistory();
    const projectDetails = useContext(ProjectContext);
    if (projectDetails.id == "") {
        history.push("/project/allprojects");
    }
    let getProjectSlug = new URLSearchParams(props.location.search).get(
        "project"
    );
    if (!getProjectSlug) {
        history.push({
            search: "?project=" + projectDetails.slug,
        });
    }
    const projectId = projectDetails.id;
    const [showPopup, setShowPopup] = useState({alert: null});
    const [activeSubscription,setActiveSubscription] = useState(null);
    
    // let subscriptionStarted = moment(new Date(props.activeSubscirption.start_at*1000)) <moment() ? true : false;
    
    
    useEffect(() => {
        (async () => {
            const response = await planService.getActiveSubscription({projectId});
            console.log("Subscription response ", response);
            if(response.status && response.data){
                setActiveSubscription(response.data);
            }
        })()
        

    },[])
    
    const cancelSubscription = async() =>{
        setShowPopup({
            alert: (
              <ReactBSAlert
              info
                style={{ display: "block", marginTop: "-100px" }}
                title="Please wait"
                showCancel={false}
                showConfirm={false}
                confirmBtnBsStyle="info"
                confirmBtnText="Ok"
              >
                <Spinner
                    animation="border"
                    role="status">
                    <span className="sr-only">
                        Loading...
                    </span>
                </Spinner>
              </ReactBSAlert>
            )
          })

        const response = await SubscriptionService.cancelSubscription(props.activeSubscirption.pg_subscription_id);
        console.log(response.data);
        if(response.data && response.data.isCancelled){
            setShowPopup({
                alert: (
                  <ReactBSAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Success"
                    onConfirm={() =>hideAlert()}
                    confirmBtnBsStyle="info"
                    confirmBtnText="Ok"
                  >
                    Subscription Cancelled.
                  </ReactBSAlert>
                )
              })
        }else{
            setShowPopup({
                alert: (
                  <ReactBSAlert
                    danger
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Failed"
                    onConfirm={() =>hideAlert()}
                    confirmBtnBsStyle="info"
                    confirmBtnText="Ok"
                  >
                    Failed to cancel subscription, Try again.
                  </ReactBSAlert>
                )
              })
        }
        setTimeout(() => {
            hideAlert();
            history.go(0)
        }, 3000); 
    }

    const hideAlert = () =>{
        setShowPopup({
            alert: null
        })
    }

    const handleCancelSubscription = ()=>{
        setShowPopup({
            alert: (
              <ReactBSAlert
                info
                style={{ display: "block", marginTop: "-100px" }}
                title="Are you sure"
                onConfirm={() => cancelSubscription()}
                onCancel={() => hideAlert()}
                confirmBtnBsStyle="warning"
                btnSize=""
                showCancel={true}
                confirmBtnText="Yes"
                cancelBtnBsStyle="info"
                cancelBtnText="NO"
              >
                Do you want to cancel 
              </ReactBSAlert>
            )
          })
    }
    
    const getColorAsPerStatus =  () => {
        // let status  = props.activeSubscirption.status;
        // let warningStatus = ["pending","paused"];
        // let successStatus = ["created","activated","authenticated","charged","completed","updated"];
        // let dangerStatus = ["cancelled","halted"]
        // if(successStatus.includes(status)) {
        //     return "success";
        // }
        // if(warningStatus.includes(status)) {
        //     return "warning"
        // }
        // if(dangerStatus.includes(status)) {
        //     return "danger"
        // }
        
    }

    return <div className='content mt-30'>
        {showPopup.alert}
        <Container>
                {activeSubscription ? <SubscriptionUnpaid project = {projectDetails} activeSubscription= {activeSubscription} /> :''}
                {activeSubscription ?<SubscriptionTransactions subscriptionId={activeSubscription.pg_subscription_id}/>:''}
                <Row>
                    
                    {!activeSubscription ? 
                                <td colspan="12" className="text-center">
                            <CardTitle tag="h4" >No Subscription Found</CardTitle>
                        </td>:""}
                </Row>
            </Container>

            <div
                className="full-page-background"
                style={{
                    backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
                }}
            />
        
    </div>
}

export default SubscriptionDetails;
import React, { useState, useEffect, useContext } from 'react'
import { Card, CardBody, Table, CardHeader, CardTitle, Button, Spinner, Row, Col, Form, Input } from 'reactstrap'
import ctvAppService from '../../services/api/ctv-app-service';
import customStyles from '../../components/CustomStyles';
import Select from 'react-select'
import ProjectContext from '../../ProjectContext';
import { useHistory } from 'react-router-dom';
import Pagination from "react-js-pagination";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit, faTrashRestoreAlt, faFilm } from '@fortawesome/free-solid-svg-icons';
import moment from "moment";
import ReactTooltip from 'react-tooltip';

function ImportVideosDashboard() {

    const projectDetails = useContext(ProjectContext);
    const history = useHistory();
    if (projectDetails.id == '') {
        history.push("/project/allprojects");
    }
    const projectId = projectDetails.id;


    const [formdetails, setFormDetails] = useState({ name: "", category: "", status: "", project_id: projectId, video_source: "" })
    const [searchDetails, setSearchDetails] = useState({ name: "", category: "", status: "", project_id: projectId, video_source: "" })
    const [categoriesList, setCategoriesList] = useState([])
    const [videos, setVideos] = useState([])
    const [totalVideos, setTotalVideos] = useState(0)
    const [renderComponent, setRenderComponent] = useState(false)
    const [activePage, setActivePage] = useState(1);
    const records = 10;
    const statusOptions = [
        { value: "", label: "All" },
        { value: "1", label: "Completed" },
        { value: '0', label: "Processing" },
        { value: "-1", label: "Failed" }
    ]
    const sourceOptions = [
        { value: "youtube_channel", label: "Youtube Channel" },
        { value: "youtube_playlist", label: "Youtube Playlist" }
    ]

    useEffect(() => {
        (async () => {
            let data = await ctvAppService.getVideoCategories({ projectId, status: 1 });
            if (data.status && data.data) {
                let array = Object.keys(data.data.catList).map(key => ({
                    label: data.data.catList[key].name,
                    value: data.data.catList[key]._id
                }));

                array.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));

                setCategoriesList(array);
            }
        })()
    }, [])
    useEffect(() => {
        (async () => {
            let videosList = await ctvAppService.getCtvImportedVideos({ ...formdetails, activePage, records })
            if (videosList.status && videosList.data) {
                console.log("videosList", videosList.data.videoList);
                setVideos(videosList.data.videoList);
                setTotalVideos(videosList.data.count)
            }
            setRenderComponent(true)
        })();
    }, [activePage, searchDetails])


    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
    }
    const handleNameChange = (event) => {
        setFormDetails({
            ...formdetails,
            name: event.target.value
        })
    }
    const handleCategoriesChange = (selectedOptions) => {
        if (selectedOptions) {
            const categoryArray = selectedOptions.map(option => option.value)
            setFormDetails({ ...formdetails, category: categoryArray });
        }
        else {
            setFormDetails({
                ...formdetails, category: ""
            })
        }

    };

    const handleSubmit = function (e) { searchVideos(); e.preventDefault() }

    const searchVideos = () => {
        setActivePage(1);
        setSearchDetails(formdetails)


    }
    const updateImporter = (data) => {
        history.push('/ctv/edit-import-video?import=' + data.slug);
    }
    const resetFilters = () => {
        console.log("in reset ");
        setFormDetails({
            ...formdetails,
            name: "",
            category: "",
            status: "",
            video_source: ""
        })
        setSearchDetails({
            ...formdetails,
            name: "",
            category: "",
            status: "",
            video_source: ""
        })
        setActivePage(1)

    }
    let videoElements = [];
    let firstRecord = 0;
    let lastRecord = 0;
    if (!videos || !videos.length) {
        if (!renderComponent) {
            videoElements.push(
                <tr>
                    <td className="text-center">
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </td>
                </tr>
            )
        }
        else {
            console.log("no videos found")
            videoElements.push(
                <td colSpan="6" className="text-center">
                    <CardTitle tag="h4" className="boRslt">No Videos Importer Found</CardTitle>
                </td>
            )
        }

    }
    else {
        let i = 1;
        videos.forEach(function (videoDetails) {
            // console.log(videoDetails);
            let className = ""
            if (videoDetails.status === -1) {
                className = "table-danger"
            }
            if (i === 1) {
                firstRecord = (activePage - 1) * records + i;
            }
            const createdAtTime = moment(videoDetails.createdAt);
            videoElements.push(
                <tr className={className}>
                    <td className="text-left">{firstRecord - 1 + i}</td>
                    <td className="text-left">{videoDetails.name}</td>
                    <td className="text-left">{sourceOptions.map(option => {
                        if (option.value === videoDetails.video_source) {
                            return option.label;
                        }
                    })}</td>
                    <td className="text-left">{videoDetails.categories.map(data => (
                        <>
                            {data.name}<br />
                        </>


                    ))}</td>
                    <td className="text-left">{JSON.stringify(videoDetails.data)}</td>
                    <td className="text-left">
                        {createdAtTime.format('h:mm:ss a')}<br />
                        {createdAtTime.format('Do MMM YYYY')}

                    </td>
                    <td className="text-left">{
                        videoDetails.status === 1 ?
                            <Row>
                                <Col>
                                    <Button type="button" className="btn-smb btn-round btn-secondary" onClick={(event) => updateImporter(videoDetails)}>Import</Button>
                                </Col>
                            </Row>
                            :
                            videoDetails.status === -1 ?
                                <Button type="button" className="btn-smb btn-round btn-secondary" onClick={(event) => updateImporter(videoDetails)}>
                                    Failed</Button>

                                : <>In Progress</>
                    }
                    </td>
                    <td>
                        <FontAwesomeIcon icon={faFilm} onClick={(event) => history.push('/ctv/importer-logs?importer-id=' + videoDetails._id)} style={{ cursor: "pointer" }} data-tip="Importer Logs" />
                        <ReactTooltip effect="solid" />
                    </td>
                </tr>
            )
            if (i === videos.length) {
                lastRecord = (activePage - 1) * records + i;
            }
            i++;
            if (i === videos.length) {
                lastRecord = (activePage - 1) * records + i;
            }
        })
    }

    return (
        <div className='container mt-30'>
            {/* <Card> */}
            <CardHeader>
                <CardTitle tag="h4">
                    Videos Importer
                </CardTitle>
                <Form className="form" method="POST" onSubmit={handleSubmit}>
                    <Row>
                        <Col md="3">
                            <Input
                                type="text"
                                name="searchname"
                                value={formdetails.name}
                                style={{ width: '100%', height: "75%", marginBottom: "20px", fontWeight: "bold", borderRadius: '8px' }}
                                id="searchname"
                                onChange={handleNameChange}
                                placeholder="Search in Name" />

                        </Col>
                        <Col md="3">
                            <Select
                                className="react-select primary"
                                value={sourceOptions.filter(option => option.value == formdetails.video_source)}
                                onChange={(e) => setFormDetails({ ...formdetails, video_source: e.value })}
                                placeholder="Select video source"
                                options={sourceOptions}
                                styles={customStyles}
                            />
                        </Col>
                        <Col md="3">
                            <Select
                                className="react-select primary"
                                value={categoriesList.filter(option => formdetails.category.includes(option.value))}
                                onChange={handleCategoriesChange}
                                placeholder="Select a category"
                                options={categoriesList}
                                styles={customStyles}
                                isMulti
                                closeMenuOnSelect={false}
                            />
                        </Col>
                        <Col md="3">
                            <Select
                                className="react-select primary"
                                value={statusOptions.filter(option => option.value == formdetails.status)}
                                onChange={(e) => setFormDetails({ ...formdetails, status: e.value })}
                                placeholder="Select Status"
                                options={statusOptions}
                                styles={customStyles}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <div style={{ float: "left" }}>
                                <Button
                                    className="btn-round"
                                    color="info"
                                    type="submit"
                                    onClick={() => searchVideos()}
                                >
                                    Search
                                </Button>
                                <Button
                                    className="btn-round"
                                    color="warning"
                                    onClick={() => resetFilters()}
                                >
                                    Reset
                                </Button>
                            </div>
                        </Col>
                        <Col md="6">
                            <div style={{ float: "right", marginTop: "5px" }}>
                                <Button
                                    className="btn"
                                    color="danger"
                                    style={{ height: "78%", marginTop: "-4px" }}
                                    onClick={(event) => history.push('/ctv/import-video')}
                                >
                                    + Import New Videos
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
                <hr></hr>

            </CardHeader>
            <CardBody>
                {totalVideos > 0 ?
                    <Row>
                        <Col>
                            <div className='pagNo'>
                                {firstRecord} - {lastRecord} of {totalVideos}
                            </div>
                        </Col>
                        <Col>
                            <div className="pagBox">
                                <div className='pagDt'>
                                    <Pagination
                                        activePage={activePage}
                                        itemsCountPerPage={records}
                                        totalItemsCount={totalVideos}
                                        pageRangeDisplayed={3}
                                        onChange={handlePageChange.bind(this)}
                                        itemClass="page-item"
                                        linkClass="page-link"
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                : null}
                <Row>
                    <Col>
                        <Table responsive hover>
                            <thead className="text-primary">
                                <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>Videos Source</th>
                                    <th>Categories</th>
                                    <th>Source Data</th>
                                    <th>Created On</th>
                                    <th>Action</th>
                                    <th >View Logs</th>
                                </tr>
                            </thead>
                            <tbody>
                                {videoElements}
                            </tbody>
                        </Table>
                    </Col>
                </Row>


            </CardBody>
            {/* </Card> */}
        </div>
    )
}

export default ImportVideosDashboard
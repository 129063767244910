import React, { useEffect, useState, useContext, useRef } from "react";
import projectsService from "../../services/api/projects-service";
import { useHistory } from "react-router-dom";
import ProjectContext from "../../ProjectContext";
import ReactHtmlParser from 'react-html-parser';
import TimezoneSelect from "react-timezone-select";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Switch from "react-bootstrap-switch";
import './settings.css';
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Input,
    Alert,
    Spinner,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col,
} from "reactstrap";

import Select from "react-select";

var i18n = {
    "Pacific/Midway": "Midway Island, Samoa",
    "Pacific/Honolulu": "Hawaii",
    "America/Juneau": "Alaska",
    "America/Dawson": "Pacific Time (US and Canada); Tijuana",
    "America/Boise": "Mountain Time (US and Canada)",
    "America/Chihuahua": "Chihuahua, La Paz, Mazatlan",
    "America/Phoenix": "Arizona",
    "America/Chicago": "Central Time (US and Canada)",
    "America/Regina": "Saskatchewan",
    "America/Mexico_City": "Guadalajara, Mexico City, Monterrey",
    "America/Belize": "Central America",
    "America/Detroit": "Eastern Time (US and Canada)",
    "America/Indiana/Indianapolis": "Indiana (East)",
    "America/Bogota": "Bogota, Lima, Quito",
    "America/Glace_Bay": "Atlantic Time (Canada)",
    "America/Caracas": "Caracas, La Paz",
    "America/Santiago": "Santiago",
    "America/St_Johns": "Newfoundland and Labrador",
    "America/Sao_Paulo": "Brasilia",
    "America/Argentina/Buenos_Aires": "Buenos Aires, Georgetown",
    "America/Godthab": "Greenland",
    "Etc/GMT+2": "Mid-Atlantic",
    "Atlantic/Azores": "Azores",
    "Atlantic/Cape_Verde": "Cape Verde Islands",
    GMT: "Dublin, Edinburgh, Lisbon, London",
    "Africa/Casablanca": "Casablanca, Monrovia",
    "Atlantic/Canary": "Canary Islands",
    "Europe/Belgrade": "Belgrade, Bratislava, Budapest, Ljubljana, Prague",
    "Europe/Sarajevo": "Sarajevo, Skopje, Warsaw, Zagreb",
    "Europe/Brussels": "Brussels, Copenhagen, Madrid, Paris",
    "Europe/Amsterdam": "Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
    "Africa/Algiers": "West Central Africa",
    "Europe/Bucharest": "Bucharest",
    "Africa/Cairo": "Cairo",
    "Europe/Helsinki": "Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius",
    "Europe/Athens": "Athens, Istanbul, Minsk",
    "Asia/Jerusalem": "Jerusalem",
    "Africa/Harare": "Harare, Pretoria",
    "Europe/Moscow": "Moscow, St. Petersburg, Volgograd",
    "Asia/Kuwait": "Kuwait, Riyadh",
    "Africa/Nairobi": "Nairobi",
    "Asia/Baghdad": "Baghdad",
    "Asia/Tehran": "Tehran",
    "Asia/Dubai": "Abu Dhabi, Muscat",
    "Asia/Baku": "Baku, Tbilisi, Yerevan",
    "Asia/Kabul": "Kabul",
    "Asia/Yekaterinburg": "Ekaterinburg",
    "Asia/Karachi": "Islamabad, Karachi, Tashkent",
    "Asia/Kolkata": "Chennai, Kolkata, Mumbai, New Delhi",
    "Asia/Kathmandu": "Kathmandu",
    "Asia/Dhaka": "Astana, Dhaka",
    "Asia/Colombo": "Sri Jayawardenepura",
    "Asia/Almaty": "Almaty, Novosibirsk",
    "Asia/Rangoon": "Yangon Rangoon",
    "Asia/Bangkok": "Bangkok, Hanoi, Jakarta",
    "Asia/Krasnoyarsk": "Krasnoyarsk",
    "Asia/Shanghai": "Beijing, Chongqing, Hong Kong SAR, Urumqi",
    "Asia/Kuala_Lumpur": "Kuala Lumpur, Singapore",
    "Asia/Taipei": "Taipei",
    "Australia/Perth": "Perth",
    "Asia/Irkutsk": "Irkutsk, Ulaanbaatar",
    "Asia/Seoul": "Seoul",
    "Asia/Tokyo": "Osaka, Sapporo, Tokyo",
    "Asia/Yakutsk": "Yakutsk",
    "Australia/Darwin": "Darwin",
    "Australia/Adelaide": "Adelaide",
    "Australia/Sydney": "Canberra, Melbourne, Sydney",
    "Australia/Brisbane": "Brisbane",
    "Australia/Hobart": "Hobart",
    "Asia/Vladivostok": "Vladivostok",
    "Pacific/Guam": "Guam, Port Moresby",
    "Asia/Magadan": "Magadan, Solomon Islands, New Caledonia",
    "Pacific/Fiji": "Fiji Islands, Kamchatka, Marshall Islands",
    "Pacific/Auckland": "Auckland, Wellington",
    "Pacific/Tongatapu": "Nuku'alofa",
};

const ProjectSettings = (props) => {
    //const projectId = "607567e2065dff6366ce76be";//TODO Project Id
    const myref=useRef();
    const projectDetails = useContext(ProjectContext);
    //console.log(projectDetails);
    const history = new useHistory();
    if (projectDetails.id == "") {
        history.push("/project/allprojects");
    }

    const projectId = projectDetails.id;
    const [formdetails, setFormDetails] = useState({
        projectid: projectId,
        templateid: "",
        locale: "",
        timezone: "",
        enable_category_in_article : false,
        enable_category_alias_url : false,
        self_canonical: false,

        shorts_category_id: "",
    });
    const [showLoader, setShowLoader] = useState(false);
    const [showSaveButton, setShowSaveButton] = useState(true);
    const [alert, setAlert] = useState({ show: false, message: "", type: "" });
    const [templates, setTemplates] = useState(false);
    const [accesskey, setAccessKey] = useState(false);
    const [secretkey, setSecretKey] = useState(false);
    const [defaultTemplateId, setDefaultTemplateId] = useState(false);
    const [selectedTimezone, setSelectedTimezone] = useState("");
    const [licenseDetails,setLicenseDetails]  = useState("")
    const [copied,setCopied]  = useState(false)
    const [copiedMessageState,setCopiedMessageState]=useState(false);
    const [showSettingsLoader, setShowSettingsLoader] = useState(false);
    const [showSettingsSaveButton, setShowSettingsSaveButton] = useState(true);
    const [settingsAlert, setSettingsAlert] = useState({ show: false, message: "", type: "" });
    
    const [copyState, setCopyState] = useState({
        accessKeyCopied: false,
        secretKeyCopied: false,
    });
    let getProjectSlug = new URLSearchParams(props.location.search).get(
        "project"
    );
    if (!getProjectSlug) {
        history.push({
            search: "?project=" + projectDetails.slug,
        });
    }

    useEffect(() => {
        (async () => {
            let templateData = await projectsService.getProjectTemplates(projectId);
            if (templateData.status) {
                let templateNames = templateData.data;
                let templateValues = [];
                Object.keys(templateNames).forEach(function (key) {

                    // console.log("temp llog",templateNames[key])
                    
                    templateValues.push({
                        value: key,
                        label: templateNames[key].name,
                        description: templateNames[key].description,
                        displayImages: templateNames[key].default_images
                    });

                });

                // console.log("templateValues",templateValues)

                setTemplates(templateValues);
            }

            let getProjectDetails = await projectsService.getProjectDetails(
                projectId
            );
            if (getProjectDetails.status) {
                // console.log("Project details:",getProjectDetails);
                // setAccessKey(getProjectDetails.data.access_key);
                // setSecretKey(getProjectDetails.data.secret_key);
               // setLicenseDetails(JSON.stringify(`{"secret_key":"${getProjectDetails.data.secret_key}","access_key":"${getProjectDetails.data.access_key}"}`));
               console.log(JSON.stringify({project_name:getProjectDetails.data.name,project_id:getProjectDetails.data.slug,host:getProjectDetails.data.host,secret_key:getProjectDetails.data.secret_key,access_key:getProjectDetails.data.access_key},undefined,4));
                setLicenseDetails(JSON.stringify({project_name:getProjectDetails.data.name,project_id:getProjectDetails.data.slug,host:getProjectDetails.data.host,secret_key:getProjectDetails.data.secret_key,access_key:getProjectDetails.data.access_key},undefined,4));
         
                if (getProjectDetails.data.settings.template_id) {
                    setDefaultTemplateId(
                        getProjectDetails.data.settings.template_id
                    );
                    setFormDetails({
                        ...formdetails,
                        templateid: getProjectDetails.data.settings.template_id,
                        timezone: getProjectDetails.data.timezone,
                        locale: getProjectDetails.data.locale,
                        enable_category_alias_url: getProjectDetails.data.settings.enable_category_alias_url,
                        enable_category_in_article: getProjectDetails.data.settings.enable_category_in_article,
                        self_canonical: getProjectDetails.data.settings.self_canonical,
                        shorts_category_id : getProjectDetails.data.settings.shorts_category_id,

                    });
                    setSelectedTimezone({
                        value: getProjectDetails.data.timezone,
                        label: i18n[getProjectDetails.data.timezone]
                            ? i18n[getProjectDetails.data.timezone]
                            : "",
                    });
                    console.log(formdetails);
                } else {
                    setFormDetails({
                        ...formdetails,
                        timezone: getProjectDetails.data.timezone,
                        locale: getProjectDetails.data.locale,
                    });
                    setSelectedTimezone({
                        value: getProjectDetails.data.timezone,
                        label: i18n[getProjectDetails.data.timezone]
                            ? i18n[getProjectDetails.data.timezone]
                            : "",
                    });
                }
            }
        })();
    }, []);

    console.log(formdetails);
    var languageOptions = [
        { value: "af", label: "Afrikaans" },
        { value: "sq", label: "Albanian" },
        { value: "am", label: "Amharic" },
        { value: "ar", label: "Arabic" },
        { value: "hy", label: "Armenian" },
        { value: "az", label: "Azerbaijani" },
        { value: "eu", label: "Basque" },
        { value: "be", label: "Belarusian" },
        { value: "bn", label: "Bengali" },
        { value: "bs", label: "Bosnian" },
        { value: "bg", label: "Bulgarian" },
        { value: "ca", label: "Catalan" },
        { value: "ceb", label: "Cebuano" },
        { value: "ny", label: "Chichewa" },
        { value: "zh", label: "Chinese (Simplified)" },
        { value: "zh-TW", label: "Chinese (Traditional)" },
        { value: "co", label: "Corsican" },
        { value: "hr", label: "Croatian" },
        { value: "cs", label: "Czech" },
        { value: "da", label: "Danish" },
        { value: "nl", label: "Dutch" },
        { value: "en", label: "English" },
        { value: "eo", label: "Esperanto" },
        { value: "et", label: "Estonian" },
        { value: "tl", label: "Filipino" },
        { value: "fi", label: "Finnish" },
        { value: "fr", label: "French" },
        { value: "fy", label: "Frisian" },
        { value: "gl", label: "Galician" },
        { value: "ka", label: "Georgian" },
        { value: "de", label: "German" },
        { value: "el", label: "Greek" },
        { value: "gu", label: "Gujarati" },
        { value: "ht", label: "Haitian Creole" },
        { value: "ha", label: "Hausa" },
        { value: "haw", label: "Hawaiian" },
        { value: "iw", label: "Hebrew" },
        { value: "hi", label: "Hindi" },
        { value: "hmn", label: "Hmong" },
        { value: "hu", label: "Hungarian" },
        { value: "is", label: "Icelandic" },
        { value: "ig", label: "Igbo" },
        { value: "id", label: "Indonesian" },
        { value: "ga", label: "Irish" },
        { value: "it", label: "Italian" },
        { value: "ja", label: "Japanese" },
        { value: "jw", label: "Javanese" },
        { value: "kn", label: "Kannada" },
        { value: "kk", label: "Kazakh" },
        { value: "km", label: "Khmer" },
        { value: "ko", label: "Korean" },
        { value: "ku", label: "Kurdish (Kurmanji)" },
        { value: "ky", label: "Kyrgyz" },
        { value: "lo", label: "Lao" },
        { value: "la", label: "Latin" },
        { value: "lv", label: "Latvian" },
        { value: "lt", label: "Lithuanian" },
        { value: "lb", label: "Luxembourgish" },
        { value: "mk", label: "Macedonian" },
        { value: "mg", label: "Malagasy" },
        { value: "ms", label: "Malay" },
        { value: "ml", label: "Malayalam" },
        { value: "mt", label: "Maltese" },
        { value: "mi", label: "Maori" },
        { value: "mr", label: "Marathi" },
        { value: "mn", label: "Mongolian" },
        { value: "my", label: "Myanmar (Burmese)" },
        { value: "ne", label: "Nepali" },
        { value: "no", label: "Norwegian" },
        { value: "ps", label: "Pashto" },
        { value: "fa", label: "Persian" },
        { value: "pl", label: "Polish" },
        { value: "pt", label: "Portuguese" },
        { value: "pa", label: "Punjabi" },
        { value: "ro", label: "Romanian" },
        { value: "ru", label: "Russian" },
        { value: "sm", label: "Samoan" },
        { value: "gd", label: "Scots Gaelic" },
        { value: "sr", label: "Serbian" },
        { value: "st", label: "Sesotho" },
        { value: "sn", label: "Shona" },
        { value: "sd", label: "Sindhi" },
        { value: "si", label: "Sinhala" },
        { value: "sk", label: "Slovak" },
        { value: "sl", label: "Slovenian" },
        { value: "so", label: "Somali" },
        { value: "es", label: "Spanish" },
        { value: "su", label: "Sundanese" },
        { value: "sw", label: "Swahili" },
        { value: "sv", label: "Swedish" },
        { value: "tg", label: "Tajik" },
        { value: "ta", label: "Tamil" },
        { value: "te", label: "Telugu" },
        { value: "th", label: "Thai" },
        { value: "tr", label: "Turkish" },
        { value: "uk", label: "Ukrainian" },
        { value: "ur", label: "Urdu" },
        { value: "uz", label: "Uzbek" },
        { value: "vi", label: "Vietnamese" },
        { value: "cy", label: "Welsh" },
        { value: "xh", label: "Xhosa" },
        { value: "yi", label: "Yiddish" },
        { value: "yo", label: "Yoruba" },
        { value: "zu", label: "Zulu" }
        ];

    const changeTimeZone = (event) => {
        setFormDetails({
            ...formdetails,
            timezone: event.value,
        });
        setSelectedTimezone(event);
    };

    const getLocaleValue = () => {
        let localeValue = languageOptions[0];
        if (formdetails.locale) {
            languageOptions.forEach(function (ldetails) {
                if (ldetails.value == formdetails.locale) {
                    localeValue = ldetails;
                }
            });
        }
        return localeValue;
    };

    const handleEnumChange = (value) => {
        // setFormDetails({
        //   ...formdetails,
        //   locale: value,
        // });
    };

    const handleSwitchChange = (fieldname,fieldvalue) => {
        setFormDetails({
          ...formdetails,
          [fieldname]: fieldvalue,
        });
    }

    const changeLocale = (event) => {
        setFormDetails({
            ...formdetails,
            locale: event.value,
        });
    };

    const handleInputChange = (event,fieldname) => {
        setFormDetails({
            ...formdetails,
            [fieldname]: event.target.value,
        })
    }
    const changeTemplate = (event) => {
        setFormDetails({
            ...formdetails,
            templateid: event.target.value,
        });
        console.log(formdetails.templateid);
    };

    const handleSubmit = function (e) {
        validateForm();
        e.preventDefault();
    };

    const saveProjectSettings = (e) => {
        validateProjectSettings();
        e.preventDefault();
    }

    const validateProjectSettings = async() => {
        let validations = true;
        if(validations){
            setShowSettingsLoader(true);
            setShowSettingsSaveButton(false);

            let saveProjectSettings = await projectsService.saveProjectSettings(formdetails);
            if(projectDetails.isContractExpired){
                setShowSettingsLoader(false);
                setShowSettingsSaveButton(true);
                projectDetails.setContractExpiredAlert(projectDetails);
            }
            else if(saveProjectSettings.status){
                setTimeout(()=>{
                    setShowSettingsLoader(false)
                    setShowSettingsSaveButton(true);
                    setSettingsAlert({show:true,type:'success',message:'Project settings were saved successfully'});
                },1000);
                
            }else{
                setTimeout(()=>{
                    setShowSettingsLoader(false)
                    setShowSettingsSaveButton(true)
                    setSettingsAlert({show:true,type:'danger',message:'Project settings could not be saved, Please try again.'});
                },1000);
            }

            setTimeout(()=>{
                setSettingsAlert({show:false,type:'',message:''});
            },3000);

            // setTimeout(()=>{
            //   history.push("/project/projects");
            // },2000);
        }
    }

    const validateForm = async () => {
        let validations = true;
        if (validations) {
            let saveProjectSettings = await projectsService.saveProjectSettings(
                formdetails
            );
            setShowLoader(true);
            setShowSaveButton(false);
            if(projectDetails.isContractExpired){
                setShowLoader(false);
                setShowSaveButton(true);
                projectDetails.setContractExpiredAlert(projectDetails);
            }
            else if (saveProjectSettings.status) {
                setTimeout(() => {
                    setShowLoader(false);
                    setShowSaveButton(true);
                    setAlert({
                        show: true,
                        type: "success",
                        message: "Project settings were saved successfully",
                    });

                }, 1000);
 
            } else {
                setTimeout(() => {
                    setShowLoader(false);
                    setShowSaveButton(true);
                    setAlert({
                        show: true,
                        type: "danger",
                        message:
                            "Project settings could not be saved, Please try again.",
                    });
                }, 1000);
            }

            setTimeout(() => {
                setAlert({ show: false, type: "", message: "" });
                // myref.current.scrollIntoView();
                // history.push("/project/settings");
                window.location.href="/project/settings";
            }, 3000);
        }
    };

    let templateElements = [];
    if (templates) {
        let i = 0;
        templates.forEach(function (templateDetails) {
            i++;
            if (
                
                templateDetails.value == defaultTemplateId
            ) {
                // console.log("Templates",templateDetails.label.default_images.home);
                templateElements.push(
                    <div style={{"height":"480px"}}  className={formdetails.templateid===templateDetails.value?"form-check-radio themCard active activatedTheme":"form-check-radio themCard  activatedTheme"}>
                        
                        <Label check>
                            <Input
                                 defaultChecked
                                value={templateDetails.value}
                                id="templateid"
                                name="templateid"
                                type="radio"
                                onChange={changeTemplate}
                            />
                            <span className="acThm">Active</span>
                            {templateDetails.label}{" "}
                    
                            <span className="form-check-sign" />
                            <p >
                            {templateDetails.description? ReactHtmlParser(templateDetails.description):""}    
                            </p>

                            {templateDetails.displayImages?
                            <>
                                <div className="themImg">
                                    <img src={templateDetails.displayImages.home} />
                                    <img src={templateDetails.displayImages.category} />
                                    <img src={templateDetails.displayImages.article} />
                                </div>
                            </>
                            :""}
                        
                        </Label>
                        
                    </div>
                );
            } else {
                templateElements.push(
                    <div  style={{"height":"480px"}} className={formdetails.templateid===templateDetails.value?"form-check-radio themCard ":"form-check-radio themCard"}>
                        <Label check>
                            <Input
                                value={templateDetails.value}
                                id="templateid"
                                name="templateid"
                                type="radio"
                                onChange={changeTemplate}
                            />
                            {/* <span className="acThm"></span> */}
                            {templateDetails.label}{" "}
                            
                            <span className="form-check-sign" />

                            <p >
                            {templateDetails.description?ReactHtmlParser(templateDetails.description):""}   
                            </p>
                            {templateDetails.displayImages?
                            <>
                            <div className="themImg">
                                    <img src={templateDetails.displayImages.home} />
                                    <img src={templateDetails.displayImages.category} />
                                    <img src={templateDetails.displayImages.article} />
                                </div>  
                            </>
                            :""}
                        </Label>
                    </div>
                );
            }
        });
    }

    return (
        <div className="content mt-30">
            <Container>
                <Row>
                    <Col className="ml-auto mr-auto" md="12">
                        <Card className="card-signup text-center dp-box themPge">
                            <CardHeader>
                                <CardTitle tag="h4"  className="heding_tab_h4">Project Settings</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Form
                                    className="form-horizontol form"
                                    action=""
                                    id="createProject"
                                    method="POST"
                                    onSubmit={handleSubmit}
                                >
                                  
                                        <h4 ref={myref}  className="themh4">Choose your template theme</h4>

                                        <div className="themeBox">
                                            <FormGroup>
                                                {templateElements}
                                            </FormGroup>
                                        </div>


                                        <div className="consFom">
                                            <Label className="lblNme">Locale</Label>
                                            <div className="inptFom">
                                                <FormGroup>
                                                    <Select
                                                        className="react-select primary"
                                                        classNamePrefix="react-select"
                                                        name="locale"
                                                        value={getLocaleValue()}
                                                        onChange={changeLocale}
                                                        options={languageOptions}
                                                    />
                                                </FormGroup>
                                            </div>
                                        </div>
                                        <div className="consFom">
                                            <Label className="lblNme">Timezone</Label>
                                            <div className="inptFom">
                                                <FormGroup>
                                                    <TimezoneSelect
                                                        name="timezone"
                                                        value={selectedTimezone}
                                                        onChange={changeTimeZone}
                                                    />
                                                </FormGroup>
                                            </div>
                                        </div>
                                    

                                    {/* <Row>
                                        <Label sm="2">Access Key</Label>
                                        <Col sm="7">
                                            <FormGroup>
                                                <Col>
                                                    <CopyToClipboard
                                                        text={accesskey}
                                                        onCopy={() =>
                                                            setCopyState({
                                                                accessKeyCopied: true,
                                                            })
                                                        }
                                                    >
                                                        <div>
                                                            <Row>
                                                                <Col sm="6">
                                                                    <Label>
                                                                        {
                                                                            accesskey
                                                                        }
                                                                    </Label>
                                                                </Col>
                                                                <Col sm="1">
                                                                    <i class="nc-icon nc-single-copy-04"></i>
                                                                </Col>
                                                                <Col sm="3">
                                                                    {copyState.accessKeyCopied
                                                                        ? "Copied"
                                                                        : ""}
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </CopyToClipboard>
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>
 */}
                                    {/* <Row>
                                        <Label sm="2">Secret Key</Label>
                                        <Col sm="7">
                                            <FormGroup>
                                                <Col>
                                                    <CopyToClipboard
                                                        text={secretkey}
                                                        onCopy={() =>
                                                            setCopyState({
                                                                secretKeyCopied: true,
                                                            })
                                                        }
                                                    >
                                                        <div>
                                                            <Row>
                                                                <Col sm="6">
                                                                    
                                                                    <Label>
                                                                        {
                                                                            secretkey
                                                                        }
                                                                    </Label>
                                                                </Col>
                                                                <Col sm="1">
                                                                    <i class="nc-icon nc-single-copy-04"></i>
                                                                </Col>
                                                                <Col sm="3">
                                                                    {copyState.secretKeyCopied
                                                                        ? "Copied"
                                                                        : ""}
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </CopyToClipboard>
                                                </Col>

                                                <Alert color="info">
                                                    Use Access Key and Secret
                                                    key for configuring
                                                    WordPress.
                                                </Alert>
                                            </FormGroup>
                                        </Col>
                                    </Row> */}
                                     
                                    <div className="consFom fulconsFom">
                                        <Label className="lblNme">Credentials</Label>
                                        <div className="inptFom">
                                            <FormGroup>
                                            {/* <textarea rows="7" cols="55" readonly="readonly">
                                            {licenseDetails}
                                            </textarea> */}
                                            {/* <Input type="textarea" value={licenseDetails}  rows="7" readonly="readonly"/> */}
                                            <textarea rows="7"  className="primary" style={{border:"groove",overflow:"hidden",resize:"none"}} value={licenseDetails} readOnly />
                                            
                                        
                                            <div className="copyBtn">
                                                    <CopyToClipboard
                                                        text={licenseDetails}
                                                        onCopy={() =>
                                                            {setCopied(true);setCopiedMessageState(true);
                                                            setTimeout(()=>{setCopiedMessageState(false)},5000);
                                                            }}>
                                                     <Button   
                                                     className="copyTxt"
                                                     type="button">
                                                     Copy Credentials
                                                     </Button> 
                                                     </CopyToClipboard>
                                                        <span>
                                                          {copiedMessageState
                                                                        ? <span style={{color: 'red'}}>Copied.</span>
                                                                        : ""}
                                                        </span>
                                                    
                                            </div>
                                            </FormGroup>
                                        </div>
                                    </div>
                                    <div className="dflLft">
                                    <Alert color="info">
                                                    Use Credentials
                                                    for configuring
                                                    WordPress Sortd Plugin.
                                                </Alert>
                                    {showSaveButton ? (
                                        <Button
                                            className="btn-round btnBlue"
                                            type="button"
                                            onClick={validateForm}
                                        >
                                            Save
                                        </Button>
                                    ) : (
                                        ""
                                    )}
                                    
                                    {alert.show ? (
                                        <Alert color={alert.type}>
                                            {alert.message}
                                        </Alert>
                                    ) : (
                                        ""
                                    )}

                                    {showLoader ? (
                                        <Spinner
                                            animation="border"
                                            role="status"
                                        >
                                            <span className="sr-only">
                                                Loading...
                                            </span>
                                        </Spinner>
                                    ) : (
                                        ""
                                    )}
                                    </div>
                                </Form>
                            </CardBody>
                            {/* <CardFooter></CardFooter> */}
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col className="ml-auto mr-auto" md="12">
                        <Card className="card-signup text-center">
                            <CardHeader>
                                <CardTitle tag="h4">Project Settings</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Form className="form-horizontal" action="" id="projectSettings"method="POST" onSubmit={saveProjectSettings}>
                                <Row>
                                    <Label sm="2">Enable Category in Article</Label>
                                    <Col sm="7">
                                        <FormGroup>
                                        <Switch name="enable_category_in_article"
                                            offColor="success"
                                            offText={<i className="nc-icon nc-simple-remove" />}
                                            onColor="success"
                                            onText={<i className="nc-icon nc-check-2" />}
                                            value={formdetails.enable_category_in_article}
                                            onChange={(value,enabled)=>  handleSwitchChange('enable_category_in_article',enabled) }
                                        />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Label sm="2">Enable Category Alias Url</Label>
                                    <Col sm="7">
                                        <FormGroup>
                                        <Switch name="enable_category_alias_url"
                                            offColor="success"
                                            offText={<i className="nc-icon nc-simple-remove" />}
                                            onColor="success"
                                            onText={<i className="nc-icon nc-check-2" />}
                                            value={formdetails.enable_category_alias_url}
                                            onChange={(value,enabled)=>  handleSwitchChange('enable_category_alias_url',enabled) }
                                        />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Label sm="2">Self Canonical</Label>
                                    <Col sm="7">
                                        <FormGroup>
                                        <Switch name="self_canonical"
                                            offColor="success"
                                            offText={<i className="nc-icon nc-simple-remove" />}
                                            onColor="success"
                                            onText={<i className="nc-icon nc-check-2" />}
                                            value={formdetails.self_canonical}
                                            onChange={(value,enabled)=>  handleSwitchChange('self_canonical',enabled) }
                                        />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Label sm="2">Shorts Category Id</Label>
                                    <Col sm="7">
                                        <FormGroup>
                                        <Input name="shorts_category_id"
                                            value={formdetails.shorts_category_id}
                                            onChange={(event)=>  handleInputChange(event,'shorts_category_id') }
                                        />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                {showSettingsSaveButton?<Button
                                    className="btn-round"
                                    color="info"
                                    type="button"
                                    onClick={validateProjectSettings}
                                >
                                    Save
                                </Button>:''}
                                {settingsAlert.show?<Alert color={settingsAlert.type}>
                                    {settingsAlert.message}
                                </Alert>:''}

                                {showSettingsLoader?<Spinner animation="border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </Spinner>:''}
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <div
                className="full-page-background"
                style={{
                    backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
                }}
            />
        </div>
    );
};

export default ProjectSettings;

import React, {useEffect,useState,useContext} from 'react';
import ProjectContext from '../../ProjectContext.js'

import {useHistory} from 'react-router-dom';
import planService from "../../services/api/plan-service";
import subscriptionService from '../../services/api/subscription-service'
import projectService from '../../services/api/projects-service.js';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardImg,
  CardFooter,
  CardTitle,
  CardText,
  Label,
  FormGroup,
  Form,
  Input,
  Alert,
  Spinner,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";
import Select from 'react-select';
import Pagination from 'react-js-pagination';
import moment from 'moment';
import ReactDatetime from 'react-datetime';
import ReactBSAlert from "react-bootstrap-sweetalert";
const closeBtnStyle={
  "border-radius": "50%",
  "padding": "0.5em",
  "width": "30px",
  "height": "30px",
  "border": "1px solid black",
  "color": "black",
  "position": "absolute",
  "background-color" :"#ffffff",
  "pointer-events":"none"
}
const SubscriptionTransactions = (props) => {
    const projectDetails = useContext(ProjectContext);
    const history = useHistory();
    if(projectDetails.id==''){
        history.push("/project/allprojects");
    }
    const project_id = projectDetails.id;
    const [formdetails, setFormDetails] = useState({project_id ,from_date :'',to_date:''});
    const [subscriptions, setSubscriptions] = useState([]);
    const [totalSubscriptions, setTotalSubscriptions] = useState(0);
    const [renderComponent , setRenderComponent] = useState(false);
    const [reloadComponent, setReloadComponent] = useState(false);
    const [activePage, setActivePage] = useState(1);
    const [firstRecord, setFirstRecord] = useState(0);
    const [lastRecord, setLastRecord] = useState(0);
    const [showPopup,setShowPopup] = useState({alert:null});
    const [paidArticleEnabled , setPaidArticleEnabled] = useState(false);
    const records = 10;
    useEffect (()=> {
        (async ()=> {
            // const projectDetails = await projectService.getProjectDetails(project_id);
            // if(projectDetails.status && projectDetails.data) {
            //     if(projectDetails.data.settings && projectDetails.data.settings.paid_article === false) {
            //         paidArticlesNotEnabledPopup();
            //     }else{
            //         setPaidArticleEnabled(true);
            //     }
            // }
        })();
    },[]);
    useEffect(()=>{
        (async ()=> {
                const response = await subscriptionService.getBillingDetails(project_id,props.subscriptionId);
                if(response.status && response.data) {
                    let subscriptions = response.data;
                    setSubscriptions(response.data);
                }
                setRenderComponent(true);
        })();
    },[reloadComponent]);
    const hidePaidArticlesNotEnabledPopup = () => {
        hideAlert();
        history.push("/dashboard/home");
    }

    const searchSubscription = () => {
            setActivePage(1);
            setReloadComponent(Math.random());
    }

  const hideAlert = ()=>{
    setShowPopup({alert:null});
  }
  const reviewSubscriptionPopup = (details) => {
      setShowPopup({
        alert: (
                <ReactBSAlert
                    Success
                    onCancel={() => hideAlert()}
                    style ={{"display":"block","position":"absolute","height" : "auto","width": "50%"}}
                    closeOnClickOutside={true}
                    showCancel={false}
                    showConfirm={false}
                    btnSize=""
                >
                    <Row>
                        <Col>
                        <CardTitle tag="h4" align="left">
                            Subscription details
                            </CardTitle>
                        </Col>
                        <Col align="right">
                            <Button onClick = {() => hideAlert()} className ="closeBtn">X</Button>
                        </Col>   
                    </Row>
                    <Table size="lg" bordered responsive="sm" className="detailsTable">
                        <tr>
                            <td >
                                Subscription Id
                            </td>
                            <td>
                                {details.pg_subscription_id}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Status
                            </td>
                            <td>
                                {details.status}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Paid count
                            </td>
                            <td>
                                {details.paid_count}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Total count
                            </td>
                            <td>
                                {details.total_count}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Recurring Amount (INR)
                            </td>
                            <td>
                                {details.rz_plan_price}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Start date
                            </td>
                            <td>
                                {details.start_at ?
                                moment(details.start_at *1000).local().format('dddd DD MMM YYYY hh:mm:ss a')
                                :""}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                End date
                            </td>
                            <td>
                                {details.end_at ? 
                                moment(details.end_at *1000).local().format('dddd DD MMM YYYY hh:mm:ss a')
                                :""}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                 Current start date
                            </td>
                            <td>
                                {details.current_start ? 
                                 moment(details.current_start *1000).local().format('dddd DD MMM YYYY hh:mm:ss a')
                                 :""}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Current end date
                            </td>
                            <td>
                                { details.current_end ?
                                moment(details.current_end *1000).local().format('dddd DD MMM YYYY hh:mm:ss a')
                                :""}
                            </td>
                        </tr>

                        <tr>
                            <td>
                                Subscribed On
                            </td>
                            <td>
                                {moment(details.createdAt).local().format('dddd DD MMM YYYY hh:mm:ss a')}
                            </td>
                        </tr>
                    </Table>
            </ReactBSAlert> 
        )
      });
    }

    return (
        <div className="content mt-30">
            {showPopup.alert}
            <Container>
                <Card className="text-centercdp-box dp-box">
                    <CardHeader>
                        <CardTitle tag="h4" className="text-center">
                            Transactions 
                        </CardTitle>
                    </CardHeader>
                    
                    {!renderComponent?
                        <div className="text-center">
                            <Spinner className="text-center" animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                        </div>
                    :null}
                <CardBody>
                  <Table responsive>
                    <thead className="text-primary">
                    {renderComponent?
                        <>
                            <th className="text-center">#</th> 
                            <th className="text-center">Invoice Id</th>
                            <th className="text-center">Status</th>
                            <th className="text-center">Paid On</th>
                            {/* <th className="text-center">Action</th> */}
                        </>:''}
                    </thead>
                    <tbody>

                        { subscriptions && subscriptions.length?subscriptions.map((details, index)=>(
                        <tr className={details.status==="cancelled"?'table-danger':""}>
                            <td className="text-center">{index+1}</td>
                            <td className="text-center">
                                {details.invoice_id}
                            </td> 
                           
                            <td className="text-center">
                                {details.is_unpaid ? "Unpaid" : "Paid"}
                            </td>
                            <td className="text-center">
                                {moment(details.createdAt).format('dddd DD MMM YYYY hh:mm:ss a')}
                            </td>
                            {/* <td className="text-center">
                                <Button className="btn-smb" onClick={(event)=>reviewSubscriptionPopup(details)}> Review</Button>
                            </td> */}
                        </tr>
                        ))
                        :
                        null
                        }
                        {renderComponent && (!subscriptions|| !subscriptions.length)?
                        <td colspan="12" className="text-center">
                            <CardTitle tag="h4" >No Subscription Found</CardTitle>
                        </td>:
                        null
                    }
                    </tbody>
                  
                  </Table>

                  
                </CardBody>





                    
                    



                </Card>
                 <div
            className="full-page-background"
            style={{
                backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
            }}
            />
            </Container>
           
        </div>
    );
}

export default SubscriptionTransactions;
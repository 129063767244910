import React, { useEffect, useState } from "react";
import formValidations from "../../variables/form-validations";
import Timer from "../../components/utilities/Timer";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

const Otp = (props)=> {

  const [formdetails, setFormDetails] = useState({otp:""});
  const [otpstate, setOtpState] = useState("");
  const [otperror, setOtpError] = useState("");
  const [expired, setExpiry] = useState(false);
  
  const handleChange = (event) => {
      props.setDetails({
        ...props.details,
        [event.target.id]: ( event.target.value ).trim(),
      });
      
      setFormDetails({
        ...formdetails,
        [event.target.id]: ( event.target.value ).trim(),
      });
      
  }

  const handleBlur = (event) => {
      props.setDetails({
        ...props.details,
        [event.target.id]: ( event.target.value ).trim(),
      });

      setFormDetails({
        ...formdetails,
        [event.target.id]: ( event.target.value ).trim(),
      });

      //validateOtp();
  }

  const validateOtp = () => {
    if (formdetails.otp === '') {
      setOtpState("has-danger");
      setOtpError("This field is required");
      return false;
    }
  
    if(!formValidations.verifyExactLength(formdetails.otp,6)){
      setOtpState("has-danger");
      setOtpError("OTP must be of 6 digits.");
      return false;
    }

    setOtpState("has-success");
    return true;
  }

  const handleSubmit = function(e){  verifyOtp();e.preventDefault() }

  const verifyOtp = () => {
    if(validateOtp()){
      props.validateOtp();
    }
  }

  const resendOtp= () => {
      props.resendOtp();
      setExpiry(false);
  }

    return (
      <div className="register-page">
        <Container>
          <Row>
          <Col className="ms-auto me-auto" lg="4" md="6">
              <Card className="card-signup text-center">
                <CardHeader>
                  <CardTitle tag="h4">Enter OTP Details</CardTitle>
                </CardHeader>
                <CardHeader>
                  <CardTitle tag="h6">Hi {props.details.pubname}, OTP is sent to {props.details.email}</CardTitle>
                </CardHeader>
                <CardBody>
                  <Form action="" className="form" method="POST" onSubmit={handleSubmit}>
                    <InputGroup  className={otpstate}>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-key-25" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input id="otp" name="otp" placeholder="OTP..." type="password"  onChange={handleChange} onBlur={handleBlur} />
                      {otpstate === "has-danger" ? (
                            <label className="error">
                              {otperror}
                            </label>
                          ) : null} 

                       
                    </InputGroup>

                    <InputGroup className={props.errorstate}>
                    {props.errorstate === "has-danger" ? (
                            <label className="error">
                              {props.errormessage}
                            </label>
                          ) : null}   
                          </InputGroup>  

                    {expired?(
                      <span className="text-danger">
                      OTP Expired
                      </span>
                    ):
                    <>
                    <span className="text-success">
                      <b>OTP will expire in : </b>
                      </span>
                      
                    <Timer minutes={3} seconds={0} setExpiry={setExpiry} /> </> }  
                      <br></br>
                    {expired ? (
                      <Button
                      className="btn-round"
                      color="info"
                      type="button"
                      onClick={resendOtp}
                    >Resend OTP</Button> 
                    ):<Button
                    className="btn-round"
                    color="info"
                    type="button"
                    onClick={verifyOtp}
                  >
                    Verify
                  </Button>
                    }
                    
                  </Form>
                </CardBody>
                <CardFooter>
                  
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
          }}
        />
      </div>
    );
  
}

export default Otp;

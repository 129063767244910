import axios from 'axios';


const getTransactions = async (filters , page , records) => {
    let formdata={
        filters,
        page,
        records
    };
    const response = await axios.post('/api/paidarticles/get-transactions',formdata);
    return response.data;
}

export default {
    getTransactions
}
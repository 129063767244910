import React, { useEffect, useState, useContext } from "react";
import contentService from "../../services/api/content-service";
import {useHistory} from "react-router-dom";
import ProjectContext from '../../ProjectContext';
import './content.css';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Alert,
  Spinner,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

const EditCategoryMeta = (props)=> {
  const projectDetails = useContext(ProjectContext);
  const history = new useHistory();
  console.log(projectDetails.id);
  if(projectDetails.id==''){
    history.push("/project/allprojects");
  }

  const projectId = projectDetails.id;

  let urlSearchParams = new URLSearchParams(props.location.search);
  let categoryId = urlSearchParams.get('categoryid');
  if(!categoryId) {
      history.push('/contentsettings/categories'+'?project='+projectDetails.slug)
  }
  // let getProjectSlug= urlSearchParams.get('project');
  // if(!getProjectSlug){
  //   history.push({
  //     search: '?project='+projectDetails.slug
  //   })
  // }
  const [formdetails, setFormDetails] = useState({projectid:projectId,categoryId:categoryId,meta_data:{title:"",description:"",keywords:""}});
  const [category, setCategory] = useState(false);
  const [renderComponent,setRenderComponent] = useState(false);
  const [showLoader, setShowLoader]   = useState(false);
  const [showSaveButton, setShowSaveButton]   = useState(true);
  const [alert, setAlert]   = useState({show:false,message:'',type:''});

  useEffect( () => {
    (async () =>{
        let categoryData = await contentService.getCategoryDetails(categoryId);
            if(categoryData.status){
                setCategory(categoryData.data);
                if(categoryData.data.meta_data){
                  let metaData = JSON.parse(categoryData.data.meta_data)
                  setFormDetails({
                    ...formdetails,
                    meta_data:{title:metaData.title,description:metaData.description,keywords:metaData.keywords},
                  });
                }
                setRenderComponent(true);
            }
        })();
    }, []);

  
  const handleChange = (event) => {
    setFormDetails({
      ...formdetails,
      meta_data:{...formdetails.meta_data,[event.target.id]: ( event.target.value )},
    });
  }


  const handleSubmit = function(e){  validateForm();e.preventDefault() }
  
  const validateForm = async () => {
      setShowLoader(true);
      setShowSaveButton(false);
      if(formdetails.meta_data.description !== '' || formdetails.meta_data.keywords !== '' || formdetails.meta_data.title !== ''){

        let response = await contentService.updateCategoryMeta(formdetails);
        if(projectDetails.isContractExpired){
          setShowLoader(false)
          setShowSaveButton(true)
          projectDetails.setContractExpiredAlert(projectDetails);
        }
        else if(response.status){
          setTimeout(()=>{
            setShowLoader(false)
            setAlert({show:true,type:'success',message:'Category Meta  updated Successfully'});
          },1000);
          
          setTimeout(()=>{
            setAlert({show:false,type:'',message:''});
          },3000);
  
          setTimeout(()=>{
            history.push("/contentsettings/categories");
          },2000);
          
        }else{
          setTimeout(()=>{
            setShowLoader(false)
            setShowSaveButton(true)
            setAlert({show:true,type:'danger',message:'Category Meta could not be update, Please try again.'});
          },1000);
  
          setTimeout(()=>{
            setAlert({show:false,type:'',message:''});
          },3000);
        }

      }else{
        // setTimeout(()=>{
          setShowLoader(false)
          setShowSaveButton(true)
          setAlert({show:true,type:'danger',message:'Please enter correct details.'});
        // },1000);

        setTimeout(()=>{
          setAlert({show:false,type:'',message:''});
        },3000);
      }
  }
    return (
      <div className="content">
        <Container>
          <Row>
          <Col className="ml-auto mr-auto"  md="12">
              <Card className="card-signup text-center">
                <CardHeader>
                  <CardTitle tag="h4">Save Category Meta of category "{category.name}"</CardTitle>
                </CardHeader>
                <CardBody>
                  <Form className="form-horizontol" action="" id="editCategoryMeta"  method="POST" onSubmit={handleSubmit}>

                    <Row>
                      <Label sm="2">Title</Label>
                      <Col sm="7">
                          <FormGroup>
                              <Input
                                type="text"
                                id="title"
                                name="title"
                                value={formdetails.meta_data.title}
                                onChange = {handleChange}
                                />
                          </FormGroup>
                      </Col>
                    </Row>
                
                    <Row>
                      <Label sm="2">Description</Label>
                      <Col sm="7">
                          <FormGroup>
                              <Input
                                type="text"
                                id="description"
                                name="description"
                                value = {formdetails.meta_data.description}
                                onChange = {handleChange}
                                />
                          </FormGroup>
                      </Col>
                    </Row> 
                    <Row>
                      <Label sm="2">Keywords</Label>
                      <Col sm="7">
                          <FormGroup>
                              <Input
                                type="text"
                                id="keywords"
                                name="keywords"
                                value={formdetails.meta_data.keywords}
                                onChange = {handleChange}
                                />
                          </FormGroup>
                      </Col>
                    </Row>  
                {showSaveButton?
                    <>
                        <Button
                            className="btn-round"
                            color="info"
                            type="button"
                            onClick={validateForm}
                        >
                            Save
                        </Button>
                        <Button
                            className="btn-round"
                            color="danger"
                            type="button"
                            onClick={(event) => {history.push('/contentsettings/categories')}}
                        >
                                Cancel
                        </Button>  
                    </>               
                  :''}
                  {alert.show?<Alert color={alert.type}>
                    {alert.message}
                  </Alert>:''}

                  {showLoader?<Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>:''}
                    
                  </Form>
                </CardBody>
                <CardFooter>
                  
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
          }}
        />
      </div>

            
    );
  
}

export default EditCategoryMeta;

import React, { useEffect, useState, useContext } from "react";
import contentService from "../../services/api/content-service";
import CreateWeblink from './CreateWeblink'
import ReorderCategories from "./ReorderCategories";
import {useHistory} from "react-router-dom";
import ProjectContext from '../../ProjectContext';
import moment from 'moment';
import './content.css';
import ReactBSAlert from "react-bootstrap-sweetalert";
import formValidations from "../../variables/form-validations";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Alert,
  Spinner,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";
// import { Link } from "react-router-dom";

const Categories = (props)=> {
  const projectDetails = useContext(ProjectContext);
  const history = new useHistory();
  console.log(projectDetails.id);
  if(projectDetails.id==''){
    history.push("/project/allprojects");
  }
  const projectId = projectDetails.id;
  //const projectId = "607445760248516294fc78bc";//TODO Project Id
  const [formdetails, setFormDetails] = useState({projectid:projectId,categoryid:"",categoryname:"",categoryalias:"",webpage_url:''});
  const [categories, setCategories] = useState(false);
  const [reorderCategories, setReorderCategories] = useState(false);
  const [createWeblink, setCreateWeblink] = useState(false);
  const [renderComponent,setRenderComponent] = useState(false);
  const [reloadComponent, setReloadComponent] = useState(0);
  const [showPopup, setShowPopup] = useState({ alert: null });
  let getProjectSlug = new URLSearchParams(props.location.search).get('project');
  if(!getProjectSlug){
    history.push({
      search: '?project='+projectDetails.slug
    })
  }

  useEffect( () => {
    (async () =>{
        let categoryData = await contentService.getCategories(projectId);
            if(categoryData.status){
                setCategories(categoryData.data);
                setRenderComponent(true);
            }
        })();
    }, [formdetails,reorderCategories,createWeblink,reloadComponent]);


  const handleDeleteCategory = async (projectId, cat_id) => {
            const response = await contentService.removeCategory({projectId, cat_id});
            if(projectDetails.isContractExpired){
              setShowPopup({alert:null});
              projectDetails.setContractExpiredAlert(projectDetails);
            }
            else if (response.status && response.data) {
                setShowPopup({
                    alert: (
                        <ReactBSAlert
                            success
                            style={{ display: "block", marginTop: "-100px" }}
                            title="Category Deleted!"
                            onConfirm={() => hideAlert()}
                            onCancel={() => hideAlert()}
                            confirmBtnBsStyle="info"
                            btnSize=""
                        >
                            Category Deleted successfully.
                        </ReactBSAlert>
                    ),
                });
    
                setTimeout(() => {
                    hideAlert();
                    setReloadComponent(Math.random());
                }, 3000);    
            }else
            {
                setShowPopup({
                    alert: (
                        <ReactBSAlert
                            danger
                            style={{ display: "block", marginTop: "-100px" }}
                            title="Category could not delete!"
                            onConfirm={() => hideAlert()}
                            onCancel={() => hideAlert()}
                            confirmBtnBsStyle="info"
                            btnSize=""
                        >
                            Category could not delete.
                        </ReactBSAlert>
                    ),
                });
    
                setTimeout(() => {
                    hideAlert();
                    setReloadComponent(Math.random());
                }, 3000);
                
            }
    };
    const confirmationPopup = (projectId, categoryId) => {
        setShowPopup({
            alert: (
                <ReactBSAlert
                    danger
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Are you sure want to delete this webpage category ?"
                    onConfirm={() => {
                        handleDeleteCategory(projectId, categoryId);
                        hideAlert();
                    }}
                    onCancel={() => {
                        hideAlert();
                    }}
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    confirmBtnText="Yes, Delete it!"
                    cancelBtnText="Cancel"
                    showCancel
                    btnSize=""
                >
                    You want to delete Category!
                </ReactBSAlert>
            ),
        });
    };
    const hideAlert = () => {
        setShowPopup({
            alert: null,
        });
    };
 
    const handleCategoryNameChange = (event) => {
        
    }

    const handleAliasChange = (event) => {
       
    }  

  const saveCategoryData = async (categoryId,event,isWebLinkCategory) => {
      let validations = true;
      let alias = document.getElementById('alias_'+categoryId).value.trim();
      if(document.getElementById('name_'+categoryId).value.trim() == ''){
        validations = false;
        document.getElementById('error_name_label_'+categoryId).style.display = '';
        setTimeout(()=>{
          document.getElementById('error_name_label_'+categoryId).style.display = 'none';
        },2000);
      }

      if(document.getElementById('alias_'+categoryId).value.trim() == ''){
        validations = false;
        document.getElementById('error_alias_label_'+categoryId).style.display = '';
        setTimeout(()=>{
          document.getElementById('error_alias_label_'+categoryId).style.display = 'none';
        },2000);
      }else{
        if(categories){
          Object.keys(categories).forEach(function(key) {
              let existingCategoryAlias = categories[key].alias;
              if(categoryId !== categories[key]._id && alias===existingCategoryAlias){
                validations = false;
                document.getElementById('error_alias_label_'+categoryId).innerHTML ="Category already exists with same alias";
                document.getElementById('error_alias_label_'+categoryId).style.display = '';
                setTimeout(()=>{
                  document.getElementById('error_alias_label_'+categoryId).style.display = 'none';
                },2000);
              }
          })
        }
      }
      if(isWebLinkCategory) {
         let url = document.getElementById('webpage_url_'+categoryId).value.trim()
         if( url === ''){
          validations = false;
          document.getElementById('error_webpage_url_label_'+categoryId).innerHTML ="Category webpage URL can not be empty."
          document.getElementById('error_webpage_url_label_'+categoryId).style.display = '';
          setTimeout(()=>{
            document.getElementById('error_webpage_url_label_'+categoryId).style.display = 'none';
          },2000);
       }else{
         if(!formValidations.verifyUrl(url)){
            validations = false;
            document.getElementById('error_webpage_url_label_'+categoryId).innerHTML ="Invalid URL."
            document.getElementById('error_webpage_url_label_'+categoryId).style.display = '';
            setTimeout(()=>{
              document.getElementById('error_webpage_url_label_'+categoryId).style.display = 'none';
              document.getElementById('error_webpage_url_label_'+categoryId).innerHTML =""
            },2000);  
         }
       }
      }

      if(!validations){
        return false;
      }
      document.getElementById('spinner_'+categoryId).style.display = '';
      document.getElementById('save_'+categoryId).style.display = 'none';
      document.getElementById('cancel_'+categoryId).style.display = 'none';
      formdetails.categoryid = categoryId;  
      let formdata = {
          projectid:projectId,
          categoryid:categoryId,
          categoryname:document.getElementById('name_'+categoryId).value,
          categoryalias:document.getElementById('alias_'+categoryId).value
      }
      if(isWebLinkCategory) {
        formdata['webpage_url'] = document.getElementById('webpage_url_'+categoryId).value
      }
      let saveCategory = await contentService.updateCategory(formdata);
      if(projectDetails.isContractExpired){
        document.getElementById('spinner_'+categoryId).style.display = 'none';
        document.getElementById('failure_alert_'+categoryId).style.display = '';
        document.getElementById('save_'+categoryId).style.display = '';
        document.getElementById('cancel_'+categoryId).style.display = '';
        projectDetails.setContractExpiredAlert(projectDetails);
      }
      else if(saveCategory.status){
        setTimeout(()=>{
            document.getElementById('edit_'+categoryId).style.display = '';
            document.getElementById('spinner_'+categoryId).style.display = 'none';
            document.getElementById('success_alert_'+categoryId).style.display = '';
            document.getElementById('span_name_label_'+categoryId).style.display = 'block';
            document.getElementById('span_alias_label_'+categoryId).style.display = 'block';
            document.getElementById('span_name_form_'+categoryId).style.display = 'none';
            document.getElementById('span_alias_form_'+categoryId).style.display = 'none';
            if(isWebLinkCategory) {
              document.getElementById('span_webpage_url_form_'+categoryId).style.display = 'none';
            }
            setFormDetails({
                ...formdetails,
                categoryid: '',
                categoryname: '',
                categoryalias : '',
                webpage_url : '',
            });
        },1000);
      }else{
        document.getElementById('spinner_'+categoryId).style.display = 'none';
        document.getElementById('failure_alert_'+categoryId).style.display = '';
        document.getElementById('save_'+categoryId).style.display = '';
        document.getElementById('cancel_'+categoryId).style.display = '';
      }

      setTimeout(()=>{
        document.getElementById('success_alert_'+categoryId).style.display = 'none';
        document.getElementById('failure_alert_'+categoryId).style.display = 'none';
      },2000);

      console.log(categoryId);
  }

  const showSaveOptions = async (categoryId, categoryName, categoryAlias, event, isWebLinkCategory ,webpageURL) => {
      
    document.getElementById('edit_'+categoryId).style.display = 'none';
    document.getElementById('save_'+categoryId).style.display = '';
    document.getElementById('cancel_'+categoryId).style.display = '';
    document.getElementById('span_name_label_'+categoryId).style.display = 'none';
    document.getElementById('span_alias_label_'+categoryId).style.display = 'none';
    document.getElementById('span_name_form_'+categoryId).style.display = 'block';
    document.getElementById('span_alias_form_'+categoryId).style.display = 'block';
    document.getElementById('name_'+categoryId).value = categoryName;
    document.getElementById('alias_'+categoryId).value = categoryAlias;
    if(isWebLinkCategory) {
      // document.getElementById('span_webpage_url_label_'+categoryId).style.display = 'none';
      document.getElementById('span_webpage_url_form_'+categoryId).style.display = 'block';
      document.getElementById('webpage_url_'+categoryId).value = webpageURL;
    }
    
    console.log(categoryId);
}

const hideSaveOptions = async (categoryId,event, isWebLinkCategory) => {
    document.getElementById('edit_'+categoryId).style.display = 'block';
    document.getElementById('save_'+categoryId).style.display = 'none';
    document.getElementById('cancel_'+categoryId).style.display = 'none';
    document.getElementById('span_name_label_'+categoryId).style.display = 'block';
    document.getElementById('span_alias_label_'+categoryId).style.display = 'block';
    document.getElementById('span_name_form_'+categoryId).style.display = 'none';
    document.getElementById('span_alias_form_'+categoryId).style.display = 'none';
    if(isWebLinkCategory) {
      // document.getElementById('span_webpage_url_label_'+categoryId).style.display = 'block';
      document.getElementById('span_webpage_url_form_'+categoryId).style.display = 'none';
    }

    console.log(categoryId);
}
const redirectUrl =(event, categoryId)=>{
    event.preventDefault();
    history.push("/contentsettings/edit-category-meta"+"?categoryid="+categoryId)
}

const decodeHtml = (html) => {
  var txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
}

  let categoryElements = [];
  if(!categories || !categories.length){
    if(!renderComponent){
      categoryElements.push(
        <td colSpan="4" className="text-center">
            <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
        </td>
      );
    }else{
    categoryElements.push(
        <td colSpan="4" className="text-center">
            <CardTitle tag="h4">No Category Found</CardTitle>
        </td>
    );
    }
  }else{
      let i=1;
    categories.forEach(function(categoryDetails) {
      console.log("categoryDetails",categoryDetails);
      let catName = decodeHtml(categoryDetails.name)
        categoryElements.push(
            <tr id={"row_"+categoryDetails._id}>
               <td className="text-left">{i}</td>
               
               <td  className="text-left" >
                   <span id={"span_name_label_"+categoryDetails._id}>
                   {categoryDetails.content_type==5 ? <i class="fa fa-link fa-rotate-90" rel="tooltip" style={{"cursor":"pointer"}} title={categoryDetails.webpage_url} aria-hidden="true"></i>:""}&nbsp;&nbsp;&nbsp;{catName} 
                   </span>
                   <span style={{display: 'none'}} className="display-none" id={"span_name_form_"+categoryDetails._id}>
                   <Input
                        name={"name_"+categoryDetails._id}
                        id={"name_"+categoryDetails._id}
                        type="text"
                        placeholder = "Category Name"
                        onChange={handleCategoryNameChange}
                        //value={categoryDetails.name}
                    />
                    <label id={"error_name_label_"+categoryDetails._id} className="error" style={{display:'none',color:'red'}}>
                              Category name can not be empty.
                     </label>
                    </span>
               </td>
               <td className="text-left">{categoryDetails.cat_guid}</td>
               <td>
                  <td  className="text-left" >
                            <span  id={"span_alias_label_"+categoryDetails._id}>
                              {categoryDetails.alias}
                            </span>
                            <span className="has-error" style={{display: 'none'}} id={"span_alias_form_"+categoryDetails._id}>
                              <Input
                                  name={"alias_"+categoryDetails._id}
                                  id={"alias_"+categoryDetails._id}
                                  type="text"
                                  placeholder ="Category Alias"
                                  onChange={handleAliasChange}
                                  //value={categoryDetails.alias}
                              />
                              <label id={"error_alias_label_"+categoryDetails._id}  className="error" style={{display:'none',color:'red'}}>
                                    Category alias can not be empty.
                              </label>
                            </span>      
                  </td>
                  {categoryDetails.content_type==5?
                  <span >
                      <td  className="text-left" >
                            {/* <span  id={"span_webpage_url_label_"+categoryDetails._id}>
                                {categoryDetails.webpage_url}
                                </span> */}
                                <span className="has-error" style={{display: 'none'}} id={"span_webpage_url_form_"+categoryDetails._id}>
                                <Input
                                    name={"webpage_url_"+categoryDetails._id}
                                    id={"webpage_url_"+categoryDetails._id}
                                    type="text"
                                    placeholder ="Web link URL"
                                />
                                <label id={"error_webpage_url_label_"+categoryDetails._id}  className="error" style={{display:'none',color:'red'}}>
                                      Category webpage URL can not be empty.
                                </label>
                            </span>      
                      </td>
                    </span>
                  :""}
               </td>
               <td className="text-left">{categoryDetails.taxonomy_type_id ? categoryDetails.taxonomy_type_id.name: "Categories"}</td>

               <td  className="text-left">
               <span>
                   {moment(categoryDetails.updatedAt).fromNow()}
                </span>
                </td>
               <td className="text-left ctMng" >
                 <span>
                    <Button
                          className="btn-bg-rm ftR"
                          id={"edit_"+categoryDetails._id}
                          type="button"
                          rel="tooltip"
                          title="Edit"
                          onClick={(event)=>  showSaveOptions(categoryDetails._id,categoryDetails.name,categoryDetails.alias, event ,categoryDetails.content_type==5?true:false , categoryDetails.webpage_url) //content_type 5 means its a weblink category
                          }
                      >
                          &nbsp;<i class="fa fa-edit"></i>
                      </Button>

                      <Button
                          style={{display: 'none'}}
                          className="btn-bg-rm grn"
                          rel="tooltip"
                          title="Save"
                          id={"save_"+categoryDetails._id}
                          type="button"
                          onClick={(event)=>  saveCategoryData(categoryDetails._id,event, categoryDetails.content_type==5?true:false) } 
                      >
                        <i class="fa fa-check"></i>
                      </Button>
                      
                      <Button
                          style={{display: 'none'}}
                          className="btn-bg-rm rd"
                          rel="tooltip"
                          title="Cancel"
                          id={"cancel_"+categoryDetails._id}
                          type="button"
                          onClick={(event)=>  hideSaveOptions(categoryDetails._id, event, categoryDetails.content_type==5?true:false) } //content_type 5 means its a weblink category
                      >
                          <i class="fa fa-times "></i>
                      </Button>
                      {categoryDetails.content_type==5?
                          <Button
                            style={{display: 'block'}}
                            className="btn-bg-rm ftR"
                            rel="tooltip"
                            title="delete category"
                            type="button"
                            onClick={(event)=>  confirmationPopup(projectId ,categoryDetails._id) }
                          >
                          <i class="fa fa-trash"></i>
                          </Button>
                        :""}
                  </span>
                  <Button
                      style={{display: 'block'}}
                      className="btn-bg-rm rd"
                      rel="tooltip"
                      title="Meta Settings"
                      type="button"
                      onClick={(event)=>  redirectUrl(event,categoryDetails._id) }
                  >
                      &nbsp;<i class="nc-icon nc-settings-gear-65"></i>
                  </Button>
                  <Spinner id={"spinner_"+categoryDetails._id} style={{display: 'none'}}  animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>

                  <Alert id={"success_alert_"+categoryDetails._id} style={{display: 'none',width:'100px'}} color="success">
                    Saved
                  </Alert>

                  <Alert className="alrtrd" id={"failure_alert_"+categoryDetails._id} style={{display: 'none'}} >
                    Not Saved.Try Again.
                  </Alert>
                  {/* <Link className="pshNot" title="Push Notification"
                    to={{
                      pathname: "/notifications/dashboard",
                      state: {
                        category_id: categoryDetails._id
                      },
                    }}
                    >
                    <i class="fa fa-bell-o" aria-hidden="true"></i>
                  </Link> */}

               </td>
            </tr>
        )
        
        if(categoryDetails.sub_categories && categoryDetails.sub_categories.length){
            let subCategories = categoryDetails.sub_categories;
            subCategories.forEach(function(subCategoryDetails) {
              categoryElements.push(
                <tr id={"row_"+subCategoryDetails._id}>
               <td className="text-left"></td>
               
               <td className="text-left">
                   <span style={{'margin-left':"20px"}} id={"span_name_label_"+subCategoryDetails._id}>
                  {subCategoryDetails.content_type==5 ? <i class="fa fa-link fa-rotate-90" rel="tooltip" style={{"cursor":"pointer"}} title={subCategoryDetails.webpage_url} aria-hidden="true"></i>:""}  &nbsp;&nbsp;&nbsp;{subCategoryDetails.name} 
                   </span>
                   <span style={{display: 'none'}} className="display-none" id={"span_name_form_"+subCategoryDetails._id}>
                   <Input
                        name={"name_"+subCategoryDetails._id}
                        id={"name_"+subCategoryDetails._id}
                        type="text"
                        placeholder="Category Name"
                        onChange={handleCategoryNameChange}
                        //value={subCategoryDetails.name}
                    />
                    <label id={"error_name_label_"+subCategoryDetails._id} className="error" style={{display:'none',color:'red'}}>
                              Category name can not be empty.
                     </label>
                    </span>
               </td>
               <td className="text-left">{subCategoryDetails.cat_guid}</td>
               <td>
               <td className="text-left">
                    <span  id={"span_alias_label_"+subCategoryDetails._id}>
                        {subCategoryDetails.alias}
                        </span>
                        <span style={{display: 'none'}} id={"span_alias_form_"+subCategoryDetails._id}>
                        <Input
                            name={"alias_"+subCategoryDetails._id}
                            id={"alias_"+subCategoryDetails._id}
                            type="text"
                            placeholder="Category Alias"
                            onChange={handleAliasChange}
                            //value={subCategoryDetails.alias}
                        />
                        <label id={"error_alias_label_"+subCategoryDetails._id}  className="error" style={{display:'none',color:'red'}}>
                                Category alias can not be empty.
                          </label>
                    </span>
               </td>
               {subCategoryDetails.content_type==5?
               <span >
               <td  className="text-left" >
                    {/* <span  id={"span_webpage_url_label_"+categoryDetails._id}>
                        {categoryDetails.webpage_url}
                        </span> */}
                        <span className="has-error" style={{display: 'none'}} id={"span_webpage_url_form_"+subCategoryDetails._id}>
                        <Input
                            name={"webpage_url_"+subCategoryDetails._id}
                            id={"webpage_url_"+subCategoryDetails._id}
                            type="text"
                            placeholder ="Web link URL"
                        />
                        <label id={"error_webpage_url_label_"+subCategoryDetails._id}  className="error" style={{display:'none',color:'red'}}>
                              Category webpage URL can not be empty.
                        </label>
                    </span>      
               </td>
                </span>
               :""}
               </td>
               <td className="text-left">{subCategoryDetails.taxonomy_type_id ? subCategoryDetails.taxonomy_type_id.name: "Categories"}</td>

               <td  className="text-left">
               <span>
                   {moment(categoryDetails.updatedAt).fromNow()}
                </span>
                </td>
               <td className="text-left ctMng">
                 <span>
                    <Button
                          className="btn-bg-rm ftR"
                          rel="tooltip"
                          title="Edit"
                          id={"edit_"+subCategoryDetails._id}
                          type="button"
                          onClick={(event)=>  showSaveOptions(subCategoryDetails._id,subCategoryDetails.name,subCategoryDetails.alias, event, subCategoryDetails.content_type==5?true:false , subCategoryDetails.webpage_url) }
                      >
                        &nbsp;<i class="fa fa-edit"></i>
                      </Button>

                      <Button
                          style={{display: 'none'}}
                          className="btn-bg-rm grn"
                          rel="tooltip"
                          title="Save"
                          id={"save_"+subCategoryDetails._id}
                          type="button"
                          onClick={(event)=>  saveCategoryData(subCategoryDetails._id,event, subCategoryDetails.content_type==5?true:false) }
                      >
                        <i class="fa fa-check "></i>
                      </Button>

                      <Button
                          style={{display: 'none'}}
                          className="btn-bg-rm rd"
                          rel="tooltip"
                          title="Cancel"
                          id={"cancel_"+subCategoryDetails._id}
                          type="button"
                          onClick={(event)=>  hideSaveOptions(subCategoryDetails._id,event, subCategoryDetails.content_type==5?true:false) }
                      >
                        <i class="fa fa-times "></i>
                      </Button>
                      {subCategoryDetails.content_type==5?
                          <Button
                            style={{display: 'block'}}
                            className="btn-bg-rm ftR"
                            rel="tooltip"
                            title="delete category"
                            type="button"
                            onClick={(event)=>  confirmationPopup(projectId ,subCategoryDetails._id) }
                          >
                          <i class="fa fa-trash"></i>
                          </Button>
                        :""}
                  </span>
                  <Button
                      style={{display: 'block'}}
                      className="btn-bg-rm rd"
                      rel="tooltip"
                      title="Meta Settings"
                      type="button"
                      onClick={(event)=>  redirectUrl(event,subCategoryDetails._id) }
                  >
                      &nbsp;<i class="nc-icon nc-settings-gear-65"></i>
                  </Button>
                 <Spinner id={"spinner_"+subCategoryDetails._id} style={{display: 'none'}}  animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>

                  <Alert id={"success_alert_"+subCategoryDetails._id} style={{display: 'none',width:'100px'}} color="success">
                    Saved
                  </Alert>

                  <Alert id={"failure_alert_"+subCategoryDetails._id} style={{display: 'none',width:'100px'}} color="danger">
                    Not Saved. Try Again.
                  </Alert>

               </td>
            </tr>
              )
            })
        }

        i++;
    })
  }
  if(createWeblink){
    return (
      <CreateWeblink setCreateWeblink={setCreateWeblink} projectId ={projectId}/>
    );
  }
  if(reorderCategories){
    return (
      <ReorderCategories setReorderCategories={setReorderCategories} categories={categories}/>
    );
  }else{
    return (
      <div className="content mt-30">
        {showPopup.alert}
        <Container>
          <Row>
          <Col className="ml-auto mr-auto"  md="12">
              <Card className="card-signup dp-box">
                <CardHeader>
                <CardTitle tag="h4" className="heding_tab_h4 ">
                    Category Manager
                    
                </CardTitle>
                <div style={{float: 'right'}}>
                  <Button
                      className="btn-smb inUsr"
                      type="button"
                      onClick={(event)=>  setCreateWeblink(true) }
                  >Add New Weblink</Button>
                 {categories.length?<Button
                      style={{float: 'right'}}
                      className="btn-smb inUsr"
                      type="button"
                      onClick={(event)=>  setReorderCategories(true) }
                  >Reorder Categories</Button>
                  :''}
                  </div>
                </CardHeader>
                <CardBody>
                <Table responsive>
                    <thead className="text-primary">
                      <th className="text-left">#</th>   
                        <th className="text-left">Category Name</th>
                        <th className="text-left">GUID</th>
                        <th className="text-left">Category Alias</th>
                        <th className="text-left">Taxonomy Type</th>
                        <th className="text-left">Updated At</th>
                        <th className="text-left">Action</th>
                        {/* <th className="text-left">Meta Setting</th> */}
                    </thead>
                    <tbody>{categoryElements}</tbody>
                  
                  </Table>
                </CardBody>
                {/* <CardFooter>
                  
                </CardFooter> */}
              </Card>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
          }}
        />
      </div>
    );
  }
  
}

export default Categories;

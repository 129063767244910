import React, { useCallback, useEffect, useRef, useState } from "react";
import "./style/trimModal.css";
import allListingService from "../../services/api/Jwplayer/allListing-service";
import { Range } from "react-range";
import TimerBox from "./components/TimerBox";

//Debouncing
const TrimModal = ({ media, projectId, cancelTrim }) => {
    // const [maxTime, setMaxTime] = useState(1);
    const videoRef = useRef(null);
    const [isVideoReady, setIsVideoReady] = useState(false);
    const [showMessage, setShowMessage] = useState({
        value: false,
        success: false,
        msg: "",
    });
    const [isPlaying, setIsPlaying] = useState(false);
    const [duration, setDuration] = useState(5);
    const [trim, setTrim] = useState([0, 5]);
    const [mediaData, setMediaData] = useState({});
    const [timestamps, setTimestamps] = useState([]);
    const [currentTime, setCurrentTime] = useState(0);
    const [newClipName, setNewClipName] = useState(media.metadata.title);
    const handleStartTimeChange = useCallback((newStartTime) => {
        console.log(trim);
        console.log("start");
        if (trim[0] !== newStartTime)
            setTrim((prevTrim) => [newStartTime, prevTrim[1]]);
    }, []);

    const handleEndTimeChange = useCallback((newEndTime) => {
        console.log("end");
        console.log("dsfa");
        if (trim[1] !== newEndTime)
            setTrim((prevTrim) => [prevTrim[0], newEndTime]);
    }, []);

    const formatSecondsWithMS = (s) => {
        let ms = s % 1;
        ms = (ms * 1000).toFixed(0).padStart(3, "0");
        let date = new Date(s * 1000).toISOString();
        return date.substr(11, 8) + "." + ms;
    };

    const formatSeconds = (s) => new Date(s * 1000).toISOString().substr(11, 8);

    const getTimestamps = () => {
        const intervalSize = videoRef.current.duration / (6 - 1);
        const intervals = [];
        for (let i = 0; i < 6; i++) {
            const start = i * intervalSize;
            intervals.push(formatSeconds(start));
        }
        setTimestamps(intervals);
    };

    const handleClip = async () => {
        const newMedia = { ...media };
        newMedia.metadata.title = newClipName;
        console.log(newMedia.metadata);
        const response = await allListingService.clipVideo(
            projectId,
            [formatSecondsWithMS(trim[0]), formatSecondsWithMS(trim[1])],
            mediaData.file,
            newMedia.metadata
        );

        setShowMessage({
            value: true,
            success: response.status,
            msg: response.status
                ? "Media Clipped Succesfully"
                : "Media Clipping failed",
        });
        setTimeout(() => {
            setShowMessage({ value: false, success: "false", msg: "" });
            cancelTrim();
        }, 3000);

        console.log(response.status ? "video Clipped" : "video Clipped failed");
    };

    const handleTrim = async () => {
        const response = await allListingService.trimVideo(
            projectId,
            media.id,
            [formatSecondsWithMS(trim[0]), formatSecondsWithMS(trim[1])],
            mediaData.file
        );
        console.log(response.status);
        setShowMessage({
            value: true,
            success: response.status,
            msg: response.status
                ? "Media Updated Succesfully"
                : "Media Updating failed",
        });
        setTimeout(() => {
            setShowMessage({ value: false, success: "false", msg: "" });
            cancelTrim();
        }, 3000);

        console.log(response.status ? "video trimmed" : "video trimmed failed");
    };

    const onLoadedMetadata = () => {
        console.log("loadMetadata");
        setIsVideoReady(true);
        setDuration(videoRef.current.duration);

        setTrim([0, videoRef.current.duration]);
        // setMaxTime(videoRef.current.duration);
        getTimestamps();
    };

    const handlePlay = () => {
        if (isPlaying) {
            videoRef.current.pause();
        } else {
            videoRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    const handleOnChange = (newTrim) => {
        setTrim(newTrim);
        videoRef.current.currentTime = newTrim[0];
    };

    useEffect(() => {
        if (isVideoReady && videoRef.current) {
            console.log("videoReady");
            const videoElement = videoRef.current;

            const updateCurrentTime = () => {
                setCurrentTime(videoElement.currentTime);
            };

            videoElement.addEventListener("timeupdate", updateCurrentTime);

            return () => {
                videoElement.removeEventListener(
                    "timeupdate",
                    updateCurrentTime
                );
            };
        }
    }, [isVideoReady]);

    useEffect(() => {
        console.log("trim change render");
        const videoElement = videoRef.current;
        if (videoElement) {
            const handleTimeUpdate = () => {
                if (videoElement.currentTime >= trim[1]) {
                    videoElement.currentTime = trim[0];
                    videoElement.pause();
                }
            };
            videoElement.addEventListener("timeupdate", handleTimeUpdate);
            return () => {
                videoElement.removeEventListener(
                    "timeupdate",
                    handleTimeUpdate
                );
            };
        }
    }, [trim]);

    useEffect(() => {
        const getData = async () => {
            const data = await allListingService.getMp4Link(media.id);
            setMediaData(data);
        };

        getData();
    }, [media.id]);

    return (
        <div className="modal1">
            <div className="modal-content1">
                <h6>Trim or Create Clip</h6>
                {mediaData && mediaData.file ? (
                    <>
                        <div className="video-container">
                            <video
                                ref={videoRef}
                                height={"360"}
                                width={"640"}
                                onLoadedMetadata={onLoadedMetadata}
                                className="video-player"
                            >
                                <source src={mediaData.file} type="video/mp4" />
                            </video>

                            <button
                                className="custom-play-button"
                                onClick={handlePlay}
                            >
                                <div
                                    className={
                                        isPlaying ? "pause-icon" : "play-icon"
                                    }
                                ></div>
                            </button>
                        </div>

                        <Range
                            step={0.01}
                            min={0}
                            max={duration}
                            values={trim}
                            onChange={handleOnChange}
                            renderTrack={({ props, children }) => (
                                <div
                                    {...props}
                                    className="track"
                                    style={{ ...props.style }}
                                >
                                    {children}
                                    <div
                                        className="current-time-indicator"
                                        style={{
                                            left: `${
                                                (currentTime / duration) * 100
                                            }%`,
                                        }}
                                    />
                                </div>
                            )}
                            renderThumb={({ props, index }) => (
                                <div
                                    {...props}
                                    className="thumb"
                                    style={{ ...props.style }}
                                >
                                    <div className="thumb-value">
                                        {formatSecondsWithMS(trim[index])}
                                    </div>
                                </div>
                            )}
                        />

                        <div className="slider-labels">
                            {timestamps.map((time, key) => (
                                <span key={key}>{time}</span>
                            ))}
                        </div>
                    </>
                ) : (
                    ""
                )}

                <div className="timer-container">
                    <div className="timers">
                        {" "}
                        <TimerBox
                            time={trim[0]}
                            onChange={handleStartTimeChange}
                            maxTime={trim[1] - 1}
                        />
                        <span>to</span>
                        <TimerBox
                            time={trim[1]}
                            onChange={handleEndTimeChange}
                            maxTime={duration}
                        />
                    </div>

                    <div className="duration-display-container">
                        <span className="duration-label">New Duration:</span>
                        <div className="duration-time">
                            {formatSecondsWithMS(trim[1] - trim[0])}
                        </div>
                    </div>
                </div>

                <div className="modal-actions">
                    <button
                        onClick={() => {
                            cancelTrim();
                        }}
                        className="cancel-button"
                    >
                        Cancel
                    </button>
                    <div className="new-clip-actions">
                        <input
                            type="text"
                            value={newClipName}
                            onChange={(e) => {
                                setNewClipName(e.target.value);
                            }}
                        ></input>
                        <button onClick={handleClip} className="save-button">
                            Save as New Clip
                        </button>
                    </div>

                    <button onClick={handleTrim} className="trim-button">
                        Trim
                    </button>
                </div>
            </div>
            {showMessage.value && (
                <div
                    className={
                        showMessage.success ? "message" : "failed-message"
                    }
                >
                    {showMessage.msg}
                </div>
            )}
        </div>
    );
};

export default TrimModal;

/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch } from "react-router-dom";

// import AuthNavbar from "../components/theme/Navbars/AuthNavbar.js";
import InitialNavbar from "../components/theme/Navbars/InitialNavbar";
import Footer from "../components/theme/Footer/Footer.js";

import routes from "../initial-routes";

var ps;

class Pages extends React.Component {
    componentDidMount() {
        document.body.className = "register-page";
        if (navigator.platform.indexOf("Win") > -1) {
            ps = new PerfectScrollbar(this.refs.fullPages);
        }
    }
    componentWillUnmount() {
        if (navigator.platform.indexOf("Win") > -1) {
            ps.destroy();
        }
    }
    getRoutes = (routes) => {
        return routes.map((prop, key) => {
            if (prop.collapse) {
                return this.getRoutes(prop.views);
            }
            if (prop.layout === "/onboard") {
                return (
                    <Route
                        path={prop.layout + prop.path}
                        component={prop.component}
                        key={key}
                    />
                );
            } else {
                if (prop.layout === "/plans") {
                    return (
                        <Route
                            path={prop.layout + prop.path}
                            component={prop.component}
                            key={key}
                        />
                    );
                }  
                return null;
            }
        });
    };
    render() {
        return (
            <>
            {/* <InitialNavbar/> */}
            <div className="wrapper wrapper-full-page" ref="fullPages">
            
            <div className="" ref="full-page">
                <Switch>{this.getRoutes(routes)}</Switch>
           
             </div>
            
            </div>
            </>
        );
    }
}

export default Pages;

import React, { useState, useEffect ,useContext} from "react";
import { useHistory } from "react-router";

import { 
    CardGroup,
    Card,
    CardImg,
    Alert,
    Spinner
} from 'reactstrap';


import ProjectContext from '../../ProjectContext';
import UserContext from '../../UserContext';

import LazyLoadImage from "./components/LazyLoadImage";

import { template1, template2, templateArr, getAppIcon } from "./webstory-config";
import webstoryService from "../../services/api/web-story-service";

import './storyBuilder.css';


const StoryTemplate = () => {

    const history = useHistory();

    // contexts 
    const projectDetails = useContext(ProjectContext);
    const userDetails = useContext(UserContext);
    // states 
    const [alertState, setAlertState] = useState({status:false,message:''});
    const [loading, setLoading] = useState(false);
    const [appIcon, setAppIcon] = useState('');
    // hooks
   
    useEffect(() => {

        (async ()=> {
            const res = await getAppIcon(projectDetails.id);
            if(res && !res.err) {
                setAppIcon(res);
            }else {
                setAlertState({status:true, message:'Update logo at | CUSTOMIZATIONS > GENERAL SETTINGS > DESIGN > APPICON |'})
            }
        })();
        if (!document.body.classList.contains("sidebar-mini")) {
            document.body.classList.toggle("sidebar-mini");
        }
        return () => {
            document.body.classList.toggle("sidebar-mini");
        }

    },[]);

    // utils 
    const createStoryBars = (page_count) => {
        let storyBars = [];
        for (let i = 0; i < page_count; i++) {
            if(i===0){
                storyBars.push(<div key={i} style={{width:(100/(page_count))+'%',height:2,background:'white',marginLeft:5}}></div>);
            } else {
                storyBars.push(<div key={i} style={{width:(100/(page_count))+'%',height:2,background:'white',opacity:0.5,marginLeft:5}}></div>);
            }
        }
        return storyBars;
    }
    const navigate = (url, response={}) =>{
        history.push({
          pathname: url,
          response: response
       });
    }

    // api calls 
    const createNewWebstory = async (template_name)=> {
        setLoading(true);
        const newWebstoryData = await webstoryService.createNewWebstory({
            articleData: {
                project_id:projectDetails.id,
                author:userDetails.name,
                title:'Untitled',
                type : "webstory",
                published :false,
                status: true,
                guid : Date.now()
            }
        });
        if(projectDetails.isContractExpired){
            setLoading(false);
            projectDetails.setContractExpiredAlert(projectDetails);
        }
        else if(!newWebstoryData.status) {
            console.log("in if");
            setTimeout(()=>{
                setLoading(false)
            },2000);
            setAlertState({status:true, message: 'Error adding New Story'})
        } else {
            const newWebstory = newWebstoryData.data;
            console.log(newWebstory);

            for (let index = 1; index <= 4; index++) {
                var newStoryPagePayload = {
                    article_id: newWebstory._id,
                    order: index,
                    template_name: template_name
                }
                const response = await webstoryService.createWebstoryPage(newStoryPagePayload)
                console.log(response);
                
                if(projectDetails.isContractExpired){
                    projectDetails.setContractExpiredAlert(projectDetails);
                }
                else if(!response.status) {
                    setAlertState({status:true, msg:'Failed to Add Story Page'});
                } 
                if(index===4){
                    navigate(`/dashboard/storyboard/${newWebstory.slug}/1/${newWebstory._id}`, {});
                }
            }
        }
    }

    // const getAppIcon = async (projectId) => {
    //     try {
    //         console.log("projectId", projectId);
    //         const res = await webstoryService.getAppIcon({projectId});
    //         if(!res.status|| !res.data) {
    //             console.log("No App Icon");
    //             return; 
    //         }
    //         if(res.data.appicon) {
    //             setAppIcon(res.data.appicon);
    //         }
    //         return;
            
    //     } catch (err){
    //         console.log(":: Failed to Get App Icon ::",err);
    //     }
    // }

    return (
        <div style={{padding:20, paddingTop:0, background:'white'}}>
           
            <h1><b>Choose Template</b></h1>
            {loading?
                <div className="spinner-container">
                    <Spinner className='center-spinner' />
                </div>
                :null}
                <Alert isOpen={alertState.status} color="danger">
                {alertState.message}
                {console.log("alert message: ",alertState)}
            </Alert>
            <CardGroup className="justify-content-around w-100" >
                
            {templateArr.map((template,index)=>(
                <div key={index} className=" col-8 col-md-5 col-lg-2 m-4 template" >
                    <Card  
                        style={{ boxShadow: '0 6px 10px -4px rgb(0 0 0)'}}
                        className="story-page-container template-card"
                        onClick={()=>createNewWebstory(template.name)}
                    >
                        
                        {template.media_type? 
                        <video style={{objectFit:'cover', height:'100%'}} className='videoTag' autoPlay loop muted>
                            <source src={template.image} type='video/mp4' />
                        </video>:
                        // <CardImg
                        //     alt="Card image cap"
                        //     src={template.image}
                        //     top
                        //     height="100%"
                        //     width="100%"
                        //     style={{objectFit:'cover'}}
                        //     loading="lazy"
                        // />
                        <LazyLoadImage
                            key={`${template}${index}`}
                            url={template.image}
                        />
                        }
                        
                         <div style={{width:'100%',position: "absolute",top:14 ,display:"flex",padding: '0 10px'}} >
                            {createStoryBars(5)}
                        </div>
                        <div style={template.css.containerCss} className='story-content-container'>
                            { template.css.logoCss ?
                                    <img style={template.css.logoCss} src={appIcon? appIcon:template.logo}/>
                                :null}
                            <div className="headline" style={template.css.headlinesCss} dangerouslySetInnerHTML={{__html: template.title}}></div>  
                        </div>
                    </Card>
                </div>
            ))}
            
            </CardGroup>
        </div>
    )
}

export default StoryTemplate;

// const templates = [
//     {
//         title:'<p>Lorem Ipsum new dummy text... </p>',
//         image: template1Image,
//         name:'default', 
//         font:'',
//         css:  {
//             fontSize: '1em',
//             color:'white',
//             width: '100%',
//             background: 'linear-gradient(0deg, rgb(0, 0, 0) 16%, rgb(0 0 0 / 10%) 100%)',
//             minHeight:'30%',
//             padding:10,
//             borderRadius: '0 0 12px 12px',
//             textTransform:"uppercase",
//             fontWeight:"bolder"
//         }
//     },
//     {
//         title:'<p>Lorem Ipsum new dummy text... </p>',
//         image: template2Image,
//         name:'hagrid', 
//         font:'',
//         css:  {
//             "minHeight":"24%",
//             "fontSize":15,
//             "color":"white",
//             "width":"90%",
//             "background":"rgba(208, 0, 0, 0.8)",
//             "padding":10,
//             "bottom":10,
//             "borderRadius":5,
//             "textTransform":"uppercase",
//             "fontWeight":"bolder",
//             "bottom":10,
//             "left":0,
//             "right":0,
//             "margin":'auto'
//         }
//     }
// ]
import React, { useEffect, useState, useContext } from "react";
import {
    Card,
    CardTitle,
    CardHeader,
    CardBody,
    Row,
    Col,
    Button,
    Label,
    Input,
    Spinner,
    Alert,
    Table,
} from "reactstrap";

import './ctv.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SortableTree from "react-sortable-tree";
import { faTrash, faEdit, faTrashRestoreAlt, faFilm } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from "react-router-dom";
import ctvAppService from '../../services/api/ctv-app-service';
import ProjectContext from "../../ProjectContext";
import ReactBSAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import Pagination from "react-js-pagination";
import moment from "moment";
import ReactTooltip from 'react-tooltip';
const ManageCategories = (props) => {
    const projectDetails = useContext(ProjectContext);
    const history = useHistory();
    if (projectDetails.id == '') {
        history.push("/project/allprojects");
    }
    const projectId = projectDetails.id;

    const [treeData, setTreeData] = useState([]);
    const [categories, setCategories] = useState([]);
    const [formDetails, setFormDetails] = useState({ projectId: projectId, status: 1 });
    const [showSavedButton, setShowSaveButton] = useState(true);
    const [showLoader, setShowLoader] = useState(false);
    const [alert, setAlert] = useState({ show: false, type: "", msg: "" })
    const [reloadComp, setReloadComp] = useState(0);
    const [renderComp, setRenderComp] = useState(false);
    const [showPopup, setShowPopup] = useState({ alert: null })
    const records = 10;
    const [totalCategories, setTotalCategories] = useState(0);
    const [activePage, setActivePage] = useState(1);
    const statusArray = [
        { value: 1, label: "Active" },
        { value: 0, label: "Deleted" },
    ]
    const contentTypeObject = {
        1: "Video",
        2: "Shorts",
    }

    useEffect(() => {
        (async () => {
            const catList = await ctvAppService.getVideoCategories({ ...formDetails, activePage, records });
            if (catList.status && catList.data) {
                setCategories(catList.data.catList);
                setTotalCategories(catList.data.totalCount);
            }
            setRenderComp(true)
        })();
    }, [activePage, reloadComp])

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
    }

    const updateCategory = async (element) => {
        console.log("element: ", element);
        history.push('/ctv/edit-category?category=' + element.cat_guid);
    }

    const deleteCategoryPopup = async (element) => {
        setShowPopup({
            alert: (
                <ReactBSAlert
                    warning
                    title="Are you sure?"
                    onConfirm={(event) => deleteCategory(element)}
                    onCancel={() => hideAlert()}
                    confirmBtnBsStyle="info"
                    cancelBtnBsStyle="danger"
                    confirmBtnText="Yes, delete it!"
                    cancelBtnText="Cancel"
                    showCancel
                >
                    You want to delete this category!
                </ReactBSAlert>
            )
        })
    }

    const restoreWithParentCategoryPopup = async (element) => {
        setShowPopup({
            alert: (
                <ReactBSAlert
                    warning
                    title="Parent Category not synced!"
                    onConfirm={() => hideAlert()}
                    confirmBtnBsStyle="info"
                    confirmBtnText="Ok"
                >
                    {`Please restore category - ${element.name}`}
                </ReactBSAlert>
            )
        })
    }

    const restoreCategoryPopup = async (element) => {
        console.log("element in restoreCategoryPopup: ",element);
        if (element.parent_id && !element.parent_id.status) {
            await restoreWithParentCategoryPopup(element.parent_id);
        } else {
            setShowPopup({
                alert: (
                    <ReactBSAlert
                        warning
                        title="Are you sure?"
                        onConfirm={(event) => restoreCategory(element)}
                        onCancel={() => hideAlert()}
                        confirmBtnBsStyle="info"
                        cancelBtnBsStyle="danger"
                        confirmBtnText="Yes, restore it!"
                        cancelBtnText="Cancel"
                        showCancel
                    >
                        You want to restore this category!
                    </ReactBSAlert>
                )
            })
        }
    }

    const restoreCategory = async (element) => {
        const delCategory = await ctvAppService.updateCategoryStatus({ project_id: projectId, category: [element], status: 1 });
        if (delCategory.status && delCategory.data) {
            setShowPopup({
                alert: (
                    <ReactBSAlert
                        success
                        title="Restored!"
                        onConfirm={() => hideAlert()}
                        onCancel={() => hideAlert()}
                        confirmBtnBsStyle="info"
                    >
                        Category Restored successfully
                    </ReactBSAlert>
                )
            })
        } else {
            setShowPopup({
                alert: (
                    <ReactBSAlert
                        danger
                        title="Please try again!"
                        onConfirm={() => hideAlert()}
                        onCancel={() => hideAlert()}
                        confirmBtnBsStyle="info"
                    >
                        Category deleted successfully
                    </ReactBSAlert>
                )
            })
        }
        setReloadComp(Math.random());

    }

    const deleteCategory = async (element) => {
        const delCategory = await ctvAppService.updateCategoryStatus({ project_id: projectId, category: [element], status: 0 });
        if (delCategory.status && delCategory.data) {
            setShowPopup({
                alert: (
                    <ReactBSAlert
                        success
                        title="Deleted!"
                        onConfirm={() => hideAlert()}
                        onCancel={() => hideAlert()}
                        confirmBtnBsStyle="info"
                    >
                        Category deleted successfully
                    </ReactBSAlert>
                )
            })
        } else {
            setShowPopup({
                alert: (
                    <ReactBSAlert
                        danger
                        title="Please try again!"
                        onConfirm={() => hideAlert()}
                        onCancel={() => hideAlert()}
                        confirmBtnBsStyle="info"
                    >
                        Category deleted successfully
                    </ReactBSAlert>
                )
            })
        }
        setReloadComp(Math.random());

    }

    const hideAlert = () => {
        setShowPopup({
            alert: null
        })
    }


    const handleSelectChange = (event) => {
        console.log("event: ", event);
        setFormDetails({
            ...formDetails,
            status: event.value,
        })
        setReloadComp(Math.random());
    }
    const goToVideosListing = (category) => {
        props.setCategory([category])
        props.updateActiveTab('videos')
    }
    let categoryElements = [];
    let firstRecord = 0;
    let lastRecord = 0;
    if (!categories || !categories.length) {
        if (!renderComp) {
            categoryElements.push(
                <td colSpan="4" className="text-center">
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </td>
            );
        } else {
            categoryElements.push(
                <td colSpan="4" className="text-center">
                    <CardTitle tag="h4" className="boRslt">No Categories Found</CardTitle>
                </td>
            );
        }
    } else {
        let i = 1;
        categories.forEach(function (categoryDetails) {
            let className = '';
            if (!categoryDetails.status) {
                className = "table-danger"
            }
            if (i === 1) {
                firstRecord = (activePage - 1) * records + i;
            }
            const createdAtTime = moment(categoryDetails.createdAt);

            categoryElements.push(
                <tr id={"row_" + categoryDetails._id} className={className}>
                    <td className="text-left">{firstRecord - 1 + i}</td>
                    <td className="text-left">{categoryDetails.name}</td>
                    <td className="text-left">{categoryDetails.alias}</td>
                    <td className="text-left">{categoryDetails.parent_id ? categoryDetails.parent_id.name : "----"}</td>
                    <td className="text-left">{
                        categoryDetails.content_type ? 
                            contentTypeObject[categoryDetails.content_type] 
                        : 
                            "Video"
                        }
                    </td>
                    <td className="text-left">
                        {createdAtTime.format('h:mm:ss a')}<br />
                        {createdAtTime.format('Do MMM YYYY')}

                    </td>
                    <td className="text-left">{categoryDetails.status ?
                        <Row>
                            <div className="contntLibBtn">
                                <Col>
                                    <FontAwesomeIcon icon={faEdit} onClick={(event) => updateCategory(categoryDetails)} style={{ cursor: "pointer" }} data-tip="Edit" />

                                </Col>
                                <Col>
                                    <FontAwesomeIcon icon={faTrash} onClick={(event) => deleteCategoryPopup(categoryDetails)} style={{ cursor: "pointer" }} data-tip="Delete" />
                                </Col>
                                <Col>
                                    <FontAwesomeIcon icon={faFilm} onClick={(event) => goToVideosListing(categoryDetails._id)} style={{ cursor: "pointer" }} data-tip="Videos Listing" />

                                </Col>
                                <ReactTooltip effect="solid" />
                            </div>

                        </Row>
                        :
                        <Row>
                            <Col>
                                <FontAwesomeIcon icon={faTrashRestoreAlt} onClick={(event) => restoreCategoryPopup(categoryDetails)} style={{ cursor: "pointer" }} data-tip="Restore" />
                                <ReactTooltip effect="solid" />

                            </Col>

                        </Row>
                    }</td>
                </tr>
            )
            if (i === categories.length) {
                lastRecord = (activePage - 1) * records + i;
            }
            i++;
            if (i === categories.length) {
                lastRecord = (activePage - 1) * records + i;
            }
        })
    }
    return (
        <div className="container mt-30">
            {/* <Card> */}
            {showPopup.alert}
            <CardHeader>
                <div className="ctvCatHeader">
                    <Row>
                        <Col md="4">
                            <label className="ctvCategories">Categories</label>
                        </Col>
                        <Col md="2">
                            <Select
                                className="react-select primary"
                                classNamePrefix="react-select"
                                id="parent_id"
                                name="parent_id"
                                options={statusArray}
                                value={formDetails.status !== "" ? statusArray.filter(option => option.value === formDetails.status) : ""}
                                onChange={handleSelectChange}
                            />

                        </Col>
                        <Col md="2">
                            <Button
                                type="button"
                                className="btnBlue btn btn-secondary"
                                onClick={(event) => history.push(`/ctv/add-category?project=${projectDetails.slug}`)}
                            >+Add Category</Button>
                        </Col>
                        <Col md="2">
                            <Button
                                type="button"
                                className="btnBlue btn btn-secondary"
                                onClick={(event) => history.push(`/ctv/reorder-category?project=${projectDetails.slug}`)}
                            >
                                Reorder Category
                            </Button>
                        </Col>
                    </Row>

                </div>
                <hr />
            </CardHeader>

            <CardBody>
                {totalCategories > 0 ? 
                    <Row>
                        <Col>
                            <div className="pagNo">
                                {firstRecord} -{lastRecord} of {totalCategories}
                            </div>
                        </Col>
                        <Col>
                            <div className="pagBox">
                                <div className="pagDt">
                                    <Pagination
                                        activePage={activePage}
                                        itemsCountPerPage={records}
                                        totalItemsCount={totalCategories}
                                        pageRangeDisplayed={3}
                                        onChange={handlePageChange.bind(this)}
                                        itemClass="page-item"
                                        linkClass="page-link"
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                : null}
                <Row>
                    <Col>
                        <Table responsive hover>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Category Name</th>
                                    <th>Category Alias</th>
                                    <th>Parent Category</th>
                                    <th>Content Type</th>
                                    <th>Created On</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {categoryElements}
                            </tbody>
                        </Table>
                    </Col>
                </Row>

            </CardBody>
            <CardTitle>

            </CardTitle>
            {/* </Card> */}
        </div>
    )
}

export default ManageCategories;
import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import notificationsService from "../../services/api/notifications-service";
import projectsService from "../../services/api/projects-service";
import ProjectContext from '../../ProjectContext'
import List from "./List"
import Stats from "./Stats"
import './notificaton.css';
import IzootoCard from "./IzootoCard";
import MoengageCard from "./MoengageCard";
import FirebaseCard from "./FirebaseCard";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


// reactstrap components
import {
  Spinner,
  Container,
  Row,
  Col,
} from "reactstrap";
const Dashboard = (props) => {
  const projectDetails = useContext(ProjectContext);
  const history = new useHistory();
  if (projectDetails.id == '') {
    history.push("/project/allprojects");
  }
  const projectId = projectDetails.id;
  const [renderComponent, setRenderComponent] = useState(false);
  const [messageService, setMessageService] = useState({});
  const [propsState, setPropsState] = useState({ ...props });
  const [izootoPlatform, setIzootoPlatform] = useState([]);
  const [firebasePlatform, setFirebasePlatform] = useState([]);
  const [moengagePlatform, setMoengagePlatform] = useState([]);
  const [categories, setCategories] = useState([])
  let getProjectSlug = new URLSearchParams(props.location.search).get('project');
  if (!getProjectSlug) {
    history.push({
      search: '?project=' + projectDetails.slug
    })
  }
  useEffect(() => {
    (async () => {
      let domainData = await projectsService.verifyPublicHostSetup(projectDetails.id);
      if (!domainData.status && !domainData.data) {
        toast.info("Public host not  setup for Web Notifications.",{ autoClose: false , theme:"colored" })
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      let getProvider = await notificationsService.getProvider(projectId);
      if (getProvider.status && getProvider.data) {
        let getMessageService = await notificationsService.getNotificationMessageService(projectId)
        setMessageService(getMessageService.data.data)
        if (getMessageService.status && getMessageService.data) {
          const data = getMessageService.data.data;
          const platformArrays = {};
          for (const [key, value] of Object.entries(data)) {
            if (platformArrays[value]) {
              platformArrays[value].push(key);
            } else {
              platformArrays[value] = [key];
            }
          }
          console.log("platformArrays : ", platformArrays);
          setIzootoPlatform(platformArrays['izooto']);
          setMoengagePlatform(platformArrays['moengage']);
          setFirebasePlatform(platformArrays['firebase'])
        }

        setRenderComponent(true)
      } else {
        history.push("/notifications/provider");
      }
    })();
  }, []);
  useEffect(() => {
    (async () => {
      let getCategories = await notificationsService.getCategories(projectId)
      if (getCategories && getCategories.data) {
        setCategories(getCategories.data)
      }
    })()
  }, [])

  return (
    <div className="content mt-30">
      <Container>
        {renderComponent ?
          <Row>
            <Stats />

            <Col className="ml-auto mr-auto" md="5">
              {(firebasePlatform && firebasePlatform.length > 0) ?
                <FirebaseCard {...propsState} platform={firebasePlatform} categories={categories} />
                : ""}
              {(izootoPlatform && izootoPlatform.length > 0) ?
                <IzootoCard {...propsState} platform={izootoPlatform} categories={categories} />
                : ""}
              {(moengagePlatform && moengagePlatform.length > 0) ?
                <MoengageCard {...propsState} platform={moengagePlatform} categories={categories} />
                : ""}
            </Col>
            <Col className="ml-auto mr-auto" md="7">
              <List />
            </Col>
          </Row>
          :
          <Row>
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </Row>

        }
      </Container>
      <div
        className="full-page-background"
        style={{
          backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
        }}
      />
      <ToastContainer position='bottom-right' />


    </div>
  );

}

export default Dashboard;

import React, { useEffect, useState, useContext } from "react";

import { useHistory } from "react-router-dom";
import ProjectContext from "../../ProjectContext";
import projectsService from "../../services/api/projects-service";
import featuresService from "../../services/api/features-service";
import FeatureConfigForm from "./FeatureConfigForm";
import FeatureConfig from "./FeatureConfig";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Alert,
  Spinner,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import Switch from "react-bootstrap-switch";
import ReactBSAlert from "react-bootstrap-sweetalert";
import "./Features.css";
const Features = (props) => {
  const projectDetails = useContext(ProjectContext);
  const history = new useHistory();
  if (projectDetails.id == "") {
    history.push("/project/allprojects");
  }
  const projectId = projectDetails.id;
  const [formdetails, setFormDetails] = useState({ projectid: projectId });
  const [showLoader, setShowLoader] = useState(false);
  const [showSaveButton, setShowSaveButton] = useState(true);
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });
  const [features, setFeatures] = useState(false);
  const [showPopup, setShowPopup] = useState({ alert: null });
  const [renderComponent, setRenderComponent] = useState(false);
  const [displayDependentFields, setDisplayDependentFields] = useState(false);
  const [dependentFieldsFilled, setDependentFieldsFilled] = useState(false);
  const [currentFeatureName, setCurrentFeatureName] = useState("");
  const [currentFeatureValue, setCurrentFeatureValue] = useState(false);
  const [currentFeatureDisplayName, setCurrentFeatureDisplayName] = useState("")
  useEffect(() => {
    (async () => {
      setShowLoader(true);
      let getFeatures = await featuresService.getProjectFeaturesInfo(projectId);
      if (getFeatures.status) {
        if (getFeatures.data) {
          let features = getFeatures.data;

          setFeatures(features);
          let featuresStatus = {};
          Object.keys(features).forEach((name) => {
            featuresStatus[name] = features[name].status;
          });
          setFormDetails({
            ...formdetails,
            ...featuresStatus,
          });
        } else {
          setFormDetails({
            ...formdetails,
          });
        }
      } else {
        history.push("/project/projects");
      }
      setShowLoader(false);
      setRenderComponent(true);
    })();
  }, []);
  const onCancel = () => {
    // dependentFieldsFilled(true)
    setDisplayDependentFields(false);
  }
  const handleFeatureConfigSave = async (isSaved) => {
    // setalert({isSaved})
    setShowLoader(true);
    setDependentFieldsFilled(true);
    // dependentFieldsFilled(true)
    setDisplayDependentFields(false);

    let response = await featuresService.updateProjectSettingFeature(
      projectId,
      currentFeatureName,
      currentFeatureValue
    );
    if(projectDetails.isContractExpired){
      setShowLoader(false);
      setShowSaveButton(true);
      projectDetails.setContractExpiredAlert(projectDetails);
    }
    else if (response.status) {
      setFormDetails({
        ...formdetails,
        [currentFeatureName]: currentFeatureValue,
      });
      setTimeout(() => {
        setShowLoader(false);
        setShowSaveButton(true);
        setAlert({
          show: true,
          type: "success",
          message: "Features were saved successfully",
        });
      }, 1000);
    } else {
      setTimeout(() => {
        setShowLoader(false);
        setShowSaveButton(true);
        setAlert({
          show: true,
          type: "danger",
          message: "Features could not be saved, Please try again.",
        });
      }, 1000);
    }

    setTimeout(() => {
      setAlert({ show: false, type: "", message: "" });
    }, 3000);
  };

  const handleSwitchChange = async (fieldname, fieldvalue) => {
    console.log(fieldname,formdetails[fieldname],fieldvalue);
      setCurrentFeatureName(fieldname);
      setCurrentFeatureValue(fieldvalue);
      setCurrentFeatureDisplayName(features[fieldname]["description"])
    if (fieldvalue === true) {
      setDependentFieldsFilled(false);
      setDisplayDependentFields(true);
      
    } else if(formdetails[fieldname]!=fieldvalue){
      setShowLoader(true);
      setShowSaveButton(false);
      setDisplayDependentFields(false);
      let response = await featuresService.updateProjectSettingFeature(
        projectId,
        fieldname,
        fieldvalue
      );
      if(projectDetails.isContractExpired){
        setShowLoader(false);
        setShowSaveButton(true);
        projectDetails.setContractExpiredAlert(projectDetails);
      }
      else if (response.status) {
        setFormDetails({
          ...formdetails,
          [fieldname]: fieldvalue,
        });
        setTimeout(() => {
          setShowLoader(false);
          setShowSaveButton(true);
          setAlert({
            show: true,
            type: "success",
            message: "Features were saved successfully",
          });
        }, 1000);
      } else {
        setTimeout(() => {
          setShowLoader(false);
          setShowSaveButton(true);
          setAlert({
            show: true,
            type: "danger",
            message: "Features could not be saved, Please try again.",
          });
        }, 1000);
      }

      setTimeout(() => {
        setAlert({ show: false, type: "", message: "" });
      }, 3000);
    }
  };

  const showUpgradePlanPopup = async (featureName) => {
    setShowPopup({
      alert: (
        <ReactBSAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Upgrade Plan"
          // title=
          onConfirm={() => {
            history.push("/dashboard/plans");
          }}
          showCancel={false}
          confirmBtnText="Upgrade Plan"
          btnSize=""
        >
          <Label>{`${featureName} is not available in your present plan`}</Label>
          <div
            className="popupCloseBtn"
            onClick={() => {
              hideAlert();
            }}
          >
            X
          </div>
        </ReactBSAlert>
      ),
    });
  };

  const hideAlert = () => {
    setShowPopup({
      alert: null,
    });
  };
  const handleSubmit = function (e) {
    validateForm();
    e.preventDefault();
  };

  const validateForm = async () => {
    let validations = true;
    if (validations) {
      setShowLoader(true);
      setShowSaveButton(false);

      let saveProjectSettings = await projectsService.saveProjectSettings(
        formdetails
      );
      if(projectDetails.isContractExpired){
        setShowLoader(false);
        setShowSaveButton(true);
        projectDetails.setContractExpiredAlert(projectDetails);
      }
      else if (saveProjectSettings.status) {
        setTimeout(() => {
          setShowLoader(false);
          setShowSaveButton(true);
          setAlert({
            show: true,
            type: "success",
            message: "Features were saved successfully",
          });
        }, 1000);
      } else {
        setTimeout(() => {
          setShowLoader(false);
          setShowSaveButton(true);
          setAlert({
            show: true,
            type: "danger",
            message: "Features could not be saved, Please try again.",
          });
        }, 1000);
      }

      setTimeout(() => {
        setAlert({ show: false, type: "", message: "" });
      }, 3000);

      setTimeout(() => {
        history.push("/project/projects");
      }, 2000);
    }
  };

  return (
    <div className="content mt-30">
      {showPopup.alert}
      <Container>
        <Card className="card-signup text-center">
          <CardHeader>
                <CardTitle tag="h4">Features</CardTitle>
          </CardHeader>
          {renderComponent && Object.keys(features).length==0 ? (
                <div className="text-center">
                  No feature is available for your current plan.
                </div>
              ) : (
                ""
              )}
          <Row>
            <Col className="ml-auto mr-auto" md="5">
              
              
              <CardBody>
                
                {Object.keys(features).map((feature_code, index) => (
                  <Row className="line-space">
                    <Label sm="2"></Label>
                    <Col sm="6">
                      <div className="designLabel">
                        <small className="text-default">
                          {features[feature_code]["feature_name"]}
                        </small>
                      </div>
                      <Label className="smLabl-txt">
                        {features[feature_code]["description"]}
                      </Label>
                    </Col>

                    <Col sm="2">
                      <FormGroup>
                        <Switch
                          name={feature_code}
                          offColor="success"
                          offText={<i className="nc-icon nc-simple-remove" />}
                          onColor="success"
                          onText={<i className="nc-icon nc-check-2" />}
                          value={formdetails[feature_code]}
                          onChange={(value, enabled) => {
                            console.log(feature_code,"-->",enabled);
                            // return;
                            handleSwitchChange(feature_code, enabled)
                          }
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                ))}

                {/* {showSaveButton?<Button
                    className="btn-round"
                    color="info"
                    type="button"
                    onClick={validateForm}
                  >
                    Save
                  </Button>:''} */}
                {alert.show ? (
                  <Alert color={alert.type}>{alert.message}</Alert>
                ) : (
                  ""
                )}

                {showLoader ? (
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                ) : (
                  ""
                )}
              </CardBody>
              <CardFooter></CardFooter>
            </Col>
            <Col className="ml-auto mr-auto" md="7">
              {displayDependentFields ? (
                <FeatureConfig
                      handleFeatureConfigSave={handleFeatureConfigSave}
                      featureCode={currentFeatureName}
                      featureName={currentFeatureDisplayName}
                      onCancel = {onCancel}
                    />
              ) : (
                ""
              )}
            </Col>
          </Row>
        </Card>
      </Container>
      <div
        className="full-page-background"
        style={{
          backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
        }}
      />
    </div>
  );
};

export default Features;

import React, { useEffect, useState, useContext } from "react";
import {useHistory} from "react-router-dom";
import { hotjar } from 'react-hotjar';
import Login from "./Login";
import Setup from "./Setup";
import ForgotPassword from "./ForgotPassword";
import Password from "./Password";
import CreateProject from "./CreateProject";
import authService from "../../services/api/auth-service.js";
import projectsService from "../../services/api/projects-service";
import onboardService from "../../services/api/onboard-service";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {useCookies} from 'react-cookie';
import './onboard.css';

// reactstrap components
import {
    Badge,
    Card,
    CardBody,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Row,
    Col,
    Button,
    CardHeader,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Input,
    Alert,
    Spinner,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
} from "reactstrap";


const GetStarted = (props) => {
  const [cookies, setCookies] = useCookies(['wpProjectDetails'])

  const history = new useHistory();
  const [step, setStep] = useState(1);
  const [currentState, setCurrentState] = useState("");
  const [loggedInData,setLoggedInData]  = useState(false)
  const [projectId,setProjectId]  = useState(false)
  const [licenseDetails,setLicenseDetails]  = useState(false)
  const [copied,setCopied]  = useState(false)
  const [copiedMessageState,setCopiedMessageState]=useState(false);
  const [projectDetails,setProjectDetails]=useState({});

  const [renderComponent,setRenderComponent] = useState(false);
  const [publisherId,setPublisherId]  = useState("")
  
  useEffect( () => {
    (async () =>{

        if(currentState!='password')
        {
          let userData = await authService.getLoggedInUser();
          console.log("UserData",userData);
            if(userData.status && userData.data){
              setLoggedInData(userData.data)
              setPublisherId(userData.data.id);
              setCurrentState('loggedin')
              setStep(2)
            }
            setRenderComponent(true)
        }
      
        })();
      
      
        
    }, [currentState]);

    useEffect(() => {
      hotjar.initialize('2731547', '6');
    }, [])

    useEffect(()=>{
     (async () =>{
       if(publisherId)
       {

      let project = await onboardService.getUnusedLicenseProjects({publisher_id:publisherId});
      console.log("recent created Projects:",project);

       if(project.data)
      { 
        setProjectId(project.data);
      }
       //setProjectId("615e812d568897a2e7dd8d36");

       }
     })();
    },[publisherId]);
    useEffect( () => {
      (async () =>{
            if(projectId=="") return;
            let projectDetails=await projectsService.getProjectDetails(projectId);
            if(projectDetails.status && projectDetails.data){
             console.log("Project Details",projectDetails);
           
            setProjectDetails({
            "host":projectDetails.data.host,
            "name":projectDetails.data.name

            });

            setStep(3)

          // setLicenseDetails(`{"secret_key":"${projectDetails.data.secret_key}","access_key":"${projectDetails.data.access_key}"}`);
         setLicenseDetails(JSON.stringify({project_name:projectDetails.data.name,project_id:projectDetails.data.slug,host:projectDetails.data.host,secret_key:projectDetails.data.secret_key,access_key:projectDetails.data.access_key},undefined,4));
          }
            setRenderComponent(true)

          })();
          
      }, [projectId]);

      const openRegistrationPage = () => {
        hotjar.event('registration_open');
        setCurrentState('register')
      }

      const openLoginPage = () => {
        hotjar.event('login_open');
        setCurrentState('login')
      }
  
    return (
      <div className="register-page">
      <div>
          <Row>
            <Col md="12">
            <div class="container_fluid_1">
         <div class="container_1">
            <div class="row_1">
               <div class="col-sm_1">
              
                  <img alt="" class="logo_img_1" src="https://ads.rwadx.com/sortdjson/300x100.svg"/>
                  <div class="content_start">
                     Welcome to the SORTD.
                  </div>
                  <div class="content_start_lists">
                     <ul id="progress">
                        <li class={step===1?"active":"proCompGrn"}>
                           <div class="node grey"></div>
                           <div class="heading_first"> To sync your Wordpress site, create your SORTD account. </div>
                           {loggedInData?
                              
                                    <Badge className="name_ofbadge_span" color="success" pill>
                                      Logged in as {loggedInData.name}
                                    </Badge>
                                  :
                                  <span>
                           <a class="creat_account" href="javascript:;" onClick={openRegistrationPage}> Create Account </a>
                           <div class="click_heretologin"> <a class="creat_account_link" href="javascript:;" onClick={openLoginPage}> Click here </a> to login </div>
                           </span> }
                        </li>
                        <li class={step===1?"":"proCompGrn"}>
                           <div class="divider grey"></div>
                        </li>
                        <li class={step===2?"active":step===3?"proCompGrn":''}>
                           <div class="node grey"></div>
                           <div class="heading_first"> Create a Project to get credentials. </div>
                        </li>
                        <li class={step===3?"proCompGrn":""}>
                           <div class="divider grey"></div>
                        </li>
                        <li class={step===3?"active":""}>
                           <div class="node grey"></div>
                           <div class="heading_first"> Back to Dashboard. </div>
                        </li>
                     </ul>
                  </div>
               </div>
               <div class="col-sm_1">
                  <div class="image_cls">

                  {currentState==''?
                     <div class="text_animation_sortd">
                        <div class="wrapper1">
                           <div class="static-txt">I'm</div>
                           <ul class="dynamic-txts">
                              <li><span>a Publisher.</span></li>
                              <li><span>SORTD.</span></li>
                              <li><span>a Developer.</span></li>
                              <li><span>SORTD.</span></li>
                           </ul>
                        </div>
                     </div>
                     :''}

                     {currentState==''?
                     <span>
                     {!licenseDetails?
                     <></>
                    //  <img alt="" src="http://mcmscache.epapr.in/mcms/515/42b214ebd1ab0c0a8f4541a026a45c1b8bbd7f96.jpg"/>
                     :''}
                     </span>
                     :''}

                     <div class="form_div">
                     {currentState=='register'?<Setup setCurrentState={setCurrentState}/>:
                        currentState=='forgotpassword'?<ForgotPassword setCurrentState={setCurrentState}/>:
                        currentState=='password'?<Password setCurrentState={setCurrentState}/>:
                        currentState=='login'?<Login setCurrentState={setCurrentState}/>:''}

                        {loggedInData?
                          <span>
                         {projectId?'':<CreateProject setProjectId={setProjectId}/>}
                         </span>
                         :''}


                    {licenseDetails?
                    <span class="copy_license_details">
                       
                        <span class="success_text" style={{'font-size':'35px',"font-weight":"500"}}>
                          Credentials
                        </span>
                      <br/>
                        <span class="success_text pn">
                        You will need these credentials  to verify your plan for PWA & AMP on SORTD.
                        </span>
                     
                        <div className="timeline-body" >

                        {/* <div class="disabled_text_body"> */}
                        {/* {licenseDetails} */}
                        {/* XXXXXXXXX
                        </div> */}
                        <div>
                        <textarea rows="7" disabled class="disabled_text_body">
                        {licenseDetails}
                          </textarea>
                        </div>
                        <CopyToClipboard text={licenseDetails}
                          onCopy={() => {setCopied(true);setCopiedMessageState(true);
                          setTimeout(()=>{setCopiedMessageState(false)},5000);
                          }}>
                          <button class="copy_clipboard btn-dfltPlug"><i class="fa fa-clone" aria-hidden="true"></i>
 Copy</button>
                        </CopyToClipboard>

                        {copied ?( copiedMessageState?<span class="copied_infact_message" >Copied</span>:'' ): null}
                        
                        <a class="sortd_1_backtodash btn-dfltPlug" href={cookies["wpProjectDetails"]["callback_url"]}><i class="fa fa-wordpress" aria-hidden="true"></i> Go to Dashboard <b><i class="fa fa-long-arrow-right" aria-hidden="true"></i></b>
</a>
                      
                        </div>
                   
                      </span>:''}

                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      </Col>

      {/* <Col md="12">
              <Card className="card-timeline card-plain">
              <CardHeader>
                <CardTitle tag="h4">
                Welcome, Please follow the steps to create PWA and AMP for your website.
                </CardTitle>
                </CardHeader>
                <CardBody>
                  <ul className="timeline timeline-left">
                    
                    <li className="">
                      <div className="timeline-badge success" style={{height:'auto'}}>
                        <b> 1</b>
                      </div>
                      <div className="timeline-panel">
                        <div className="timeline-heading">
                          <Badge color="success" pill>
                            Create Account
                          </Badge>
                        </div>
                        <div className="timeline-body">
                          {currentState=='register'?<Setup setCurrentState={setCurrentState}/>:
                        currentState=='forgotpassword'?<ForgotPassword setCurrentState={setCurrentState}/>:
                        currentState=='password'?<Password setCurrentState={setCurrentState}/>:
                        currentState=='login'?<Login setCurrentState={setCurrentState}/>:''}
                        </div>
                      </div>
                    </li>

                    {loggedInData?
                    <li className="timeline-inverted">
                      
                      <div className="timeline-panel">
                        <div className="timeline-heading">
                          <Badge color="success" pill>
                            Logged in as {loggedInData.name}
                          </Badge>
                        </div>
                        <div className="timeline-body">
                       
                        </div>
                      </div>
                    </li>:''}

                    <li className="">
                      <div className="timeline-badge warning" style={{height:'auto'}}>
                      <b>2</b>
                      </div>
                      <div className="timeline-panel">
                        <div className="timeline-heading">
                          <Badge color="warning" pill>
                            Create Project
                          </Badge>
                        </div>
                        <div className="timeline-body">
                          <p>
                            You need to create a project to create PWA and AMP for your website.
                          </p>
                          {loggedInData?
                          <span>
                         {projectId?'':<CreateProject setProjectId={setProjectId}/>}
                         </span>
                         :''}
                        </div>
                      </div>
                    </li>
                    {projectId?
                    <li className="timeline-inverted">
                      
                      <div className="timeline-panel">
                        <div className="timeline-heading">
                          <Badge color="success" pill>
                            Project Details
                          </Badge>
                        </div>
                        <div className="timeline-body">
                         Project Name : {projectDetails.name}<br/><br/>
                         Website URL : {projectDetails.host}<br/>
                        </div>
                      </div>
                    </li>:''}





                    <li className="">
                      <div className="timeline-badge danger" style={{height:'auto'}}>
                      <b>3</b>
                      </div>
                      <div className="timeline-panel">
                        <div className="timeline-heading">
                          <Badge color="danger" pill>
                            GO Back to WordPress
                          </Badge>
                        </div>
                        <div className="timeline-body">
                          <p>
                            You need to copy license details to use SORTD Plugin.
                          </p>
                        </div>
                      </div>
                    </li>

                    {licenseDetails?
                    <li className="timeline-inverted">
                      
                      <div className="timeline-panel">
                        <div className="timeline-heading">
                          <Badge color="success" pill>
                            License Details
                          </Badge>
                        </div>
                        <div className="timeline-body">
                       
                         <textarea rows="6" cols="50" style={{border:"groove",resize:"none"}} readonly="readonly" value={JSON.stringify(JSON.parse(licenseDetails),undefined,4)} />
                          
                          <br/>
                        <CopyToClipboard text={licenseDetails}
                          onCopy={() => {setCopied(true);setCopiedMessageState(true);
                          setTimeout(()=>{setCopiedMessageState(false)},5000);
                          }}>
                          <button>Copy to clipboard</button>
                        </CopyToClipboard>

                        {copied ?( copiedMessageState?<span style={{color: 'red'}}>Copied.</span>:'' ): null}<br/><br/>
                        {copied ?
                        <button><a target="_blank" href={cookies["wpProjectDetails"]["callback_url"]}>Go to WordPress</a></button>:''}
                      
                        </div>
                      </div>
                    </li>:''}

                  </ul>
                </CardBody>
              </Card>
                        </Col>*/}
          </Row> 
          </div>
        </div>
    );
};

export default GetStarted;

import React, { useEffect, useState, useContext } from "react";
import featuresService from '../../services/api/features-service';
import ProjectContext from '../../ProjectContext';
import { useHistory } from "react-router-dom";
import Content from './Components/Content';
import {
    Card,
    CardTitle,
    CardHeader,
    CardBody,
    Row,
    Col,
    Button,
    Label,
    Spinner,
} from "reactstrap";

import './ctv.css'

const Dashboard = (props) => {
    const history = new useHistory();
    const projectDetails = useContext(ProjectContext);
    console.log("projectDetails: ",projectDetails);
    if(projectDetails.id ===''){
        history.push("/project/allprojects");
    }
    const projectId = projectDetails.id;
    const [isCTVAppsEnabled, setIsCTVAppsEnabeld] = useState(false);
    const [showLoader,setShowLoader] = useState(true);
    let getProjectSlug = new URLSearchParams(props.location.search).get('project');
    if (!getProjectSlug) {
        history.push({
            search: '?project=' + projectDetails.slug
        })
    }
    useEffect(()=>{
        (async()=>{
            if(projectId){
                const featureStatus = await featuresService.getFeatureStatus('enable_ctv',projectId);
                if(featureStatus.status && featureStatus.data){
                    setIsCTVAppsEnabeld(featureStatus.data.enable_ctv);
                    setShowLoader(false);
                }
            }
        })();
    },[projectId])

    if(isCTVAppsEnabled){
        return <Content {...props}/>
    }else{
        return (
            // <div className="content mt-10">
            <div className="container mt-30">
                    {showLoader ? 
                        <Card>
                            <CardBody>
                                <Spinner animation="border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </Spinner>
                            </CardBody>
                        </Card>
                    :
                    
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <Label className="ctvHeading"><b>Contact Us: helpdesk@sortd.mobi</b></Label>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <div className="ctvInfo">
                                <Row>
                                    <Col>
                                        <h2>Sortd Connected TV</h2>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="7">
                                        Now Create and manage your Connected TV App through one single platform.
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="7">
                                        SORTD Connected TV supports Youtube and Native Videos. Not only this, you can now monetize your videos as well.
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="7">
                                        Book a Demo now.
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Button
                                            type="button"
                                            className="btnBlue btn btn-secondary"
                                            onClick={(event) => {
                                                window.open('https://mail.google.com/mail/?view=cm&fs=1&to=helpdesk@sortd.mobi', '_blank');
                                                event.preventDefault();
                                            }
                                        }
                                        >
                                            Book Now
                                        </Button>
                                    </Col>
                                </Row>
        
                            </div>
        
                        </CardBody>
                    </Card>
                    }
            </div>
    
            // </div>
        )

    }
}

export default Dashboard;
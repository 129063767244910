import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";



const RwPopup = (props)=> {
  

  function handleChange(event) {
    props.setImportrwdata({
      ...props.importrwdata,
      [event.target.id]: event.target.value,
    });
  }

    return (
      <div className="register-page">
        <Container>
          <Row>
            <Col className="ml-auto" lg="2" md="2">
              </Col>
            <Col className="mr-auto" lg="4" md="6">
              <Card className="card-signup text-center">
                <CardHeader>
                  <CardTitle tag="h4">You are a readwhere user. Do you want to import existing data from Readwhere?</CardTitle>
                  
                </CardHeader>
                <CardBody>
                  <Form action="" className="form" method="" onSubmit={props.submitRwdata}>
                    
                  <FormGroup check className="mt-3">
                      <FormGroup check>
                        <Label check>
                          <Input id="importrwdata" name="importrwdata" defaultValue="" type="checkbox" onChange={handleChange} />
                          Import Data from Readwhere
                          <span className="form-check-sign" />
                        </Label>
                      </FormGroup>
                    </FormGroup>

                    <Button
                    className="btn-round"
                    color="info"
                    type="submit"
                  >
                    Submit
                  </Button>
                    
                  </Form>
                </CardBody>
                <CardFooter>
                  
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
          }}
        />
      </div>
    );
  
}

export default RwPopup;

import React, { useEffect, useState, useContext } from "react";
import formValidations from "../../variables/form-validations";
import onboardService from "../../services/api/onboard-service";
import projectsService from "../../services/api/projects-service";
import TimezoneSelect from "react-timezone-select";
import { useCookies } from "react-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import "./plan.css";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  CardText,
  CardImg,
  Label,
  FormGroup,
  Form,
  Input,
  Alert,
  Spinner,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

import Select from "react-select";
import planService from "../../services/api/plan-service";
import ProjectContext from "../../ProjectContext";
import authService from "../../services/api/auth-service";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { useLocation } from 'react-router-dom';

const Checkout = (props) => {
  let showWarning = true;
  const projectDetails = useContext(ProjectContext);
  console.log("handlePurchaseprops state",props.location);
  if(props.location.state === "buyPlan") {
    console.log("props.location.state",props.location.state);
    showWarning = false;
  }
  const history = new useHistory();
  const planId = new URLSearchParams(props.location.search).get("planId");
  const projectId = new URLSearchParams(props.location.search).get("projectId");
  const couponCode = new URLSearchParams(props.location.search).get('couponCode');
  let getProjectSlug = new URLSearchParams(props.location.search).get(
    "project"
  );
  if (!getProjectSlug) {
    history.push({
      search: "?project=" + projectDetails.slug,
    });
  }
  const [planFeatures, setPlanFeatures] = useState([]);
  const [planPrice, setPlanPrice] = useState({
    currency: "",
    base_price: "",
    discount: "",
    duration: "",
    duration_unit: "",
  });
  const [planDetails, setPlanDetails] = useState({});
  const [componentRendered, setComponentRendered] = useState(false);
  const [showPopup, setShowPopup] = useState({ alert: null });
  const [showLoader, setShowLoader] = useState(false);
  const [showLoaderAtButton, setShowLoaderAtButton] = useState(false);

  const [showProceedButton, setShowProceedButton] = useState(true);
  const [couponApplied, setCouponApplied] = useState(false);
  const [priceAfterDiscount, setPriceAfterDiscount] = useState(false);
  const [isPlanRenewed, setIsPlanRenewed] = useState(false);
  useEffect(() => {
     (async ()=>{
      const response = await planService.isPlanRenewed(projectDetails.id);
      if(response.status){
        if(response.data) {
          setIsPlanRenewed(true);
        }else {
          setIsPlanRenewed(false);
        }
      } 
     })();
  },[]);
  useEffect(() => {
    (async ()=> {
      if(couponCode) {
        const coupon = await planService.validateCoupon({projectId, coupon_code : couponCode,planId});
        if(coupon.status) {
        if(coupon.data) {
            setCouponApplied(coupon.data);
            // setCouponCodeState('has-danger');
            // setCouponCodeError("Coupon Applied");
        }else{
        }
      }
      }
    })();
  },[]);
  useEffect(() => {
    (async () => {
      setShowLoader(true);
      const response = await planService.getPlan(planId);
      if (response.status && response.data) {
        setPriceAfterDiscount(response.data.planPrice.base_price - response.data.planPrice.discount);
        setPlanPrice({ ...response.data.planPrice });
        setPlanDetails({ ...response.data.planDetails });
        let tempFeatures = [];
        if (response.data.planFeatures && response.data.planFeatures.length) {
          response.data.planFeatures.forEach((feature) => {
            tempFeatures.push({
              name: feature.feature_id.name,
              quota: feature.feature_quota || "",
            });
          });
          setPlanFeatures(tempFeatures);
        }
      }
      setShowLoader(false);
      setComponentRendered(true);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.async = true;
      document.body.appendChild(script);
    })();
  }, []);
  const hideAlert = () => {
    setShowPopup({
      alert: null,
    });
  };
  const handleOnPurchase = async (projectId, planId) => {
    if(isPlanRenewed) {
      setShowPopup({
              alert: (
                <ReactBSAlert
                  info
                  style={{ display: "block", marginTop: "-100px" }}
                  title="Warning"
                  onConfirm={hideAlert}
                  confirmBtnBsStyle="info"
                  confirmBtnText = "Ok"
                  showConfirm = {true}
                  showCancel = {false}
                  btnSize=""
                >
                  You have already renewed your contract.
                </ReactBSAlert>
              ),
          });
      return false;
    }
    const isPlanCompitibleForProject = await projectsService.isPlanCompatibleForProject(projectId, planId);
    if(isPlanCompitibleForProject.status && isPlanCompitibleForProject.data){
        let response = isPlanCompitibleForProject.data;
        if(response.status === 'compatible') {
          await processPayment(projectId, planId); //if compatible start payment process
        }else if(response.status === 'warning'){
          setShowPopup({
            alert: (
              <ReactBSAlert
                info
                style={{ display: "block", marginTop: "-100px" }}
                title="Warning"
                onConfirm={()=>handleConfirm(projectId, planId)}
                onCancel={() => hideAlert()}
                confirmBtnBsStyle="info"
                confirmBtnText = "Continue"
                showConfirm = {true}
                showCancel = {true}
                btnSize=""
              >
                  You have exhausted more than 85 % of the plan limit. We suggest you to switch to a higher plan.
              </ReactBSAlert>
            ),
        });
        }else if(response.status === 'error') {
          setShowPopup({
            alert: (
              <ReactBSAlert
                danger
                style={{ display: "block", marginTop: "-100px" }}
                title="Error"
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnBsStyle="info"
                btnSize=""
              >
                You have exhausted your plan limits. Please switch to a higher plan.
              </ReactBSAlert>
            ),
        });
        }
    }
    return false;
  }
  const handleConfirm = async (projectId, planId) => {
    hideAlert();
    await processPayment(projectId, planId);
  }
  const processPayment = async (projectId, planId) => {
    setShowLoaderAtButton(true);
    setShowProceedButton(false);

    let userDetails = await authService.getLoggedInUser();
    console.log(userDetails);
    let planOrderData = await planService.createPlanOrder(
      projectId,
      planId,
      "Razorpay",
      couponApplied.coupon_code
    );
    if (planOrderData.status && planOrderData.data) {
      let orderData = planOrderData.data;
      if (orderData === "payments_disabled") {
        setShowPopup({
          alert: (
            <ReactBSAlert
              danger
              style={{ display: "block", marginTop: "-100px" }}
              title="Payments are not enabled. Please try later!"
              onConfirm={() => hideAlert()}
              onCancel={() => hideAlert()}
              confirmBtnBsStyle="info"
              btnSize=""
            >
              Payments are not enabled. Please try later!
            </ReactBSAlert>
          ),
        });

        setTimeout(() => {
          hideAlert();
        }, 3000);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
        return;
      }

      console.log("PlanData:", planOrderData.data);
      var options = {
        key: orderData.razorpay_key_id,
        amount: orderData.amount, // 2000 paise = INR 20, amount in paisa
        name: orderData.name,
        description: orderData.description,
        order_id: orderData.order_id,
        handler: async function (response) {
          console.log("Handler", response);
          var values = {
            razorpay_signature: response.razorpay_signature,
            razorpay_order_id: response.razorpay_order_id,
            razorpay_payment_id: response.razorpay_payment_id,
            amount: orderData.amount,
            transaction_id: orderData.transaction_id,
          };

          let planVerifyPayment = await planService.verifyPlanPayment(
            projectId,
            planId,
            values
          );
          if (planVerifyPayment.status && planVerifyPayment.data.verified) {
            // alert("success")
            setShowPopup({
              alert: (
                <ReactBSAlert
                  success
                  style={{
                    display: "block",
                    marginTop: "-100px",
                  }}
                  title="Payment Successfull!"
                  onConfirm={() => hideAlert()}
                  onCancel={() => hideAlert()}
                  confirmBtnBsStyle="info"
                  btnSize=""
                >
                  Payment Done successfully.
                </ReactBSAlert>
              ),
            });

            setTimeout(() => {
              hideAlert();
              //  history.push("/plan/details");
              window.location.href =
                "/plan/details?project=" + projectDetails.slug;
            }, 2000);
          } else {
            // alert("failure")
            setShowPopup({
              alert: (
                <ReactBSAlert
                  danger
                  style={{
                    display: "block",
                    marginTop: "-100px",
                  }}
                  title="Payment Failed!"
                  onConfirm={() => hideAlert()}
                  onCancel={() => hideAlert()}
                  confirmBtnBsStyle="info"
                  btnSize=""
                >
                  Payment Failed.
                </ReactBSAlert>
              ),
            });
            setTimeout(() => {
              hideAlert();
            }, 3000);
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          }
        },
        prefill: {
          name: userDetails.data.id,
          email: userDetails.data.email,
          contact: "",
        },
        notes: {
          address: "",
          payment_for : "sortd_plans"
        },
        theme: {
          color: "#528ff0",
        },
        modal: {
          ondismiss: function () {
            setShowPopup({
              alert: (
                <ReactBSAlert
                  danger
                  style={{
                    display: "block",
                    marginTop: "-100px",
                  }}
                  title="Transaction Failed!"
                  onConfirm={() => hideAlert()}
                  onCancel={() => hideAlert()}
                  confirmBtnBsStyle="info"
                  btnSize=""
                >
                  Payment Failed.
                </ReactBSAlert>
              ),
            });
            setTimeout(() => {
              hideAlert();
            }, 3000);
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          },
        },
      };
      setShowLoaderAtButton(false);
      setShowProceedButton(true);
      console.log(options);
      var rzp1 = new window.Razorpay(options);
      rzp1.open();
      rzp1.on("payment.failed", async function (response) {
        // alert(response.error.code);
        // alert(response.error.description);
        // alert(response.error.source);
        // alert(response.error.step);
        // alert(response.error.reason);
        // alert(response.error.metadata.order_id);
        // alert(response.error.metadata.payment_id);
        rzp1.close();
        setShowPopup({
          alert: (
            <ReactBSAlert
              danger
              style={{ display: "block", marginTop: "-100px" }}
              title="Transaction Failed!"
              onConfirm={() => hideAlert()}
              onCancel={() => hideAlert()}
              confirmBtnBsStyle="info"
              btnSize=""
            >
              Payment Failed.
            </ReactBSAlert>
          ),
        });
        var values = {
          razorpay_signature: "",
          razorpay_order_id: "",
          razorpay_payment_id: "",
          amount: "",
          transaction_id: orderData.transaction_id,
        };

        await planService.verifyPlanPayment(projectId, planId, values);

        setTimeout(() => {
          hideAlert();
        }, 3000);
      });
    } else {
      setShowPopup({
        alert: (
          <ReactBSAlert
            danger
            style={{ display: "block", marginTop: "-100px" }}
            title="Some Error Occured. Please try again!"
            onConfirm={() => hideAlert()}
            onCancel={() => hideAlert()}
            confirmBtnBsStyle="info"
            btnSize=""
          >
            Some Error Occured. Please try again!
          </ReactBSAlert>
        ),
      });

      setTimeout(() => {
        hideAlert();
      }, 3000);
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  };

  const getFeaturesTable = () => {
    let i = 1;
    // setComponentRendered(true)

    const table = [];
    planFeatures.forEach((feature) => {
      table.push(
        <tr>
          <td className="text-left">*</td>
          <td className="text-left">{feature.name}</td>
          <td className="text-left">{feature.quota}</td>
        </tr>
      );
    });
    return table;
  };
  const getCouponDiscountAmount = ()=> {
    let discounted_price=0;
    if(couponApplied.discount_type==='percentage'){
      discounted_price = (priceAfterDiscount * couponApplied.discount_percentage/100)
      if(couponApplied.limit_amount && discounted_price > couponApplied.limit_amount) {
        discounted_price = couponApplied.limit_amount;
      }
    }else if(couponApplied.discount_type==='flat'){
      discounted_price = couponApplied.discount_flat;
      if(couponApplied.limit_amount && discounted_price > couponApplied.limit_amount) {
        discounted_price = couponApplied.limit_amount;
      }
    }
    if((planPrice.base_price - planPrice.discount - discounted_price)<0) {
      return 0;
    }
    return discounted_price;
  }
  return (
    <div className="content mt-30">
      {showPopup.alert}
      <Container>
        <Row>
          <Col className="ml-auto mr-auto" md="12">
            <Card className="card-signup text-center dp-box ">
              <div className="dfltDiv extra_text_addedsign">
                <div className="plnCrd">
                  <Row>
                    <Col md="7">
                      <div className="paymnt_img">
                        <Button
                          className="btn-round"
                          color="info"
                          size="sm"
                          type="button"
                          onClick={(event) => history.push("/dashboard/plans")}
                        >
                          See All Plans
                        </Button>
                        <h1>Buying Plan For Project</h1>
                        <h2>{projectDetails.name}</h2>
                        <img src="https://mcmscache.epapr.in/post_images/website_132/new_post_images/618a17fe99f36_Group-66.png"></img>
                      </div>
                    </Col>
                    <Col md="5">
                      {showLoader ? (
                        <Spinner animation="border" role="status">
                          <span className="sr-only">Loading...</span>
                        </Spinner>
                      ) : (
                        <div className="checkOutCard">
                          <CardTitle tag="h6">
                            {planDetails.plan_group_id
                              ? planDetails.plan_group_id.name
                              : ""}
                          </CardTitle>

                          <CardBody>
                            <Table className="pCardTbl">
                              <tr>
                                <td>Plan Duration</td>
                                <td>
                                  {planPrice
                                    ? planPrice.duration === 1 &&
                                      planPrice.duration_unit === "months"
                                      ? "1 month"
                                      : planPrice.duration +
                                        "  " +
                                        planPrice.duration_unit
                                    : ""}
                                </td>
                              </tr>
                              <hr />
                              <tr>
                                <td>
                                  Base Price
                                </td>
                                <td>
                                  <i
                                    className="fa fa-inr"
                                    style={{ fontSize: "15px" }}
                                  />
                                  {planPrice.base_price}
                                </td>
                              </tr>
                              {planPrice.discount ? (
                                <tr>
                                  <td>
                                    Discount
                                  </td>
                                  <td>
                                    <i
                                      className="fa fa-inr"
                                      style={{ fontSize: "15px" }}
                                    />
                                    {planPrice.discount}
                                  </td>
                                </tr>
                              ) : (
                                ""
                              )}
                              {couponApplied ? (
                                <tr>
                                  <td>
                                    Coupon Discount
                                  </td>
                                  <td>
                                    <i
                                      className="fa fa-inr"
                                      style={{ fontSize: "15px" }}
                                    />{
                                      getCouponDiscountAmount()
                                    }
                                  </td>
                                </tr>
                              ) : (
                                ""
                              )}

                              <hr />
                              <tr>
                                <td>Effective Price</td>
                                <td>
                                  <i
                                    className="fa fa-inr"
                                    style={{ fontSize: "15px" }}
                                  />
                                  {planPrice.base_price - planPrice.discount- (couponApplied ? getCouponDiscountAmount():0)}
                                </td>
                              </tr>
                              {/* <tr>
                                                            <td>
                                                                Total Amount (
                                                                {planPrice.currency?planPrice.currency.toUpperCase():''}
                                                                )
                                                            </td>
                                                            <td>
                                                                {planPrice.base_price?planPrice.base_price -
                                                                    planPrice.discount:""}
                                                            </td>
                                                        </tr> */}
                              <hr />
                            </Table>
                            {showProceedButton ? (
                              <Button
                                className="btn-round btnBlue"
                                onClick={async () => {
                                  showWarning ? handleOnPurchase(projectId, planId): processPayment(projectId, planId);
                                }}
                              >
                                Proceed To Payment
                              </Button>
                            ) : (
                              ""
                            )}
                            {showLoaderAtButton ? (
                              <Spinner animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                              </Spinner>
                            ) : (
                              ""
                            )}
                          </CardBody>
                        </div>
                      )}
                    </Col>
                  </Row>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
      <div
        className="full-page-background"
        style={{
          backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
        }}
      />
    </div>
  );
};

export default Checkout;

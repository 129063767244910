import React, { useState, useRef } from 'react';
import { useIntersection } from '../helpers/intersectionObserver';

const LazyLoadImage = ({ url, failCallback, style={} }) => {

  const [isInView, setIsInView] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const imgRef = useRef();

  useIntersection(imgRef, () => {
    setIsInView(true);
  });

  const handleOnLoad = () => {
    setIsLoaded(true);
  };

  return (
    <div
      ref={imgRef}
      style={{width:'100%',height:'100%'}}
    >
      {isInView && (
          <img
            style={{width:'100%',height:'100%',objectFit:'cover', ...style}}
            src={url}
            onLoad={handleOnLoad}
            onError={failCallback}
          />
      )}
      {!isLoaded?
       <div className='template_loader'></div> 
        :null
      }
    </div>
  );
  
};

export default LazyLoadImage;
import React,{useState,useEffect,useContext} from 'react';

import ProjectContext from "../../ProjectContext";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  CardText,
  CardImg,
  CardSubtitle,
  Label,
  FormGroup,
  Form,
  Input,
  Alert,
  Spinner,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";
import { Link } from "react-router-dom";
import AndroidIcon from '../../assets/img/sortd-apps/android.png';
import IOSIcon from '../../assets/img/sortd-apps/apple.png';
import notEnabledImage from '../../assets/img/bg/not_enabled.png';

import Android from './android/Android';
import IOS from './iOS/IOS';
import ReactBSAlert from "react-bootstrap-sweetalert";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faTrash,faTrashRestoreAlt } from '@fortawesome/free-solid-svg-icons'
import './General.css';
import appsService from '../../services/api/sortd-apps/general-service.js';
import androidService from '../../services/api/sortd-apps/android-service.js';
import iosService from '../../services/api/sortd-apps/ios-service.js';
import featuresService from '../../services/api/features-service.js';
const AppsManager = (props) => {
    const history = useHistory();
    const projectDetails = useContext(ProjectContext);
    if(!projectDetails.id) {
      history.push('/project/allprojects');
    }
    const projectId = projectDetails.id;
    const [showPopup,setShowPopup] = useState({alert:null});
    const [formdetails, setFormDetails] = useState({});
    const [isAndroidAppEnabled, setIsAndroidAppEnabled] = useState(false);
    const [isIOSAppEnabled, setIsIOSAppEnabled] = useState(false);
    const [reloadComponent, setReloadComponent] = useState(false);
    const [isCompleted, setIsCompleted] = useState(false);
    const [isSortdAppEnabled, setIsSortdAppEnabled] = useState(false);
    useEffect(() => {
      (async ()=>{
        const response = await featuresService.getFeatureStatus('sortd_apps',projectId);
        if(response.status && response.data) {
          console.log("Response",response.data);
          if(response.data["sortd_apps"]) {
            setIsSortdAppEnabled(true);
          }
        }
      })();
    },[])
    useEffect( () => {
    (async () =>{
        if(!isSortdAppEnabled) return ;
        let getAppsGeneralSettingsDetails = await appsService.getProjectAppsGeneralSettings(projectId);
        console.log("getAppsGeneralSettingsDetails",getAppsGeneralSettingsDetails);
        if(getAppsGeneralSettingsDetails.status && getAppsGeneralSettingsDetails.data){
            if(getAppsGeneralSettingsDetails.data.android_app_enabled) {
              setIsAndroidAppEnabled(true);
            }
            if(getAppsGeneralSettingsDetails.data.ios_app_enabled) {
                  setIsIOSAppEnabled(true);
            }
        }
        let androidAppInfo = await androidService.getAndroidAppInfo(projectId);
        console.log("androidAppInfo",androidAppInfo);
        if(androidAppInfo.status ) {
            if(androidAppInfo.data){
                setFormDetails({
                  ...formdetails,
                  android_app_enabled: androidAppInfo.data.android_app_enabled,
                  android_app_name: androidAppInfo.data.android_app_name,
                  android_app_package_name: androidAppInfo.data.android_app_package_name,
                  android_google_services_json: androidAppInfo.data.android_google_services_json?JSON.stringify(JSON.parse(androidAppInfo.data.android_google_services_json),undefined,4):"",
                  fcm_api_server_key: androidAppInfo.data.fcm_api_server_key,
                  launcher_icon: androidAppInfo.data.launcher_icon,
                  app_logo: androidAppInfo.data.app_logo,
                  launcher_background: androidAppInfo.data.launcher_background,
                });
                if(androidAppInfo.data.android_app_enabled) {
                  setIsAndroidAppEnabled(true);
                }
                
            }
        }else{
          history.push("/project/projects");
        }

    })();
  }, [isCompleted,isSortdAppEnabled]);
    useEffect( () => {
    (async () =>{
        if(!isSortdAppEnabled) return ;
        let iosAppInfo = await iosService.getIOSAppInfo(projectId);
        console.log("iosAppInfo",iosAppInfo);
        if(iosAppInfo.status ) {
            if(iosAppInfo.data){
                setFormDetails({
                  ...formdetails,
                  fcm_api_server_key: iosAppInfo.data.fcm_api_server_key,
                  ios_app_enabled: iosAppInfo.data.ios_app_enabled,
                  ios_app_name: iosAppInfo.data.ios_app_name,
                  ios_app_package_name: iosAppInfo.data.ios_app_package_name,
                  ios_team_id : iosAppInfo.data.ios_team_id,
                  launcher_icon: iosAppInfo.data.launcher_icon,
                  app_logo: iosAppInfo.data.app_logo,
                  app_store_api_key_id: iosAppInfo.data.app_store_api_key_id ,
                  app_store_api_key_issuer_id: iosAppInfo.data.app_store_api_key_issuer_id,
                  app_store_api_auth_key_url: iosAppInfo.data.app_store_api_auth_key_url,
                  app_store_api_auth_key_file_name : iosAppInfo.data.app_store_api_auth_key_file_name,
                });
                if(iosAppInfo.data.ios_app_enabled) {
                  setIsIOSAppEnabled(true);
                }
                
            }
        }else{
          history.push("/project/projects");
        }

    })();
  }, [isCompleted,isSortdAppEnabled]);
    const enableAndroidApp = () => {
      setShowPopup({
        alert: (
          <ReactBSAlert
            
            style={{ display: "block", "margin-top" : "-10px !important", height:"auto",width:"800px" , "overflow-y": "scroll" }}
            title="Android"
            onCancel={() => hideAlert()}
            confirmBtnText="Ok"
            cancelBtnText="Cancel"
            showConfirm={false}
            openAnim = {{ name: 'showSweetAlert', duration: 1000 }}
          >
            <Android hideAlert = {hideAlert} setIsCompleted={setIsCompleted}/>
          </ReactBSAlert>
        ),
      });
    }
    const enableIOSApp = () => {
      setShowPopup({
        alert: (
          <ReactBSAlert
            
            style={{ display: "block", "margin-top" : "-10px !important", height:"auto",width:"800px", "overflow-y": "scroll" }}
            title="iOS"
            onCancel={() => hideAlert()}
            showConfirm={false}
             openAnim = {{ name: 'showSweetAlert', duration: 1000 }}
          >
            <IOS hideAlert = {hideAlert} setIsCompleted={setIsCompleted}/>
          </ReactBSAlert>
        ),
      });
    }
    
    const hideAlert = () => {
      setShowPopup({
        alert: null,
      });
    }; 
    const redirectUrl = (e,navigate,redirectPath,project) => {
      e.preventDefault()
      history.push("/"+navigate+"/"+redirectPath+"?project="+project)
    } 
    return <div className="content mt-30">
      <div className='container'>
        {showPopup.alert}
        <Card >
          <CardTitle>
            <h4 align="center">SORTD Apps</h4>
          </CardTitle>
          <CardBody className="mainCard">
        {isSortdAppEnabled ?
                  <Row  align="right" className='manageContainer'> 
                  <Col sm="3" >
                  </Col>
                      <Col sm="3" >
                        <Card className="phonAp"
                          align="center" 
                          
                        >
                                      <CardImg
                                      className="androidCardImage"
                                      src={AndroidIcon}
                                  />
                          <CardBody>
                            <CardTitle tag="h5">
                              Android
                            </CardTitle>
                            { isAndroidAppEnabled ?
                                      <div className='actionItem'>
                                            <a title="App Versions" onClick={(event)=>  redirectUrl(event,'apps','android/dashboard',projectDetails.slug) }>
                                               <i class="fa fa-tablet fa-2x"></i>
                                            </a>
                                            
                                            <a title="Edit Android Settings" onClick={ enableAndroidApp}>
                                              <i class="fa fa-pencil  fa-2x"></i>
                                            </a>
                                            
                                            <a title="Customization" onClick={(event)=>  redirectUrl(event,'apps','config/versions',projectDetails.slug) } >
                                            <i class="fa fa-cog fa-2x"></i> 
                                            </a>
                                      </div>
                                    :
                                    <Button className="enblBtn"
                                      onClick={enableAndroidApp}
                                    >
                                      Enable Now
                                    </Button> 
                            }
                          </CardBody>
                        </Card>
                      </Col>
                      <Col sm="3" >
                        <Card className="phonAp"
                            align="center"
                          >
                                      <CardImg
                                      className='iosCardImage'
                                      alt="Card image"
                                      src={IOSIcon}
                                  />
                            <CardBody>
                              <CardTitle align="center" tag="h5">
                                iOS
                              </CardTitle>
                              { isIOSAppEnabled ?
                                      <div className="actionItem">
                                            <a title="App Versions" onClick={(event)=>  redirectUrl(event,'apps','ios/dashboard',projectDetails.slug) } >
                                              <i class="fa fa-tablet fa-2x"></i>
                                            </a>
                                            
                                            <a title="Edit iOS Settings" onClick={enableIOSApp} >
                                              <i class="fa fa-pencil  fa-2x"></i>
                                            </a>
                                            
                                            <a title="Customization" onClick={(event)=>  redirectUrl(event,'apps','config/versions',projectDetails.slug) } >
                                            <i class="fa fa-cog fa-2x"></i> 
                                            </a>
                                      </div>
                                    :
                                    <Button className="enblBtn"
                                      onClick={enableIOSApp}
                                    >
                                      Enable Now
                                    </Button>
                              }
                            </CardBody>
                        </Card>
                      </Col>
                  </Row>
          : <h4 align="center">Not Enabled For your project</h4>}
          </CardBody>
        </Card>
        
      </div>
    </div>
}

export default AppsManager;
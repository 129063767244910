import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom/cjs/react-router-dom";
import ProjectContext from "../../ProjectContext";
import "./style/chapter.css";
import useTimer from "./customHooks/useTimer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faArrowLeft,
    faPlus,
    faTrash,
} from "@fortawesome/free-solid-svg-icons";

const Chapter = () => {
    const location=useLocation();
    const history = useHistory();
    const { mediaId, trackId } = useParams();
    const projectDetails = useContext(ProjectContext);
    const projectId = projectDetails.id;
    const [showTimer, setShowTimer] = useState(false);

    const {
        timerLabel,
        trackLabel,
        hours,
        minutes,
        seconds,
        milliseconds,
        addToChapList,
        handleSave,
        handleInputChange,
        chapTimerEntries,
        handleDeleteChapterItem,
        showMessage,
        showDurationMessage,
        updateEmbedVideo,
    } = useTimer(mediaId, projectId, trackId);

    useEffect(() => {
        console.log(location);
    });

    return (
        <div>
            <div className="track-header">
                <div
                    className="back-button"
                    onClick={() => {
                        history.push({pathname:`/video/uploadvideo`, 
                        chapterData:{
                            mediaId,
                            state:true,
                            duration:location.duration
                        },});
                    }}
                >
                    <FontAwesomeIcon icon={faArrowLeft} />
                </div>

                <h2>{trackLabel}</h2>
            </div>
            <div className="chapter-body">
                <div style={{ flex: 1, padding: "10px" }}>
                    <div style={{ marginBottom: "10px" }}>
                        <label>Track Label:</label>
                        <input
                            type="text"
                            id="myInput"
                            value={trackLabel}
                            onChange={(e) => {
                                handleInputChange(e, "trackLabel");
                            }}
                            className="track-label-input"
                        />
                    </div>
                    <label>Chapters:</label>

                    <div className="chapters-container">
                        <div className="chapters-header">
                            <button
                                style={{ color: showTimer ? "grey" : "black" }}
                                disabled={showTimer}
                                onClick={() => {
                                    setShowTimer(true);
                                }}
                                // onClick={addToChapList}
                                className="add-chapter-button"
                            >
                                <FontAwesomeIcon icon={faPlus} />
                                Add Chapter
                            </button>
                        </div>

                        {showTimer && (
                            <div className="timer-input-container">
                                <div className="timer-input-box">
                                    <div className="timer-symbol-container">
                                        <input
                                            type="text"
                                            value={hours}
                                            onChange={(e) =>
                                                handleInputChange(e, "hours")
                                            }
                                            placeholder="00"
                                            className="timer-input"
                                        />
                                        hr
                                    </div>
                                    :
                                    <div className="timer-symbol-container">
                                        <input
                                            type="text"
                                            value={minutes}
                                            onChange={(e) =>
                                                handleInputChange(e, "minutes")
                                            }
                                            placeholder="00"
                                            maxLength="2"
                                            className="timer-input"
                                        />
                                        min
                                    </div>
                                    :
                                    <div className="timer-symbol-container">
                                        <input
                                            type="text"
                                            value={seconds}
                                            onChange={(e) =>
                                                handleInputChange(e, "seconds")
                                            }
                                            placeholder="00"
                                            maxLength="2"
                                            className="timer-input"
                                        />
                                        sec
                                    </div>
                                    :
                                    <div className="timer-symbol-container">
                                        <input
                                            type="text"
                                            value={milliseconds}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    e,
                                                    "milliseconds"
                                                )
                                            }
                                            placeholder="000"
                                            maxLength="3"
                                            className="timer-input"
                                        />
                                        ms
                                    </div>
                                    <input
                                        type="text"
                                        value={timerLabel}
                                        onChange={(e) => {
                                            handleInputChange(e, "timerLabel");
                                        }}
                                        placeholder="Chapter text"
                                        className="timer-label-input"
                                    />
                                </div>

                                {showDurationMessage ? (
                                    <div className="duration-message">
                                        Time cannot be greater than media's
                                        duration
                                    </div>
                                ) : (
                                    ""
                                )}
                                <div className="timer-buttons-container">
                                    {" "}
                                    <button
                                        className="cancel-btn"
                                        onClick={() => {
                                            setShowTimer(false);
                                        }}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="done-btn"
                                        onClick={() => {
                                            addToChapList();
                                           
                                        }}
                                    >
                                        Done
                                    </button>
                                </div>
                            </div>
                        )}

                        <div className="chapter-list-container">
                            <ul className="chapter-list">
                                {chapTimerEntries.map((chapter, index) => (
                                    <li key={index} className="chapter-item">
                                        <div>
                                            <span className="chapter-start">
                                                {chapter.start}
                                            </span>
                                            <span className="chapter-label">
                                                {chapter.timerLabel}
                                            </span>
                                        </div>
                                        <div>
                                            <span className="chapter-delete">
                                                <FontAwesomeIcon
                                                    title="Delete"
                                                    icon={faTrash}
                                                    onClick={() => {
                                                        handleDeleteChapterItem(
                                                            chapter
                                                        );
                                                    }}
                                                    className="delete-icon"
                                                />
                                            </span>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="embed-video-container">
                    <div>
                        <button
                            className="cancel-button"
                            onClick={() => {
                                history.push({
                                    pathname:`/video/uploadvideo`,
                                    chapterData:{
                                        mediaId,
                                        state:true,
                                        duration:location.duration
                                    }
                                });
                            }}
                        >
                            Cancel
                        </button>
                        <button className="save-button" onClick={handleSave}>
                            Save
                        </button>
                    </div>
                    <div key={updateEmbedVideo}>
                        <iframe
                            src={`https://cdn.jwplayer.com/players/${mediaId}-N1kHxxk9.html`}
                            width="620"
                            height="360"
                            frameborder="0"
                            scrolling="auto"
                            title="a"
                            allowfullscreen
                        ></iframe>
                    </div>
                </div>
            </div>
            {showMessage && (
                <div className="message">Track Created Succesfully</div>
            )}
        </div>
    );
};

export default Chapter;

import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import "./Customizations.css"
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col,
    NavItem,
    NavLink,
    Nav,
    TabContent,
    TabPane,
} from "reactstrap";

import Switch from "react-bootstrap-switch";
import Select from "react-select";

import ImageUpload from "./UploadImage.js";
import ReactTooltip from "react-tooltip";

const ConfigFormComponent = (props) => {
    var element = [];
    const [showRemove, setShowRemove] = useState(true);
    var elementName = props.elementname;
    var elementId = props.elementname;
    var elementDesign = props.data;

    var elementType = elementDesign.type;
    var helpText = elementDesign.helptext ? elementDesign.helptext : "";

    if (elementDesign.source && elementDesign.source == "category_list") {
        elementType = "enum";
        elementDesign.type_items = props.categories;
    }

    if (props.grandparent != "") {
        elementName =
            props.subparent +
            "[" +
            props.parent +
            "]" +
            "[" +
            props.elementname +
            "]";
        elementId =
            props.subparent + ":" + props.parent + ":" + props.elementname;
    } else if (props.subparent != "") {
        elementName = props.parent + "[" + props.elementname + "]";
        elementId = props.parent + ":" + props.elementname;
    }

    const handleChange = (event) => {
        let inputId = [event.target.id].toString();
        let inputValue = event.target.value;
        let parentsArray = inputId.split(":");
        if (parentsArray.length === 3) {
            props.setCurrentTabConfig({
                ...props.currentTabConfig,
                [parentsArray[0]]: {
                    ...props.currentTabConfig[parentsArray[0]],
                    [parentsArray[1]]: {
                        ...props.currentTabConfig[parentsArray[0]][
                            parentsArray[1]
                        ],
                        [parentsArray[2]]: inputValue,
                    },
                },
            });
        } else if (parentsArray.length === 2) {
            props.setCurrentTabConfig({
                ...props.currentTabConfig,
                [parentsArray[0]]: {
                    ...props.currentTabConfig[parentsArray[0]],
                    [parentsArray[1]]: inputValue,
                },
            });
        } else {
            props.setCurrentTabConfig({
                ...props.currentTabConfig,
                [parentsArray[0]]: inputValue,
            });
        }

        props.validateConfigField(inputId, inputValue, elementDesign, "field");
    };

    const handleBlur = (event) => {
        let input_id = [event.target.id];
        let input_value = [event.target.value];
    };

    const handleMultiImageChange = (
        inputId,
        event,
        multiValueElementDesign
    ) => {
        //console.log(inputId);
        //console.log(event.target.value);
        // console.log("Handle Multi Image  change:",inputId,event,multiValueElementDesign);
        let inputValue = event.value;
        let parentsArray = inputId.split(":");
        if (parentsArray.length === 5) {
            let multiInputArray =
                props.currentTabConfig[parentsArray[0]][parentsArray[1]][
                    parentsArray[2]
                ];
            multiInputArray[parentsArray[3]][parentsArray[4]] = inputValue;

            props.setCurrentTabConfig({
                ...props.currentTabConfig,
                [parentsArray[0]]: {
                    ...props.currentTabConfig[parentsArray[0]],
                    [parentsArray[1]]: {
                        ...props.currentTabConfig[parentsArray[0]][
                            parentsArray[1]
                        ],
                        [parentsArray[2]]: multiInputArray,
                    },
                },
            });

            // props.setCurrentTabConfig({
            //   ...props.currentTabConfig,
            //   [parentsArray[0]] : {
            //     ...props.currentTabConfig[parentsArray[0]],
            //     [parentsArray[1]] : {
            //       ...props.currentTabConfig[parentsArray[0]][parentsArray[1]],
            //       [parentsArray[2]] : {
            //         ...props.currentTabConfig[parentsArray[0]][parentsArray[1]][parentsArray[2]],
            //         [parentsArray[3]] : {
            //           ...props.currentTabConfig[parentsArray[0]][parentsArray[1]][parentsArray[2]][parentsArray[3]],
            //           [parentsArray[4]] : inputValue,
            //         }
            //       }
            //     }
            //   }
            // });
        } else if (parentsArray.length === 4) {
            let multiInputArray =
                props.currentTabConfig[parentsArray[0]][parentsArray[1]];
            multiInputArray[parentsArray[2]][parentsArray[3]] = inputValue;

            props.setCurrentTabConfig({
                ...props.currentTabConfig,
                [parentsArray[0]]: {
                    ...props.currentTabConfig[parentsArray[0]],
                    [parentsArray[1]]: multiInputArray,
                },
            });

            // props.setCurrentTabConfig({
            //   ...props.currentTabConfig,
            //   [parentsArray[0]] : {
            //     ...props.currentTabConfig[parentsArray[0]],
            //     [parentsArray[1]] : {
            //       ...props.currentTabConfig[parentsArray[0]][parentsArray[1]],
            //       [parentsArray[2]] : {
            //         ...props.currentTabConfig[parentsArray[0]][parentsArray[1]][parentsArray[2]],
            //         [parentsArray[3]] : inputValue,
            //       }
            //     }
            //   }
            // });
        } else {
            let multiInputArray = props.currentTabConfig[parentsArray[0]];
            multiInputArray[parentsArray[1]][parentsArray[2]] = inputValue;

            props.setCurrentTabConfig({
                ...props.currentTabConfig,
                [parentsArray[0]]: multiInputArray,
            });

            // props.setCurrentTabConfig({
            //   ...props.currentTabConfig,
            //   [parentsArray[0]] : {
            //     ...props.currentTabConfig[parentsArray[0]],
            //     [parentsArray[1]] : {
            //       ...props.currentTabConfig[parentsArray[0]][parentsArray[1]],
            //       [parentsArray[2]] : inputValue,
            //     }
            //   }
            // });
        }
        props.validateConfigField(
            inputId,
            inputValue,
            multiValueElementDesign,
            "field"
        );
        //console.log(props.currentTabConfig);
    };

    const handleMultiValueChange = (
        inputId,
        event,
        multiValueElementDesign
    ) => {
        //console.log(inputId);
        //console.log(event.target.value);
        let inputValue = event.target.value;
        let parentsArray = inputId.split(":");
        if (parentsArray.length === 5) {
            let multiInputArray =
                props.currentTabConfig[parentsArray[0]][parentsArray[1]][
                    parentsArray[2]
                ];
            multiInputArray[parentsArray[3]][parentsArray[4]] = inputValue;

            props.setCurrentTabConfig({
                ...props.currentTabConfig,
                [parentsArray[0]]: {
                    ...props.currentTabConfig[parentsArray[0]],
                    [parentsArray[1]]: {
                        ...props.currentTabConfig[parentsArray[0]][
                            parentsArray[1]
                        ],
                        [parentsArray[2]]: multiInputArray,
                    },
                },
            });

            // props.setCurrentTabConfig({
            //   ...props.currentTabConfig,
            //   [parentsArray[0]] : {
            //     ...props.currentTabConfig[parentsArray[0]],
            //     [parentsArray[1]] : {
            //       ...props.currentTabConfig[parentsArray[0]][parentsArray[1]],
            //       [parentsArray[2]] : {
            //         ...props.currentTabConfig[parentsArray[0]][parentsArray[1]][parentsArray[2]],
            //         [parentsArray[3]] : {
            //           ...props.currentTabConfig[parentsArray[0]][parentsArray[1]][parentsArray[2]][parentsArray[3]],
            //           [parentsArray[4]] : inputValue,
            //         }
            //       }
            //     }
            //   }
            // });
        } else if (parentsArray.length === 4) {
            let multiInputArray =
                props.currentTabConfig[parentsArray[0]][parentsArray[1]];
            multiInputArray[parentsArray[2]][parentsArray[3]] = inputValue;

            props.setCurrentTabConfig({
                ...props.currentTabConfig,
                [parentsArray[0]]: {
                    ...props.currentTabConfig[parentsArray[0]],
                    [parentsArray[1]]: multiInputArray,
                },
            });

            // props.setCurrentTabConfig({
            //   ...props.currentTabConfig,
            //   [parentsArray[0]] : {
            //     ...props.currentTabConfig[parentsArray[0]],
            //     [parentsArray[1]] : {
            //       ...props.currentTabConfig[parentsArray[0]][parentsArray[1]],
            //       [parentsArray[2]] : {
            //         ...props.currentTabConfig[parentsArray[0]][parentsArray[1]][parentsArray[2]],
            //         [parentsArray[3]] : inputValue,
            //       }
            //     }
            //   }
            // });
        } else {
            let multiInputArray = props.currentTabConfig[parentsArray[0]];
            multiInputArray[parentsArray[1]][parentsArray[2]] = inputValue;

            props.setCurrentTabConfig({
                ...props.currentTabConfig,
                [parentsArray[0]]: multiInputArray,
            });

            // props.setCurrentTabConfig({
            //   ...props.currentTabConfig,
            //   [parentsArray[0]] : {
            //     ...props.currentTabConfig[parentsArray[0]],
            //     [parentsArray[1]] : {
            //       ...props.currentTabConfig[parentsArray[0]][parentsArray[1]],
            //       [parentsArray[2]] : inputValue,
            //     }
            //   }
            // });
        }
        props.validateConfigField(
            inputId,
            inputValue,
            multiValueElementDesign,
            "field"
        );
        //console.log(props.currentTabConfig);
    };

    const handleMultiValueEnumChange = (
        inputId,
        inputValue,
        multiValueEnumDesign
    ) => {
        //let inputValue = event.target.value;
        // console.log(inputId);
        // console.log(inputValue);
        let parentsArray = inputId.split(":");

        if (parentsArray.length === 5) {
            let multiInputArray =
                props.currentTabConfig[parentsArray[0]][parentsArray[1]][
                    parentsArray[2]
                ];
            multiInputArray[parentsArray[3]][parentsArray[4]] =
                inputValue.value;
            if (multiValueEnumDesign.dependent_field) {
                multiInputArray[parentsArray[3]][
                    multiValueEnumDesign.dependent_field
                ] = inputValue.label;
            }

            props.setCurrentTabConfig({
                ...props.currentTabConfig,
                [parentsArray[0]]: {
                    ...props.currentTabConfig[parentsArray[0]],
                    [parentsArray[1]]: {
                        ...props.currentTabConfig[parentsArray[0]][
                            parentsArray[1]
                        ],
                        [parentsArray[2]]: multiInputArray,
                    },
                },
            });
        } else if (parentsArray.length === 4) {
            let multiInputArray =
                props.currentTabConfig[parentsArray[0]][parentsArray[1]];
            multiInputArray[parentsArray[2]][parentsArray[3]] =
                inputValue.value;
            if (multiValueEnumDesign.dependent_field) {
                multiInputArray[parentsArray[2]][
                    multiValueEnumDesign.dependent_field
                ] = inputValue.label;
            }

            props.setCurrentTabConfig({
                ...props.currentTabConfig,
                [parentsArray[0]]: {
                    ...props.currentTabConfig[parentsArray[0]],
                    [parentsArray[1]]: multiInputArray,
                },
            });
        } else {
            let multiInputArray = props.currentTabConfig[parentsArray[0]];
            multiInputArray[parentsArray[1]][parentsArray[2]] =
                inputValue.value;
            if (multiValueEnumDesign.dependent_field) {
                multiInputArray[parentsArray[1]][
                    multiValueEnumDesign.dependent_field
                ] = inputValue.label;
            }

            props.setCurrentTabConfig({
                ...props.currentTabConfig,
                [parentsArray[0]]: multiInputArray,
            });
        }

        props.validateConfigField(
            inputId,
            inputValue.value,
            multiValueEnumDesign
        );
        //console.log(props.currentTabConfig);
    };

    const handleEnumChange = (inputId, inputValue) => {
        // console.log("inputId",inputId);
        // console.log("inputValue",inputValue);
        // console.log(elementDesign);
        
        let parentsArray = inputId.split(":");
        
        // console.log("parentsArray",parentsArray);

        if (parentsArray.length === 3) {
            props.setCurrentTabConfig({
                ...props.currentTabConfig,
                [parentsArray[0]]: {
                    ...props.currentTabConfig[parentsArray[0]],
                    [parentsArray[1]]: {
                        ...props.currentTabConfig[parentsArray[0]][
                            parentsArray[1]
                        ],
                        [parentsArray[2]]: inputValue.value,
                    },
                },
            });
        } else if (parentsArray.length === 2) {
            props.setCurrentTabConfig({
                ...props.currentTabConfig,
                [parentsArray[0]]: {
                    ...props.currentTabConfig[parentsArray[0]],
                    [parentsArray[1]]: inputValue.value,
                },
            });
        } else {
            props.setCurrentTabConfig({
                ...props.currentTabConfig,
                [parentsArray[0]]: inputValue.value,
            });

        }

        props.validateConfigField(inputId, inputValue.value, elementDesign);
        //console.log(props.currentTabConfig);
    };

    const currentEnumValue = () => {
        let value;
        let savedvalue = "";
        //console.log(props.currentTabConfig);
        if (props.currentTabConfig) {
            if (props.grandparent != "") {
                //console.log(props.currentTabConfig[props.subparent][props.parent][props.elementname]);
                if(props.currentTabConfig[props.subparent] && props.currentTabConfig[props.subparent][props.parent]) {
                    savedvalue =
                        props.currentTabConfig[props.subparent][props.parent][props.elementname];
                }

            } else if (props.subparent != "") {
                //console.log(props.currentTabConfig[props.parent][props.elementname]);
                if(props.currentTabConfig[props.parent]) {
                    savedvalue =
                        props.currentTabConfig[props.parent][props.elementname];
                }
            } else {
                //console.log(props.currentTabConfig[props.elementname]);
                savedvalue = props.currentTabConfig[props.elementname];
            }
        }
        if((savedvalue === undefined || savedvalue==="") && elementDesign.default !== undefined){
                savedvalue = elementDesign.default;
        }
        elementDesign.type_items.forEach(function (sdetails) {
            if (sdetails.value == savedvalue) {
                value = sdetails;
            }
        });
        return value;
    };

    const handleMultiEnumChange = (inputId, inputValue) => {
        // console.log(inputId);
        // console.log(inputValue);
        // console.log(elementDesign);
        let saveValue = [];
        // console.log("input value ", inputValue)
        if (inputValue && inputValue.length) {
            inputValue.forEach(function (saveddetails) {
                saveValue.push(saveddetails.value);
            });
        }


        let parentsArray = inputId.split(":");
        // console.log("input",saveValue)
        if (parentsArray.length === 3) {
            // console.log("input three")
            props.setCurrentTabConfig({
                ...props.currentTabConfig,
                [parentsArray[0]]: {
                    ...props.currentTabConfig[parentsArray[0]],
                    [parentsArray[1]]: {
                        ...props.currentTabConfig[parentsArray[0]][
                            parentsArray[1]
                        ],
                        [parentsArray[2]]: saveValue,
                    },
                },
            });
        } else if (parentsArray.length === 2) {
            // console.log("two")
            props.setCurrentTabConfig({
                ...props.currentTabConfig,
                [parentsArray[0]]: {
                    ...props.currentTabConfig[parentsArray[0]],
                    [parentsArray[1]]: saveValue,
                },
            });
        } else {
            // console.log()
            // console.log("input",parentsArray.length)
            props.setCurrentTabConfig({
                ...props.currentTabConfig,
                [parentsArray[0]]: saveValue,
            });
        }
        //console.log(props.currentTabConfig);
    };

    const currentMultiEnumValue = () => {
        let value = [];
        let savedvalue = [];
        if (props.currentTabConfig) {
            if (props.grandparent != "") {
                //console.log(props.currentTabConfig[props.subparent][props.parent][props.elementname]);
                // console.log("input grandparent")
                savedvalue =
                    props.currentTabConfig[props.subparent][props.parent][
                        props.elementname
                    ];


                    // console.log("input savedvalue",savedvalue)
            } else if (props.subparent != "") {
                //console.log(props.currentTabConfig[props.parent][props.elementname]);
                savedvalue =
                    props.currentTabConfig[props.parent][props.elementname];
            } else {
                //console.log(props.currentTabConfig[props.elementname]);
                savedvalue = props.currentTabConfig[props.elementname];
            }
        }
        console.log("input elementDesign", elementDesign.type_items)

        if (savedvalue?.length && elementDesign.type_items) {
            savedvalue.forEach(function (saveddetails) {
                elementDesign.type_items.forEach(function (sdetails) {
                    if (sdetails.value == saveddetails) {
                        value.push(sdetails);
                    }
                });
            });
        }
        // console.log("input values", value)
        return value;
    };

    const currentValue = () => {
        let value=[];
        // console.log("currentValue",props.currentTabConfig);
        if (props.currentTabConfig) {
            if (props.grandparent != "") {
                if(props.currentTabConfig[props.subparent] && props.currentTabConfig[props.subparent][props.parent]){
                    value =
                        props.currentTabConfig[props.subparent][props.parent][
                            props.elementname
                        ];
                }
            } else if (props.subparent != "") {
                //console.log(props.currentTabConfig[props.parent][props.elementname]);
                if(props.currentTabConfig[props.parent]) {
                    value = props.currentTabConfig[props.parent][props.elementname];
                }
            } else {
                value = props.currentTabConfig[props.elementname];
            }
        }
        // if(elementDesign.type !='boolean' && value===undefined && elementDesign.default && elementDesign.default!=''){
        //     value = elementDesign.default;
        //     // handleEnumChange(elementId,elementDesign.default)
        // }
        if((value === undefined || value==="" || value.length==0) && elementDesign.default !== undefined){
                value = elementDesign.default;
        }
        // if((value === "") && elementDesign.default !== undefined){    
        //     value = elementDesign.default;
        //         // handleEnumChange(elementId,{value:elementDesign.default})
        // }
        if(elementDesign.type == 'boolean'){
            if(value==='false'){
                return false;
            }

        }
        // console.log("Value:",value);
        return value;
    };

    const removeMultiInputValueElement = (indexid) => {
        let removeindex = indexid - 1;
        let elementValue = currentValue();
        elementValue.splice(removeindex, 1);
        //delete elementValue[removeindex];
        //console.log(elementValue);

        if (props.currentTabConfig) {
            if (props.grandparent != "") {
                props.setCurrentTabConfig({
                    ...props.currentTabConfig,
                    [props.subparent]: {
                        ...props.currentTabConfig[props.subparent],
                        [props.parent]: {
                            ...props.currentTabConfig[props.subparent][
                                props.parent
                            ],
                            [props.elementname]: elementValue,
                        },
                    },
                });
                //props.currentTabConfig[props.subparent][props.parent][props.elementname].push(element);
            } else if (props.subparent != "") {
                props.setCurrentTabConfig({
                    ...props.currentTabConfig,
                    [props.parent]: {
                        ...props.currentTabConfig[props.parent],
                        [props.elementname]: elementValue,
                    },
                });
            } else {
                props.setCurrentTabConfig({
                    ...props.currentTabConfig,
                    [props.elementname]: elementValue,
                });
            }
        }

        //console.log(props.currentTabConfig);
    };

    const addMultiInputValueElement = (indexid, element) => {
        let elementValue;
        let currValue=[];
        currValue=currentValue();
        if (currValue && currValue.length) {
            elementValue = currValue;
        } else {
            //elementValue = {};
            elementValue = [];
        }
        //elementValue[indexid] = element;

        elementValue.push(element);
        //console.log(elementValue);
        //console.log(props.currentTabConfig);
        if (props.currentTabConfig) {
            if (props.grandparent != "") {
                props.setCurrentTabConfig({
                    ...props.currentTabConfig,
                    [props.subparent]: {
                        ...props.currentTabConfig[props.subparent],
                        [props.parent]: {
                            ...props.currentTabConfig[props.subparent][
                                props.parent
                            ],
                            [props.elementname]: elementValue,
                        },
                    },
                });
                //props.currentTabConfig[props.subparent][props.parent][props.elementname].push(element);
            } else if (props.subparent != "") {
                props.setCurrentTabConfig({
                    ...props.currentTabConfig,
                    [props.parent]: {
                        ...props.currentTabConfig[props.parent],
                        [props.elementname]: elementValue,
                    },
                });
            } else {
                props.setCurrentTabConfig({
                    ...props.currentTabConfig,
                    [props.elementname]: elementValue,
                });

                props.setFieldStates({
                  ...props.fieldStates,
                  ['error_'+props.elementname] : 'has-success',
                  ['error_message_'+props.elementname] : ''
                });
            }
        }
        //console.log(props.currentTabConfig);
    };

    const multiInputValue = (index, keyname) => {
        // console.log(index);
        // console.log(keyname);
        // console.log("Multi Input Value:",index,keyname);
        let value;
        if (props.currentTabConfig) {
            if (
                props.grandparent != "" &&
                props.currentTabConfig[props.subparent][props.parent][
                    props.elementname
                ]
            ) {
                value =
                    props.currentTabConfig[props.subparent][props.parent][
                        props.elementname
                    ][index][keyname];
            } else if (
                props.subparent != "" &&
                props.currentTabConfig[props.parent][props.elementname]
            ) {
                value =
                    props.currentTabConfig[props.parent][props.elementname][
                        index
                    ][keyname];
            } else {
                if (props.currentTabConfig[props.elementname]) {
                    value =
                        props.currentTabConfig[props.elementname][index][
                            keyname
                        ];
                }
            }
        }
        // console.log(elementDesign);
        if(value === undefined && elementDesign["type_items"][0][keyname].default !== undefined){
            value = elementDesign["type_items"][0][keyname].default;
        }
        // console.log(props)
        // console.log("Value:",value);
        return value;
    };

    const filterDropdownElement = ( keyname, items) => {
        let savedvalue = [];
        if (props.currentTabConfig) {
            if (
                props.grandparent != "" &&
                props.currentTabConfig[props.subparent][props.parent][
                    props.elementname
                ]
            ) {
                savedvalue =
                    props.currentTabConfig[props.subparent][props.parent][
                        props.elementname
                    ];
            } else if (
                props.subparent != "" &&
                props.currentTabConfig[props.parent][props.elementname]
            ) {
                savedvalue =
                    props.currentTabConfig[props.parent][props.elementname]
            } else {
                if (props.currentTabConfig[props.elementname]) {
                    savedvalue =
                        props.currentTabConfig[props.elementname];
                }
            }
        }
        if(savedvalue === undefined ){
            savedvalue = [];
        }
        let filteredList = [];
        items.forEach(function(itemDetails) {
            let isPresent = false;
            for(let i=0;i<savedvalue.length;i++) {
                if(itemDetails.value === savedvalue[i][keyname]) {
                    isPresent = true;
                    break;
                }
            }
            if(isPresent ===false) {
                filteredList.push(itemDetails);
            }
        });

        return filteredList;
    }

    const multiInputEnumValue = (index, keyname, items) => {
        // console.log(index);
        // console.log(keyname);
        // console.log("propsM...",props.currentTabConfig);
        // console.log("propsMMultiInputEnumValue:",index,keyname,items);
        let value;
        let savedvalue = "";
        if (props.currentTabConfig) {
            if (
                props.grandparent != "" &&
                props.currentTabConfig[props.subparent][props.parent][
                    props.elementname
                ]
            ) {
                savedvalue =
                    props.currentTabConfig[props.subparent][props.parent][
                        props.elementname
                    ][index][keyname];
            } else if (
                props.subparent != "" &&
                props.currentTabConfig[props.parent][props.elementname]
            ) {
                savedvalue =
                    props.currentTabConfig[props.parent][props.elementname][
                        index
                    ][keyname];
            } else {
                if (props.currentTabConfig[props.elementname]) {
                    savedvalue =
                        props.currentTabConfig[props.elementname][index][
                            keyname
                        ];
                }
            }
        }
        if(savedvalue === undefined && elementDesign["type_items"][0][keyname].default !== undefined){
            savedvalue = elementDesign["type_items"][0][keyname].default;
        }

        if (items.length) {
            items.forEach(function (itemdetails) {
                if (itemdetails.value && itemdetails.value == savedvalue) {
                    value = itemdetails;
                }
            });
        }

        return value;
    };

    //console.log(props);
    if (elementType == "string") {
        element.push(
            <Row>
                    <Col sm="6">
                        <div className="designLabel">
                            <small className="text-default">
                                {elementDesign.label}{props.data.required?<span class="text-danger"> * </span>:""}
                            </small>
                        </div>
                        {helpText != "" ? (
                            <Label className="smLabl-txt">{helpText}</Label>
                        ) : (
                            ""
                        )}
                    </Col>
                <Col sm="6">
                  <div className="inptFom1">
                    <FormGroup
                         className={props.fieldStates["error_" + elementId]}
                        >
                        <Input
                            type="text"
                            name={elementName}
                            id={elementId}
                            value={currentValue()}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                         {props.fieldStates["error_" + elementId] ===
                        "has-danger" ? (
                            <label className="error">
                                {
                                    props.fieldStates[
                                        "error_message_" + elementId
                                    ]
                                }
                            </label>
                        ) : null} 
                    </FormGroup>
                  </div>  
                </Col>
            </Row>
        );
    } else if (elementType == "boolean") {
        element.push(
            <Row>
                <Col sm="6" >
                    <div className="designLabel">
                            <small className="text-default">
                                {elementDesign.label}{props.data.required?<span className="text-danger"> * </span>:""}
                            </small>
                        </div>
                        {helpText != "" ? <Label className="smLabl-txt">{helpText}</Label> : ""}
                </Col>
                <Col sm="6">
                     <div className="inptFom1">
                        <FormGroup>
                            <Switch
                                name={elementName}
                                offColor="success"
                                offText={<i className="nc-icon nc-simple-remove" />}
                                onColor="success"
                                onText={<i className="nc-icon nc-check-2" />}
                                value={currentValue()}
                                onChange={(value, enabled) =>
                                    handleEnumChange(elementId, { value: enabled })
                                }
                            />{" "}
                        </FormGroup>
                    </div>
                </Col>
            </Row>
        );
    } else if (elementType == "integer") {
        element.push(
            <Row>
                <Col sm="6">
                     <div className="designLabel">
                            <small className="text-default">
                                {elementDesign.label}{props.data.required?<span class="text-danger"> * </span>:""}
                            </small>
                     </div>
                    {helpText != "" ? <Label className="smLabl-txt">{helpText}</Label> : ""}
                </Col>
                <Col sm="6">
                    <div className="inptFom1">
                        <FormGroup
                             className={props.fieldStates["error_" + elementId]}
                            >
                            <Input
                                type="number"
                                name={elementName}
                                id={elementId}
                                value={currentValue()}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                             {props.fieldStates["error_" + elementId] ===
                            "has-danger" ? (
                                <label className="error">
                                    {
                                        props.fieldStates[
                                            "error_message_" + elementId
                                        ]
                                    }
                                </label>
                            ) : null} 
                        </FormGroup>
                    </div>
                </Col>
            </Row>
        );
    } else if (elementType == "hidden") {
        element.push(
            <Row>
                <Col sm="12">
                    <div className="inptFom1">
                        <FormGroup>
                            <Input
                                type="hidden"
                                name={elementName}
                                id={elementId}
                                value={currentValue()}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </FormGroup>
                    </div>
                </Col>
            </Row>
        );
    } else if (elementType == "hex_color") {
        element.push(
            <Row>
                <Col sm="6">
                        <div className="designLabel">
                            <small className="text-default">
                                {elementDesign.label}{props.data.required?<span class="text-danger"> * </span>:""}
                            </small>
                        </div>
                        {helpText != "" ? <Label className="smLabl-txt">{helpText}</Label> : ""}
                </Col>
                <Col sm="6">
                    <div className="inptFomColr">
                        <div className="clrChng" style={{display:"inline-block", border:"none", }}>
                        <Input
                            style={{ background: currentValue() }}
                            type="color"
                            name={elementName}
                            id={elementId}
                            value={currentValue()}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        </div>
                        <div className="clrTxt" style={{display:"inline-block"}}>
                            <input 
                            //   style={{border:"none" }}
                              type="text"
                              name={elementName}
                              id={elementId}
                              value={currentValue()}
                              onChange={handleChange}
                              />
                              </div>
                        </div>
                </Col>
            </Row>
        );
    } else if (elementType == "url") {
        element.push(
            <Row>
                <Col sm="6">
                        <div className="designLabel">
                            <small className="text-default">
                                {elementDesign.label}{props.data.required?<span class="text-danger"> * </span>:""}
                            </small>
                        </div>
                        {helpText != "" ? <Label className="smLabl-txt">{helpText}</Label> : ""}
                </Col>
                <Col sm="6">
                    <div className="inptFom1">
                        <FormGroup
                             className={props.fieldStates["error_" + elementId]}
                            >
                            <Input
                                type="url"
                                name={elementName}
                                id={elementId}
                                value={currentValue()}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                             {props.fieldStates["error_" + elementId] ===
                            "has-danger" ? (
                                <label className="error">
                                    {
                                        props.fieldStates[
                                            "error_message_" + elementId
                                        ]
                                    }
                                </label>
                            ) : null} 
                        </FormGroup>
                    </div>
                </Col>
            </Row>
        );
    } else if (elementType == "enum" && elementDesign.type == "array") {
        //console.log(elementName);

        element.push(
            <Row>
                <Col sm="6">
                    <div className="designLabel">
                            <small className="text-default">
                                {elementDesign.label}{props.data.required?<span class="text-danger"> * </span>:""}
                            </small>
                        </div>
                        {helpText != "" ? <Label className="smLabl-txt">{helpText}</Label> : ""}
                </Col>
                <Col sm="6">
                    <div className="inptFom1">
                        <FormGroup
                             className={props.fieldStates["error_" + elementId]}
                            >
                            <Select
                                className="react-select primary"
                                classNamePrefix="react-select"
                                name={elementName}
                                isMulti="true"
                                value={currentMultiEnumValue()}
                                options={elementDesign.type_items}
                                onChange={(value) =>
                                    handleMultiEnumChange(elementId, value)
                                }
                            />
                         {props.fieldStates["error_" + elementId] ===
                            "has-danger" ? (
                                <label>
                                    {
                                        props.fieldStates[
                                            "error_message_" + elementId
                                        ]
                                    }
                                </label>
                            ) : null} 
                            <div id={elementId} tabIndex="0"></div>
                        </FormGroup>
                    </div>
                </Col>
            </Row>
        );
    } else if (elementType == "enum") {
        //console.log(elementName);

        element.push(
            <Row>
                <Col sm="6">
                    <div className="designLabel">
                            <small className="text-default">
                                {elementDesign.label}{props.data.required?<span class="text-danger"> * </span>:""}
                            </small>
                        </div>
                        {helpText != "" ? <Label className="smLabl-txt">{helpText}</Label> : ""}
                </Col>
                <Col sm="6">
                    <div className="inptFom1">
                        <FormGroup
                             className={props.fieldStates["error_" + elementId]}>
                            <Select
                                className="react-select primary"
                                classNamePrefix="react-select"
                                name={elementName}
                                id={elementId}
                                value={currentEnumValue()}
                                options={elementDesign.type_items}
                                onChange={(value) =>
                                    handleEnumChange(elementId, value)
                                }
                            />
                            <br></br>
                         {props.fieldStates["error_" + elementId] ===
                            "has-danger" ? (
                                <label className="error">
                                    {
                                        props.fieldStates[
                                            "error_message_" + elementId
                                        ]
                                    }
                                </label>
                            ) : null} 
                            <div id={elementId} tabIndex="0"></div>
                        </FormGroup>
                    </div>
                </Col>
            </Row>
        );
    } else if (elementType == "file_upload") {
        element.push(
            <Row>
                <Col sm="6">
                    <div className="designLabel">
                        <small className="text-default">
                            {elementDesign.label}
                            {elementDesign.size ? (
                                <span>({elementDesign.size})</span>
                            ) : (
                                ""
                            )}{props.data.required?<span class="text-danger"> * </span>:""}
                        </small>
                    </div>
                    {helpText != "" ? <Label className="smLabl-txt">{helpText}</Label> : ""}
                </Col>
                <Col sm="6">
                    <div className="inptFom1">
                        <FormGroup
                             className={props.fieldStates["error_" + elementId]}>
                            
                            <ImageUpload
                                projectId={props.projectId}
                                imageDetails={elementDesign}
                                elementValue={
                                    props.currentTabConfig ? currentValue() : ""
                                }
                                id={elementId}
                                handleEnumChange={handleEnumChange}
                            />
                            <Input
                                type="hidden"
                                name={elementName}
                                id={elementId}
                                value={currentValue()}
                                onChange={handleChange}
                            />
                             {props.fieldStates["error_" + elementId] ===
                            "has-danger" ? (
                                <label className="error">
                                    {
                                        props.fieldStates[
                                            "error_message_" + elementId
                                        ]
                                    }
                                </label>
                            ) : null} 
                        </FormGroup>
                    </div>
                </Col>
            </Row>
        );
    } else if (elementType == "multi-input") {
        //console.log(elementDesign.type_items);
        let multiInputFields = elementDesign.type_items;
        let multiInputElements = [];
        let defaultElement = {};

        Object.keys(multiInputFields[0]).forEach(function (fieldkey) {
            defaultElement[fieldkey] = multiInputFields[0][fieldkey].default;
        });
        let totalValues = multiInputFields;
        let currValue=[];
        currValue=currentValue();
        // console.log("currentValue",currValue);
        if (!currValue || !currValue.length) {
            totalValues = [];
            //addMultiInputValueElement(0,defaultElement);
        } else {
            totalValues = currValue;
        }

        let nextIndex = 0;
        if (totalValues.length) {
            for (let key = 0; key < totalValues.length; key++) {
                //Object.keys(totalValues).forEach(function(key) {
                multiInputElements.push(
                    <Row>
                        <Col>
                            <b>{nextIndex + 1}</b>
                            <hr></hr>
                        </Col>
                    </Row>
                );
                Object.keys(multiInputFields[0]).forEach(function (fieldkey) {
                    //console.log(multiInputFields[key][fieldkey]);
                    let listField = multiInputFields[0][fieldkey];
                    let listFieldName =
                        elementName + "[" + key + "][" + fieldkey + "]";
                    let listFieldId = elementId + ":" + key + ":" + fieldkey;
                    let listFieldType = listField.type;
                    if (
                        listField.source &&
                        listField.source == "category_list"
                    ) {
                        listFieldType = "enum";
                        listField.type_items = props.categories;
                    }
                    // console.log(listField)
                    let showField = true;
                    if(listField.parent_field){
                        
                        let parentValue = multiInputValue(
                            key,
                            listField.parent_field
                        );
                        if(parentValue !== listField.parent_value){
                            showField = false;
                        }
                        // console.log(listField.parent_field);
                        // console.log(listField.parent_value);
                        // console.log(parentValue);
                    }

                    let displayField = '';
                    if(!showField){
                        displayField = 'none'
                    }
                    
                    //defaultElement[fieldkey] = listField.default;
                    if (listFieldType == "string") {
                        multiInputElements.push(
                            
                            <Row style={{display:displayField}}>
                                <Col md="6">
                                    <div className="designLabel ">
                                        <Label className="smLabl-txt">{listField.label}{listField.required?<span class="text-danger"> * </span>:''}</Label>
                                    </div>
                                </Col>
                                <Col md="6">
                                    <div className="inptFom">
                                        <FormGroup
                                            className={
                                                props.fieldStates[
                                                    "error_" + listFieldId
                                                ]
                                            }
                                            >
                                            <Input
                                                type="text"
                                                name={listFieldName}
                                                id={listFieldId}
                                                value={multiInputValue(
                                                    key,
                                                    fieldkey
                                                )}
                                                onChange={(event) =>
                                                    handleMultiValueChange(
                                                        listFieldId,
                                                        event,
                                                        listField
                                                    )
                                                }
                                            />
                                             {props.fieldStates[
                                                "error_" + listFieldId
                                            ] === "has-danger" ? (
                                                <label className="error">
                                                    {
                                                        props.fieldStates[
                                                            "error_message_" +
                                                                listFieldId
                                                        ]
                                                    }
                                                </label>
                                            ) : null} 
                                        </FormGroup>
                                    </div>
                                </Col>
                            </Row>
                        );
                    } else if (listFieldType == "boolean") {
                        multiInputElements.push(
                            <Row style={{display:displayField}}>
                                <Col md="6">
                                    <div className="designLabel">
                                        <Label className="smLabl-txt">{listField.label}{listField.required?<span class="text-danger"> * </span>:''}</Label>
                                    </div>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <Switch
                                            name={listFieldName}
                                            offColor="success"
                                            offText={
                                                <i className="nc-icon nc-simple-remove" />
                                            }
                                            onColor="success"
                                            onText={
                                                <i className="nc-icon nc-check-2" />
                                            }
                                            value={multiInputValue(
                                                key,
                                                fieldkey
                                            )}
                                            onChange={(value, enabled) =>
                                                handleMultiValueEnumChange(
                                                    listFieldId,
                                                    { value: enabled },
                                                    listField
                                                )
                                            }
                                        />{" "}
                                    </FormGroup>
                                </Col>
                            </Row>
                        );
                    } else if (listFieldType == "integer") {
                        multiInputElements.push(
                            <Row style={{display:displayField}}>
                                <Col md="6">
                                    <div className="designLabel">
                                        <Label className="smLabl-txt">{listField.label}{listField.required?<span class="text-danger"> * </span>:''}</Label>
                                    </div>
                                </Col>
                                <Col md="6">
                                    <div className="inptFom">
                                        <FormGroup
                                            className={
                                                props.fieldStates[
                                                    "error_" + listFieldId
                                                ]
                                            }>
                                            
                                            <Input
                                                type="number"
                                                name={listFieldName}
                                                id={listFieldId}
                                                value={multiInputValue(
                                                    key,
                                                    fieldkey
                                                )}
                                                onChange={(event) =>
                                                    handleMultiValueChange(
                                                        listFieldId,
                                                        event,
                                                        listField
                                                    )
                                                }
                                            />
                                             {props.fieldStates[
                                                "error_" + listFieldId
                                            ] === "has-danger" ? (
                                                <label className="error">
                                                    {
                                                        props.fieldStates[
                                                            "error_message_" +
                                                                listFieldId
                                                        ]
                                                    }
                                                </label>
                                            ) : null} 
                                        </FormGroup>
                                    </div>
                                </Col>
                            </Row>
                        );
                    } else if (listFieldType == "hidden") {
                        multiInputElements.push(
                            <Row style={{display:displayField}}>
                                <Col md="6">
                                    <div className="lblName">
                                        <Label >{listField.label}{listField.required?<span class="text-danger"> * </span>:''}</Label>
                                    </div>
                                </Col>
                                <Col md="6">
                                    <div className="inptFom">
                                        <FormGroup>
                                            <Input
                                                type="hidden"
                                                name={listFieldName}
                                                id={listFieldName}
                                                value={multiInputValue(
                                                    key,
                                                    fieldkey
                                                )}
                                                onChange={(event) =>
                                                    handleMultiValueChange(
                                                        listFieldId,
                                                        event,
                                                        listField
                                                    )
                                                }
                                            />
                                        </FormGroup>
                                    </div>
                                </Col>
                            </Row>
                        );
                    } else if (listFieldType == "hex_color") {
                        multiInputElements.push(
                            <Row style={{display:displayField}}>
                                <Col md="6">
                                    <div className="designLabel">
                                        <Label className="smLabl-txt">{listField.label}{listField.required?<span class="text-danger"> * </span>:''}</Label>
                                    </div>
                                </Col>
                                <Col md="6">
                                    <div className="inptFomColr">
                                        <div className="clrChng" style={{display:"inline-block", border:"none", }}>

                                        <Input
                                            style={{
                                                background: multiInputValue(
                                                    key,
                                                    fieldkey
                                                ),
                                            }}
                                            type="color"
                                            name={listFieldName}
                                            id={listFieldId}
                                            value={multiInputValue(
                                                key,
                                                fieldkey
                                            )}
                                            onChange={(event) =>
                                                handleMultiValueChange(
                                                    listFieldId,
                                                    event,
                                                    listField
                                                )
                                            }
                                        />
                                        </div>
                                        <div className="clrTxt" style={{display:"inline-block"}}>
                                        <input 
                                        //   style={{border:"none" }}
                                        type="text"
                                        name={listFieldName}
                                                id={listFieldId}
                                                value={multiInputValue(
                                                    key,
                                                    fieldkey
                                                )}
                                                onChange={(event) =>
                                                    handleMultiValueChange(
                                                        listFieldId,
                                                        event,
                                                        listField
                                                    )
                                                }
                                        />
                                        </div>

                                    
                                         {props.fieldStates[
                                                "error_" + listFieldId
                                            ] === "has-danger" ? (
                                                <label className="error" id={props.parent+listFieldId}>
                                                    {
                                                        props.fieldStates[
                                                            "error_message_" +
                                                                listFieldId
                                                        ]
                                                    }
                                                </label>
                                            ) : null} 
                                    </div>

                                </Col>
                            </Row>
                        );
                    } else if (listFieldType == "url") {
                        multiInputElements.push(
                            <Row style={{display:displayField}}>
                                <Col md="6">
                                    <div className="designLabel">
                                        <Label className="smLabl-txt">{listField.label}{listField.required?<span class="text-danger"> * </span>:''}</Label>
                                    </div>
                                </Col>
                                <Col md="6">
                                    <div className="inptFom">
                                        <FormGroup
                                            className={
                                                props.fieldStates[
                                                    "error_" + listFieldId
                                                ]
                                            }
                                            >
                                            <Input
                                                type="url"
                                                name={listFieldName}
                                                id={listFieldId}
                                                value={multiInputValue(
                                                    key,
                                                    fieldkey
                                                )}
                                                onChange={(event) =>
                                                    handleMultiValueChange(
                                                        listFieldId,
                                                        event,
                                                        listField
                                                    )
                                                }
                                            />
                                             {props.fieldStates[
                                                "error_" + listFieldId
                                            ] === "has-danger" ? (
                                                <label className="error">
                                                    {
                                                        props.fieldStates[
                                                            "error_message_" +
                                                                listFieldId
                                                        ]
                                                    }
                                                </label>
                                            ) : null} 
                                        </FormGroup>
                                    </div>
                                </Col>
                            </Row>
                        );
                    } else if (listFieldType == "enum") {
                        //console.log(elementName);

                        multiInputElements.push(
                            <Row style={{display:displayField}}>
                                <Col md="6">
                                    <div className="designLabel">
                                        <Label className="smLabl-txt">{listField.label}{listField.required?<span class="text-danger"> * </span>:''}</Label>
                                    </div>
                                </Col>
                                <Col md="6">
                                    <div className="inptFom">
                                        <FormGroup
                                            className={
                                                props.fieldStates[
                                                    "error_" + listFieldId
                                                ]
                                            }
                                            >
                                            <Select
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                name={listFieldName}
                                                
                                                value={multiInputEnumValue(
                                                    key,
                                                    fieldkey,
                                                    listField.type_items
                                                )}
                                                // options={filterDropdownElement(fieldkey,listField.type_items)}
                                                options ={listField.type_items}
                                                onChange={(value) =>
                                                    handleMultiValueEnumChange(
                                                        listFieldId,
                                                        value,
                                                        listField
                                                    )
                                                }
                                            />
                                            <br></br>
                                             {props.fieldStates[
                                                "error_" + listFieldId
                                            ] === "has-danger" ? (
                                                <label className="error">
                                                    {
                                                        props.fieldStates[
                                                            "error_message_" +
                                                                listFieldId
                                                        ]
                                                    }
                                                </label>
                                            ) : null} 
                                            <div id={listFieldId} tabIndex="0"></div>
                                        </FormGroup>
                                    </div>
                                </Col>
                            </Row>
                        );
                    } else if (listFieldType == "file_upload") {
                        multiInputElements.push(
                            <Row style={{display:displayField}}>
                                <Col md="6">
                                    <div className="designLabel">
                                        <Label className="smLabl-txt">
                                            {listField.label}
                                            {listField.size ? (
                                                <span>
                                                    ({listField.size})
                                                </span>
                                            ) : (
                                                ""
                                            )}{listField.required?<span class="text-danger"> * </span>:''}
                                        </Label>
                                    </div>
                                </Col>
                                <Col sm="6">
                                    <div className="inptFom">
                                        <FormGroup
                                            className={
                                                props.fieldStates[
                                                    "error_" + listFieldId
                                                ]
                                            }
                                            >
                                            <ImageUpload
                                                projectId={props.projectId}
                                                imageDetails={listField}
                                                elementValue={
                                                    props.currentTabConfig
                                                        ? multiInputValue(
                                                            key,
                                                            fieldkey
                                                        )
                                                        : ""
                                                }
                                                id={listFieldId}
                                                handleEnumChange={(id,image)=>handleMultiImageChange(listFieldId,image,fieldkey)}
                                            />
                                            <Input
                                                type="hidden"
                                                name={listFieldName}
                                                id={listFieldId}
                                                value={multiInputValue(
                                                    key,
                                                    fieldkey
                                                )}
                                                onChange={(event) =>
                                                    handleMultiValueChange(
                                                        listFieldId,
                                                        event,
                                                        listField
                                                    )
                                                }
                                            />
                                             {props.fieldStates[
                                                "error_" + listFieldId
                                            ] === "has-danger" ? (
                                                <label className="error">
                                                    {
                                                        props.fieldStates[
                                                            "error_message_" +
                                                                listFieldId
                                                        ]
                                                    }
                                                </label>
                                            ) : null} 
                                        </FormGroup>
                                    </div>
                                </Col>
                            </Row>
                        );
                    }else  if (listFieldType == "html") {
                        multiInputElements.push(
                            <Row>
                                <Col md="6">
                                    <div className="designLabel">
                                        <Label className="smLabl-txt">{listField.label}{listField.required?<span class="text-danger"> * </span>:''}</Label>
                                    </div>
                                </Col>
                                <Col sm="6">
                                    <div className="inptFom">
                                        <FormGroup
                                            className={
                                                props.fieldStates[
                                                    "error_" + listFieldId
                                                ]
                                            }>
                                            <textarea
                                                type="textarea"
                                                className="txtarea"
                                                name={listFieldName}
                                                id={listFieldId}
                                                value={multiInputValue(
                                                    key,
                                                    fieldkey
                                                )}
                                                onChange={(event) =>
                                                    handleMultiValueChange(
                                                        listFieldId,
                                                        event,
                                                        listField
                                                    )
                                                }
                                            />
                                            {props.fieldStates[
                                                "error_" + listFieldId
                                            ] === "has-danger" ? (
                                                <label className="error" id={props.parent+listFieldId}>
                                                    {
                                                        props.fieldStates[
                                                            "error_message_" +
                                                                listFieldId
                                                        ]
                                                    }
                                                </label>
                                            ) : null}
                                        </FormGroup>
                                    </div>
                                </Col>
                            </Row>
                        );
                    }else {
                        multiInputElements.push(
                            <Row style={{display:displayField}}>
                                <Col md="6">
                                    <div className="designLabel">
                                        <Label className="smLabl-txt">{listField.label}{listField.required?<span class="text-danger"> * </span>:''}</Label>
                                    </div>
                                </Col>
                                <Col sm="6">
                                    <div className="inptFom">
                                        <FormGroup
                                            className={
                                                props.fieldStates[
                                                    "error_" + listFieldId
                                                ]
                                            }
                                            >
                                            <Input
                                                type="text"
                                                name={listFieldName}
                                                id={listFieldId}
                                                value={multiInputValue(
                                                    key,
                                                    fieldkey
                                                )}
                                                onChange={(event) =>
                                                    handleMultiValueChange(
                                                        listFieldId,
                                                        event,
                                                        listField
                                                    )
                                                }
                                            />
                                             {props.fieldStates[
                                                "error_" + listFieldId
                                            ] === "has-danger" ? (
                                                <label className="error">
                                                    {
                                                        props.fieldStates[
                                                            "error_message_" +
                                                                listFieldId
                                                        ]
                                                    }
                                                </label>
                                            ) : null} 
                                        </FormGroup>
                                    </div>
                                </Col>
                            </Row>
                        );
                    }
                });

                let removeIndex = nextIndex + 1;
                multiInputElements.push(
                    <Button
                        className="btn-smb btnRd"
                        type="button"
                        onClick={(value) =>
                            removeMultiInputValueElement(removeIndex)
                        }>
                        Remove
                    </Button>
                );

                nextIndex++;
                //})
            }
        }

        element.push(
            <Row>
                <Col md="12">
                    <Label  className="subHeading_h4">
                        <b>{elementDesign.label}</b>
                    </Label>
                </Col>
                <Col md="12">
                    <div id={elementId}>
                        {multiInputElements}
                        {props.fieldStates["error_" + elementId] ===
                            "has-danger" ? (
                                <label className="error" style={{color:'#FFC0A4'}}>
                                    {
                                        props.fieldStates[
                                            "error_message_" + elementId
                                        ]
                                    }

                                </label>
                            ) : null
                        } 
                    </div>
                    
                </Col>
                <Col md="8">
                    <div className="dflLft">
                        <Button
                            className="btn-smb"
                            type="button"
                            onClick={(value) =>
                                addMultiInputValueElement(nextIndex, defaultElement)
                            }>
                            Add More
                        </Button>
                    </div>

                    {/* {nextIndex>1?<Button
          className="btn-round"
          color="danger"
          type="button"
          onClick={(value)=>removeMultiInputValueElement(nextIndex) }
        >Remove</Button>:''} */}
                </Col>
            </Row>
        );
    } else if(elementType == "html") {
        element.push(
            <>
                <Row>
                    <Col sm="6">
                        <div className="designLabel">
                            <small className="text-default">
                                {elementDesign.label}
                            </small>
                        </div>
                        {helpText != "" ? <Label className="smLabl-txt">{helpText}</Label> : ""}
                    </Col>

                    <Col sm="6">
                        <div className="inptFom1">
                        <FormGroup
                            className={props.fieldStates["error_" + elementId]}>
                            <textarea
                                type="textarea"
                                className="txtarea"
                                name={elementName}
                                id={elementId}
                                value={currentValue()}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </FormGroup>
                        {props.fieldStates["error_" + elementId] ===
                        "has-danger" ? (
                            <label className="error" id={props.parent+elementId}>
                                {
                                    props.fieldStates[
                                        "error_message_" + elementId
                                    ]
                                }
                            </label>
                        ) : null}
                        </div>
                    </Col>
                </Row>
            </>
        );
    }else {
        element.push(
            <Row>
                <Col sm="6">
                        <div className="designLabel">
                            <small className="text-default">
                                {elementDesign.label}
                            </small>
                        </div>
                        {helpText != "" ? <Label className="smLabl-txt">{helpText}</Label> : ""}
                </Col>

                <Col sm="6">
                    <div className="inptFom1">
                        <FormGroup
                             className={props.fieldStates["error_" + elementId]}
                            >
                            <Input
                                type="text"
                                name={elementName}
                                id={elementId}
                                value={currentValue()}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </FormGroup>
                     {props.fieldStates["error_" + elementId] ===
                    "has-danger" ? (
                        <label className="error">
                            {props.fieldStates["error_message_" + elementId]}
                        </label>
                    ) : null} 
                </div>
                </Col>
            </Row>
        );
    }

    return (
        <div className="container">
            <ReactTooltip
                place="top"
                border="true"
                effect="solid"
                type="light"
                html={true}
            />
            {element}
        </div>
    );
};

export default ConfigFormComponent;

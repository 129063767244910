import React, { useEffect, useState, useContext } from "react";
import projectService from "../../services/api/projects-service";
import {useHistory} from "react-router-dom";
import ProjectContext from '../../ProjectContext';

// reactstrap components
import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Spinner,
  Row,
  Col,
} from "reactstrap";

const MediaStorageCountWidget = (props)=> {
  const projectDetails = useContext(ProjectContext);
  const history = new useHistory();
  
  if(projectDetails.id==''){
    history.push("/project/allprojects");
  }
  const projectId = projectDetails.id;
  const [totalStorage,setTotalStorage] = useState(0);
  const [renderComponent,setRenderComponent] = useState(false);
 
  useEffect( () => {
    (async () =>{
        let storageData = await projectService.getProjectStorageMedia(projectId);
            if(storageData.status && storageData.data.count){
                let mediaStorage = storageData.data.count;
                if(mediaStorage > 1000){
                    mediaStorage = (Math.round(mediaStorage/1000 * 100) / 100).toFixed(0)+'MB';
                }else{
                    mediaStorage = (Math.round(mediaStorage * 100) / 100).toFixed(0)+'KB';
                }
                setTotalStorage(mediaStorage)
            }
            setRenderComponent(true);
        })();
    }, []);  
 

    return (
        <Card className="card-stats zooM">
        
              <div className="icnBx">
              <i className="fa fa-hdd-o" />
              </div>
            
              <div className="numbers">
                <h2 className="card-category">Media Storage</h2>
                <p>
                    {!renderComponent?
                    <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                  :totalStorage}
                </p>
              </div>
      </Card>
    );
  
  
}

export default MediaStorageCountWidget;

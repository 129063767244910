import React, { useEffect, useState } from "react";
import allListingService from "../../services/api/Jwplayer/allListing-service";
import { Col, Row, Spinner, Table } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCode } from "@fortawesome/free-solid-svg-icons";

import EmbedPopup from "./EmbedPopup";

import "./style/playlist.css";

const JwPlayerPlaylists = ({ projectId }) => {
    const [pageNum, setPageNum] = useState(1);
    const [playlistData, setPlayListData] = useState([]);
    const [loader, setLoader] = useState(true);
    const [showEmbed, setShowEmbed] = useState(false);

    const [selectedPlaylistData, setSelectedPlaylistData] = useState({
        id: "",
        name: "",
    });

    const showEmbedCode = (playlistId, playlistName) => {
        setSelectedPlaylistData({ id: playlistId, name: playlistName });
        setShowEmbed(!showEmbed);
    };

    const fetchAllPlaylists = async () => {
        const data = await allListingService.getAllPlaylists(
            pageNum,
            projectId
        );
        setPlayListData(data.data.data.data.playlists);
        setLoader(false);
    };

    useEffect(() => {
        fetchAllPlaylists();
    }, []);

    return (
        <div className="content be-jwCont">
            <div className="container">
                <Row>
                    <Col md="12">
                        <div className="card">
                            <div className="card-body">
                                <Table responsive>
                                    <thead>
                                        <tr>
                                            <th>Title</th>
                                            <th>Playlist ID</th>
                                            <th>Type</th>
                                            <th>Created On</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    {loader && (
                                        <tbody>
                                            <tr>
                                                <td
                                                    colSpan="6"
                                                    className="text-center"
                                                >
                                                    <Spinner
                                                        animation="border"
                                                        role="status"
                                                    ></Spinner>
                                                </td>
                                            </tr>
                                        </tbody>
                                    )}
                                    <tbody>
                                        {playlistData.map((item, key) => (
                                            <tr key={key}>
                                                <td>{item.metadata.title}</td>
                                                <td>{item.id}</td>
                                                <td>{item.type}</td>
                                                <td>
                                                    {item.created.split("T")[0]}
                                                </td>
                                                <td>
                                                    <div className="mb-3">
                                                        <FontAwesomeIcon
                                                            title="Embed Code"
                                                            style={{
                                                                cursor: "pointer",
                                                            }}
                                                            onClick={() => {
                                                                showEmbedCode(
                                                                    item.id,
                                                                    item
                                                                        .metadata
                                                                        .title
                                                                );
                                                            }}
                                                            icon={faCode}
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                <EmbedPopup
                                    showEmbed={showEmbed}
                                    toggleEmbed={() => {
                                        setShowEmbed(!showEmbed);
                                    }}
                                    data={selectedPlaylistData}
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default JwPlayerPlaylists;

import React, { useEffect, useState, useContext } from "react";
import contentService from "../../services/api/content-service";
import {useHistory} from "react-router-dom";
import ProjectContext from '../../ProjectContext';
import Pagination from "react-js-pagination";
import Moment from 'react-moment';
import ReactBSAlert from "react-bootstrap-sweetalert";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash,faTrashRestoreAlt } from '@fortawesome/free-solid-svg-icons'
import sortdLogo from "../../assets/img/sortdlogol.png"

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Alert,
  Spinner,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

import Select from "react-select";

const ArticlesWidget = (props)=> {
  const projectDetails = useContext(ProjectContext);
  const history = new useHistory();
  
  if(projectDetails.id==''){
    history.push("/project/allprojects");
  }
  const projectId = projectDetails.id;
  const [articles, setArticles] = useState(false);
  const [formdetails, setFormDetails] = useState({categoryids:[],title:''});
  const [activePage,setActivePage] = useState(1);
  const [renderComponent,setRenderComponent] = useState(false);
  const [showPopup,setShowPopup] = useState({alert:null});
  const [reloadComponent,setReloadComponent] = useState(0);
  const records = 10;
 

  useEffect( () => {
    (async () =>{
        let articlesData = await contentService.getArticles(projectId,formdetails,activePage,records);
            if(articlesData.status){
                setArticles(articlesData.data.articles);
            }
            setRenderComponent(true);
        })();
    }, [reloadComponent]);

    const onImageError = (event) => {
        // event.target.src = "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/React-icon.svg/1200px-React-icon.svg.png";
       event.target.src = "https://mcmscache.epapr.in/mcms/461/9e326070049c91c0a19855f334fe114b53f733e0.svg";
         
        console.log(event.target.src); 

    }

    const deleteArticlePopup = (articleId) => {
      setShowPopup({
        alert: (
          <ReactBSAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title="Are you sure?"
            onConfirm={() => deleteArticle(articleId)}
            onCancel={() => hideAlert()}
            confirmBtnBsStyle="info"
            cancelBtnBsStyle="danger"
            confirmBtnText="Yes, delete it!"
            cancelBtnText="Cancel"
            showCancel
            btnSize=""
          >
            You want to delete this article!
          </ReactBSAlert>
        ),
      });
    }

    const restoreArticlePopup = (articleId) => {
      setShowPopup({
        alert: (
          <ReactBSAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title="Are you sure?"
            onConfirm={() => restoreArticle(articleId)}
            onCancel={() => hideAlert()}
            confirmBtnBsStyle="info"
            cancelBtnBsStyle="danger"
            confirmBtnText="Yes, restore it!"
            cancelBtnText="Cancel"
            showCancel
            btnSize=""
          >
            You want to restore this article!
          </ReactBSAlert>
        ),
      });
    }

    const deleteArticle = async (articleId) => {
      let delArticle = await contentService.deleteArticle(articleId, projectId);
      if(projectDetails.isContractExpired){
        setShowPopup({alert:null});
        projectDetails.setContractExpiredAlert(projectDetails)
      }
      else if(delArticle.status && delArticle.data.deleted){
        setShowPopup({
          alert: (
            <ReactBSAlert
              success
              style={{ display: "block", marginTop: "-100px" }}
              title="Deleted!"
              onConfirm={() => hideAlert()}
              onCancel={() => hideAlert()}
              confirmBtnBsStyle="info"
              btnSize=""
            >
              Article deleted successfully.
            </ReactBSAlert>
          ),
        });
          setReloadComponent(Math.random());
      }else{
        setShowPopup({
          alert: (
            <ReactBSAlert
              danger
              style={{ display: "block", marginTop: "-100px" }}
              title="Please try again!"
              onConfirm={() => hideAlert()}
              onCancel={() => hideAlert()}
              confirmBtnBsStyle="info"
              btnSize=""
            >
              Article could not be deleted. Please try again.
            </ReactBSAlert>
          ),
        });
      }
    }

    const restoreArticle = async (articleId) => {
      let resArticle = await contentService.restoreArticle(articleId,projectId);
      if(projectDetails.isContractExpired){
        setShowPopup({alert:null});
        projectDetails.setContractExpiredAlert(projectDetails)
      }
      else if(resArticle.status && resArticle.data.restored){
        setShowPopup({
          alert: (
            <ReactBSAlert
              success
              style={{ display: "block", marginTop: "-100px" }}
              title="Restored!"
              onConfirm={() => hideAlert()}
              onCancel={() => hideAlert()}
              confirmBtnBsStyle="info"
              btnSize=""
            >
              Article restored successfully.
            </ReactBSAlert>
          ),
        });
        setReloadComponent(Math.random());
      }else{
        setShowPopup({
          alert: (
            <ReactBSAlert
              danger
              style={{ display: "block", marginTop: "-100px" }}
              title="Please try again!"
              onConfirm={() => hideAlert()}
              onCancel={() => hideAlert()}
              confirmBtnBsStyle="info"
              btnSize=""
            >
              Article could not be restored. Please try again.
            </ReactBSAlert>
          ),
        });
      }
    }

    const hideAlert = () => {
      setShowPopup({
        alert: null,
      });
    };



  let articlesElements = [];
  let firstRecord = 0;
  let lastRecord  = 0;
  if(!articles || !articles.length){
    if(!renderComponent){
      articlesElements.push(
        <td colSpan="4" className="text-center">
            <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
        </td>
      );
    }else{
    articlesElements.push(
        <td colSpan="4" className="text-center">
            <CardTitle tag="h4" className="noRslt">No Article Found</CardTitle>
        </td>
    );
    }
  }else{
      let i=1;
      articles.forEach(function(articleDetails) {
          let className = '';
          if(!articleDetails.status){
            className = 'table-gray';
          }
          if(i==1){
            firstRecord = (activePage-1)*records+i;
          }
        articlesElements.push(
            <tr id={"row_"+articleDetails.id} className={className}>
               <td className="text-left recntArtc_dv" >
                {articleDetails.thumburl?
                <img src={articleDetails.thumburl} width="120" height="70" onError={onImageError}></img>
                :
                <img src={sortdLogo} width="120" height="50" onError={onImageError}></img>
                }
               </td>
               <td className="text-left">
                   {articleDetails.title}
               </td>
               <td className="text-center">
                   {articleDetails.status?
                  <a title="Delete" onClick={(event)=>  deleteArticlePopup(articleDetails.id) } href="javascript:void(0)" class="dlIcn">
                  {/* <i class="nc-icon nc-simple-remove"></i> */}
                  {/* <FontAwesomeIcon icon={faTrash} /> */}
                  <i class="fa fa-trash-o" aria-hidden="true"></i>
                </a>
                :
                <a title="Restore" onClick={(event)=>  restoreArticlePopup(articleDetails.id) } href="javascript:void(0)" class="nav-restr">
                    {/* <i class="nc-icon nc-refresh-69"></i> */}
                    <FontAwesomeIcon icon={faTrashRestoreAlt} />
                  </a> 
                  }
               </td>

            </tr>
        )
        if(i==articles.length){
          lastRecord = (activePage-1)*records+i;
        }
        i++;
        if(i==articles.length){
          lastRecord = (activePage-1)*records+i;
        }
    })
  }

 
    return (
      <div className="content">
        {showPopup.alert}
          <Row>
          <Col className="ml-auto mr-auto"  md="12">
              <Card className="card-signup ">
                <CardHeader>
                <CardTitle tag="h4" className="heding_dsh_h4">
                    Recent Articles
                </CardTitle>
               
                <hr></hr>
                
                </CardHeader>
                <CardBody>
                <Table>
                    <thead className="text-primary">
                      
                    </thead>
                    <tbody>{articlesElements}</tbody>
                  
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
      </div>
    );
  
  
}

export default ArticlesWidget;

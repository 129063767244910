import React from 'react'
import './VideoUploadForm.css'
import promoImages from '../../assets/image/premium-logo.png'
import facebookImages from '../../assets/image/facebook2x.png'
import instaImage from '../../assets/image/instagram2x.png'
import twitterImage from '../../assets/image/twitter2x.png'
import youtubeImage from '../../assets/image/youtube2x.png'
import themeImage from '../../assets/image/theme.png'
import discountImage from '../../assets/image/discount-bro.png'
import windowsImage from '../../assets/image/windows-cuate.png'
import insertBlockImage from '../../assets/image/insert-block-amico.png'
import refundImage from '../../assets/image/refund-amico.png'
import handImage from '../../assets/image/hand.png'


const PromotionalPage = () => {
  
  
    
    return (
      <div>
        <header>
          <div className="header-top">
            <div className="container">
              <div className="col-md-12">
                <div className="navigationBox">
                  <nav className="navbar navbar-expand-lg navbar-light bg-light sticky-top">
                    {/* <a className="navbar-brand" href="https://www.readwhere.com/upgrade-the-publisher"><img src={promoImages} /></a> */}
                    {/* <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                      </button> */}
                    <div className id="navbarText">
                      {/* <a target="_blank" href="https://www.facebook.com/myreadwhere"><img src={facebookImages} /></a>
                      <a target="_blank" href="https://www.instagram.com/accounts/login/?next=/myreadwhere/"><img src={instaImage} /></a>
                      <a target="_blank" href="https://twitter.com/myreadwhere"><img src={twitterImage} /></a>
                      <a target="_blank" href="https://www.youtube.com/c/ReadwhereDotCom"><img src={youtubeImage} /></a> */}
                      <span>
                        Contact Us: <a href="mailto:helpdesk@sortd.mobi" style={{color:"grey"}}>helpdesk@sortd.mobi</a>
                      </span>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <div className="banner-sectn">
            <div className="container">
              <div className="row">
                <div className="col-md-5 dn-l">
                  <div className="upgrImg">
                    <img src={themeImage} />
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="banerHed">
                    <h1>Sortd Video Player </h1>
                    <p>Sortd Video Player uses adaptive bitrate streaming to deliver video at the highest possible quality, depending on the viewer's device and network connection. </p>
                    <p>Sortd Video Player supports a wide range of video and audio formats, including MP4, WebM, MPEG.</p> 
                    <p>Sortd Video Player provides detailed analytics about how viewers are watching their videos. This information can be used to improve the video experience for viewers and to make better decisions about video content.</p>
                    <a href="#"><i className="bi bi-mouse" /> Enable Now</a>
                  </div> 
                </div>
                <div className="col-md-5 dn-s">
                  <div className="upgrImg">
                    {/* <img src={themeImage} /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        <section className="featureBox">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="featImg ">
                  {/* <img src={discountImage} /> */}
                </div>
              </div>
              <div className="col-md-6">
                <div className="featCont">
                  
                  <h3>ANALYTICS</h3>
                  <p> This information can be used to improve the video experience for viewers and to make better decisions about video content. For example, if you know that viewers are not completing a particular video, you can edit the video to make it more engaging. Or, if you know that a particular topic is popular with your viewers, you can create more videos on that topic.</p>
                  <p>Sortd video Player's analytics can be used to learn about the demographics of your viewers and the topics that they are interested in. You can then use this information to create more relevant content that is more likely to appeal to your target audience. </p> 
                  <p>Sortd video player's analytics can be used to track the performance of your video marketing campaigns. You can then use this information to optimize your campaigns and improve your results.</p>
                  <a href="#" className="actNbtn"> Contact Us Now</a>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="featCont mt-5">
                  
                  <h3><strong>Analytics</strong>, <strong>Dashboard</strong> and many other features</h3>
                  <h5>Helps you to make profitable business decision</h5>
                  {/* <p><i className="bi bi-cone-striped" /> Publisher user with super-admin rights can log-in to Readwhere Publisher panel</p>
                  <p><i className="bi bi-cone-striped" /> You will see a message to free upgrade for two months. Until 31 st July 2022, you can publish all your editions from new publisher (V6) without any hindrance. </p> 
                  <p><i className="bi bi-cone-striped" /> You can explore the new dashboard &amp; its advanced features like themes, self-managed ad codes, e-paper widget for your main site etc.</p> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section className="salientBox">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <div className="salntFetCard">
                  <h4>Dashboard</h4>
                  <span className="svgIcn">
                    <img src={windowsImage} />
                  </span>
                  <p>Publishers can now manage the full aspect of the e-paper website. Setting up pre-crafted themes, changing the brand logo &amp; colours , managing collections is now available with publisher.</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="salntFetCard">
                  <h4>Analytics</h4>
                  <span className="svgIcn">
                    <img src={insertBlockImage} />
                  </span>
                  <p>Publishers can now keep 100% of revenue earned by ads. Our new version gives the publishers full liberty to manage their own ads. All ad slots are now configured through dashboard so that the publisher can manage the ads themselves.</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="salntFetCard">
                  <h4>video Usage</h4>
                  <span className="svgIcn">
                    <img src={refundImage} />
                  </span>
                  <p>With the new pay-as-you-go model, we will charge the publishers only for the volumes being published. Publisher can anytime upgrade the volume publishing quota as per the publishing need. It is simple &amp; transparent model.</p>
                </div>
              </div>
              <div className="col-md-12">
                <div className="salientCont">
                  <h3>We are here to help you in your business </h3>
                  <p>Book a slot for detailed training with Readwhere customer success team</p>
                  <a href="#" className="actNbtn">Contact us Now</a>
                </div>
              </div>
            </div>
          </div>
        </section> */}
       
        
        <section className="videoBox">
          <div className="container">
            <div className="col-md-12">
              <div className="foterLogo">
                {/* <a href="https://www.readwhere.com/upgrade-the-publisher"><img src={promoImages} /></a> */}
                <p>Contact Us: <a href="mailto:helpdesk@sortd.mobi">helpdesk@sortd.mobi</a></p>
                <span className="fotrLink">
                  {/* <a target="_blank" href="https://www.facebook.com/myreadwhere"><img src={facebookImages} /></a>
                  <a target="_blank" href="https://www.instagram.com/accounts/login/?next=/myreadwhere/"><img src={instaImage} /></a>
                  <a target="_blank" href="https://twitter.com/myreadwhere"><img src={twitterImage} /></a>
                  <a target="_blank" href="https://www.youtube.com/c/ReadwhereDotCom"><img src={youtubeImage}/></a> */}
                </span>
              </div>
            </div>
          </div>
        </section>
        <div className="fotbtm">
        </div>
      </div>
    );
 
}

export default PromotionalPage

import React,{useState} from 'react';
import planService from '../../services/api/plan-service';
import ReactBSAlert from "react-bootstrap-sweetalert";
import SubscriptionService from '../../services/api/plan-service'
import {useHistory} from 'react-router-dom';
import moment from 'moment';
import {
    Label,
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Container,
    Row,
    Badge,
    Col,
    Spinner,
    Form,
    FormGroup,   
} from "reactstrap";
import Moment from "react-moment";
const SubscriptionDetails = (props)=>{  
    const [showPopup, setShowPopup] = useState({alert: null});
    const history=new useHistory();
    let subscriptionStarted = moment(new Date(props.activeSubscirption.start_at*1000)) <moment() ? true : false;
    const cancelSubscription = async() =>{
        setShowPopup({
            alert: (
              <ReactBSAlert
              info
                style={{ display: "block", marginTop: "-100px" }}
                title="Please wait"
                showCancel={false}
                showConfirm={false}
                confirmBtnBsStyle="info"
                confirmBtnText="Ok"
              >
                <Spinner
                    animation="border"
                    role="status">
                    <span className="sr-only">
                        Loading...
                    </span>
                </Spinner>
              </ReactBSAlert>
            )
          })

        const response = await SubscriptionService.cancelSubscription(props.activeSubscirption.pg_subscription_id);
        console.log(response.data);
        if(response.data && response.data.isCancelled){
            setShowPopup({
                alert: (
                  <ReactBSAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Success"
                    onConfirm={() =>hideAlert()}
                    confirmBtnBsStyle="info"
                    confirmBtnText="Ok"
                  >
                    Subscription Cancelled.
                  </ReactBSAlert>
                )
              })
        }else{
            setShowPopup({
                alert: (
                  <ReactBSAlert
                    danger
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Failed"
                    onConfirm={() =>hideAlert()}
                    confirmBtnBsStyle="info"
                    confirmBtnText="Ok"
                  >
                    Failed to cancel subscription, Try again.
                  </ReactBSAlert>
                )
              })
        }
        setTimeout(() => {
            hideAlert();
            history.go(0)
        }, 3000); 
    }

    const hideAlert =() =>{
        setShowPopup({
            alert: null
        })
    }

    const handleCancelSubscription = ()=>{
        setShowPopup({
            alert: (
              <ReactBSAlert
                info
                style={{ display: "block", marginTop: "-100px" }}
                title="Are you sure"
                onConfirm={() => cancelSubscription()}
                onCancel={() => hideAlert()}
                confirmBtnBsStyle="warning"
                btnSize=""
                showCancel={true}
                confirmBtnText="Yes"
                cancelBtnBsStyle="info"
                cancelBtnText="NO"
              >
                Do you want to cancel 
              </ReactBSAlert>
            )
          })
    }
    
    const getColorAsPerStatus =  () => {
        let status  = props.activeSubscirption.status;
        let warningStatus = ["pending","paused"];
        let successStatus = ["created","activated","authenticated","charged","completed","updated"];
        let dangerStatus = ["cancelled","halted"]
        if(successStatus.includes(status)) {
            return "success";
        }
        if(warningStatus.includes(status)) {
            return "warning"
        }
        if(dangerStatus.includes(status)) {
            return "danger"
        }
        
    }

    return <>
        {showPopup.alert}
        <Container>
            <Card>
                <CardHeader>
                    <CardTitle tag="h4" className="heding_tab_h4">
                        Plan Subscription Details
                    </CardTitle>
                </CardHeader>
                <CardBody>
                <div className="subsc">
        <Form className="form">
            <Row>
                <Col>
                    <FormGroup>
                        <Label for="labelFor">Subscription</Label>
                        <div id="labelFor">{props.activeSubscirption.plan_id.name}</div>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <Label for="labelFor">Starts From</Label>
                        <div id="labelFor"><Moment format="DD MMM YYYY hh:mm:ss a">{new Date(props.activeSubscirption.start_at*1000)}</Moment></div>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <Label for="labelFor">Active Till</Label>
                        <div id="labelFor"><Moment format="DD MMM YYYY hh:mm:ss a">{new Date(props.activeSubscirption.start_at*1000)}</Moment></div>
                    </FormGroup>
                </Col>
                {
                    props.activeSubscirption.charge_on ?
                        <Col>
                        <FormGroup>
                            <Label for="labelFor">Next Payment On</Label>
                            <div id="labelFor"><Moment format="DD MMM YYYY hh:mm:ss a">{new Date(props.activeSubscirption.charge_on*1000)}</Moment></div>
                        </FormGroup>
                    </Col>: null}
            </Row>
            <Row>
                {props.activeSubscirption.recurring_amount ? <Col>
                    <FormGroup>
                        <Label for="labelFor">Recurring Amount</Label>
                        <div id="labelFor"><i class="fa fa-inr" style={{fontSize: "13px"}} > </i> &nbsp;{props.activeSubscirption.recurring_amount}</div>
                    </FormGroup>
                </Col> : <Col></Col>}
                <Col>
                    <FormGroup>
                        <Label for="labelFor">Paid Count </Label>
                        <div id="labelFor">{props.activeSubscirption.paid_count}</div>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <Label for="labelFor">Remaining</Label>
                        <div id="labelFor">{props.activeSubscirption.total_count - props.activeSubscirption.paid_count}</div>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <Label for="labelFor">Subscription Status</Label>
                        <div id="labelFor"><Badge color={getColorAsPerStatus()}>
                            {   
                                props.activeSubscirption.cancel_requested_at? 
                                props.activeSubscirption.status == "cancelled"? "Cancelled" : "Cancellation pending":   
                                props.activeSubscirption.status
                            }
                            </Badge></div>
                    </FormGroup>
                </Col>
            </Row>
            {props.activeSubscirption.status !="cancelled"?
            <Row>
                <Col>
                    <FormGroup>
                        <Button className="btn btn-danger" onClick={handleCancelSubscription}>Cancel</Button>
                    </FormGroup>
                </Col>
            </Row>
            :""}
        </Form>
      </div>
                </CardBody>
            </Card>
        </Container>
    </>
}

export default SubscriptionDetails;
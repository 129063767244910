import React, { useEffect, useState, useContext } from "react";
import contentService from "../../services/api/content-service";
import {useHistory} from "react-router-dom";
import ProjectContext from '../../ProjectContext';
import './content.css';
import formValidations from "../../variables/form-validations";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Alert,
  Spinner,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

const CreateWebLink = (props)=> {
  const projectDetails = useContext(ProjectContext);
  const history = new useHistory();
  console.log(projectDetails.id);
  if(projectDetails.id==''){
    history.push("/project/allprojects");
  }

  const projectId = projectDetails.id;
  const [formdetails, setFormDetails] = useState({projectid:projectId,categoryname:'', categoryalias:'', webpage_url:''});
  const [categories, setCategories] = useState(false);
  const [renderComponent,setRenderComponent] = useState(false);
  const [showLoader, setShowLoader]   = useState(false);
  const [showSaveButton, setShowSaveButton]   = useState(true);
  const [alert, setAlert]   = useState({show:false,message:'',type:''});
  const [fieldStates,setFieldStates] = useState({});

  useEffect( () => {
    (async () =>{
        let categoryData = await contentService.getCategories(projectId);
            if(categoryData.status){
                setCategories(categoryData.data);
            }
        })();
    }, [formdetails]);

  
  const handleChange = (event) => {
    setFormDetails({
      ...formdetails,
      [event.target.id] : event.target.value,
    });
    fieldStates["error_"+event.target.id] = 'has-success';
    fieldStates["error_message_"+event.target.id] = '';
    setFieldStates({
                ...fieldStates,
                fieldStates
            });
  }
    const validateFields = ()=>{
        let validations = true;
            if(formdetails.categoryname.trim()===''){
                fieldStates['error_categoryname'] = 'has-danger';
                fieldStates['error_message_categoryname'] = 'This field is required.';
                validations = false;
            }else{
                fieldStates['error_categoryname'] = 'has-success';
                fieldStates['error_message_categoryname'] = '';
            }
            if(formdetails.categoryalias.trim()===''){
                fieldStates['error_categoryalias'] = 'has-danger';
                fieldStates['error_message_categoryalias'] = 'This field is required.';
                validations = false;
            }else{
                if(categories){
                  Object.keys(categories).forEach(function(key) {
                      let existingCategoryAlias = categories[key].alias;
                      if(existingCategoryAlias===formdetails.categoryalias.trim()){
                        validations = false;
                        fieldStates['error_categoryalias'] = 'has-danger';
                        fieldStates['error_message_categoryalias'] = 'Category already exist with same alias.';
                      }
                  })
                }else{
                  fieldStates['error_categoryalias'] = 'has-success';
                  fieldStates['error_message_categoryalias'] = '';
                }
            }

            if(formdetails.webpage_url.trim()===''){
                fieldStates['error_webpage_url'] = 'has-danger';
                fieldStates['error_message_webpage_url'] = 'This field is required.';
                validations = false;
            }else{
                if(!formValidations.verifyUrl(formdetails.webpage_url)){
                    fieldStates['error_webpage_url'] = 'has-danger';
                    fieldStates['error_message_webpage_url'] = 'Invalid URL.';
                    validations = false;
                }else{
                    fieldStates['error_webpage_url'] = 'has-success';
                    fieldStates['error_message_webpage_url'] = '';
                }
            }

            setFieldStates({
                ...fieldStates,
                fieldStates
            });
        return validations;
    }

  const handleSubmit = function(e){  validateForm();e.preventDefault() }
  
  const validateForm = async () => {
      if(validateFields()) {
        setShowLoader(true);
        setShowSaveButton(false);
        let response = await contentService.createWebpageCategory(formdetails);
        if(projectDetails.isContractExpired){
          setShowLoader(false);
          setShowSaveButton(true);
          projectDetails.setContractExpiredAlert(projectDetails);
        }
        else if(response.status){
            setTimeout(()=>{
            setShowLoader(false)
            setAlert({show:true,type:'success',message:'Weblink has created Successfully'});
            },1000);
            
            setTimeout(()=>{
              setAlert({show:false,type:'',message:''});
              props.setCreateWeblink(false);
            },3000);
            
        }else{
            setTimeout(()=>{
            setShowLoader(false)
            setShowSaveButton(true)
            setAlert({show:true,type:'danger',message:'Weblink could not be create, Please try again.'});
            },1000);

            setTimeout(()=>{
            setAlert({show:false,type:'',message:''});
            },3000);
        }
    }
  }
    return (
      <div className="content">
        <Container>
          <Row>
          <Col className="ml-auto mr-auto"  md="12">
              <Card className="card-signup text-center">
                <CardHeader>
                  <CardTitle tag="h4">Create Weblink</CardTitle>
                </CardHeader>
                <CardBody>
                  <Form className="form-horizontol" action="" id="createWeblink"  method="POST" onSubmit={handleSubmit}>

                    <Row>
                      <Label sm="2">Category Name</Label>
                      <Col sm="7">
                          <FormGroup className={fieldStates["error_categoryname"]}>
                              <Input
                                type="text"
                                id="categoryname"
                                name="categoryname"
                                value={formdetails.name}
                                onChange = {handleChange}
                                />
                                {fieldStates["error_categoryname"] ===
                                            "has-danger" ? (
                                                <label className="error">
                                                    {
                                                        fieldStates[
                                                            "error_message_categoryname"
                                                        ]
                                                    }
                                                </label>
                                            ) : null}
                          </FormGroup>
                      </Col>
                    </Row>
                
                    <Row>
                      <Label sm="2">Alias</Label>
                      <Col sm="7">
                          <FormGroup className={fieldStates["error_categoryalias"]}>
                              <Input
                                type="text"
                                id="categoryalias"
                                name="categoryalias"
                                value = {formdetails.categoryalias}
                                onChange = {handleChange}
                                />
                                {fieldStates["error_categoryalias"] ===
                                            "has-danger" ? (
                                                <label className="error">
                                                    {
                                                        fieldStates[
                                                            "error_message_categoryalias"
                                                        ]
                                                    }
                                                </label>
                                                ) : null}
                          </FormGroup>
                      </Col>
                    </Row> 
                    <Row>
                      <Label sm="2">Web URL</Label>
                      <Col sm="7">
                          <FormGroup className={fieldStates["error_webpage_url"]}>
                              <Input
                                type="text"
                                id="webpage_url"
                                name="webpage_url"
                                value={formdetails.webpage_url}
                                onChange = {handleChange}
                                />
                                {fieldStates["error_webpage_url"] ===
                                            "has-danger" ? (
                                                <label className="error">
                                                    {
                                                        fieldStates[
                                                            "error_message_webpage_url"
                                                        ]
                                                    }
                                                </label>
                                                ) : null}
                          </FormGroup>
                      </Col>
                    </Row>  
                {showSaveButton?
                    <>
                        <Button
                            className="btn-round"
                            color="info"
                            type="button"
                            onClick={validateForm}
                        >
                            Save
                        </Button>
                        <Button
                            className="btn-round"
                            color="danger"
                            type="button"
                            onClick={(event)=>  props.setCreateWeblink(false) }
                        >
                                Cancel
                        </Button>  
                    </>               
                  :''}
                  {alert.show?<Alert color={alert.type}>
                    {alert.message}
                  </Alert>:''}

                  {showLoader?<Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>:''}
                    
                  </Form>
                </CardBody>
                <CardFooter>
                  
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
          }}
        />
      </div>

            
    );
  
}

export default CreateWebLink;

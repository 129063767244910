import axios from "axios";

const createProject= async(formdata)=>{
   const response=await axios.post("/api/onboard/create-project",formdata);
   return response.data;
}
const getUnusedLicenseProjects= async(formdata)=>{
    console.log("FormData",formdata);
    const response=await axios.post("/api/onboard/get-project",formdata);
    return response.data;
 }

export default {
    createProject,
    getUnusedLicenseProjects
}
import React, { useEffect, useState, useContext } from "react";
import projectService from "../../services/api/projects-service";
import { useHistory } from "react-router-dom";
import UserContext from "../../UserContext";
import CreateProject from "./CreateProject";
import { useCookies } from "react-cookie";
import ReactBSAlert from "react-bootstrap-sweetalert";
import "./settings.css";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Alert,
  Spinner,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

const AllProjects = (props) => {
  const history = new useHistory();
  const userDetails = useContext(UserContext);
  if (!userDetails || userDetails.id == "") {
    //history.push("/auth/login");
  }

  const [projects, setProjects] = useState(false);
  const [createProject, setCreateProject] = useState(false);
  const [cookies, setCookie] = useCookies(["sortdprojectid"]);
  const [renderComponent, setRenderComponent] = useState(false);
  const [showPopup, setShowPopup] = useState({alert: null});

  useEffect(() => {
    (async () => {
      let projectsData = await projectService.getPublisherExistingProjects();
      console.log(projectsData);
      if (projectsData.status && projectsData.data) {
        if (!cookies.sortdprojectid && projectsData.data.length == 1) {
          window.location.href =
            "/dashboard/home?project=" + projectsData.data[0].slug;
        }
        setProjects(projectsData.data);
        setRenderComponent(true);
        if (projectsData.data.length == 0) {
          history.push("/project/create");
        }
      }
    })();
  }, []);

  const hideAlert = () => {
    setShowPopup({
      alert: null,
    });
  };

  const changeProject = (projectslug, status,pub_project_status) => {

    if(status==-1 || status == -2){
      setShowPopup({
        alert: (
          <ReactBSAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title="This project is deleted"
            onConfirm={() => {hideAlert()}}
            showCancel={false}
            btnSize=""
          >
          </ReactBSAlert>
        ),
      })
    }else if(pub_project_status === "false" || pub_project_status === "0"){
      setShowPopup({
        alert: (
          <ReactBSAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title="Access denied to the project"
            onConfirm={() => {hideAlert()}}
            showCancel={false}
            btnSize=""
          >
          </ReactBSAlert>
        ),
      })
    }
    else{
      setCookie("sortdprojectid", projectslug, { path: "/" });
      window.location.href = "/dashboard/home?project=" + projectslug;
    }
  };

  let projectElements = [];
  if (!projects || !projects.length) {
    if (!renderComponent) {
      projectElements.push(
        <td colSpan="4" className="text-center">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </td>
      );
    } else {
      projectElements.push(
        <td colSpan="4" className="text-center">
          <CardTitle tag="h4">
            No Project Created Yet. Please create a project to continue.
          </CardTitle>
        </td>
      );
    }
  } else {
    let i = 1;
    projects.forEach(function (projectDetails) {
      let className = "";
      if (projectDetails.slug === cookies.sortdprojectid) {
        className = "table-gray";
      }
      if (projectDetails.status == -1 || projectDetails.status == -2) {
        className = "table-danger";
      }
      if(!projectDetails.pub_project_status || projectDetails.pub_project_status === "false" || projectDetails.pub_project_status === "0"){
        className = "table-danger";
      }
      projectElements.push(
        <tr id={"row_" + projectDetails._id} className={className}>
          <td className="text-left">{i}</td>
          <td className="text-left">
            <a
              href="javascript:void(0);"
              onClick={(event) => changeProject(projectDetails.slug,projectDetails.status,projectDetails.pub_project_status )}
            >
              {projectDetails.name}
            </a>
          </td>
          <td className="text-left">
            <a
              href="javascript:void(0);"
              onClick={(event) => changeProject(projectDetails.slug,projectDetails.status,projectDetails.pub_project_status )}
            >
              {projectDetails.slug}
            </a>
          </td>
        </tr>
      );
      i++;
    });
  }

  if (createProject) {
    return <CreateProject />;
  } else {
    return (
      <div className="content mt-30">
        {showPopup.alert}
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" md="12">
              <Card className="card-signup text-center dp-box">
                <CardHeader>
                  <CardTitle tag="h4" className="heding_tab_h4">
                    Projects
                  </CardTitle>

                  <Button
                    style={{ float: "right" }}
                    className="btn-smb"
                    type="button"
                    onClick={(event) => setCreateProject(true)}
                  >
                    Create Project
                  </Button>
                </CardHeader>
                <CardBody>
                  <Table responsive>
                    <thead className="text-primary">
                      <th className="text-left">#</th>
                      <th className="text-left">Project Name</th>
                      <th className="text-left">Project ID</th>
                    </thead>
                    <tbody>{projectElements}</tbody>
                  </Table>
                </CardBody>
                {/* <CardFooter>
                  
                </CardFooter> */}
              </Card>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
          }}
        />
      </div>
    );
  }
};

export default AllProjects;

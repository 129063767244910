import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import ProjectContext from '../../ProjectContext';
import AndroidIcon from '../../assets/img/sortd-apps/android-ctv-removebg-preview.png';
import IOSIcon from '../../assets/img/sortd-apps/ios-tv.png';
import featuresService from '../../services/api/features-service.js';

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Alert,
    Spinner,
    Container,
    Row,
    Col,
    Input,
    CardImg
} from "reactstrap";
import formValidations from "../../variables/form-validations.js";
import './ctv-app-manager.css'

const CTVAppManager = (props) => {
    const projectDetails = useContext(ProjectContext);
    const history = new useHistory();
    console.log(props);
    if (projectDetails.id == '') {
        history.push("/project/allprojects");
    }

    const projectId = projectDetails.id;

    // let getProjectSlug = new URLSearchParams(props.location.search).get('project');
    // if (!getProjectSlug) {
    //     history.push({
    //         search: '?project=' + projectDetails.slug
    //     })
    // }

    const redirectUrl = (e, navigate, redirectPath, project) => {
        e.preventDefault()
        history.push("/" + navigate + "/" + redirectPath + "?project=" + project)
    }

    return (
        <div className="content mt-30">
            <div className="container">
                {/* <Card> */}
                <CardTitle>
                    <h4 align="center">SORTD Connected TV</h4>
                </CardTitle>
                <CardBody className="mainCard">
                    <div>
                        <Row align="right" className="manageContainer">
                            <Col sm="6">
                                <Card className="phonAp" align="center">
                                    <CardImg className="androidCardImage" src={AndroidIcon} />
                                    <CardBody>
                                        <CardTitle tag="h5">Android TV</CardTitle>
                                        <div className="container-grid">
                                            <div className="actionItem">
                                                <a title="App Settings" onClick={(event) => redirectUrl(event, 'ctv', 'settings', projectDetails.slug)}>
                                                    <i className="fa fa-cog fa-2x"></i>
                                                </a>

                                                <a title="Customizations" onClick={(event) => redirectUrl(event, 'ctv', 'config/android-customizations', projectDetails.slug)}>
                                                    <i className="fa fa-pencil fa-2x"></i>
                                                </a>
                                            </div>

                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col sm="6">
                                <Card className="phonAp" align="center">
                                    <CardImg className="iosCardImage" alt="Card image" src={IOSIcon} />
                                    <CardBody>
                                        <CardTitle align="center" tag="h5">IOS TV</CardTitle>
                                        <div className="container-grid">
                                            <div className="actionItem">
                                                <a title="App Settings" onClick={(event) => redirectUrl(event, 'ctv', 'settings', projectDetails.slug)}>
                                                    <i className="fa fa-cog fa-2x"></i>
                                                </a>

                                                <a title="Customizations" onClick={(event) => redirectUrl(event, 'ctv', 'config/ios-customizations', projectDetails.slug)}>
                                                    <i className="fa fa-pencil fa-2x"></i>
                                                </a>
                                            </div>

                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </CardBody>
                {/* </Card> */}
            </div>
        </div>
    )
}

export default CTVAppManager;
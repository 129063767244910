import axios from 'axios';

const getProjectAuthors = async (projectId) => {
    let data = {
        projectId
    }
    const response = await axios.post('/api/content/get-authors',data);
    return response.data;
}
const updateAuthor = async (projectId, author) => {
    let formdata = {
        projectId,
        author
    }
    console.log("formdata",formdata);
    const response = await axios.post('/api/content/update-author',formdata);
    return response.data;
}
const getAuthorDetails= async(authorId) =>{
    const formdata = {
        authorId : authorId
    }
    const response = await axios.post('/api/content/get-author-details',formdata);
    return response.data;
}

export default {
    getProjectAuthors,
    updateAuthor,
    getAuthorDetails
}
import React, { useState, useEffect } from 'react';

import { 
    InputGroupText,
    InputGroup,
    Input,
    FormText,
    FormFeedback
} from 'reactstrap';

import Switch from "react-bootstrap-switch";
import { autoAdvance } from '../webstory-config';

const AutoAdvance = ({currentAutoAdvance, isBackgroundVideo, updateAutoAdvance, openAlert}) => {


    const [switchClicked, setSwitchClicked] = useState(false);
    const [seconds, setSeconds] = useState(0);


    const onSwitchClick = (value)=> {
        setSwitchClicked(value);
    }

    useEffect(() => {
        console.log('useeffect switchClicked',switchClicked);
        // switch is true, which means either it is clicked or it has string check string value now
        if( switchClicked === true ) { 
            if(isBackgroundVideo) {
                updateAutoAdvance("video");
                return;
            }
            // uncomment '!seconds' to apply default value to all new slides
            // if seconds is already set on a old page, use the old value, and do not set default values
            if(!currentAutoAdvance && !seconds){
                setSeconds(5);
                updateAutoAdvance(`5s`)
            } else {
                updateAutoAdvance(`${seconds}s`)
            }
        }

        if(switchClicked === false) {
            updateAutoAdvance('');
        }

    },[switchClicked]);


    useEffect(() => {
        console.log('useeffect currentAutoAdvance',currentAutoAdvance);
        let initialState = currentAutoAdvance ? true : false;
        setSwitchClicked(initialState);

        if (currentAutoAdvance) {
            if(currentAutoAdvance == 'true' || currentAutoAdvance == 'false') {
                setSeconds(3);
            } else if(currentAutoAdvance === 'video') { // background video case
                updateAutoAdvance("video");
            } else {
                setSeconds(currentAutoAdvance.split('s')[0]);
            } 
        } 
    },[currentAutoAdvance]);


    useEffect(() => {
        console.log('useeffect seconds',seconds);
    },[seconds]);

    const onInputChange = (value)=> {
        console.log('onInputChangev',value);
        if(value>autoAdvance.max ||value<autoAdvance.min ) {
            setSeconds(0);
            openAlert({status:true, msg: 'Time Out of Bounds', color:'danger'})
            return;
        }
        updateAutoAdvance(`${value}s`);
    }
    
    return (
        <>
            <div className='h-100' style={{width:'45%'}}>
                        <p className='m-0 d-flex justify-content-center align-items-center fs-1vw' style={{height:'40%'}}>
                                Auto Advance  
                        </p>
                        <div className='d-flex align-items-center justify-content-center' style={{height:'40%'}}>
                            <div style={{height:'100%'}}>
                                <i class='auto_advance fa fa-info-circle' > 
                                    <span className="tooltiptext right">
                                        <b>Auto Advance to the  next page of the story after a specified amount of time.</b> <br/>
                                        For videos, the story plays for exactly the length of the video. 
                                    </span>
                                </i> 

                            </div>
                            <div style={{height:'100%'}}>
                                <Switch
                                    name=""
                                    value={switchClicked}
                                    offColor="success"
                                    offText={<i className="nc-icon nc-simple-remove" />}
                                    onColor="success"
                                    onText={<i className="nc-icon nc-check-2" />}
                                    onChange={(value, enabled) => { 
                                        onSwitchClick(enabled);
                                    }}
                                />{" "}

                            </div>
                        </div>
            </div>
                
            
            <div style={{width:'55%', height:'100%'}}> 
                {switchClicked? <p className='m-0 d-flex justify-content-center align-items-center'  style={{height:'25%', fontSize: '0.9vw'}}>
                         Minimum {autoAdvance.min} seconds, Maximum {autoAdvance.max} seconds
                </p>:null}
                <div style={{height:'50%',"display":"flex","flexDirection":"column","justifyContent":"center"}}>

                    {switchClicked && !isBackgroundVideo?  
                        <InputGroup style={{marginBottom:0, justifyContent:'center'}}>
                            <Input value={seconds}  min={autoAdvance.min} max={autoAdvance.max} type="number" onChange={(e)=>{onInputChange(e.target.value)}} style={{margin:0, width:'25%', flex:'none', fontSize:'1vw',height:'3vw'}} />
                        
                            <InputGroupText style={{background: '#219653', color:'white', fontWeight:'bold', padding:'1vw',fontSize:'1vw',height:'3vw'}}>
                                Seconds
                            </InputGroupText>
                                                 
                        </InputGroup>
                        :
                        null}
                        { switchClicked && isBackgroundVideo?
                            <p style={{"padding":"0.2vw","color":"black","background":"gold","borderRadius":"0.2vw","fontSize":"1vw","margin":"0"}}>
                                For videos, the story plays for exactly the length of the video. 
                            </p>
                        :null}
                </div>
                {seconds === '' ? 
                    <div className='fs-1vw' style={{height:'25%', color:'red'}}>
                        This field cannot be empty.
                    </div>
                    :null
                }
            </div>
        </>
    )
}

export default AutoAdvance;
import React, {useEffect,useState,useContext} from 'react';
import ProjectContext from '../../ProjectContext.js'

import {useHistory} from 'react-router-dom';
import paidArticlesService from '../../services/api/paid-articles-service.js';
import projectService from '../../services/api/projects-service.js';
import {
    Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardImg,
  CardFooter,
  CardTitle,
  CardText,
  Label,
  FormGroup,
  Form,
  Input,
  Alert,
  Spinner,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";
import Select from 'react-select';
import Pagination from 'react-js-pagination';
import moment from 'moment';
import ReactDatetime from 'react-datetime';
import './Transaction.css';
import ReactBSAlert from "react-bootstrap-sweetalert";
import planService from '../../services/api/plan-service.js';

const Transactions = (props) => {
    const projectDetails = useContext(ProjectContext);
    const history = useHistory();
    if(projectDetails.id==''){
        history.push("/project/allprojects");
    }
    const project_id = projectDetails.id;
    const [formdetails, setFormDetails] = useState({project_id ,status:2,amount : '' , pg_txn_id: '',from_date :'',to_date:''});
    const [transactions, setTransactions] = useState([]);
    const [totalTransactions, setTotalTransactions] = useState(0);
    const [renderComponent , setRenderComponent] = useState(false);
    const [reloadComponent, setReloadComponent] = useState(false);
    const [activePage, setActivePage] = useState(1);
    const [firstRecord, setFirstRecord] = useState(0);
    const [lastRecord, setLastRecord] = useState(0);
    const [showPopup,setShowPopup] = useState({alert:null});
    const [paidArticleEnabled , setPaidArticleEnabled] = useState(false);
    const records = 10;
    const transactionStatusValue = [
        {
            value: 1,
            label: "Order Created",
        },
        {
            value: -1,
            label: "Order creation failed",
        },
        {
            value: 2,
            label: "Success",
        },
        {
            value: -2,
            label: "Failed",
        }
    ];
    useEffect(()=>{
        (async ()=> {
                const response = await planService.getTransactions(project_id,formdetails,activePage,records);
                if(response.status && response.data) {
                    let transactions = response.data.transactions;
                    setTransactions(response.data.transactions);
                    setTotalTransactions(response.data.counts);
                    if(transactions && transactions.length) {
                        setFirstRecord((activePage-1)*records+1);
                        setLastRecord((activePage-1)*records+ transactions.length);
                    }else{
                        setFirstRecord((activePage-1)*records);
                        setLastRecord((activePage-1)*records+ transactions.length);
                    }
                }
                setRenderComponent(true);
        })();
    },[reloadComponent]);
    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
        setReloadComponent(Math.random());
    }
    const handleSubmit = function(e){  searchTransaction();e.preventDefault() }

    const searchTransaction = () => {
        setActivePage(1);
        setReloadComponent(Math.random());
    }
    const resetForm = () => {
        setActivePage(1);
        setFormDetails({
            ...formdetails,
            status :'',
            from_date : '',
            to_date : '',
            amount : '',
            pg_txn_id : '',
        });
        setReloadComponent(Math.random());
    }

    const handleChange = (event)=>{
        setFormDetails({
        ...formdetails,
        [event.target.id]: event.target.value,
        });
    }
    const handleEnumChange = (id, value)=>{
        setFormDetails({
        ...formdetails,
        [id]: value,
        });
    }
    const handleDateChange = (event,id) => {
        setFormDetails({
        ...formdetails,
        [id]: event._d,
        });
  }
  const hideAlert = ()=>{
    setShowPopup({alert:null});
  }
  const reviewTransactionPopup = (details) => {
      let orderData = {};
      if(details.order_data) {
        orderData = JSON.parse(details.order_data);
      }
      setShowPopup({
        alert: (
                <ReactBSAlert
                    Success
                    onCancel={() => hideAlert()}
                    style ={{"display":"block","position":"absolute","height" : "auto","width": "auto"}}
                    closeOnClickOutside={false}
                    showCancel={false}
                    showConfirm={false}
                    btnSize=""
                >
                    <Row>
                        <Col>
                        <CardTitle tag="h4" align="left">
                            Transaction Details
                            </CardTitle>
                        </Col>
                        <Col align="right">
                            <Button onClick = {() => hideAlert()} className ="closeBtn">X</Button>
                        </Col>   
                    </Row>
                    <Table size="lg" bordered responsive="sm" className="detailsTable">
                        <tr>
                            <td >
                                Transaction Id
                            </td>
                            <td>
                                {details.pg_txn_id}
                            </td>
                        </tr>
                        <tr>
                            <td >
                                Transaction Status
                            </td>
                            <td>
                                {details.status===2?<Badge color="success" pill>Success</Badge> : null}
                                    {details.status===-2?<Badge color="danger" pill> Fail</Badge> : null}
                                    {details.status===-1? "Order Created" :""}
                                    {details.status===1? "Order creation failed" :""}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Paid By
                            </td>
                            <td>
                                {details.publisher_id.email}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Payment For
                            </td>
                            <td>
                                {details.plan_id?details.plan_id.plan_group_id.name:''}{" Plan"}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Amount (INR)
                            </td>
                            <td>
                                {details.amount/100}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Coupon
                            </td>
                            <td>
                                {details.coupon_id?details.coupon_id.coupon_code:"No coupon Applied"}
                            </td>
                        </tr>
                        {details.coupon_discount?
                            <tr>
                                <td>
                                Coupon Discount
                                </td>
                                <td>
                                    { details.coupon_discount/100}
                                </td>
                            </tr> 
                        : ''}

                        <tr>
                            <td>
                                Transaction Date
                            </td>
                            <td>
                                {moment(details.createdat).local().format('dddd DD MMM YYYY hh:mm:ss a')}
                            </td>
                        </tr>
                        
                    </Table>
            </ReactBSAlert> 
        )
      });
    }
    const downloadPdf = async (transaction)=> {
        const response = await planService.getTransactionReceipt(project_id, transaction.pg_txn_id);
        if(response.status && response.data) {
        }
    }

    return (
        <div className="content mt-30">
            {showPopup.alert}
            <Container>
                <Card className="text-center dp-box">
                    <CardHeader>
                        <CardTitle tag="h4" className="text-center">
                            Plan Purchase History 
                        </CardTitle>
                        
                        <Form
                            id="transactionFilters"
                            action=""
                            className="form"
                            method="POST"
                            onSubmit={handleSubmit}
                            >
                                <Row>
                                    <Col>
                                        <Input
                                            className="mrgin"
                                            value={formdetails.pg_txn_id}
                                            type="text"
                                            name="pg_txn_id"
                                            id="pg_txn_id"
                                            onChange={handleChange}
                                            placeholder="Transaction Id"
                                        />
                                    </Col>
                                    {/* <Col className="text-center">
                                        <Select
                                            className="react-select primary mrgin"
                                            classNamePrefix="react-select"
                                            placeholder="Select Status .."
                                            name = "status"
                                            id="status"
                                            options={transactionStatusValue}
                                            value={formdetails.status? transactionStatusValue.forEach(details => formdetails.status === details.value):""}
                                            onChange={(event)=> handleEnumChange("status",event.value)}
                                        />
                                    </Col> */}
                                    <Col className="text-center">
                                        <ReactDatetime
                                            className = "mrgin calenderCSS"
                                            value={formdetails.from_date}
                                            onChange={(event)=>handleDateChange(event , "from_date")}
                                            inputProps={{
                                                className: "form-control",
                                                placeholder: "From Date",
                                                id:"from_date",
                                                autoComplete : "off"
                                            }
                                            }
                                        />
                                    </Col>
                                    <Col>
                                        <ReactDatetime
                                            className="mrgin calenderCSS"
                                            value={formdetails.to_date}
                                            onChange={(event)=>handleDateChange(event , "to_date")}
                                            inputProps={{
                                                className: "form-control",
                                                placeholder: "To Date",
                                                id:"to_date",
                                                autoComplete : "off"
                                            }}
                                        />
                                    </Col>
                                    
                                    <Col>
                                    <Button className="btn-smb" type="submit">
                                        Search
                                    </Button>
                                    <Button
                                        className="btn-smb btn-rst"
                                        type="button"
                                        onClick={resetForm}
                                        >
                                    Reset 
                                    </Button>
                                    </Col>
                                    
                                </Row>
                        </Form>
                        {totalTransactions > 0 ? 
                            <div className="pagBox">
                                
                                <div className="pagNo">
                                {firstRecord} - {lastRecord} of {totalTransactions}
                                </div>
                                <div className="pagDgt">
                                    
                                <Pagination
                                activePage={activePage}
                                itemsCountPerPage={records}
                                totalItemsCount={totalTransactions}
                                pageRangeDisplayed={5}
                                onChange={handlePageChange.bind(this)}
                                itemClass="page-item"
                                linkClass="page-link"
                                />
                                </div>
                            </div>
                        : null}
                    </CardHeader>
                    
                    {!renderComponent?
                        <div className="text-center">
                            <Spinner className="text-center" animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                        </div>
                    :null}
                <CardBody>
                  <Table responsive>
                    <thead className="text-primary">
                    {renderComponent?
                        <>
                            <th className="text-center">#</th> 
                            <th className="text-center" >Transaction Id</th>
                            <th className="text-center" >Plan</th>  
                            <th className="text-center" >Amount(INR)</th>
                            <th className="text-center" >Coupon Discount(INR)</th>
                            <th className="text-center" >Effective Amount (INR)</th>
                            <th className="text-center" >Status</th>
                            <th className="text-center" >Transaction Date</th>
                            <th className="text-center" >Type</th>
                            <th className="text-center">Action</th>
                        </>:''}
                    </thead>
                    <tbody>

                        { transactions && transactions.length?transactions.map((details, index)=>(
                        <tr className={details.status==2?'table-success':
                                details.status==1?'':
                                details.status==-2?'table-danger':
                                ''}>
                            <td className="text-center">{firstRecord+index}</td>
                            
                            <td className="text-left">
                                {details.pg_txn_id}
                            </td>
                            <td className="text-center">{details.plan_id?details.plan_id.plan_group_id.name:''}</td>
                            <td className="text-center">
                                {details.amount/100}
                            </td>
                            <td className="text-center">
                               {details.coupon_id? details.coupon_discount/100:"-"}
                            </td>
                            <td className="text-center">
                                {
                                details.coupon_discount ?
                                    (details.amount- details.coupon_discount)/100
                                    :
                                    details.amount/100
                                }
                            </td>
                            <td className="text-center">
                                {
                                details.status==2?'Success':
                                details.status==1?'Initiated':
                                details.status==-2?'Failed':
                                ''
                                }
                            </td>
                            
                            <td className="text-center">
                                {moment(details.createdat).format('dddd DD MMM YYYY hh:mm:ss a')}
                            </td>
                            <td className="text-center">
                                {
                                details.type == 1 ? "One time" :
                                details.type == 2 ? "Recurring":
                                "One time"
                                }
                            </td>
                            <td  className="text-center">
                                <div className="">
                                    <div>
                                        {details.status === 2 ? <a title="download receipt" href={'/download-txn-receipt/'+details.id} target="_blank"><i class="fa fa-download fa-2x"></i></a>:""}
                                    </div>
                                    <div className="">
                                        <a href="#"><i title="View More" class="fa fa-ellipsis-h fa-2x" onClick={(event)=>reviewTransactionPopup(details)}></i></a>
                                    </div>
                                </div>
                                
                                
                            </td>
                        </tr>
                        ))
                        :
                        null
                        }
                        {renderComponent && (!transactions|| !transactions.length)?
                        <td colspan="12" className="text-center">
                            <CardTitle tag="h4" >No Records Found</CardTitle>
                        </td>:
                        null
                    }
                    </tbody>
                  
                  </Table>

                  
                </CardBody>
                </Card>
                 <div
            className="full-page-background"
            style={{
                backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
            }}
            />
            </Container>
           
        </div>
    );
}

export default Transactions;
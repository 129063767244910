import React, { useEffect, useState,useContext } from "react";
import ProjectContext from "../../../ProjectContext";
import {useHistory} from "react-router-dom";
import Switch from "react-bootstrap-switch";
import formValidations from "../../../variables/form-validations";
import { Prompt } from 'react-router'
// import './AppSettings.css';
import defaultImage from "../../../assets/img/image_placeholder.jpg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faTrash,faTablet,faTrashRestoreAlt } from '@fortawesome/free-solid-svg-icons'
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Alert,
  Spinner,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

import Select from "react-select";
import ImageUpload from "../utility-component/UploadImage.js";
// import FileUpload from './FileUpload.js'
import ReactBSAlert from "react-bootstrap-sweetalert";
import '../General.css';
import sortdAppService from '../../../services/api/sortd-apps/android-service.js';
import projectsService from '../../../services/api/projects-service.js'
import androidService from "../../../services/api/sortd-apps/android-service.js";
import generalService from '../../../services/api/sortd-apps/general-service.js';
const Android = (props) => {
    const projectDetails = useContext(ProjectContext);
    const history = new useHistory();
    if(projectDetails.id==''){
        history.push("/project/allprojects");
    }
    const projectId = projectDetails.id;
    const [formdetails, setFormDetails] = useState({project_id:projectId,android_app_enabled:true,android_app_name:"",android_app_package_name:"",android_google_services_json:"", fcm_api_server_key : "",launcher_icon:"",app_logo:"",launcher_background:"#eadcdc",fcm_service_account_json:""});
    const [showLoader, setShowLoader]   = useState(false);
    const [showSaveButton, setShowSaveButton]   = useState(true);
    const [alert, setAlert]   = useState({show:false,message:'',type:''});
    const [projectName, setProjectName] = useState("");
    const [fieldStates,setFieldStates] = useState({});
    const [existingApps, setExistingApps] = useState([]);
    const [showPopup, setShowPopup] = useState({ alert: null });
    const [reloadComponent ,setReloadComponent] = useState(null);
    const [androidAppEnabled, setAndroidAppEnabled] = useState(false);
    const [appPackageName, setAppPackageName] = useState("");
    let error = "";
    const googleServiceValidationFormat = {
        "project_info": {
            "project_number": "12345",
            "project_id": "12345",
            "storage_bucket": "12345"
        },
        "client": [
            {
            "client_info": {
                "mobilesdk_app_id": "12345",
                "android_client_info": {
                "package_name": ""
                }
            },
            "oauth_client": [],
            "api_key": [
                {
                "current_key": "12345"
                }
            ],
            "services": {
                "appinvite_service": {
                "other_platform_oauth_client": []
                }
            }
            }
        ],
        "configuration_version": "1"
}
    const handleEnumChange = (inputId, inputValue) => {
        setFormDetails({
            ...formdetails,
            [inputId]: ( inputValue ),
        });
        fieldStates['error_'+inputId] = 'has-success';
        fieldStates['error_message_'+inputId] = '';
        setFieldStates({
        ...fieldStates,
        fieldStates
    });
    }

    const handleChange = (event) => {
        setFormDetails({
        ...formdetails,
        [event.target.id]: ( event.target.value ),
        });
        fieldStates['error_'+event.target.id] = 'has-success';
        fieldStates['error_message_'+event.target.id] = '';
        setFieldStates({
            ...fieldStates,
            fieldStates
        });
    }
  
    const handleJSONChange = (event) => {
        setFormDetails({
        ...formdetails,
        android_google_services_json: event.target.value ,
        });
    }
    const handleBlurJSON = (event) => {
        setFormDetails({
        ...formdetails,
        android_google_services_json: event.target.value,
        });
    }

    const handleServiceAccountJSONChange = (event) => {
        setFormDetails({
        ...formdetails,
        fcm_service_account_json: event.target.value ,
        });
    }
    const handleBlurServiceAccountJSON = (event) => {
        setFormDetails({
        ...formdetails,
        fcm_service_account_json: event.target.value,
        });
    }
    
    const handleBlur = (event) => {
        setFormDetails({
        ...formdetails,
        [event.target.id]: ( event.target.value ),
        });

    }

    const handleSwitchChange = (fieldname,fieldvalue) => {
        setFormDetails({
        ...formdetails,
        [fieldname]: fieldvalue,
        });
    }
    const handleSubmit = function(e){ onSave(); e.preventDefault() }
    const validateAppName = () => {
        let appNameState = {
            error_android_app_name : 'has-success',
            error_message_android_app_name : '',
            is_valid : true
        }
        if(formdetails.android_app_name === undefined){
            appNameState['error_android_app_name'] = 'has-danger';
            appNameState['error_message_android_app_name'] = 'This field is required.';
            appNameState.is_valid = false;
            return appNameState;
        }
        if(formdetails.android_app_name.trim()===''){
            appNameState['error_android_app_name'] = 'has-danger';
            appNameState['error_message_android_app_name'] = 'This field is required.';
            appNameState.is_valid = false;
            return appNameState;
        }
        if(formValidations.verifySpecialCharacters(formdetails.android_app_name)) {
            appNameState['error_android_app_name'] = 'has-danger';
            appNameState['error_message_android_app_name'] = 'Invalid app name.';
            appNameState.is_valid = false;
            return appNameState;
        }
        if(formdetails.android_app_name.length<3 || formdetails.android_app_name.length>15) {
            appNameState['error_android_app_name'] = 'has-danger';
            appNameState['error_message_android_app_name'] = 'App name length should be in between 3 to 15 character.';
            appNameState.is_valid = false;
            return appNameState;
        }
        return appNameState;
    }
    const validateAndroidForm = () => {
        console.log("formdetails:",formdetails);
        let validations = true;

        // if(formdetails.android_app_name === undefined || formdetails.android_app_name.trim()===''){
        //     fieldStates['error_android_app_name'] = 'has-danger';
        //     fieldStates['error_message_android_app_name'] = 'This field is required.';
        //     validations = false;
        // }else{
        //     if(formValidations.verifySpecialCharacters(formdetails.android_app_name)) {
        //         fieldStates['error_android_app_name'] = 'has-danger';
        //         fieldStates['error_message_android_app_name'] = 'Invalid app name.';
        //         validations = false;
        //     }else{
        //         fieldStates['error_android_app_name'] = 'has-success';
        //         fieldStates['error_message_android_app_name'] = '';
        //     }
            
        // }
        const appNameState =  validateAppName();
        fieldStates['error_android_app_name'] = appNameState['error_android_app_name'];
        fieldStates['error_message_android_app_name'] = appNameState['error_message_android_app_name'];
        validations = appNameState['is_valid'];

        if(formdetails.android_app_package_name === undefined || formdetails.android_app_package_name.trim() ===''){
            fieldStates['error_android_app_package_name'] = 'has-danger';
            fieldStates['error_message_android_app_package_name'] = 'This field is required.';
            validations = false;
        }else{
            if(existingApps && validateDuplicatePackageNameExists()){
                fieldStates['error_android_app_package_name'] = 'has-danger';
                fieldStates['error_message_android_app_package_name'] = 'Package name already exists .Please try with different package name'; 
                validations = false;
            }else{
                if(formValidations.validateAppPackage(formdetails.android_app_package_name)===false || formValidations.verifySpecialCharacters(formdetails.android_app_package_name)) {
                    fieldStates['error_android_app_package_name'] = 'has-danger';
                    fieldStates['error_message_android_app_package_name'] = 'Invalid app package name. Special characters and numbers are not allowed.';
                    validations = false;
                }else{
                    fieldStates['error_android_app_package_name'] = 'has-success';
                    fieldStates['error_message_android_app_package_name'] = '';
                }
            }
        }
        if(formdetails.android_google_services_json===''){
            // setFormDetails({
            //     ...formdetails,
            //     android_google_services_json: JSON.stringify(googleServiceJSON)
            // });
        }else {
            if(!isJSONValid(JSON.stringify(formdetails.android_google_services_json))){
                fieldStates['error_android_google_services_json'] = 'has-danger';
                fieldStates['error_message_android_google_services_json'] = 'Invalid JSON';
                validations = false;
            }else{
                if(!validateGoogleServiceJSON()) {
                    fieldStates['error_android_google_services_json'] = 'has-danger';
                    fieldStates['error_message_android_google_services_json'] = <pre>Fields are missing in  JSON<br/>{error}</pre>;
                    error="";
                    validations = false;
                }else{
                    if(validations && formdetails.android_app_package_name!==JSON.parse(formdetails.android_google_services_json).client[0].client_info.android_client_info.package_name){
                        fieldStates['error_android_app_package_name'] = 'has-danger';
                        fieldStates['error_message_android_app_package_name'] = 'Package name should be same as client_info.android_client_info.package_name of service JSON.';
                        validations = false;
                    }
                    fieldStates['error_android_google_services_json'] = 'has-success';
                    fieldStates['error_message_android_google_services_json'] = '';
                }
            }
            
        }
        if (formdetails.fcm_service_account_json === '') {
            //not necessary 
        } else {
            if (!isJSONValid(JSON.stringify(formdetails.fcm_service_account_json))) {
                fieldStates['error_fcm_service_account_json'] = 'has-danger';
                fieldStates['error_message_android_google_services_json'] = 'Invalid JSON';
                validations = false;
            } 
            else if(!((JSON.parse(formdetails.fcm_service_account_json)).hasOwnProperty("project_id"))){
                fieldStates['error_fcm_service_account_json'] = 'has-danger';
                fieldStates['error_message_fcm_service_account_json'] = 'project_id is missing';
                validations = false;
            }
            else {
                fieldStates['error_fcm_service_account_json'] = 'has-success';
                fieldStates['error_message_fcm_service_account_json'] = '';
            }
        }
        if(formdetails.launcher_icon.trim()===''){
            fieldStates['error_launcher_icon'] = 'has-danger';
            fieldStates['error_message_launcher_icon'] = 'This field is required.';
            validations = false;
        }else{
            fieldStates['error_launcher_icon'] = 'has-success';
            fieldStates['error_message_launcher_icon'] = '';
        }

        if(formdetails.app_logo.trim()===''){
            fieldStates['error_app_logo'] = 'has-danger';
            fieldStates['error_message_app_logo'] = 'This field is required.';
            validations = false;
        }else{
            fieldStates['error_app_logo'] = 'has-success';
            fieldStates['error_message_app_logo'] = '';
        }
                
    setFieldStates({
        ...fieldStates,
        fieldStates
    });
    return validations;
    }

    const saveAppGeneralSettings = async ()=>{
        let formdata = formdetails;
        if(formdetails.android_app_enabled && !formdetails.android_google_services_json) {
                formdata = {
                    ...formdetails,
                    android_google_services_json : generateGoogleServiceJSONString()
                }
        }
        let androidAppInfo = await androidService.saveAndroidAppInfo(formdata);
        console.log("androidAppInfo",androidAppInfo);
        if(projectDetails.isContractExpired){
            setShowPopup({alert:null});
            props.hideAlert();
            projectDetails.setContractExpiredAlert(projectDetails);
        }
        else if (androidAppInfo.status && androidAppInfo.data) {
                setShowPopup({
                    alert: (
                        <ReactBSAlert
                            success
                            style={{ display: "block", marginTop: "-100px" }}
                            title="Android App setting saved !"
                            onConfirm={() =>{hideAlert();props.hideAlert();props.setIsCompleted()} }
                            onCancel={() => hideAlert()}
                            confirmBtnBsStyle="info"
                            btnSize=""
                            openAnim = {{ name: 'showSweetAlert', duration: 1000 }}
                            closeAnim = {{ name: 'hideSweetAlert', duration: 300 }}
                        >
                            App settings saved successfully.
                        </ReactBSAlert>
                    ),
                });
    
                // setTimeout(() => {
                //     hideAlert();
                    
                // }, 3000);

                // setReloadComponent(Math.random());
                
        }else {
                // setTimeout(() => {
                //     hideAlert();
                //     // window.location.reload();
                // }, 500);
                setShowPopup({
                    alert: (
                        <ReactBSAlert
                            danger
                            style={{ display: "block", marginTop: "-100px" }}
                            title="App general settings could not save!"
                            onConfirm={() => hideAlert()}
                            onCancel={() => hideAlert()}
                            confirmBtnBsStyle="info"
                            btnSize=""
                            openAnim = {{ name: 'hideSweetAlert', duration: 3000 }}
                            
                        >
                            App settings could not save.
                        </ReactBSAlert>
                    ),
                });
    
                // setTimeout(() => {
                //     hideAlert();
                // }, 3000);
                // setReloadComponent(Math.random());
            }
    //   if(saveProjectSettings.status){
    //     setTimeout(()=>{
    //       setShowLoader(false)
    //       setShowSaveButton(true);
    //       setAlert({show:true,type:'success',message:'Project Apps settings were saved successfully'});
    //     },1000);
        
    //   }else{
    //     setTimeout(()=>{
    //       setShowLoader(false)
    //       setShowSaveButton(true)
    //       setAlert({show:true,type:'danger',message:'Project Apps settings could not be saved, Please try again.'});
    //     },1000);
    //   }

    //   setTimeout(()=>{
    //     setAlert({show:false,type:'',message:''});
    //   },3000);

    //   setTimeout(()=>{
    //     window.location.reload();
    //   },2000);
  }

    const onSave = ()=> {
      if(validateAndroidForm()==true) {
          confirmationPopup();
      }
    }
    const confirmationPopup = () => {
        setShowPopup({
            alert: (
                <ReactBSAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Are you sure want to save app general settings ?"
                    onConfirm={() => {
                        saveAppGeneralSettings();
                        // hideAlert();
                    }}
                    onCancel={() => {
                        hideAlert();
                    }}
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    confirmBtnText="Yes, Save it!"
                    cancelBtnText="Cancel"
                    showCancel
                    btnSize=""
                    openAnim = {{ name: 'showSweetAlert', duration: 1000 }}
                >
                    You want to save App general settings!
                </ReactBSAlert>
            ),
        });
    };
    const hideAlert = () => {
        setShowPopup({
            alert: null,
        });
    };
    const generateGoogleServiceJSONString = ()=>{
        let googleServiceJSON = {
                    "project_info": {
                        "project_number": "12345",
                        "project_id": "12345",
                        "storage_bucket": "12345"
                    },
                    "client": [
                        {
                        "client_info": {
                            "mobilesdk_app_id": "12345",
                            "android_client_info": {
                            "package_name": formdetails.android_app_package_name
                            }
                        },
                        "oauth_client": [
                            {
                            "client_id": "12345",
                            "client_type": 3
                            }
                        ],
                        "api_key": [
                            {
                            "current_key": "12345"
                            }
                        ],
                        "services": {
                            "appinvite_service": {
                            "other_platform_oauth_client": [
                                {
                                "client_id": "12345",
                                "client_type": 3
                                },
                                {
                                "client_id": "12345",
                                "client_type": 2,
                                "ios_info": {
                                    "bundle_id": formdetails.android_app_package_name
                                }
                                }
                            ]
                            }
                        }
                        }
                    ],
                    "configuration_version": "1"
        };
        return JSON.stringify(googleServiceJSON);
    }
    const isJSONValid = (jsonString)=>{
        try{
            JSON.parse(jsonString);
            return true;
        }catch(error){
            return false;
        }
    }

    function validate(jsonObject,originalJSON) {
        for (var key in jsonObject) {
            if (jsonObject.hasOwnProperty(key)) {
                var val = jsonObject[key];
                if(originalJSON[key]===undefined) {
                    error+=key+ " key is missing in Service JSON\n";
                    continue;
                }
            if(typeof val === "object") {
                validate(val,originalJSON[key]);
            }
            
            }
        }
    } 

    const validateGoogleServiceJSON = ()=>{
        try{  
            validate(googleServiceValidationFormat,JSON.parse(formdetails.android_google_services_json));
            if(error) {
                return false;
            }
            return true;
        }catch(error) {
            console.log(error);
            return false;
        }
    }
    const validateDuplicatePackageNameExists = ()=>{
            let duplicateName = false;
            existingApps.filter(function(packageName) {
                if((appPackageName !== formdetails.android_app_package_name) && formdetails.android_app_package_name === packageName){
                    duplicateName = true;
                }
            });
            return duplicateName;
    }
    
    useEffect(()=>{
        (async ()=> {
            if(!projectId) return;
            let getAppsGeneralDetails =await generalService.getAppsGeneralDetails(projectId);
            if(getAppsGeneralDetails.status && getAppsGeneralDetails.data) {
                setFormDetails({
                    fcm_api_server_key: getAppsGeneralDetails.data.fcm_api_server_key,
                    launcher_icon: getAppsGeneralDetails.data.launcher_icon,
                    app_logo: getAppsGeneralDetails.data.app_logo,
                });
            }
        })();
    },[projectId])
    useEffect(()=>{
        (async ()=> {
            if(!projectId) return;
                let getAndroidDistributions =await androidService.getAndroidDistributions();
                if(getAndroidDistributions.status && getAndroidDistributions.data) {
                    setExistingApps(getAndroidDistributions.data);
                }
            const androidAppInfo = await sortdAppService.getAndroidAppInfo(projectId);
            if(androidAppInfo.status){
                if(androidAppInfo.data) {
                    setAppPackageName(androidAppInfo.data.android_app_package_name);
                    setFormDetails({
                        ...formdetails,
                         android_app_enabled: androidAppInfo.data.android_app_enabled,
                        android_app_name: androidAppInfo.data.android_app_name,
                        android_app_package_name: androidAppInfo.data.android_app_package_name,
                        android_google_services_json: androidAppInfo.data.android_google_services_json?JSON.stringify(JSON.parse(androidAppInfo.data.android_google_services_json),undefined,4):"",
                        fcm_api_server_key: androidAppInfo.data.fcm_api_server_key,
                        launcher_icon: androidAppInfo.data.launcher_icon,
                        app_logo: androidAppInfo.data.app_logo,
                        fcm_service_account_json:androidAppInfo.data.fcm_service_account_json?androidAppInfo.data.fcm_service_account_json:""
                    });
                    setAndroidAppEnabled(androidAppInfo.data)
                }
            }
        })();
    },[])


    return <div className='content'>
        {showPopup.alert}
        <Container>
          <Row>
          <Col className="ml-auto mr-auto"  md="12">
              <Card className="card-signup text-center androidFormCard">
                <CardBody>
                  <Form className="form-horizontol" action="" id="appSetting" method="POST" onSubmit={handleSubmit}>
                    <Row>
                        <Col>
                            <Col sm="12">
                                <div className="designLabel">
                                    <small className="text-default">
                                        Android App Name<span class="text-danger"> * </span>
                                    </small>
                                </div>
                                <Label className="smLabl-txt">Enter name for your Android app</Label>
                            </Col>
                            <Col sm="12">
                                <div className="inptFom1">
                                <FormGroup className={fieldStates["error_android_app_name"]}>
                                <Input
                                    type="text"
                                    name="android_app_name"
                                    id="android_app_name"
                                    value={formdetails.android_app_name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {fieldStates["error_android_app_name"] ===
                                    "has-danger" ? (
                                        <label className="error">
                                            {
                                                fieldStates[
                                                    "error_message_android_app_name"
                                                ]
                                            }
                                        </label>
                                    ) : null}
                                </FormGroup>
                                </div>
                            </Col>
                    </Col>
                  {/* </Row>
                  
                  <Row> */}
                  <Col>
                    <Col sm="12">
                        <div className="designLabel">
                            <small className="text-default">
                                Android Package Name<span className="text-danger"> * </span>
                            </small>
                        </div>
                        <Label className="smLabl-txt">Enter app package name (com.yourappname.app)</Label>
                    </Col>
                    <Col sm="12">
                        <div className="inptFom1">
                            <FormGroup className={fieldStates["error_android_app_package_name"]}>
                                <Input
                                    type="text"
                                    name="android_app_package_name"
                                    id="android_app_package_name"
                                    value={formdetails.android_app_package_name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {fieldStates["error_android_app_package_name"] ===
                                    "has-danger" ? (
                                        <label className="error">
                                            {
                                                fieldStates[
                                                    "error_message_android_app_package_name"
                                                ]
                                            }
                                        </label>
                                    ) : null}
                                </FormGroup>
                        </div>
                    </Col>
                  </Col>
                  </Row>
                  <Row>
                    <Col>
                    <Col sm="12">
                        <div className="designLabel">
                            <small className="text-default">
                                Android Google Services JSON
                            </small>
                        </div>
                        <Label className="smLabl-txt">Paste google service json</Label>
                    </Col>
                    <Col sm="12">
                        <div className="inptFom1">
                            <FormGroup className={fieldStates["error_android_google_services_json"]}>
                                <Input
                                    type="textarea"
                                    
                                    name="android_google_services_json"
                                    id="android_google_services_json"
                                    value={formdetails.android_google_services_json}
                                    // value ={googleServiceJSON?JSON.stringify(googleServiceJSON):""}
                                    onChange={handleJSONChange}
                                    onBlur={handleBlurJSON}
                                    // style={{ "min-height": "100px","padding":"15px" }}
                                />
                                {fieldStates["error_android_google_services_json"] ===
                                    "has-danger" ? (
                                        <label className="error">
                                            {
                                                fieldStates[
                                                    "error_message_android_google_services_json"
                                                ]
                                            }
                                        </label>
                                    ) : null}
                                </FormGroup>
                        </div>
                    </Col>
                    </Col>
                    <Col>
                    <Col sm="12">
                        <div className="designLabel">
                            <small className="text-default">
                                FCM API Server Key 
                            </small>
                        </div>
                        <Label className="smLabl-txt">For sending Notifications</Label>
                    </Col>
                    <Col sm="12">
                        <div className="inptFom1">
                            <FormGroup className={fieldStates["error_fcm_api_server_key"]}>
                                <Input
                                    type="textarea"
                                    name="fcm_api_server_key"
                                    id="fcm_api_server_key"
                                    value={formdetails.fcm_api_server_key}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </FormGroup>
                        </div>
                    </Col>
                    </Col>
                  </Row>
                  <Row>
                  <Col>
                    <Col sm="12">
                        <div className="designLabel">
                            <small className="text-default">
                                FCM Service Account JSON
                            </small>
                        </div>
                        <Label className="smLabl-txt">Paste FCM service account json</Label>
                    </Col>
                    <Col sm="6">
                        <div className="inptFom1">
                            <FormGroup className={fieldStates["error_fcm_service_account_json"]}>
                                <Input
                                    type="textarea"
                                    
                                    name="fcm_service_account_json"
                                    id="fcm_service_account_json"
                                    value={formdetails.fcm_service_account_json}
                                    // value ={googleServiceJSON?JSON.stringify(googleServiceJSON):""}
                                    onChange={handleServiceAccountJSONChange}
                                    onBlur={handleBlurServiceAccountJSON}
                                    // style={{ "min-height": "100px","padding":"15px" }}
                                />
                                {fieldStates["error_fcm_service_account_json"] ===
                                    "has-danger" ? (
                                        <label className="error">
                                            {
                                                fieldStates[
                                                    "error_message_fcm_service_account_json"
                                                ]
                                            }
                                        </label>
                                    ) : null}
                                </FormGroup>
                        </div>
                    </Col>
                    </Col>
                  </Row>
                <Row>
                    <Col sm="6">
                        <div className="designLabel">
                            <small className="text-default">
                                Launcher Icon(1024x1024)<span class="text-danger"> * </span>
                            </small>
                        </div>
                        <Label className="smLabl-txt">Upload launcher icon</Label>
                        <div className="inptFom1">
                            <FormGroup className={fieldStates["error_launcher_icon"]}>
                        <ImageUpload
                                projectId={projectId}
                                size="1024x1024"
                                id="launcher_icon"
                                value={formdetails.launcher_icon ||defaultImage}
                                file_type="image/jpg,image/png,image/jpeg"
                                handleEnumChange={handleEnumChange}
                            />
                            {fieldStates["error_launcher_icon"] ===
                            "has-danger" ? (
                                <label className="error">
                                    {
                                        fieldStates[
                                            "error_message_launcher_icon"
                                        ]
                                    }
                                </label>
                            ) : null}
                        
                        </FormGroup>
                        </div>
                    </Col>
                    <Col sm="6">
                        <div className="designLabel">
                            <small className="text-default">
                               App Logo (960x1600) <span class="text-danger"> * </span>
                            </small>
                        </div>
                        <Label className="smLabl-txt">Upload App Logo</Label>
                        <div className="inptFom1">
                            <FormGroup className={fieldStates["error_app_logo"]}>
                                <ImageUpload
                                        projectId={projectId}
                                        size="960x1600"
                                        id="app_logo"
                                        value={formdetails.app_logo || defaultImage}
                                        file_type="image/jpg,image/png,image/jpeg"
                                        handleEnumChange={handleEnumChange}
                                    />
                         {fieldStates["error_app_logo"] ===
                            "has-danger" ? (
                                <label className="error">
                                    {
                                        fieldStates[
                                            "error_message_app_logo"
                                        ]
                                    }
                                </label>
                            ) : null}
                        </FormGroup>
                        </div>
                    </Col>
                  </Row>
                

                  {/* <Row>
                      <Label sm="3">Launcher Background <span class="text-danger"> * </span></Label>
                      <Col sm="7">
                        <FormGroup className={fieldStates["error_launcher_background"]}>
                       

                        <div className="inptFomColr">
                            <div className="clrChng" style={{display:"inline-block", border:"none", }}>
                            <input
                                style={{ background: formdetails.launcher_background}}
                                type="color"
                                name="launcher_background"
                                id="launcher_background"
                                value={formdetails.launcher_background}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />    
                            </div>
                            <div className="clrTxt" style={{display:"inline-block"}}>
                            <input 
                            //   style={{border:"none" }}
                              type="text"
                              name="launcher_background"
                                id="launcher_background"
                                value={formdetails.launcher_background}
                              onChange={handleChange}
                              />
                              {fieldStates["error_launcher_background"] ===
                            "has-danger" ? (
                                <label className="error">
                                    {
                                        fieldStates[
                                            "error_message_launcher_background"
                                        ]
                                    }
                                </label>
                            ) : null}
                              </div>
                              
                        </div>

                        </FormGroup>
                      </Col>
                  </Row>   */}



                  


                  {showSaveButton?<Button
                    className="btn-round btnBlue"
                    type="button"
                    onClick={handleSubmit}
                  >
                    Save
                  </Button>:''}
                  <Button
                    className="btn-round btnGry"
                    type="button"
                    onClick={()=>{props.hideAlert()}}
                  >
                    Cancel
                  </Button>
                  {alert.show?<Alert color={alert.type}>
                    {alert.message}
                  </Alert>:''}

                  

                  {showLoader?<Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>:''}
                    
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
    </div>
}

export default Android;
import React, { useEffect, useState, useContext } from "react";
import contentService from "../../services/api/content-service";
import authorService from '../../services/api/author-service';
import {useHistory} from "react-router-dom";
import ProjectContext from '../../ProjectContext';
import './content.css';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Alert,
  Spinner,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";
import './EditAuthor.css'
const EditAuthor = (props)=> {
  const projectDetails = useContext(ProjectContext);
  const history = new useHistory();
  if(projectDetails.id==''){
    history.push("/project/allprojects");
  }

  const projectId = projectDetails.id;

  let urlSearchParams = new URLSearchParams(window.location.search);
  let authorId = urlSearchParams.get('authorid');
  if(!authorId) {
      history.push('/contentsettings/authors'+'?project='+projectDetails.slug)
  }
  const [formdetails, setFormDetails] = useState({ projectId, authorId ,authorInfo : {}});
  const [author, setAuthor] = useState(false);
  const [renderComponent,setRenderComponent] = useState(false);
  const [showLoader, setShowLoader]   = useState(false);
  const [showSaveButton, setShowSaveButton]   = useState(true);
  const [alert, setAlert]   = useState({show:false,message:'',type:''});

  useEffect( () => {
    (async () =>{
          let authorData = await authorService.getAuthorDetails(authorId);
          if(authorData.status && authorData.data){
                setAuthor(authorData.data);
                setFormDetails({
                    ...formdetails,
                    authorInfo : authorData.data
                  });
            }
            setRenderComponent(true);
        })();
    }, []);

  
  const handleChange = (event) => {
    setFormDetails({
      ...formdetails,
      authorInfo:{...formdetails.authorInfo,[event.target.id]: ( event.target.value )},
    });
  }


  const handleSubmit = function(e){  validateForm();e.preventDefault() }
  const validateForm = async () => {
      setShowLoader(true);
      setShowSaveButton(false);
      let response = await authorService.updateAuthor(projectId, formdetails.authorInfo);
      if(projectDetails.isContractExpired){
        setShowLoader(false);
        setShowSaveButton(true);
        projectDetails.setContractExpiredAlert(projectDetails);
      }
      else if(response.status &&  response.data){
        setTimeout(()=>{
          setShowLoader(false)
          setAlert({show:true,type:'success',message:'Author details updated Successfully'});
        },1000);
        
        setTimeout(()=>{
          setAlert({show:false,type:'',message:''});
          setShowLoader(false);
          setShowSaveButton(true);
        },3000);

        setTimeout(()=>{
          history.push("/contentsettings/authors");
        },2000);
        
      }else{
        setTimeout(()=>{
          setShowLoader(false)
          setShowSaveButton(true)
          setAlert({show:true,type:'danger',message:'Author could not be update, Please try again.'});
        },1000);

        setTimeout(()=>{
          setAlert({show:false,type:'',message:''});
        },3000);
      }
  }
    return (
      <div className="content mt-30">
        <Container>
          <Row>
          <Col className="ml-auto mr-auto"  md="12">
              <Card className="card-signup text-center">
                <CardHeader>
                  <CardTitle tag="h4">Edit Author</CardTitle>
                </CardHeader>
                <CardBody>
                  <Form className="form-horizontol form-group formRow" action="" id="editAuthor"  method="POST" onSubmit={handleSubmit}>
                        <Row >
                            <Col >
                                Author GUID
                            </Col>
                            <Col className="alignLeft">
                                {formdetails.authorInfo.author_guid}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                Name
                            </Col>
                            <Col className="alignLeft">
                                {formdetails.authorInfo.display_name}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                Description
                            </Col>
                            <Col className="alignLeft">
                                {formdetails.authorInfo.description}
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                Author Page URL
                            </Col>
                            <Col md="5">
                                <Input
                                    type="text"
                                    id="author_page_url"
                                    name="author_page_url"
                                    value={formdetails.authorInfo.author_page_url}
                                    onChange = {handleChange}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                LinkedIn
                            </Col>
                            <Col md="5">
                                <Input
                                    type="text"
                                    id="linkedin_url"
                                    name="linkedin_url"
                                    value={formdetails.authorInfo.linkedin_url}
                                    onChange = {handleChange}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                Facebook
                            </Col>
                            <Col md="5">
                                <Input
                                type="text"
                                id="facebook_url"
                                name="facebook_url"
                                value={formdetails.authorInfo.facebook_url}
                                onChange = {handleChange}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                Twitter
                            </Col>
                            <Col md="5">
                                <Input
                                type="text"
                                id="twitter_url"
                                name="twitter_url"
                                value={formdetails.authorInfo.twitter_url}
                                onChange = {handleChange}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                Instagram
                            </Col>
                            <Col md="5">
                                <Input
                                type="text"
                                className="form-group"
                                id="instagram_url"
                                name="instagram_url"
                                value={formdetails.authorInfo.instagram_url}
                                onChange = {handleChange}
                                />
                            </Col>
                        </Row>
                        {/* <Row>
                            <Col>
                                Image
                            </Col>
                            <Col>
                                {formdetails.authorInfo.image}
                            </Col>
                        </Row> */}

                {showSaveButton?
                    <>
                        <Button
                            className="btn-round"
                            color="info"
                            type="button"
                            onClick={validateForm}
                        >
                            Save
                        </Button>
                        <Button
                            className="btn-round"
                            color="danger"
                            type="button"
                            onClick={(event) => {history.push('/contentsettings/authors')}}
                        >
                                Cancel
                        </Button>  
                    </>               
                  :''}
                  {alert.show?<Alert color={alert.type}>
                    {alert.message}
                  </Alert>:''}

                  {showLoader?<Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>:''}
                    
                  </Form>
                </CardBody>
                <CardFooter>
                  
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
          }}
        />
      </div>

            
    );
  
}

export default EditAuthor;

import { useEffect, useState } from "react";
import allListingService from "../../../services/api/Jwplayer/allListing-service";

import { useLocation } from "react-router-dom/cjs/react-router-dom";

function useTimer(mediaId, projectId, trackId) {
    const [updateEmbedVideo, setUpdateEmbedVideo] = useState(0);
    const location = useLocation();
    const [trackLabel, setTrackLabel] = useState("New Label");
    const [timerLabel, setTimerLabel] = useState("");
    const [hours, setHours] = useState("00");
    const [minutes, setMinutes] = useState("00");
    const [seconds, setSeconds] = useState("00");
    const [milliseconds, setMilliseconds] = useState("000");
    const [showMessage, setShowMessage] = useState(false);
    const [showDurationMessage, setShowDurationMessage] = useState(false);
    const [duration, setDuration] = useState();

    const [chapTimerEntries, setChapTimerEntries] = useState([]);

    const handleDeleteChapterItem = (deletedItem) => {
        const updatedArray = chapTimerEntries.filter(
            (item) =>
                !(
                    item.start === deletedItem.start &&
                    item.label === deletedItem.label
                )
        );

        setChapTimerEntries(updatedArray);
    };

    const addToChapList = () => {
        let s = seconds;
        let m = minutes;
        let ms = milliseconds;
        let h = hours;
        while (s.length < 2) s = `0${s}`;
        while (m.length < 2) m = `0${m}`;
        while (ms.length < 3) ms = `0${ms}`;
        while (h.length < 2) h = `0${h}`;

        const startTime = `${h}:${m}:${s}.${ms}`;

        const timeInMilliSeconds = timecodeToNumber(startTime);

        const durationInMilliSeconds = parseInt(duration * 1000);

        if (timeInMilliSeconds > durationInMilliSeconds) {
            setShowDurationMessage(true);
            setTimeout(() => {
                setShowDurationMessage(false);
            }, 5000);
            return;
        }
        setChapTimerEntries((prevEntries) => [
            ...prevEntries,
            { start: startTime, timerLabel },
        ]);
        setHours("00");
        setMinutes("00");
        setSeconds("00");
        setMilliseconds("000");
        setTimerLabel("");
    };

    function timecodeToNumber(timecode) {
        const [hours, minutes, seconds, milliseconds] = timecode
            .split(/[:.]/)
            .map(Number);
        return (
            hours * 3600000 + minutes * 60000 + seconds * 1000 + milliseconds
        );
    }

    function numberToTimecode(milliseconds) {
        const pad = (num) => (num < 10 ? `0${num}` : num);
        const date = new Date(milliseconds);
        const hours = pad(date.getUTCHours());
        const minutes = pad(date.getUTCMinutes());
        const seconds = pad(date.getUTCSeconds());
        const millisecondsStr = pad(date.getUTCMilliseconds());
        return `${hours}:${minutes}:${seconds}.${millisecondsStr}`;
    }

    const subtractOneSecond = (timecode) => {
        // Convert timecode to milliseconds
        const totalMilliseconds = timecodeToNumber(timecode);

        // Subtract 1000 milliseconds (1 second)
        const newTotalMilliseconds = totalMilliseconds - 1000;

        // Ensure the result is non-negative
        const nonNegativeMilliseconds = Math.max(newTotalMilliseconds, 0);

        // Convert back to timecode format
        const resultTimecode = numberToTimecode(nonNegativeMilliseconds);

        return resultTimecode;
    };

    const handleSave = async () => {
        let vttData = `WEBVTT\n\n`;
        chapTimerEntries.sort((a, b) => a.start.localeCompare(b.start));
        let i = 1;
        while (i < chapTimerEntries.length) {
            const subtractedValue = subtractOneSecond(
                chapTimerEntries[i].start
            );
            vttData += `${i}\n${
                chapTimerEntries[i - 1].start
            } --> ${subtractedValue}\n${
                chapTimerEntries[i - 1].timerLabel
            }\n\n`;
            i++;
        }
        //tobe changed
        const endTime = "00:00:05.313";

        vttData += `${i}\n${chapTimerEntries[i - 1].start} --> ${endTime}\n${
            chapTimerEntries[i - 1].timerLabel
        }`;

        const response = trackId
            ? await allListingService.updateChapterTrack(
                  projectId,
                  mediaId,
                  trackId,
                  vttData,
                  trackLabel
              )
            : await allListingService.createChapter(
                  projectId,
                  mediaId,
                  vttData,
                  trackLabel
              );
        if (!trackId) {
            const newUrl = `/video/${mediaId}/createchapter/${response.data.data.data.id}`; // Change this to your desired URL
            window.history.pushState({ path: newUrl }, "", newUrl);
        }
        setShowMessage(true);
        setTimeout(() => {
            setShowMessage(false);
        }, 3000);

        setUpdateEmbedVideo((...prev) => prev + 1);
    };

    const validateInput = (value, field) => {
        // Use regex to ensure valid input (two digits for each field)
        const regex = /^\d*$/;
        const maxValue = field === "milliseconds" ? 999 : 59;

        if (!regex.test(value)) {
            // Return the current value if it doesn't pass the regex test
            switch (field) {
                case "hours":
                    return hours;
                case "minutes":
                    return minutes;
                case "seconds":
                    return seconds;
                case "milliseconds":
                    return milliseconds;
                default:
                    return "";
            }
        }

        if (value === "") {
            return "";
        }

        if (field === "hours") {
            return value;
        }
        return parseInt(value, 10) <= maxValue ? value : maxValue;
    };

    const handleInputChange = (e, field) => {
        const value = e.target.value;
        if (field === "timerLabel") {
            setTimerLabel(value);
            return;
        }

        if (field === "trackLabel") {
            setTrackLabel(value);
            return;
        }

        // Validate input for each field
        let validValue = validateInput(value, field);

        // Update state based on the field
        switch (field) {
            case "hours":
                setHours(validValue);
                break;
            case "minutes":
                setMinutes(validValue);
                break;
            case "seconds":
                setSeconds(validValue);
                break;
            case "milliseconds":
                setMilliseconds(validValue);
                break;
            default:
                break;
        }
    };

    const convertVttToObject = (vttFile) => {
        const lines = vttFile.split("\n");

        let i = 3;
        while (i < lines.length) {
            const start = lines[i].slice(0, 12);
            const timerLabel = lines[i + 1];

            setChapTimerEntries((prevEntries) => [
                ...prevEntries,
                { start, timerLabel },
            ]);
            i += 4;
        }
    };

    const fetchChapterTracks = async () => {
        const response = await allListingService.getChapterTrack(
            projectId,
            mediaId,
            trackId
        );
        if(!response.status)
            return;
        setTrackLabel(response.data.metadata.label);

        const vttData = await fetch(response.data.delivery_url);
        const vttFile = await vttData.text();
        convertVttToObject(vttFile);
    };

    const getDuration = async () => {
        if (location.start) {
            setDuration(location.start);
            return;
        }
        const response = await allListingService.getMetadata(
            mediaId,
            projectId
        );
  
        setDuration(response.data?.data?.data?.duration);
    };

    useEffect(() => {
        getDuration();

        if (trackId) {
            fetchChapterTracks();
        }
    }, []);

    return {
        timerLabel,
        trackLabel,
        hours,
        minutes,
        seconds,
        milliseconds,
        chapTimerEntries,
        addToChapList,
        handleSave,
        handleInputChange,
        handleDeleteChapterItem,
        showMessage,
        showDurationMessage,
        updateEmbedVideo,
    };
}

export default useTimer;

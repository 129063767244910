import React, { useEffect, useState, useContext, Suspense } from "react";
import planService from "../../services/api/plan-service";
import projectsService from "../../services/api/projects-service";
import ProjectContext from "../../ProjectContext";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  CardText,
  CardImg,
  Label,
  FormGroup,
  Form,
  Input,
  Alert,
  Spinner,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";
import { Link } from "react-router-dom";
import ReactBSAlert from "react-bootstrap-sweetalert";
import "./plan.css";
import notificationIcon from '../../assets/img/features_icon/notification.png';
import articleIcon from '../../assets/img/features_icon/articles.png'
import mediaStorageIcon from '../../assets/img/features_icon/media_storage.png'
import bandwidthIcon from '../../assets/img/features_icon/cdn_bandwidth.png'
import subuserIcon from '../../assets/img/features_icon/subuser.png'
import supportWidgetIcon from '../../assets/img/features_icon/hand.png'
import paidArticleIcon from '../../assets/img/features_icon/paid_article.png'
const PlanDetailsCard = (props) => {
  const projectDetails = useContext(ProjectContext);
  const [planFeatures, setPlanFeatures] = useState([]);
  const history = new useHistory();
  const [componentRendered, setComponentRendered] = useState(false);
  const [subscribePlan, setSubscribePlan] = useState(false);
  const [isPlanRenewed, setIsPlanRenewed] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  useEffect(() => {
     (async ()=>{
      const response = await planService.isPlanRenewed(projectDetails.id);
      if(response.status){
        if(response.data) {
          setIsPlanRenewed(true);
        }else {
          setIsPlanRenewed(false);
        }
      } 
     })();
  },[]);
  useEffect(() => {
     (async ()=>{
      const response = await planService.isSubscribed(projectDetails.id);
      if(response.status){
        if(response.data) {
          setIsSubscribed(true);
        }else {
          setIsSubscribed(false);
        }
      } 
     })();
  },[]);
  useEffect(() => {
    let planFeatures = props.details.planFeatures;
    let tempFeatures = [];
    if (planFeatures && Object.keys(planFeatures).length) {
      Object.keys(planFeatures).forEach((feature) => {
        tempFeatures.push({
          name: planFeatures[feature].feature_id.name,
          code: planFeatures[feature].feature_id.code,
          quota_duration: planFeatures[feature].feature_id.quota_duration,
          quota: planFeatures[feature].feature_quota || "",
        });
      });
      setPlanFeatures(tempFeatures);
      setComponentRendered(true);
    }
  }, []);

  const getDiscountMessage = () => {
    let basePrice = props.details.planPrice.base_price;
    let discount = props.details.planPrice.discount;
    if (props.details.details.type === "yearly") {
      let actualPrice = basePrice-discount;
      return <> &nbsp;Save flat {<i className="fa fa-inr" style={{ fontSize: "10px" }} />} {(Number(actualPrice/10-actualPrice/12)*12).toLocaleString()}  with yearly plan</>;
   }else{
     return <br></br>
   }
  }

  const checkboxHandler = (event) =>{
    setSubscribePlan(!subscribePlan)
  }

  const getFeatureIcon = (feature) => {
      switch (feature.code ) {
        case "web_push_notifications":
          return <img src={notificationIcon} ></img>
        case "articles_import":
          return <img src={articleIcon} ></img>
        case "media_storage":
          return <img src={mediaStorageIcon} ></img>
        case "cdn_bandwidth":
          return <img src={bandwidthIcon} ></img>
        case "sub_users":
          return <img src={subuserIcon} ></img>
        case "paid_article":
          return <img src={paidArticleIcon} ></img>
        case "support_widget":
          return <img src={supportWidgetIcon} ></img>
        case "auto_notifications":
          return <img src={notificationIcon} ></img>
      }
  }
  const getCouponAppliedPrice = (details) => {
      let base_price = props.details.planPrice.base_price;
      let discount = props.details.planPrice.discount || 0;
      let original_price = base_price - discount;
      let discount_type = props.couponApplied.discount_type;
      let discounted_price = 0;
      let coupon_discount = 0;
      if(discount_type==='percentage') {
          coupon_discount  = props.couponApplied.discount_percentage;
          discounted_price = (original_price * coupon_discount / 100);
      }else if(discount_type==='flat') {
          discounted_price = props.couponApplied.discount_flat;
      }

      if(props.couponApplied.limit_amount && discounted_price > props.couponApplied.limit_amount) {
          discounted_price = props.couponApplied.limit_amount;
      }
      if((base_price-discount -discounted_price)<0) {
        return getPriceWithMessage(props.details);
      }
      if (details.details.type === "yearly") {
      return (
        <>
        <span className="rate">
            <CardText tag="h6">
              <i className="fa fa-inr" style={{ fontSize: "14px" }} />
              &nbsp;
              <s>{(
                base_price
              ).toLocaleString()
              }</s>
            </CardText>
          </span>
          <span className="rate">
            <CardText tag="h3">
              <i className="fa fa-inr" style={{ fontSize: "23px" }} />
              &nbsp;
              {(base_price-discount -discounted_price).toLocaleString()}
              *
            </CardText>
          </span>
          
          <span className="offRate">
            <b>{getDiscountMessage()}</b>
          </span>
          <br />
          <br />
        </>
      );
      } else {
        return (
          <>
          <span className="rate">
              <CardText tag="h6">
                <i className="fa fa-inr" style={{ fontSize: "14px" }} />
                &nbsp;
                <s>{(
                  base_price
                ).toLocaleString()
                }</s>
              </CardText>
            </span>
            <span className="rate">
              <CardText tag="h3">
                <i className="fa fa-inr" style={{ fontSize: "23px" }} />
                &nbsp;
                {(base_price-discount - discounted_price).toLocaleString()}
                *
              </CardText>
                <b>/ per month</b>
            </span>
            <br />
            <br />
          </>
        );
      }
  }
  const getPriceWithMessage = (details) => {
    if (details.details.type === "yearly") {
      return (
        <>
        {props.details.planPrice.discount ?<span className="rate">
            <CardText tag="h6">
              <i className="fa fa-inr" style={{ fontSize: "14px" }} />
              &nbsp;
              <s>{(
                props.details.planPrice.base_price
              ).toLocaleString()
              }</s>
            </CardText>
          </span>:""}
          <span className="rate">
            <CardText tag="h3">
              <i className="fa fa-inr" style={{ fontSize: "23px" }} />
              &nbsp;
              {(
                (props.details.planPrice.base_price -
                  props.details.planPrice.discount) 
                
              ).toLocaleString()}
              *
            </CardText>
            {/* <b className="yrpMonth">per month (when paid yearly)</b> */}
          </span>
          <span className="offRate">
            <b>{getDiscountMessage()}</b>
          </span>
          <br />
          <br />
        </>
      );
    } else {
      return (
        <>
        {props.details.planPrice.discount ? <span className="rate">
            <CardText tag="h6">
              <i className="fa fa-inr" style={{ fontSize: "14px" }} />
              &nbsp;
              <s>{(
                props.details.planPrice.base_price
              ).toLocaleString()
              }</s>
            </CardText>
          </span>:""}
          <span className="rate">
            <CardText tag="h3">
              <i className="fa fa-inr" style={{ fontSize: "23px" }} />
              &nbsp;
              {(
                props.details.planPrice.base_price -
                props.details.planPrice.discount
              ).toLocaleString()}
              *
            </CardText>
            <b>/ per month</b>
          </span>
          <br />
          <br />
        </>
      );
    }
  };
  const getFeatureQuotaWithUnit = (quota) => {
    var units = ["GB", "TB"];
    let i = 0;
    for (i; quota >= 1024; i++) {
      quota /= 1024;
    }

    return quota + " " + units[i];
  };
  const getFeaturesTable = () => {
    let i = 1;

    const table = [];
    planFeatures.forEach((feature) => {
      table.push(
       
            <li >
            {props.planType === "Enterprises" ? (
              ""
            ) : (
              <>
              {getFeatureIcon(feature)}
              <b>               
                {feature.code === "media_storage" ||
                feature.code === "cdn_bandwidth"
                  ? getFeatureQuotaWithUnit(feature.quota)
                  :
                  (feature.code === "web_push_notifications"?
                  "":feature.quota.toLocaleString()
                  )
                  }
                  </b>
 
              </>
            )}
            {feature.name}
            {feature.quota_duration === "monthly" ? <>**</> : ""}
            
            </li>
            
      );
    });
    return table;
  };

  let checkoutLink ="/plan/checkout?projectId=" +projectDetails.id +"&planId=" +props.details.planPrice.plan_id +"&project=" +projectDetails.slug;
  let subscriptionLink ="/plan/subscribe?projectId=" +projectDetails.id +"&planId=" +props.details.planPrice.plan_id +"&project=" +projectDetails.slug;

  if(props.couponApplied && props.couponApplied.coupon_code) {
    checkoutLink += "&couponCode=" + props.couponApplied.coupon_code;
  }
  const isCouponApplied = ()=> {
    if(props.details.details.recurring_enabled && subscribePlan) return false;
    if(!props.couponApplied) return false;
    if(props.couponApplied.conditions && ( !props.couponApplied.conditions.includes('plan') || props.couponApplied.conditions.length==0) ) {
      return true;
    }
    return (props.couponApplied.conditions && props.couponApplied.conditions.includes('plan') && props.couponApplied.plan_ids.includes(props.details.planPrice.plan_id))
  }



  const checkPlanCompatablity =async() =>{
    const isPlanCompitibleForProject = await projectsService.isPlanCompatibleForProject(projectDetails.id, props.details.planPrice.plan_id);
    if(isPlanCompitibleForProject.status && isPlanCompitibleForProject.data){
        let response = isPlanCompitibleForProject.data;
        console.log({
          response: response.status
        })
        if(response.status === 'compatible') {
          return true;
        }else if(response.status === 'warning'){
          props.setShowPopup({
            alert: (
              <ReactBSAlert
                info
                style={{ display: "block", marginTop: "-100px" }}
                title="Warning"
                onConfirm={handleConfirm}
                onCancel={() => props.hideAlert()}
                confirmBtnBsStyle="info"
                confirmBtnText = "Continue"
                showConfirm = {true}
                showCancel = {true}
                btnSize=""
              >
                You have exhausted more than 85 % of the plan limit. We suggest you to switch to a higher plan.
              </ReactBSAlert>
            ),
        });
        }else if(response.status === 'error') {
          props.setShowPopup({
            alert: (
              <ReactBSAlert
                danger
                style={{ display: "block", marginTop: "-100px" }}
                title="Error"
                onConfirm={() => props.hideAlert()}
                onCancel={() => props.hideAlert()}
                confirmBtnBsStyle="info"
                btnSize=""
              >
                You have exhausted your plan limits. Please switch to a higher plan.
              </ReactBSAlert>
            ),
        });
        }
        return false;
    }
  }


  const handleOnSubscribe = async(e) =>{
    e.preventDefault();
    if(isSubscribed) {
      props.setShowPopup({
            alert: (
              <ReactBSAlert
                info
                style={{ display: "block", marginTop: "-100px" }}
                title="Warning"
                onConfirm={() => props.hideAlert()}
                confirmBtnBsStyle="info"
                confirmBtnText = "Ok"
                showConfirm = {true}
                showCancel = {false}
                btnSize=""
              >
                You have already Subscribe plan.
              </ReactBSAlert>
            ),
        });
        return false;
    }
    const isCompatable = await checkPlanCompatablity();
    console.log()
    if(isCompatable){
      history.push(subscriptionLink,"buyPlan");
    }
    return true;
}

  const handleOnPurchase = async (e) => {
    e.preventDefault();
    
    if(isPlanRenewed) {
      props.setShowPopup({
              alert: (
                <ReactBSAlert
                  info
                  style={{ display: "block", marginTop: "-100px" }}
                  title="Warning"
                  onConfirm={() => props.hideAlert()}
                  confirmBtnBsStyle="info"
                  confirmBtnText = "Ok"
                  showConfirm = {true}
                  showCancel = {false}
                  btnSize=""
                >
                  You have already renewed your contract.
                </ReactBSAlert>
              ),
          });
      return false;
    }

    const isCompatable = await checkPlanCompatablity();

    if(isCompatable){
      history.push(checkoutLink,"buyPlan");
    }
    return true;
  }
  const handleConfirm = async () => {
    props.hideAlert();
    history.push(subscribePlan ? subscriptionLink : checkoutLink,"buyPlan");
  }

  return (
    <Col md="3">
      <div
        className={
          props.details.details.plan_group_id.name === "Premium"
            ? "subsCard premiumPlan"
            : "subsCard userPurchase_plan"
        }
      >
        {props.isCurrentPlan?<span className="activeCrd">
          Current plan
        </span>:""}
        <CardTitle tag="h6">
          {props.details.details.plan_group_id.name}
        </CardTitle>
        {props.details.details.plan_group_id.name === "Premium" ? (
          <span className="popularPln">
            <b>Popular</b>
          </span>
        ) : (
        ""
        )}
        {props.planType === "Enterprises" ? (
          <CardText tag="h3">CUSTOM PLAN</CardText>
        ) : (
          isCouponApplied() ? getCouponAppliedPrice(props.details) : getPriceWithMessage(props.details)
        )}
            {!planFeatures || !planFeatures.length ? (
              !componentRendered ? (
                <ul>
                  <li colSpan="4" className="text-center">
                    <Spinner animation="border" role="status">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  </li>
                </ul>
              ) : (
                <ul>
                  <li colSpan="4" className="text-center">
                    <CardTitle tag="h4"></CardTitle>
                  </li>
                </ul>
              )
            ) : (
              <ul>              
                {getFeaturesTable()}
                </ul>

            )}
        {props.planType === "Enterprises" ? (
          <>
            <a
              href="mailto:helpdesk@sortd.mobi"
              style={{ height: "40px", width: "140px" }}
              className="btn btn-round btnBlue"
            >
              Contact Us
            </a>
          </>
        ) : (
          <>
            
              <Link 
              onClick={(e)=>handleOnPurchase(e)}
               className="btn btn-round btnBlue"
               to={{
                pathname : checkoutLink,
                state : "buyPlan"
              }}
               >
                {props.buttonLabel}
              </Link>

              {props.details.details.recurring_enabled && props.details.details.razorpay_plan_info?
                <Link 
                onClick={(e)=>handleOnSubscribe(e)}
                  className="btn btn-round btnBlue"
                  to={{
                      pathname : subscriptionLink,
                      state : "buyPlan"
                    }}
                  >
                    Subscribe for 12 months @ &nbsp;<i className="fa fa-inr" style={{"font-size": "12px"}} />{props.details.details.razorpay_plan_info.price}/month.
                </Link>
            : ""}
          </>
        )}
      </div>
    </Col>
  );
};

export default PlanDetailsCard;

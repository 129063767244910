import React, { useEffect, useState } from "react";
import formValidations from "../../variables/form-validations";
import authService from "../../services/api/auth-service.js";
import {useHistory} from "react-router-dom";
import projectsService from "../../services/api/projects-service";
//import { hotjar } from 'react-hotjar';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row,
} from "reactstrap";

const Login = (props)=> {
    const history = new useHistory();
    const [formdetails, setFormDetails] = useState({password:"",email:""});
    const [passwordstate, setPasswordState] = useState("");
    const [passworderror, setPasswordError] = useState("");
    const [emailstate, setEmailState] = useState("");
    const [emailerror, setEmailError] = useState("");
    const [errorstate, setErrorState] = useState('has-success');
    const [errormessage, setErrorMessage] = useState('There was an error. Please try again.');

    function gtag(){window.dataLayer.push(arguments);}
  useEffect(() => {
    (async () => {
      var gtag_id = 'G-4KKR34B2P6';
      if(window.location.hostname==='console.sortd.mobi'){
        gtag_id = 'G-N7860XY35S';
      }
      const script = document.createElement("script");
      script.src = "https://www.googletagmanager.com/gtag/js?id="+gtag_id;
      document.body.appendChild(script);

    window.dataLayer = window.dataLayer || [];
    gtag('js', new Date());
    gtag('config', gtag_id);
    })();
  }, []);
    useEffect(() => {
        //document.body.classList.toggle("login-page");
        //document.body.className = "register-page";
    }, []);
   


    useEffect( () => { 
      (async ()=>{
          let currentUser= await projectsService.getLoggedInUser();
          if(currentUser.status && currentUser.data){
              console.log("currentUser:",currentUser.data.email);
              gtag('login_state',"logged_in");
              gtag('event', 'email', {'email': currentUser.data.email});
              history.push("/dashboard/home");
          }else{
            gtag('event','session_state',{'session_state':"guest"});
          }
    })();
  }, []);

    // useEffect(() => {
    //   hotjar.initialize('2731547', '6');
    // }, [])
  
    const handleChange = (event) => {
        setFormDetails({
            ...formdetails,
            [event.target.id]: ( event.target.value ).trim(),
        });

        if(event.target.id=='password'){
          setPasswordState("has-success");
        }
    
        if(event.target.id=='email'){
          setEmailState("has-success");
        }
    }
    
    const handleBlur = (event) => {
        setFormDetails({
            ...formdetails,
            [event.target.id]: ( event.target.value ).trim(),
        });
    
        // if(event.target.id=='password'){
        //     validatePassword();
        // }
    
        // if(event.target.id=='email'){
        //     validatePublisherEmail();
        // }
    
    }

    const validatePassword = () => { 
        if (formdetails.password === '') {
          setPasswordState("has-danger");
          setPasswordError("This field is required");
          return false;
        }
      
        if(!formValidations.verifyLength(formdetails.password,6)){
          setPasswordState("has-danger");
          setPasswordError("Password should be of minimum 6 digits.");
          return false;
        }
      
        setPasswordState("has-success");
        return true;
    }

    const validatePublisherEmail = () => {
        if(formdetails.email===''){
          setEmailState("has-danger");
          setEmailError("This field is required");
          return false;
        }
  
        if(!formValidations.verifyEmail(formdetails.email)){
          setEmailState("has-danger");
          setEmailError("Please enter a valid email.");
          return false;
        }
  
        setEmailState("has-success");
        return true;
    }

    const handleSubmit = function(e){  validateForm();e.preventDefault() }
    
    const validateForm= async () =>{
        let emailvalidation =  validatePublisherEmail();
        let passwordvalidation = validatePassword();
        if(emailvalidation && passwordvalidation){
          try {
            const response = await authService.formLogin(formdetails);
            if(response.status){
              gtag('login_state',"logged_in");
              gtag('event', 'email', {'email': formdetails.email});
              window.location.href = "/dashboard/home";
            }else{
              setErrorState("has-danger");
              setErrorMessage(response.error.message);
            }
            
          } catch (err) {}
        }
        return false;
    }

    const openForgotPasswordScreen = (e)=>{
      history.push("/auth/forgotpassword");e.preventDefault();
    }

    const openRegistrationScreen = (e)=>{
      history.push("/auth/register");e.preventDefault();
    }
 
    return (
      <div className="register-page">
        <Container>
          <Row>
            <Col className="ms-auto me-auto" lg="4" md="6">
              <Form id="loginform" action="" className="form" method="POST" onSubmit={handleSubmit}>
                <Card className="card-login text-center">
                  <CardHeader>
                    <CardHeader>
                      <h3 className="header">Login</h3>
                      <div className="social">
                        <Button className="btn-icon btn-round" color="google" href="/api/auth/google">
                          <i className="fa fa-google" />
                        </Button>
                        <Button className="btn-icon btn-round" color="facebook" href="/api/auth/facebook">
                          <i className="fa fa-facebook-f" />
                        </Button>
                        <p className="card-description">or be classical</p>
                      </div>
                    </CardHeader>
                  </CardHeader>
                  <CardBody>
                  <InputGroup className={emailstate}>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-email-85" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input id="email" name="email" placeholder="Email..." type="text"  onChange={handleChange} onBlur={handleBlur}/>
                      {emailstate === "has-danger" ? (
                            <label className="error">
                              {emailerror}
                            </label>
                          ) : null} 
                    </InputGroup>

                    <InputGroup  className={passwordstate}>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-key-25" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input id="password" name="password" placeholder="Password..." type="password"  onChange={handleChange} onBlur={handleBlur} />
                      {passwordstate === "has-danger" ? (
                            <label className="error">
                              {passworderror}
                            </label>
                          ) : null} 
                    </InputGroup>

                    <InputGroup className={errorstate}>
                    {errorstate === "has-danger" ? (
                            <label className="error">
                              {errormessage}
                            </label>
                          ) : null}   
                          </InputGroup>  

                    <br />

                    <FormGroup check className="text-left">
                        <a href="#" onClick={(e) => openForgotPasswordScreen(e)}>
                          Forgot Password?
                        </a>

                        <a style={{'float':'right'}} href="#" onClick={(e) => openRegistrationScreen(e)}>
                          New User?
                        </a>
                    </FormGroup>
                  </CardBody>
                  <CardFooter>
                    <Button
                      block
                      className="btn-round mb-3"
                      color="warning"
                      type="button"
                      onClick={validateForm}
                    > 
                      Login
                    </Button>
                  </CardFooter>
                </Card>
              </Form>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("../../assets/img/bg/fabio-mangione.jpg")})`,
          }}
        />
      </div>
    );
  
}

export default Login;

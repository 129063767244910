import axios from "axios";

const getAlert=async (formdata)=>{

    const response = await axios.post('/api/alert/get-alerts',formdata);
    return response.data;

}
const markAlertAsRead= async(formdata)=>{

    const response = await axios.post('/api/alert/mark-alert-as-read',formdata);
    return response.data;
   
}
const getFlashAlert = async() => {
    const response = await axios.get('/api/alert/get-flash-alert');
    return response.data;
}

const markAllAlertAsRead= async(formdata)=>{
    const response = await axios.post('/api/alert/mark-all-alert-as-read',formdata);
    return response.data;
}

export default {
    getAlert,
    markAlertAsRead,
    getFlashAlert,
    markAllAlertAsRead,
}


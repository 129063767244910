import React, { useEffect, useState } from "react";

const Timer = (props)=> { 
    const [minutes,setMinute] = useState(props.minutes);
    const [seconds,setSeconds] = useState(props.seconds);
    

    useEffect(()=>{
        if(seconds > 0){
            setTimeout(() => setSeconds(seconds - 1), 1000);
        }else if(minutes > 0){
            setSeconds(59);
            setMinute(minutes-1);
        }else{
            props.setExpiry(true);
        }
    })

    return (
        <>
        <span><b>0{minutes}:{seconds < 10 ? 0:null}{seconds}</b></span>
        </>
    )
}

export default Timer;
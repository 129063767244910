import React, { useEffect, useState } from "react";
import formValidations from "../../variables/form-validations";
import onboardService from "../../services/api/onboard-service";
import projectsService from "../../services/api/projects-service";
import TimezoneSelect from 'react-timezone-select';
import {useCookies} from 'react-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Alert,
  Spinner,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

import Select from "react-select";

const CreateProject = (props)=> {
  //TODO values to be filled from cookie
  const [formdetails, setFormDetails] = useState({projectname:"",slug:"",about:"",projecturl:"",locale:"en",timezone:"Asia/Kolkata",templateid:""});
  const [projectnamestate, setProjectNameState] = useState("");
  const [projectnameerror, setProjectNameError] = useState("");
  const [aboutprojectstate, setAboutProjectState] = useState("");
  const [aboutprojecterror, setAboutProjectError] = useState("");
  const [projecturlstate, setProjectUrlState] = useState("");
  const [projecturlerror, setProjectUrlError] = useState("");
  const [projectlocalestate, setProjectLocaleState] = useState("");
  const [projectlocaleerror, setProjetLocaleError] = useState("");
  const [projecttimezonestate, setProjectTimezoneState] = useState("");
  const [projecttimezoneerror, setProjectTimezoneError] = useState("");
  const [existingProjects, setExistingProjects] = useState(false);
  const [showLoader, setShowLoader]   = useState(false);
  const [showSaveButton, setShowSaveButton]   = useState(true);
  const [alert, setAlert]   = useState({show:false,message:'',type:''});
  const [selectedTimezone,setSelectedTimezone] = useState({value: "Asia/Kolkata", label: "(GMT+5:30) Chennai, Kolkata, Mumbai, New Delhi"});
  const [locale,setLocale] = useState({value: "en", label: "English"});
  const [templates, setTemplates] = useState(false);
  const [defaultTemplateId, setDefaultTemplateId] = useState(false);
  const [agree, setAgree] = useState(false);

  const [cookies, setCookies] = useCookies(['wpProjectDetails'])
  var languageOptions = [
    { value: "af", label: "Afrikaans" },
    { value: "sq", label: "Albanian" },
    { value: "am", label: "Amharic" },
    { value: "ar", label: "Arabic" },
    { value: "hy", label: "Armenian" },
    { value: "az", label: "Azerbaijani" },
    { value: "eu", label: "Basque" },
    { value: "be", label: "Belarusian" },
    { value: "bn", label: "Bengali" },
    { value: "bs", label: "Bosnian" },
    { value: "bg", label: "Bulgarian" },
    { value: "ca", label: "Catalan" },
    { value: "ceb", label: "Cebuano" },
    { value: "ny", label: "Chichewa" },
    { value: "zh", label: "Chinese (Simplified)" },
    { value: "zh-TW", label: "Chinese (Traditional)" },
    { value: "co", label: "Corsican" },
    { value: "hr", label: "Croatian" },
    { value: "cs", label: "Czech" },
    { value: "da", label: "Danish" },
    { value: "nl", label: "Dutch" },
    { value: "en", label: "English" },
    { value: "eo", label: "Esperanto" },
    { value: "et", label: "Estonian" },
    { value: "tl", label: "Filipino" },
    { value: "fi", label: "Finnish" },
    { value: "fr", label: "French" },
    { value: "fy", label: "Frisian" },
    { value: "gl", label: "Galician" },
    { value: "ka", label: "Georgian" },
    { value: "de", label: "German" },
    { value: "el", label: "Greek" },
    { value: "gu", label: "Gujarati" },
    { value: "ht", label: "Haitian Creole" },
    { value: "ha", label: "Hausa" },
    { value: "haw", label: "Hawaiian" },
    { value: "iw", label: "Hebrew" },
    { value: "hi", label: "Hindi" },
    { value: "hmn", label: "Hmong" },
    { value: "hu", label: "Hungarian" },
    { value: "is", label: "Icelandic" },
    { value: "ig", label: "Igbo" },
    { value: "id", label: "Indonesian" },
    { value: "ga", label: "Irish" },
    { value: "it", label: "Italian" },
    { value: "ja", label: "Japanese" },
    { value: "jw", label: "Javanese" },
    { value: "kn", label: "Kannada" },
    { value: "kk", label: "Kazakh" },
    { value: "km", label: "Khmer" },
    { value: "ko", label: "Korean" },
    { value: "ku", label: "Kurdish (Kurmanji)" },
    { value: "ky", label: "Kyrgyz" },
    { value: "lo", label: "Lao" },
    { value: "la", label: "Latin" },
    { value: "lv", label: "Latvian" },
    { value: "lt", label: "Lithuanian" },
    { value: "lb", label: "Luxembourgish" },
    { value: "mk", label: "Macedonian" },
    { value: "mg", label: "Malagasy" },
    { value: "ms", label: "Malay" },
    { value: "ml", label: "Malayalam" },
    { value: "mt", label: "Maltese" },
    { value: "mi", label: "Maori" },
    { value: "mr", label: "Marathi" },
    { value: "mn", label: "Mongolian" },
    { value: "my", label: "Myanmar (Burmese)" },
    { value: "ne", label: "Nepali" },
    { value: "no", label: "Norwegian" },
    { value: "ps", label: "Pashto" },
    { value: "fa", label: "Persian" },
    { value: "pl", label: "Polish" },
    { value: "pt", label: "Portuguese" },
    { value: "pa", label: "Punjabi" },
    { value: "ro", label: "Romanian" },
    { value: "ru", label: "Russian" },
    { value: "sm", label: "Samoan" },
    { value: "gd", label: "Scots Gaelic" },
    { value: "sr", label: "Serbian" },
    { value: "st", label: "Sesotho" },
    { value: "sn", label: "Shona" },
    { value: "sd", label: "Sindhi" },
    { value: "si", label: "Sinhala" },
    { value: "sk", label: "Slovak" },
    { value: "sl", label: "Slovenian" },
    { value: "so", label: "Somali" },
    { value: "es", label: "Spanish" },
    { value: "su", label: "Sundanese" },
    { value: "sw", label: "Swahili" },
    { value: "sv", label: "Swedish" },
    { value: "tg", label: "Tajik" },
    { value: "ta", label: "Tamil" },
    { value: "te", label: "Telugu" },
    { value: "th", label: "Thai" },
    { value: "tr", label: "Turkish" },
    { value: "uk", label: "Ukrainian" },
    { value: "ur", label: "Urdu" },
    { value: "uz", label: "Uzbek" },
    { value: "vi", label: "Vietnamese" },
    { value: "cy", label: "Welsh" },
    { value: "xh", label: "Xhosa" },
    { value: "yi", label: "Yiddish" },
    { value: "yo", label: "Yoruba" },
    { value: "zu", label: "Zulu" }
    ];
  
  useEffect( () => {
    (async () =>{
      
      let projects = await projectsService.getExistingProjects();
      if(projects.status){
        setExistingProjects(projects.data);
      }
      let templateData = await projectsService.getTemplates();

      if (templateData.status) {
          let templateNames = templateData.data;
          let templateValues = [];
          Object.keys(templateNames).forEach(function (key) {
              templateValues.push({
                  value: key,
                  label: templateNames[key],
              });
          });
          setTemplates(templateValues);
          setDefaultTemplateId(
            templateValues[0].value
        );
        let language="en";
        if(languageOptions[cookies["wpProjectDetails"]["language"]])
        {
          language=cookies["wpProjectDetails"]["language"];
        }
        setFormDetails({
          projectname: cookies["wpProjectDetails"]["site_title"],
          slug:  generateProjectSlug(cookies["wpProjectDetails"]["site_title"]),
          about: cookies["wpProjectDetails"]["site_description"],
          projecturl: cookies["wpProjectDetails"]["wp_hosted_site_url"],
          locale: language,
          timezone: "Asia/Kolkata",
          templateid: templateValues[0].value
        })
        // setFormDetails({...formdetails,templateid:templateValues[0].value});
      }
      
      console.log("Cookie data", cookies["wpProjectDetails"])
    })();
  }, []);
  
  
  const handleChange = (event) => {
    setFormDetails({
      ...formdetails,
      [event.target.id]: ( event.target.value ),
    });
    if(event.target.id=="projecturl")
    {
    setProjectUrlState("has-success");
    setProjectUrlError("");
    }
  }

  const changeTimeZone = (event) => {
    setFormDetails({
      ...formdetails,
      timezone: event.value,
    });
    setSelectedTimezone(event)
  }

  const changeLocale = (event) => {
    setFormDetails({
      ...formdetails,
      locale: event.value,
    });
    setLocale(event)
  }
  const changeTemplate = (event) => {
    setFormDetails({
        ...formdetails,
        templateid: event.target.value,
    });
};

  const validateProjectName = () => {
      if(formdetails.projectname.trim()===''){
        setProjectNameState("has-danger");
        setProjectNameError("Project Name is required");
        return false;
      }
      if(formdetails.projectname.length >=50){
        setProjectNameState("has-danger");
        setProjectNameError("Project Name should have max 50 characters");
        return false;
      }
      if(formdetails.projectname.length <5){
        setProjectNameState("has-danger");
        setProjectNameError("Project Name should have min 5 characters");
        return false;
      }

      if(existingProjects){
        let duplicateName = false;
        Object.keys(existingProjects).forEach(function(key) {
            let existingProjectName = existingProjects[key].name;
            if(formdetails.projectname===existingProjectName){
              duplicateName = true;
            }
        })
        if(duplicateName){
          setProjectNameState("has-danger");
          setProjectNameError("You have already created this project.");
          return false;
        }
      }

      setProjectNameState("has-success");
      return true;
  }

  const validateProjectUrl = () => { 
    if (formdetails.projecturl === '') {
      setProjectUrlState("has-danger");
      setProjectUrlError("Website URL is required");
      return false;
    }

    if(!formValidations.verifyWebsiteUrl(formdetails.projecturl.trim())){
      setProjectUrlState("has-danger");
      setProjectUrlError("Invalid URL. Example :https://example.com");
      return false;
    }

    if(existingProjects){
      let duplicateUrl = false;
      Object.keys(existingProjects).forEach(function(key) {
          let existingProjectUrl = existingProjects[key].host;
          if(formdetails.projecturl===existingProjectUrl){
            duplicateUrl = true;
          }
      })
      if(duplicateUrl){
        setProjectUrlState("has-danger");
        setProjectUrlError("You have already created a project with this url.");
        return false;
      }
    }

    setProjectUrlState("has-success");
    return true;
  }
  const checkboxHandler = () => {
    setAgree(!agree);
  }
  const handleSubmit = function(e){  validateForm();e.preventDefault() }
  
  const generateProjectSlug = (projectSlug) => {
   
    projectSlug = projectSlug.replace(/^\s+|\s+$/g, ''); // trim
    projectSlug = projectSlug.toLowerCase();
  
    // remove accents, swap ñ for n, etc
    var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to   = "aaaaeeeeiiiioooouuuunc------";
    for (var i=0, l=from.length ; i<l ; i++) {
        projectSlug = projectSlug.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    projectSlug = projectSlug.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes

    return projectSlug;
  }

  const validateForm = async () => {
    let namevalidation = validateProjectName();
    let urlvalidation =  validateProjectUrl();
    if(namevalidation && urlvalidation){
      formdetails.projectname = formdetails.projectname.trim();
      formdetails.projecturl = formdetails.projecturl.trim();
      formdetails.slug = generateProjectSlug(formdetails.projectname);
      setShowLoader(true);
      setShowSaveButton(false);
      let createProject = await onboardService.createProject(formdetails);
      
      if(createProject.status && createProject.data){
        setTimeout(()=>{
          setShowLoader(false)
          setAlert({show:true,type:'success',message:'Project was created Successfully'});
        },2000);
        
        setTimeout(()=>{
          setAlert({show:false,type:'',message:''});
         // window.location.href = "/project/settings?project="+formdetails.slug;
         
         props.setProjectId(createProject.data.id);

        },4000);

        
      }else{
        setTimeout(()=>{
          setShowLoader(false)
          setShowSaveButton(true)
          setAlert({show:true,type:'danger',message:'Project could not be created, Please try again.'});
        },2000);

        setTimeout(()=>{
          setAlert({show:false,type:'',message:''});
        },4000);
      }
    }
  }

  const openEditForm = (event,fieldname) => {
    document.getElementById(fieldname+'_form').style.display = '';
    document.getElementById(fieldname+'_label').style.display = 'none';
    document.getElementById(fieldname).focus();
  }

  const hideForm = (event,fieldname) => {
    document.getElementById(fieldname+'_form').style.display = 'none';
    document.getElementById(fieldname+'_label').style.display = '';
  }


  let templateElements = [];
  if (templates) {
      let i = 0;
      templates.forEach(function (templateDetails) {
          i++;
          if (
              (!defaultTemplateId && i == 1) ||
              templateDetails.value == defaultTemplateId
          ) {
              templateElements.push(
                  <div className="form-check-radio">
                      <Label check>
                          <Input
                              defaultChecked
                              value={templateDetails.value}
                              id="templateid"
                              name="templateid"
                              type="radio"
                              onChange={changeTemplate}
                          />
                          {templateDetails.label}{" "}
                          <span className="form-check-sign" />
                      </Label>
                  </div>
              );
          } else {
              templateElements.push(
                  <div className="form-check-radio">
                      <Label check>
                          <Input
                              value={templateDetails.value}
                              id="templateid"
                              name="templateid"
                              type="radio"
                              onChange={changeTemplate}
                          />
                          {templateDetails.label}{" "}
                          <span className="form-check-sign" />
                      </Label>
                  </div>
              );
          }
      });
  }
    return (
      <div className="content">
        <Container>
          <Row>
          <Col className="ml-auto mr-auto"  md="12">
              <Card className="card-signup text-center">
                <CardHeader className="extra_text_addedsign">
                  <CardTitle tag="h4">To obtain the credentials, please create a project for your website on Sortd. You will be able to fully manage  PWA and AMP with this project.</CardTitle>
                </CardHeader>
                <CardBody>
                  <Form className="form-horizontol form" action="" id="createProject" method="POST" onSubmit={handleSubmit}>

                  <Row>
                      <Label sm="3" className="inbtw_formelements_label">Name</Label>
                      <Col sm="6" className="inbtw_formelements">
                        <FormGroup className={projectnamestate}>
                        <span id="projectname_label">
                              {formdetails.projectname}
                              <a style={{display: 'inline'}} title="Edit" onClick={(event)=>  openEditForm(event,'projectname') } href="javascript:void(0)" class="nav-link">
                                      <FontAwesomeIcon icon={faPencilAlt} />
                              </a>
                        </span>
                        <span style={{display: 'none'}} className="display-none" id="projectname_form">
                          <Input
                            name="projectname"
                            id="projectname"
                            type="text"
                            onChange={handleChange}
                            //onBlur={handleBlur}
                            onBlur={(event)=>  hideForm(event,'projectname') }
                            value={formdetails.projectname}
                          />
                          </span>
                          {projectnamestate === "has-danger" ? (
                            <label className="error">
                              {projectnameerror}
                            </label>
                          ) : null}
                        </FormGroup>
                      </Col>
                  </Row>

                  {/* <Row>
                      <Label sm="2">About Project</Label>
                      <Col sm="7">
                        <FormGroup className={aboutprojectstate}>
                          <Input
                            name="about"
                            id="about"
                            type="textarea"
                            onChange={handleChange}
                            value={formdetails.about}
                          />
                          {aboutprojectstate === "has-danger" ? (
                            <label className="error">
                              {aboutprojecterror}
                            </label>
                          ) : null}
                        </FormGroup>
                      </Col>
                  </Row> */}

                  <Row>
                  <Label sm="3" className="inbtw_formelements_label">Website URL</Label>
                      <Col sm="6" className="inbtw_formelements">
                      <a title="https://example.com">
                     
                        
                        <FormGroup className={projecturlstate}>
                        <span id="projecturl_label">
                              {formdetails.projecturl}
                              <a style={{display: 'inline'}} title="Edit" onClick={(event)=>  openEditForm(event,'projecturl') } href="javascript:void(0)" class="nav-link">
                                      <FontAwesomeIcon icon={faPencilAlt} />
                              </a>
                        </span>
                        <span style={{display: 'none'}} className="display-none" id="projecturl_form">
                          <Input
                            name="projecturl"
                            id="projecturl"
                            type="url"
                            //onBlur={handleBlur}
                            onChange={handleChange}
                            onBlur={(event)=>  hideForm(event,'projecturl') }
                            value={formdetails.projecturl}
                          />
                          </span>
                          {projecturlstate === "has-danger" ? (
                            <label className="error">
                              {projecturlerror}
                            </label>
                          ) : null}
                          
                        </FormGroup>
                        </a>
                      </Col>
                     
                  </Row>

                  {/* <Row>
                      <Label sm="2">Locale</Label>
                      <Col sm="7">
                        <FormGroup className={projectlocalestate}>
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="locale"
                            value={locale}
                            onChange={changeLocale}
                            options={[
                              { value: "af", label: "Afrikaans" },
                              { value: "sq", label: "Albanian" },
                              { value: "am", label: "Amharic" },
                              { value: "ar", label: "Arabic" },
                              { value: "hy", label: "Armenian" },
                              { value: "az", label: "Azerbaijani" },
                              { value: "eu", label: "Basque" },
                              { value: "be", label: "Belarusian" },
                              { value: "bn", label: "Bengali" },
                              { value: "bs", label: "Bosnian" },
                              { value: "bg", label: "Bulgarian" },
                              { value: "ca", label: "Catalan" },
                              { value: "ceb", label: "Cebuano" },
                              { value: "ny", label: "Chichewa" },
                              { value: "zh", label: "Chinese (Simplified)" },
                              { value: "zh-TW", label: "Chinese (Traditional)" },
                              { value: "co", label: "Corsican" },
                              { value: "hr", label: "Croatian" },
                              { value: "cs", label: "Czech" },
                              { value: "da", label: "Danish" },
                              { value: "nl", label: "Dutch" },
                              { value: "en", label: "English" },
                              { value: "eo", label: "Esperanto" },
                              { value: "et", label: "Estonian" },
                              { value: "tl", label: "Filipino" },
                              { value: "fi", label: "Finnish" },
                              { value: "fr", label: "French" },
                              { value: "fy", label: "Frisian" },
                              { value: "gl", label: "Galician" },
                              { value: "ka", label: "Georgian" },
                              { value: "de", label: "German" },
                              { value: "el", label: "Greek" },
                              { value: "gu", label: "Gujarati" },
                              { value: "ht", label: "Haitian Creole" },
                              { value: "ha", label: "Hausa" },
                              { value: "haw", label: "Hawaiian" },
                              { value: "iw", label: "Hebrew" },
                              { value: "hi", label: "Hindi" },
                              { value: "hmn", label: "Hmong" },
                              { value: "hu", label: "Hungarian" },
                              { value: "is", label: "Icelandic" },
                              { value: "ig", label: "Igbo" },
                              { value: "id", label: "Indonesian" },
                              { value: "ga", label: "Irish" },
                              { value: "it", label: "Italian" },
                              { value: "ja", label: "Japanese" },
                              { value: "jw", label: "Javanese" },
                              { value: "kn", label: "Kannada" },
                              { value: "kk", label: "Kazakh" },
                              { value: "km", label: "Khmer" },
                              { value: "ko", label: "Korean" },
                              { value: "ku", label: "Kurdish (Kurmanji)" },
                              { value: "ky", label: "Kyrgyz" },
                              { value: "lo", label: "Lao" },
                              { value: "la", label: "Latin" },
                              { value: "lv", label: "Latvian" },
                              { value: "lt", label: "Lithuanian" },
                              { value: "lb", label: "Luxembourgish" },
                              { value: "mk", label: "Macedonian" },
                              { value: "mg", label: "Malagasy" },
                              { value: "ms", label: "Malay" },
                              { value: "ml", label: "Malayalam" },
                              { value: "mt", label: "Maltese" },
                              { value: "mi", label: "Maori" },
                              { value: "mr", label: "Marathi" },
                              { value: "mn", label: "Mongolian" },
                              { value: "my", label: "Myanmar (Burmese)" },
                              { value: "ne", label: "Nepali" },
                              { value: "no", label: "Norwegian" },
                              { value: "ps", label: "Pashto" },
                              { value: "fa", label: "Persian" },
                              { value: "pl", label: "Polish" },
                              { value: "pt", label: "Portuguese" },
                              { value: "pa", label: "Punjabi" },
                              { value: "ro", label: "Romanian" },
                              { value: "ru", label: "Russian" },
                              { value: "sm", label: "Samoan" },
                              { value: "gd", label: "Scots Gaelic" },
                              { value: "sr", label: "Serbian" },
                              { value: "st", label: "Sesotho" },
                              { value: "sn", label: "Shona" },
                              { value: "sd", label: "Sindhi" },
                              { value: "si", label: "Sinhala" },
                              { value: "sk", label: "Slovak" },
                              { value: "sl", label: "Slovenian" },
                              { value: "so", label: "Somali" },
                              { value: "es", label: "Spanish" },
                              { value: "su", label: "Sundanese" },
                              { value: "sw", label: "Swahili" },
                              { value: "sv", label: "Swedish" },
                              { value: "tg", label: "Tajik" },
                              { value: "ta", label: "Tamil" },
                              { value: "te", label: "Telugu" },
                              { value: "th", label: "Thai" },
                              { value: "tr", label: "Turkish" },
                              { value: "uk", label: "Ukrainian" },
                              { value: "ur", label: "Urdu" },
                              { value: "uz", label: "Uzbek" },
                              { value: "vi", label: "Vietnamese" },
                              { value: "cy", label: "Welsh" },
                              { value: "xh", label: "Xhosa" },
                              { value: "yi", label: "Yiddish" },
                              { value: "yo", label: "Yoruba" },
                              { value: "zu", label: "Zulu" }
                              ]}
                          />
                          
                          {projectlocalestate === "has-danger" ? (
                            <label className="error">
                              {projectlocaleerror}
                            </label>
                          ) : null}
                        </FormGroup>
                      </Col>
                  </Row> */}

                  {/* <Row>
                      <Label sm="2">Timezone</Label>
                      <Col sm="7">
                        <FormGroup className={projecttimezonestate}>
                        <TimezoneSelect
                          name = "timezone"
                          value={selectedTimezone}
                          onChange={changeTimeZone}
                        />
                          
                          {projecttimezonestate === "has-danger" ? (
                            <label className="error">
                              {projecttimezoneerror}
                            </label>
                          ) : null}
                        </FormGroup>
                      </Col>
                  </Row> */}
                  {/* <Row>
                    <Label sm="2">Template</Label>
                      <Col sm="7">
                        <FormGroup>
                          {templateElements}
                        </FormGroup>
                      </Col>
                  </Row>                   */}
                  <Row>
                      <Col sm="8">
                        <FormGroup>
                          <Input type="checkbox" id="agree" onChange={checkboxHandler} />
                          <Label className="lblNme" htmlFor="agree"> &nbsp;&nbsp;I accept and agree to the <a target="_blank" href="https://www.sortd.mobi/terms-of-service"><b>terms and conditions</b></a></Label>
                          
                        </FormGroup>
                      </Col>
                  </Row>    
                  {showSaveButton?<Button
                    className={agree ? "btn-round extra_pading_buttonsplus": "btn-round extra_pading_buttonsplus uncheckeddisabled"}
                    color="info"
                    type="button"
                    disabled={!agree}
                    onClick={validateForm}
                  >
                    Create Project
                  </Button>:''}
                  {alert.show?<Alert color={alert.type}>
                    {alert.message}
                  </Alert>:''}

                  {showLoader?<Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>:''}
                    
                  </Form>
                </CardBody>
                <CardFooter className="default_card_settingfoot">
                Upon successfully creating the project, we will activate the trial plan. 
                <a target="_blank" href="https://sortd.mobi"> View Plan Details </a>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
          }}
        />
      </div>
    );
  
}

export default CreateProject;

import axios from "axios";

const getConfigSchema = async(projectId) =>{
    let data = {projectId:projectId}
    const response = await axios.post('/api/customizations/config-schema',data);
    return response.data;
}

const getSavedConfig = async(projectId) =>{
    let data = {projectId:projectId}
    const response = await axios.post('/api/customizations/saved-config',data);
    return response.data;
}

const storeConfig = async(projectId,config_type,config) =>{
    let data = {projectId:projectId,groupName:config_type,formdata:config}
    const response = await axios.post('/api/customizations/store-config',data);
    return response.data;
}

const uploadImage = async(projectId,fieldId,imageData,contentType) =>{
    let data = {projectId:projectId,fieldId:fieldId,imageData:imageData,contentType:contentType}
    const response = await axios.post('/api/customizations/upload-image',data);
    return response.data;
}

const getAppConfigSchema = async() =>{
    const response = await axios.get('/api/customizations/app-config-schema');
    // console.log("APi config response ", response.data)
    return response.data;
}

const getAppSavedConfig = async(projectId,version) =>{
    let data = {projectId:projectId,version : version}
    // console.log("Data:",data);
    const response = await axios.post('/api/customizations/app-saved-config',data);
    return response.data;
}

const storeAppConfig = async(projectId,version,config_type,config) =>{
    let data = {projectId:projectId,version:version, groupName:config_type,formdata:config}
    const response = await axios.post('/api/customizations/store-app-config',data);
    return response.data;
}
const getAppConfigVersions = async (projectId) =>{
    let formdata = {
        projectId
    }
    const response =  await axios.post('/api/customizations/app-config-versions',formdata);
    return response.data;
}
const createAppConfig = async (formdata) =>{
    const response = await axios.post('/api/customizations/create-app-config', formdata);
    return response.data;
}
const publishAppConfig = async(projectId,version) =>{
    const response = await axios.post('/api/customizations/publish-app-config',{projectId,version})
    return response.data;
}
const deactivateAppConfig = async(projectId,version) =>{
    const response = await axios.post('/api/customizations/deactivate-app-config',{projectId,version})
    return response.data;
}
const activateAppConfig = async(projectId,version) =>{
    const response = await axios.post('/api/customizations/activate-app-config',{projectId,version})
    return response.data;
}

export default{
    getConfigSchema,
    getAppConfigSchema,
    getSavedConfig,
    storeConfig,
    uploadImage,
    getAppSavedConfig,
    storeAppConfig,
    getAppConfigVersions,
    createAppConfig,
    publishAppConfig,
    deactivateAppConfig,
    activateAppConfig
}
import React, { useEffect, useState } from "react";
import TimezoneSelect from "react-timezone-select";

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Input,
    Alert,
    Spinner,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col,
} from "reactstrap";

import Select from "react-select";
import planService from "../../services/api/plan-service";
import moment from "moment";
import { Link } from "react-router-dom";
import { number } from "prop-types";
import {useCookies} from 'react-cookie';
import { faFileSignature } from "@fortawesome/free-solid-svg-icons";
const PlanExpiryAlert = (props) => {
    
    const [cookies, setCookie] = useCookies(['showPlanExpiryAlert'])
    const [showAlert,setShowAlert]=useState(true);
    //console.log("Cookies",cookies.showPlanExpiryAlert);
    const [visible, setVisible] = useState(true);

    const hideAlert = () =>{
        if(cookies.showPlanExpiryAlert && cookies.showPlanExpiryAlert==="hide")
        {
            setShowAlert(false)
        }else{ 
            setShowAlert(true);
        }
    }

    useEffect(()=>{
        hideAlert()
    },[])


    const onDismiss = () =>{ 
        setVisible(false);
        setCookie('showPlanExpiryAlert',"hide");
        setShowAlert(false);
    }
    const [planType, setPlanType] = useState("");
    const [planStatus, setPlanStatus] = useState({ status: "Active", daysLeft: 0 });
    const [alertMessage, setAlertMessage] = useState("");
    const [isPlanExtended,setIsPlanExtended] = useState(false);
    const [planDetails,setPlanDetails] = useState(false);   


    useEffect(() => {
        (async () => {
            const isSubscribed = await planService.isSubscribed(props.projectDetails.id);
            if(isSubscribed.status && isSubscribed.data) {
                onDismiss();
                return;
            }
            const response = await planService.getPlanDetails(
                props.projectDetails.id
            );
            if (response.status && response.data) {
                setIsPlanExtended(response.data.is_extended);
                setPlanDetails(response.data);
                setPlanType(response.data.plan_type);
                const today=moment().subtract(1,"minute");
                var numberOfHoursLeftToExpirePlan = moment(
                    response.data.plan_expire_date
                ).diff(today, "hours");       

                //if numOfHours <=0 then expired

                //else if noOfHours>0 &&noOfHours<24
                var numberOfDaysLeftToExpirePlan;
               if(numberOfHoursLeftToExpirePlan<=0)
               {

                numberOfDaysLeftToExpirePlan=-1;
               }else{
                numberOfDaysLeftToExpirePlan= Math.floor(numberOfHoursLeftToExpirePlan/24); 
               }
            //    console.log("numberOfDaysLeftToExpirePlan",numberOfDaysLeftToExpirePlan);
 
                if (numberOfDaysLeftToExpirePlan< 0) {
                    setPlanStatus({ status: "Expired", daysLeft: 0 });
                    if (response.data.plan_type == "trial") {
                        setAlertMessage(
                            <div>
                                Your Trial Plan has expired.
                                <Link
                                    to={{
                                        pathname: "/dashboard/plans",
                                    }}
                                >
                                    Buy Plan
                                </Link>
                            </div>
                        );
                    } else {
                        let checkoutLink = "/plan/checkout?projectId=" +
                        props.projectDetails.id +
                        "&planId=" +
                        response.data.plan_id +
                        "&project=" +
                        props.projectDetails.slug;
                        setAlertMessage(
                            <div>
                                Your Plan has expired.
                                <Link
                                    to={checkoutLink}
                                >
                                    Renew Plan
                                </Link>
                            </div>
                        );
                    }
                } else {
                    if (response.data.expire_status==1) {
                        setPlanStatus({
                            status: "Expiring",
                            daysLeft: numberOfDaysLeftToExpirePlan,
                        });
                        if (response.data.plan_type == "trial") {
                           // console.log("Expiring");
                           if(numberOfDaysLeftToExpirePlan==0)
                           {
                            setAlertMessage(
                                <div>
                                    Your Trial Plan is expiring today.
                                    <Link
                                        to={{
                                            pathname: "/dashboard/plans",
                                            //   state: {
                                            //     projectId:props.projectDetails.id
                                            //   },
                                        }}
                                    >
                                        Buy Plan
                                    </Link>
                                </div>
                            );
                           }else{

                        
                            setAlertMessage(
                                <div>
                                    Your Trial Plan is about to expiring in{" "}
                                    {numberOfDaysLeftToExpirePlan} day(s).
                                    <Link
                                        to={{
                                            pathname: "/dashboard/plans",
                                            //   state: {
                                            //     projectId:props.projectDetails.id
                                            //   },
                                        }}
                                    >
                                        Buy Plan
                                    </Link>
                                </div>
                            );
                                    }
                        } else {
                            let checkoutLink = "/plan/checkout?projectId=" +
                        props.projectDetails.id +
                        "&planId=" +
                        response.data.plan_id +
                        "&project=" +
                        props.projectDetails.slug;
                            if(numberOfDaysLeftToExpirePlan==0)
                            {
                                
                                setAlertMessage(
                                    <div>
                                        Your Plan is expiring today.
                                        <Link
                                            to={checkoutLink}
                                        >
                                            Extend Plan
                                        </Link>
                                    </div>
                                );
                            }
                            else{
                            setAlertMessage(
                                <div>
                                    Your Plan is about to expiring in {""} 
                                    {numberOfDaysLeftToExpirePlan} day(s).
                                    <Link
                                        to={checkoutLink}
                                    >
                                        Extend Plan
                                    </Link>
                                </div>
                            );
                                    }
                        }
                    } else {
                        setPlanStatus({
                            status: "Active",
                            daysLeft: numberOfDaysLeftToExpirePlan,
                        });
                    }
                }
 
            } else {
                setPlanType("trial");
                setPlanStatus({ status: "Expired", daysLeft: 0 });
                setAlertMessage(
                    <div>
                        Your Trial Plan has expired.
                        <Link
                            to={{
                                pathname: "/dashboard/plans",
                                //   state: {
                                //     projectId:props.projectDetails.id
                                //   },
                            }}
                        >
                            Buy Plan
                        </Link>
                    </div>
                );
            }
        })();
    }, []);

    if(props.projectDetails.id=="") return "";
    else{
    return <>{isPlanExtended?"":<> 
            {
                showAlert? 
                planStatus.status != "Active" ? 
                        <div
                            style={{
                                position:"relative",
                                marginLeft: "40px",
                                marginRight: "40px",
                                marginTop: "5px",
                                marginBottom: "-1px",
                            }}
                        >
                            
                            <Alert  style={{"background-color":"#ed1a44"}} isOpen={visible} toggle={onDismiss}>
                                {alertMessage}
                            </Alert>
                
                        </div>
                    : ""
                : ""
            }
        
        </>
    }</>
    }
};

export default PlanExpiryAlert;

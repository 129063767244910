import React, { useEffect, useState,useContext } from "react";
import ProjectContext from "../../../ProjectContext";
import {useHistory} from "react-router-dom";
import Switch from "react-bootstrap-switch";
import formValidations from "../../../variables/form-validations";
import { Prompt } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faTrash,faTablet,faTrashRestoreAlt } from '@fortawesome/free-solid-svg-icons'
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Alert,
  Spinner,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

import Select from "react-select";
import FileUpload from '../utility-component/FileUpload.js';
import ImageUpload from "../utility-component/UploadImage.js";
import defaultImage from "../../../assets/img/image_placeholder.jpg";
import ReactBSAlert from "react-bootstrap-sweetalert";
import iosService from '../../../services/api/sortd-apps/ios-service';
import generalService from '../../../services/api/sortd-apps/general-service.js';
const IOS = (props) => {
    const projectDetails = useContext(ProjectContext);
    const history = new useHistory();
    if(projectDetails.id==''){
        history.push("/project/allprojects");
    }
    const projectId = projectDetails.id;
    const [formdetails, setFormDetails] = useState({project_id:projectId,fcm_api_server_key : "",ios_app_enabled:true,ios_app_name:"",ios_app_package_name:"",ios_google_services_json:"",ios_username:"",ios_teamname:"",ios_team_id:'',app_store_api_key_id: '',app_store_api_key_issuer_id: '',app_store_api_auth_key_url: '',app_store_api_auth_key_file_name:'',launcher_icon:"",app_logo:"",launcher_background:"#eadcdc"});
    const [showLoader, setShowLoader]   = useState(false);
    const [showSaveButton, setShowSaveButton]   = useState(true);
    const [alert, setAlert]   = useState({show:false,message:'',type:''});
    const [projectName, setProjectName] = useState("");
    const [fieldStates,setFieldStates] = useState({});
    const [isSaved,setIsSaved] = useState(false);
    const [projectAppsGeneralSettings , setProjectAppsGeneralSettings] = useState({});
    const [existingApps, setExistingApps] = useState([]);
    const [json,setJSON] = useState({});
    const [showPopup, setShowPopup] = useState({ alert: null });
    const [reloadComponent ,setReloadComponent] = useState(null);
    const [iosAppEnabled, setIOSAppEnabled] = useState(false);
    const [fileName,setFileName] = useState("");
    useEffect(()=> {
        if(fileName) {
            setFormDetails({
            ...formdetails,
            app_store_api_auth_key_file_name : fileName
        });
        console.log("UseEffect got called",fileName);
        }
    },[fileName])
    useEffect(()=>{
        (async ()=> {
            if(!projectId) return;
            let getAppsGeneralDetails = await generalService.getAppsGeneralDetails(projectId);
            if(getAppsGeneralDetails.status && getAppsGeneralDetails.data) {
                setFormDetails({
                    fcm_api_server_key: getAppsGeneralDetails.data.fcm_api_server_key,
                    launcher_icon: getAppsGeneralDetails.data.launcher_icon,
                    app_logo: getAppsGeneralDetails.data.app_logo,
                });
            }
        })();
    },[])
    useEffect(()=>{
        (async ()=> {
            if(!projectId) return;
            
            const iosAppInfo = await iosService.getIOSAppInfo(projectId);
            console.log("IOSAppInfo",iosAppInfo);
            if(iosAppInfo.status){
                if(iosAppInfo.data) {
                    setFormDetails({
                        ...formdetails,
                        fcm_api_server_key: iosAppInfo.data.fcm_api_server_key,
                        ios_app_enabled: iosAppInfo.data.ios_app_enabled,
                        ios_app_name: iosAppInfo.data.ios_app_name,
                        ios_app_package_name: iosAppInfo.data.ios_app_package_name,
                        ios_team_id : iosAppInfo.data.ios_team_id,
                        launcher_icon: iosAppInfo.data.launcher_icon,
                        app_logo: iosAppInfo.data.app_logo,
                        app_store_api_key_id: iosAppInfo.data.app_store_api_key_id ,
                        app_store_api_key_issuer_id: iosAppInfo.data.app_store_api_key_issuer_id,
                        app_store_api_auth_key_url: iosAppInfo.data.app_store_api_auth_key_url,
                        app_store_api_auth_key_file_name : iosAppInfo.data.app_store_api_auth_key_file_name,
                        
                    });
                    setIOSAppEnabled(iosAppInfo.data.ios_app_enabled)
                }
            }
            
        })();
    },[])


  const handleEnumChange = (inputId, inputValue) => {
    setFormDetails({
        ...formdetails,
        [inputId]: ( inputValue ),
      }); 
    fieldStates['error_'+inputId] = 'has-success';
    fieldStates['error_message_'+inputId] = '';
    setFieldStates({
    ...fieldStates,
    fieldStates
    }); 
  }

  const handleChange = (event) => {
    setFormDetails({
      ...formdetails,
      [event.target.id]: ( event.target.value ),
    });
    fieldStates['error_'+event.target.id] = 'has-success';
    fieldStates['error_message_'+event.target.id] = '';
    setFieldStates({
        ...fieldStates,
        fieldStates
    });
  }
  
    const handleJSONChange = (event) => {
    setFormDetails({
      ...formdetails,
      android_google_services_json: event.target.value ,
    });
  }

  const handleBlur = (event) => {
    setFormDetails({
      ...formdetails,
      [event.target.id]: ( event.target.value ),
    });

  }

    const handleSwitchChange = (fieldname,fieldvalue) => {
        setFormDetails({
        ...formdetails,
        [fieldname]: fieldvalue,
        });
    }
    const handleSubmit = function(e){ onSave(); e.preventDefault() }
    const onSave = ()=> {
      if(validateIOSForm() === true) {
          confirmationPopup();
      }
    }
    const validateIOSForm = () => {
        console.log("Validation Start:",formdetails);
        let validations = true;
        if(formdetails.ios_app_package_name===undefined || formdetails.ios_app_package_name.trim()===''){
            fieldStates['error_ios_app_package_name'] = 'has-danger';
            fieldStates['error_message_ios_app_package_name'] = 'This field is required.';
            validations = false;
        }else{
            
            fieldStates['error_ios_app_package_name'] = 'has-success';
            fieldStates['error_message_ios_app_package_name'] = '';
        }
        if(formdetails.ios_team_id === undefined || formdetails.ios_team_id.trim()===''){
            fieldStates['error_ios_team_id'] = 'has-danger';
            fieldStates['error_message_ios_team_id'] = 'This field is required.';
            validations = false;
        }else{
            fieldStates['error_ios_team_id'] = 'has-success';
            fieldStates['error_message_ios_team_id'] = '';
        }
        if(formdetails.app_store_api_key_id===undefined || formdetails.app_store_api_key_id.trim()===''){
            fieldStates['error_app_store_api_key_id'] = 'has-danger';
            fieldStates['error_message_app_store_api_key_id'] = 'This field is required.';
            validations = false;
        }else{
            fieldStates['error_app_store_api_key_id'] = 'has-success';
            fieldStates['error_message_app_store_api_key_id'] = '';
        }
        if(formdetails.app_store_api_key_issuer_id === undefined || formdetails.app_store_api_key_issuer_id.trim()==='' ){
            fieldStates['error_app_store_api_key_issuer_id'] = 'has-danger';
            fieldStates['error_message_app_store_api_key_issuer_id'] = 'This field is required.';
            validations = false;
        }else{
            fieldStates['error_app_store_api_key_issuer_id'] = 'has-success';
            fieldStates['error_message_app_store_api_key_issuer_id'] = '';
        }
        if(formdetails.app_store_api_auth_key_url===undefined || formdetails.app_store_api_auth_key_url.trim()===''){
            fieldStates['error_app_store_api_auth_key_url'] = 'has-danger';
            fieldStates['error_message_app_store_api_auth_key_url'] = 'This field is required.';
            validations = false;
        }else{
            fieldStates['error_app_store_api_auth_key_url'] = 'has-success';
            fieldStates['error_message_app_store_api_auth_key_url'] = '';
        }
        

        if(formdetails.launcher_icon.trim()===''){
            fieldStates['error_launcher_icon'] = 'has-danger';
            fieldStates['error_message_launcher_icon'] = 'This field is required.';
            validations = false;
        }else{
            fieldStates['error_launcher_icon'] = 'has-success';
            fieldStates['error_message_launcher_icon'] = '';
        }

        if(formdetails.app_logo.trim()===''){
            fieldStates['error_app_logo'] = 'has-danger';
            fieldStates['error_message_app_logo'] = 'This field is required.';
            validations = false;
        }else{
            fieldStates['error_app_logo'] = 'has-success';
            fieldStates['error_message_app_logo'] = '';
        }
        
        setFieldStates({
            ...fieldStates,
            fieldStates
        });
        console.log("Validation",validations);
        return validations;
    }
    const saveAppGeneralSettings = async ()=> {
        console.log();
        let iosAppInfo = await iosService.saveIOSAppInfo(formdetails);
        console.log("iosAppInfo",iosAppInfo);
        if(projectDetails.isContractExpired){
            setShowPopup({alert:null});
            props.hideAlert();
            projectDetails.setContractExpiredAlert(projectDetails);
        }
        else if (iosAppInfo.status && iosAppInfo.data) {
                setShowPopup({
                    alert: (
                        <ReactBSAlert
                            success
                            style={{ display: "", marginTop: "-100px" }}
                            title="iOS App setting saved !"
                            onConfirm={() =>{hideAlert();props.hideAlert();props.setIsCompleted()} }
                            onCancel={() => hideAlert()}
                            confirmBtnBsStyle="info"
                            btnSize=""
                            openAnim = {{ name: 'showSweetAlert', duration: 2000 }}
                        >
                            iOS App settings saved successfully.
                        </ReactBSAlert>
                    ),
                });           
        }else {
                setTimeout(() => {
                    hideAlert();
                    // window.location.reload();
                }, 1000);
                if(iosAppInfo.error && iosAppInfo.error.errorCode=== 1015) {
                    fieldStates['error_ios_app_package_name'] = 'has-danger';
                    fieldStates['error_message_ios_app_package_name'] = iosAppInfo.error.message;
                    setFieldStates({
                            ...fieldStates,
                            fieldStates
                        });
                }
                if(iosAppInfo.error && iosAppInfo.error.errorCode===1016) {
                    fieldStates['error_app_store_api_key_id'] = 'has-danger';
                    fieldStates['error_message_app_store_api_key_id'] = 'Invalid Api key';
                    fieldStates['error_app_store_api_key_issuer_id'] = 'has-danger';
                    fieldStates['error_message_app_store_api_key_issuer_id'] = 'Invalid Issuer Id.';
                    fieldStates['error_app_store_api_auth_key_url'] = 'has-danger';
                    fieldStates['error_message_app_store_api_auth_key_url'] = 'Invalid Auth key file.';
                    fieldStates['error_ios_team_id'] = 'has-danger';
                    fieldStates['error_message_ios_team_id'] = 'Invalid iOS Team id.';
                    setFieldStates({
                            ...fieldStates,
                            fieldStates
                        });
                }
        }
    }

    const confirmationPopup = () => {
        setShowPopup({
            alert: (
                <ReactBSAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Are you sure want to save app general settings ?"
                    onConfirm={() => {
                        saveAppGeneralSettings();
                        // hideAlert();
                    }}
                    onCancel={() => {
                        hideAlert();
                    }}
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    confirmBtnText="Yes, Save it!"
                    cancelBtnText="Cancel"
                    showCancel
                    btnSize=""
                    openAnim = {{ name: 'showSweetAlert', duration: 2000 }}
                >
                    You want to save App general settings!
                </ReactBSAlert>
            ),
        });
    };
    const hideAlert = () => {
        setShowPopup({
            alert: null,
        });
    };
    
    const setFileData = (data) => {
        console.log("setFileData-----------",data);
        setFormDetails({
            ...formdetails,
            ...data,
        })
    }

    return <div className='content'>
        {showPopup.alert}
        <Container>
          <Row>
          <Col className="ml-auto mr-auto"  md="12">
              <Card className="card-signup text-center androidFormCard">
                <CardBody>
                  <Form className="form-horizontol" action="" id="appSetting" method="POST" onSubmit={handleSubmit}>
                    
                  <Row>
                    <Col>
                        <Col sm="12">
                            <div className="designLabel">
                                <small className="text-default">
                                    iOS Package Name<span class="text-danger"> * </span>
                                </small>
                            </div>
                            <Label className="smLabl-txt">Enter app package name (com.yourappname.app)</Label>
                        </Col>
                        <Col sm="12">
                            <div className="inptFom1">
                                <FormGroup className={fieldStates["error_ios_app_package_name"]}>
                            <Input
                                type="text"
                                name="ios_app_package_name"
                                id="ios_app_package_name"
                                value={formdetails.ios_app_package_name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {fieldStates["error_ios_app_package_name"] ===
                                "has-danger" ? (
                                    <label className="error">
                                        {
                                            fieldStates[
                                                "error_message_ios_app_package_name"
                                            ]
                                        }
                                    </label>
                                ) : null}
                            </FormGroup>
                            </div>
                        </Col>
                    </Col>
                    <Col>
                    {formdetails.ios_app_name ?
                        <Col>
                            <Col sm="12">
                                <div className="designLabel">
                                    <small className="text-default">
                                    iOS App Name 
                                    </small>
                                </div>
                            </Col>
                            <Col sm="12">
                                <div className="inptFom1">
                                    <FormGroup className={fieldStates["error_ios_app_name"]}>
                                        <Label >{formdetails.ios_app_name}</Label>
                                {/* <Input
                                    type="text"
                                    name="ios_app_name"
                                    id="ios_app_name"
                                    value={formdetails.ios_app_name}
                                    // onChange={handleChange}
                                    // onBlur={handleBlur}
                                    readonly
                                /> */}
                                {/* {fieldStates["error_ios_app_name"] ===
                                    "has-danger" ? (
                                        <label className="error">
                                            {
                                                fieldStates[
                                                    "error_message_ios_app_name"
                                                ]
                                            }
                                        </label>
                                    ) : null} */}
                                </FormGroup>
                                </div>
                            </Col>
                      </Col>
                      :""}
                    </Col>
                  </Row>
                  
                  <Row>
                    <Col>
                    <Col sm="12">
                        <div className="designLabel">
                            <small className="text-default">
                                 API key Id <span class="text-danger"> * </span>
                            </small>
                        </div>
                        <Label className="smLabl-txt">Enter appstore api key id</Label>
                    </Col>
                    <Col sm="12">
                        <div className="inptFom1">
                            <FormGroup className={fieldStates["error_app_store_api_key_id"]}>
                        <Input
                            type="textarea"
                            name="app_store_api_key_id"
                            id="app_store_api_key_id"
                            value={formdetails.app_store_api_key_id}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        {fieldStates["error_app_store_api_key_id"] ===
                            "has-danger" ? (
                                <label className="error">
                                    {
                                        fieldStates[
                                            "error_message_app_store_api_key_id"
                                        ]
                                    }
                                </label>
                            ) : null}
                        </FormGroup>
                        </div>
                    </Col>
                    </Col>
                    <Col>
                    <Col sm="12">
                        <div className="designLabel">
                            <small className="text-default">
                                 API key issuer Id <span class="text-danger"> * </span>
                            </small>
                        </div>
                        <Label className="smLabl-txt">Enter appstore api key issuer id</Label>
                    </Col>
                    <Col sm="12">
                        <div className="inptFom1">
                            <FormGroup className={fieldStates["error_app_store_api_key_issuer_id"]}>
                        <Input
                            type="textarea"
                            name="app_store_api_key_issuer_id"
                            id="app_store_api_key_issuer_id"
                            value={formdetails.app_store_api_key_issuer_id}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        {fieldStates["error_app_store_api_key_issuer_id"] ===
                            "has-danger" ? (
                                <label className="error">
                                    {
                                        fieldStates[
                                            "error_message_app_store_api_key_issuer_id"
                                        ]
                                    }
                                </label>
                            ) : null}
                        </FormGroup>
                        </div>
                    </Col>
                    </Col>
                    
                  </Row>
                  <Row>
                    <Col>
                    <Col sm="12">
                        <div className="designLabel">
                            <small className="text-default">
                                 API Auth File <span class="text-danger"> * </span>
                            </small>
                        </div>
                        <Label className="smLabl-txt">Enter appstore api auth key</Label>
                    </Col>
                    <Col sm="12">
                        <div className="inptFom1">
                            <FormGroup className={fieldStates["error_app_store_api_auth_key_url"]}>
                            <FileUpload
                                    projectId={projectId}
                                    id="app_store_api_auth_key_url"
                                    setFileName = {setFileName}
                                    value= {formdetails.app_store_api_auth_key_file_name}
                                    file_type="application/pkcs8,"
                                    handleEnumChange={handleEnumChange}
                                    setFileData={setFileData}
                                />
                        
                        {fieldStates["error_app_store_api_auth_key_url"] ===
                            "has-danger" ? (
                                <label className="error">
                                    {
                                        fieldStates[
                                            "error_message_app_store_api_auth_key_url"
                                        ]
                                    }
                                </label>
                            ) : null}
                        </FormGroup>
                        </div>
                    </Col>
                    </Col>
                    <Col>
                      <Col sm="12">
                        <div className="designLabel">
                            <small className="text-default">
                                iOS Team Id <span class="text-danger"> * </span>
                            </small>
                        </div>
                        <Label className="smLabl-txt">iOS team ID</Label>
                    </Col>
                      <Col sm="12">
                        <div className="inptFom1">
                            <FormGroup className={fieldStates["error_ios_team_id"]}>
                            <Input
                                type="text"
                                name="ios_team_id"
                                id="ios_team_id"
                                value={formdetails.ios_team_id}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {fieldStates["error_ios_team_id"] ===
                                "has-danger" ? (
                                    <label className="error">
                                        {
                                            fieldStates[
                                                "error_message_ios_team_id"
                                            ]
                                        }
                                    </label>
                                ) : null}
                            </FormGroup>
                        </div>
                    </Col>
                    
                    </Col>
                  </Row>
                   <Row>
                    <Col>
                        <Col sm="12">
                        <div className="designLabel">
                            <small className="text-default">
                                Launcher Icon(1024x1024)<span class="text-danger"> * </span>
                            </small>
                        </div>
                        <Label className="smLabl-txt">Upload launcher icon</Label>
                        <div className="inptFom1">
                            <FormGroup className={fieldStates["error_launcher_icon"]}>
                        <ImageUpload
                                projectId={projectId}
                                size="1024x1024"
                                id="launcher_icon"
                                value={formdetails.launcher_icon ||defaultImage}
                                file_type="image/jpg,image/png,image/jpeg"
                                handleEnumChange={handleEnumChange}
                            />
                            {fieldStates["error_launcher_icon"] ===
                            "has-danger" ? (
                                <label className="error">
                                    {
                                        fieldStates[
                                            "error_message_launcher_icon"
                                        ]
                                    }
                                </label>
                            ) : null}
                        
                        </FormGroup>
                        </div>
                    </Col>
                    </Col>
                    <Col>
                    <Col sm="12">
                        <div className="designLabel">
                            <small className="text-default">
                               App Logo (960x1600) <span class="text-danger"> * </span>
                            </small>
                        </div>
                        <Label className="smLabl-txt">Upload App Logo</Label>
                        <div className="inptFom1">
                            <FormGroup className={fieldStates["error_app_logo"]}>
                        <ImageUpload
                                projectId={projectId}
                                size="960x1600"
                                id="app_logo"
                                value={formdetails.app_logo || defaultImage}
                                file_type="image/jpg,image/png,image/jpeg"
                                handleEnumChange={handleEnumChange}
                            />
                         {fieldStates["error_app_logo"] ===
                            "has-danger" ? (
                                <label className="error">
                                    {
                                        fieldStates[
                                            "error_message_app_logo"
                                        ]
                                    }
                                </label>
                            ) : null}
                        </FormGroup>
                        </div>
                    </Col>
                    </Col>
                    
                  </Row>
                    {showSaveButton?<Button
                    className="btn-round btnBlue"
                    type="button"
                    onClick={handleSubmit}
                  >
                    Save
                  </Button>:''}
                  <Button
                    className="btn-round btnGry"
                    type="button"
                    onClick={()=>{props.hideAlert()}}
                  >
                    Cancel
                  </Button>
                  {alert.show?<Alert color={alert.type}>
                    {alert.message}
                  </Alert>:''}

                  

                  {showLoader?<Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>:''}
                    
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
    </div>
}

export default IOS;
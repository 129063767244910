import React, { useEffect, useState, useContext } from "react";
import {
    Card,
    CardTitle,
    CardHeader,
    CardBody,
    Row,
    Col,
    Button,
    Label,
    Input,
    Spinner,
    Alert,
} from "reactstrap";

import './ctv.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SortableTree from "react-sortable-tree";
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from "react-router-dom";
import ctvAppService from '../../services/api/ctv-app-service';
import ProjectContext from "../../ProjectContext";
import ReactBSAlert from 'react-bootstrap-sweetalert';

const ReorderCategories = (props) => {
    const projectDetails = useContext(ProjectContext);
    const history = useHistory();
    if (projectDetails.id == '') {
        history.push("/project/allprojects");
    }
    const projectId = projectDetails.id;

    const [treeData, setTreeData] = useState([]);
    const [categories,setCategories] = useState([]);
    const [showSavedButton, setShowSaveButton] = useState(true);
    const [showLoader, setShowLoader] = useState(false);
    const [alert,setAlert] = useState({show:false,type:"",msg:""})
    const [reloadComp, setReloadComp] = useState(0);
    const [showPopup, setShowPopup] = useState({alert:null})
    
    useEffect(()=>{
        (async()=>{
            const catList = await ctvAppService.getCategoryList({projectId});
            if(catList.status && catList.data){
                let categories = catList.data;
                setCategories(catList.data);
                setTreeData(catList.data)
                generateTreeData(categories);
            }
        })();
    },[reloadComp])

    const generateTreeData = (data) => {
        data.forEach(element => {
            element.title = <Row className="catTitle">
                <Col>
                {element.name}
                </Col>
            </Row>;

            if(element.children){
                generateTreeData(element.children);
            }
        })
        setTreeData(data);
    }

    const updateCategory = async(element) => {
        console.log("element: ",element);
        history.push('/ctv/edit-category?category='+element.cat_guid);
    }

    const deleteCategoryPopup = async(element) => {
        setShowPopup({
            alert:(
                <ReactBSAlert
                    warning
                    title="Are you sure?"
                    onConfirm={(event) => deleteCategory(element)}
                    onCancel={() => hideAlert()}
                    confirmBtnBsStyle="info"
                    cancelBtnBsStyle="danger"
                    confirmBtnText="Yes, delete it!"
                    cancelBtnText="Cancel"
                    showCancel
                >
                    You want to delete this category!
                </ReactBSAlert>
            )
        })
    }

    const deleteCategory = async(element) => {
        const delCategory = await ctvAppService.deleteCategory({project_id:projectId,category:[element]});
        if(delCategory.status && delCategory.data){
            setShowPopup({
                alert:(
                    <ReactBSAlert
                        success
                        title="Deleted!"
                        onConfirm={()=>hideAlert()}
                        onCancel={()=>hideAlert()}
                        confirmBtnBsStyle="info"
                    >
                        Category deleted successfully
                    </ReactBSAlert>
                )
            })
        }else{
            setShowPopup({
                alert: (
                    <ReactBSAlert
                        danger
                        title="Please try again!"
                        onConfirm={()=>hideAlert()}
                        onCancel={()=>hideAlert()}
                        confirmBtnBsStyle="info"
                    >
                        Category deleted successfully
                    </ReactBSAlert>
                )
            })
        }
        setReloadComp(Math.random());

    }

    const hideAlert = () => {
        setShowPopup({
            alert: null
        })
    }
    const updateTreeData = (treeData) => {
        console.log("updated treedata: ",treeData);

        updateParentId(treeData,null);
        setTreeData(treeData);
    }


    const updateParentId = async(treeData,parent_id) => {
        for(let i = 0; i<treeData.length; i++){
            if(treeData[i].parent_id !== parent_id){
                treeData[i].parent_id = parent_id;
                treeData[i].parent_updated = true;
            }
            if(treeData[i].children && treeData[i].children.length > 0){
                await updateParentId(treeData[i].children,treeData[i]._id)
            }
        }
    }

    const reorderCategories = async(event) => {
        setShowLoader(true);
        setShowSaveButton(false);
        const catReordered = await ctvAppService.reorderCategories({treeData,projectId});
        if(catReordered.status && catReordered.data){
            setTimeout(()=>{
                setShowLoader(false);
                setShowSaveButton(true);
                setAlert({show:true,type:"success",msg:"Categories order updated successfully"})
            },1000)
        }else{
            setTimeout(()=>{
                setShowLoader(false);
                setShowSaveButton(true);
                setAlert({show:true,type:"danger",msg:"Categories could not be reordered"});
            },1000)
        }

        setTimeout(()=>{
            setAlert({show:false,type:"",msg:""});
            setReloadComp(Math.random())
        },3000);
    }
    return (
        <div className="container mt-30">
            <Card>
                {showPopup.alert}
                <CardHeader>
                    <div className="ctvCatHeader">
                        <Row>
                            <Col className="text-center">
                                <label className="ctvCategories">Reorder Categories</label>
                            </Col>
                        </Row>
                        <hr/>
                    </div>
                </CardHeader>

                <CardBody>
                    <Row>
                        <Col md="12">
                            <SortableTree
                                isVirtualized={false}
                                maxDepth="6"
                                treeData={treeData}
                                onChange={(event) => updateTreeData(event)}
                            />
                        </Col>

                    </Row>
                    <Row>
                        <Col className="text-center">
                            <div>
                                {showSavedButton ? 
                                    <>
                                    <Button
                                        className="btn-round"
                                        color ="danger"
                                        type="button"
                                        onClick={(event)=>history.push(`/ctv/dashboard?project=${projectDetails.slug}#category`)}
                                    >
                                        Back
                                    </Button>
                                    <Button
                                        type="button"
                                        className="btn-round"
                                        color ="success"
                                        onClick={(event) => reorderCategories(event)}
                                    >
                                        Save
                                    </Button>
                                    
                                    </>
                                : 
                                    null
                                }
                                {
                                    showLoader ? 
                                        <Spinner animation = "border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </Spinner>
                                    : 
                                        null
                                }
                                {
                                    alert.show ? 
                                        <Alert color={alert.type}>
                                            {alert.msg}
                                        </Alert>
                                    : 
                                        null
                                }
                            </div>
                        </Col>

                    </Row>
                </CardBody>
                <CardTitle>

                </CardTitle>
            </Card>
        </div>
    )
}

export default ReorderCategories;
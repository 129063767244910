import axios from "axios";
const googleApiKey = "AIzaSyAhiazHyhYuK38K6cjFxKNcqGe_41-a9Mk" ; 
const fontsUrl = `https://www.googleapis.com/webfonts/v1/webfonts?key=${googleApiKey}`;

const getAllWebStories = async (formData)=>{
    const response = await axios.post('/api/webstory/get-webstories', formData);
    return response.data;
}

const getWebstoryById = async (payload)=>{
    const response = await axios.post('/api/webstory/get-webstory', payload);
    return response.data;
}

const getWebstoryPages = async (formData)=>{
    const response = await axios.post('/api/webstory/get-webstory-pages', formData);
    return response.data;
}

const getWebstoryPagesInDefaultFormat = async (formData)=>{
    const response = await axios.post('/api/webstory/get-webstory-pages-in-default-format', formData);
    return response.data;
}

const getWebstoryPageById = async (formData)=>{
    const response = await axios.get('/api/webstory/get-webstory-page', formData);
    return response.data;
}

const getStoryTemplates = async () => {
    const response = await axios.get('/api/webstory/story-templates');
    return response.data;
}

const createNewWebstory = async (payload) => {
    const response = await axios.post('/api/webstory/add-webstory',{...payload});
    return response.data;
}
/**
 * Used for create a single webstory page  
 * @param {MongoObjectId} article_id Id of the Webstory 
 * @param {Number} order The order of Webstory Page
 * @param {string} template_name Template Name of Webstory
 * @returns {string} Id of the Webstory Page
 */
const createWebstoryPage = async (payload) => {
    const response = await axios.post('/api/webstory/create-webstory-page',{...payload});
    return response.data;
}

const saveWebstoryPage = async (payload) => {
    const response = await axios.post('/api/webstory/save-webstory-page',{...payload});
    return response.data;
}

const deleteWebstoryPage = async (payload) => {
    const response = await axios.post('/api/webstory/delete-webstory-page',{...payload});
    return response.data;
}

const webstoryMediaUpload = async (payload) => {
    const response = await axios.post('/api/webstory/media-upload',{...payload});
    return response.data;
}

const deleteWebstoryArticle = async (webstoryId,projectId) => {
    const response = await axios.post('/api/webstory/delete-web-story',{webstoryId,projectId});
    return response.data;
}
const publishWebstory = async (payload) => {
    const response = await axios.post('/api/webstory/publish-webstory',{...payload});
    return response.data;
}
const unPublishWebstory = async (storyId,projectId) => {
    const response = await axios.post('/api/webstory/unpublish-webstory',{storyId,projectId});
    return response.data;
}
const updateWebstory = async (payload) => {
    const response = await axios.post('/api/webstory/update-webstory-category',{...payload});
    return response.data;
}

const uploadDetails = async (payload) => {
    const response = await axios.post('/api/webstory/upload-details',{...payload});
    return response.data;
}

const copyToProjectS3 = async (payload) => {
    const response = await axios.post('/api/webstory/copy-to-project-s3',{...payload});
    return response.data;
}

const getAppIcon = async (payload) => {
    const response = await axios.post('/api/webstory/get-app-icon',{...payload});
    return response.data;
}

const imageUrlUpload = async (payload) => {
    const response = await axios.post('/api/webstory/image-url-upload',{...payload});
    return response.data;
}

const changeTemplate = async (payload) => {
    const response = await axios.post('/api/webstory/change-template',{...payload});
    return response.data;
}

const updateShowAuthor = async (payload) => {
    const response = await axios.post('/api/webstory/show-author',{...payload});
    return response.data;
}

const getMediaLibrary = async (payload) => {
    const response = await axios.post('/api/webstory/get-media-library',{...payload});
    return response.data;
}

const getGoogleFonts = async () => {
    const response = await axios.get(fontsUrl);
    return response.data;
}

const getDefaultFont = async (payload) => {
    const response = await axios.post('/api/webstory/get-default-font',{...payload});
    return response.data;
}

const updateAllPagesForMeta = async (payload) => {
    const response = await axios.post('/api/webstory/update-all-page-meta',{...payload});
    return response.data;
}

const genResumableUrl = async(payload) => {
    const response = await axios.post('/api/webstory/gen-resumable-url',payload);
    return response.data;
}
export default {
    getAllWebStories,
    getWebstoryById,
    createNewWebstory,
    createWebstoryPage,
    getWebstoryPages,
    getWebstoryPagesInDefaultFormat,
    saveWebstoryPage,
    getWebstoryPageById,
    webstoryMediaUpload,
    deleteWebstoryArticle,
    publishWebstory,
    unPublishWebstory,
    updateWebstory,
    getStoryTemplates,
    deleteWebstoryPage,
    uploadDetails,
    copyToProjectS3, 
    getAppIcon,
    imageUrlUpload,
    changeTemplate,
    updateShowAuthor,
    getMediaLibrary,
    getGoogleFonts, 
    getDefaultFont,
    updateAllPagesForMeta,
    genResumableUrl,
}


import React from 'react';
import { useHistory } from "react-router";

import { 
    Button,
    Card,
    Container,
    CardBody,
    CardImg
} from 'reactstrap';

import errorImg from '../../../assets/img/404.png';

const PageNotFound = () => {

    const history = useHistory();

    return (
        <Container>

        <Card>
            <CardBody style={{textAlign:'center'}}>
                    <h2>Page Not Found</h2>

                    <div>
                        <CardImg
                            alt="Card image cap"
                            src={errorImg}
                            top
                            style={{ width:'50%'}}
                        />
                    </div>
                    <Button
                        className="btn-smb"
                        type="button"
                        onClick={()=>history.push("/contentsettings/webstory/list/published")}>Go Back To Webstories</Button>
                </CardBody>
            </Card>
        </Container>
    )
}

export default PageNotFound;
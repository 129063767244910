import React, { useState, useEffect } from "react";

import { 
    Button,
    Input,
    Label,
    FormGroup,
    FormText,
} from 'reactstrap';

import Select from "react-select";

import { embedTypes } from '../webstory-config';

const iframeCheck  = /iframe/;

const blockQuoteCheck  = /blockquote/;

const Embed = ({changeEmbed, currentPage}) => {

    const [showEmbed, setShowEmbed] = useState(false);
    const [embedType, setEmbedType] = useState({});
    const [error, setError] = useState('');
    
    useEffect(() => {
        console.log( 'useeffect embed type ',embedType)
        changeEmbed(embedType)
    },[embedType]);

    useEffect(() => {
        console.log(' --------- Inside Embed ---------')

        console.log('useeffecct embed current page',currentPage)
        let currentPageHasEmbed = '' ;
        // currentPageHasEmbed = (currentPage.iframe || currentPage.blockquote);
        currentPageHasEmbed = currentPage.blockquote;

        // if(currentPage.iframe && currentPage.iframe !== embedType.iframe) {
        //     setShowEmbed('')
        //     setEmbedType({iframe:currentPage.iframe});
        // }
        if(currentPage.blockquote && currentPage.blockquote !== embedType.blockquote && /class="twitter-tweet"/.test(currentPage.blockquote)) {
            setShowEmbed('')
            setEmbedType({blockquote:currentPage.blockquote});
        }
        console.log('currentPageHasEmbed',currentPageHasEmbed)
        if(!currentPageHasEmbed) {
            removeEmbed();
        }
        console.log(' --------- EXIT Embed ---------')

    },[currentPage]);   

    const removeEmbed = () => {
        setShowEmbed(false);
        setEmbedType({});
        setError('');
    }

    const handleIframeChange = (event) => {
        console.log( iframeCheck.test(event.target.value) || blockQuoteCheck.test(event.target.value) )

        if(event.target.value === '') {
            setShowEmbed('');
        } else {
            // if(iframeCheck.test(event.target.value)) {
            //     setError('');
            //     setEmbedType({iframe:event.target.value});
            // }
            // else
            if(blockQuoteCheck.test(event.target.value) && /class="twitter-tweet"/.test(event.target.value)) {
                setError('');
                setEmbedType({blockquote:event.target.value});
            }
             else {
                setEmbedType({});
                setError('Invalid Embed Code');
            }
            
        }
    }

    
    return (<div>
            {showEmbed !== false?  
             <Button color="danger"  
            onClick={removeEmbed}
            >Remove Embed</Button>
             :
             <Button color="success"  
            onClick={()=>{setShowEmbed('')}}
            >Add Embed</Button> }

            {showEmbed !== false ? 
            <FormGroup>
                    <Label>Enter Embed Code</Label>
                    <Input value={embedType?.iframe|| embedType?.blockquote}  type="textarea" onChange={handleIframeChange}/>
                    <p style={{color:'red'}}>{error}</p>
                    <FormText >
                        Twitter Widget is Supported.
                        Invalid Embed code will not be shown.
                    </FormText>
            </FormGroup>
            : ''}

        </div>
    )
}
  
export default Embed;


// return (<div>
//     {showEmbed?  
//      <Button color="danger"  
//     onClick={removeEmbed}
//     >Remove Embed</Button>
//      :
//      <Button color="success"  
//     onClick={()=>{setShowEmbed(true)}}
//     >Add Embed</Button> }

//     {showEmbed ? 
//             <FormGroup>
//                 {/* <Label >Please Select Embed Type </Label> */}

//                 <h6 style={{textAlign:"left"}}> Embed Type  <span style={{color:'red'}}>*</span>  </h6>
//                 <Select
//                     className="react-select primary"
//                     classNamePrefix="react-select"
//                     placeholder="Select Embed Type"
//                     options={embedTypes}
//                     value={Object.keys(embedType).map(e=> {return {label:e,value:e}})[0]}
//                     onChange={handleSelectChange }
//                 />
               
//             </FormGroup>
//     :null}

//     {embedType.tweetURL != undefined  ? 
//         <FormGroup>
//             <Label>Enter URL of Tweet</Label>
//             <Input value={embedType?.tweetURL} onChange={handleTweetChange} />
//         </FormGroup>
//     :null}

//     {embedType.instagramURL != undefined  ? 
//         <FormGroup>
//             <Label>Enter URL of Instagram</Label>
//             <Input onChange={handleInstagramChange} />
//         </FormGroup>
//     :null}

//      {embedType.iframe != undefined ? 
//         <FormGroup>
//             <Label>Enter Iframe Code</Label>
//             <Input value={embedType?.iframe}  type="textarea" onChange={handleIframeChange}/>
//         </FormGroup>
//     :null}

// </div>
// )
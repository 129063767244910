import axios from 'axios';

const getBillingDetails = async (projectId, subscriptionId) => {
    let formdata = {
        projectId,
        subscriptionId
    };
    const response = await axios.post('/api/subscription/get-billing-details', formdata);
    return response.data;
}

const isActiveSubscriptionHalted =  async (projectId) =>{
    const formdata={
        projectId 
    }
    const response=await axios.post('/api/subscription/is-halted',formdata);
    return response.data;
}




export default {
    getBillingDetails,
    isActiveSubscriptionHalted
}
import React, { useState, useEffect, useContext } from "react";
import "./VideoUploadForm.css";

import { CopyToClipboard } from "react-copy-to-clipboard";
import Pagination from "react-js-pagination";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faTrash,
    faEdit,
    faCode,
    faChartLine,
    faHandScissors,
} from "@fortawesome/free-solid-svg-icons";
//const momemt  = require('moment')

import {
    Col,
    Row,
    Spinner,
    Button,
    Modal,
    ModalBody,
    Table,
    Alert,
    DropdownMenu,
    DropdownItem,
    ButtonDropdown,
    DropdownToggle,
    Input,
} from "reactstrap";

import allListingService from "../../services/api/Jwplayer/allListing-service";

import moment from "moment";
import { Link, useLocation } from "react-router-dom/cjs/react-router-dom";
import SlidingChapterScreen from "./SlidingChapterScreen";
import TrimModal from "./TrimModal";
import ProjectContext from "../../ProjectContext";

const AWS = require("aws-sdk");
const VideoUploadForm = ({
    siteId,
    projectId,
    videoUrl,
    bucketName,
    identityPoolId,
    region,
    gcpRegion,
    gcpVideoUrl,
    parallelSyncingAllowed,
}) => {
    const location=useLocation();
    const [isSlidingScreenVisible, setSlidingScreenVisible] = useState({
        value: false,
        mediaId: null,
        duration: null,
    });

    const [ans, setAns] = useState();
    const recommendationText = "Suggestion: Add 3+ words";
    const [isRecommendationTextVisible, setisRecommendationTextVisible] =
        useState({
            title: false,
            description: false,
            authorname: false,
            tags: false,
        });
    const [uploadVideoLoader, setUploadVideoLoader] = useState(false);
    const [ansTrue, setAnsTrue] = useState(true);
    const [height, setHeight] = useState(0);
    const [textCopied1, setTextCopied1] = useState(false);
    const [textCopied2, setTextCopied2] = useState(false);
    const [textCopied3, setTextCopied3] = useState(false);
    const [alert, setAlert] = useState({ show: false, message: "", type: "" });
    const [previewVideo, setPreviewVideo] = useState(<div></div>);
    const [dropdownOpen, setOpen] = React.useState(false);
    const [dropdownOpen1, setOpen1] = React.useState(false);
    const [deteleModel, setDeleteModel] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalEditOpen, setModalEditOpen] = useState(false);
    const [validImage, setValidImage] = useState(false);
    const [validVideo, setValidVideo] = useState(false);
    const [currentMediaId, setCurrentMediaId] = useState("");
    const [playerType1, setPlayerType1] = useState("");
    const [playerType2, setPlayerType2] = useState("");
    const [mediadata, setMediaData] = useState([]);
    const [playerType3, setPlayerType3] = useState("");
    const [embelModel, setEmbedModal] = useState(false);
    const [alertModel, setAlertModal] = useState(false);
    const [previewModel1, setPreviewModal1] = useState(false);
    const [randomNumber1, setRandomNumber1] = useState(0);
    const [randomNumber2, setRandomNumber2] = useState(0);
    const [dropDownText, setDropDownText] = useState("Search By");
    const [dropDownSearchText, setDropDownsearchText] = useState("Status");
    const [filterText, setFilterText] = useState("");
    const [firstPage, setFirstPage] = useState(0);
    const [lastPage, setLastPage] = useState(0);
    const [activePage, setActivePage] = useState(1);
    const [reloadPage, setReloadPage] = useState(false);
    const [totalArticles, setTotalArticles] = useState(0);
    const [showLoader, setShowLoader] = useState(false);
    const [imageFetched, setImageFetched] = useState(true);
    const [mediaEdited, setMediaEdited] = useState(true);
    const [formData, setFormData] = useState({
        title: "",
        description: "",
        authorname: "",
        tags: [],
        video: null,
        id: "",
    });
    let firstData = 0;
    let lastData = 0;
    let records = 10;

    const [trimMediaModal, setTrimMediaModal] = useState(false);
    const [selectedMedia, setSelectedMedia] = useState({});
    const projectContextDetails = useContext(ProjectContext);

    const handleChange = (event) => {
        const { name, value } = event.target;
        if (value.split(/\s+/).length < 3) {
            setisRecommendationTextVisible((prevValue) => ({
                ...prevValue,
                [name]: true,
            }));
        } else {
            setisRecommendationTextVisible((prevValue) => ({
                ...prevValue,
                [name]: false,
            }));
        }
        console.log(isRecommendationTextVisible);
        setFormData({
            ...formData,
            [name]: value.trimStart(),
        });
    };

    const handleTagsChange = (event) => {
        const { value } = event.target;
        setFormData({
            ...formData,
            tags: value.split(",").map((tag) => tag.trimStart()),
        });
        if (formData.tags.length <= 2) {
            setisRecommendationTextVisible((prevValue) => ({
                ...prevValue,
                tags: true,
            }));
        } else {
            setisRecommendationTextVisible((prevValue) => ({
                ...prevValue,
                tags: false,
            }));
        }
    };

    const handleVideoChange = (event) => {
        const file = event.target.files[0];
        setFormData({
            ...formData,
            video: file,
        });
    };

    const generateAWSSignedUrl = async(s3BucketName,uploadKey) => {
        try{
            AWS.config.update({ region: region });
            const identityPoolIds = identityPoolId;
            const credentials = new AWS.CognitoIdentityCredentials({
                IdentityPoolId: identityPoolIds,
            });
            AWS.config.credentials = credentials;


            const s3 = new AWS.S3();
            const params = {
                Bucket: s3BucketName,
                Key: uploadKey,
                Expires: 3600,
            };

            return await s3.getSignedUrlPromise("putObject", params);
        }
        catch(error){
            console.log(error);
            return false;
        }
    }

    const uploadVideoToSIgnedUrl = async(url,file,uploadKey) => {
        try{
            return await fetch(url, {
                method: "PUT",
                body: file,
                Key: uploadKey,
            });
        }
        catch(error){
            console.log(error);
            return false;
        }
    }
    const handleSubmit = async (event) => {
        try {
            event.preventDefault();
            const splitText = formData.video.name.split(".");
            const length = splitText.length;
            let videoExtention = splitText[length - 1].trim();

            if (
                videoExtention === "mp4" ||
                videoExtention === "webm" ||
                videoExtention === "MP4" ||
                videoExtention === "mpeg"
            ) {
                setMediaEdited(false);
                setUploadVideoLoader(true);
                setValidVideo(false);

                const file = formData.video;

                const s3BucketName = bucketName;
                const folderName = "videos";
                const uploadKey = `${folderName}/${siteId}/${file.name}`;
                let url = "";

                let uploaded ;
                if(projectContextDetails.cloudProvider === "aws" || parallelSyncingAllowed){
                    url = await generateAWSSignedUrl(s3BucketName,uploadKey);
                    uploaded = await uploadVideoToSIgnedUrl(url,file,uploadKey);

                    console.log("response of aws put video: ",uploaded);

                }

                if(projectContextDetails.cloudProvider === "gcp" || parallelSyncingAllowed){
                    const response = await allListingService.generateSignedUrl({bucketName,objectKey:uploadKey})
                    url = response.data;
                    uploaded = await uploadVideoToSIgnedUrl(url,file,uploadKey);
                
                    console.log("response of gcs put video: ",uploaded);
                    
                    videoUrl = gcpVideoUrl;
                }

                const customUrl = `${videoUrl}${folderName}/${siteId}/${file.name}`;

                const videoMetadata = {
                    projectId: projectId,
                    title: formData.title,
                    description: formData.description,
                    tags: formData.tags,
                    authors: formData.authorname,
                    videoUrl: customUrl,
                };

                console.log("response of video meta data: ",videoMetadata);

                const videoUploadStatus =
                    await allListingService.saveJwplayerVideoData(
                        videoMetadata
                    );


                console.log("response of videoUploadStatus: ",videoUploadStatus);
                setTimeout(() => {
                    setAlert({
                        show: true,
                        type: "success",
                        message:
                            "Media Uploaded Successfully. It may take up to 2-3 minutes to get reflected on dashboard.",
                    });
                    setAlertModal(true);
                    setMediaEdited(true);
                }, 1000);
                setTimeout(() => {
                    setAlertModal(false);
                    setShowLoader(true);
                    setMediaData([]);
                    fetchData(activePage);
                }, 6000);
                setModalOpen(false);
                setUploadVideoLoader(false);
                setMediaEdited(true);
            } else {
                setValidVideo(true);
                setMediaEdited(true);

                return;
            }
        } catch (error) {
            console.error("Error uploading video:", error);
        }
    };
    const toggleModal = () => {
        setFormData({
            ...formData,
            title: "",
            authorname: "",
            description: "",
            tags: [],
            id: "",
        });
        setisRecommendationTextVisible({
            title: false,
            description: false,
            authorname: false,
            tags: false,
        });
        setModalOpen(!modalOpen);
    };

    const toggleTrimModal = (e) => {
        setSelectedMedia(e);
        // setCurrentMediaId(mediaId);
        setTrimMediaModal(true);
    };
    const toggleEditModal = (mediaId) => {
        setCurrentMediaId(mediaId);
        if (modalEditOpen === false) {
            getSingleMediaInfo(mediaId);
        }

        setisRecommendationTextVisible({
            title: false,
            description: false,
            authorname: false,
            tags: false,
        });

        setTimeout(() => {
            setModalEditOpen(!modalEditOpen);
        }, 500);
    };
    const getSingleMediaInfo = async (mediaId) => {
        const {
            data: {
                data: {
                    data: { metadata },
                },
            },
        } = await allListingService.getMetadata(mediaId, projectId);
        console.log(metadata);
        setFormData({
            ...formData,
            title: metadata.title,
            description: metadata.description,
            tags: metadata.tags,
            authorname: metadata.author,
            id: mediaId,
        });
    };
    const embedToggalModel = (mediaId) => {
        const val1 = `<div style="position:relative;overflow:hidden;padding-bottom:177.78%"><iframe src="https://cdn.jwplayer.com/players/${mediaId}-4QAZv0KO.html" width="100%" height="100%" frameborder="0" scrolling="auto" title="Black Turmeric Reel" style="position:absolute;" allowfullscreen></iframe></div>`;
        const val2 = `<iframe src="https://cdn.jwplayer.com/players/${mediaId}-N1kHxxk9.html" width="640" height="360" frameborder="0" scrolling="auto" title="Black Turmeric Reel" allowfullscreen></iframe>`;
        const val3 = `<div style="position:relative;overflow:hidden;padding-bottom:56.25%"><iframe src="https://cdn.jwplayer.com/players/${mediaId}-7iV6s7Lb.html" width="100%" height="100%" frameborder="0" scrolling="auto" title="Black Turmeric Reel" style="position:absolute;" allowfullscreen></iframe></div>`;

        setPlayerType1(val1);
        setPlayerType2(val2);
        setPlayerType3(val3);

        setCurrentMediaId(mediaId);
        setEmbedModal(!embelModel);
    };

    const deleteToggle = (modelId) => {
        let x = Math.floor(Math.random() * 10 + 1);
        let y = Math.floor(Math.random() * 10 + 1);
        setRandomNumber1(x);
        setRandomNumber2(y);
        setCurrentMediaId(modelId);
        setDeleteModel(!deteleModel);
    };

    const fetchData = async (page) => {
        setMediaData([]);
        const { data } = await allListingService.allListingVideo(
            page,
            projectId
        );
     
        
        const mediaData = data.data.data.media;
      

        mediaData.sort(function(a, b) {
            let dateA = new Date(a.last_modified);
           
            let dateB = new Date(b.last_modified);
            
            return dateB - dateA;
          });

        setMediaData(mediaData);
        setTotalArticles(data.data.data.total);

        firstData = records * (activePage - 1) + 1;
        lastData = records * (activePage - 1) + mediaData.length;
        setFirstPage(firstData);
        setLastPage(lastData);
        setShowLoader(false);

        if (mediaData[0].status === "processing") {
            var interval = setInterval(async () => {
                const { data } = await allListingService.allListingVideo(
                    page,
                    projectId
                );
                const mediaData1 = data.data.data.media;
                if (mediaData1[0].status === "ready") {
                    setMediaData(mediaData1);

                    clearInterval(interval);
                }
            }, 10000);
        }
    };

    useEffect(() => {
        if(location.chapterData){
            setSlidingScreenVisible({
                value:location.chapterData.state,
                mediaId:location.chapterData.mediaId,
                duration:location.chapterData.duration
            })
        location.chapterData=null;
        }
        setShowLoader(true);
        setMediaData([]);

        fetchData(activePage);
    }, [activePage, reloadPage]);

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
    };

    const handleCopyClick1 = () => {
        setTextCopied1(true);
        setTimeout(() => {
            setTextCopied1(false);
        }, 3000);
    };
    const handleCopyClick2 = () => {
        setTextCopied2(true);
        setTimeout(() => {
            setTextCopied2(false);
        }, 3000);
    };
    const handleCopyClick3 = () => {
        setTextCopied3(true);
        setTimeout(() => {
            setTextCopied3(false);
        }, 3000);
    };

    const handleItemDelete = async () => {
        if (Number(randomNumber1 + randomNumber2) == ans) {
            deleteToggle(currentMediaId);
            const response = await allListingService.deleteSelectedMedia(
                currentMediaId,
                projectId
            );

            if (response.status) {
                setTimeout(() => {
                    setAlert({
                        show: true,
                        type: "success",
                        message: "Media Deleted",
                    });
                    setAns();
                    setAlertModal(true);
                }, 1000);
            }

            setTimeout(() => {
                //setAlert({ show: false, type: "", message: "" });
                setAlertModal(false);
                setShowLoader(true);
                setMediaData([]);

                if (firstPage === totalArticles) {
                    setActivePage(1);
                    fetchData(1);
                    return;
                } else {
                    fetchData(activePage);
                }
            }, 3000);
        } else {
            setAnsTrue(false);

            setTimeout(() => {
                setAnsTrue(true);
            }, 2000);
        }

        // setMediaData([]);
    };
    const onImageError = (event) => {
        // event.target.src = "https://upload.wikimedia. setHtml()org/wikipedia/commons/thumb/a/a7/React-icon.svg/1200px-React-icon.svg.png";
        event.target.src =
            "https://assets.entrepreneur.com/content/3x2/2000/20170720143824-image-search-phone.jpeg";
    };
    const handleSearchDropdown = (filter) => {
        setDropDownText(filter);
        setOpen(false);
        if (filter == "Search By") {
            setFilterText("");
        }
    };

    const handleFliterSearch = async () => {
        setActivePage(1);

        if (
            dropDownText === "Title" &&
            (dropDownSearchText === "all" || dropDownSearchText === "Status") &&
            filterText !== ""
        ) {
            setShowLoader(true);
            const { data } = await allListingService.searchByTitle(
                filterText,
                projectId
            );
            const mediaData = data.data.data.media;
            firstData = records * (activePage - 1) + 1;
            lastData = records * (activePage - 1) + mediaData.length;
            setFirstPage(firstData);
            setLastPage(lastData);
            setMediaData(mediaData);
            setTotalArticles(data.data.data.total);
            setShowLoader(false);
        } else if (dropDownText === "Media" && filterText !== "") {
            setShowLoader(true);
            const { data } = await allListingService.searchByMedia(
                filterText,
                projectId
            );
            const mediaData = data.data.data.media;
            setMediaData(mediaData);
            firstData = records * (activePage - 1) + 1;
            lastData = records * (activePage - 1) + mediaData.length;
            setFirstPage(firstData);
            setLastPage(lastData);
            setTotalArticles(data.data.data.total);
            setShowLoader(false);
        } else if (dropDownSearchText === "all") {
            setHeight(window.innerHeight);
            setShowLoader(true);
            setMediaData([]);
            fetchData(activePage);
            return;
        } else if (
            dropDownText === "Title" &&
            dropDownSearchText !== "all" &&
            dropDownSearchText !== "Status" &&
            filterText !== ""
        ) {
            setShowLoader(true);
            const { data } = await allListingService.titleStatusFilter(
                filterText,
                dropDownSearchText,
                projectId
            );
            const mediaData = data.data.data.media;
            setMediaData(mediaData);
            firstData = records * (activePage - 1) + 1;
            lastData = records * (activePage - 1) + mediaData.length;
            setFirstPage(firstData);
            setLastPage(lastData);
            setTotalArticles(data.data.data.total);
            setShowLoader(false);
        } else if (dropDownSearchText !== "Status") {
            setShowLoader(true);
            const { data } = await allListingService.onlyStatusFilter(
                dropDownSearchText,
                projectId
            );

            const mediaData = data.data.data.media;
            setMediaData(mediaData);
            firstData = records * (activePage - 1) + 1;
            lastData = records * (activePage - 1) + mediaData.length;
            setFirstPage(firstData);
            setLastPage(lastData);
            setTotalArticles(data.data.data.total);
            setShowLoader(false);
        }
    };
    const handleFilterChange = (e) => {
        setFilterText(e.target.value.trimStart());
    };

    const handleSearchByStatusDropdown = (selectedText) => {
        setDropDownsearchText(selectedText);
    };

    const handleFilterReset = () => {
        setHeight(window.innerHeight);
        setShowLoader(true);
        setDropDownsearchText("Status");
        setDropDownText("Search By");
        setFilterText("");
        setMediaData([]);

        fetchData(activePage);
    };
    const convertSecondsToHMS = (seconds) => {
        seconds=seconds<0?0:seconds;
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = Math.floor(seconds % 60);

        const formattedHours = hours.toString().padStart(2, "0");
        const formattedMinutes = minutes.toString().padStart(2, "0");
        const formattedSeconds = remainingSeconds.toString().padStart(2, "0");

        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    };

    const handleUpdateSubmit = async (event) => {
        event.preventDefault();
        setMediaEdited(false);
        const file = formData.video;
        console.log("starting update submit");
        let thumbNailId = "null";

        if (file === null) {
            console.log("here on edit image on null");
            setUploadVideoLoader(true);
            setValidImage(false);

            const updatedThumbnailStatus =
                await allListingService.updatedMetaDataOnly(
                    formData,
                    projectId,

                    currentMediaId
                );
            setTimeout(() => {
                setAlert({
                    show: true,
                    type: "success",
                    message: "Updated Successfully",
                });
                setAlertModal(true);
                setMediaEdited(true);
            }, 2000);

            setTimeout(() => {
                setAlertModal(false);
                setAlert({ show: false, type: "", message: "" });
                setShowLoader(true);
                setMediaData([]);
                fetchData(activePage);
            }, 4000);
            setModalEditOpen(false);
            setUploadVideoLoader(false);
        } else {
            console.log("here on edit image");
            const splitText = formData.video.name.split(".");
            const length = splitText.length;
            let imageExtention = splitText[length - 1].trim();
            console.log("image extention is :", imageExtention);
            if (
                imageExtention === "jpg" ||
                imageExtention === "png" ||
                imageExtention === "jpeg" ||
                imageExtention === "webp"
            ) {
                console.log("here if image found valid");
                const imageName = file.name
                    .split(" ")
                    .map((part) => encodeURIComponent(part))
                    .join("%20");
                setUploadVideoLoader(true);
                setValidImage(false);
                const s3BucketName = bucketName;
                <label className="form-label">
                    Title <span style={{ color: "red" }}>*</span>
                    <input
                        type="text"
                        name="title"
                        value={formData.title}
                        onChange={handleChange}
                        className="form-input"
                        required
                    />
                </label>;
                const folderName = "images";

                AWS.config.update({ region: region });
                const identityPoolIds = identityPoolId;
                const credentials = new AWS.CognitoIdentityCredentials({
                    IdentityPoolId: identityPoolIds,
                });
                AWS.config.credentials = credentials;

                const uploadKey = `${folderName}/${siteId}/${file.name}`;

                const s3 = new AWS.S3();

                const params = {
                    Bucket: s3BucketName,
                    Key: uploadKey,
                    Expires: 3600,
                };

                const url = await s3.getSignedUrlPromise("putObject", params);

                const response = await fetch(url, {
                    method: "PUT",
                    body: file,
                    Key: uploadKey,
                });

                const customUrl = `https://vplayer.sortd.dev/${folderName}/${siteId}/${imageName}`;

                const thumbnail_id = await allListingService.updateData(
                    formData,
                    projectId,
                    currentMediaId,
                    customUrl
                );

                if (thumbnail_id) {
                    thumbNailId = thumbnail_id.data.data.data.id;
                    setTimeout(async () => {
                        const updatedThumbnailStatus =
                            await allListingService.updatedThumbnail(
                                formData,
                                projectId,
                                thumbNailId,
                                currentMediaId
                            );

                        setTimeout(() => {
                            setAlert({
                                show: true,
                                type: "success",
                                message:
                                    "Media Updated Successfully. It may take up to 2-3 minutes to get reflected on dashboard",
                            });
                            setAlertModal(true);
                            setMediaEdited(true);
                        }, 2000);
                        setTimeout(() => {
                            setAlertModal(false);
                            setAlert({ show: false, type: "", message: "" });
                            setShowLoader(true);
                            setMediaData([]);
                            fetchData(activePage);
                        }, 10000);
                        setModalEditOpen(false);
                        setUploadVideoLoader(false);
                    }, 15000);
                }
            }
        }
    };
    const handleContainerClick = () => {};
    return (
        <>
            <div className="content be-jwCont" onClick={handleContainerClick}>
                <div className="container">
                    <Row>
                        <Col md="12">
                            <div className="card">
                                <div className="card-body">
                                    <ButtonDropdown
                                        toggle={() => {
                                            setOpen(!dropdownOpen);
                                        }}
                                        isOpen={dropdownOpen}
                                    >
                                        <DropdownToggle
                                            className="bg-lgry "
                                            caret
                                        >
                                            {dropDownText}
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem
                                                onClick={() => {
                                                    handleSearchDropdown(
                                                        "Search By"
                                                    );
                                                }}
                                            >
                                                Search By
                                            </DropdownItem>
                                            <DropdownItem
                                                onClick={() => {
                                                    handleSearchDropdown(
                                                        "Title"
                                                    );
                                                }}
                                            >
                                                Title
                                            </DropdownItem>
                                            <DropdownItem
                                                onClick={() => {
                                                    handleSearchDropdown(
                                                        "Media"
                                                    );
                                                }}
                                            >
                                                Media
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </ButtonDropdown>
                                    <ButtonDropdown
                                        toggle={() => {
                                            setOpen1(!dropdownOpen1);
                                        }}
                                        isOpen={dropdownOpen1}
                                    >
                                        <DropdownToggle
                                            className="bg-lgry"
                                            caret
                                        >
                                            {dropDownSearchText}
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem
                                                onClick={() => {
                                                    handleSearchByStatusDropdown(
                                                        "all"
                                                    );
                                                }}
                                            >
                                                All
                                            </DropdownItem>
                                            <DropdownItem
                                                onClick={() => {
                                                    handleSearchByStatusDropdown(
                                                        "ready"
                                                    );
                                                }}
                                            >
                                                Ready
                                            </DropdownItem>
                                            <DropdownItem
                                                onClick={() => {
                                                    handleSearchByStatusDropdown(
                                                        "processing"
                                                    );
                                                }}
                                            >
                                                Processing
                                            </DropdownItem>
                                            <DropdownItem
                                                onClick={() => {
                                                    handleSearchByStatusDropdown(
                                                        "updating"
                                                    );
                                                }}
                                            >
                                                Updating
                                            </DropdownItem>
                                            <DropdownItem
                                                onClick={() => {
                                                    handleSearchByStatusDropdown(
                                                        "failed"
                                                    );
                                                }}
                                            >
                                                Failed
                                            </DropdownItem>
                                            <DropdownItem
                                                onClick={() => {
                                                    handleSearchByStatusDropdown(
                                                        "expired"
                                                    );
                                                }}
                                            >
                                                Expired
                                            </DropdownItem>
                                            <DropdownItem
                                                onClick={() => {
                                                    handleSearchByStatusDropdown(
                                                        "scheduled"
                                                    );
                                                }}
                                            >
                                                Scheduled
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </ButtonDropdown>
                                    <input
                                        disabled={dropDownText === "Search By"}
                                        //style={{ display: "inliine", width: "40%", margin: "1px 5px 0px" }}
                                        type="text"
                                        className="srchTitl"
                                        name="filter"
                                        value={filterText}
                                        onChange={handleFilterChange}
                                        placeholder={
                                            dropDownText == "Search By"
                                                ? "Please Select filter from dropdown"
                                                : "Enter" +
                                                  " " +
                                                  dropDownText +
                                                  " and hit Go Button"
                                        }
                                    />

                                    <Button
                                        // disabled={
                                        //   dropDownSearchText === "Status" && dropDownText === "Search By"
                                        // }
                                        type="button"
                                        className={
                                            dropDownSearchText === "Status" &&
                                            dropDownText === "Search By"
                                                ? "btn-smb-grn btn "
                                                : "btn-smb-grn btn-success "
                                        }
                                        onClick={handleFliterSearch}
                                    >
                                        Go
                                    </Button>
                                    <Button
                                        type="button"
                                        className="btn btn-rst-gry "
                                        onClick={handleFilterReset}
                                    >
                                        Reset
                                    </Button>

                                    <Button
                                        type="button"
                                        className="btn-smb-ad friht "
                                        color="info"
                                        onClick={toggleModal}
                                    >
                                        Add Video
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Modal
                        isOpen={modalEditOpen}
                        // toggle={mediaEdited ? toggleEditModal : ""}
                    >
                        <ModalBody>
                            <div className=" upLVid">
                                <h4>Update Video Details</h4>
                                <div
                                    className="cancil"
                                    onClick={mediaEdited ? toggleEditModal : ""}
                                >
                                    x
                                </div>
                                <form onSubmit={handleUpdateSubmit}>
                                    <label className="form-label">
                                        Title{" "}
                                        <span style={{ color: "red" }}>*</span>
                                        <input
                                            type="text"
                                            name="title"
                                            value={formData.title}
                                            onChange={handleChange}
                                            className="form-input"
                                            required
                                        />
                                        <div className="below-input-text">
                                            <p style={{ color: "grey" }}>
                                                Max 5000 characters
                                            </p>
                                            {isRecommendationTextVisible.title ? (
                                                <p style={{ color: "red" }}>
                                                    {recommendationText}
                                                </p>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </label>
                                    <label className="form-label">
                                        Description
                                        <textarea
                                            name="description"
                                            value={formData.description}
                                            onChange={handleChange}
                                            className="form-textarea"
                                        />
                                        <div className="below-input-text">
                                            <p style={{ color: "grey" }}>
                                                Max 25000 characters
                                            </p>
                                            {isRecommendationTextVisible.description ? (
                                                <p style={{ color: "red" }}>
                                                    {recommendationText}
                                                </p>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </label>
                                    <label className="form-label">
                                        Author Name
                                        <input
                                            type="text"
                                            name="authorname"
                                            value={formData.authorname}
                                            onChange={handleChange}
                                            className="form-input"
                                        />
                                        <div className="below-input-text">
                                            <p style={{ color: "grey" }}>
                                                Max 256 characters
                                            </p>
                                            {isRecommendationTextVisible.authorname ? (
                                                <p style={{ color: "red" }}>
                                                    {recommendationText}
                                                </p>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </label>
                                    <label className="form-label">
                                        Tags (comma-separated)
                                        <input
                                            type="text"
                                            name="tags"
                                            value={formData.tags.join(", ")}
                                            onChange={handleTagsChange}
                                            className="form-input"
                                        />
                                        <div className="below-input-text">
                                            <p style={{ color: "grey" }}></p>
                                            {isRecommendationTextVisible.tags ? (
                                                <p style={{ color: "red" }}>
                                                    {recommendationText}
                                                </p>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </label>
                                    <label className="form-label">
                                        Thumb Image
                                        <input
                                            disabled={!mediaEdited}
                                            type="file"
                                            name="video"
                                            accept="image/*"
                                            onChange={handleVideoChange}
                                            className="form-input"
                                        />
                                        {validImage && (
                                            <p style={{ color: "red" }}>
                                                Only images allowed
                                            </p>
                                        )}
                                    </label>
                                    <label className="form-label">
                                        Chapters
                                        <div className="manage-chapter-btn-container">
                                            <button
                                                className="manage-chapter-btn"
                                                onClick={(e) => {
                                                    e.preventDefault();

                                                    console.log(formData);
                                                    setSlidingScreenVisible({
                                                        value: true,
                                                        mediaId: formData.id,
                                                        duration:
                                                            formData.duration,
                                                    });
                                                    setModalEditOpen(
                                                        !modalEditOpen
                                                    );
                                                }}
                                            >
                                                Manage
                                            </button>
                                            <p>
                                                Markers to segment content and
                                                convey viewing milestones
                                            </p>
                                        </div>
                                    </label>
                                    {uploadVideoLoader ? (
                                        <center>
                                            <Spinner></Spinner>
                                            <h6
                                                style={{
                                                    display: "inline-block",
                                                    marginTop: "0px",
                                                }}
                                            >
                                                Updating, Please Wait...
                                            </h6>
                                        </center>
                                    ) : (
                                        <button
                                            type="submit"
                                            className="btn-smb btn form-button"
                                        >
                                            Submit
                                        </button>
                                    )}
                                </form>
                            </div>
                        </ModalBody>
                    </Modal>

                    <Modal
                        isOpen={modalOpen}
                        // toggle={mediaEdited ? toggleModal : ""}
                    >
                        <ModalBody>
                            <div className=" upLVid">
                                <h4>Upload Video </h4>
                                <div
                                    className="cancil"
                                    onClick={mediaEdited ? toggleModal : ""}
                                >
                                    x
                                </div>

                                <form onSubmit={handleSubmit}>
                                    <label className="form-label">
                                        Title{" "}
                                        <span style={{ color: "red" }}>*</span>
                                        <input
                                            type="text"
                                            name="title"
                                            value={formData.title}
                                            onChange={handleChange}
                                            className="form-input"
                                            required
                                        />
                                        <div className="below-input-text">
                                            <p style={{ color: "grey" }}>
                                                Max 5000 characters
                                            </p>
                                            {isRecommendationTextVisible.title ? (
                                                <p style={{ color: "red" }}>
                                                    {recommendationText}
                                                </p>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </label>
                                    <label className="form-label">
                                        Description
                                        <textarea
                                            name="description"
                                            value={formData.description}
                                            onChange={handleChange}
                                            className="form-textarea"
                                        />
                                        <div className="below-input-text">
                                            <p style={{ color: "grey" }}>
                                                Max 25000 characters
                                            </p>
                                            {isRecommendationTextVisible.description ? (
                                                <p style={{ color: "red" }}>
                                                    {recommendationText}
                                                </p>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </label>
                                    <label className="form-label">
                                        Author Name
                                        <input
                                            type="text"
                                            name="authorname"
                                            value={formData.authorname}
                                            onChange={handleChange}
                                            className="form-input"
                                        />
                                        <div className="below-input-text">
                                            <p style={{ color: "grey" }}>
                                                Max 256 characters
                                            </p>
                                            {isRecommendationTextVisible.authorname ? (
                                                <p style={{ color: "red" }}>
                                                    {recommendationText}
                                                </p>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </label>
                                    <label className="form-label">
                                        Tags (comma-separated)
                                        <input
                                            type="text"
                                            name="tags"
                                            value={formData.tags.join(", ")}
                                            onChange={handleTagsChange}
                                            className="form-input"
                                        />
                                        <div className="below-input-text">
                                            <p style={{ color: "grey" }}></p>
                                            {isRecommendationTextVisible.tags ? (
                                                <p style={{ color: "red" }}>
                                                    {recommendationText}
                                                </p>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </label>
                                    <label className="form-label">
                                        Video (mp4/webm/mpeg){" "}
                                        <span style={{ color: "red" }}>*</span>
                                        <input
                                            type="file"
                                            name="video"
                                            accept="video/*"
                                            onChange={handleVideoChange}
                                            className="form-input"
                                            required
                                        />
                                        {validVideo && (
                                            <p style={{ color: "red" }}>
                                                Invalid File
                                            </p>
                                        )}
                                    </label>
                                    {uploadVideoLoader ? (
                                        <center>
                                            <Spinner></Spinner>
                                            <h6
                                                style={{
                                                    display: "inline-block",
                                                    marginTop: "0px",
                                                }}
                                            >
                                                Uploading, Please Wait...
                                            </h6>
                                        </center>
                                    ) : (
                                        <button
                                            type="submit"
                                            className="btn-smb btn form-button"
                                        >
                                            Submit
                                        </button>
                                    )}
                                </form>
                            </div>
                        </ModalBody>
                    </Modal>
                    <Modal
                        isOpen={deteleModel}
                        toggle={() => {
                            deleteToggle(currentMediaId);
                        }}
                    >
                        <ModalBody>
                            <div className=" upLVid del-Crd">
                                <h4>Upload Video </h4>
                                <div
                                    className="cancil"
                                    onClick={mediaEdited ? toggleModal : ""}
                                >
                                    x
                                </div>
                                <h6>
                                    To delete, please solve the following :{" "}
                                    {randomNumber1 + " + " + randomNumber2}?
                                </h6>

                                <Input
                                    value={ans}
                                    onChange={(e) => setAns(e.target.value)}
                                    type="text"
                                    name="ans"
                                    id="exampleEmail"
                                    placeholder="Write your answer"
                                />
                                {ansTrue ? (
                                    ""
                                ) : (
                                    <p style={{ color: "red" }}>
                                        invalid ans! please write correct answer
                                    </p>
                                )}

                                <div className="actnMdlCrd">
                                    <Button
                                        type="button"
                                        className="btn-smb bg-gry btn"
                                        onClick={() => {
                                            deleteToggle(currentMediaId);
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        onClick={handleItemDelete}
                                        className="btn-smb  btn"
                                    >
                                        Delete
                                    </Button>
                                </div>
                            </div>
                        </ModalBody>
                        {/* <ModalFooter>
            
          </ModalFooter> */}
                    </Modal>
                    <Modal
                        isOpen={previewModel1}
                        toggle={() => {
                            setPreviewModal1(!previewModel1);
                        }}
                    >
                        {/* <div className="d-flex justify-content-between">
            <div>Watch Preview</div>
            <div className="cancil" onClick={()=>{setPreviewModal1(!previewModel1)}}>X</div> */}

                        {previewVideo}
                    </Modal>
                    <Modal isOpen={alertModel}>
                        <Alert color={alert.type}>{alert.message}</Alert>
                    </Modal>
                    <Modal isOpen={embelModel} toggle={embedToggalModel}>
                        <ModalBody
                            style={{
                                // maxHeight: "60vh",
                                overflowY: "auto",
                                // border: "1px solid #ccc",
                            }}
                        >
                            <div className=" upLVid">
                                <h4>Select player</h4>
                                <h5
                                    className="cancil"
                                    onClick={embedToggalModel}
                                >
                                    x
                                </h5>

                                <div className="copyCrd">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <h6
                                            style={{
                                                display: "inline",
                                                marginRight: "3rem",
                                            }}
                                        >
                                            Shorts
                                        </h6>
                                        <div>
                                            <CopyToClipboard
                                                text={playerType1}
                                                onCopy={() => {
                                                    handleCopyClick1();
                                                }}
                                            >
                                                {textCopied1 ? (
                                                    <Button
                                                        className="btn btn-success btn-sm"
                                                        type="button"
                                                    >
                                                        copied
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        className="copyTxt btn-sm"
                                                        type="button"
                                                    >
                                                        copy
                                                    </Button>
                                                )}
                                            </CopyToClipboard>
                                            <Button
                                                className="btn-sm"
                                                color="info"
                                                onClick={() => {
                                                    setPreviewVideo(
                                                        <div
                                                            style={{
                                                                position:
                                                                    "relative",
                                                                overflow:
                                                                    "hidden",
                                                                padding:
                                                                    "0 0 177.78% 0",
                                                            }}
                                                        >
                                                            <iframe
                                                                src={`https://cdn.jwplayer.com/players/${currentMediaId}-4QAZv0KO.html`}
                                                                width="100%"
                                                                height="100%"
                                                                frameborder="0"
                                                                scrolling="auto"
                                                                title="Black Turmeric Reel"
                                                                style={{
                                                                    position:
                                                                        "absolute",
                                                                }}
                                                                allowfullscreen
                                                            ></iframe>
                                                            <div
                                                                style={{
                                                                    color: "white",
                                                                    position:
                                                                        "absolute",
                                                                    top: "10px", // Adjust the top position as needed
                                                                    right: "10px", // Adjust the right position as needed
                                                                    background:
                                                                        "rgba(255, 255, 255, 0.8)",
                                                                    padding:
                                                                        "5px 10px",
                                                                    borderRadius:
                                                                        "4px",
                                                                    cursor: "pointer",
                                                                }}
                                                                onClick={() =>
                                                                    setPreviewModal1(
                                                                        false
                                                                    )
                                                                }
                                                            >
                                                                X
                                                            </div>
                                                        </div>
                                                    );
                                                    setPreviewModal1(
                                                        !previewModel1
                                                    );
                                                }}
                                            >
                                                Preview
                                            </Button>
                                        </div>
                                    </div>

                                    <p className="mt-1 check ">{playerType1}</p>
                                </div>
                                <div className="copyCrd">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <h6
                                            style={{
                                                display: "inline",
                                                marginRight: "1rem",
                                            }}
                                        >
                                            640x360 example player
                                        </h6>
                                        <div>
                                            <CopyToClipboard
                                                text={playerType2}
                                                onCopy={() => {
                                                    handleCopyClick2();
                                                }}
                                            >
                                                {textCopied2 ? (
                                                    <Button
                                                        className="btn btn-success btn-sm"
                                                        type="button"
                                                    >
                                                        copied
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        className="copyTxt btn-sm"
                                                        type="button"
                                                    >
                                                        copy
                                                    </Button>
                                                )}
                                            </CopyToClipboard>
                                            <Button
                                                className="btn-sm"
                                                color="info"
                                                onClick={() => {
                                                    setPreviewVideo(
                                                        <div>
                                                            <iframe
                                                                src={`https://cdn.jwplayer.com/players/${currentMediaId}-N1kHxxk9.html`}
                                                                width="640"
                                                                height="360"
                                                                frameborder="0"
                                                                scrolling="auto"
                                                                title="Black Turmeric Reel"
                                                                allowfullscreen
                                                                style={{
                                                                    position:
                                                                        "absolute",
                                                                }}
                                                            ></iframe>
                                                            <div
                                                                style={{
                                                                    color: "white",
                                                                    position:
                                                                        "absolute",
                                                                    top: "10px", // Adjust the top position as needed
                                                                    left: "600px", // Adjust the right position as needed
                                                                    background:
                                                                        "rgba(255, 255, 255, 0.8)",
                                                                    padding:
                                                                        "5px 10px",
                                                                    borderRadius:
                                                                        "4px",
                                                                    cursor: "pointer",
                                                                }}
                                                                onClick={() =>
                                                                    setPreviewModal1(
                                                                        false
                                                                    )
                                                                }
                                                            >
                                                                X
                                                            </div>
                                                        </div>
                                                    );
                                                    setPreviewModal1(
                                                        !previewModel1
                                                    );
                                                }}
                                            >
                                                Preview
                                            </Button>
                                        </div>
                                    </div>

                                    <p className="mt-3 check">{playerType2}</p>
                                </div>

                                <div className="copyCrd">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <h6
                                            style={{
                                                display: "inline",
                                                marginRight: "3rem",
                                            }}
                                        >
                                            16:9 example player
                                        </h6>
                                        <div>
                                            <CopyToClipboard
                                                text={playerType3}
                                                onCopy={() => {
                                                    handleCopyClick3();
                                                }}
                                            >
                                                {textCopied3 ? (
                                                    <Button
                                                        className="btn btn-success btn-sm"
                                                        type="button"
                                                    >
                                                        copied
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        className="copyTxt btn-sm"
                                                        type="button"
                                                    >
                                                        copy
                                                    </Button>
                                                )}
                                            </CopyToClipboard>

                                            <Button
                                                className="btn-sm"
                                                color="info"
                                                onClick={() => {
                                                    setPreviewVideo(
                                                        <div
                                                            style={{
                                                                position:
                                                                    "relative",
                                                                overflow:
                                                                    "hidden",
                                                                padding:
                                                                    "0 0 56.25% 0",
                                                            }}
                                                        >
                                                            <iframe
                                                                src={`https://cdn.jwplayer.com/players/${currentMediaId}-7iV6s7Lb.html`}
                                                                width="100%"
                                                                height="100%"
                                                                frameborder="0"
                                                                scrolling="auto"
                                                                title="Black Turmeric Reel"
                                                                style={{
                                                                    position:
                                                                        "absolute",
                                                                }}
                                                                allowfullscreen
                                                                autoPlay
                                                            ></iframe>
                                                            <div
                                                                style={{
                                                                    color: "white",
                                                                    position:
                                                                        "absolute",
                                                                    top: "10px", // Adjust the top position as needed
                                                                    right: "10px", // Adjust the right position as needed
                                                                    background:
                                                                        "rgba(255, 255, 255, 0.8)",
                                                                    padding:
                                                                        "5px 10px",
                                                                    borderRadius:
                                                                        "4px",
                                                                    cursor: "pointer",
                                                                }}
                                                                onClick={() =>
                                                                    setPreviewModal1(
                                                                        false
                                                                    )
                                                                }
                                                            >
                                                                X
                                                            </div>
                                                        </div>
                                                    );
                                                    setPreviewModal1(
                                                        !previewModel1
                                                    );
                                                }}
                                            >
                                                Preview
                                            </Button>
                                        </div>
                                    </div>

                                    <p className="mt-3 check">{playerType3}</p>
                                </div>
                            </div>
                        </ModalBody>
                    </Modal>

                    {/* <VideoListingTable /> */}
                    <>
                        {showLoader ? (
                            <center>
                                <Spinner></Spinner>
                            </center>
                        ) : (
                            <div className="row">
                                <Col md="12">
                                    <div className="dp-box videoContnr">
                                        <div className="card">
                                            <div className="card-body">
                                                {totalArticles > 0 ? (
                                                    <div className="pagJw">
                                                        <b>
                                                            {firstPage}-
                                                            {lastPage} of{" "}
                                                            {totalArticles}
                                                        </b>
                                                        <Pagination
                                                            activePage={
                                                                activePage
                                                            }
                                                            itemsCountPerPage={
                                                                records
                                                            }
                                                            totalItemsCount={
                                                                totalArticles
                                                            }
                                                            pageRangeDisplayed={
                                                                5
                                                            }
                                                            onChange={handlePageChange.bind(
                                                                this
                                                            )}
                                                            itemClass="page-item"
                                                            linkClass="page-link"
                                                        />
                                                    </div>
                                                ) : (
                                                    ""
                                                )}

                                                {mediadata.length === 0 ? (
                                                    // <Card className="mt-3">
                                                    //   <div style={{ width: "90%", height: "400px" }}>
                                                    <center>
                                                        <h4>
                                                            No Records Found
                                                        </h4>{" "}
                                                    </center>
                                                ) : (
                                                    //   </div>
                                                    // </Card>
                                                    // <Card>
                                                    //   <CardBody>
                                                    <div className="tBdata-list">
                                                        <Table responsive>
                                                            <thead
                                                            //style={{ backgroundColor: "rgba(23, 112, 230, 0.43)" }}
                                                            >
                                                                <th className="tdCenter">
                                                                    S.no
                                                                </th>
                                                                <th
                                                                    title="Media"
                                                                    className="tdCenter"
                                                                >
                                                                    Media id
                                                                </th>
                                                                <th
                                                                    title="Thumbnail Image"
                                                                    className="tdCenter"
                                                                    width="150px"
                                                                >
                                                                    Thumbimage
                                                                </th>
                                                                <th
                                                                    title="Title"
                                                                    className="tdCenter"
                                                                >
                                                                    Title
                                                                </th>
                                                                <th
                                                                    title="Duration"
                                                                    className="tdCenter"
                                                                >
                                                                    Duration
                                                                </th>
                                                                <th
                                                                    title="Last Updated"
                                                                    className="tdCenter"
                                                                >
                                                                    Last
                                                                    Modified At
                                                                </th>
                                                                <th
                                                                    title="Status"
                                                                    className="tdCenter"
                                                                >
                                                                    Status
                                                                </th>
                                                                <th
                                                                    title="Actions"
                                                                    className="tdCenter"
                                                                    width="120px"
                                                                >
                                                                    Action
                                                                </th>
                                                            </thead>

                                                            <tbody>
                                                                {mediadata &&
                                                                    mediadata.length !==
                                                                        0 &&
                                                                    mediadata.map(
                                                                        (
                                                                            e,
                                                                            index
                                                                        ) => {
                                                                            if (
                                                                                e.status ===
                                                                                "failed"
                                                                            ) {
                                                                                return (
                                                                                    <tr
                                                                                        key={
                                                                                            index
                                                                                        }
                                                                                        className="table-danger"
                                                                                        style={{
                                                                                            height: "100px",
                                                                                        }}
                                                                                    >
                                                                                        <td className="tdCenter">
                                                                                            {(activePage -
                                                                                                1) *
                                                                                                10 +
                                                                                                index +
                                                                                                1}
                                                                                        </td>
                                                                                        <td className="tdCenter">
                                                                                            {
                                                                                                e.id
                                                                                            }
                                                                                        </td>
                                                                                        <td className="tdCenter thImg">
                                                                                            {e.status !==
                                                                                                "failed" && (
                                                                                                <img
                                                                                                    width="150px"
                                                                                                    // height="100%"
                                                                                                    style={{
                                                                                                        boxfit: "cover",
                                                                                                    }}
                                                                                                    src={`https://cdn.jwplayer.com/v2/media/${e.id}/poster.jpg`}
                                                                                                    alt={
                                                                                                        e
                                                                                                            .metadata
                                                                                                            .title
                                                                                                    }
                                                                                                />
                                                                                            )}
                                                                                        </td>
                                                                                        <td className="tdCenter">
                                                                                            {
                                                                                                e
                                                                                                    .metadata
                                                                                                    .title
                                                                                            }
                                                                                        </td>
                                                                                        <td className="tdCenter">
                                                                                            {convertSecondsToHMS(
                                                                                                Math.floor(
                                                                                                    e.duration
                                                                                                )
                                                                                            )}
                                                                                        </td>
                                                                                        <td className="tdCenter">
                                                                                            {moment(
                                                                                                e.last_modified
                                                                                            ).fromNow()}
                                                                                        </td>
                                                                                        {(() => {
                                                                                            if (
                                                                                                e.status ===
                                                                                                "ready"
                                                                                            ) {
                                                                                                return (
                                                                                                    <td
                                                                                                        style={{
                                                                                                            color: "green",
                                                                                                        }}
                                                                                                    >
                                                                                                        {
                                                                                                            e.status
                                                                                                        }
                                                                                                    </td>
                                                                                                );
                                                                                            } else if (
                                                                                                e.status ===
                                                                                                "failed"
                                                                                            ) {
                                                                                                return (
                                                                                                    <td
                                                                                                        style={{
                                                                                                            color: "red",
                                                                                                        }}
                                                                                                    >
                                                                                                        {
                                                                                                            e.status
                                                                                                        }
                                                                                                    </td>
                                                                                                );
                                                                                            } else {
                                                                                                return (
                                                                                                    <td
                                                                                                        style={{
                                                                                                            color: "grey",
                                                                                                        }}
                                                                                                    >
                                                                                                        {
                                                                                                            e.status
                                                                                                        }
                                                                                                    </td>
                                                                                                );
                                                                                            }
                                                                                        })()}

                                                                                        {e.status !==
                                                                                        "failed" ? (
                                                                                            <td>
                                                                                                <div className="mb-3">
                                                                                                    <FontAwesomeIcon
                                                                                                        title="Delete"
                                                                                                        icon={
                                                                                                            faTrash
                                                                                                        }
                                                                                                        onClick={() => {
                                                                                                            deleteToggle(
                                                                                                                e.id
                                                                                                            );
                                                                                                        }}
                                                                                                        style={{
                                                                                                            color: "red",
                                                                                                            cursor: "pointer",
                                                                                                        }}
                                                                                                    />
                                                                                                </div>

                                                                                                <div className="mb-3">
                                                                                                    <FontAwesomeIcon
                                                                                                        style={{
                                                                                                            cursor: "pointer",
                                                                                                        }}
                                                                                                        title="Edit"
                                                                                                        onClick={() => {
                                                                                                            toggleEditModal(
                                                                                                                e.id
                                                                                                            );
                                                                                                        }}
                                                                                                        icon={
                                                                                                            faEdit
                                                                                                        }
                                                                                                        className="mx-3"
                                                                                                    />
                                                                                                </div>

                                                                                                <div className="mb-3">
                                                                                                    <FontAwesomeIcon
                                                                                                        title="Embed Code"
                                                                                                        style={{
                                                                                                            cursor: "pointer",
                                                                                                        }}
                                                                                                        onClick={() => {
                                                                                                            embedToggalModel(
                                                                                                                e.id
                                                                                                            );
                                                                                                        }}
                                                                                                        icon={
                                                                                                            faCode
                                                                                                        }
                                                                                                    />
                                                                                                </div>
                                                                                            </td>
                                                                                        ) : (
                                                                                            <td>
                                                                                                <FontAwesomeIcon
                                                                                                    title="Delete"
                                                                                                    icon={
                                                                                                        faTrash
                                                                                                    }
                                                                                                    onClick={() => {
                                                                                                        deleteToggle(
                                                                                                            e.id
                                                                                                        );
                                                                                                    }}
                                                                                                    style={{
                                                                                                        color: "red",
                                                                                                        cursor: "pointer",
                                                                                                    }}
                                                                                                />
                                                                                            </td>
                                                                                        )}
                                                                                    </tr>
                                                                                );
                                                                            } else {
                                                                                return (
                                                                                    <tr
                                                                                        key={
                                                                                            index
                                                                                        }
                                                                                    >
                                                                                        <td className="tdCenter">
                                                                                            {(activePage -
                                                                                                1) *
                                                                                                10 +
                                                                                                index +
                                                                                                1}
                                                                                        </td>
                                                                                        <td className="tdCenter">
                                                                                            {
                                                                                                e.id
                                                                                            }
                                                                                        </td>
                                                                                        <td className="tdCenter thImg1">
                                                                                            {e.status !==
                                                                                                "failed" &&
                                                                                            e.status ===
                                                                                                "ready" ? (
                                                                                                <picture>
                                                                                                    <source
                                                                                                        media="(max-width:750px)"
                                                                                                        srcSet="https://www.sortd.mobi/wp-content/uploads/2023/04/Digital-750-%C3%97-450-px.jpg"
                                                                                                    ></source>
                                                                                                    <img
                                                                                                        onError={
                                                                                                            onImageError
                                                                                                        }
                                                                                                        width="100%"
                                                                                                        // height="150px"
                                                                                                        style={{
                                                                                                            boxfit: "cover",
                                                                                                        }}
                                                                                                        src={`https://cdn.jwplayer.com/v2/media/${e.id}/poster.jpg?width=320`}
                                                                                                        alt={
                                                                                                            e
                                                                                                                .metadata
                                                                                                                .title
                                                                                                        }
                                                                                                    />
                                                                                                </picture>
                                                                                            ) : (
                                                                                                <div
                                                                                                    style={{
                                                                                                        // height: "150px",
                                                                                                        width: "150px",
                                                                                                    }}
                                                                                                ></div>
                                                                                            )}
                                                                                        </td>
                                                                                        <td className="tdCenter ">
                                                                                            <div className="jwplayer-title">
                                                                                                {
                                                                                                    e
                                                                                                        .metadata
                                                                                                        .title
                                                                                                }
                                                                                            </div>
                                                                                        </td>
                                                                                        {e.status ===
                                                                                        "processing" ? (
                                                                                            <td className="tdCenter">
                                                                                                {convertSecondsToHMS(
                                                                                                    Math.ceil(
                                                                                                        e.duration
                                                                                                    )
                                                                                                )}
                                                                                            </td>
                                                                                        ) : (
                                                                                            <td className="tdCenter">
                                                                                                {convertSecondsToHMS(
                                                                                                    Math.ceil(
                                                                                                        e.duration
                                                                                                    ) -
                                                                                                        1
                                                                                                )}
                                                                                            </td>
                                                                                        )}

                                                                                        <td className="tdCenter">
                                                                                            {moment(
                                                                                                e.last_modified
                                                                                            ).fromNow()}
                                                                                        </td>
                                                                                        {(() => {
                                                                                            if (
                                                                                                e.status ===
                                                                                                "ready"
                                                                                            ) {
                                                                                                return (
                                                                                                    <td
                                                                                                        style={{
                                                                                                            color: "green",
                                                                                                        }}
                                                                                                    >
                                                                                                        {
                                                                                                            e.status
                                                                                                        }
                                                                                                    </td>
                                                                                                );
                                                                                            } else if (
                                                                                                e.status ===
                                                                                                "failed"
                                                                                            ) {
                                                                                                return (
                                                                                                    <td
                                                                                                        style={{
                                                                                                            color: "red",
                                                                                                        }}
                                                                                                    >
                                                                                                        {
                                                                                                            e.status
                                                                                                        }
                                                                                                    </td>
                                                                                                );
                                                                                            } else {
                                                                                                return (
                                                                                                    <td
                                                                                                        style={{
                                                                                                            color: "grey",
                                                                                                        }}
                                                                                                    >
                                                                                                        {
                                                                                                            e.status
                                                                                                        }
                                                                                                    </td>
                                                                                                );
                                                                                            }
                                                                                        })()}

                                                                                        {e.status !==
                                                                                        "failed" ? (
                                                                                            <td
                                                                                                // style={{ height: "200px" }}
                                                                                                className="d-flex actnTabl flex-column"
                                                                                            >
                                                                                                <div></div>
                                                                                                <FontAwesomeIcon
                                                                                                    title="Delete"
                                                                                                    icon={
                                                                                                        faTrash
                                                                                                    }
                                                                                                    onClick={() => {
                                                                                                        console.log(
                                                                                                            "DELETE"
                                                                                                        );
                                                                                                        deleteToggle(
                                                                                                            e.id
                                                                                                        );
                                                                                                    }}
                                                                                                    style={{
                                                                                                        color: "red",
                                                                                                        cursor: "pointer",
                                                                                                        margin: "4 0",
                                                                                                    }}
                                                                                                />
                                                                                                <FontAwesomeIcon
                                                                                                    style={{
                                                                                                        cursor: "pointer",
                                                                                                        margin: "10",
                                                                                                    }}
                                                                                                    title="Edit"
                                                                                                    onClick={() => {
                                                                                                        toggleEditModal(
                                                                                                            e.id
                                                                                                        );
                                                                                                    }}
                                                                                                    icon={
                                                                                                        faEdit
                                                                                                    }
                                                                                                    className="mx-3"
                                                                                                />
                                                                                                <FontAwesomeIcon
                                                                                                    title="embed code"
                                                                                                    style={{
                                                                                                        cursor: "pointer",
                                                                                                        margin: "4 0",
                                                                                                    }}
                                                                                                    onClick={() => {
                                                                                                        embedToggalModel(
                                                                                                            e.id
                                                                                                        );
                                                                                                    }}
                                                                                                    icon={
                                                                                                        faCode
                                                                                                    }
                                                                                                />
                                                                                                <div className="mb-3">
                                                                                                    <Link
                                                                                                        target="_blank"
                                                                                                        to={`/analytics/media/${e.id}`}
                                                                                                    >
                                                                                                        <FontAwesomeIcon
                                                                                                            title="Analytics"
                                                                                                            style={{
                                                                                                                cursor: "pointer",
                                                                                                                color: "black",
                                                                                                            }}
                                                                                                            // onClick={() => {
                                                                                                            //   embedToggalModel(e.id);
                                                                                                            // }}
                                                                                                            icon={
                                                                                                                faChartLine
                                                                                                            }
                                                                                                        />
                                                                                                    </Link>
                                                                                                </div>

                                                                                                <FontAwesomeIcon
                                                                                                    style={{
                                                                                                        cursor: "pointer",
                                                                                                        margin: "10",
                                                                                                    }}
                                                                                                    title="Trim"
                                                                                                    onClick={() => {
                                                                                                        toggleTrimModal(
                                                                                                            e
                                                                                                        );
                                                                                                    }}
                                                                                                    icon={
                                                                                                        faHandScissors
                                                                                                    }
                                                                                                    className="mx-3"
                                                                                                />
                                                                                            </td>
                                                                                        ) : (
                                                                                            <td>
                                                                                                <FontAwesomeIcon
                                                                                                    title="Delete"
                                                                                                    icon={
                                                                                                        faTrash
                                                                                                    }
                                                                                                    onClick={() => {
                                                                                                        deleteToggle(
                                                                                                            e.id
                                                                                                        );
                                                                                                    }}
                                                                                                    style={{
                                                                                                        color: "red",
                                                                                                        cursor: "pointer",
                                                                                                    }}
                                                                                                />
                                                                                            </td>
                                                                                        )}
                                                                                    </tr>
                                                                                );
                                                                            }
                                                                        }
                                                                    )}
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                    //   </CardBody>
                                                    // </Card>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </div>
                        )}
                    </>
                </div>
                {isSlidingScreenVisible.value ? (
                    <div>
                        {" "}
                        <SlidingChapterScreen
                            projectId={projectId}
                            mediaId={isSlidingScreenVisible.mediaId}
                            duration={isSlidingScreenVisible.duration}
                        />
                        <div
                            className="overlay"
                            onClick={() => {
                                setSlidingScreenVisible({
                                    value: false,
                                    mediaId: null,
                                    duration: null,
                                });
                            }}
                        >
                            {" "}
                        </div>
                    </div>
                ) : (
                    ""
                )}
            </div>

            {trimMediaModal ? (
                <div>
                    <TrimModal
                        media={selectedMedia}
                        projectId={projectId}
                        cancelTrim={() => {
                            setTrimMediaModal(false);
                            setReloadPage(!reloadPage);
                        }}
                    />
                    {/* <div
                        className="overlay"
                        onClick={() => {
                            setTrimMediaModal(false);
                        }}
                    >
                        {" "}
                    </div> */}
                </div>
            ) : (
                ""
            )}
        </>
    );
};

export default VideoUploadForm;

import React, { useEffect, useState, useContext } from "react";
import contentService from "../../services/api/content-service";
import { useHistory } from "react-router-dom";
import ProjectContext from '../../ProjectContext';
import Moment from 'react-moment';
import Pagination from "react-js-pagination";
import ReactBSAlert from "react-bootstrap-sweetalert";
import formValidations from "../../variables/form-validations";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Label,
  FormGroup,
  Form,
  Input,
  CardTitle,
  Alert,
  Spinner,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";
import Select from "react-select";
import './content.css';

const Taxonomies = (props) => {
  const projectDetails = useContext(ProjectContext);
  const history = new useHistory();
  console.log(projectDetails.id);
  if (projectDetails.id == '') {
    history.push("/project/allprojects");
  }
  const projectId = projectDetails.id;
  const [formdetails, setFormDetails] = useState({ projectid: projectId, name: '', order_by: 'createdAt' });
  const [search, setSearchDetails] = useState({ name: '', order_by: 'createdAt' });
  const [name, setname] = useState("");
  const [taxonomies, settaxonomies] = useState([]);
  const [totalTaxonomies, setTotalTaxonomies] = useState(0);
  const [renderComponent, setRenderComponent] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const records = 10;
  const orderByOptions = [
    {
      value: "createdAt",
      label: "Created At"
    },
    {
      value: "updatedAt",
      label: "Updated At"
    },
  ];

  let getProjectSlug = new URLSearchParams(props.location.search).get('project');
  if (!getProjectSlug) {
    history.push({
      search: '?project=' + projectDetails.slug
    })
  }

  useEffect(() => {
    (async () => {
      let taxonomyData = await contentService.getTaxonomies(formdetails, projectId, activePage, records);
      if (taxonomyData.status && taxonomyData.data && taxonomyData.data.taxonomy_types) {
        settaxonomies(taxonomyData.data.taxonomy_types);
        setTotalTaxonomies(taxonomyData.data.count);
      }
      setRenderComponent(true);
    })();
  }, [formdetails, activePage]);


  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  }

  const handleOrderByChange = (event) => {
    setFormDetails({
      ...formdetails,
      order_by: event.value,
    });
  }

  const handlenameChange = (event) => {
    setFormDetails({
      ...formdetails,
      name: event.target.value,
    });
    setname(event.target.value);
  }

  const handleSubmit = function (e) { e.preventDefault() }


  const resetForm = () => {
    setname("");
    setActivePage(1)
    setFormDetails({
      ...formdetails,
      name: "",
      order_by: 'createdAt'
    });
    setSearchDetails({
      name: "",
      order_by: 'createdAt'
    });
  }


  let taxonomyElements = [];
  let firstRecord = 0;
  let lastRecord = 0;
  if (!taxonomies || !taxonomies.length) {
    if (!renderComponent) {
      taxonomyElements.push(
        <td colSpan="4" className="text-center">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </td>
      );
    } else {
      taxonomyElements.push(
        <td colSpan="4" className="text-center">
          <CardTitle>
            No taxonomy Found
          </CardTitle>
        </td>
      );
    }
  } else {
    let i = 1;
    taxonomies.forEach(function (taxonomyDetails) {
      let className = '';
      if (!taxonomyDetails.status) {
        className = "table-gray";
      }
      if (i == 1) {
        firstRecord = (activePage - 1) * records + i;
      }
      taxonomyElements.push(
        <tr id={"row_" + taxonomyDetails._id} className={className}>
          <td className="text-center">{(activePage - 1) * records + i}</td>
          <td className="text-center">{taxonomyDetails.name}</td>
          <td className="text-center">{taxonomyDetails.slug}</td>
          <td className="text-center">{taxonomyDetails.count}</td>
          <td className="text-center">
            <Moment format="dddd DD MMM YYYY hh:mm:ss a">
              {taxonomyDetails.createdAt}
            </Moment>
          </td>
          <td className="text-center">
            <Moment format="dddd DD MMM YYYY hh:mm:ss a">
              {taxonomyDetails.updatedAt}
            </Moment>
          </td>
        </tr>
      )
      if (i == taxonomies.length) {
        lastRecord = (activePage - 1) * records + i;
      }
      i++;
      if (i == taxonomies.length) {
        lastRecord = (activePage - 1) * records + i;
      }
    })
  }
  return (
    <div className="content mt-30">
      <Container>
        <Row>
          <Col className="ml-auto mr-auto" md="12">
            <Card className="card-signup dp-box">
              <CardHeader>
                <Row>
                  <CardTitle tag="h4" className="heding_tab_h4 ">
                    Taxonomy Manager
                  </CardTitle>
                </Row>
                <Form id="taxonomyfilters" action="" className="form" method="POST" onSubmit={handleSubmit}>
                  <div className="srchBox">
                    <Row>
                      <Input type="text" name="searchname" id="searchname" value={name} onChange={handlenameChange} placeholder="Search in Name" />
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        placeholder="Order by"
                        name="order_by"
                        id="order_by"
                        options={orderByOptions}
                        value={orderByOptions ? orderByOptions.filter(option => option.value == formdetails.order_by) : ""}
                        onChange={handleOrderByChange}
                      />

                      <Col>
                        <Button
                          className="btn-smb btn-rst-txny"
                          type="button"
                          onClick={resetForm}
                        >
                          Reset
                        </Button>
                      </Col>
                    </Row>
                  </div>

                  {totalTaxonomies > 0 ?
                    <div className="pagNo" style={{padding:"10px"}}>
                      {firstRecord} - {lastRecord} of {totalTaxonomies}
                    </div>
                    : null}
                
                    {totalTaxonomies > 0 ?
                      <Row>
                        <div className="txny-pagBox" style={{"margin-top":"10px !important"}}>
                          <div className="pagDgt">
                            <Pagination
                              activePage={activePage}
                              itemsCountPerPage={records}
                              totalItemsCount={totalTaxonomies}
                              pageRangeDisplayed={5}
                              onChange={handlePageChange.bind(this)}
                              itemClass="page-item"
                              linkClass="page-link"
                            />
                          </div>
                        </div>
                      </Row>

                      : ''}
                </Form>

              </CardHeader>
              <CardBody>
                <Table responsive>
                  <thead className="text-primary">
                    <th className="text-center">#</th>
                    <th className="text-center">Taxonomy Name</th>
                    <th className="text-center">Slug</th>
                    <th className="text-center">Total Taxonomies</th>
                    <th className="text-center">Created At</th>
                    <th className="text-center">Updated At</th>
                  </thead>
                  <tbody>{taxonomyElements}</tbody>

                </Table>


              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Taxonomies;

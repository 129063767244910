import React, { useState, useEffect, useContext } from "react";
import Select from "react-select";
import { Line, Pie } from "react-chartjs-2";
import ReactDatetime from 'react-datetime'
import allListingService from "../../services/api/Jwplayer/allListing-service";

import {
  Container,
  Row,
  Col,
  Table,
  Spinner,
  Card,
  CardBody,
} from "reactstrap";
import DatePicker from "react-datepicker";
import "./VideoUploadForm.css";
import ProjectContext from "../../ProjectContext";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { useHistory, useParams } from "react-router-dom";
import countryCode from "./country_code";

const SingleMediaAnalytics = () => {
  let [labels, setLabels] = useState([]);
  const [label1, setLabel1] = useState("");
  const [label2, setLabel2] = useState("");
  let [data1, setData1] = useState([]);
  let [data2, setData2] = useState([]);
  const [fetched, setFetched] = useState(false);
  const [videoPlaysData, setVideoPlaysData] = useState([]);
  const [countryPlaysData, setCountryPlaysData] = useState([]);
  const [domainPlaysData, setDomainPlaysData] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [isOpen, setIsOpen] = useState(false);
  const [startDate1, setStartDate1] = useState(new Date());
  const [isOpen1, setIsOpen1] = useState(false);
  const [analyticsMetrix1, setAnalyticsMetrix1] = useState("plays");
  const [analyticsMetrix2, setAnalyticsMetrix2] = useState("ad_impressions");
  const [showLoader, setShowLoader] = useState(false);
  const [showLoader1, setShowLoader1] = useState(false);
  const [curdate1, setCurdate1] = useState("");
  const [curdate2, setCurdate2] = useState("");
  const [myNewMap, setMyNewMap] = useState({});
  const [dateMessage, setDateMessage] = useState("");
  const [isValidDate, setIsValidDate] = useState(false);
  const [defaultButton, setDefaultButton] = useState(true);
  const [activeButton1,setActiceButton1] = useState(true);
  const [activeButton2,setActiceButton2] = useState(false);
  const [activeButton3,setActiceButton3] = useState(false);
  const [formdetails, setFormDetails] = useState({
  
    order_by :'',
   
  });
  const orderByOptions = [
    {
      value : "today",
      label : "Today"
    },
    {
      value : "this month",
      label : "This Month"
    },
    {
      value : "last month",
      label : "Last Month"
    },
  ];
  const [pieOptions, setPieOptions] = useState({
    labels: ["Device A", "Device B"],
    datasets: [
      {
        label: "# of Votes",
        data: [0, 10],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  });
  const history = useHistory();
  const projectDetails = useContext(ProjectContext);
  if (!projectDetails.id) {
    history.push("/project/allprojects");
  }
  let singleMediaId = "";

  const { id } = useParams();
  singleMediaId = id;

  const projectId = projectDetails.id;
  var myMap = new Map();
  let check = 0;
  let count = 0;
  const countryMap = countryCode;
  const fetchAnalytics = async (field1, field2) => {
    setAnalyticsMetrix1(field1);
    setAnalyticsMetrix2(field2);
    data1 = [];
    data2 = [];
    labels = [];
    const date1 = moment(startDate).format("YYYY-MM-DD");
    const date2 = moment(startDate1).format("YYYY-MM-DD");
    const currentDate = moment(new Date()).format("YYYY-MM-DD");

    if (date1 > date2) {
      setDateMessage("Please enter valid date");
      setIsValidDate(true);
      return;
    } else if (date1 > currentDate && date2 > currentDate) {
      setDateMessage("Please enter current or past date");
      setIsValidDate(true);
      return;
    }
    setFetched(false);
    if (startDate !== curdate1 || startDate1 !== curdate2) {
      setVideoPlaysData([]);
      setCountryPlaysData([]);
      setDomainPlaysData([]);
      setShowLoader1(true);
    }

    setShowLoader(true);
    setIsValidDate(false);
    const { data } = await allListingService.jwplayerAnalyticsData(
      field1,
      field2,
      date1,
      date2,
      projectId,
      singleMediaId
    );

    const newData = JSON.parse(data.data.data).data.rows;

    for (let i = 0; i < newData.length; i++) {
      data1.push(newData[i][1]);
      data2.push(newData[i][2]);
      labels.push(moment(newData[i][0]).format("Do MMM"));
      setLabel1(
        JSON.parse(data.data.data).metadata.column_headers.metrics[0].display
      );
      setLabel2(
        JSON.parse(data.data.data).metadata.column_headers.metrics[1].display
      );
    }
    setData1(data1);
    setData2(data2);
    setLabels(labels);

    setFetched(true);
    setShowLoader(false);
    if (startDate !== curdate1 || startDate1 !== curdate2) {
      const videoPLays = await fetchVideoPlayData();
      const countryPlays = await fetchCountryPlaysData();
      await fetchDevicePlaysData();
      const domainPlays = await fetchDomainPlaysData();
      setVideoPlaysData(videoPLays);
      setCountryPlaysData(countryPlays);
      setDomainPlaysData(domainPlays);
      setCurdate1(startDate);
      setCurdate2(startDate1);
    }
  };

  const fetchVideoPlayData = async () => {
    const date1 = moment(startDate).format("YYYY-MM-DD");
    const date2 = moment(startDate1).format("YYYY-MM-DD");
    const { data } = await allListingService.jwplayerVideoPlaysData(
      date1,
      date2,
      projectId
    );
    const res = await allListingService.getAllMediaData(projectId);
    const mediaData = res.data.data.media;
    mediaData.map((e) => {
      myMap.set(e.id, e.metadata.title);
    });
    setMyNewMap(myMap);
    setShowLoader1(false);
    // console.log("map is",myNewMap.get("wICkBUAV"))
    const newData = JSON.parse(data.data.data).data.rows;

    if (newData) {
      return newData;
    }
  };
  const fetchDevicePlaysData = async () => {
    const date1 = moment(startDate).format("YYYY-MM-DD");
    const date2 = moment(startDate1).format("YYYY-MM-DD");
    const { data } = await allListingService.jwplayerDevicePlaysData(
      date1,
      date2,
      projectId
    );
    const newData = JSON.parse(data.data.data).data.rows;
    console.log("device data is: ", newData);
    let device = [];
    let devicePlays = [];

    for (let i = 0; i < newData.length; i++) {
      device.push(newData[i][0]);
      devicePlays.push(newData[i][1]);
    }
    let pieData = {
      labels: device,
      datasets: [
        {
          label: "# of Votes",
          data: devicePlays,
          backgroundColor: [
            "rgba(255, 99, 132, 0.2)",
            "rgba(54, 162, 235, 0.2)",
            "rgba(255, 206, 86, 0.2)",
            "rgba(75, 192, 192, 0.2)",
            "rgba(153, 102, 255, 0.2)",
            "rgba(255, 159, 64, 0.2)",
          ],
          borderColor: [
            "rgba(255, 99, 132, 1)",
            "rgba(54, 162, 235, 1)",
            "rgba(255, 206, 86, 1)",
            "rgba(75, 192, 192, 1)",
            "rgba(153, 102, 255, 1)",
            "rgba(255, 159, 64, 1)",
          ],
          borderWidth: 1,
        },
      ],
    };
    setPieOptions(pieData);
  };
  const fetchCountryPlaysData = async () => {
    const date1 = moment(startDate).format("YYYY-MM-DD");
    const date2 = moment(startDate1).format("YYYY-MM-DD");
    const { data } = await allListingService.jwplayerCountryPlaysData(
      date1,
      date2,
      projectId
    );
    const newData = JSON.parse(data.data.data).data.rows;

    if (newData) {
      return newData;
    }
  };
  const fetchDomainPlaysData = async () => {
    const date1 = moment(startDate).format("YYYY-MM-DD");
    const date2 = moment(startDate1).format("YYYY-MM-DD");
    const { data } = await allListingService.jwplayerDomainPlaysData(
      date1,
      date2,
      projectId
    );
    const newData = JSON.parse(data.data.data).data.rows;

    if (newData) {
      return newData;
    }
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Chart.js Line Chart",
      },
    },
    scales: {
      y: {
        suggestedMin: 0, // Set the minimum value for the y-axis
      },
    },
  };
  useEffect(() => {
    (async () => {
      const defaultDate = moment(startDate).startOf("month");
      const timestamp = new Date(defaultDate).getTime();
      setStartDate(timestamp);
      // await fetchAnalytics("plays", "ad_impressions");

      setFetched(true);
    })();
  }, []);

  const callPlaysImpression = () => {
    setActiceButton1(true);
    setActiceButton2(false);
    setActiceButton3(false);
    data1 = [];
    data2 = [];
    labels = [];
    setFetched(false);
    (async () => {
     
      await fetchAnalytics("plays", "ad_impressions");
      setFetched(true);
    })();
  };

  const callPLaysCompletes = () => {
    setActiceButton1(false);
    setActiceButton2(true);
    setActiceButton3(false);
    data1 = [];

    data2 = [];
    labels = [];
    setDefaultButton(false);
    setFetched(false);
    (async () => {
      await fetchAnalytics("plays", "completes");
      setFetched(true);
    })();
  };

  const callPlaysEmbeds = () => {
    setActiceButton1(false);
    setActiceButton2(false);
    setActiceButton3(true);
    setFetched(false);
    (async () => {
      await fetchAnalytics("plays", "embeds");
      setFetched(true);
    })();
  };
  const handleChange = (e) => {
    setIsOpen(!isOpen);
    setStartDate(e);
  };
  const handleClick = (e) => {
    e.preventDefault();

    setIsOpen(!isOpen);
  };
  const handleChange1 = (e) => {
    setIsOpen1(!isOpen1);
    setStartDate1(e);
  };
  const handleClick1 = (e) => {
    e.preventDefault();
    setIsOpen1(!isOpen1);
  };
  const handleReset = () => {
    const defaultDate = moment(new Date()).startOf("month");
    const timestamp = new Date(defaultDate).getTime();
    setStartDate(timestamp);
    setDefaultButton(true);
    setStartDate1(new Date());
    setData1([]);
    setData2([]);
    setLabels([]);
    setVideoPlaysData([]);
    setCountryPlaysData([]);
    setDomainPlaysData([]);
    setPieOptions({
      labels: ["Device A", "Device B"],
      datasets: [
        {
          label: "# of Votes",
          data: [0, 10],
          backgroundColor: [
            "rgba(255, 99, 132, 0.2)",
            "rgba(54, 162, 235, 0.2)",
            "rgba(255, 206, 86, 0.2)",
            "rgba(75, 192, 192, 0.2)",
            "rgba(153, 102, 255, 0.2)",
            "rgba(255, 159, 64, 0.2)",
          ],
          borderColor: [
            "rgba(255, 99, 132, 1)",
            "rgba(54, 162, 235, 1)",
            "rgba(255, 206, 86, 1)",
            "rgba(75, 192, 192, 1)",
            "rgba(153, 102, 255, 1)",
            "rgba(255, 159, 64, 1)",
          ],
          borderWidth: 2,
        },
      ],
    });
    setIsValidDate(false);
  };

  const today = moment();
  const disableFutureDt = current => {
  
    return current.isBefore(today)
  }
  const handleSelect = (e)=>{
   
 
    if(e.value==='today'){
      setFormDetails({
        ...formdetails,
        order_by: e.value,
      });
      console.log("here inside selected")
      setStartDate(new Date());
      setStartDate1(new Date());
    }
    else if(e.value==='this month'){
      setFormDetails({
        ...formdetails,
        order_by: e.value,
      });
      const defaultDate = moment(new Date()).startOf("month");
      const timestamp = new Date(defaultDate).getTime();
      setStartDate(timestamp);
      setStartDate1(new Date());
    }
    else if(e.value==='last month'){
      setFormDetails({
        ...formdetails,
        order_by: e.value,
      });
      let lastMonthEnd = moment(new Date()).subtract(1,'months').endOf('month');
      setStartDate1(lastMonthEnd);
      let lastMonthFirst = moment(new Date()).subtract(1,'months').startOf('month');
      setStartDate(lastMonthFirst);
    }
  }
  const handleDateChange = (event,key)=>{
    console.log(event);
    setStartDate(()=>moment(event._d).format('YYYY/MM/DD'));
  console.log("start date is :",moment(event._d).format('YYYY/MM/DD'));
  }
  const handleDateChange1 = (event,key)=>{
    setStartDate1(()=>moment(event._d).format('YYYY/MM/DD'));
  }
  return (
    // <>
    //   <Container>
    //     <div className="my-4">
    //       <h6>{`Analytics for media -> ${singleMediaId} `}</h6>
    //     </div>
    //     <Row className="mt-4">
    //       <Col xs="12" md="2" lg="2" sm="12" className="m-3">
    //         From &nbsp;
    //         <FontAwesomeIcon icon={faCalendar}></FontAwesomeIcon> :
    //         <button className="example-custom-input" onClick={handleClick}>
    //           {moment(startDate).format("MM-DD-YYYY")}
    //         </button>
    //         <span className="mx-3"></span>
    //         {isOpen && (
    //           <DatePicker
    //             inline
    //             maxDate={new Date()}
    //             selected={startDate}
    //             onChange={handleChange}
    //           />
    //         )}
    //       </Col>

    //       <Col xs="12" md="2" lg="2" sm="12" className="m-3">
    //         To &nbsp;
    //         <FontAwesomeIcon icon={faCalendar}></FontAwesomeIcon> :
    //         <button className="example-custom-input" onClick={handleClick1}>
    //           {moment(startDate1).format("MM-DD-YYYY")}
    //         </button>
    //         {isOpen1 && (
    //           <DatePicker
    //             inline
    //             maxDate={new Date()}
    //             selected={startDate1}
    //             onChange={handleChange1}
    //           />
    //         )}
    //       </Col>
    //       <Col lg="2" md="3" sm="12" className="mt-3">
    //         <select onChange={handleSelect}>
    //           <option value="">Select an option</option>
    //           <option value="today">Today</option>
    //           <option value="this month">This Month</option>
    //           <option value="last month">Last Month</option>
    //           {/* Add more options as needed */}
    //         </select>
    //       </Col>
    //       <Col xs="12" md="4" sm="12">
    //         <button
    //           type="button"
    //           className="btn btn-success btn-round px-4 mt-0"
    //           onClick={() => {
    //             fetchAnalytics(analyticsMetrix1, analyticsMetrix2);
    //           }}
    //         >
    //           Go
    //         </button>

    //         <button
    //           type="button"
    //           className="btn btn-warning btn-round px-4 mt-0"
    //           onClick={() => {
    //             handleReset();
    //           }}
    //         >
    //           Reset
    //         </button>
    //         {isValidDate && <p style={{ color: "red" }}>{dateMessage}</p>}
    //       </Col>
    //     </Row>
    //   </Container>
    //   <div>
    //     <div className="d-flex justify-content-evenly my-3 my-4">
    //       <div>
    //         {defaultButton ? (
    //           <button
    //             title="Plays vs Ad impression"
    //             style={{ color: "black" }}
    //             type="button"
    //             className="btn btn-primary"
    //             onClick={callPlaysImpression}
    //           >
    //             Plays vs Ad impressions
    //           </button>
    //         ) : (
    //           <button
    //             title="Plays vs Ad impression"
    //             style={{ color: "black" }}
    //             type="button"
    //             className="btn btn-outline-primary"
    //             onClick={callPlaysImpression}
    //           >
    //             Plays vs Ad impressions
    //           </button>
    //         )}
    //       </div>
    //       <div>
    //         <button
    //           title="Plays vs Completes"
    //           style={{ color: "black" }}
    //           type="button"
    //           className="btn btn-outline-primary"
    //           onClick={callPLaysCompletes}
    //         >
    //           Plays vs Ad Completes
    //         </button>
    //       </div>
    //       <div>
    //         <button
    //           title="Plays vs Embeds"
    //           style={{ color: "black" }}
    //           className="btn btn-outline-primary"
    //           onClick={callPlaysEmbeds}
    //         >
    //           Plays vs Ad Embeds
    //         </button>
    //       </div>
    //     </div>
    //     {fetched ? (
    //       <div className="d-flex justify-content-center align-items-center">
    //         <div
    //           style={{ width: "700px", height: "450px", alignment: "center" }}
    //         >
    //           <Line
    //             options={options}
    //             data={{
    //               labels: labels,
    //               datasets: [
    //                 {
    //                   label: analyticsMetrix1,
    //                   data: data1,
    //                   borderColor: "rgb(255, 99, 132)",
    //                   lineTension: 0,
    //                 },
    //                 {
    //                   label: analyticsMetrix2,
    //                   data: data2,
    //                   borderColor: "rgb(53, 162, 235)",
    //                   lineTension: 0,
    //                 },
    //               ],
    //             }}
    //           />
    //         </div>
    //       </div>
    //     ) : (
    //       <div
    //         className="d-flex justify-content-center align-items-center"
    //         style={{ height: "450px", alignment: "center" }}
    //       >
    //         {showLoader ? <Spinner></Spinner> : ""}
    //       </div>
    //     )}

    //     <Container>
    //       <Row>
    //         <Col sm="12" md="6" lg="6">
    //           <Card style={{ height: "550px" }}>
    //             <CardBody>
    //               <Table>
    //                 <thead
    //                   style={{ backgroundColor: "rgba(23, 112, 230, 0.43)" }}
    //                 >
    //                   <tr>
    //                     <th className="tdCenter">Sno</th>
    //                     <th className="tdCenter" title="Media Id">
    //                       Media Id
    //                     </th>
    //                     <th className="tdCenter" title="Title">
    //                       Title
    //                     </th>
    //                     <th className="tdCenter" title="Plays">
    //                       Plays
    //                     </th>
    //                   </tr>
    //                 </thead>

    //                 {showLoader1 && (
    //                   <tbody>
    //                     <tr>
    //                       <td colSpan="4" className="text-center">
    //                         <Spinner animation="border" role="status"></Spinner>
    //                       </td>
    //                     </tr>
    //                   </tbody>
    //                 )}
    //                 {videoPlaysData && videoPlaysData.length !== 0 && (
    //                   <tbody>
    //                     {videoPlaysData.map((e, index) => {
    //                       return (
    //                         <tr key={index}>
    //                           <td className="tdCenter">{index + 1}</td>
    //                           <td className="tdCenter">{e[0]}</td>
    //                           <td className="tdCenter">
    //                             {myNewMap.get(e[0]) === undefined ||
    //                             myNewMap.get(e[0]) === null ? (
    //                               <span style={{ color: "red" }}>
    //                                 Media Deleted
    //                               </span>
    //                             ) : (
    //                               myNewMap.get(e[0])
    //                             )}
    //                           </td>
    //                           <td className="tdCenter">{e[1]}</td>
    //                         </tr>
    //                       );
    //                     })}
    //                   </tbody>
    //                 )}
    //               </Table>
    //             </CardBody>
    //           </Card>
    //         </Col>
    //         <Col md="6" lg="6" sm="12">
    //           <Card>
    //             <CardBody>
    //               <Table>
    //                 <thead
    //                   style={{ backgroundColor: "rgba(23, 112, 230, 0.43)" }}
    //                 >
    //                   <tr>
    //                     <th className="tdCenter">Device Breakdown</th>
    //                   </tr>
    //                 </thead>
    //               </Table>

    //               {!showLoader1 ? (
    //                 <Pie
    //                   data={pieOptions}
    //                   options={{
    //                     plugins: {
    //                       datalabels: {
    //                         display: true,
    //                       },
    //                     },
    //                   }}
    //                 />
    //               ) : (
    //                 <center>
    //                   <Spinner></Spinner>
    //                 </center>
    //               )}
    //             </CardBody>
    //           </Card>
    //           <Card>
    //             <CardBody>
    //               <Table>
    //                 <thead
    //                   style={{ backgroundColor: "rgba(23, 112, 230, 0.43)" }}
    //                 >
    //                   <tr>
    //                     <th className="tdCenter" title="Country">
    //                       Domain
    //                     </th>
    //                     <th className="tdCenter" title="Plays">
    //                       Plays
    //                     </th>
    //                   </tr>
    //                 </thead>
    //                 {showLoader1 && (
    //                   <tbody>
    //                     <tr>
    //                       <td colSpan="6" className="text-center">
    //                         <Spinner animation="border" role="status"></Spinner>
    //                       </td>
    //                     </tr>
    //                   </tbody>
    //                 )}
    //                 {domainPlaysData && domainPlaysData != [] && (
    //                   <tbody>
    //                     {domainPlaysData.map((e, index) => {
    //                       return (
    //                         <tr key={index}>
    //                           <td className="tdCenter">{e[0]}</td>
    //                           <td className="tdCenter">{e[1]}</td>
    //                         </tr>
    //                       );
    //                     })}
    //                   </tbody>
    //                 )}
    //               </Table>
    //             </CardBody>
    //           </Card>
    //           <div className="d-flex flex-column">
    //             <Card>
    //               <CardBody>
    //                 <Table>
    //                   <thead
    //                     style={{ backgroundColor: "rgba(23, 112, 230, 0.43)" }}
    //                   >
    //                     <tr>
    //                       <th className="tdCenter" title="Country">
    //                         Country
    //                       </th>
    //                       <th className="tdCenter" title="Plays">
    //                         Plays
    //                       </th>
    //                     </tr>
    //                   </thead>
    //                   {showLoader1 && (
    //                     <tbody>
    //                       <tr>
    //                         <td colSpan="6" className="text-center">
    //                           <Spinner
    //                             animation="border"
    //                             role="status"
    //                           ></Spinner>
    //                         </td>
    //                       </tr>
    //                     </tbody>
    //                   )}

    //                   {countryPlaysData && countryPlaysData != [] && (
    //                     <tbody>
    //                       {countryPlaysData.map((e, index) => {
    //                         return (
    //                           <tr key={index}>
    //                             <td className="tdCenter">
    //                               {countryMap.get(e[0])}
    //                             </td>
    //                             <td className="tdCenter">{e[1]}</td>
    //                           </tr>
    //                         );
    //                       })}
    //                     </tbody>
    //                   )}
    //                 </Table>
    //               </CardBody>
    //             </Card>
    //           </div>
    //         </Col>
    //       </Row>
    //     </Container>
    //   </div>
    // </>
    <>
    <div className="content be-jwCont">
      <div className="container">
        <Row>

          <Col md="12">
            <div className="dp-box card">
              <div className="card-body">
                <div className="row">    
                  <Col>    
                        <label className="form-label">From Date:</label>
                        <ReactDatetime
                          isValidDate={disableFutureDt}
                            value={startDate}
                           onChange={(event)=> handleDateChange(event,"startDate")}
                            inputProps={{
                              className: "form-control inpt",
                              placeholder: "From date",
                              id:"fromDate",
                              input:'false',
                              autoComplete : 'off'
                            }}
                            dateFormat ={"DD/MM/YYYY"}
                            closeOnSelect
                          />
                  </Col>
                  <Col> 
                        <label className="form-label">To Date:</label>
                        <ReactDatetime
                            value={startDate1}
                            isValidDate={disableFutureDt}
                           onChange={(event)=> handleDateChange1(event,"startDate")}
                            inputProps={{
                              className: "form-control inpt",
                              placeholder: "From date",
                              id:"fromDate",
                              input:'false',
                              autoComplete : 'off'
                            }}
                            dateFormat ={"DD/MM/YYYY"}
                            closeOnSelect
                          />
                  </Col>
                  <Col>
                      <div className="slctDy">   
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          placeholder="Order by"
                          name="order_by"
                          id="order_by"
                          options={orderByOptions}
                          value = {orderByOptions?orderByOptions.filter(option=> option.value == formdetails.order_by):""}
                          onChange={handleSelect}
                        /> 
                      </div>
                  </Col>
                  <Col>
                    <div className="actBx">
                        <button
                          type="button"
                          className="btn btn-smb-grn"
                          onClick={() => {
                            console.log("hit go");
                            console.log(analyticsMetrix1 + " and :" + analyticsMetrix2)
                            fetchAnalytics(analyticsMetrix1, analyticsMetrix2);
                          }}
                        >Go </button>

                        <button
                          type="button"
                          className="btn btn-rst-gry"
                          onClick={() => {
                            handleReset();
                          }}
                        >Reset</button>
                    </div>
                  </Col>
                  {isValidDate && <p style={{ color: "red" }}>{dateMessage}</p>}
                </div>
              </div>
            </div>
          </Col>
        
          <Col md="12">
            <div className="dp-box card">
              <div className="card-body">
                <div className="viGraph-cardBox">

                  <div className="videoActnTab">
                    
                      {activeButton1?<button
                        title="Plays vs Ad impression"
                        style={{ color: "black" }}
                        type="button"
                        className="btn btn-smb-tab"
                        onClick={callPlaysImpression}
                      >
                        Plays vs Ad impressions
                      </button>:<button
                        title="Plays vs Ad impression"
                        style={{ color: "black" }}
                        type="button"
                        className="btn btn-gry-tab"
                        onClick={callPlaysImpression}
                      >
                        Plays vs Ad impressions
                      </button>}
                    
                    {activeButton2?<button
                        title="Plays vs Ad impression"
                        style={{ color: "black" }}
                        type="button"
                        className="btn btn-smb-tab"
                        onClick={callPLaysCompletes}
                      >
                        Plays vs Completes
                      </button>:<button
                        title="Plays vs Ad impression"
                        style={{ color: "black" }}
                        type="button"
                        className="btn btn-gry-tab"
                        onClick={callPLaysCompletes}
                      >
                        Plays vs Completes
                      </button>}
                    
                    {activeButton3?<button
                        title="Plays vs Ad impression"
                        style={{ color: "black" }}
                        type="button"
                        className="btn btn-smb-tab"
                        onClick={callPlaysEmbeds}
                      >
                        Plays vs embeds
                      </button>:<button
                        title="Plays vs Ad impression"
                        style={{ color: "black" }}
                        type="button"
                        className="btn btn-gry-tab"
                        onClick={callPlaysEmbeds}
                      >
                        Plays vs Embeds
                      </button>}
                  </div>

                  {fetched ? (
                    <div className="grCard">
                        <Card>
                          <CardBody>
                          <Line
                          options={options}
                          data={{
                            labels: labels,
                            datasets: [
                              {
                                label: label1,
                                data: data1,
                                borderColor: "rgb(255, 99, 132)",
                                lineTension: 0,
                              },
                              {
                                label: label2,
                                data: data2,
                                borderColor: "rgb(53, 162, 235)",
                                lineTension: 0,
                              },
                            ],
                          }}
                        />
                          </CardBody>
                        </Card>
                    </div>
                  ) : (
                    <div className="loDer">
                      {showLoader ? <Spinner></Spinner> : ""}
                    </div>
                  )}
                </div>
              </div>
            </div>

          </Col>

        </Row>

        
        <Row>
          <Col sm="12" md="6" lg="6">
            <div class="dp-box videoContnr">
              <Card style={{height:'550px'}}>
                <CardBody>
                  <div class="tBdata-list mt-30">
                    <Table>
                      <thead>
                          <th className="tdCenter">S.no</th>
                          <th className="tdCenter" title="Media Id">Media Id </th>
                          <th className="tdCenter" title="Title">Title</th>
                          <th className="tdCenter" title="Plays">Plays</th>
                      </thead>

                      {showLoader1 && (
                        <tbody>
                          <tr>
                            <td colSpan="4" className="text-center">
                              <Spinner animation="border" role="status"></Spinner>
                            </td>
                          </tr>
                        </tbody>
                      )}
                      {videoPlaysData && videoPlaysData.length !== 0 && !showLoader1 && (
                        <tbody>
                          {videoPlaysData.map((e, index) => {
                      
                          
                            
                            
                              return (
                                <tr key={index}>
                                  <td className="tdCenter">{index+1}</td>
                                  <td className="tdCenter">{e[0]}</td>
                                  <td className="tdCenter">
                                    {myNewMap.get(e[0])===undefined || myNewMap.get(e[0])===null ? <span style={{color:'red'}}>Media Deleted</span>:myNewMap.get(e[0])}
                                  </td>
                                  <td className="tdCenter">{e[1]}</td>
                                </tr>
                              );
                          
                          
                          })}
                        </tbody>
                      )}
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </div>
          </Col>

          <Col md="6" lg="6" sm="12">
            <div class="dp-box videoContnr">
                <Card>
                  <CardBody>
                    <div class="tBdata-list mt-30">
                      <Table>
                        <thead>
                            <th className="tdCenter"> Device Breakdown </th>
                        </thead>
                      </Table>
              
                              {!showLoader1? 
                            
                            
                              <Pie data={pieOptions}
                              options={{
                                plugins: {
                                  datalabels: {
                                    display: true,
                                  },
                                },
                              }}
                              
                              />:
                              
                                <center>

                                  <Spinner></Spinner>
                                </center>
                              
                          
                              }
                    </div>       
                  </CardBody>
                </Card>
              
                <Card>
                  <CardBody>
                    <div class="tBdata-list mt-30">
                      <Table>
                        <thead>
                            <th className="tdCenter" title="Country"> Domain </th>
                            <th className="tdCenter" title="Plays">Plays </th>
                        </thead>
                        {showLoader1 && (
                          <tbody>
                            <tr>
                              <td colSpan="6" className="text-center">
                                <Spinner
                                  animation="border"
                                  role="status"
                                ></Spinner>
                              </td>
                            </tr>
                          </tbody>
                        )}
                        {domainPlaysData && domainPlaysData.length !==0 && !showLoader1 && (
                          <tbody>
                            {domainPlaysData.map((e, index) => {
                              return (
                                <tr key={index}>
                                  <td className="tdCenter">{(e[0])}</td>
                                  <td className="tdCenter">{e[1]}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        )}
                      </Table>
                    </div>
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    <div class="tBdata-list mt-30">
                      <Table>
                        <thead>
                          <th className="tdCenter" title="Country">Country</th>
                          <th className="tdCenter" title="Plays">Plays</th>
                        </thead>
                        {showLoader1 && (
                          <tbody>
                            <tr>
                              <td colSpan="6" className="text-center">
                                <Spinner
                                  animation="border"
                                  role="status"
                                ></Spinner>
                              </td>
                            </tr>
                          </tbody>
                        )}
                        {countryPlaysData && countryPlaysData.length !==0 && (
                          <tbody>
                            {countryPlaysData.map((e, index) => {
                              return (
                                <tr key={index}>
                                  <td className="tdCenter">{countryMap.get(e[0])}</td>
                                  <td className="tdCenter">{e[1]}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        )}
                      </Table>
                    </div>
                  </CardBody>
                </Card>
                
            </div>
          </Col>

        </Row>
      </div>
    </div> 
    </>
  );
};

export default SingleMediaAnalytics;

import axios from 'axios';

const enableIOSApp = async (formdata) => {
    const response = await axios.post('/api/sortd-apps/enable-ios-app',formdata);
    return response.data; 
}
const getIOSAppInfo = async (projectId) => {
    let formdata = {
        projectId
    };
    const response = await axios.post('/api/sortd-apps/get-ios-app-info',formdata);
    return response.data;
}
const saveIOSAppInfo = async (formdata) => {
    const response = await axios.post('/api/sortd-apps/save-ios-app-info',formdata);
    return response.data;
}
const getAppleAccountUsers = async (projectId) =>{
    let formdata = {
        projectId
    }
    const response = await axios.post('/api/sortd-apps/get-apple-account-user',formdata);
    return response.data;
} 
const addUserToAppleAccount = async (formdata) =>{
    const response = await axios.post('/api/sortd-apps/add-user-to-apple-account',formdata);
    return response.data;
} 
const addUserAsIOSAppTester = async (formdata) =>{
    const response = await axios.post('/api/sortd-apps/add-user-as-ios-app-tester',formdata);
    return response.data;
} 
const getIOSAppTesters = async(formdetails,page,records) =>{
    const formdata = {
        filters : formdetails,
        page : page,
        records : records
    }
    const response = await axios.post('/api/sortd-apps/get-ios-app-testers',formdata);
    return response.data;
}
export default   {
    enableIOSApp,
    getIOSAppInfo,
    saveIOSAppInfo,
    getAppleAccountUsers,
    addUserToAppleAccount,
    addUserAsIOSAppTester,
    getIOSAppTesters

}
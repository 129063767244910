import React, { useEffect, useState, useContext } from "react";
import contentService from "../../services/api/content-service";
import {useHistory} from 'react-router-dom';
import ProjectContext from '../../ProjectContext';
import Pagination from "react-js-pagination";
import moment from 'moment';
import './WPWebstories.css'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardImg,
  CardFooter,
  CardTitle,
  CardText,
  Label,
  FormGroup,
  Form,
  Input,
  Alert,
  Spinner,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";
import defaultImage from "../../assets/img/no_image.png";
import whatsappImage from "../../assets/img/whatsapp.png";
import facebookImage from "../../assets/img/facebook.png";
import twitterImage from "../../assets/img/twitter.png";
import telegramImage from "../../assets/img/telegram.png";
import copyToClipboardImage from "../../assets/img/copy.png";
import Select from "react-select";
const WPWebstories = (props)=> {
  const projectDetails = useContext(ProjectContext);
  const history = new useHistory();
  
  if(projectDetails.id==''){
    history.push("/project/allprojects");
  }
  const projectId = projectDetails.id;
  const [formdetails, setFormDetails] = useState({projectId ,title : '', status : 1});

  const [webstories, setWebstories] = useState(false);
  const [totalWebstories,setTotalWebstories] = useState(0);
  const [activePage,setActivePage] = useState(1);
  const [renderComponent,setRenderComponent] = useState(false);
  const [showPopup,setShowPopup] = useState({alert:null});
  const [search,setSearchDetails] = useState({projectId ,title : '', status : ''});
  const [reloadComponent,setReloadComponent] = useState(0);
  const records = 8;
  const [firstRecord, setFirstRecord] = useState(0);
  const [lastRecord, setLastRecord] = useState(0);
  const statusArray = [{"value":"all",label : "All"},{"value":1,label:"Active"},{"value":0,label:"Deleted"}];
  useEffect(()=>{
        (async ()=>{
            const response = await contentService.getWPWebstories(formdetails,activePage,records);
            if(response.status && response.data) {
                console.log(response.data);
                let webstories = response.data.webstories;
                let webstoriesArray =[];
                let size = 4;
                for (let i=0; i<webstories.length; i+=size) {
                    webstoriesArray.push(webstories.slice(i,i+size));
                }
                setWebstories(webstoriesArray);
                setTotalWebstories(response.data.count);
                if(webstories && webstories.length) {
                    setFirstRecord((activePage-1)*records+1);
                    setLastRecord((activePage-1)*records+ webstories.length);
                }else{
                    setFirstRecord(0);
                    setLastRecord(0);
                }

            }
            setRenderComponent(true);
        })();
    },[reloadComponent]);
    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
        setReloadComponent(Math.random());
    }
    const handleCardClick = (details) => {
        console.log(details);
        if(details.share_url) {
            window.open(details.share_url, '_blank');
        }      
    }
    const onImageError = (event) => {
        event.target.src = defaultImage;
    }
        const handleSubmit = function(e){  searchWebstories();e.preventDefault() }

    const searchWebstories = () => {
            console.log("Formdetails:",formdetails);
            setActivePage(1);
            setSearchDetails(formdetails);
            setReloadComponent(Math.random());
    }
  const resetForm = () => {
    setActivePage(1);
    setFormDetails({
      ...formdetails,
      title : '',
      status : 1
    });
    setSearchDetails({
      title : '',
      status : 1
    });
    setReloadComponent(Math.random());
  }

  const handleChange = (event)=>{
      console.log("Event:",event);
    setFormDetails({
      ...formdetails,
      [event.id]: event.value,
    });
  }
 const handleTitleChange = (event) => {
    setFormDetails({
      ...formdetails,
      title: event.target.value,
    });
  };
 const handleStatusChange = (event)=>{
    setFormDetails({
      ...formdetails,
      status: event.value,
    });
  }
  const handleWhatsappShare = (data) => {
    const isMobileDevice = window.navigator.userAgent.toLowerCase().includes("mobi");
    if(isMobileDevice) {
        window.open(`whatsapp://send?text=${data.share_url}`,'_blank')
    }else {
        // window.open(`https://wa.me/?text=${data.share_url}`,'_blank')
        window.open(`https://web.whatsapp.com://share?text=${data.share_url}`,'_blank')
    }
  }
    return (
        <div className="content mt-30">
            <Container>
                <Card>
                    <CardTitle tag="h4" className="text-center ">
                        Wordpress Webstories   
                    </CardTitle>

                    {!renderComponent?
                        <div className="text-center">
                            <Spinner className="text-center" animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                        </div>
                    :null}
                    <Form
                        id="webstoryFilters"
                        action=""
                        className="form"
                        method="POST"
                        onSubmit={handleSubmit}
                        >
                        <div className="">
                            <Row style={{paddingLeft:"12px",paddingRight:"22px"}}>
                                <Col md="4">
                                    <Select
                                        className="react-select primary"
                                        classNamePrefix="react-select"
                                        placeholder="Select Status .."
                                        name="status"
                                        
                                        id="status"
                                        options={statusArray}
                                        value={statusArray && (formdetails.status!=="")?statusArray.filter(option=> option.value === formdetails.status):""}
                                        onChange={handleStatusChange}
                                    />
                                </Col>
                                <Col md="4">
                                    <Input
                                        value={formdetails.title}
                                        type="text"
                                        name="title"
                                        id="title"
                                        onChange={handleTitleChange}
                                        placeholder="Search by Webstory title"
                                    />
                                </Col>
                                <Col md="4">
                                    <div>
                                        <Button className="btn-smb" type="submit">
                                            Search
                                        </Button>
                                        <Button
                                            className="btn-smb btn-rst"
                                            type="button"
                                            onClick={resetForm}
                                            >
                                        Reset 
                                        </Button>
                                    </div> 
                                </Col>
                            </Row>
                        
                            
                             
                        </div>
                    </Form>
                    {/* {webstories && webstories.length? */}
                    {totalWebstories > 0 ? 
                        <div className="pagBox">
                            <hr/>
                                <div className="pagNo">
                                [ {firstRecord} - {lastRecord} of {totalWebstories} ]
                                </div>
                            <div className="pagDgt">
                                
                            <Pagination
                            activePage={activePage}
                            itemsCountPerPage={records}
                            totalItemsCount={totalWebstories}
                            pageRangeDisplayed={5}
                            onChange={handlePageChange.bind(this)}
                            itemClass="page-item"
                            linkClass="page-link"
                            />
                            </div>
                        </div>
                    : null}
                    {/* // :""} */}
                    {webstories && webstories.length?webstories.map((details, index)=>(
                        <Row className="strRow">
                        {details.map((data,index) => (
                            <Col  sm="3">
                                <Card  className={data.status?"stryCard":"stryCard deletedStry"}   >
                                <CardBody className="cardBody">
                                    <CardImg title="Click here to view"  onClick= {()=>{handleCardClick(data)}} onError ={onImageError} variant="top" src={data.image} style={{"height":"250px"}} />
                                    <p  title={data.title} className="wrWord">{data.title}</p>   
                                </CardBody>
                                <CardFooter>
                                    <div>
                                    <div class="social_icons">
                                        <a id="whatsapp" title ="Share on Whatsapp" target="_blank" data-action="share/whatsapp/share" href={`https://web.whatsapp.com/send?text=${data.share_url}`} > 
                                            <img width="27" height="27" alt="Whatsapp share" src={whatsappImage} />
                                        </a>
                                        <a id="facebook" title ="Share on Facebook" target="_blank" href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(data.share_url)}`}> 
                                            <img width="24" height="24" alt="facebook share" src={facebookImage} /> 
                                        </a>
                                        <a id="twitter" title ="Share on Twitter" target="_blank" href={`https://twitter.com/intent/tweet?text=${data.title}&url=${data.share_url}`}> 
                                            <img width="24" height="24" alt="tweet button" src={twitterImage} /> 
                                        </a>
                                        <a id="telegram" title ="Share on Telegram" target="_blank" href={`https://t.me/share/url?url=${data.share_url}&text=${data.title}`}>
                                            <img width="24" height="24" alt="telegram share" src={telegramImage} /> 
                                        </a>
                                        {/* <a id="shareButton" title ="Copy to clipboard" href="javascript:;"> 
                                            <img width="24" height="24" alt="Native Share" src={copyToClipboardImage} />
                                        </a> */}
                                    </div>
                                    <small className="text-muted">{moment(data.published_on).fromNow()}</small>
                                    </div>
                                </CardFooter>
                            </Card>
                            </Col>
                        ))}
                        </Row>
                    )):
                    <div className="text-center">
                        <CardTitle tag="h4" >No Webstory Found</CardTitle>
                    </div>
                    }

                </Card>
            </Container>
            <div
            className="full-page-background"
            style={{
                backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
            }}
            />
        </div>
        
    );
}
export default WPWebstories;
import React, { useEffect, useState, useContext } from "react";
import { useHistory } from 'react-router-dom'
import ProjectContext from "../../ProjectContext";
import {
    Card,
    CardTitle,
    CardHeader,
    CardBody,
    Row,
    Col,
    Button,
    Label,
    Table,
    Input,
    Container,
    Form,
    FormGroup,
    Alert,
    Spinner
} from "reactstrap";

import './ctv.css'
import Select from 'react-select';
import ImageUplaod from '../ctv/UploadImage';
import formValidations from "../../variables/form-validations";
import ctvAppService from "../../services/api/ctv-app-service";

const AddCategory = (props) => {
    const projectDetails = useContext(ProjectContext);
    const history = useHistory();
    if (projectDetails.id == '') {
        history.push("/project/allprojects");
    }
    const projectId = projectDetails.id;

    const [formdetails, setFormDetails] = useState({ project_id: projectId, name: "", alias: "", desc: "", parent_id: null, webpage_link: "", icon_url: "",banner_image:"", order: 1,content_type:1 });
    const [catList, setCatList] = useState([]);
    const [categories, setCategories] = useState([]);
    const [showLoader, setShowLoader] = useState(false);
    const [showSaveButton, setShowSaveButton] = useState(true);
    const [alert, setAlert] = useState({ show: false, message: '', type: '' });
    const [fieldStates, setFieldStates] = useState({});

    const imageDetails = {
        label: "Thumbnail",
        file_type: "image/png,image/jpeg,image/jpg,image/webp,image/tiff,image/x-icon,image/aviff"
    }

    const contentTypeArray = [
        {
            value: 1,
            label: "Video",
        },
        {
            value: 2,
            label: "Shorts",
        }
    ]

    useEffect(() => {
        (async () => {
            let catData = await ctvAppService.getVideoCategories({ projectId ,status:1});
            if (catData.status && catData.data) {
                setCategories(catData.data.catList);
                let catArr = [];
                Object.keys(catData.data.catList).forEach(function (key) {
                    let category = {};
                    category["label"] = catData.data.catList[key].name;
                    category["value"] = catData.data.catList[key]._id;
                    catArr.push(category);
                })
                setCatList(catArr);
            }
        })()
    }, [])

    const handleChange = (event) => {
        setFormDetails({
            ...formdetails,
            [event.target.id]: event.target.value,
        });
        fieldStates['error_' + event.target.id] = 'has-success';
        fieldStates['error_msg_' + event.target.id] = "";
        setFieldStates({
            ...fieldStates,
            fieldStates,
        })
    }

    const handleImageChange = (id, image) => {
        console.log("id: ", id);
        console.log("image: ", image);
        setFormDetails({
            ...formdetails,
            [id]: image.value,
        })
    }

    const handleSelectChange = (event) => {
        setFormDetails({
            ...formdetails,
            parent_id: event.value,
        });

        fieldStates['error_parent_id'] = 'has-success';
        fieldStates['error_msg_parent_id'] = "";
        setFieldStates({
            ...fieldStates,
            fieldStates,
        })
    }

    const handleCnttypeChange = (event) => {
        setFormDetails({
            ...formdetails,
            content_type: event.value,
        });

        fieldStates['error_content_type'] = 'has-success';
        fieldStates['error_msg_content_type'] = "";
        setFieldStates({
            ...fieldStates,
            fieldStates,
        })
    }

    const handleSubmit = function (e) { validateForm(); e.preventDefault() }

    const validateForm = () => {
        let validations = true;
        if (formdetails.name.trim() === "") {
            fieldStates["error_name"] = "has-danger";
            fieldStates["error_msg_name"] = "This field is required";
            validations = false;
        } else if (formdetails.name && formValidations.verifySpecialCharacters(formdetails.name)) {
            fieldStates["error_name"] = "has-danger";
            fieldStates["error_msg_name"] = "Please enter a valid name";
            console.log("in else: ", fieldStates)

            validations = false;
        } else {
            fieldStates["error_name"] = "has-success";
            fieldStates["error_msg_name"] = "";
        }

        if (formdetails.alias.trim() === "") {
            fieldStates["error_alias"] = "has-danger";
            fieldStates["error_msg_alias"] = "This field is required";
            validations = false;
        } else if (formdetails.alias && formValidations.verifyCategoryAlias(formdetails.alias)) {
            console.log("formValidations.verifySpecialCharacters(formdetails.alias): ",formValidations.verifySpecialCharacters(formdetails.alias))
            fieldStates["error_alias"] = "has-danger";
            fieldStates["error_msg_alias"] = "Please enter a valid alias";
            validations = false;
        } else if (categories) {
            Object.keys(categories).forEach(function (key) {
                let existingCatAlias = categories[key].alias;
                if (existingCatAlias === formdetails.alias.trim()) {
                    fieldStates["error_alias"] = "has-danger";
                    fieldStates["error_msg_alias"] = "Category already exist with same alias.";
                    validations = false;
                }
            })
        } else {
            fieldStates["error_name"] = "has-success";
            fieldStates["error_msg_name"] = "";
        }

        if(formdetails.content_type < 1){
            fieldStates["error_content_type"] = "has-danger";
            fieldStates["error_msg_content_type"] = "This field is required";
            validations = false;
        }else{
            fieldStates["error_content_type"] = "has-success";
            fieldStates["error_msg_content_type"] = "";
        }

        if (formdetails.desc.trim()==="" && formValidations.verifySpecialCharacters(formdetails.desc)) {
            fieldStates["error_desc"] = "has-danger";
            fieldStates["error_msg_desc"] = "Please enter a valid desc";
            validations = false;
        } else {
            fieldStates["error_desc"] = "has-success";
            fieldStates["error_msg_desc"] = "";
        }

        setFieldStates({
            ...fieldStates,
            fieldStates,
        })
        return validations;
    }

    const addCategory = async () => {
        if (validateForm()) {
            setShowLoader(true);
            setShowSaveButton(false);
            const categoryAdded = await ctvAppService.addCategory(formdetails);
            if (categoryAdded.status && categoryAdded.data) {
                setTimeout(() => {
                    setShowLoader(false);
                    setAlert({ show: true, type: "success", message: "Category Added Successfully" })
                }, 1000);
                setTimeout(() => {
                    setAlert({ show: false, type: "", message: "" });
                    setShowSaveButton(true);
                    history.push(`/ctv/dashboard?project=${projectDetails.slug}#category`)
                }, 3000)
            } else {
                setTimeout(() => {
                    setShowLoader(false);
                    // setShowSaveButton(true);
                    setAlert({ show: true, type: "danger", message: "Category Could not be added" });
                }, 1000)
                setTimeout(() => {
                    setAlert({ show: false, type: "", message: "" });
                }, 3000)
            }
            
        }
    }

    return (
        <div className="container mt-30">
            <Container>
                <Row>
                    <Col className="ml-auto mr-auto" md="12">
                        <Card className="card-signup text-center">
                            <CardHeader>
                                <CardTitle tag="h4">
                                    <Row>
                                        <Col>
                                            Add Category
                                        </Col>
                                    </Row>
                                </CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Form className="form-horizontal" action="" id="ctvCategoriesForm" method="POST" onSubmit={handleSubmit}>
                                    <Row>
                                        <Label sm="2">Name<span className="text-danger"> * </span></Label>
                                        <Col sm="7">
                                            <FormGroup className={fieldStates["error_name"]}>
                                                <Input
                                                    type="text"
                                                    id="name"
                                                    name="name"
                                                    value={formdetails.name}
                                                    onChange={handleChange}
                                                />
                                                {
                                                    fieldStates['error_name'] === 'has-danger' ?
                                                        <label className="error">{fieldStates['error_msg_name']}</label>
                                                        :
                                                        null
                                                }
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Label sm="2">Alias<span className="text-danger"> * </span></Label>
                                        <Col sm="7">
                                            <FormGroup className={fieldStates["error_alias"]}>
                                                <Input
                                                    type="text"
                                                    id="alias"
                                                    name="alias"
                                                    value={formdetails.alias}
                                                    onChange={handleChange}
                                                />
                                                {
                                                    fieldStates['error_alias'] === 'has-danger' ?
                                                        <label className="error">{fieldStates['error_msg_alias']}</label>
                                                        :
                                                        null
                                                }
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Label sm="2">Select Parent Category</Label>
                                        <Col sm="7">
                                            <FormGroup className={fieldStates["error_parent_id"]}>
                                                <Select
                                                    className="react-select primary"
                                                    classNamePrefix="react-select"
                                                    id="parent_id"
                                                    name="parent_id"
                                                    options={catList}
                                                    value={formdetails.parent_id !== "" ? catList.filter(option => option.value === formdetails.parent_id) : ""}
                                                    onChange={handleSelectChange}
                                                />
                                                {
                                                    fieldStates["error_parent_id"] === 'has-danger' ?
                                                        <label className="error">{fieldStates['error_msg_parent_id']}</label>
                                                        :
                                                        null
                                                }
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Label sm="2">Select Content Type</Label>
                                        <Col sm="7">
                                            <FormGroup className={fieldStates["error_content_type"]}>
                                                <Select
                                                    className="react-select primary"
                                                    classNamePrefix="react-select"
                                                    id="content_type"
                                                    name="content_type"
                                                    options={contentTypeArray}
                                                    value={formdetails.content_type !== "" ? contentTypeArray.filter(option => option.value === formdetails.content_type) : ""}
                                                    onChange={handleCnttypeChange}
                                                />
                                                {
                                                    fieldStates["error_content_type"] === 'has-danger' ?
                                                        <label className="error">{fieldStates['error_msg_content_type']}</label>
                                                        :
                                                        null
                                                }
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Label sm="2">Description</Label>
                                        <Col sm="7">
                                            <FormGroup className={fieldStates["error_desc"]}>
                                                <Input
                                                    type="textarea"
                                                    id="desc"
                                                    name="desc"
                                                    value={formdetails.desc}
                                                    onChange={handleChange}
                                                />

                                                {fieldStates["error_desc"] === 'has-danger' ?
                                                    <label className="error">{fieldStates['error_msg_desc']}</label>
                                                    :
                                                    null
                                                }
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Label sm="2">Banner Icon</Label>
                                        <Col sm="7">
                                            <FormGroup className={fieldStates["error_banner_image"]}>
                                                <ImageUplaod
                                                    projectId={projectId}
                                                    imageDetails={imageDetails}
                                                    elementValue={formdetails.banner_image}
                                                    id="banner_image"
                                                    handleEnumChange={(id, image) => handleImageChange(id, image)}
                                                />
                                                <Input
                                                    type="hidden"
                                                    id="banner_image"
                                                    name="banner_image"
                                                    value={formdetails.banner_image}
                                                    onChange={handleChange}
                                                />
                                                {fieldStates["error_banner_image"] === 'has-danger' ?
                                                    <label className="error">{fieldStates['error_msg_banner_image']}</label>
                                                    :
                                                    null
                                                }
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Label sm="2">Icon</Label>
                                        <Col sm="7">
                                            <FormGroup className={fieldStates["error_icon_url"]}>
                                                <ImageUplaod
                                                    projectId={projectId}
                                                    imageDetails={imageDetails}
                                                    elementValue={formdetails.icon_url}
                                                    id="icon_url"
                                                    handleEnumChange={(id, image) => handleImageChange(id, image)}
                                                />
                                                <Input
                                                    type="hidden"
                                                    id="icon_url"
                                                    name="icon_url"
                                                    value={formdetails.icon_url}
                                                    onChange={handleChange}
                                                />
                                                {fieldStates["error_icon_url"] === 'has-danger' ?
                                                    <label className="error">{fieldStates['error_msg_icon_url']}</label>
                                                    :
                                                    null
                                                }
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    {
                                        showSaveButton ?
                                            <div>
                                                <Button
                                                    className="btn-round"
                                                    color="info"
                                                    type="button"
                                                    onClick={addCategory}
                                                >
                                                    Save
                                                </Button>
                                                <Button
                                                    className="btn-round"
                                                    color="danger"
                                                    type="button"
                                                    onClick={(event) => history.push(`/ctv/dashboard?project=${projectDetails.slug}#category`)}
                                                >Cancel</Button>
                                            </div>

                                            : null
                                    }
                                    {
                                        alert.show ?
                                            <Alert color={alert.type}>
                                                {alert.message}
                                            </Alert>
                                            :
                                            ''
                                    }
                                    {
                                        showLoader ?
                                            <Spinner animation="border" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </Spinner>
                                            :
                                            ''
                                    }
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default AddCategory;
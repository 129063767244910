import axios from "axios";

const createProject = async (formdata) => {
    const response = await axios.post('/api/projects/create-project', formdata);
    return response.data;
}

const saveProjectSettings = async (formdata) => {
    const response = await axios.post('/api/projects/save-project-settings', formdata);
    return response.data;
}

const getExistingProjects = async () => {
    const response = await axios.post('/api/projects/get-existing-projects');
    return response.data;
}

const getTemplates = async () => {
    const response = await axios.post('/api/projects/get-templates');
    return response.data;
}

const getProjectDetails = async (projectId) => {
    const formdata = {
        projectId: projectId
    }
    const response = await axios.post('/api/projects/get-project-details', formdata);
    return response.data;
}

const getProjectDetailsBySlug = async (projectSlug) => {
    const formdata = {
        projectSlug: projectSlug
    }
    const response = await axios.post('/api/projects/get-project-details-byslug', formdata);
    return response.data;
}

const getLoggedInUser = async () => {
    const response = await axios.post('/api/projects/get-user-details');
    return response.data;
}

const getProjectUsers = async (projectId) => {
    const formdata = {
        projectId: projectId
    }
    const response = await axios.post('/api/projects/get-project-users', formdata);
    return response.data;
}

const getProjectInvitedUsers = async (projectId) => {
    const formdata = {
        projectId: projectId
    }
    const response = await axios.post('/api/projects/get-project-invited-users', formdata);
    return response.data;
}

const suspendUserFromProject = async (projectId, userId) => {
    const formdata = {
        projectId: projectId,
        userId: userId
    }
    const response = await axios.post('/api/projects/suspend-project-user', formdata);
    return response.data;
}

const restoreUserToProject = async (projectId, userId) => {
    const formdata = {
        projectId: projectId,
        userId: userId
    }
    const response = await axios.post('/api/projects/restore-project-user', formdata);
    return response.data;
}

const inviteUser = async (formdata) => {
    const response = await axios.post('/api/projects/invite-user', formdata);
    return response.data;
}

const resendInviteToProject = async (inviteId, projectId) => {
    const formdata = {
        inviteId: inviteId,
        projectId: projectId,
    }
    const response = await axios.post('/api/projects/resend-invite', formdata);
    return response.data;
}

const getInvitationDetails = async (accessToken, projectId) => {
    const formdata = {
        accessToken: accessToken,
        projectId: projectId
    }
    const response = await axios.post('/api/projects/invite-details', formdata);
    return response.data;
}

const acceptInviteToProject = async (inviteId) => {
    const formdata = {
        inviteId: inviteId
    }
    const response = await axios.post('/api/projects/accept-invite', formdata);
    return response.data;
}

const getProjectDomain = async (projectId) => {
    const formdata = {
        projectId: projectId
    }
    const response = await axios.post('/api/projects/get-project-domain', formdata);
    return response.data;
}

const getProjectStorageMedia = async (projectId) => {
    const formdata = {
        projectId: projectId
    }
    const response = await axios.post('/api/projects/get-project-storage-media', formdata);
    return response.data;
}

const getProjectTemplates = async (projectId) => {
    const formdata = {
        projectId: projectId
    }
    const response = await axios.post('/api/projects/get-project-templates', formdata)
    return response.data;
}

const uploadFile = async (projectId, fieldId, imageData, contentType) => {
    let data = { projectId: projectId, fieldId: fieldId, imageData: imageData, contentType }
    const response = await axios.post('/api/projects/upload-file', data);
    return response.data;
}
const isPlanCompatibleForProject = async (projectId, planId) => {
    let formdata = {
        projectId,
        planId
    }
    const response = await axios.post('/api/projects/is-plan-compatible-for-project', formdata);
    return response.data;
}
const getPublisherByProjectId = async (projectId) => {
    let formdata = {
        projectId
    }
    const response = await axios.post('/api/projects/get-publisher-by-project-id', formdata);
    return response.data;
}
const getActiveContractByProjectId = async (projectId) => {
    let formdata = {
        projectId: projectId
    }
    const response = await axios.post('/api/projects/get-active-contract-by-projectid', formdata);
    return response.data;
}
const getPublisherMultiProjects = async (projectId) => {
    const formdata = {
        projectId: projectId
    }
    const response = await axios.post('/api/projects/get-publisher-multi-projects', formdata);
    return response.data;
}
const verifyPublicHostSetup = async (projectId) => {
    const formdata = {
        projectId: projectId
    }
    const response = await axios.post('/api/projects/verify-public-host-setup', formdata)
    return response.data;
}

const getPublisherExistingProjects = async() =>{
    const response = await axios.post('/api/projects/get-publishers-existing-projects');
    return response.data;
}
const analyseUrl = async (url) => {
    const response = await axios.post('/api/projects/analyse-url', {url});
    return response.data;
}
export  default {
    createProject,
    saveProjectSettings,
    getExistingProjects,
    getTemplates,
    getProjectDetails,
    getProjectDetailsBySlug,
    getLoggedInUser,
    getProjectUsers,
    suspendUserFromProject,
    restoreUserToProject,
    inviteUser,
    getProjectInvitedUsers,
    resendInviteToProject,
    getInvitationDetails,
    acceptInviteToProject,
    getProjectDomain,
    getProjectStorageMedia,
    getProjectTemplates,
    uploadFile,
    isPlanCompatibleForProject,
    getPublisherByProjectId,
    getActiveContractByProjectId,
    getPublisherMultiProjects,
    getPublisherExistingProjects,
    verifyPublicHostSetup,
    analyseUrl,
}
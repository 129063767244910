import React, { useEffect, useState } from "react";
import ConfigForm from "./ConfigForm";
import Scrollspy from 'react-scrollspy'

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col,
    NavItem,
    NavLink,
    Nav,
    TabContent,
    TabPane,
} from "reactstrap";



const ConfigVerticalMenu = (props) => {

    // var activeTab = false;
    // var i=0;
    // Object.keys(props.data.items).forEach(function(key) {
    //   if(i==0){
    //     activeTab = key;
    //   }
    //   i++;
    // });

    // const [verticalTab, setVerticalTab] = useState(activeTab);
    const [verticalTab, setVerticalTab] = useState("");
    useEffect(() => {
        const url = new URL(window.location.href);
        const subTab = url.hash.substring(1);
        const configKeys = Object.keys(props.data.items);

        if (configKeys.includes(subTab) && props.data.items[subTab].visibility !== "private") {
            setVerticalTab(subTab);
            const targetElement = document.getElementById(subTab);
            if (targetElement) {
                targetElement.scrollIntoView({ behavior: "auto" });
            }
        } else {
            for (const key of configKeys) {
                if (props.data.items[key].visibility !== "private") {
                    setVerticalTab(key);
                    break;
                }
            }
        }

    }, [props.data.items]);
    

    var navItems = [];
    var tabPanes = [];
    var navItemsArray = [];
    var configSchema = props.data.items;
    var i = 0;

    Object.keys(configSchema).forEach(function (key) {
        navItemsArray.push(key);
        navItems.push(<NavItem>
            <NavLink
                data-toggle="tab"
                href={'#' + key}
                role="tab"
                className={
                    (verticalTab === key)
                        ? "active"
                        : ""
                }
                onClick={() =>
                    setVerticalTab(key)
                }
            >
                {configSchema[key].label}
            </NavLink>
        </NavItem>);

        tabPanes.push(<TabPane id={key} tabId={verticalTab} role="tabpanel">
            <CardTitle tag="h3" className="heding_tab_h3">{configSchema[key].label}</CardTitle>
            {configSchema[key].helptext ? (
                <label className="text-default smRt">
                    {configSchema[key].helptext}
                </label>
            ) : (
                ""
            )}{" "}
            <hr></hr>
            <ConfigForm projectId={props.projectId} fieldStates={props.fieldStates} setFieldStates={props.setFieldStates} validateConfigField={props.validateConfigField} categories={props.categories} setCurrentTabConfig={props.setCurrentTabConfig} currentTabConfig={props.currentTabConfig} data={configSchema[key]} subparent={props.menutype} parent={key} />
            <hr></hr>
        </TabPane>);
        i++;
    });

    const scrollUpdated = (event) => {
        if (event) {
            setVerticalTab(event.id);
        }

    }


    return (
        <Row>
            <Col md="3">
                <div className="nav-tabs-navigation verical-navs p-0">
                    <div className="nav-tabs-wrapper">
                        {/* <Nav
                className="flex-column nav-stacked"
                role="tablist"
                tabs
              >
                {navItems}
              </Nav> */}
                        <Scrollspy className="flex-column nav-stacked scroll nav nav-tabs" items={navItemsArray} currentClassName="bg-light" onUpdate={(event) => scrollUpdated(event)} rootEl="#vertical-tab-content">
                            {navItems}
                        </Scrollspy>
                    </div>
                </div>
            </Col>

            <Col md="9">
                <TabContent id="vertical-tab-content" style={{ 'height': '400px', 'overflowY': 'scroll' }} activeTab={verticalTab}>
                    {tabPanes}
                </TabContent>
            </Col>
        </Row>
    );

}

export default ConfigVerticalMenu;

import React, { useEffect, useState, useContext } from "react";
import contentService from "../../services/api/content-service";
import {useHistory} from "react-router-dom";
import ProjectContext from '../../ProjectContext';

// reactstrap components
import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Spinner,
  Row,
  Col,
} from "reactstrap";

const CategoryCountWidget = (props)=> {
  const projectDetails = useContext(ProjectContext);
  const history = new useHistory();
  
  if(projectDetails.id==''){
    history.push("/project/allprojects");
  }
  const projectId = projectDetails.id;
  const [totalCategories,setTotalCategories] = useState(0);
  const [renderComponent,setRenderComponent] = useState(false);
 
  useEffect( () => {
    (async () =>{
        let categoryData = await contentService.getCategories(projectId);
        if(categoryData.status){
            let categories = categoryData.data;
            let categoryCount=0;
            if(categories.length){
                categories.forEach(function(categoryDetails) {
                    categoryCount++;
                    if(categoryDetails.sub_categories && categoryDetails.sub_categories.length){
                        let subCategories = categoryDetails.sub_categories;
                        subCategories.forEach(function(subCategoryDetails) {
                            categoryCount++;
                        })
                    }
                })

            }
            setTotalCategories(categoryCount);
            setRenderComponent(true);
        }
        
        })();
    }, []);  
 

    return (
        <Card className="card-stats zooM">
              <div className="icnBx">
                <i className="fa fa-list-alt" />
              </div>
              <div className="numbers">
                <h2 className="card-category">Categories</h2>
                <p>
                    {!renderComponent?
                    <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                  :totalCategories}
                </p>
              </div>
        </Card>
    );
  
  
}

export default CategoryCountWidget;

import React, { useEffect, useState }  from "react";
import formValidations from "../../variables/form-validations";
import authService from "../../services/api/auth-service.js";
import {useHistory} from "react-router-dom";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

const Password = (props)=> {
  const history = new useHistory();
  const [passwords, setPasswords] = useState({password:"",confirmpassword:""});
  const [passwordstate, setPasswordState] = useState("");
  const [confirmpasswordstate, setConfirmPasswordState] = useState("");
  const [passworderror, setPasswordError] = useState("");
  const [confirmpassworderror, setConfirmPasswordError] = useState("");

  const [errorstate, setErrorState] = useState('has-success');
  const [errormessage, setErrorMessage] = useState('There was an error. Please try again.');
  
  const handleChange = (event) => {
    setPasswords({
      ...passwords,
      [event.target.id]: ( event.target.value ).trim(),
    });

    if(event.target.id=='password'){
      setPasswordState("has-success");
    }

    if(event.target.id=='confirmpassword'){
      setConfirmPasswordState("has-success");
    }

    
  }

  const handleBlur = (event) => {
    setPasswords({
      ...passwords,
      [event.target.id]: ( event.target.value ).trim(),
    });

    // if(event.target.id=='password'){
    //   validatePassword();
    // }

    // if(event.target.id=='confirmpassword'){
    //   validateConfirmPassword();
    // }
  }

  const validateConfirmPassword = () => {
    if(passwords.confirmpassword===''){
      setConfirmPasswordState("has-danger");
      setConfirmPasswordError("This field is required");
      return false;
    }

    if(!formValidations.compare(passwords.password,passwords.confirmpassword)){
      setConfirmPasswordState("has-danger");
      setConfirmPasswordError("Passwords do not match.");
      return false;
    }

    setConfirmPasswordState("has-success");
    return true;
}

const validatePassword = () => { 
  if (passwords.password === '') {
    setPasswordState("has-danger");
    setPasswordError("This field is required");
    return false;
  }

  if(!formValidations.verifyLength(passwords.password,6)){
    setPasswordState("has-danger");
    setPasswordError("Password should be of minimum 6 digits.");
    return false;
  }

  setPasswordState("has-success");
  return true;
}

const validateForm = async () => {
  let passwordvalidation = validatePassword();
  let cnfrmpasswordvalidation =  validateConfirmPassword();
  if(passwordvalidation && cnfrmpasswordvalidation){
    await updatePassword();
  }
}

const updatePassword = async ()=>{
  try {
    const response = await authService.updatePassword(passwords);
    if(response.status && response.data.password_updated){
      props.setCurrentState('checklogin')
    }else{
      setErrorState("has-danger");
      setErrorMessage(response.error.message);
    }
    
  } catch (err) {}
}

    return (
     
          <Row>
          <Col className="ms-auto me-auto" lg="10" md="10">
              <Card className="card-signup text-center">
                <CardHeader>
                  <CardTitle tag="h4">Set Password</CardTitle>
                </CardHeader>
                <CardBody>
                  <Form action="" className="form" method="">
                    <InputGroup  className={passwordstate}>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-key-25" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input id="password" name="password" placeholder="Password..." type="password"  onChange={handleChange} onBlur={handleBlur} />
                      {passwordstate === "has-danger" ? (
                            <label className="error">
                              {passworderror}
                            </label>
                          ) : null} 
                    </InputGroup>

                    <InputGroup  className={confirmpasswordstate}>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-key-25" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input id="confirmpassword" name="confirmpassword" placeholder="Confirm Password..." type="password"  onChange={handleChange} onBlur={handleBlur} />
                      {confirmpasswordstate === "has-danger" ? (
                            <label className="error">
                              {confirmpassworderror}
                            </label>
                          ) : null} 
                    </InputGroup>

                    <InputGroup className={errorstate}>
                    {errorstate === "has-danger" ? (
                            <label className="error">
                              {errormessage}
                            </label>
                          ) : null}   
                          </InputGroup>

                    <Button
                    className="btn-round"
                    color="info"
                    type="button"
                    onClick={validateForm}
                  >
                    Submit
                  </Button>
                    
                  </Form>
                </CardBody>
                <CardFooter>
                  
                </CardFooter>
              </Card>
            </Col>
          </Row>
        
    );
  
}

export default Password;

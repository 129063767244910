import React, { useEffect, useState, useContext } from "react";
import ctvAppService from "../../services/api/ctv-app-service.js";
import { useHistory } from "react-router-dom";
import Switch from "react-bootstrap-switch";
import ProjectContext from '../../ProjectContext';
import ImageUpload from './UploadImage.js';
import ctvConfigSchema from './ctv-config-schema.js';
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Alert,
    Spinner,
    Container,
    Row,
    Col,
    Input
} from "reactstrap";
import formValidations from "../../variables/form-validations.js";
import ReorderSection from "./ReorderSection.js";
import Select from 'react-select';

const CTVCustomizations = (props) => {
    const projectDetails = useContext(ProjectContext);
    const history = new useHistory();
    //console.log(projectDetails);
    if (projectDetails.id == '') {
        history.push("/project/allprojects");
    }

    const projectId = projectDetails.id;

    let getProjectSlug = new URLSearchParams(props.location.search).get('project');
    if (!getProjectSlug) {
        history.push({
            search: '?project=' + projectDetails.slug
        })
    }
    const [formdetails, setFormDetails] = useState({ project_id: projectId, config: { livetvs: [] } });
    const [showLoader, setShowLoader] = useState(false);
    const [showSaveButton, setShowSaveButton] = useState(true);
    const [alert, setAlert] = useState({ show: false, message: '', type: '' });
    const [fieldStates, setFieldStates] = useState({});
    const [projectDists, setProjectDists] = useState([]);
    const [configSchema, setConfigSchema] = useState({});
    const [reorderSection, setReorderSections] = useState(false);
    const [reloadComponent, setReloadComponent] = useState(0);
    const [sections, setSections] = useState([])
    const [typeList, setTypeList] = useState([{value:"youtube",label:"Youtube"},{value:"native",label:"Native"}])
    let elements = [];
    const imageDetails = {
        label: "Thumbnail",
        file_type: "image/png,image/jpeg,image/jpg,image/webp,image/tiff,image/x-icon"

    }

    useEffect(() => {
        // returnElements();
        setConfigSchema(ctvConfigSchema.config);
    }, [])

    useEffect(() => {
        (async () => {
            const projectDistribution = await ctvAppService.getProjectDistribution();
            if (projectDistribution.status && projectDistribution.data) {
                setProjectDists(projectDistribution.data);
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            const config = await ctvAppService.getCTVAppConfig({ project_id: projectId });
            if (config.status && config.data) {
                // setAppPackageName(config.data.package_name);
                setSections(JSON.parse(config.data.home)['livetvs'])
                setFormDetails({
                    ...formdetails,
                    config: JSON.parse(config.data.home)
                })
            }
        })();
    }, [reloadComponent,reorderSection])

    const handleChange = (index, event, key) => {

        const newData = { ...formdetails };
        newData.config.livetvs[index][key] = event.target.value;
        setFormDetails(newData)
        fieldStates["error_" + index + "_" + key] = 'has-success';
        fieldStates["error_msg_" + index + "_" + key] = '';
        setFieldStates({
            ...fieldStates,
            fieldStates
        });
    }

    const handleSelectChange = (index,event,key,select) =>{
        console.log(index,event,key,select)
        const newData = { ...formdetails };
        newData.config.livetvs[index][key] = event.value;
        setFormDetails(newData)
        fieldStates["error_" + index + "_" + key] = 'has-success';
        fieldStates["error_msg_" + index + "_" + key] = '';
        setFieldStates({
            ...fieldStates,
            fieldStates
        });
    }

    const handleMultiImageChange = (id, image, index) => {
        console.log("id,image,index: ", id, image, index)
        let key = id.split('_')[0];
        const newData = { ...formdetails };
        newData.config.livetvs[index][key] = image.value;
        setFormDetails(newData);
        fieldStates["error_" + index + "_" + key] = 'has-success';
        fieldStates["error_msg_" + index + "_" + key] = '';
        setFieldStates({
            ...fieldStates,
            fieldStates
        });

    }

    const handleSubmit = function (e) { validateForm(); e.preventDefault() }

    const validateForm = () => {
        console.log("Validation Start:", formdetails);
        let validations = true;
        formdetails.config.livetvs?.map((section, index) => {
            console.log("section", section)
            if (section.type.trim() === "") {
                fieldStates['error_' + index + '_type'] = "has-danger";
                fieldStates['error_msg_' + index + '_type'] = "This field is required";
                validations = false;
            } else {
                fieldStates['error_' + index + '_type'] = "has-success";
                fieldStates['error_msg_' + index + '_type'] = "";
            }

            if (section.title.trim() === "") {
                fieldStates['error_' + index + '_title'] = "has-danger";
                fieldStates['error_msg_' + index + '_title'] = "This field is required";
                validations = false;
            } else {
                fieldStates['error_' + index + '_title'] = "has-success";
                fieldStates['error_msg_' + index + '_title'] = "";
            }

            if (section.url === "") {
                fieldStates['error_' + index + '_url'] = "has-danger";
                fieldStates['error_msg_' + index + '_url'] = "This field is required";
                validations = false;
            } else if (!formValidations.verifyUrl(section.url)) {
                fieldStates['error_' + index + '_url'] = "has-danger";
                fieldStates['error_msg_' + index + '_url'] = "Please enter valid url";
                validations = false;
            }else if(section.type === "youtube" && !formValidations.verifyYoutubeUrl(section.url)){
                fieldStates['error_' + index + '_url'] = "has-danger";
                fieldStates['error_msg_' + index + '_url'] = "Please enter valid youtube url";
                validations = false;
            }else if(section.type === 'native' && !formValidations.verifyVideoUrl(section.url)){
                fieldStates['error_' + index + '_url'] = "has-danger";
                fieldStates['error_msg_' + index + '_url'] = "Please enter valid video url";
                validations = false;
            }
            else {
                fieldStates['error_' + index + '_url'] = "has-success";
                fieldStates['error_msg_' + index + '_url'] = "";
            }

            // if(section.thumbnail.trim() === ""){
            //     fieldStates['error_'+index+'_thumbnail'] = "has-danger";
            //     fieldStates['error_msg_'+index+'_thumbnail'] = "This field is required";
            //     validations = false;
            // }else{
            //     fieldStates['error_'+index+'_thumbnail'] = "has-success";
            //     fieldStates['error_msg_'+index+'_thumbnail'] = "";
            // }
            setFieldStates({
                ...fieldStates,
                fieldStates,
            })
        })
        return validations;
    }

    const saveConfig = async () => {

        if (validateForm()) {
            setShowSaveButton(false);
            setShowLoader(true);
            const settingsSaved = await ctvAppService.saveCTVAppConfig(formdetails);
            if (settingsSaved.status && settingsSaved.data) {
                setTimeout(() => {
                    setShowLoader(false);
                    setShowSaveButton(true);
                    setReloadComponent(Math.random())
                    setAlert({ show: true, type: 'success', message: "CTV App Customizations saved succesfully" })
                }, 1000);
            } else {
                setTimeout(() => {
                    setShowLoader(true);
                    setShowSaveButton(false);
                    setAlert({ show: true, type: 'danger', message: "CTV App Customizations could not be saved" })
                }, 1000);
            }
            setTimeout(() => {
                setAlert({ show: false, type: '', message: '' });
            }, 3000);
        }
    }

    console.log("formdetails", formdetails)
    const addMore = () => {
        const newData = { ...formdetails }
        newData.config.livetvs.push({ type: "", title: "", url: "", thumbnail: "" })
        setFormDetails(newData);
    }

    const removeSection = (removeIndex) => {
        const newData = { ...formdetails }
        newData.config.livetvs.splice(removeIndex, 1)
        setFormDetails(newData);
    }

    {
        formdetails.config.livetvs?.map((section, index) => {
            elements.push(
                <div key={index}>
                    <Row>
                        <Col>
                            {index + 1}
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <div className="designLabel">
                                <Label className="smLabl-txt">Type<span class="text-danger"> * </span></Label>
                            </div>
                        </Col>
                        <Col md="6">
                            <div className="inptFom">
                                <FormGroup className={fieldStates["error_" + index + "_type"]}>
                                    <Select
                                        className="react-select primary"
                                        classNamePrefix="react-select"
                                        name="type"
                                        value={section.type !== "" ? typeList.filter(option=> option.value === section.type):""}
                                        options={typeList}
                                        onChange={(event) => handleSelectChange(index, event, 'type',section)}
                                    />
                                    {
                                        fieldStates['error_' + index + '_type'] === 'has-danger' ?
                                            <label className="error">{fieldStates['error_msg_' + index + '_type']}</label>
                                            :
                                            null
                                    }
                                </FormGroup>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <div className="designLabel">
                                <Label className="smLabl-txt">Title<span class="text-danger"> * </span></Label>
                            </div>
                        </Col>
                        <Col md="6">
                            <div className="inptFom">
                                <FormGroup className={fieldStates["error_" + index + "_title"]}>
                                    <Input
                                        type="text"
                                        name="title"
                                        id={`title_${index}`}
                                        value={section.title}
                                        onChange={(event) => handleChange(index, event, 'title')}
                                    />
                                    {
                                        fieldStates['error_' + index + '_title'] === 'has-danger' ?
                                            <label className="error">{fieldStates['error_msg_' + index + '_title']}</label>
                                            :
                                            null
                                    }
                                </FormGroup>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <div className="designLabel">
                                <Label className="smLabl-txt">Url<span class="text-danger"> * </span></Label>
                            </div>
                        </Col>
                        <Col md="6">
                            <div className="inptFom">
                                <FormGroup className={fieldStates["error_" + index + "_url"]}>
                                    <Input
                                        type="url"
                                        name="url"
                                        id={`url_${index}`}
                                        value={section.url}
                                        onChange={(event) => handleChange(index, event, 'url')}
                                    />
                                    {
                                        fieldStates['error_' + index + '_url'] === 'has-danger' ?
                                            <label className="error">{fieldStates['error_msg_' + index + '_url']}</label>
                                            :
                                            null
                                    }
                                </FormGroup>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <div className="designLabel">
                                <Label className="smLabl-txt">Thumbnail</Label>
                            </div>
                        </Col>
                        <Col md="6">
                            <div className="inptFom">
                                <FormGroup className={fieldStates["error_" + index + "_thumbnail"]}>
                                    <ImageUpload
                                        projectId={projectId}
                                        imageDetails={imageDetails}
                                        elementValue={section.thumbnail}
                                        id={`thumbnail_${index}`}
                                        handleEnumChange={(id, image) => handleMultiImageChange(id, image, index)}
                                    />
                                    <Input
                                        type="hidden"
                                        name="thumbnail"
                                        id={`thumbnail_${index}`}
                                        value={section.thumbnail}
                                        onChange={(event) => handleChange(index, event, 'thumbnail')}
                                    />
                                    {
                                        fieldStates['error_' + index + '_thumbnail'] === 'has-danger' ?
                                            <label className="error">{fieldStates['error_msg_' + index + '_thumbnail']}</label>
                                            :
                                            null
                                    }
                                </FormGroup>
                            </div>
                        </Col>
                    </Row>
                    <Button
                        type="button"
                        className="btn-smb btnRd"
                        onClick={(value) => removeSection(index)}
                    >Remove</Button>
                </div>
            )
        })
    }

    if (reorderSection) {
        return <ReorderSection setReorderSections={setReorderSections} sections={sections} />
    }
    else {
        return (
            <div className="content">
                <Container>
                    <Row>
                        <Col className="ml-auto mr-auto" md="12">
                            <Card className="card-signup text-center">
                                <CardHeader>
                                    <CardTitle tag="h4">
                                        <Row>
                                            <Col md="10">
                                                Connected TV App Customization
                                            </Col>
                                            <Col>
                                                <Button
                                                    type="button"
                                                    className='btn-smb'
                                                    onClick={(event) => setReorderSections(true)}
                                                >
                                                    Reorder Sections
                                                </Button>
                                            
                                            </Col>
                                        </Row>
                                    </CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <Form className="form-horizontal" action="" id="ctvAppGenSettings" method="POST" onSubmit={handleSubmit}>
                                        <Row>
                                            <Label className="subHeading_h4">Live TV</Label>
                                        </Row>
                                        {/* div element starts */}
                                        {elements}
                                        {/* div element end */}

                                        <Row>
                                            <Col md="8">
                                                <div className="dflLft">
                                                    <Button
                                                        className="btn-smb"
                                                        type="button"
                                                        onClick={(value) => addMore()}
                                                    >Add More</Button>
                                                </div>
                                            </Col>
                                        </Row>

                                        {
                                            showSaveButton ?
                                                <Button
                                                    className='btn-round'
                                                    color='info'
                                                    type='button'
                                                    onClick={saveConfig}
                                                >
                                                    Save
                                                </Button>
                                                : ""
                                        }
                                        {
                                            alert.show ?
                                                <Alert color={alert.type}>
                                                    {alert.message}
                                                </Alert>
                                                :
                                                ''
                                        }
                                        {
                                            showLoader ?
                                                <Spinner animation="border" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </Spinner>
                                                :
                                                ''
                                        }
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }

}

export default CTVCustomizations;

import React, { useEffect, useState, useContext } from "react";
import notificationsService from "../../services/api/notifications-service";
import { useHistory } from "react-router-dom";
import ProjectContext from '../../ProjectContext';
import Pagination from "react-js-pagination";
import Moment from 'react-moment';
import ReactBSAlert from "react-bootstrap-sweetalert";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faTrashRestoreAlt } from '@fortawesome/free-solid-svg-icons'
import './notificaton.css';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Alert,
  Spinner,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";


const List = (props) => {
  const projectDetails = useContext(ProjectContext);
  const history = new useHistory();

  if (projectDetails.id == '') {
    history.push("/project/allprojects");
  }
  const projectId = projectDetails.id;
  const [notifications, setNotifications] = useState(false);
  const [totalNotifications, setTotalNotifications] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [renderComponent, setRenderComponent] = useState(false);
  const [showPopup, setShowPopup] = useState({ alert: null });
  const [reloadComponent, setReloadComponent] = useState(0);
  const records = 10;


  useEffect(() => {
    (async () => {
      let notificationsData = await notificationsService.getProjectNotifications(projectId, activePage, records);
      if (notificationsData.status) {
        console.log("notificationsData", notificationsData)
        setNotifications(notificationsData.data.notificationList);
        setTotalNotifications(notificationsData.data.count);
      }
      // let totalNotificationsData = await notificationsService.getProjectTotalNotifications(projectId);
      // if(totalNotificationsData.status && totalNotificationsData.data){
      //     setTotalNotifications(totalNotificationsData.data.count);
      // }
      setRenderComponent(true);
    })();
  }, [reloadComponent, activePage]);

  const handlePageChange = (pageNumber) => {
    console.log(pageNumber);
    setActivePage(pageNumber);
  }


  let notificationElements = [];
  let firstRecord = 0;
  let lastRecord = 0;
  if (!notifications || !notifications.length) {
    if (!renderComponent) {
      notificationElements.push(
        <td colSpan="4" className="text-center">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </td>
      );
    } else {
      notificationElements.push(
        <td colSpan="4" className="text-center">
          <CardTitle tag="h4">No Notifications Found</CardTitle>
        </td>
      );
    }
  } else {
    let i = 1;
    notifications.forEach(function (notificationDetails) {
      let className = '';
      if (notificationDetails.message_type == 'article_promotion') {
        className = 'border-danger1';
      } else {
        className = 'border-primary1';
      }
      if (i == 1) {
        firstRecord = (activePage - 1) * records + i;
      }
      notificationElements.push(
        <tr className={className}>
          <td className="text-left">
            {notificationDetails.message}
          </td>
          <td className="text-left">
            {notificationDetails.platform}
          </td>
          <td className="text-left">
            <div>
              {notificationDetails.message_service ? notificationDetails.message_service : "firebase"}
            </div>
            <div>
              {
                notificationDetails.message_service === "izooto"
                  ? `(${notificationDetails.segment_id?.segment_name || "All Subscribers"})`
                  : ""
              }                </div>
            {/* <div>
                  {notificationDetails.segment_id ? notificationDetails.segment_id?.segment_name : ""}
                </div> */}
          </td>
          <td className="text-left">
            {notificationDetails.message_type}
          </td>
          <td className="text-left">
            {notificationDetails.send_medium && notificationDetails.send_medium == 1 ? "Auto" : "Manual"}
          </td>
          <td className="text-left">
            <Moment format="DD MMM YYYY hh:mm:ss a">{notificationDetails.sent_on}</Moment>
          </td>
          <td className="text-left">
            {notificationDetails.delivery_status ? notificationDetails.delivery_status : "success"}
          </td>
          <td className="text-center">
            {notificationDetails.reason ? notificationDetails.reason : "-"}
          </td>
        </tr>
      )
      if (i == notifications.length) {
        lastRecord = (activePage - 1) * records + i;
      }
      i++;
      if (i == notifications.length) {
        lastRecord = (activePage - 1) * records + i;
      }
    })
  }


  return (
    <div className="content">
      <Row>
        <Col className="ml-auto mr-auto" md="12">
          <Card className="card-signup text-start dp-box">
            <CardHeader>
              <CardTitle tag="h4" className="heding_dsh_h4">
                Recent Notifications
              </CardTitle>

            </CardHeader>
            <CardBody>
              <Table responsive >
                <thead className="text-primary text-left">
                  <th>Message</th>
                  <th>Platform</th>
                  <th>Message Service</th>
                  <th>Target Screen</th>
                  <th>Type</th>
                  <th>Sent On</th>
                  <th>Status</th>
                  <th>Fail Reason</th>
                </thead>
                <tbody>{notificationElements}</tbody>

              </Table>
              {totalNotifications > 0 ?
                <div className="pagBox">

                  <div className="pagNo-s">
                    <b>{firstRecord} - {lastRecord} of {totalNotifications}</b>
                  </div>
                  <div className="pagDgt-s">
                    <Pagination
                      activePage={activePage}
                      itemsCountPerPage={records}
                      totalItemsCount={totalNotifications}
                      pageRangeDisplayed={3}
                      onChange={handlePageChange.bind(this)}
                      itemClass="page-item"
                      linkClass="page-link"
                    />
                  </div>
                </div>
                : ''}
            </CardBody>
            {/* <CardFooter> </CardFooter> */}
          </Card>
        </Col>
      </Row>
    </div>
  );


}

export default List;

import React, { useEffect, useState, useContext } from "react";
import projectService from "../../services/api/projects-service";
import {useHistory} from "react-router-dom";
import ReactBSAlert from "react-bootstrap-sweetalert";
import moment from 'moment';
import ProjectContext from "../../ProjectContext";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Alert,
  Spinner,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";


const Authorize = (props)=> {
  const history = new useHistory();
  let accessToken = new URLSearchParams(props.location.search).get('accesstoken');
  let projectId = new URLSearchParams(props.location.search).get('pid');
  if(!accessToken || !projectId){
    history.push("/project/allprojects");
  }
  const projectDetails = useContext(ProjectContext);

  const [showPopup,setShowPopup] = useState({alert:null});
  
  useEffect( () => {
    (async () =>{
        let details = await projectService.getInvitationDetails(accessToken,projectId);
            if(details.status && details.data){
                setShowPopup({
                    alert: (
                      <ReactBSAlert
                        warning
                        style={{ display: "block", marginTop: "-100px" }}
                        title={"Accept Invite to access "+details.data.projectName}
                        onConfirm={() => acceptInvite(details.data.inviteId)}
                        onCancel={() => hideAlert()}
                        confirmBtnBsStyle="info"
                        cancelBtnBsStyle="danger"
                        confirmBtnText="Yes, Accept!"
                        cancelBtnText="Cancel"
                        showCancel
                        btnSize=""
                      >
                        Accept Invite to access {details.data.projectName}
                      </ReactBSAlert>
                    ),
                  });
            }else{
                setShowPopup({
                    alert: (
                      <ReactBSAlert
                        warning
                        style={{ display: "block", marginTop: "-100px" }}
                        title="Invite is expired or invalid."
                        onConfirm={() => hideAlert()}
                        onCancel={() => hideAlert()}
                        confirmBtnBsStyle="info"
                        cancelBtnBsStyle="danger"
                        confirmBtnText="Ok"
                        cancelBtnText="Cancel"
                        //showCancel
                        btnSize=""
                      >
                        Invite is expired or invalid!
                      </ReactBSAlert>
                    ),
                  });
            }
            
        })();
    }, []);


   
    const acceptInvite = async (inviteId) => {
      let resUser = await projectService.acceptInviteToProject(inviteId);
      if(projectDetails.isContractExpired){
        setShowPopup({alert:null});
        projectDetails.setContractExpiredAlert(projectDetails);
      }
      else if(resUser.status && resUser.data){
        setShowPopup({
          alert: (
            <ReactBSAlert
              success
              style={{ display: "block", marginTop: "-100px" }}
              title="Project Added!"
              onConfirm={() => hideAlert()}
              onCancel={() => hideAlert()}
              confirmBtnBsStyle="info"
              btnSize=""
            >
              Project added successfully.
            </ReactBSAlert>
          ),
        });
        
      }else{
        setShowPopup({
          alert: (
            <ReactBSAlert
              danger
              style={{ display: "block", marginTop: "-100px" }}
              title="Please try again!"
              onConfirm={() => hideAlert()}
              onCancel={() => hideAlert()}
              confirmBtnBsStyle="info"
              btnSize=""
            >
              Project could not be added. Please contact to your team.
            </ReactBSAlert>
          ),
        });
      }
    }

    const hideAlert = () => {
      setShowPopup({
        alert: null,
      });
      history.push("/project/allprojects");
    };  
 
    return (
      <div className="content">
         {showPopup.alert?showPopup.alert:
         <Spinner animation="border" role="status">
         <span className="sr-only">Loading...</span>
        </Spinner>
         }
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
          }}
        />
      </div>
    );
  }
  

export default Authorize;

import React, { useEffect, useState, useContext } from "react";
import formValidations from "../../variables/form-validations";
import onboardService from "../../services/api/onboard-service";
import projectsService from "../../services/api/projects-service";
import TimezoneSelect from "react-timezone-select";
import { useCookies } from "react-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import "./plan.css";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  CardText,
  CardImg,
  Label,
  FormGroup,
  Form,
  Input,
  Alert,
  Spinner,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

import Select from "react-select";
import planService from "../../services/api/plan-service";
import ProjectContext from "../../ProjectContext";
import authService from "../../services/api/auth-service";
import ReactBSAlert from "react-bootstrap-sweetalert";
const Subscribe = (props) => {
  const projectDetails = useContext(ProjectContext);

  const history = new useHistory();
  const planId = new URLSearchParams(props.location.search).get("planId");
  const projectId = new URLSearchParams(props.location.search).get("projectId");
  let getProjectSlug = new URLSearchParams(props.location.search).get("project");
  
  if (!getProjectSlug) {
    history.push({
      search: "?project=" + projectDetails.slug,
    });
  }

  const [planFeatures, setPlanFeatures] = useState([]);
  const [planPrice, setPlanPrice] = useState({
    currency: "",
    base_price: "",
    discount: "",
    duration: "",
    duration_unit: "",
  });
  const [razorpayPlan, setRazorpayPlan] = useState(false);
  const [planDetails, setPlanDetails] = useState({});
  const [componentRendered, setComponentRendered] = useState(false);
  const [showPopup, setShowPopup] = useState({ alert: null });
  const [showLoader, setShowLoader] = useState(false);
  const [showLoaderAtButton, setShowLoaderAtButton] = useState(false);

  const [showProceedButton, setShowProceedButton] = useState(true);
  const [isPlanRenewed, setIsPlanRenewed] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  useEffect(() => {
     (async ()=>{
      const response = await planService.isPlanRenewed(projectDetails.id);
      if(response.status){
        if(response.data) {
          setIsPlanRenewed(true);
        }else {
          setIsPlanRenewed(false);
        }
      } 
     })();
  },[]);
  useEffect(() => {
     (async ()=>{
      const response = await planService.isSubscribed(projectDetails.id);
      if(response.status){
        if(response.data) {
          setIsSubscribed(true);
        }else {
          setIsSubscribed(false);
        }
      } 
     })();
  },[]);
  useEffect(() => {
    (async () => {
      setShowLoader(true);
      //const planId = props.match.params["planId"];
      //const projectId = props.match.params["projectId"];

      const response = await planService.getPlan(planId);
      //console.log("Response", response);
      if (response.status) {
        setRazorpayPlan({...response.data.razorpayPlan});
        setPlanPrice({ ...response.data.planPrice });
        setPlanDetails({ ...response.data.planDetails });
        let tempFeatures = [];

        if (response.data.planFeatures && response.data.planFeatures.length) {
          response.data.planFeatures.forEach((feature) => {
            tempFeatures.push({
              name: feature.feature_id.name,
              quota: feature.feature_quota || "",
            });
          });
          setPlanFeatures(tempFeatures);
        }
      }
      setShowLoader(false);
      setComponentRendered(true);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.async = true;
      document.body.appendChild(script);
    })();
  }, []);

  const hideAlert = () => {
    setShowPopup({
      alert: null,
    });
  };
  const handleOnPurchase = async (projectId, planId) => {
    if(isSubscribed) {
        setShowPopup({
              alert: (
                <ReactBSAlert
                  info
                  style={{ display: "block", marginTop: "-100px" }}
                  title="Warning"
                  onConfirm={hideAlert}
                  confirmBtnBsStyle="info"
                  confirmBtnText = "Ok"
                  showConfirm = {true}
                  showCancel = {false}
                  btnSize=""
                >
                  You have already Subscribe plan.
                </ReactBSAlert>
              ),
          });
        return false;
    }
    if(isPlanRenewed) {
      setShowPopup({
              alert: (
                <ReactBSAlert
                  info
                  style={{ display: "block", marginTop: "-100px" }}
                  title="Warning"
                  onConfirm={hideAlert}
                  confirmBtnBsStyle="info"
                  confirmBtnText = "Ok"
                  showConfirm = {true}
                  showCancel = {false}
                  btnSize=""
                >
                  You have already renewed your contract.
                </ReactBSAlert>
              ),
          });
      return false;
    }
    const isPlanCompitibleForProject = await projectsService.isPlanCompatibleForProject(projectId, planId);
    if(isPlanCompitibleForProject.status && isPlanCompitibleForProject.data){
        let response = isPlanCompitibleForProject.data;
        if(response.status === 'compatible') {
          await processPayment(projectId, planId); //if compatible start payment process
        }else if(response.status === 'warning'){
          setShowPopup({
            alert: (
              <ReactBSAlert
                info
                style={{ display: "block", marginTop: "-100px" }}
                title="Warning"
                onConfirm={()=>handleConfirm(projectId, planId)}
                onCancel={() => hideAlert()}
                confirmBtnBsStyle="info"
                confirmBtnText = "Proceed"
                showConfirm = {true}
                showCancel = {true}
                btnSize=""
              >
              </ReactBSAlert>
            ),
        });
        }else if(response.status === 'error') {
          setShowPopup({
            alert: (
              <ReactBSAlert
                danger
                style={{ display: "block", marginTop: "-100px" }}
                title="Error"
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnBsStyle="info"
                btnSize=""
              >
              </ReactBSAlert>
            ),
        });
        }
    }
    return false;
  }
  const handleConfirm = async (projectId, planId) => {
    hideAlert();
    await processPayment(projectId, planId);
  }
  const processPayment = async (projectId, planId) => {
    setShowLoaderAtButton(true);
    setShowProceedButton(false);
    let userDetails = await authService.getLoggedInUser();
    
    console.log("userDetails",userDetails);

    let subscriptionData = await planService.createSubscription(projectId,planId,"Razorpay");
    console.log("New subscription created ", subscriptionData)

    if (subscriptionData && subscriptionData.data) {
    
      var options = {
        key: subscriptionData.data.rp_key_id,
        subscription_id: subscriptionData.data.id,
        name: "SORTD",
        description: "",
        // amount: "100000",
        order_id: "",
        handler: async function (response) {
          setShowPopup({
            alert: (
              <ReactBSAlert
              info
                style={{ display: "block", marginTop: "-100px" }}
                title="Please wait"
                showCancel={false}
                showConfirm={false}
                confirmBtnBsStyle="info"
                confirmBtnText="Ok"
              >
                <Spinner
                    animation="border"
                    role="status">
                    <span className="sr-only">
                        Loading...
                    </span>
                </Spinner>
              </ReactBSAlert>
            )
          })
          console.log("susbcription response", response)
            let values = {
                razorpay_payment_id: response.razorpay_payment_id,
                razorpay_signature: response.razorpay_signature,
                razorpay_subscription_id: response.razorpay_subscription_id
            }
            let subsVerification = await planService.verifySubscription(values)
            // let subsVerification = true;
            console.log("subsVerification: ",subsVerification)

            if(subsVerification){
                setShowPopup({
                    alert: (
                      <ReactBSAlert
                        success
                        style={{
                          display: "block",
                          marginTop: "-100px",
                        }}
                        title="Payment Successfull!"
                        onConfirm={() => hideAlert()}
                        onCancel={() => hideAlert()}
                        confirmBtnBsStyle="info"
                        btnSize="">
                        Payment Done successfully.
                      </ReactBSAlert>
                    ),
                  });
                  setTimeout(() => {
                    hideAlert();
                    window.location.href =
                      "/plan/details?project=" + projectDetails.slug;
                  }, 3000);
            }else {
              setShowPopup({
                alert: (
                    <ReactBSAlert
                    danger
                    style={{
                        display: "block",
                        marginTop: "-100px",
                    }}
                    title="Transaction Failed!"
                    onConfirm={() => hideAlert()}
                    onCancel={() => hideAlert()}
                    confirmBtnBsStyle="info"
                    btnSize=""
                    >
                    Payment Failed.
                    </ReactBSAlert>
                ),
                });
            }
        },
        prefill: {
            name: "",
            email: "",
            contact: "",
        },
        notes: {
        address: "",
        payment_for : "sortd_subscription"
        },
        theme: {
        color: "#528ff0",
        },
        modal: {
        ondismiss: function () {
            setShowPopup({
            alert: (
                <ReactBSAlert
                danger
                style={{
                    display: "block",
                    marginTop: "-100px",
                }}
                title="Transaction Failed!"
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnBsStyle="info"
                btnSize=""
                >
                Payment Failed.
                </ReactBSAlert>
            ),
            });
            setTimeout(() => {
            hideAlert();
            }, 3000);
            setTimeout(() => {
            window.location.reload();
            }, 2000);
        },
        }   
        }
      
     setShowLoaderAtButton(false);
      setShowProceedButton(true);
      console.log(options);
      var rzp1 = new window.Razorpay(options);
      rzp1.open();
      rzp1.on("payment.failed", async function (response) {
        rzp1.close();
        setShowPopup({
          alert: (
            <ReactBSAlert
              danger
              style={{ display: "block", marginTop: "-100px" }}
              title="Transaction Failed!"
              onConfirm={() => hideAlert()}
              onCancel={() => hideAlert()}
              confirmBtnBsStyle="info"
              btnSize=""
            >
              Payment Failed.
            </ReactBSAlert>
          ),
        });
        var values = {
          razorpay_signature: "",
          razorpay_order_id: "",
          razorpay_payment_id: "",
          amount: "",
        //   transaction_id: orderData.transaction_id,
        };
    })
    }else{
        setShowPopup({
            alert: (
              <ReactBSAlert
                danger
                style={{ display: "block", marginTop: "-100px" }}
                title="Some Error Occured. Please try again!"
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnBsStyle="info"
                btnSize=""
              >
                Some Error Occured. Please try again!
              </ReactBSAlert>
            ),
          });
    
          setTimeout(() => {
            hideAlert();
          }, 3000);
    }
}

  const getFeaturesTable = () => {
    let i = 1;

    const table = [];
    planFeatures.forEach((feature) => {
      table.push(
        <tr>
          <td className="text-left">*</td>
          <td className="text-left">{feature.name}</td>
          <td className="text-left">{feature.quota}</td>
        </tr>
      );
    });
    return table;
  };

  return (
    <div className="content mt-30">
      {showPopup.alert}
      <Container>
        <Row>
          <Col className="ml-auto mr-auto" md="12">
            <Card className="card-signup text-center dp-box ">
              <div className="dfltDiv extra_text_addedsign">
                <div className="plnCrd">
                  <Row>
                    <Col md="7">
                      <div className="paymnt_img">
                        <Button
                          className="btn-round"
                          color="info"
                          size="sm"
                          type="button"
                          onClick={(event) => history.push("/dashboard/plans")}
                        >
                          See All Plans
                        </Button>
                        <h1>Subscribe Plan For Project</h1>
                        <h2>{projectDetails.name}</h2>
                        <img src="https://mcmscache.epapr.in/post_images/website_132/new_post_images/618a17fe99f36_Group-66.png"></img>
                      </div>
                    </Col>
                    <Col md="5">
                      {showLoader ? (
                        <Spinner animation="border" role="status">
                          <span className="sr-only">Loading...</span>
                        </Spinner>
                      ) : (
                        <div className="checkOutCard">
                          <CardTitle tag="h6">
                            {planDetails.plan_group_id
                              ? planDetails.plan_group_id.name
                              : ""}
                          </CardTitle>

                          <CardBody>
                            <Table className="pCardTbl">
                              <tr>
                                <td>Plan Duration</td>
                                <td>
                                  {planPrice
                                    ? planPrice.duration === 1 &&
                                      planPrice.duration_unit === "months"
                                      ? "1 month"
                                      : planPrice.duration +
                                        "  " +
                                        planPrice.duration_unit
                                    : ""}
                                </td>
                              </tr>
                              <hr />
                              <tr>
                                <td>
                                  Base Price
                                </td>
                                <td>
                                  <i
                                    className="fa fa-inr"
                                    style={{ fontSize: "15px" }}
                                  />
                                  {planPrice.base_price}
                                </td>
                              </tr>
                              
                                <tr>
                                  <td>
                                    Discount
                                  </td>
                                  <td>
                                    <i
                                      className="fa fa-inr"
                                      style={{ fontSize: "15px" }}
                                    />
                                    {planPrice.base_price-razorpayPlan.price}
                                  </td>
                                </tr>
                              

                              <hr />
                              <tr>
                                <td>Effective Price</td>
                                <td>
                                  <i
                                    className="fa fa-inr"
                                    style={{ fontSize: "15px" }}
                                  />
                                  {razorpayPlan.price}
                                </td>
                              </tr>
                              <hr />
                            </Table>
                            {showProceedButton ? (
                              <Button
                                className="btn-round btnBlue"
                                onClick={async () => {
                                  handleOnPurchase(projectId, planId);
                                }}
                              >
                                Proceed To Subscribe
                              </Button>
                            ) : (
                              ""
                            )}
                            {showLoaderAtButton ? (
                              <Spinner animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                              </Spinner>
                            ) : (
                              ""
                            )}
                          </CardBody>
                          <div>An amount of  &nbsp;<i
                                    className="fa fa-inr"
                                    style={{ fontSize: "13px" }}
                                  /> {razorpayPlan.price} will be auto debited from your account every month.</div>
                        </div>
                      )}
                    </Col>
                  </Row>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
      <div
        className="full-page-background"
        style={{
          backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
        }}
      />
    </div>
  );
};

export default Subscribe;

/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch,useLocation } from "react-router-dom";
import { instanceOf } from 'prop-types';
import AdminNavbar from "../components/theme/Navbars/AdminNavbar.js";
import Footer from "../components/theme/Footer/Footer.js";
import Sidebar from "../components/theme/Sidebar/Sidebar.js";
import FixedPlugin from "../components/theme/FixedPlugin/FixedPlugin.js";
import {ProjectProvider} from "../ProjectContext";
import projectsService from '../services/api/projects-service';
import { withCookies, Cookies } from 'react-cookie';
import {UserProvider} from "../UserContext";
import ProjectSuspended from "../views/projects/ProjectSuspended.js";
import AllProjects from "../views/projects/AllProjects.js";
import PlanExpiryAlert from "../views/plan/PlanExpiryAlert.js";
import routes from "../routes.js";
import FlashAlert from './FlashAlert.js'
import SubscriptionAlert from '../views/subscription/SubscriptionAlert.js'
import ReactBSAlert from "react-bootstrap-sweetalert";
var ps;

class Admin extends React.Component {
  state = {
    alert:null
  }
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };


  constructor(props) {
    super(props);

    const { cookies } = props;
    let sortProjectId = '';
    
    if(cookies.get('sortdprojectid')){
      sortProjectId = cookies.get('sortdprojectid');
    }

    let getProjectId = new URLSearchParams(props.location.search).get('project');
    let accesstoken = new URLSearchParams(props.location.search).get('accesstoken');
    if(getProjectId){
      console.log(getProjectId);
      sortProjectId = getProjectId;
      cookies.set('sortdprojectid', sortProjectId, { path: '/' });
    }else{
      if(sortProjectId && !accesstoken){
        this.props.history.push({
          search: '?project='+sortProjectId
        })
      }
    }
    
    this.state = {
      backgroundColor: "white",
      activeColor: "info",
      sidebarMini: false,
      project : {id:'',name:'',slug:'', status:''},
      selectedProject : sortProjectId,
      renderComponent : false,
      user:'',
      allowedRoutes:["/project/allprojects"]
    };
    
  }
  goBack = () => {
    this.setState({alert:null})
    this.props.history.go(-1);
  }

  goHome = ()=>{
    this.setState({alert:null})
    this.props.history.push("/dashboard/home");
  }
  setContractExpiredAlert = ()=>{
    let projectData = this.state.project;
    if(projectData.isContractExpired){
      this.setState({
        alert: <ReactBSAlert
                warning
                style={{display:"block",marginTop:"-100px"}}
                title="Your Contract is expired!!"
                onConfirm={this.goBack}
                onCancel={this.goHome}
                confirmBtnBsStyle="info"
                cancelBtnBsStyle="danger"
                confirmBtnText="Go Back"
                cancelBtnText="Go To Home"
                showCancel
                closeOnClickOutside={false}
                btnSize=""
              >
                Your contract is Expired, Please Subscribe a Plan...!!
              </ReactBSAlert>
      })
    }
  }
  async componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      //ps = new PerfectScrollbar(this.refs.mainPanel);
    }

    if(this.state.selectedProject!=''){
        let projectDetails = await projectsService.getProjectDetailsBySlug(this.state.selectedProject);
        let contractDetails;
        let projectSettingsDetails;
        if(projectDetails && projectDetails.data && projectDetails.data.id){
          contractDetails = await projectsService.getActiveContractByProjectId(projectDetails.data.id);
          let response = await projectsService.getProjectDetails(projectDetails.data.id);
          projectSettingsDetails = response.data;
        }
        console.log("article details from admin layout", projectDetails)
        let webstoryOnlyDashboardStatus = false;
        let projectCloudProvider = projectSettingsDetails?.settings?.cloud_provider ? projectSettingsDetails.settings.cloud_provider : "aws";
        if(projectSettingsDetails && projectSettingsDetails.settings && projectSettingsDetails.settings.webstory_only_dashboard){
          webstoryOnlyDashboardStatus = true;
        }
        console.log("webstoryOnlyDashboardStatus: ",webstoryOnlyDashboardStatus)
        let contractStatus = false;
        if(contractDetails && contractDetails.status && contractDetails.data){
          contractStatus = false;
        }
        else if(contractDetails && contractDetails.status && !contractDetails.data){
          contractStatus = true;
        }
        if(projectDetails && projectDetails.status && projectDetails.data){
            this.setState({ project: {id:projectDetails.data.id,name:projectDetails.data.name,slug:projectDetails.data.slug, status:projectDetails.data.status, isContractExpired:contractStatus, setContractExpiredAlert:this.setContractExpiredAlert, webstoryOnlyDashboard : webstoryOnlyDashboardStatus,cloudProvider:projectCloudProvider} });
        }
        this.setState({renderComponent:true});
        
    }else{
      this.setState({renderComponent:true});
    }

    let currentUser = await projectsService.getLoggedInUser();

    if(currentUser.status && currentUser.data){
      if(Object.keys(currentUser.data).length){
        this.setState({ user: currentUser.data});
      }              
    }
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      //ps.destroy();
      document.documentElement.className += " perfect-scrollbar-off";
      document.documentElement.classList.remove("perfect-scrollbar-on");
    }
  }
  componentDidUpdate(e) {
    if (e.history.action === "PUSH") {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      //this.refs.mainPanel.scrollTop = 0;
    }
  }
  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout !== "") {
        let currentPath = window.location.pathname.split("/").slice(1);
        let currentPathLayout = "/"+currentPath[0];
        let currentPathEndPoint = "/"+currentPath[1];
        if(currentPathLayout === prop.layout && currentPathEndPoint === prop.path){
          if(this.state.project.webstoryOnlyDashboard && !prop.webstoryOnlyRoute){
            this.props.history.push("/project/pagenotfound")
            return null;
          }
        }
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  handleActiveClick = (color) => {
    this.setState({ activeColor: color });
  };
  handleBgClick = (color) => {
    this.setState({ backgroundColor: color });
  };
  handleMiniClick = () => {
    if (document.body.classList.contains("sidebar-mini")) {
      this.setState({ sidebarMini: false });
    } else {
      this.setState({ sidebarMini: true });
    }
    document.body.classList.toggle("sidebar-mini");
  };
  render() {
    if(!this.state.renderComponent){
      return (
        <>
        </>
      )
    }else{
    return (

      <div className="ab">
        {this.state.alert}
        <div className={this.state.alertOn?"overlayAlert alertActive":"overlayAlert"} style={{display:"none"}}></div>
        <UserProvider value={this.state.user}>
        <ProjectProvider value={this.state.project}>
        <Sidebar
          {...this.props}
          routes={routes}
          bgColor={this.state.backgroundColor}
          activeColor={this.state.activeColor}
          userdetails={this.state.user}
          projectDetails = {this.state.project}
        />
        <div className="main-panel"   ref="mainPanel">
          <AdminNavbar {...this.props} parent={(value)=>{this.setState(value)}} handleMiniClick={this.handleMiniClick} projectDetails={this.state.project} />
          {/* {this.state.project.slug? <FlashAlert project={this.state.project}/> : null} */}
          {this.state.project.slug?<PlanExpiryAlert  projectDetails={this.state.project}/> : null}
          {this.state.project.slug? <SubscriptionAlert project={this.state.project}/> : null}
          {this.state.project.status == -1 && !this.state.allowedRoutes.includes(this.props.location.pathname)?<ProjectSuspended/>: <Switch>{this.getRoutes(routes)}</Switch>}
          
          
          {
            // we don't want the Footer to be rendered on full screen maps page
            this.props.location.pathname.indexOf("full-screen-map") !==
            -1 ? null : (
              <Footer fluid />
            )
          }
        </div>
        {/* <FixedPlugin
          bgColor={this.state.backgroundColor}
          activeColor={this.state.activeColor}
          sidebarMini={this.state.sidebarMini}
          handleActiveClick={this.handleActiveClick}
          handleBgClick={this.handleBgClick}
          handleMiniClick={this.handleMiniClick}
        /> */}
        </ProjectProvider>
        </UserProvider>
      </div>
    );
      }
  }
}

export default withCookies(Admin);

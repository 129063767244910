import React, { useEffect, useState, useContext } from "react";
import notificationsService from "../../services/api/notifications-service";
import ProjectContext from '../../ProjectContext'
import { Pie, Bar, Doughnut } from "react-chartjs-2";
import './notificaton.css';

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Input,
    Alert,
    Spinner,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col,
  } from "reactstrap";


const Stats = (props)=>{

    const projectDetails = useContext(ProjectContext);

    const projectId = projectDetails.id;

    const [todayChartLabel,setTodayChartLabel] = useState([]);
    const [todayData,setTodayData] = useState([]);

    const [thisMonthChartLabel, setThisMonthChartLabel] = useState([]);
    const [thisMonthData, setThisMonthData] = useState([]);

    const [totalChartLabel,setTotalChartLabel] = useState([]);
    const [totalData,setTotalData] = useState([]);


    useEffect(()=>{
        (async() =>{

            const response = await notificationsService.getNotificationStats(projectId);

            const today= {
                general:{
                    label:"General",
                    count: 0
                },
                // news:{
                //     label:"News",
                //     count: 0
                // },
                article_promotion:{
                    label:"Article Promotion",
                    count: 0
                },
                category: {
                    label: "Category",
                    count: 0
                },
                live_tv: {
                    label: "Live TV",
                    count: 0
                },
                unread_screen:{
                    label:"Unread Screen",
                    count: 0
                },
            };
            
            const thisMonth ={
                general:{
                    label:"General",
                    count: 0
                },
                // news:{
                //     label:"News",
                //     count: 0
                // },
                article_promotion:{
                    label:"Article Promotion",
                    count: 0
                },
                category: {
                    label: "Category",
                    count: 0
                },
                live_tv: {
                    label: "Live TV",
                    count: 0
                },
                unread_screen:{
                    label:"Unread Screen",
                    count: 0
                },
            };

            const total = {
                general:{
                    label:"General",
                    count: 0
                },
                // news:{
                //     label:"News",
                //     count: 0
                // },
                article_promotion:{
                    label:"Article Promotion",
                    count: 0
                },
                category: {
                    label: "Category",
                    count: 0
                },
                live_tv: {
                    label: "Live TV",
                    count: 0
                },
                unread_screen:{
                    label:"Unread Screen",
                    count: 0
                },
            };

            if(response){
                let label =[];
                let data = [];

                if(response.data.today){
                    response.data.today.forEach(data=> {
                        if (!today[data._id]) {
                            today[data._id] = { count: 0 }; // or any other default value
                        }
                        today[data._id].count = data.count;
                    });

                    label =[];
                    data=[];

                    for(const key in today){
                        label.push(today[key].label);
                        data.push(today[key].count);
                    }
                    setTodayChartLabel(label)
                    setTodayData(data)
                }
                
                if(response.data.thisMonth){
                    response.data.thisMonth.forEach(data=> {
                        if (!thisMonth[data._id]) {
                            thisMonth[data._id] = { count: 0 }; // or any other default value
                        }
                        thisMonth[data._id].count = data.count;
                    });

                    label =[];
                    data=[];

                    for(const key in thisMonth){
                        label.push(thisMonth[key].label);
                        data.push(thisMonth[key].count);
                    }

                    setThisMonthChartLabel(label);
                    setThisMonthData(data);
                }
                
                if(response.data.total){
                    response.data.total.forEach(data=> {
                        if (!total[data._id]) {
                            total[data._id] = { count: 0 }; // or any other default value
                        }
                        total[data._id].count = data.count;
                    });
                    label =[];
                    data=[];

                    for(const key in total){
                        label.push(total[key].label);
                        data.push(total[key].count);
                    }


                    setTotalChartLabel(label);
                    setTotalData(data);
                }

            }

        })()    

        console.log(thisMonthData)
    },[])

    const getChartData = (labels, data) =>{
        console.log("called chart data")

        return {
            labels: labels,
            datasets: [
              {
                label: '# of Votes',
                data: data,
                backgroundColor: [
                  'rgba(255, 99, 132, 0.2)',
                  'rgba(54, 162, 235, 0.2)',
                  'rgba(255, 206, 86, 0.2)',
                  'rgba(75, 192, 192, 0.2)',
                  'rgba(153, 102, 255, 0.2)',
                  'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                  'rgba(255, 99, 132, 1)',
                  'rgba(54, 162, 235, 1)',
                  'rgba(255, 206, 86, 1)',
                  'rgba(75, 192, 192, 1)',
                  'rgba(153, 102, 255, 1)',
                  'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
              },
            ],
          }
    }



    return (
        <div className="content">
            <Row>
                <Col className="ml-auto mr-auto"  md="4">
                    <Card className="card-signup text-center dp-box">
                        <CardHeader>
                             <CardTitle tag="h4"  className="heding_dsh_h4">Today</CardTitle>
                         </CardHeader>
                         <CardBody>
                    { !todayData.length  ?<CardTitle tag="h4">No notification sent</CardTitle>:
                  <Pie
                    data={getChartData(todayChartLabel, todayData)}
                  />
                  } 
                </CardBody>
                    </Card>
                </Col>
                <Col className="ml-auto mr-auto"  md="4">
                    <Card className="card-signup text-center dp-box">
                        <CardHeader>
                             <CardTitle tag="h4" className="heding_dsh_h4">This Month</CardTitle>
                         </CardHeader>
                         <CardBody>
                            {!thisMonthData.length?<CardTitle tag="h4">No notification sent</CardTitle>:
                        <Pie
                            data={getChartData(thisMonthChartLabel, thisMonthData)}
                        />
                         }
                </CardBody>
                    </Card>
                </Col>
                <Col className="ml-auto mr-auto"  md="4">
                    <Card className="card-signup text-center dp-box">
                        <CardHeader>
                             <CardTitle tag="h4"  className="heding_dsh_h4">Till date</CardTitle>
                         </CardHeader>
                         <CardBody>
                            {!totalData.length?<CardTitle tag="h4">No notification sent</CardTitle>:
                        <Pie
                            data={getChartData(totalChartLabel, totalData)}
                        />
                        }
                </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}


export default Stats;
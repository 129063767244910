import axios from "axios";

const getPlanDetails = async(projectId) =>{
    const formdata = {
        projectId : projectId
    }
    const response = await axios.post('/api/plan/get-plan-details',formdata);
    return response.data;
}

const getActivePlans=async()=>{
    const response=await axios.get('/api/plan/get-active-plans');
    return response.data;
}
const getPlan = async(plan_id)=>{
    const formdata = {
        plan_id : plan_id
    }
    const response=await axios.post('/api/plan/get-plan',formdata);
    return response.data;
}

const createPlanOrder = async(projectId,planId,pg_type,coupon_code) =>{
    const formdata = {
        projectId : projectId,
        planId : planId,
        pg_type : pg_type,
        coupon_code
    }
    const response = await axios.post('/api/plan/create-plan-order',formdata);
    return response.data;
}


const createSubscription = async(projectId,planId,pg_type) =>{
    const formdata = {
        projectId : projectId,
        planId : planId,
        pg_type:pg_type
    }
    const response = await axios.post('/api/plan/create-subscription',formdata);
    return response.data;
}


const verifyPlanPayment = async(projectId,planId,paymentData) =>{
    const formdata = {
        projectId : projectId,
        planId : planId,
        paymentData : paymentData
    }
    const response = await axios.post('/api/plan/verify-plan-payment',formdata);
    return response.data;
}
const getActivePlanId=async (plan_id,plan_name,plan_type)=>{

    const formdata={
     plan_id:plan_id,
     plan_name:plan_name,
     plan_type:plan_type   
    }
    const response=await axios.post('/api/plan/get-active-planId',formdata);
    return response.data;
}
const getPlanGroups = async()=>{
    const response = await axios.get('/api/plan/get-plans-groups');
    return response.data;
}
const validateCoupon = async (formdata) => {
    const response =  await axios.post('/api/plan/validate-coupon',formdata);
    return response.data;
}

const verifySubscription = async(data) =>{
    const response = await axios.post('/api/plan/verify-subscription', data)
    return response.data;
}

const getActiveSubscription = async(data)=>{
    const response = await axios.post('/api/plan/current-subscription', data);
    return response.data;
}

// const cancelSubscription = async(data)=>{
//     const response = await axios.post('/api/plan/cancel-subscription', data);
//     return response.data;
// }
const getTransactions = async (projectId,filters , page , records) => {
    let formdata={
        projectId,
        filters,
        page,
        records
    };
    const response = await axios.post('/api/plan/get-transactions',formdata);
    return response.data;
}
const getTransactionReceipt = async (projectId, transactionId) => {
    let formdata = {
        projectId,
        transactionId
    }
    const response = await axios.post('/api/plan/get-transaction-receipt', formdata);
    return response.data;
}
const isPlanRenewed =  async (projectId)=>{
    const formdata={
        projectId 
    }
    const response=await axios.post('/api/plan/is-plan-renewed',formdata);
    return response.data;
}

const isSubscribed =  async (projectId)=>{
    const formdata={
        projectId 
    }
    const response=await axios.post('/api/plan/is-subscribed',formdata);
    return response.data;
}

const cancelSubscription = async (subscriptionId) => {
    let formdata = {
        subscriptionId
    }
    const response = await axios.post('/api/plan/cancel-subscription', formdata);
    return response.data;
}

const getSubscriptions = async (projectId,filters , page , records) => {
    let formdata={
        projectId,
        filters,
        page,
        records
    };
    const response = await axios.post('/api/plan/get-subscriptions',formdata);
    return response.data;
}

const createManualPayOrder = async (projectId, subscriptionId) => {
    const formdata = {
        projectId,
        subscriptionId
    }
    const response = await axios.post('/api/plan/create-manual-pay-order',formdata);
    return response.data;
}
const verifyManualPayment = async(projectId,subscriptionId,paymentData) =>{
    const formdata = {
        projectId : projectId,
        subscriptionId : subscriptionId,
        paymentData : paymentData
    }
    const response = await axios.post('/api/plan/verify-manual-payment',formdata);
    return response.data;
}
const getSubscription = async(projectId,subscriptionId) => {
    let formdata = {
        projectId,
        subscriptionId
    }
    const response = await axios.post('/api/plan/get-subscription', formdata);
    return response.data;
}


export  default {
    getPlanDetails,
    getActivePlans,
    createPlanOrder,
    verifyPlanPayment,
    getPlan,
    getActivePlanId,
    getPlanGroups,
    validateCoupon,
    createSubscription,
    verifySubscription,
    getActiveSubscription,
    cancelSubscription,
    getTransactions,
    getTransactionReceipt,
    isPlanRenewed,
    isSubscribed,
    getSubscriptions,
    createManualPayOrder,
    verifyManualPayment,
    getSubscription
}
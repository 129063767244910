import React,{useState,useEffect, useContext} from 'react';
import Select, { components } from "react-select";
import ReactBSAlert from "react-bootstrap-sweetalert";
import AddUser from './AddUser';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  Formfeature,
  FormGroup,
  Form,
  Input,
  Alert,
  Spinner,
  InputfeatureAddon,
  InputfeatureText,
  Inputfeature,
  Table,
  Container,
  Row,
  Col,
  Tooltip
} from "reactstrap";
import './general.css';
import moment from 'moment';
import Pagination from "react-js-pagination";
import acceptImage from "../../../../assets/img/sortd-apps/accept.png";
import plusImage from "../../../../assets/img/sortd-apps/plus.png";
import iosService from "../../../../services/api/sortd-apps/ios-service";
import ProjectContext from '../../../../ProjectContext';

const ManageTestFlightUsers = (props) => {
    const projectId = props.projectId;
    const projectDetails = useContext(ProjectContext);
    const [showButton, setShowButton] = useState(true);
    const [alert, setAlert]   = useState({show:false,message:'',type:''});
    const [showLoader, setShowLoader]   = useState(false);
    const [users , setUsers] = useState([]);
    const [filteredUser , setFilteredUser] = useState([]);
    const [search , setSearch] = useState("");
    const [inviteUser , setInviteUser] = useState(false);
    const [renderComponent,setRenderComponent] = useState(false);
    const [showPopup,setShowPopup] = useState({alert:null});
    const [reloadComponent,setReloadComponent] = useState(0);
    const [formdetails , setFormDetails] = useState({project_id : projectId});
    const [testers ,setTesters] = useState([]);
    const [totalTester , setTotalTester] = useState(false);
    const records = 10;
    const page =1;
    const [firstRecord, setFirstRecord] = useState(0);
    const [lastRecord, setLastRecord] = useState(0);
    const [activePage,setActivePage] = useState(1);
    const [isLoading ,setIsLoading] = useState(false);
      useEffect( () => {
    (async () =>{
        setIsLoading(true);
        let usersData = await iosService.getAppleAccountUsers(projectId);
            if(usersData.status && usersData.data){
                let data = [];
                usersData.data.forEach((user)=>{
                console.log("Users:",user.attributes);
                data.push({
                    value : user.attributes.username || user.attributes.email,
                    label : user.attributes.username || user.attributes.email
                });
          });
          setUsers(data);
          setIsLoading(false);
            }
            setRenderComponent(true);
        })();
    }, [reloadComponent]);

    useEffect(()=> {
        (async () =>{
        let iosAppTesters = await iosService.getIOSAppTesters(formdetails , activePage ,records);
            if(iosAppTesters.status && iosAppTesters.data){
                setTesters(iosAppTesters.data.testers);
                setTotalTester(iosAppTesters.data.count);
                let testers = iosAppTesters.data.testers;
                if(testers && testers.length) {
                    setFirstRecord((activePage-1)*records+1);
                    setLastRecord((activePage-1)*records+ testers.length);
                }else{
                    setFirstRecord((activePage-1)*records);
                    setLastRecord((activePage-1)*records+ testers.length);
                }
            }
            setRenderComponent(true);
        })();
    },[reloadComponent]);
    const addUserAsTester = async (email)=> {
        setIsLoading(true);
        const userAdded = await iosService.addUserAsIOSAppTester({email , projectId});
        if(projectDetails.isContractExpired){
          setIsLoading(false);
          projectDetails.setContractExpiredAlert(projectDetails);
        }
        else if(userAdded.status && userAdded.data) {
          console.log("UserAdded",userAdded.data);
          setIsLoading(false);
        }
        setReloadComponent(Math.random());
    }
    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
        setReloadComponent(Math.random());
    }
    const handleSearchTextChange = (value)=> {
      setSearch(value);
      let list =[];
      list = users.filter(function (str) { return str.value.toUpperCase().indexOf(value) == -1; })
      setFilteredUser(list);
    }
    const isUserPresentInTestersList = (email) => {
        let isPresent = testers.find(details => details.email === email && details.status==true  );
        return isPresent ? true :false;
    }
    const formatOptionLabel = ({ value, label, customAbbreviation }) => {
      return ( 
      <div style={{ display: "flex" }}>
          {/* <div style={{ marginLeft: "10px", color: "#000000",cursor:"pointer" }}> */}
            <div style={{ marginLeft: "1px", color: "#000000",cursor:"pointer" }}>
              {label} 
            </div>
            <div style={{ "align-items": "right !important", color: "#787878",cursor:"pointer" }}className="text-right">
              {
              isUserPresentInTestersList(value) ? 
                  <img width="24" height="24" title="Already Added" alt="Already Added" src={acceptImage} />
                  :
                  <img width="24" height="24" title="Add as a tester" onClick={(response) => addUserAsTester(value ,label)} alt="Add as a tester" src={plusImage} />
              }
            </div>
            
          {/* </div> */}
      </div>);
    };
    return (
        <ReactBSAlert
            closeOnClickOutside ={false}
            showConfirm={false} 
            // showCloseButton = {true}
            onCancel ={()=>{props.hidePopup()}}
            showCancel={false}
            style ={{"display":"block","height" : "auto"}}
          >
            <Row>
                        <Col>
                        <CardTitle tag="h4" align="left" style={{marginTop:'0px'}}>
                            {inviteUser ? "Invite User" : "Manage User"}                            
                        </CardTitle>
                        </Col>
                        <Col align="right">
                            <Button onClick = {()=>props.hidePopup()} className ="closeBtn">X</Button>
                        </Col>   
                    </Row>
            <div>
              {alert.show?
                <Alert color={alert.type}>
                  {alert.message}
                </Alert>
              :''}
              {showLoader?
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
              :''}
              {!inviteUser?
                  <FormGroup >
                    <Select
                      className={"react-select emailDropdown"}
                      classNamePrefix="react-select"
                      placeholder="Enter user email .."
                      name = "email"
                      id="email"
                      isLoading = {isLoading}
                      onInputChange={handleSearchTextChange}
                      options={filteredUser?filteredUser:""}
                      formatOptionLabel={formatOptionLabel}
                      isOptionDisabled={(option) => true}
                      closeMenuOnSelect ={true}
                      onChange = {(event)=>{
                        setSearch(event.value)
                      }}
                      noOptionsMessage={() => <div>
                        {search} <Button
                        title = "Invite user to Appstore"
                          // style={{"backgroundColor": "white"}}
                          className="inviteBtnU"
                          type="button"
                          onClick={() => setInviteUser(true)}
                      ><i className="fa fa-user"> </i>Invite </Button>
                      </div>
                      }
                      />
                  </FormGroup>
              :null}
              {inviteUser?
                  <AddUser email = {search} projectId = {props.projectId} onCancel ={ ()=>{setInviteUser(false)}}/>
                  : null }
              {!inviteUser?
                  <div >
                    {showPopup.alert}
                    <Container>
            
          {/* <Row>
          <Col   md="12"> */}
              {/* <Card className=" text-center dp-box">
                <CardHeader>
                <CardTitle tag="h4" className="heding_tab_h4"> 
                     
                </CardTitle>
                </CardHeader>
                <CardBody> */}
                <Table  responsive>
                    <thead className="text-primary">
                    
                      <th className="text-left">#</th>   
                        <th className="text-left">Email</th>
                        <th className="text-left">Added on</th>
                        <th className="text-left">Status</th>
                        {/* <th className="text-left">Action</th> */}
                    </thead>
                    <tbody>
                        {testers.map((userDetails ,index) => (
                            <tr >
                            <td className="text-left">{((activePage-1)*records)+(index+1)}</td>
                            <td className="text-left">
                                {userDetails.email}
                            </td>
                            <td className="text-left">
                                {moment(userDetails.createdAt).fromNow()}
                            </td>
                            <td className="text-left">
                                {userDetails.status==0?'Unable to add'
                                    :userDetails.status==1?'Added'
                                    :''}
                            </td>
                            {/* <td className="text-left">
                                {expired?
                                <a title="Retry" onClick={(event)=>  resendInvitePopup(userDetails.id) } href="javascript:void(0)" class="nav-link">
                                Retry
                                </a>
                                :''}
                            </td> */}
                            </tr>
                          ))}
                    </tbody>
                </Table>
                  <Row>
                      
                      <Col md="6">
                        <div style={{float:'right',margin:'5px'}}>
                          <b>{firstRecord} - {lastRecord} of {totalTester}</b>
                        </div>
                      </Col>
                      <Col md="6">
                      <div style={{float:'left'}}>
                          <Pagination
                          activePage={activePage}
                          itemsCountPerPage={records}
                          totalItemsCount={totalTester}
                          pageRangeDisplayed={5}
                          onChange={handlePageChange.bind(this)}
                          itemClass="page-item"
                          linkClass="page-link"
                          />
                      </div>
                    </Col>
                    </Row>
                {/* </CardBody>
                <CardFooter>
                </CardFooter>
              </Card> */}
            {/* </Col>
          </Row> */}
        </Container>
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("../../../../assets/img/bg/jan-sendereks.jpg")})`,
          }}
        />
      </div>

                    :null}


                      </div>
                  </ReactBSAlert>);
}

export default ManageTestFlightUsers;
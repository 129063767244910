import React, { useEffect, useState, useContext } from "react";
import ctvAppService from "../../services/api/ctv-app-service";
import SortableTree from 'react-sortable-tree';
import 'react-sortable-tree/style.css';
import { useHistory } from "react-router-dom";
import ProjectContext from '../../ProjectContext';

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Alert,
    Spinner,
    Container,
    Row,
    Col,
} from "reactstrap";

const ReorderSection = (props) => {


    const projectDetails = useContext(ProjectContext);
    const history = new useHistory();
    console.log(projectDetails.id);
    if (projectDetails.id == '') {
        history.push("/project/allprojects");
    }
    const projectId = projectDetails.id;

    const [sections, setSections] = useState(props.sections);
    const [showLoader, setShowLoader] = useState(false);
    const [showSaveButton, setShowSaveButton] = useState(true);
    const [alert, setAlert] = useState({ show: false, message: '', type: '' });

    let sectionTreeData = [];
    if (sections && sections.length) {
        sections.forEach(function (sectionDetails) {
            console.log(sectionDetails);
            let title = sectionDetails.title;
            let treesection = { title: title, type: sectionDetails.type, url: sectionDetails.url, thumbnail: sectionDetails.thumbnail };
            sectionTreeData.push(treesection);
        })
    }


    const [treeData, setTreeData] = useState(sectionTreeData);
    console.log(treeData);
    useEffect(() => {

        history.push({
            search: '?project=' + projectDetails.slug
        })

    }, []);


    const updateTreeData = (treeData) => {
        console.log(treeData);
        setTreeData(treeData);
    }

    const saveSectionOrder = async (event) => {
        setShowLoader(true)
        setShowSaveButton(false)
        let sectionTreeData = [];
        if (treeData && treeData.length) {
            treeData.forEach(function (sectionDetails) {
                console.log(sectionDetails);
                let title = sectionDetails.title;
                let treesection = { title: title, type: sectionDetails.type, url: sectionDetails.url, thumbnail: sectionDetails.thumbnail };
                sectionTreeData.push(treesection);
            })
        }
        console.log("sectionTreeData", sectionTreeData);
        let formdata = { 'sectionOrders': sectionTreeData, 'projectId': projectId };
        let savesectionOrders = await ctvAppService.reorderSections(formdata);
        if (savesectionOrders.status) {
            setTimeout(() => {
                setShowLoader(false)
                setShowSaveButton(true)
                setAlert({ show: true, type: 'success', message: 'Saved Successfully' });
            }, 1000);

            setTimeout(() => {
                setAlert({ show: false, type: '', message: '' });
            }, 3000);
        } else {
            setTimeout(() => {
                setShowLoader(false)
                setShowSaveButton(true)
                setAlert({ show: true, type: 'success', message: 'Could Not Save. Please try again.' });
            }, 1000);

            setTimeout(() => {
                setAlert({ show: false, type: '', message: '' });
            }, 3000);
        }
    }

    return (
        <div className="content mt-30">
            <Container>
                <Row>
                    <Col className="ml-auto mr-auto" md="12">
                        {alert.show ? <Alert color={alert.type}>
                            {alert.message}
                        </Alert> : ''}
                        <Card className="card-signup text-center dp-box">
                            <CardHeader>
                                <CardTitle tag="h4" className="heding_tab_h4">Reorder Sections</CardTitle>

                                {showSaveButton ? <Button
                                    className="btn-smb "
                                    style={{ float: 'right' }}
                                    type="button"
                                    onClick={(event) => saveSectionOrder(event)}
                                >
                                    Save
                                </Button> : ''}

                                <Button
                                    className="btn-smb btnBlk"
                                    style={{ float: 'right' }}
                                    type="button"
                                    onClick={(event) => props.setReorderSections(false)}
                                >
                                    Back
                                </Button>

                            </CardHeader>
                            <CardBody>

                                {showLoader ? <Spinner animation="border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </Spinner> : ''}
                                <Col md="12">
                                    <div >
                                        <SortableTree
                                            isVirtualized={false}
                                            maxDepth="2"
                                            treeData={treeData}
                                            onChange={treeData => updateTreeData(treeData)}
                                        />
                                    </div>

                                </Col>


                            </CardBody>
                            <CardFooter>

                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <div
                className="full-page-background"
                style={{
                    backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
                }}
            />
        </div>
    );

}

export default ReorderSection;

import axios from 'axios';

const getCTVAppGeneralSettings = async (formData) => {
    const response = await axios.post('/api/ctv-app/get-general-settings', formData);
    return response.data;
}

const saveCTVAppGeneralSettings = async (formData) => {
    const response = await axios.post('/api/ctv-app/save-general-settings', formData);
    return response.data;
}

const getProjectDistribution = async () => {
    const response = await axios.post('/api/ctv-app/get-ctv-app-distribution');
    return response.data;
}

const getCTVAppConfig = async (formData) => {
    const response = await axios.post('/api/ctv-app/get-config', formData);
    return response.data;
}

const saveCTVAppConfig = async (formData) => {
    const response = await axios.post('/api/ctv-app/save-config', formData);
    return response.data;
}

const reorderSections = async (formData) => {
    const response = await axios.post('/api/ctv-app/reorder-section', formData);
    return response.data;
}

const addCategory = async (formData) => {
    const response = await axios.post('/api/ctv-app/add-category', formData);
    return response.data;
}

const getVideoCategories = async (formData) => {
    const response = await axios.post('/api/ctv-app/get-categories', formData);
    return response.data;
}

const getCategoryList = async (formData) => {
    const response = await axios.post('/api/ctv-app/get-category-list', formData);
    return response.data;
}

const reorderCategories = async (formData) => {
    const response = await axios.post('/api/ctv-app/reorder-categories', formData);
    return response.data;
}

const getCategoryDetails = async (formData) => {
    const response = await axios.post('/api/ctv-app/get-category-detail', formData);
    return response.data;
}

const updateCategoryStatus = async (formData) => {
    const response = await axios.post('/api/ctv-app/update-category-status', formData);
    return response.data;
}

const getCtvImportedVideos = async (formdata) => {
    console.log("form data : ", formdata);
    const response = await axios.post('/api/ctv-app/get-imported-videos', formdata);
    return response.data;
} 

const getCtvVideos = async (formdata) => {
    console.log("getCtvVideos : ", formdata);
    const response = await axios.post('/api/ctv-app/get-videos-list', formdata);
    return response.data;
} 
const insertCtvVideoImporter = async (formdata)=>{
    console.log(formdata);
    const response = await axios.post('/api/ctv-app/insert-ctv-importer-data', formdata);
    return response.data;
}

const getVideoDetails = async (formdata) => {
    const response = await axios.post('/api/ctv-app/get-video-details', formdata);
    return response.data;
}

const saveVideo = async (formdata) => {
    const response = await axios.post('/api/ctv-app/save-video', formdata);
    return response.data;
}
const syncVideo = async (formdata) => {
    const response = await axios.post('/api/ctv-app/sync-video', formdata);
    return response.data;
}

const updateVideoStatus = async (formData) => {
    const response = await axios.post('/api/ctv-app/update-video-status', formData);
    return response.data;
}

const getImporterDetails = async (formdata) => {
    const response = await axios.post('/api/ctv-app/get-importer-details', formdata);
    return response.data;
}

const saveImportVideos = async (formdata) => {
    const response = await axios.post('/api/ctv-app/save-importer', formdata);
    return response.data;
}

const getImporterLogsList = async (formdata) => {
    const response = await axios.post('/api/ctv-app/importer-logs',formdata);
    return response.data;
}
const getConfigSchema = async(formdata) => {
    const response = await axios.post('/api/ctv-app/get-config-schema',formdata);
    return response.data;
}

const getSavedConfig = async(formdata) => {
    const response = await axios.post('/api/ctv-app/get-saved-config',formdata);
    return response.data;
}

const storeAndroidConfig = async(formdata) => {
    const response = await axios.post('/api/ctv-app/store-android-config',formdata);
    return response.data;
}

const storeIosConfig = async(formdata) => {
    const response = await axios.post('/api/ctv-app/store-ios-config',formdata);
    return response.data;
}

const getAppConfigVersions = async (formdata) =>{
    const response =  await axios.post('/api/ctv-app/app-config-versions',formdata);
    return response.data;
}

const publishAppConfig = async (formdata) =>{
    const response =  await axios.post('/api/ctv-app/publish-app-config',formdata);
    return response.data;
}

const createAppConfig = async (formdata) => {
    const response = await axios.post('/api/ctv-app/create-app-config',formdata);
    return response.data;
}

const getMultiSelectArray = async(formdata) => {
    const response = await axios.post('/api/ctv-app/get-livetvs-array',formdata);
    return response.data;
}

export default {
    getCTVAppGeneralSettings,
    saveCTVAppGeneralSettings,
    getProjectDistribution,
    getCTVAppConfig,
    saveCTVAppConfig,
    reorderSections,
    addCategory,
    getVideoCategories,
    getCategoryList,
    reorderCategories,
    getCategoryDetails,
    updateCategoryStatus,
    getCtvImportedVideos,
    insertCtvVideoImporter,
    getCtvVideos,
    getVideoDetails,
    saveVideo,
    syncVideo,
    updateVideoStatus,
    getImporterDetails,
    saveImportVideos,
    getImporterLogsList,
    getConfigSchema,
    getSavedConfig,
    storeAndroidConfig,
    getAppConfigVersions,
    publishAppConfig,
    createAppConfig,
    storeIosConfig,
    getMultiSelectArray,
}
import React from "react";
import WebFont from 'webfontloader';

import template1Media from '../../assets/img/webstory/1.mp4';
import template2Media from '../../assets/img/webstory/2.webm';
import template3Media from '../../assets/img/webstory/3.webm';
import template4Media from '../../assets/img/webstory/template4.jpg';
import template5Media from '../../assets/img/webstory/template5.jpg';
import template6Media from '../../assets/img/webstory/template6.jpg';
import template7Media from '../../assets/img/webstory/template7.jpg';
import template8Media from '../../assets/img/webstory/template8.jpg';
import template9Media from '../../assets/img/webstory/template9.jpg';
import template10Media from '../../assets/img/webstory/template10.png';
import template11Media from '../../assets/img/webstory/template11.png';
import template12Media from '../../assets/img/webstory/template12.jpg';
import template13Media from '../../assets/img/webstory/template13.jpg';
import template14Media from '../../assets/img/webstory/template14.jpg';
import template15Media from '../../assets/img/webstory/template15.jpg';
import template5logo from '../../assets/img/webstory/logo.png';
import webstoryService from "../../services/api/web-story-service";

import imageSVG from '../../assets/img/webstory/icons/image_icon.png';
import videoSVG from '../../assets/img/webstory/icons/video.svg';
import imageByUrlSVG from '../../assets/img/webstory/icons/image_by_url.png';

const storypagelimit = 20;
const descriptionWordlimit = 280;
const headlinesWordlimit = 90;
const paragraphWordlimit = 4;

const payloadSizeLimit = 4000000;// a palyoad larger than 4Mb cannot be sent

const imageUploadLimit = 2000000; // can be set to different limits for images 
const upperUploadLimit = 15000000;// can be set to different limits for videos 

const embedTypes = [
    {
        label: 'Iframe',
        value:'iframe'
    }, 
    {
        label: 'Twitter Embed',
        value:'tweetURL'
    }, 
    {
        label: 'Instagram Embed',
        value:'instagramURL'
    }];

// const supportedFileTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'video/mp4' ];
const supportedFileTypes = ['image/jpeg', 'image/jpg', 'image/png', 'video/mp4', 'image/webp' ];
const acceptedPosterImageTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/webp' ];
const allowedFileTypes = {
    video: ['video/mp4'],
    image:  ['image/jpeg', 'image/jpg', 'image/png', 'image/webp']
}
const autoAdvance = {
    max:30,
    min:0
}

const createMetaFor =  (label, value, type, status=true) => {
    return  {
        "component_name" : label,
        "component_value" : value,
        "status" : status,
        "component_type" : type
    }
}
const getAppIcon = async (projectId) => {
    try {
        console.log("projectId", projectId);
        const res = await webstoryService.getAppIcon({projectId});
        if(!res.status|| !res.data) {
            console.log("No App Icon");
            return false; 
        }
        if(res.data.appicon) {
            return res.data.appicon;
        }
        return false;
        
    } catch (err){
        console.log(":: Failed to Get App Icon ::",err);
        return {err};
    }
}
const getMediaIcon = (storyPage)=> {
    
    if(storyPage.background_image) {
        return <div className='media-icon' title="Uploaded Image">
                  <img style={{width:'100%'}} src={imageSVG} />
              </div>
    }
    if(storyPage.background_image_by_url) {
      return <div className='media-icon' title="Image By Url">
              <img style={{width:'100%'}} src={imageByUrlSVG} />
            </div>
    }
    if(storyPage.background_video) {
      return <div className='media-icon' title="Video">
              <img style={{width:'100%'}} src={videoSVG} />
            </div>
    }
  }

const getDefaultFont = async (projectId) => {
    try {
        console.log("projectId", projectId);
        const res = await webstoryService.getDefaultFont({projectId});
        if(!res.status|| !res.data) {
            console.log("No App Icon");
            return false; 
        }
        if(res.data.defaultFont) {
            return res.data.defaultFont;
        }
        return false;
        
    } catch (err){
        console.log(":: Failed to Get App Icon ::",err);
        return {err};
    }
}
const loadFonts = async (value) => {
    console.log('ujere')
    if(value) {
        WebFont.load({
            google: {
              families: [value]
            }
        });
    }
}

const colorPickerColors = ['#FF6900', '#FCB900', '#7BDCB5', '#00D084', '#8ED1FC', '#0693E3', '#ABB8C3', '#EB144C', '#F78DA7', '#9900EF'];

const template1 =  {
    title:`<p>Lorem Ipsum new dummy text...</p>`,
    description:'<p>Lorem ipsum dolor sit amet. Eum aliquam voluptas a unde reiciendis est omnis quia nulla.</p>',
    image: template1Media,
    media_type: 'video',// this field has only 'video' value or does not exist
    name:'default', 
    font:'',
    css:{
        containerCss:  {
            width: '100%',
            background: 'linear-gradient(to top,rgb(0,0,0,1),rgb(0,0,0,.8),rgb(0,0,0,.6),rgb(0,0,0,.4),rgb(0,0,0,.2),transparent)',
            left:0,
            bottom:0,
            padding: '15% 5% 0',
            borderRadius: '0 0 2px 2px'
        },
        headlinesCss:{
            fontSize: '17px',
            "color":"white",
            "fontWeight":"bolder",
            
            margin: "2% 5% 6% 5%",
            width:"90%",
            position:"relative",
            height:"auto",
            bottom:0,
            minHeight: "19%",
            whiteSpace:"normal",
            wordBreak: "break-word"
        }, 
        descriptionCss:{
            borderLeft: '2px solid red',
            margin: "2% 5% 14% 5%",
            width:"90%",
            position:"relative",
            height:"auto",
            "color":"white",
            bottom:0,
            paddingLeft:'4%',
            wordBreak: "break-word"
        }
    },
    cssObj:{
        containerCss:  `width: 100%;background: linear-gradient(to top,rgb(0,0,0,1),rgb(0,0,0,.8),rgb(0,0,0,.6),rgb(0,0,0,.4),rgb(0,0,0,.2),transparent);left:0;bottom:0;position:absolute; padding: 15% 5% 0;`,
        headlinesCss:`font-size: 17px;color:white;font-weight:bolder;margin: 2% 5% 14% 5%;width:90%;position:relative;height:auto;bottom:0px;white-space:normal;line-height:1.5;word-break: break-word;`,
        descriptionCss:`border-left: 2px solid red;margin: 2% 5% 14% 5%;width:90%;position:relative;height:auto;color:white;bottom:0px;padding-left:4%;line-height:1.3;word-break: break-word;`
        }
}

const template2 = {
    title:'<p>Lorem Ipsum new dummy text...</p>',
    description:'<p>Lorem ipsum dolor sit amet. Eum aliquam voluptas a unde reiciendis est omnis quia nulla.</p>',
    image: template2Media,
    media_type: 'video',
    name:'hagrid', 
    font:'',
    css:{
        containerCss:  {
            "minHeight":"24%",
            "width":"90%",
            "background":"rgba(208, 0, 0, 0.8)",
            "padding":"4%",
            "bottom":10,
            "borderRadius":5,
            "bottom":10,
            "left":0,
            "right":0,
            "margin":'auto',
        },
        headlinesCss:{
            "fontSize":15,
            "color":"white",
            "fontWeight":"bolder",
            
            wordBreak: "break-word"
        }, 
        descriptionCss:{
            "color":"white",
            wordBreak: "break-word"
        }
    },
    cssObj:{
        containerCss:`min-height: 24%;width: 90%;position: absolute;background: #cf0000;padding: 4%;bottom: 10px;border-radius: 5px;bottom: 10px;left: 0px;right: 0px;margin: auto;line-height:1.2;`,
        headlinesCss:`font-size:19px;color:white;font-weight:bolder;line-height:1.5;word-break: break-word;`,
        descriptionCss:`color:white;line-height:1.2;word-break: break-word;`
    }
}

const template3 = {
    title:'<p>Lorem Ipsum new dummy text...</p>',
    description:'<p>Lorem ipsum dolor sit amet. Eum aliquam voluptas a unde reiciendis est omnis quia nulla.</p>',
    image: template3Media,
    media_type: 'video',
    name:'template3', 
    font:'',
    css:{
        containerCss:  {
            "minHeight":"24%",
            "width":"100%",
            padding: "9% 4% 4%",
            "background":"#5451e0b3",
            "bottom":"0",
            "borderRadius":"17% 17% 2px 2px",
            "left":"0px","right":"0px",
            "margin":"auto",
        },
        headlinesCss:{
            "fontSize":19,
            "color":"white",
            "fontWeight":"bolder",
            
            wordBreak: "break-word"
        }, 
        descriptionCss:{
            "color":"white",
            wordBreak: "break-word"
        }
    },
    cssObj:{
        containerCss:`min-height: 24%;width: 100%;padding: 4%;background: #5451e0b3;bottom: 0;border-radius: 17% 17% 9px 9px;left: 0px;right: 0px;margin: auto;border-top: 11px solid #5451e00f;position:absolute`,
        headlinesCss:`font-size:19px;color:white;font-weight:bolder;line-height:1.5;word-break: break-word;`,
        descriptionCss:`color:white;line-height:1.3;word-break: break-word;`
    }
}

const template4 =  {
    title:`<p>Lorem Ipsum new dummy text...</p>`,
    description:'<p>Lorem ipsum dolor sit amet. Eum aliquam voluptas a unde reiciendis est omnis quia nulla.</p>',
    image: template4Media,
    // media_type: 'image',
    name:'template4', 
    font:'',
    css:{
        containerCss:  {
            width: '100%',
            left:0,
            bottom:0,
            padding: '15% 5% 0',
            borderRadius: '0 0 8px 8px'
        },
        headlinesCss:{
            fontSize: '17px',
            "color":"white",
            "fontWeight":"bolder",
            
            margin: "2% 5% 6% 5%",
            width:"90%",
            position:"relative",
            height:"auto",
            bottom:0,
            minHeight: "19%",
            whiteSpace:"normal",
            wordBreak: "break-word"
        }, 
        descriptionCss:{
            margin: "2% 5% 14% 5%",
            width:"90%",
            position:"relative",
            height:"auto",
            "color":"white",
            bottom:0,
            wordBreak: "break-word"
        }
    },
    cssObj:{
        containerCss:  `width: 100%;left:0;bottom:0;position:absolute; padding: 15% 5% 0;`,
        headlinesCss:`font-size: 17px;color:white;font-weight:bolder;margin: 2% 5% 14% 5%;width:90%;position:relative;height:auto;bottom:0px;white-space:normal;line-height:1.5;word-break: break-word;`,
        descriptionCss:`margin: 2% 5% 14% 5%;width:90%;position:relative;height:auto;color:white;bottom:0px;line-height:1.3;word-break: break-word;`
        }
}
 
const template5 =  {
    title:`<p style="margin:0">Lorem Ipsum new text...</p>`,
    description:'<p>Lorem ipsum dolor sit amet. Eum aliquam voluptas a unde reiciendis est omnis quia nulla.</p>',
    image: template5Media,
    logo: template5logo,
    // media_type: 'video',
    name:'template5', 
    font:'',
    css:{
        containerCss:  {
            width: '100%',
            background: 'linear-gradient(to top,rgb(0,0,0,1),rgb(0,0,0,.8),rgb(0,0,0,.6),rgb(0,0,0,.4),rgb(0,0,0,.2),transparent)',
            left:0,
            bottom:0,
            padding: '0 5% 12%',
            borderRadius: '0 0 2px 2px',
            flexDirection: 'row',
            alignItems: 'center'
        },
        headlinesCss:{
            fontSize: '17px',
            "color":"white",
            borderLeft: '2px solid red',
            "fontWeight":"bolder",
            
            margin: "0 0 0 3%",
            width:"77%",
            position:"relative",
            height:"auto",
            bottom:0,
            minHeight: "19%",
            whiteSpace:"normal",
            wordBreak: "break-word",
            paddingLeft:'4%',
        }, 
        descriptionCss:{
            "color":"white",
            borderLeft: '2px solid red',
            margin: "0 0 0 3%",
            width:"100%",
            position:"relative",
            height:"auto",
            bottom:0,
            minHeight: "19%",
            whiteSpace:"normal",
            wordBreak: "break-word",
            paddingLeft:'4%',
        },
        logoCss: {
            width: "20%",
            height:" max-content"
        },
        qlSnowCorrectionCss: { // only exist on react side, to show text correctly
            flexGrow:1
        }
    },
    cssObj:{
        containerCss:  `width:100%;background:linear-gradient(to top,#000,rgba(0,0,0,.8),rgba(0,0,0,.6),rgba(0,0,0,.4),rgba(0,0,0,.2),transparent);left:0;bottom:0;padding:0 5% 12%;border-radius:0 0 2px 2px;flex-direction:row;align-items:center;position:absolute;display:flex;`,
        headlinesCss:`font-size:17px;color:#fff;border-left:2px solid red;font-weight:bolder;margin:0 0 0 3%;width:77%;position:relative;height:auto;bottom:0;min-height:19%;white-space:normal;word-break:break-word;padding-left:4%;`,
        descriptionCss:`color:#fff;border-left:2px solid red;margin:0 0 0 3%;width:100%;position:relative;height:auto;bottom:0;min-height:19%;white-space:normal;word-break:break-word;padding-left:4%;`,
        logoCss: `width:20%;height:max-content;`
    }
}
const template6 =  {
    title:`<p style="margin-bottom:0" >Lorem Ipsum new dummy text...</p>`,
    description:'<p>Lorem ipsum dolor sit amet. Eum aliquam voluptas a unde reiciendis est omnis quia nulla.</p>',
    image: template6Media,
    // media_type: 'video',
    name:'template6', 
    font:'',
    css:{
        containerCss:  {
            "width":"90%","background":"rgb(255, 204, 0)","right":"0px","bottom":"5%","padding":"5% 2% 5% 20%","borderRadius":"50% 0px 0px 50%","minHeight":"27%","justifyContent":"center"
        },
        headlinesCss:{
            fontSize: '17px',
            "color":"black",
            "fontWeight":"bolder",
            
            margin: 0,
            width:"90%",
            position:"relative",
            height:"auto",
            bottom:0,
            minHeight: "19%",
            whiteSpace:"normal",
            wordBreak: "break-word",
            width:"100%"
        }, 
        descriptionCss:{
            margin: 0,
            width:"100%",
            position:"relative",
            height:"auto",
            "color":"black",
            bottom:0,
            paddingLeft:'4%',
            wordBreak: "break-word"
        }
    },
    cssObj:{
        containerCss:  `position:absolute;border-radius:50% 0 0 50%;width:90%;background:#fc0;right:0;bottom:5%;padding:5% 2% 5% 20%;min-height:27%;align-items:center;display:flex`,
        headlinesCss:`font-size: 17px;color:black;font-weight:bolder;margin: 0;width:90%;position:relative;height:auto;bottom:0px;white-space:normal;line-height:1.5;word-break: break-word;`,
        descriptionCss:`margin: 0;width:90%;position:relative;height:auto;color:#000000;bottom:0px;padding-left:4%;line-height:1.3;word-break: break-word;`
        }
}
const template7 =  {
    title:`<p style="margin-bottom:0" >Lorem Ipsum new dummy text...</p>`,
    description:'<p>Lorem ipsum dolor sit amet. Eum aliquam voluptas a unde reiciendis est omnis quia nulla.</p>',
    image: template7Media,
    // media_type: 'video',
    name:'template7', 
    font:'',
    css:{
        containerCss:  {
            "width":"90%","background":"rgb(33, 150, 243)","right":"5%","bottom":"5%","position":"absolute","padding":"10% 13%","borderRadius":"60%","justifyContent":"center","minHeight":"30%"
        },
        headlinesCss:{
            "fontSize":"17px","color":"white","fontWeight":"bolder","margin":"0","width":"100%","position":"relative","height":"auto","bottom":"0px","whiteSpace":"normal","wordBreak":"break-word"
        }, 
        descriptionCss:{
            margin: 0,
            width:"100%",
            position:"relative",
            height:"auto",
            "color":"white",
            bottom:0,
            wordBreak: "break-word"
        }
    },
    cssObj:{
        containerCss:  `width: 90%;background: #2196f3;right: 5%;bottom: 5%;position:absolute;padding: 10% 13%;border-radius: 60%;`,
        headlinesCss:`font-size:17px;color:white;font-weight:bolder;margin:0;width:100%;position:relative;height:auto;bottom:0;white-space:normal;line-height:1.5;word-break:break-word`,
        descriptionCss:`margin:0;width:100%;position:relative;height:auto;color:#fff;bottom:0;padding-left:4%;line-height:1.3;word-break:break-word;`
    }
}

const template8 =  {
    title:`<p>Lorem Ipsum new dummy text...</p>`,
    description:'<p>Lorem ipsum dolor sit amet. Eum aliquam voluptas a unde reiciendis est omnis quia nulla.</p>',
    image: template8Media,
    // media_type: 'video',
    name:'template8', 
    font:'',
    css:{
        containerCss:  {
            "width":"100%","background":"linear-gradient(to top, #2c711a, #52c234, transparent)","left":"0","bottom":"0","padding":"15% 5% 0"
        },
        headlinesCss:{
            fontSize: '17px',
            "color":"white",
            "fontWeight":"bolder",
            
            margin: "2% 5% 6% 5%",
            width:"90%",
            position:"relative",
            height:"auto",
            bottom:0,
            minHeight: "19%",
            whiteSpace:"normal",
            wordBreak: "break-word"
        }, 
        descriptionCss:{
            margin: "2% 5% 14% 5%",
            width:"90%",
            position:"relative",
            height:"auto",
            "color":"white",
            bottom:0,
            paddingLeft:'4%',
            wordBreak: "break-word"
        }
    },
    cssObj:{
        containerCss:  `width: 100%;background: linear-gradient(to top, #2c711a, #52c234, transparent);left:0;bottom:0;position:absolute;padding: 15% 5% 0;`,
        headlinesCss:`font-size: 17px;color:white;font-weight:bolder;margin: 2% 5% 14% 5%;width:90%;position:relative;height:auto;bottom:0px;white-space:normal;line-height:1.5;word-break: break-word;`,
        descriptionCss:`margin: 2% 5% 14% 5%;width:90%;position:relative;height:auto;color:white;bottom:0px;padding-left:4%;line-height:1.3;word-break: break-word;`
        }
}
const template9 =  {
    title:`<p>Lorem Ipsum new dummy text...</p>`,
    description:'<p>Lorem ipsum dolor sit amet. Eum aliquam voluptas a unde reiciendis est omnis quia nulla.</p>',
    image: template9Media,
    // media_type: 'video',
    name:'template9', 
    font:'',
    css:{
        containerCss:  {
            "width":"100%","background":"linear-gradient(to top, #ff0099, transparent)","left":"0","bottom":"0","position":"absolute","padding":"35% 5% 0%"
        },
        headlinesCss:{
            fontSize: '17px',
            "color":"white",
            "fontWeight":"bolder",
            
            margin: "2% 5% 6% 5%",
            width:"90%",
            position:"relative",
            height:"auto",
            bottom:0,
            minHeight: "19%",
            whiteSpace:"normal",
            wordBreak: "break-word"
        }, 
        descriptionCss:{
            margin: "2% 5% 14% 5%",
            width:"90%",
            position:"relative",
            height:"auto",
            "color":"white",
            bottom:0,
            paddingLeft:'4%',
            wordBreak: "break-word"
        }
    },
    cssObj:{
        containerCss:  `width: 100%;background: linear-gradient(to top, #ff0099, transparent);left:0;bottom:0;position:absolute;padding: 35% 5% 0%;`,
        headlinesCss:`font-size: 17px;color:white;font-weight:bolder;margin: 2% 5% 14% 5%;width:90%;position:relative;height:auto;bottom:0px;white-space:normal;line-height:1.5;word-break: break-word;`,
        descriptionCss:`margin: 2% 5% 14% 5%;width:90%;position:relative;height:auto;color:white;bottom:0px;padding-left:4%;line-height:1.3;word-break: break-word;`
        }
}
const template10 =  {
    title:`<p>Lorem Ipsum new dummy text...</p>`,
    description:'<p>Lorem ipsum dolor sit amet. Eum aliquam voluptas a unde reiciendis est omnis quia nulla.</p>',
    image: template10Media,
    // media_type: 'video',
    name:'template10', 
    font:'',
    css:{
        containerCss:  {
            "width":"100%","background":"linear-gradient(to top, #ff00cc, #333399, transparent)","left":"0","bottom":"0","position":"absolute","padding":"40% 5% 0%"
        },
        headlinesCss:{
            fontSize: '17px',
            "color":"white",
            "fontWeight":"bolder",
            
            margin: "2% 5% 6% 5%",
            width:"90%",
            position:"relative",
            height:"auto",
            bottom:0,
            minHeight: "19%",
            whiteSpace:"normal",
            wordBreak: "break-word"
        }, 
        descriptionCss:{
            margin: "2% 5% 14% 5%",
            width:"90%",
            position:"relative",
            height:"auto",
            "color":"white",
            bottom:0,
            paddingLeft:'4%',
            wordBreak: "break-word"
        }
    },
    cssObj:{
        containerCss:  `width: 100%;background: linear-gradient(to top, #ff00cc, #333399, transparent);left:0;bottom:0;position:absolute;padding: 40% 5% 0%;`,
        headlinesCss:`font-size: 17px;color:white;font-weight:bolder;margin: 2% 5% 14% 5%;width:90%;position:relative;height:auto;bottom:0px;white-space:normal;line-height:1.5;word-break: break-word;`,
        descriptionCss:`margin: 2% 5% 14% 5%;width:90%;position:relative;height:auto;color:white;bottom:0px;padding-left:4%;line-height:1.3;word-break: break-word;`
        }
}

const template11 =  {
    title:`<p>Lorem Ipsum new dummy text...</p>`,
    description:'<p>Lorem ipsum dolor sit amet. Eum aliquam voluptas a unde reiciendis est omnis quia nulla.</p>',
    image: template11Media,
    // media_type: 'video',
    name:'template11', 
    font:'',
    css:{
        containerCss:  {
            "bottom":"0", width: "100%"
                        // "width":"80%","background":"rgba(0, 92, 151, 0.8)","left":"10%","top":"11%","position":"absolute","padding":"25% 5%","minHeight":"50%","borderRadius":"3%","justifyContent":"center","alignItems":"center"
        },
        headlinesCss:{
            "width": "80%","background":"rgba(0, 92, 151, 0.8)","position":"relative","padding":"25% 5%","minHeight":"67%","borderRadius":"3%","justifyContent":"center","alignItems":"center","fontSize":"17px","color":"white","fontWeight":"bolder","whiteSpace":"normal","wordBreak":"break-word","marginBottom":"30%","marginLeft":"10%"
            // fontSize: '17px',
            // "color":"white",
            // "fontWeight":"bolder",
            // 
            // margin: 0,
            // width:"90%",
            // position:"relative",
            // height:"auto",
            // bottom:0,
            // minHeight: "19%",
            // whiteSpace:"normal",
            // wordBreak: "break-word"
        }, 
        descriptionCss:{
            "width":"95%","position":"relative","height":"auto","color":"white","marginLeft":"2.5%","bottom":"0px","wordBreak":"break-word","padding":"5%","background":"rgba(0, 92, 151, 0.8)","borderTop":"2px solid"
        }
    },
    cssObj:{
        containerCss:  `bottom: 0px; width: 100%;position:absolute;`,
        headlinesCss:`width: 80%; background: rgba(0, 92, 151, 0.8); position: relative; padding: 25% 5%; min-height: 67%; border-radius: 3%; justify-content: center; align-items: center; font-size: 17px; color: white; font-weight: bolder;  white-space: normal; word-break: break-word; margin-bottom: 30%; margin-left: 10%;`,
        descriptionCss:`width: 95%; position: relative; height: auto; color: white; margin-left: 2.5%; bottom: 0px; word-break: break-word; padding: 5%; background: rgba(0, 92, 151, 0.8); border-top: 8px solid;`
    }
}

const template12 =  {
    title:`<p>Lorem Ipsum new dummy text...</p>`,
    description:'<p>Lorem ipsum dolor sit amet. Eum aliquam voluptas a unde reiciendis est omnis quia nulla.</p>',
    image: template12Media,
    // media_type: 'video',
    name:'template12', 
    font:'',
    css:{
        containerCss:  {
            "width":"100%","background":"linear-gradient(to top, #ee9ca7, #ffdde1, transparent)","left":"0","bottom":"0","position":"absolute","padding":"41% 5% 0"
        },
        headlinesCss:{
            fontSize: '17px',
            "color":"#222222",
            "fontWeight":"bolder",
            
            margin: "2% 5% 6% 5%",
            width:"90%",
            position:"relative",
            height:"auto",
            bottom:0,
            minHeight: "19%",
            whiteSpace:"normal",
            wordBreak: "break-word"
        }, 
        descriptionCss:{
            margin: "2% 5% 14% 5%",
            width:"90%",
            position:"relative",
            height:"auto",
            "color":"#222222",
            bottom:0,
            paddingLeft:'4%',
            wordBreak: "break-word"
        }
    },
    cssObj:{
        containerCss:  `width: 100%;background: linear-gradient(to top, #ee9ca7, #ffdde1, transparent);left:0;bottom:0;position:absolute;padding: 41% 5% 0;`,
        headlinesCss:`font-size: 17px;color:#222222;font-weight:bolder;margin: 2% 5% 14% 5%;width:90%;position:relative;height:auto;bottom:0px;white-space:normal;line-height:1.5;word-break: break-word;`,
        descriptionCss:`margin: 2% 5% 14% 5%;width:90%;position:relative;height:auto;color:#222222;bottom:0px;padding-left:4%;line-height:1.3;word-break: break-word;`
        }
}

const template13 =  {
    title:`<p>Lorem Ipsum new dummy text...</p>`,
    description:'<p>Lorem ipsum dolor sit amet. Eum aliquam voluptas a unde reiciendis est omnis quia nulla.</p>',
    image: template13Media,
    // media_type: 'video',
    name:'template13', 
    font:'',
    css:{
        containerCss:  {
            "width":"100%","background":"linear-gradient(to top, #f12711, #f5af19, transparent)","left":"0","bottom":"0","position":"absolute","padding":"40% 5% 0"
        },
        headlinesCss:{
            fontSize: '17px',
            "color":"white",
            "fontWeight":"bolder",
            
            margin: "2% 5% 6% 5%",
            width:"90%",
            position:"relative",
            height:"auto",
            bottom:0,
            minHeight: "19%",
            whiteSpace:"normal",
            wordBreak: "break-word"
        }, 
        descriptionCss:{
            margin: "2% 5% 14% 5%",
            width:"90%",
            position:"relative",
            height:"auto",
            "color":"white",
            bottom:0,
            paddingLeft:'4%',
            wordBreak: "break-word"
        }
    },
    cssObj:{
        containerCss:  `width: 100%;background: linear-gradient(to top, #f12711, #f5af19, transparent);left:0;bottom:0;position:absolute;padding: 40% 5% 0;`,
        headlinesCss:`font-size: 17px;color:white;font-weight:bolder;margin: 2% 5% 14% 5%;width:90%;position:relative;height:auto;bottom:0px;white-space:normal;line-height:1.5;word-break: break-word;`,
        descriptionCss:`margin: 2% 5% 14% 5%;width:90%;position:relative;height:auto;color:white;bottom:0px;padding-left:4%;line-height:1.3;word-break: break-word;`
        }
}

const template14 =  {
    title:`<p>Lorem Ipsum new dummy text...</p>`,
    description:'<p>Lorem ipsum dolor sit amet. Eum aliquam voluptas a unde reiciendis est omnis quia nulla.</p>',
    image: template14Media,
    // media_type: 'video',
    name:'template14', 
    font:'',
    css:{
        containerCss:  {
            "width":"100%","background":"linear-gradient(to bottom, transparent, #1e9600, #fff200, #ff0000)","left":"0","bottom":"0","position":"absolute","padding":"38% 5% 0"
        },
        headlinesCss:{
            fontSize: '17px',
            "color":"#000000",
            "fontWeight":"bolder",
            
            margin: "2% 5% 6% 5%",
            width:"90%",
            position:"relative",
            height:"auto",
            bottom:0,
            minHeight: "19%",
            whiteSpace:"normal",
            wordBreak: "break-word"
        }, 
        descriptionCss:{
            margin: "2% 5% 14% 5%",
            width:"90%",
            position:"relative",
            height:"auto",
            "color":"#000000",
            bottom:0,
            paddingLeft:'4%',
            wordBreak: "break-word"
        }
    },
    cssObj:{
        containerCss:  `width: 100%;background: linear-gradient(to bottom, transparent, #1e9600, #fff200, #ff0000);left:0;bottom:0;position:absolute;padding: 38% 5% 0;`,
        headlinesCss:`font-size: 17px;color:black;font-weight:bolder;margin: 2% 5% 14% 5%;width:90%;position:relative;height:auto;bottom:0px;white-space:normal;line-height:1.5;word-break: break-word;`,
        descriptionCss:`margin: 2% 5% 14% 5%;width:90%;position:relative;height:auto;color:#000000;bottom:0px;padding-left:4%;line-height:1.3;word-break: break-word;`
        }
}

const template15 =  {
    title:`<p>Lorem Ipsum new dummy text...</p>`,
    description:'<p>Lorem ipsum dolor sit amet. Eum aliquam voluptas a unde reiciendis est omnis quia nulla.</p>',
    image: template15Media,
    // media_type: 'video',
    name:'template15', 
    font:'',
    css:{
        containerCss:  {
            "width":"100%","background":"linear-gradient(to top, #636363, #a2ab58, transparent)","left":"0","bottom":"0","position":"absolute","padding":"38% 5% 0"
        },
        headlinesCss:{
            fontSize: '17px',
            "color":"white",
            "fontWeight":"bolder",
            
            margin: "2% 5% 6% 5%",
            width:"90%",
            position:"relative",
            height:"auto",
            bottom:0,
            minHeight: "19%",
            whiteSpace:"normal",
            wordBreak: "break-word"
        }, 
        descriptionCss:{
            margin: "2% 5% 14% 5%",
            width:"90%",
            position:"relative",
            height:"auto",
            "color":"white",
            bottom:0,
            paddingLeft:'4%',
            wordBreak: "break-word"
        }
    },
    cssObj:{
        containerCss:  `width: 100%;background: linear-gradient(to top, #636363, #a2ab58, transparent);left:0;bottom:0;position:absolute;padding: 38% 5% 0;`,
        headlinesCss:`font-size: 17px;color:white;font-weight:bolder;margin: 2% 5% 14% 5%;width:90%;position:relative;height:auto;bottom:0px;white-space:normal;line-height:1.5;word-break: break-word;`,
        descriptionCss:`margin: 2% 5% 14% 5%;width:90%;position:relative;height:auto;color:white;bottom:0px;padding-left:4%;line-height:1.3;word-break: break-word;`
        }
}
const templates = {
    "default": template1,
    "hagrid":template2,
    "template3":template3,
    "template4":template4,
    "template5":template5,
    template6:template6,
    template7:template7,
    template8:template8,
    template9:template9,
    template10:template10,
    template11:template11,
    template12:template12,
    template13:template13,
    template14:template14,
    template15:template15
}
const templateArr = [template1, template2, template3, template4, template5, template6,template7, template8, template9, template10, template11, template12, template13,template14, template15];

export{
    templates,
    storypagelimit,
    colorPickerColors,
    descriptionWordlimit,
    paragraphWordlimit,
    headlinesWordlimit,
    supportedFileTypes,
    templateArr,
    payloadSizeLimit,
    embedTypes,
    acceptedPosterImageTypes,
    createMetaFor,
    upperUploadLimit, 
    getAppIcon,
    autoAdvance,
    imageUploadLimit,
    allowedFileTypes,
    getMediaIcon,
    getDefaultFont,
    loadFonts
}
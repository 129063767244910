import React, { useEffect, useState, useContext } from "react";
import contentService from "../../services/api/content-service";
import {useHistory} from "react-router-dom";
import ProjectContext from '../../ProjectContext';
import './dashboard.css';

// reactstrap components
import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Spinner,
  Row,
  Col,
} from "reactstrap";

const ArticlesCountWidget = (props)=> {
  const projectDetails = useContext(ProjectContext);
  const history = new useHistory();
  
  if(projectDetails.id==''){
    history.push("/project/allprojects");
  }
  const projectId = projectDetails.id;
  const [formdetails, setFormDetails] = useState({categoryids:[],title:'',status:true});
  const [totalArticles,setTotalArticles] = useState(0);
  const [renderComponent,setRenderComponent] = useState(false);
  const [reloadComponent,setReloadComponent] = useState(0);
 
  useEffect( () => {
    (async () =>{
      let getTotalArticles = await contentService.getTotalArticles(projectId,formdetails);
      if(getTotalArticles.status){
          setTotalArticles(getTotalArticles.data.count);
      }
      setRenderComponent(true);
        })();
    }, [reloadComponent]);  
 

    return (
      <Card className="card-stats zooM">
          <div className="icnBx">
            <i className="fa fa-file-image-o " />
          </div>
          <div className="numbers">
            <h2 className="card-category">Articles</h2>
            <p>
                {!renderComponent?
                <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
              :totalArticles}
            </p>
          </div>
      </Card>
    );
  
  
}

export default ArticlesCountWidget;

import React from "react";
import { useHistory } from "react-router-dom";
import {
    Card,
    CardBody,
    Container,
    Button,
    CardImg
} from "reactstrap";

import errorImg from '../../assets/img/404.png';

const PageNotMatched = (props) => {
    const history = new useHistory();
    const buttonStyle = {
        border: "solid #2CCF6D",
        background: "white",
        color: "black",
        fontWeight: "bold",
        width: "160px",
    }
    const changeBackGroundColor = (event) => {
        console.log("change background color");
    }
    return (
        <div className="content">
            <Container>
                <Card>
                    <CardBody style={{ textAlign: 'center' }}>
                        <h2>Page Not Found</h2>

                        <div>
                            <CardImg
                                alt="Card image cap"
                                src={errorImg}
                                top
                                style={{ width: '50%' }}
                            />
                        </div>
                        <Button
                            className="btn-round"
                            style={buttonStyle}
                            onMouseOver={changeBackGroundColor}
                            size="sm"
                            type="button"
                            color="success"
                            onClick={(event) => { history.push("/dashboard/home") }}
                        >
                        Go Back To Home
                        </Button>
                    </CardBody>
                </Card>
            </Container>
        </div>
    )
}

export default PageNotMatched;
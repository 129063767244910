import React, { useEffect, useState } from "react";
import formValidations from "../../variables/form-validations";
import authService from "../../services/api/auth-service.js";
import { useHistory } from "react-router-dom";
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Label,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Col,
    Row,
} from "reactstrap";

const Login = (props) => {
    const history = new useHistory();
    const [formdetails, setFormDetails] = useState({ password: "", email: "" });
    const [passwordstate, setPasswordState] = useState("");
    const [passworderror, setPasswordError] = useState("");
    const [emailstate, setEmailState] = useState("");
    const [emailerror, setEmailError] = useState("");
    const [errorstate, setErrorState] = useState("has-success");
    const [errormessage, setErrorMessage] = useState(
        "There was an error. Please try again."
    );

    useEffect(() => {
        //document.body.classList.toggle("login-page");
        //document.body.className = "register-page";
    }, []);

    const handleChange = (event) => {
        setFormDetails({
            ...formdetails,
            [event.target.id]: event.target.value.trim(),
        });

        if (event.target.id == "password") {
            setPasswordState("has-success");
        }

        if (event.target.id == "email") {
            setEmailState("has-success");
        }
    };

    const handleBlur = (event) => {
        setFormDetails({
            ...formdetails,
            [event.target.id]: event.target.value.trim(),
        });

        // if(event.target.id=='password'){
        //     validatePassword();
        // }

        // if(event.target.id=='email'){
        //     validatePublisherEmail();
        // }
    };

    const validatePassword = () => {
        if (formdetails.password === "") {
            setPasswordState("has-danger");
            setPasswordError("Password is required");
            return false;
        }

        if (!formValidations.verifyLength(formdetails.password, 6)) {
            setPasswordState("has-danger");
            setPasswordError("Password should be of minimum 6 digits.");
            return false;
        }

        setPasswordState("has-success");
        return true;
    };

    const validatePublisherEmail = () => {
        if (formdetails.email === "") {
            setEmailState("has-danger");
            setEmailError("Email is required");
            return false;
        }

        if (!formValidations.verifyEmail(formdetails.email)) {
            setEmailState("has-danger");
            setEmailError("Please enter a valid email.");
            return false;
        }

        setEmailState("has-success");
        return true;
    };

    const handleSubmit = function (e) {
        validateForm();
        e.preventDefault();
    };

    const validateForm = async () => {
        let emailvalidation = validatePublisherEmail();
        let passwordvalidation = validatePassword();
        if (emailvalidation && passwordvalidation) {
            try {
                const response = await authService.formLogin(formdetails);
                if (response.status) {
                    window.location.href = "/onboard/get-started";
                } else {
                    setErrorState("has-danger");
                    setErrorMessage(response.error.message);
                }
            } catch (err) {}
        }
        return false;
    };

    const openForgotPasswordScreen = (e) => {
        //history.push("/auth/forgotpassword");e.preventDefault();
        props.setCurrentState("forgotpassword");
    };

    const openRegistrationScreen = (e) => {
        //history.push("/auth/register");e.preventDefault();
        props.setCurrentState("register");
    };

    return (
        <Row>
            <Col className="ms-auto me-auto" lg="10" md="10">
                <Form
                    id="loginform"
                    action=""
                    className="form"
                    method="POST"
                    onSubmit={handleSubmit}
                >
                    <Card className="card-login text-center">
                        <CardHeader>
                            <CardHeader>
                                <h3 className="header">Login</h3>
                                {/* <div className="social">
                        <Button className="btn-icon btn-round" color="google" href="/api/auth/google">
                          <i className="fa fa-google" />
                        </Button>
                        <Button className="btn-icon btn-round" color="facebook" href="/api/auth/facebook">
                          <i className="fa fa-facebook-f" />
                        </Button>
                        <p className="card-description">or be classical</p>
                      </div> */}
                                <div className="social">
                                    <a
                                        className=" btn-round social_icons_round"
                                        
                                        href="/api/auth/google"
                                    >
                                        <i
                                            className="fa fa-google fa-10x"
                                            style={{
                                                background:
                                                    "conic-gradient(from -45deg, #ea4335 110deg, #4285f4 90deg 180deg, #34a853 180deg 270deg, #fbbc05 270deg) 73% 55%/150% 150% no-repeat",
                                                "-webkit-background-clip":
                                                    "text",
                                                "background-clip": "text",
                                                color: "transparent",
                                                "-webkit-text-fill-color":
                                                    "transparent",
                                            }}
                                        />
                                        &nbsp;&nbsp;Continue with Google
                                    </a>
                                    <br />
                                    <Button
                                        className="btn-round"
                                        style={{
                                            textTransform: "capitalize",
                                            width: "100%",
                                            "font-size": "15px",
                                            "font-weight": "500",
                                        }}
                                        color="facebook"
                                        href="/api/auth/facebook"
                                    >
                                        <i
                                            className="fa fa-facebook-f"
                                          
                                        />
                                        &nbsp;&nbsp;Continue with Facebook
                                    </Button>
                                    <p className="card-description">
                                        <b>Or</b>
                                    </p>
                                </div>
                            </CardHeader>
                        </CardHeader>
                        <CardBody>
                            <InputGroup className={emailstate}>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <i className="nc-icon nc-email-85" />
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                    id="email"
                                    name="email"
                                    placeholder="Email"
                                    type="text"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {emailstate === "has-danger" ? (
                                    <label className="error">
                                        {emailerror}
                                    </label>
                                ) : null}
                            </InputGroup>

                            <InputGroup className={passwordstate}>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <i className="nc-icon nc-key-25" />
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                    id="password"
                                    name="password"
                                    placeholder="Password"
                                    type="password"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {passwordstate === "has-danger" ? (
                                    <label className="error">
                                        {passworderror}
                                    </label>
                                ) : null}
                            </InputGroup>

                            <InputGroup className={errorstate}>
                                {errorstate === "has-danger" ? (
                                    <label className="error">
                                        {errormessage}
                                    </label>
                                ) : null}
                            </InputGroup>

                            <Button
                                block
                                className="btn-round mb-3"
                                color="success"
                                type="button"
                                onClick={validateForm}
                            >
                                Continue
                            </Button>
                            <div className="terms_and_condition">By continuing, you agree to Sortd's <a  target="_blank" href="https://www.sortd.mobi/privacy-policy">Terms of Service</a> and acknowledge that you've read our <a target="_blank" href="https://www.sortd.mobi/privacy-policy" >Privacy Policy</a></div>
                            <br/>
                            <FormGroup check className="text-left bottom_formsection">
                               

                                <a className="only_form_changes"
                                    style={{ float: "right" }}
                                    href="#"
                                    onClick={(e) => openRegistrationScreen(e)}
                                >
                                    Sign up
                                </a>
                                <a className="only_form_changes"
                                    style={{ float: "right" }}
                                    href="#"
                                    onClick={(e) => openForgotPasswordScreen(e)}
                                >
                                    Forgot Password?
                                </a>
                            </FormGroup>
                        </CardBody>
                        <CardFooter></CardFooter>
                    </Card>
                </Form>
            </Col>
        </Row>
    );
};

export default Login;

import React, { useEffect, useState, useContext,useRef } from "react";
import projectService from "../../services/api/projects-service";
import { useHistory } from "react-router-dom";
import ProjectContext from '../../ProjectContext';
import { Line, Bar, Doughnut } from "react-chartjs-2";
// react plugin for creating vector maps
import { VectorMap } from "react-jvectormap";
import ArticlesWidget from "./ArticlesWidget";
import ArticlesCountWidget from "./ArticlesCountWidget";
import CategoryCountWidget from "./CategoryCountWidget";
import SubuserCountWidget from "./SubuserCountWidget";
import MediaStorageCountWidget from "./MediaStorageCountWidget";
import ArticlesFrequencyChart from "./ArticlesFrequencyChart";
import ArticlesPieChart from "./ArticlesPieChart";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { Country, State, City } from 'country-state-city';
import publisherContactDetailsService from "../../services/api/publisher-contact-details-service.js";
import Select from "react-select"
import formValidations from "../../variables/form-validations";

import './dashboard.css';
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Alert,
  Spinner,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

import {
  chartExample1,
  chartExample2,
  chartExample3,
  chartExample4,
  chartExample5,
  chartExample6,
  chartExample7,
  chartExample8,
} from "../../variables/charts.js";

import {useCookies} from 'react-cookie';
const Home = (props) => {

  const projectDetails = useContext(ProjectContext);
  const history = new useHistory();
  // console.log(projectDetails.id);
  if (projectDetails.id == '') {
    history.push("/project/allprojects");
  }
  const projectId = projectDetails.id;
  let interval = useRef();
  const [domain, setProjectDomain] = useState(false);
  const [showLoader, setShowLoader]   = useState(false);
  const [showSaveButton, setShowSaveButton] = useState(true);
  const [showPopup, setShowPopup] = useState({alert:null});
  const [alert, setAlert] = useState({ show: false, message: '', type: '' });
  const [publishersData, setPublishersData] = useState("");
  const [showContactDetailsPopup, setShowContactDetailsPopup] = useState(false);
  const [contactDetails, setContactDetails] = useState({ phone_number: "", country_code: "", whastapp_contact_number: "" })
  const [contactDetailsState, setContactDetailsState] = useState("")
  const [contactDetailsError, setContactDetailsError] = useState("")
  const [contactNumberDetails, setContactNumberDetails] = useState("");
  const [countriesCode, setCountriesCode] = useState([]);
  const [whastappContactDetails, setWhatappContactDetails] = useState(true);
  const [whatsappNumberState, setWhatsappNumberState] = useState("")
  const [whatsappNumberError, setWhatsappNumberError] = useState("")
  const [verifyOTPField, setVerifyOTPField] = useState(false);
  const [otp, setOTP] = useState({ OTP: "" })
  const [otpState, setOTPState] = useState("")
  const [otpError, setOTPError] = useState("");
  const [otpButtonText, setOTPButtonText] = useState("Send OTP");
  const [sendOTPButtonfield, setSendOTPButtonfield] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [hour,setHour] = useState(0);
  const [minutes, setMinutes] = useState(4);
  const [seconds, setSeconds] = useState(59);
  let cookieName = 'showContactDetailsPopup:'+projectDetails.slug;
  console.log(projectDetails.slug);
  const [cookies,setCookie] = useCookies([cookieName])
  useEffect(() => {
    (async () => {
      let domainData = await projectService.getProjectDomain(projectDetails.id);
      if (domainData.status && domainData.data) {
        let public_host = "https://" + domainData.data.public_host.replace('https://', "")
        setProjectDomain(public_host);
        //setRenderComponent(true);
      }
    })();
  }, []);


  useEffect(() => {

    history.push({
      search: '?project=' + projectDetails.slug
    })

  }, []);

  useEffect(() => {
    (async () => {
      let countriesList = Country.getAllCountries();
      let countryArray = [];
      countriesList.forEach((country) => {
        if (country.phonecode) {
          countryArray.push({
            value: country.phonecode,
            label: "(" + country.phonecode + ")   " + country.name
          });
        }
        setContactDetails({
          ...contactDetails,
          country_code:"91"
        })
      });
      setCountriesCode(countryArray);
    })();
  }, []);


  useEffect(() => {
    (async () => {
      let publishersData = await projectService.getPublisherByProjectId(projectDetails.id);
      setPublishersData(publishersData.data);
      if (publishersData.status && !publishersData.data.phone_number && !cookies[cookieName]) {
        setShowContactDetailsPopup(true);
        setContactNumberDetails(true);
        setSendOTPButtonfield(true);
        setOTPButtonText("Send OTP")
      }

    })();
  }, [])

  const handlePhoneNumberChange = (event) => {
    setContactDetails({
      ...contactDetails,
      phone_number: event.target.value,
    })
    setContactDetailsState("has-success");
    setContactDetailsError("");
  }

  const handleWhastappNumberChange = (event) => {
    setContactDetails({
      ...contactDetails,
      whastapp_contact_number: event.target.value
    })
    setWhatsappNumberState("has-success");
    setWhatsappNumberError("");
  }

  const handleCheckBox = (event) => {
    if (event.target.checked) {
      setContactDetails({
        ...contactDetails,
        whastapp_contact_number: contactDetails.phone_number
      })
    }
    else {
      setWhatappContactDetails(false);
      setContactDetails({
        ...contactDetails,
        whastapp_contact_number: ""
      })
    }
  }

  const validatePhoneNumber = () => {
    if (contactDetails.phone_number !== "" &&  formValidations.verifyPhoneNumber(contactDetails.phone_number)) {
      setContactDetailsState("has-success")
      return true;
    }
    if(contactDetails.phone_number !== ""  && !formValidations.verifyPhoneNumber(contactDetails.phone_number)){
      setContactDetailsState("has-danger");
      setContactDetailsError("Please Enter Valid Phone Number");  
      return false;
    }
    setContactDetailsState("has-danger");
    setContactDetailsError("Please Enter Your Phone Number")
    return false;
  }

  const validateCountryCode = ()=>{
    console.log("country code: ",contactDetails.country_code)
    if(contactDetails.country_code !== ""){ 
      return true;
    }
    setContactDetailsState("has-success");
    setContactDetailsError("Please Enter Country Code");
    return false;
  }

  const validateOTP = () => {
    console.log("in validate")
    if (otp.OTP !== "") {
      console.log("in if")
      setOTPState("has-success");
      return true;
    }
    setOTPState("has-danger");
    setOTPError("Please Enter OTP");
    return false;
  }

  const validateWhatsappNumber = ()=>{
    if (contactDetails.whastapp_contact_number !== "" &&  formValidations.verifyPhoneNumber(contactDetails.whastapp_contact_number)) {
      setWhatsappNumberState("has-success");
      return true;
    }
    if (contactDetails.whastapp_contact_number !== "" &&  !formValidations.verifyPhoneNumber(contactDetails.whastapp_contact_number)) {
      setWhatsappNumberState("has-danger");
      setWhatsappNumberError("Please Enter Your Valid Whatsapp Number")  
      return false;
    }
    setWhatsappNumberState("has-danger");
    setWhatsappNumberError("Please Enter Your Whatsapp Number")
    return false;
  }
  

  const handleOtpChange = (event) => {
    setOTP({
      ...otp,
      OTP: event.target.value
    })
  }
  const verifyOtp = async () => {
    setOTPState("has-success");
    setOTPError("");
    if (validateOTP()) {

      const isOTPVerified = await publisherContactDetailsService.verifyOTP(contactDetails.phone_number, contactDetails.country_code, otp.OTP);
      if (!isOTPVerified.status && isOTPVerified.error.errorCode === 107) {
        setOTPState("has-danger");
        setOTPError("You’ve reached the maximum OTP verification attempts");
        setTimeout(()=>{
          setOTPState("has-danger");
          setOTPError("");
        },3000);
      }
      if (!isOTPVerified.status && isOTPVerified.error.errorCode === 103) {
        setOTPState("has-danger");
        setOTPError("OTP is invalid");
      }
      if (isOTPVerified.status) {
        // clearInterval(interval.current);
        // console.log("interval: ",interval.current)
        const contactDetailsSaved = await publisherContactDetailsService.saveContactDetails(publishersData._id, contactDetails.country_code, contactDetails.phone_number, contactDetails.whastapp_contact_number);
        if(contactDetailsSaved.status && contactDetailsSaved.data){
            setShowContactDetailsPopup(false);
            setShowPopup({
              alert:(
                <ReactBSAlert
                  success
                  style={{disabled:"block",marginTop:"-100px"}}
                  title="Your Contact Details Updated Successfully"
                  showConfirm={true}
                  showCancel={false}
                  onConfirm={()=>onCancel()}
                  confirmBtnBsStyle="info"
                  confirmBtnText="Go to Dashboard"
                  btnSize=""
                >
                  Details Saved Successfully
                </ReactBSAlert>
              )
            })
        }
        else{
          setShowContactDetailsPopup(false);

          setShowPopup({
            alert:(
              <ReactBSAlert
                warning
                style={{display:"block",marginTop:"-100px"}}
                title="There is some error"
                onConfirm={()=>onCancel()}
                showConfirm={true}
                showCancel={false}
                confirmBtnText="Go to Dashboard"
                confirmBtnBsStyle="info"
                btnSize=""
              ></ReactBSAlert>
            )
          })
        
        }
      }
      else {

      }
    }

  }
  const handleCountryCodeChange = (event) => {
    setContactDetails({
      ...contactDetails,
      country_code: event.value
    })
    console.log("if", contactDetails)
  }

  const saveContactDetails = async () => {
    setIsDisabled(true);
    if(validateWhatsappNumber()){
      setShowLoader(true);
      setShowSaveButton(false);
      const contactDetailsSaved = await publisherContactDetailsService.saveContactDetails(publishersData._id, contactDetails.country_code, contactDetails.phone_number, contactDetails.whastapp_contact_number);
      console.log("details:",contactDetailsSaved)
      if (contactDetailsSaved.status) {
        setTimeout(()=>{
          setShowLoader(false);
          setAlert({
            show:true,
            type:"success",
            message:"Contacts Details Updated Successfully"
          })
        },1000);
        setWhatsappNumberState("has-success");
        setWhatsappNumberError("Contact Details are saved Successfully");
        setTimeout(()=>{
          setShowContactDetailsPopup(false);
          setContactDetails(false);
        },3000);
      }
      else{
        setTimeout(()=>{
          setShowLoader(false);
          setShowSaveButton(true);
        },1000)
        setAlert({
          show:true,
          type:"danger",
          message:"Contacts Details could not be upadted. Please Try again!!"
        })
        setWhatappContactDetails(false)
      }
    }
    else{
      setIsDisabled(false);
    }
  }

  const sendOtp = async () => {
    console.log("in sendOTP")
    if (validateCountryCode() && validatePhoneNumber()) {
      const isOTPSent = await publisherContactDetailsService.sendOTPforVerification(contactDetails.phone_number, contactDetails.country_code);
      console.log(isOTPSent)
      if (!isOTPSent.status && isOTPSent.error.errorCode === 106) {
        if (contactNumberDetails) {
          console.log("in if", contactDetails);
          setContactDetailsState("has-danger");
          setContactDetailsError("Oops! You have reached the OTP limit.Please retry after 1 hour of first failed attempt.");
        }
        else {
          console.log("in else block")
          setOTPState("has-danger");
          setOTPError("Oops! You have reached the OTP limit.Please retry after 1 hour of first failed attempt.")
        }
        setTimeout(()=>{
          //set cookie
          setCookie(cookieName,true,{maxAge: 12*60*60});
          setShowContactDetailsPopup(false);
        },3000);
      }
      else if (isOTPSent.status) {
        setOTPState("has-success");
        setOTPError("OTP has sent successfully")
        setSendOTPButtonfield(false)
        setOTPButtonText("");
        setContactNumberDetails(false);
        setVerifyOTPField(true);
        setIsDisabled(true);
        setTimeout(()=>{
          setOTPState("has-success");
          setOTPError("");
        },5000);
        let timer = 299;
        interval.current = setInterval(()=>{
          if(timer >=0){
            setHour(0);
            setMinutes(String(Math.floor(timer/60)));
            setSeconds(String(timer%60));
            timer = timer - 1;
          }
          if(timer === 0 ){
            console.log("in time == 0",verifyOTPField)
            setIsDisabled(false);
            setOTPState("has-danger");
            setOTPError("OTP is Expired, Please Resend The OTP!")
          }
        },1000)
        
      }
      else {

      }
    }
    else {
      // setIsDisabled(false);
      // setVerifyOTPField(false);
      setSendOTPButtonfield(true);
    }
  }

  const onCancel = () => {
    console.log("in cancel",cookieName);
    setCookie(cookieName,true,{maxAge: 12*60*60});
    setShowContactDetailsPopup(false);
    setShowPopup({alert:null});
  }

  return (
    <div className="content mt-30">
      {showPopup.alert}
      {showContactDetailsPopup ?
        <span>
          <ReactBSAlert
            title=""
            type="custom"
            closeOnClickOutside={true}
            showConfirm={false}
            showCancel={false}
          >
            <div className="phonenumberpopup">
              <div className="bg">
              </div>
              <div>
                <img src={"https://ads.rwadx.com/sortdjson/300x100.svg"} alt="Sortd"/>
              </div>
              <Button
                className="btnB cancelBtn"
                id="resend"
                type="button"
                onClick={onCancel}
              >
                X
              </Button>
              <div className="title" >
                Update Your Contact Details  
              </div>
              {contactNumberDetails ?

                <FormGroup className={contactDetailsState}>
                  <Row>
                    <Col md="4" >
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        name="country_code"
                        value={countriesCode ? countriesCode.filter(option => option.value === contactDetails.country_code) : ""}
                        onChange={(event) => handleCountryCodeChange(event)}
                        options={countriesCode}
                      >
                      </Select>
                      <span>
                          {contactDetailsState === "has-success" ? (
                            <label className="error" style={{color:"#F6A678"}}>
                              {contactDetailsError}
                            </label>
                          ) :""}
                      </span>
                    </Col>
                    <Col md="8">
                      <Input
                        type="tel"
                        id="phone"
                        name="phone"
                        disabled={false}
                        style={{ borderColor: "green" }}
                        value={contactDetails.phone_number}
                        placeholder="Your 10 digit mobile number"
                        pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                        onChange={handlePhoneNumberChange}
                      />
                      <span>

                        {contactDetailsState === "has-danger" ? (
                          <label className="error">
                            {contactDetailsError}
                          </label>
                        ) :""}
                      </span>
                    </Col>
                    
                  </Row>
                  <Row>
                        <div className="checkedboxclass">
                          <Row>
                            <Col md="1">
                              <Input type="checkbox" id="checkbox" name="checkbox" defaultChecked={false} onChange={handleCheckBox} />
                            </Col>
                            <Col md="10">
                              <Label style={{marginTop:"15px",fontSize:"16px"}}>Use This as Your Whatsapp Number</Label>
                            </Col>
                          </Row>
                        </div>
                      {// <FormGroup className={whatsappNumberState}>
                      // <Row>
                      //   <Col md="4">
                      //   <Select
                      //     className="react-select primary"
                      //     classNamePrefix="react-select"
                      //     style={{ borderStyle: "Solid", borderColor: "green", backgroundColor: "white" }}
                      //     name="country_code"
                      //     value={countriesCode ? countriesCode.filter(option => option.value === contactDetails.country_code) : ""}
                      //     onChange={(event) => handleCountryCodeChange(event)}
                      //     options={countriesCode}
                      //   >
                      //   </Select>
                      //   <span>
                      //       {contactDetailsState === "has-success" ? (
                      //         <label className="error" style={{color:"#F6A678"}}>
                      //           {contactDetailsError}
                      //         </label>
                      //       ) :""}
                      //   </span>
                      //   </Col>
                      //   <Col md="8">
                      //     <Input
                      //       type="tel"
                      //       id="phone"
                      //       name="phone"
                      //       style={{ borderColor: "green", borderWidth: "1px" }}
                      //       value={contactDetails.whastapp_contact_number}
                      //       placeholder="Your 10 digit WhatsApp Number"
                      //       pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                      //       onChange={handleWhastappNumberChange}
                      //     />
                      //     {whatsappNumberState === "has-danger" ?
                      //       (<label className="error">
                      //         {whatsappNumberError}
                      //       </label>)
                      //       : (<label className="info" style={{color:"green"}}>
                      //         {whatsappNumberError}
                      //     </label>)}
                      //     </Col>
                      // </Row>
                      // </FormGroup>
                      }
                  </Row>
                </FormGroup>

                : null}
              <Row>
                  {
                    verifyOTPField ?
                      <FormGroup className={otpState}>
                        {/* {console.log("verify OTP",verifyOTPField)} */}
                        <Input type="tel" id="otp" name="otp" maxLength={6} onChange={handleOtpChange} placeholder="Enter OTP" style={{width:"40%",marginLeft:"30%",borderColor:"green",textAlign:"center",paddingRight:"10px"}}></Input>
                        <Row>
                          {otpState === "has-danger" ?
                            (
                              <label className="error">
                                {otpError}
                              </label>
                            ) : <label className="info" style={{ color: "green" }}>
                              {otpError}
                            </label>
                          }
                        </Row>
                        <div >
                          {minutes}m::{seconds}s
                        </div>
                        <Button
                          style={{ backgroundColor:"#2F80ED",borderRadius:"13px",fontWeight:"normal",color:"white",fontSize:"15px"}}
                          className="btnB"
                          type="button"
                          onClick={verifyOtp}
                        >
                          Verify OTP
                        </Button>
                        <Button
                          style={{backgroundColor:"white",color:"#EB5757",borderRadius:"13px",fontWeight:"normal",border:"2px solid #EB5757"}}
                          className="btnB"
                          type="button"
                          id="send"
                          onClick={sendOtp}
                          disabled={isDisabled}
                        >
                          Resend Otp
                        </Button>
                      </FormGroup>

                      : null
                  }
                <Col>

                  {
                    sendOTPButtonfield ?
                      <>
                        {/* {otpButtonText === "Resend OTP" ?
                          <Button
                            style={{ backgroundColor: "white", color: "black", border: "none", paddingTop: "1px" }}
                            className="btnB"
                            id="resend"
                            type="button"
                            onClick={sendOtp}
                            disabled={isDisabled}
                          >
                            {otpButtonText}
                          </Button> */}
                          {/* : */}

                          <Button
                            style={{backgroundColor:"#2F80ED",borderRadius:"13px",fontWeight:"normal",color:"white",fontSize:"15px"}}
                            className="btnB"
                            type="button"
                            id="send"
                            onClick={sendOtp}
                          >
                            Send Otp
                          </Button>
                        {/* } */}
                      </>
                      : null
                  }
                </Col>
              </Row>
              {/* {
                whastappContactDetails ?
                  <FormGroup className={whatsappNumberState}>
                    <Row style={{ color: "black", marginBottom: "10px" }}>
                      <Col md="10">
                        <Label>Is This Your Whatsapp Number also ?</Label>
                      </Col>
                      <Col md="1">
                        <Input type="checkbox" id="checkbox" name="checkbox" defaultChecked={false} onChange={handleCheckBox} style={{ borderColor: "black", borderWidth: "1px" }} />
                      </Col>
                    </Row>
                    <Row>
                      <Input
                        type="tel"
                        id="phone"
                        name="phone"
                        style={{ borderColor: "green", borderWidth: "1px" }}
                        value={contactDetails.whastapp_contact_number}
                        placeholder="Enter Your WhatsApp Number"
                        pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                        onChange={handleWhastappNumberChange}
                      />
                      {whatsappNumberState === "has-danger" ?
                        (<label className="error">
                          {whatsappNumberError}
                        </label>)
                        : (<label className="info" style={{color:"green"}}>
                          {whatsappNumberError}
                      </label>)}
                    </Row>
                      {showSaveButton ? (
                        <Button
                          style={{ backgroundColor: "blue" }}
                          className="btnB"
                          type="button"
                          onClick={saveContactDetails}
                          disabled={isDisabled}
                        >
                          Save
                        </Button>
                      ):("")}
                      {
                        //alert message
                        alert.show? (
                          <Alert color={alert.type}>
                            {alert.message}
                          </Alert>
                        ):("")
                      }
                      {showLoader ? (
                          <Spinner
                            animation="border"
                            role="status"
                          >
                            <span className="sr-only">
                                Loading...
                            </span>
                        </Spinner>
                      ):("")}
                  </FormGroup>
                  :
                  null
              } */}


            </div>
          </ReactBSAlert>
        </span>
        : ""}
      <Container>
        <Row>
          <Col md="3">
            <ArticlesCountWidget />
          </Col>
          <Col md="3">
            <CategoryCountWidget />
          </Col>
          <Col md="3">
            <SubuserCountWidget />
          </Col>
          <Col md="3">
            <MediaStorageCountWidget />
          </Col>
        </Row>
        <Row>
          <Col md="6" >
            <Row>
              <Col md="12" >
                <Card className="card-stats difsts">
                  <div className="icnBx">
                    <i className="nc-icon nc-globe " />
                  </div>
                  <div className="statsNme">
                    <h3>{projectDetails.name}</h3>
                    <p className="text-left">{domain ? <a target="_blank" href={domain}>{domain}</a> : ''}</p>
                  </div>
                  <div className="statsEdt">
                    <a href="/config/customizations"><i className="fa fa-pencil" /></a>
                  </div>

                  <div className="mngInf">
                    <div className="dtlUsr">
                      {/* <i class="fa fa-rocket" aria-hidden="true"></i>
                      Upgrade Plan */}
                      {/* </div>
                    <div className="dtlUsr text-end"> */}
                      <a href="/project/users">
                        <i className="fa fa-user" />
                        Manage Users
                      </a>
                    </div>
                  </div>
                </Card>
              </Col>
            </Row>


            {/* <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                </CardHeader>
                <CardBody>
                  <h6 className="big-title">
                    GA Monthly Stats
                  </h6>
                  <Line
                    data={chartExample1.data}
                    options={chartExample1.options}
                    height={380}
                    width={826}
                  />
                </CardBody>
              </Card>
            </Col>
        </Row> */}

            <Row>
              <Col md="12">
                <ArticlesFrequencyChart />
              </Col>
            </Row>

            <Row>
              <Col md="12">
                <ArticlesPieChart />
              </Col>
            </Row>

          </Col>
          {/* <Col md="4">
                <Row>
                <Col lg="12" md="12" sm="12">
                    <Card className="card-stats">
                      <CardBody>
                        <Row>
                          <Col md="10">
                              <div className="stats text-left">
                              <CardTitle tag="h3">Analytics</CardTitle>
                              <p className="text-left">Last 7 Days</p>
                            </div>
                          </Col>
                          <Col md="2">
                          <div className="stats text-end">
                          <i className="fa fa-cog" />
                        </div>
                          </Col>
                        </Row>
                        <hr />
                        <Row>
                      

                        <Col md="10">
                        <div className="stats">
                              
                              <CardTitle tag="h3">1,30,704</CardTitle>
                              <p className="card-category">Page Views</p>
                              <p />
                            </div>
                          </Col>
                          <Col md="2">
                            <div className="icon-big text-left icon-warning">
                              <i className="fa fa-file-image-o text-warning" />
                            </div>
                          </Col>
                          
                        </Row>
                        <hr />
                        <Row>
                        

                        <Col md="10">
                        <div className="stats">
                              
                              <CardTitle tag="h3">3,704</CardTitle>
                              <p className="card-category">Users</p>
                              <p />
                            </div>
                          </Col>
                          <Col md="2">
                            <div className="icon-big text-left icon-warning">
                              <i className="fa fa-user text-warning" />
                            </div>
                          </Col>
                          
                        </Row>
                        <hr />
                        <Row>
                        

                        <Col md="10">
                        <div className="stats">
                              
                              <CardTitle tag="h3">30,704</CardTitle>
                              <p className="card-category">Events</p>
                              <p />
                            </div>
                          </Col>
                          <Col md="2">
                            <div className="icon-big text-left icon-warning">
                              <i className="fa fa-hand-pointer-o text-warning" />
                            </div>
                          </Col>
                          
                        </Row>
                      </CardBody>
                      <CardFooter>
                        
                      </CardFooter>
                    </Card>
                  </Col>
                </Row>
              
                </Col>
               */}
          <Col md="6">
            <ArticlesWidget />
          </Col>
        </Row>


      </Container>
      <div
        className="full-page-background"
        style={{
          backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
        }}
      />
    </div>
  );

}

export default Home;

import React, { useState, useEffect, useContext } from "react";
import JwplayerAnalysis from "./JwplayerAnalysis";
import VideoUploadForm from "./VideoUploadForm";
import { useHistory } from "react-router-dom";
import JwplayerUsage from "./JwplayerUsage";
import ProjectContext from "../../ProjectContext";
import allListingService from "../../services/api/Jwplayer/allListing-service";
import axios from "axios";
import "./VideoUploadForm.css";
import {
    Navbar,
    NavItem,
    NavbarToggler,
    Collapse,
    NavLink,
    Nav,
    Card,
    CardTitle,
    CardBody,
    Spinner,
} from "reactstrap";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import PromotionalPage from "./PromotionalPage";
import JwPlayerPlaylists from "./JwPlayerPlaylists";

const JwplayerNav = (props) => {
    const [currentState, setCurrentState] = useState(1);
    const [isOpen, setIsOpen] = useState(false);
    const [jwplayerStatus, setJwplayerStatus] = useState(false);
    const [siteId, setSiteId] = useState("");
    const [bucketName, setBucketName] = useState("");
    const [identityPoolId, setIdentitypoolId] = useState("");
    const [videoUrl, setVideoUrl] = useState("");
    const [region, setRegion] = useState("");
    const [gcpVideoUrl, setGCPVideoUrl] = useState("");
    const [gcpRegion, setGCPRegion] = useState("");
    const [parallelSyncingAllowed, setParallelSyncingAllowed] = useState(false);
    const [isDataFetched, setIsDataFetched] = useState(true);
    // const [active1, setActive1] = useState(true);
    // const [active2, setActive2] = useState(false);
    // const [active3, setActive3] = useState(false);

    const history = useHistory();
    const projectDetails = useContext(ProjectContext);
    if (!projectDetails.id) {
        history.push("/project/allprojects");
    }

    const fetchStatus = async () => {
        const { data } = await allListingService.jwplyerEnableStatus(
            projectDetails.id
        );

        if (data.data) {
            if (data.data.enablejwplayer) {
                const jwplayerCredentials =
                    await allListingService.getJwplayerConfigCredentials({projectId:projectDetails.id});
                if (jwplayerCredentials) {
                    setBucketName(jwplayerCredentials.data.data.bucketName);
                    setIdentitypoolId(jwplayerCredentials.data.data.IdentityId);
                    setRegion(jwplayerCredentials.data.data.region);
                    setVideoUrl(jwplayerCredentials.data.data.cdnUrl);
                    setGCPRegion(jwplayerCredentials.data.data['gcpRegion']);
                    setGCPVideoUrl(jwplayerCredentials.data.data['gcpCdnUrl']);
                    setParallelSyncingAllowed(jwplayerCredentials.data.data.parallelSyncingAllowed)
                }
            }
            setJwplayerStatus(data.data.enablejwplayer);
            setSiteId(data.data.site_id);
        }
    };
    useEffect(() => {
        // setActive1(true);

        fetchStatus();
    }, []);
    const projectId = projectDetails.id;

    const toggle = () => setIsOpen(!isOpen);

    // const callFirst = () => {
    //     setActive1(true);
    //     setActive2(false);
    //     setActive3(false);
    //     setCurrentState(1);
    // };
    // const callSecond = () => {
    //     setActive1(false);
    //     setActive2(true);
    //     setActive3(false);
    //     setCurrentState(2);
    // };
    // const callThird = () => {
    //     setActive1(false);
    //     setActive2(false);
    //     setActive3(true);
    //     setCurrentState(3);
    // };

    return (
        <>
            {(() => {
                if (isDataFetched) {
                    {
                        if (jwplayerStatus) {
                            return (
                                <div className="content jw-content mt-30">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <Navbar
                                                            className="jw-Main-dashboard"
                                                            color="white"
                                                            light
                                                            expand="md"
                                                        >
                                                            <NavbarToggler
                                                                className="rm-border"
                                                                onClick={toggle}
                                                            />
                                                            <Collapse
                                                                isOpen={isOpen}
                                                                navbar
                                                            >
                                                                <Nav
                                                                    className="mr-auto align-items-center"
                                                                    navbar
                                                                >
                                                                    <NavItem>
                                                                        <NavLink
                                                                            style={{
                                                                                background:
                                                                                    currentState ===
                                                                                    1
                                                                                        ? "#0d55c1"
                                                                                        : "#116bf0",
                                                                            }}
                                                                            href="#"
                                                                            onClick={() => {
                                                                                setCurrentState(
                                                                                    1
                                                                                );
                                                                            }}
                                                                        >
                                                                            DASHBOARD
                                                                        </NavLink>
                                                                    </NavItem>
                                                                    <NavItem>
                                                                        <NavLink
                                                                            style={{
                                                                                background:
                                                                                    currentState ===
                                                                                    2
                                                                                        ? "#0d55c1"
                                                                                        : "#116bf0",
                                                                            }}
                                                                            href="#"
                                                                            onClick={() => {
                                                                                setCurrentState(
                                                                                    2
                                                                                );
                                                                            }}
                                                                        >
                                                                            ANALYTICS
                                                                        </NavLink>
                                                                    </NavItem>
                                                                    <NavItem>
                                                                        <NavLink
                                                                            style={{
                                                                                background:
                                                                                    currentState ===
                                                                                    3
                                                                                        ? "#0d55c1"
                                                                                        : "#116bf0",
                                                                            }}
                                                                            href="#"
                                                                            onClick={() => {
                                                                                setCurrentState(
                                                                                    3
                                                                                );
                                                                            }}
                                                                        >
                                                                            USAGE
                                                                        </NavLink>
                                                                    </NavItem>
                                                                    <NavItem>
                                                                        <NavLink
                                                                            style={{
                                                                                background:
                                                                                    currentState ===
                                                                                    4
                                                                                        ? "#0d55c1"
                                                                                        : "#116bf0",
                                                                            }}
                                                                            href="#"
                                                                            onClick={() => {
                                                                                setCurrentState(
                                                                                    4
                                                                                );
                                                                            }}
                                                                        >
                                                                            Playlist
                                                                        </NavLink>
                                                                    </NavItem>
                                                                </Nav>
                                                            </Collapse>
                                                        </Navbar>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        } else {
                            return (
                                <div className="content mt-30">
                                    <Card>
                                        <CardBody className="mainCard">
                                            <PromotionalPage />
                                        </CardBody>
                                    </Card>
                                </div>
                            );
                        }
                    }
                } else {
                    return (
                        <div style={{ height: "600px" }}>
                            <center>
                                <Spinner animation="border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </Spinner>
                            </center>
                        </div>
                    );
                }
            })()}

            {currentState === 1 && jwplayerStatus && isDataFetched ? (
                <VideoUploadForm
                    siteId={siteId}
                    projectId={projectDetails.id}
                    bucketName={bucketName}
                    videoUrl={videoUrl}
                    region={region}
                    identityPoolId={identityPoolId}
                    gcpRegion={gcpRegion}
                    gcpVideoUrl={gcpVideoUrl}
                    parallelSyncingAllowed={parallelSyncingAllowed}
                />
            ) : (
                ""
            )}
            {currentState === 2 && jwplayerStatus ? (
                <JwplayerAnalysis projectId={projectDetails.id} />
            ) : (
                ""
            )}
            {currentState === 3 && jwplayerStatus ? (
                <JwplayerUsage projectId={projectDetails.id} />
            ) : (
                ""
            )}
            {currentState === 4 && jwplayerStatus ? (
                <JwPlayerPlaylists projectId={projectDetails.id} />
            ) : (
                ""
            )}
        </>
    );
};

export default JwplayerNav;

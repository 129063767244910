import React, { useEffect, useState,useContext } from "react";
import projectsService from "../../services/api/projects-service";
import formValidations from "../../variables/form-validations";
import './settings.css';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Alert,
  Spinner,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

import Select from "react-select";
import ProjectContext from '../../ProjectContext'

const roles = [{value:'admin',label:'ADMIN'},{value:'accounts',label:'ACCOUNTS'}];
const AddUser = (props)=> {

  const projectId = props.projectId;
  const projectDetails = useContext(ProjectContext);
  const [formdetails, setFormDetails] = useState({projectId:projectId,email:'',roles:''});
  const [showLoader, setShowLoader]   = useState(false);
  const [showSaveButton, setShowSaveButton]   = useState(true);
  const [alert, setAlert]   = useState({show:false,message:'',type:''});
  const [emailstate, setEmailState] = useState("");
  const [emailerror, setEmailError] = useState("");
  const [rolesstate, setRolesState] = useState("");
  const [roleserror, setRolesError] = useState("");


  const handleChange = (event) => {
    setFormDetails({
      ...formdetails,
      [event.target.name]: ( event.target.value ).trim(),
    });

    setEmailState("has-success");
  }

  const handleBlur = (event) => {
    
  }

  const validateEmail = () => {
    if(formdetails.email===''){
      setEmailState("has-danger");
      setEmailError("This field is required");
      return false;
    }

    if(!formValidations.verifyEmail(formdetails.email)){
      setEmailState("has-danger");
      setEmailError("Please enter a valid email.");
      return false;
    }

    setEmailState("has-success");
    return true;
}

const validateRoles = () => {
  if(formdetails.roles===''){
    setRolesState("has-danger");
    setRolesError("This field is required");
    return false;
  }
  setRolesState("has-success");
  return true;
}

const handleRolesChange = (value) => {
  let projectRoles = [];
  if(value && value.length > 0){
    value.forEach(function(roleDetails){
      projectRoles.push(roleDetails.value);
    })
  }
  setFormDetails({
    ...formdetails,
    roles: projectRoles,
  });

  setRolesState("has-success");
}
  
  const handleSubmit = function(e){  validateForm();e.preventDefault() }
 
  const validateForm = async () => {
    let validEmail = validateEmail();
    let validRoles = validateRoles();
    if(validEmail && validRoles){
      setShowLoader(true);
      setShowSaveButton(false);
      let addUser = await projectsService.inviteUser(formdetails);
      if(projectDetails.isContractExpired){
        setShowLoader(false);
        setShowSaveButton(true);
        projectDetails.setContractExpiredAlert(projectDetails);
      }
      else if(addUser.status){
        if(addUser.data.invited){
          setTimeout(()=>{
            setShowLoader(false)
            setAlert({show:true,type:'success',message:'Invitation was sent Successfully'});
          },1000);
          
          setTimeout(()=>{
            setAlert({show:false,type:'',message:''});
          },3000);
  
          setTimeout(()=>{
            props.setShowAddUser(false);
          },3000);
        }
        if(addUser.data.exists){
          setTimeout(()=>{
            setShowLoader(false)
            setShowSaveButton(true)
            setAlert({show:true,type:'danger',message:'Invitation already sent or email is already associated .'});
          },1000);
  
          setTimeout(()=>{
            setAlert({show:false,type:'',message:''});
          },8000);
        }
        
      }else{
        if(addUser.error)
        {
          setTimeout(()=>{
          setShowLoader(false)
          setShowSaveButton(true)
          setAlert({show:true,type:'danger',message:addUser.error.message});
        },1000); 
      }else
      {
        setTimeout(()=>{
          setShowLoader(false)
          setShowSaveButton(true)
          setAlert({show:true,type:'danger',message:'Invitation could not be sent, Please try again.'});
        },1000);
      }
        setTimeout(()=>{
          setAlert({show:false,type:'',message:''});
        },8000);
      }
    }
  }
  
    return (
      <div className="content mt-30">
        <Container>
          <Row>
          <Col className="ml-auto mr-auto"  md="12">
              <Card className="card-signup text-center dp-box">
                <CardHeader>
                  <CardTitle tag="h4" className="heding_tab_h4">Add/Invite User to {props.projectName}</CardTitle>
                  {showSaveButton?<Button style={{float: 'right'}}
                    className="btn-smb btnBlk"
                    type="button"
                    onClick={(event)=>  props.setShowAddUser(false) }
                  >
                    Back
                  </Button>:''}
                </CardHeader>
                <CardBody>
                  <Form className="form-horizontol form" action="" id="inviteUser" method="POST" onSubmit={handleSubmit}>

                  <div className="consFom">
                      <Label className="lblNme">Email : </Label>
                      <div className="inptFom1 mb-0">
                      <InputGroup className={emailstate}>
                      <InputGroupAddon addonType="prepend">
                        {/* <InputGroupText>
                          <i className="nc-icon nc-email-85" />
                        </InputGroupText> */}
                      </InputGroupAddon>
                      <Input id="email" name="email" placeholder="Email..." type="text"  onChange={handleChange} onBlur={handleBlur}/>
                      {emailstate === "has-danger" ? (
                            <label className="error">
                              {emailerror}
                            </label>
                          ) : null} 
                    </InputGroup>
                      </div>
                  </div>

                  <div className="consFom">
                      <Label className="lblNme">Roles : </Label>
                      <div className="inptFom1 mb-0">
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        placeholder="select roles"
                        name = "roles"
                        id="roles"
                        isMulti = "true"
                        //value={currentMultiEnumValue()}
                        options={roles}
                        onChange={(value)=>  handleRolesChange(value) }
                      />
                      <InputGroup className={rolesstate}>

                      {rolesstate === "has-danger" ? (
                            <label className="error">
                              {roleserror}
                            </label>
                          ) : null} 
                    </InputGroup>
                      </div>
                  </div>
                  <div className="dflLft">
                    {showSaveButton?<Button
                      className="btn-round btnBlue"
                      type="button"
                      onClick={validateForm}
                    >
                      Invite User
                    </Button>:''}
                  </div>
                  {/* {showSaveButton?<Button
                    className="btn-round"
                    color="danger"
                    type="button"
                    onClick={(event)=>  props.setShowAddUser(false) }
                  >
                    Back
                  </Button>:''} */}
                  <div className="dfl">
                  {alert.show?<Alert color={alert.type}>
                    {alert.message}
                  </Alert>:''}

                  {showLoader?<Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>:''}
                  </div>
                  </Form>
                </CardBody>
                <CardFooter>
                  <div className="infoAlrt">
                    <h4>Roles</h4>
                    <p><span>ADMIN -</span> Have all rights to the project</p>
                    <p><span>ACCOUNTS -</span> Have view only rights in project except accounting screens.</p>
                  </div>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
          }}
        />
      </div>
    );
  
}

export default AddUser;

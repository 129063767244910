import React, { useEffect, useState, useContext } from "react";
import { useHistory } from 'react-router-dom'
import {
    Card,
    CardTitle,
    CardHeader,
    CardBody,
    Row,
    Col,
    Button,
    Label,
    Table,
    Input,
    Spinner,
    Form,
} from "reactstrap";
import ProjectContext from "../../ProjectContext";
import './ctv.css'
import ctvAppService from "../../services/api/ctv-app-service";
import Pagination from "react-js-pagination";
import ReactBSAlert from 'react-bootstrap-sweetalert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit, faTrashRestoreAlt, faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select'
import customStyles from '../../components/CustomStyles';
import moment from "moment";
import ReactTooltip from 'react-tooltip';

const ContentLibrary = (props) => {

    const projectDetails = useContext(ProjectContext);
    const history = useHistory();
    if (projectDetails.id == '') {
        history.push("/project/allprojects");
    }
    const projectId = projectDetails.id;

    const [activeTab, setActiveTab] = useState("");
    const [searchDetails, setSearchDetails] = useState({ _id: "", video_type: "", title: "", category: "", project_id: projectId, status: "" })
    const [formdetails, setFormDetails] = useState({ _id: "", video_type: "", title: "", category: props.category, project_id: projectId, status: "" })
    const records = 10;
    const [activePage, setActivePage] = useState(1);
    const [videos, setVideos] = useState([])
    const [totalVideos, setToatalVideos] = useState(0);
    const [renderComponent, setRenderComponent] = useState(false);
    const [showPopup, setShowPopup] = useState({ alert: null })
    const [categoriesList, setCategoriesList] = useState([])
    const [reloadComponent, setReloadComponent] = useState(0);
    const [videoEditPopup, setVideoEditPopup] = useState(false)
    const statusOptions = [
        { value: "", label: "All" },
        { value: "1", label: "Active" },
        { value: '0', label: "Inactive" },
    ]
    useEffect(() => {
        (async () => {
            let data = await ctvAppService.getVideoCategories({ projectId, status: 1 });
            if (data.status && data.data) {
                let array = Object.keys(data.data.catList).map(key => ({
                    label: data.data.catList[key].name,
                    value: data.data.catList[key]._id
                }));

                array.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));

                setCategoriesList(array);
            }
        })()
    }, [])

    useEffect(() => {
        (async () => {
            setRenderComponent(false)
            const videoList = await ctvAppService.getCtvVideos({ ...formdetails, activePage, records });
            if (videoList.status && videoList.data) {
                setVideos(videoList.data.videoList);
                setToatalVideos(videoList.data.count);
            }
            setRenderComponent(true);

        })();
    }, [activePage, searchDetails, reloadComponent])

    const handleTabClick = (tab) => {
        setFormDetails({
            ...formdetails,
            video_type: tab
        })
        setSearchDetails({
            ...formdetails,
            video_type: tab
        })
        setActiveTab(tab);
    }

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
    }
    const handleTitleChange = (event) => {
        setFormDetails({
            ...formdetails,
            title: event.target.value
        })
    }
    const handleCategoriesChange = (selectedOptions) => {
        if (selectedOptions) {
            const categoryArray = selectedOptions.map(option => option.value)
            setFormDetails({ ...formdetails, category: categoryArray });
        }
        else {
            setFormDetails({
                ...formdetails, category: ""
            })
        }

    };
    const syncVideoPopup = async (element) => {
        setShowPopup({
            alert: (
                <ReactBSAlert
                    warning
                    title="Are you sure?"
                    onConfirm={(event) => syncVideo(element.video_id)}
                    onCancel={() => hideAlert()}
                    confirmBtnBsStyle="info"
                    cancelBtnBsStyle="danger"
                    confirmBtnText="Sync Video"
                    cancelBtnText="Cancel"
                    showCancel
                    closeOnClickOutside={false}
                >
                    You want to Restore this video!
                </ReactBSAlert>
            )
        })
    }
    const syncVideo = async (videoId) => {
        const syncVideo = await ctvAppService.syncVideo({
            video_id: videoId,
            project_id: projectId,
            
        })
        if (syncVideo.status && syncVideo.data) {
            setShowPopup({
                alert: (
                    <ReactBSAlert
                        success
                        title="Success"
                        onConfirm={() => hideAlert()}
                        onCancel={() => hideAlert()}
                        confirmBtnBsStyle="info"
                    />
                )
            })
        }
        else {
            setShowPopup({
                alert: (
                    <ReactBSAlert
                        danger
                        title="Please try again!"
                        onConfirm={() => hideAlert()}
                        onCancel={() => hideAlert()}
                        confirmBtnBsStyle="info"
                    />
                )
            })
        }
        setReloadComponent(Math.random());
    }
    const deleteVideoPopup = async (element) => {
        setShowPopup({
            alert: (
                <ReactBSAlert
                    warning
                    title="Are you sure?"
                    onConfirm={(event) => deleteVideo(element)}
                    onCancel={() => hideAlert()}
                    confirmBtnBsStyle="info"
                    cancelBtnBsStyle="danger"
                    confirmBtnText="Yes, delete it!"
                    cancelBtnText="Cancel"
                    showCancel
                >
                    You want to delete this video!
                </ReactBSAlert>
            )
        })
    }

    const restoreVideoPopup = async (element) => {
        setShowPopup({
            alert: (
                <ReactBSAlert
                    warning
                    title="Are you sure?"
                    onConfirm={(event) => restoreVideo(element.video_id)}
                    onCancel={() => hideAlert()}
                    confirmBtnBsStyle="info"
                    cancelBtnBsStyle="danger"
                    confirmBtnText="Yes, restore it!"
                    cancelBtnText="Cancel"
                    showCancel
                >
                    You want to restore this video!
                </ReactBSAlert>
            )
        })
    }

    const restoreVideo = async (videoId) => {
        const restVideo = await ctvAppService.syncVideo({ video_id: videoId,project_id: projectId, });
        if (restVideo.status && restVideo.data) {
            setShowPopup({
                alert: (
                    <ReactBSAlert
                        success
                        title="Restored!"
                        onConfirm={() => hideAlert()}
                        onCancel={() => hideAlert()}
                        confirmBtnBsStyle="info"
                    >
                        Video Restored successfully
                    </ReactBSAlert>
                )
            })
        } else {
            setShowPopup({
                alert: (
                    <ReactBSAlert
                        danger
                        title="Please try again!"
                        onConfirm={() => hideAlert()}
                        onCancel={() => hideAlert()}
                        confirmBtnBsStyle="info"
                    >
                        Video deleted successfully
                    </ReactBSAlert>
                )
            })
        }
        setReloadComponent(Math.random());

    }

    const deleteVideo = async (element) => {
        const delVideo = await ctvAppService.updateVideoStatus({ project_id: projectId, video: element, status: 0 });
        if (delVideo.status && delVideo.data) {
            setShowPopup({
                alert: (
                    <ReactBSAlert
                        success
                        title="Deleted!"
                        onConfirm={() => hideAlert()}
                        onCancel={() => hideAlert()}
                        confirmBtnBsStyle="info"
                    />
                )
            })
        } else {
            setShowPopup({
                alert: (
                    <ReactBSAlert
                        danger
                        title="Please try again!"
                        onConfirm={() => hideAlert()}
                        onCancel={() => hideAlert()}
                        confirmBtnBsStyle="info"
                    />
                )
            })
        }
        setReloadComponent(Math.random());
    }

    const hideAlert = () => {
        setShowPopup({
            alert: null
        })
    }

    const handleSubmit = function (e) { searchVideos(); e.preventDefault() }

    const searchVideos = () => {
        setSearchDetails(formdetails)
        setActivePage(1);

    }
    const resetFilters = () => {
        console.log("in reset ");
        setFormDetails({
            ...formdetails,
            title: "",
            category: "",
            status: ""
        })
        setSearchDetails({
            ...formdetails,
            title: "",
            category: "",
            status: ""
        })
        setActivePage(1)

    }
    let videoElements = [];
    let firstRecord = 0;
    let lastRecord = 0;
    if (!videos || !videos.length) {
        if (!renderComponent) {
            videoElements.push(
                <tr>
                    <td className="text-center">
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </td>
                </tr>
            )
        }
        else {
            videoElements.push(
                <td colSpan="4" className="text-center">
                    <CardTitle tag="h4" className="boRslt">No Videos Found</CardTitle>
                </td>
            )
        }
    }
    else {
        let i = 1;
        videos.forEach(function (videoDetails) {
            // console.log(videoDetails);
            const createdAtTime = moment(videoDetails.createdAt);

            const publishedAtTime = videoDetails.published_at ? moment(videoDetails.published_at) : createdAtTime;

            let className = '';
            if (!videoDetails.status) {
                className = "table-danger"
            }
            // console.log(videoDetails);
            if (i === 1) {
                firstRecord = (activePage - 1) * records + i;
            }
            videoElements.push(
                <tr className={className}>
                    <td className="text-left">{firstRecord - 1 + i}</td>
                    <td className="text-left">
                        {
                            <div className="libTblElms">
                                <Row>
                                    <Col md="2">
                                        <img
                                            src={videoDetails.thumbnail} />
                                    </Col>
                                    <Col md="8">
                                        <Row>
                                            {videoDetails.title}
                                        </Row>
                                        <Row>
                                            Video Id : {videoDetails.video_id}
                                        </Row>

                                    </Col>
                                </Row>
                            </div>
                        }
                    </td>
                    <td className="text-left">
                        {videoDetails.categories.map(data => (
                            <>
                                {data.name}<br />
                            </>


                        ))}
                    </td>
                    <td className="text-left">
                        {publishedAtTime.format('h:mm:ss a')}<br />
                        {publishedAtTime.format('Do MMM YYYY')}

                    </td>
                    <td className="text-left">
                        {createdAtTime.format('h:mm:ss a')}<br />
                        {createdAtTime.format('Do MMM YYYY')}

                    </td>
                    <td className="text-left">
                        {
                            videoDetails.status === 1 ?
                                <Row>
                                    <div className="contntLibBtn">
                                        <Col>
                                            <FontAwesomeIcon icon={faEdit} onClick={(event) => history.push('/ctv/edit-video?video=' + videoDetails._id)} style={{ cursor: "pointer" }} data-tip="Edit" />
                                        </Col>
                                        <Col>
                                            <FontAwesomeIcon icon={faTrash} onClick={(event) => deleteVideoPopup(videoDetails)} style={{ cursor: "pointer" }} data-tip="Delete" />
                                        </Col>
                                        {videoDetails.edited_by !== "system" && (
                                            <Col>
                                                <FontAwesomeIcon icon={faCloudDownloadAlt} onClick={(event) => syncVideoPopup(videoDetails)} style={{ cursor: "pointer" }} data-tip="Re-sync" />
                                            </Col>
                                        )}
                                        <ReactTooltip effect="solid" />
                                    </div>

                                </Row>
                                :
                                <Row>
                                    <div className="contntLibBtn">
                                        <Col>
                                            <FontAwesomeIcon icon={faTrashRestoreAlt} onClick={(event) => restoreVideoPopup(videoDetails)} style={{ cursor: "pointer" }} data-tip="Restore" />
                                        </Col>
                                    </div>
                                </Row>
                        }
                    </td>
                </tr>
            )
            if (i === videos.length) {
                lastRecord = (activePage - 1) * records + i;
            }
            i++;
            if (i === videos.length) {
                lastRecord = (activePage - 1) * records + i;
            }
        })
    }
    return (
        <div className="container mt-30">
            {/* <Card> */}
            {showPopup.alert}
            <CardHeader>
                <div className="ctvLibaryHead">
                    <Row>
                        <Col md="2">
                            <label
                                className={activeTab === "" ? 'active' : ""}
                                onClick={() => handleTabClick("")}
                                style={{ cursor: "pointer" }}
                            >
                                All Videos
                            </label>
                        </Col>
                        <Col md="2">
                            <label
                                className={activeTab === "youtube" ? 'active' : ""}
                                onClick={() => handleTabClick("youtube")}
                                style={{ cursor: "pointer" }}
                            >
                                Youtube Videos
                            </label>
                        </Col>
                        <Col md="2">
                            <label
                                className={activeTab === "jwplayer" ? 'active' : ""}
                                onClick={() => handleTabClick("jwplayer")}
                                style={{ cursor: "pointer" }}
                            >
                                JW Player Videos
                            </label>
                        </Col>
                    </Row>
                    <div class="underLine"></div>
                </div>
            </CardHeader>
            <CardBody>
                <Form className="form" method="POST" onSubmit={handleSubmit}>
                    <Row>
                        <Col md="3">
                            <Input
                                type="text"
                                name="searchname"
                                value={formdetails.title}
                                style={{ width: '100%', height: "75%", marginBottom: "20px", fontWeight: "bold", borderRadius: '8px' }}
                                id="searchname"
                                onChange={handleTitleChange}
                                placeholder="Search in Title" />

                        </Col>
                        <Col md="3">
                            <Select
                                className="react-select primary"
                                value={categoriesList.filter(option => formdetails.category.includes(option.value))}
                                onChange={handleCategoriesChange}
                                placeholder="Select a category"
                                options={categoriesList}
                                styles={customStyles}
                                isMulti
                                closeMenuOnSelect={false}
                            />
                        </Col>
                        <Col md="3">
                            <Select
                                className="react-select primary"
                                value={statusOptions.filter(option => option.value == formdetails.status)}
                                onChange={(e) => setFormDetails({ ...formdetails, status: e.value })}
                                placeholder="Select Status"
                                options={statusOptions}
                                styles={customStyles}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md="4">
                            <div style={{ float: "left" }}>
                                <Button
                                    className="btn-round"
                                    color="info"
                                    type="submit"
                                    onClick={() => searchVideos()}
                                >
                                    Search
                                </Button>
                                <Button
                                    className="btn-round"
                                    color="warning"
                                    type="button"
                                    onClick={() => resetFilters()}
                                >
                                    Reset
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
                <hr />
                {totalVideos > 0 ?
                    <Row>
                        <Col>
                            <div className="pagNo">
                                <b>
                                    {firstRecord} - {lastRecord} of {totalVideos}
                                </b>
                            </div>
                        </Col>
                        <Col>
                            <div className="pagBox">
                                <div className="pagDt">
                                    <Pagination
                                        activePage={activePage}
                                        itemsCountPerPage={records}
                                        totalItemsCount={totalVideos}
                                        pageRangeDisplayed={3}
                                        onChange={handlePageChange.bind(this)}
                                        itemClass="page-item"
                                        linkClass="page-link"
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                : null}

                <Row>
                    <Col>
                        <Table responsive hover>
                            <thead>
                                <tr>
                                    <th>#</th>

                                    <th>Title</th>

                                    <th>Categories</th>

                                    <th>Published On</th>

                                    <th>Created On</th>

                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {videoElements}
                            </tbody>
                        </Table>

                    </Col>
                </Row>

            </CardBody>
        </div>
    )
}

export default ContentLibrary;


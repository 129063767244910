import React, { useState, useEffect, useContext} from 'react'
import {
  useParams
} from "react-router-dom";

import { useHistory } from "react-router";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faPlus, faImage, faGripVertical, faImages} from '@fortawesome/free-solid-svg-icons';

import { 
  CardGroup,
  Card,
  CardImg,
  CardTitle,
  Alert,
  Container,
  Col,
  Row,
  CardHeader
} from 'reactstrap';

import webstoryService from "../../services/api/web-story-service";

import { 
  templates, 
  storypagelimit, 
  getAppIcon, 
  getMediaIcon,
  getDefaultFont,
  loadFonts
} from "./webstory-config";

import ViewAllStoriesIcon from '../../assets/img/icons/view_all_stories.png';
import ProjectContext from '../../ProjectContext';

import 'react-quill/dist/quill.snow.css';
import './storyBuilder.css';
import LazyLoadImage from "./components/LazyLoadImage";



const input = {
  borderLeft: '2px solid red'
}

const defaultAlertState = {status:false, msg:null,color:'success'};

const StoryPageList = () => {

  const {storyId, slug} = useParams();
  const history = useHistory();
  const projectDetails = useContext(ProjectContext);

  const [alertState,setAlertState] = useState(defaultAlertState);
  const [storyPages, setStoryPages] = useState([]);
  const [appIcon, setAppIcon] = useState('');
  const [webstoryArticle, setWebStoryArticle] = useState({});
  const [defaultFont, setDefaultFont] = useState('');
  
  useEffect(() => {
    (async () =>{
      console.log('\n \n -------------- Webstory Article --------------')
      await getWebstoryById();
      console.log('\n \n --------------  Webstory Pages  --------------')
      await getWebstoryPages();

      const response = await getDefaultFont(projectDetails.id);
      if(response && !response.err) {
          setDefaultFont(response);
      } else {
          setDefaultFont('sans-serif');
      }
      const res = await getAppIcon(projectDetails.id);
        if(res && !res.err) {
            setAppIcon(res);
      }
  })();

  },[]);

  useEffect(() => {
    (async () => {
       await loadFonts(defaultFont);
    })();

}, [defaultFont]);

  const navigate = (url,response={}) =>{
    history.push({
      pathname:  url,
      state: {
        response: response 
      } 
   });
  }
  const openAlert = (obj, autoClose=true)=> {
    setAlertState(obj);
    if(autoClose) {
        setTimeout(() => {
            closeAlert();
        }, 1000);
    }
  }
  const closeAlert = ()=>{
      setAlertState(defaultAlertState);
  }

  const getWebstoryPages = async ()=>{
    try {
      const response = await  webstoryService.getWebstoryPages({articleId: storyId, projectId:projectDetails.id});
      if(response.status) {
        setStoryPages([...response.data]);
        console.log(response)
        console.log(storyPages)
      } else {
        openAlert({status:true, msg:'Error Loading Webstory Pages',color:'danger'});
      }
    } catch(err){
      console.log("WEBSTORY ERROR :: Get Webstory Pages ::",err);
      openAlert({status:true, msg:'Failed to Get Webstory Pages',color:'danger'},false);
    }
  }
  const getWebstoryById = async () => {
    try {
        const response = await  webstoryService.getWebstoryById({storyId});
        console.log(response) 
        if(!response.status) {
            openAlert({status:true, msg:'Error Loading Webstory Data',color:'danger'},false);
        }
        else { 
            setWebStoryArticle(response.data);
        }
    } catch(err){
        console.log("WEBSTORY ERROR :: Get Webstory Data ::",err);
        openAlert({status:true, msg:'Failed to Get Webstory Data',color:'danger'}, false);
    }
}

  const addNewWebstoryPage = async()=> {
    try{
      if(storyPages.length >= storypagelimit) {
        openAlert({status:true, msg:'Max Limit Reached',color:'warning'},false);
      } else {
        var payload = {
          article_id: storyId,
          order:storyPages.length + 1,
          template_name:storyPages[0].template_name
        }

        const response = await webstoryService.createWebstoryPage(payload);
        
        console.log(response)
        if(projectDetails.isContractExpired){
          projectDetails.setContractExpiredAlert(projectDetails);
        }
        else if(response.status && response.data) {
          navigate(`/dashboard/storyboard/${slug}/${storyPages.length + 1}/${storyId}`, response);
        } else {
          openAlert({status:true, msg:'Error Adding New Page',color:'danger'});
        }
      }
    } catch(err){
      console.log("WEBSTORY ERROR :: Add New Story Page ::",err);
      openAlert({status:true, msg:'Failed to Add Story Page',color:'danger'}, false);
    }
  }

  const getDefaultWidget = (val) => {
    return <div >
       Your {val} Embed will be here
    </div>
  }
  const getFontFor = (value) => {
    console.log('getFontFor', value);
    loadFonts(value);
    return '';
  }
  
  return (
    <Container>
        <Alert isOpen={alertState.status} color={alertState.color} toggle={closeAlert}>
            {alertState.msg}
        </Alert>
      <Card >
        <div style={{width:'100%',padding: "10px 10px 0"}}>
            
        </div>
        <CardHeader>
            <CardTitle tag="h4" className="heding_tab_h4 w-100">
                <Row>
                    <Col md="6">
                        Story Pages ( {storyPages.length} )    
                    </Col>
                    <Col md="6" style={{display:'flex', justifyContent:'right'}}>
                          <div className="action-box " style={(storyPages.length>=storypagelimit)?{opacity:0.5}:null} onClick={addNewWebstoryPage}>
                              <FontAwesomeIcon  className="m-1" style={{cursor:'pointer'}} icon={faPlus} />

                              { !(storyPages.length>=storypagelimit) ? <span className="tooltiptext right">Add New Page</span>:null}
                          </div>
                          <div className="action-box " onClick={()=>navigate(`/dashboard/storyboard/${slug}/1/${storyId}`)}>
                              <FontAwesomeIcon  className="m-1" style={{cursor:'pointer'}} icon={faGripVertical} />
                                      <span className="tooltiptext right">Story Board View</span>

                          </div>
                          <div className="action-box"  onClick={()=>{webstoryArticle.published?navigate(`/contentsettings/webstory/list/published`):navigate(`/contentsettings/webstory/list/drafts`)}}>
                              <img  className="m-1" style={{cursor:'pointer', width:'18px'}} src={ViewAllStoriesIcon} />

                              <span className="tooltiptext right">View All Stories</span>
                          </div>
                    </Col>

                </Row>  
                        
            </CardTitle>
            
        </CardHeader>
        <hr/>
        <CardGroup className="justify-content-sm-around w-100" >
        {storyPages.map((storyPage,index)=>(
          <div key={index} className=" col-2 m-1" >
            
            <Card  
            className="grid-view-container template-card"
            onClick={()=>navigate(`/dashboard/storyboard/${slug}/${storyPage.order}/${storyId}`)}>
              {getMediaIcon(storyPage)}
              {/* {storyPage.background_image? 
                <CardImg
                      alt="Card image cap"
                      src={storyPage.background_image}
                      top
                      height="100%"
                      width="100%"
                      style={{objectFit:'cover', borderRadius:2}}
              />:null} */}
              {getFontFor(storyPage.font)}

                <div  
                    className="position-relative h-100" 
                    style={storyPage.background_color?{background:storyPage.background_color, filter:`brightness(${storyPage.background_options?storyPage.background_options.brightness:1}) contrast(${storyPage.background_options?storyPage.background_options.contrast:1})`}:null} 
                    >
                          {storyPage.background_image?<LazyLoadImage style={{filter:`brightness(${storyPage.background_options?storyPage.background_options.brightness:1}) contrast(${storyPage.background_options?storyPage.background_options.contrast:1})`}}
                              key={storyPage.background_image}
                              url={storyPage.background_image}
                          />:null}
                          {storyPage.background_image_by_url?<LazyLoadImage style={{filter:`brightness(${storyPage.background_options?storyPage.background_options.brightness:1}) contrast(${storyPage.background_options?storyPage.background_options.contrast:1})`}}
                              key={storyPage.background_image_by_url}
                              url={storyPage.background_image_by_url}
                          />:null}
                          {storyPage.blockquote? <div className="embed-container">
                                {getDefaultWidget('Twitter')}
                          </div>:null}
                          {storyPage.background_video? 
                          <video key={storyPage.background_video} style={{objectFit:'cover', height:'100%', width:'100%', borderRadius:2, filter:`brightness(${storyPage.background_options?storyPage.background_options.brightness:1}) contrast(${storyPage.background_options?storyPage.background_options.contrast:1})`}} 
                          className='videoTag' autoPlay loop muted>
                            <source src={storyPage.background_video} type='video/mp4' />
                          </video>:null}

                          {(!storyPage.imageId && !storyPage.background_color && !storyPage.background_video)? 
                            <div className="no_image_available">
                              <FontAwesomeIcon  className="m-1" style={{cursor:'pointer',fontSize: 43}} icon={faImage} />
                              <CardTitle tag='h6' style={{marginTop:9}}>No Image Available</CardTitle>
                            </div>:null}

                            {(index===0 && storyPage.show_author)?<div className="webstory-author-name">
                                  by {webstoryArticle?.author}
                              </div>:null}
                  </div>
              <div className="grid-view-content-container" style={templates[storyPage.template_name]?.css.containerCss}>
                    { templates[storyPage.template_name]?.css.logoCss && storyPage.order==1?
                        <img style={templates[storyPage.template_name]?.css.logoCss} src={appIcon? appIcon:templates[storyPage.template_name]?.logo}/>
                    :null}
                   
                    <div className='ql-snow' style={templates[storyPage.template_name]?.css.qlSnowCorrectionCss? templates[storyPage.template_name]?.css.qlSnowCorrectionCss:{}}>
                        <div  style={{fontFamily:`'${storyPage.font?storyPage.font:defaultFont}'`, ...(storyPage.order>1 ? templates[storyPage.template_name]?.css.descriptionCss:templates[storyPage.template_name]?.css.headlinesCss) }}
                              dangerouslySetInnerHTML={{__html:(storyPage.description || storyPage.headlines)? (storyPage.order>1? storyPage.description : storyPage.headlines):'' }}
                              className="ql-editor">
                        </div>
                    </div>
                </div>
            </Card>
          </div>
        ))}
        
        </CardGroup>
      </Card>
    
    </Container>

  )


  
}

export default StoryPageList
import React, { useEffect, useState, useContext } from "react";
import contentService from "../../services/api/content-service";
import {useHistory} from "react-router-dom";
import ProjectContext from '../../ProjectContext';
import { Pie, Bar, Doughnut } from "react-chartjs-2";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  CardTitle,
  Spinner,
  Row,
  Col,
} from "reactstrap";


const ArticlesPieChart = (props)=> {
  const projectDetails = useContext(ProjectContext);
  const history = new useHistory();
  
  if(projectDetails.id==''){
    history.push("/project/allprojects");
  }
  const projectId = projectDetails.id;
  const [labels,setLabels] = useState(false);
  const [dataAvailable,setDataAvailable] = useState(false);
  const [data,setData] = useState(false);
  const [renderComponent,setRenderComponent] = useState(false);
 
  useEffect( () => {
    (async () =>{
        let articlesData = await contentService.getArticlesTypeCount(projectId);
            if(articlesData.status && articlesData.data){
                let articles = articlesData.data;
                let chartLabels  = [];
                let chartDataValues = [];
                if(articles.length){
                    articles.forEach(function(articleDetails) {
                        //if(articleDetails._id != null){
                            chartLabels.push(articleDetails._id);
                            chartDataValues.push(articleDetails.count);
                            setDataAvailable(true)
                        //}
                        
                    })
                }
                setLabels(chartLabels)
                setData(chartDataValues)
            }
            setRenderComponent(true);
        })();
    }, []);  
   
    const chartData = {
        labels: labels,
        datasets: [
          {
            label: '# of Votes',
            data: data,
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(255, 159, 64, 0.2)',
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)',
            ],
            borderWidth: 1,
          },
        ],
      };
 

    return (
            <Card>
                <CardHeader>
                  <CardTitle tag="h4" className="heding_dsh_h4">Type of Articles</CardTitle>
                  <hr></hr>
                </CardHeader>
                <CardBody>
                {!dataAvailable?<CardTitle tag="h4" className="noRslt mb3">No Article Found</CardTitle>:
                  <Pie
                    data={chartData}
                  />}
                </CardBody>
              </Card>
    );
  
  
}

export default ArticlesPieChart;

import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { Card, CardHeader, CardBody, CardTitle, FormGroup, Input, Label, Row, Col, Form, Button, Spinner, Alert, Container } from 'reactstrap'
import customStyles from '../../components/CustomStyles';
import Select from 'react-select'
import ctvAppService from '../../services/api/ctv-app-service';
import ProjectContext from '../../ProjectContext';
import formValidations from '../../variables/form-validations';



function ImportVideo() {
    const projectDetails = useContext(ProjectContext);
    const history = useHistory();
    if (projectDetails.id == '') {
        history.push("/project/allprojects");
    }
    const projectId = projectDetails.id;

    const [formdetails, setFormDetails] = useState({ project_id: projectId, video_source: "", name: "", categories: [], youtube_playlist_id: "", youtube_channel_id: "", data: {} })
    const [categoriesList, setCategoriesList] = useState([])
    const [alert, setAlert] = useState({ show: false, message: '', type: '' });
    const [showLoader, setShowLoader] = useState(false)
    const [showSaveButton, setShowSaveButton] = useState(true);
    const [fieldStates, setFieldStates] = useState({});
    const sourceOptions = [
        { value: "youtube_channel", label: "Youtube Channel" },
        { value: "youtube_playlist", label: "Youtube Playlist" }
    ]

    useEffect(() => {
        (async () => {
            let data = await ctvAppService.getVideoCategories({ projectId, status: 1 });
            if (data.status && data.data) {
                let array = Object.keys(data.data.catList).map(key => ({
                    label: data.data.catList[key].name,
                    value: data.data.catList[key]._id
                }));
                array.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
                setCategoriesList(array);
            }
        })()
    }, [])

    const validateForm = () => {
        let validations = true;
        console.log("invalidateform");
        if (formdetails.name.trim() === "") {
            fieldStates["error_name"] = "has-danger";
            fieldStates["error_msg_name"] = "This field is required"
            validations = false;
        }
        else if (formdetails.name && formValidations.verifySpecialCharacters(formdetails.name)) {
            fieldStates["error_name"] = "has-danger";
            fieldStates["error_msg_name"] = "Please enter a valid name."
            validations = false;
        }
        else {
            fieldStates["error_name"] = "has-success";
            fieldStates["error_msg_name"] = "";
        }

        if(formdetails.categories.length === 0){
            fieldStates['error_categories'] = 'has-danger';
            fieldStates['error_msg_categories'] = 'Please select at least one category';
            validations = false;
        }else{
            fieldStates["error_categories"] = "has-success";
            fieldStates["error_msg_categories"] = "";
        }

        if(formdetails.video_source.trim() === ""){
            fieldStates['error_source'] = 'has-danger';
            fieldStates['error_msg_source'] = 'Please select at least one source';
            validations = false;
        }else{
            fieldStates["error_source"] = "has-success";
            fieldStates["error_msg_source"] = "";
        }

        if(formdetails.video_source === 'youtube_playlist'){
            if (formdetails.youtube_playlist_id.trim() === "") {
                fieldStates["error_youtube_playlist_id"] = "has-danger";
                fieldStates["error_msg_youtube_playlist_id"] = "This field is required"
                validations = false;
            } else {
                fieldStates["error_youtube_playlist_id"] = "has-success";
                fieldStates["error_msg_youtube_playlist_id"] = "";
            }

        }else{
            if (formdetails.youtube_channel_id.trim() === "") {
                fieldStates["error_youtube_channel_id"] = "has-danger";
                fieldStates["error_msg_youtube_channel_id"] = "This field is required"
                validations = false;
            } else {
                fieldStates["error_youtube_channel_id"] = "has-success";
                fieldStates["error_msg_youtube_channel_id"] = "";
            }
        }



        setFieldStates({
            ...fieldStates,
            fieldStates,
        })
        return validations;
    }

    const importVideos = async () => {
        if (validateForm()) {
            setShowLoader(true);
            setShowSaveButton(false);
            const data = await ctvAppService.insertCtvVideoImporter(formdetails);
            console.log("data in import videos : ", data);
            if (data.status) {
                setTimeout(() => {
                    setShowLoader(false);
                    // setShowSaveButton(true);
                    setAlert({ show: true, type: "success", message: "Videos Importer Added" })
                }, 1000);
                setTimeout(() => {
                    setAlert({ show: false, type: "", message: "" });
                    history.push(`/ctv/dashboard?project=${projectDetails.slug}#videos`)
                }, 3000)
            } else {
                setTimeout(() => {
                    setShowLoader(false);
                    setShowSaveButton(true);
                    setAlert({ show: true, type: "danger", message: data.error.message });
                }, 1000)
            }
            
        }

    }
    const handleChangeName = (event) => {
        console.log("change event : ", event.target.id, event.target.value);
        setFormDetails({
            ...formdetails,
            [event.target.id]: event.target.value,
        });
        fieldStates['error_' + event.target.id] = 'has-success';
        fieldStates['error_msg_' + event.target.id] = "";
        setFieldStates({
            ...fieldStates,
            fieldStates,
        })
    }
    const handleChange = (event) => {
        setFormDetails({
            ...formdetails,
            [event.target.id]: event.target.value,
            data: {
                [event.target.id]: event.target.value,
            }
        });
        fieldStates['error_' + event.target.id] = 'has-success';
        fieldStates['error_msg_' + event.target.id] = "";
        setFieldStates({
            ...fieldStates,
            fieldStates,
        })
    }

    const handleCategoriesChange = (selectedOptions) => {
        if (selectedOptions) {
            const categoryIds = selectedOptions.map(option => option.value);
            setFormDetails({ ...formdetails, categories: categoryIds });
        }
        else {
            setFormDetails({ ...formdetails, categories: "" });
        }
        {
            fieldStates["error_categories"] = "has-success";
            fieldStates["error_msg_categories"] = "";
        }
    }

    const handleSubmit = function (e) {
        validateForm();
        e.preventDefault()
    }

    return (
        <div className='container mt-30'>
            <Container>
                <Row>
                    <Col className="ml-auto mr-auto" md="12">
                        <Card className ="card-signup text-center">
                            <CardHeader>
                                <CardTitle tag="h4">
                                    Import Videos
                                </CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Form className="form-horizontal" id="ctvCCategoriesForm" method="POST" onSubmit={handleSubmit}>
                                    <Row>
                                        <Label sm="2">Name<span className="text-danger"> * </span></Label>
                                        <Col sm="7">
                                            <FormGroup className={fieldStates["error_name"]}>
                                                <Input
                                                    type="text"
                                                    id="name"
                                                    name="name"
                                                    value={formdetails.name}
                                                    onChange={handleChangeName}
                                                />
                                                {
                                                    fieldStates['error_name'] === 'has-danger' ?
                                                        <label className="error">{fieldStates['error_msg_name']}</label>
                                                        :
                                                        null
                                                }
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Label sm='2'>Categories<span className='text-danger'>*</span></Label>
                                        <Col sm='7'>
                                            <FormGroup className={fieldStates["error_name"]}>
                                                <Select
                                                    className="react-select primary"
                                                    value={categoriesList.filter(option => formdetails.categories.includes(option.value))}
                                                    onChange={(e) => handleCategoriesChange(e)}
                                                    options={categoriesList}
                                                    styles={customStyles}
                                                    isMulti
                                                    closeMenuOnSelect={false}
                                                />
                                                {
                                                    fieldStates["error_categories"] === 'has-danger' ?
                                                        <label className="error">{fieldStates['error_msg_categories']}</label>
                                                        :
                                                        null
                                                }
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Label sm='2'>Source<span className='text-danger'>*</span></Label>
                                        <Col sm='7'>
                                            <FormGroup className={fieldStates["error_name"]}>
                                                <Select
                                                    className="react-select primary"
                                                    value={sourceOptions.filter(option => option.value === formdetails.video_source)}
                                                    onChange={(e) => {setFormDetails({ ...formdetails, video_source: e.value });
                                                    {
                                                        fieldStates["error_source"] = "has-success";
                                                        fieldStates["error_msg_source"] = "";
                                                    }
                                                }}
                                                    options={sourceOptions}
                                                    styles={customStyles}
                                                />
                                                 {
                                                    fieldStates["error_source"] === 'has-danger' ?
                                                        <label className="error">{fieldStates['error_msg_source']}</label>
                                                        :
                                                        null
                                                }
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    {(formdetails.video_source === 'youtube_channel') && (
                                        <Row>
                                            <Label sm="2">Youtube Channel ID<span className="text-danger"> * </span></Label>
                                            <Col sm="7">
                                                <FormGroup className={fieldStates["error_youtube_channel_id"]}>
                                                    <Input
                                                        type="text"
                                                        id="youtube_channel_id"
                                                        name="youtube_channel_id"
                                                        value={formdetails.youtube_channel_id}
                                                        onChange={handleChange}
                                                    />
                                                    {
                                                        fieldStates['error_youtube_channel_id'] === 'has-danger' ?
                                                            <label className="error">{fieldStates['error_msg_youtube_channel_id']}</label>
                                                            :
                                                            null
                                                    }
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    )}
                                    {(formdetails.video_source === 'youtube_playlist') && (
                                        <Row>
                                            <Label sm="2">Youtube Playlist ID<span className="text-danger"> * </span></Label>
                                            <Col sm="7">
                                                <FormGroup className={fieldStates["error_youtube_playlist_id"]}>
                                                    <Input
                                                        type="text"
                                                        id="youtube_playlist_id"
                                                        name="youtube_playlist_id"
                                                        value={formdetails.youtube_playlist_id}
                                                        onChange={handleChange}
                                                    />
                                                    {
                                                        fieldStates['error_youtube_playlist_id'] === 'has-danger' ?
                                                            <label className="error">{fieldStates['error_msg_youtube_playlist_id']}</label>
                                                            :
                                                            null
                                                    }
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    )}
                                    {showSaveButton ?
                                        <div>
                                            <Button
                                                className='btn-round'
                                                type="button"
                                                color="info"
                                                onClick={importVideos}
                                            >
                                                Import
                                            </Button>
                                            <Button
                                                className=" btn-round"
                                                type="button"
                                                color="danger"
                                                onClick={(event) => history.push(`/ctv/dashboard?project=${projectDetails.slug}#importer`)}
                                            >
                                                Cancel
                                            </Button>
                                        </div>
                                        : null}
                                    {
                                        alert.show ?
                                            <Alert color={alert.type}>
                                                {alert.message}
                                            </Alert>
                                            :
                                            ''
                                    }
                                    {
                                        showLoader ?
                                            <Spinner animation="border" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </Spinner>
                                            :
                                            ''
                                    }
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>

        </div>
    )
}

export default ImportVideo
import React, { useEffect, useState, useRef, useContext } from "react";
import { Button, Spinner } from "reactstrap";
import defaultImage from "../../../assets/img/image_placeholder.jpg";
import customizationsService from "../../../services/api/customizations-service";
import { useHistory } from "react-router-dom";
import axios from "axios";
import ProjectContext from "../../../ProjectContext";

const UploadImage = (props) => {
    const projectId = props.projectId;
    const projectDetails = useContext(ProjectContext)

    let configDefaultImage = '';
    if (props.imageDetails && props.imageDetails.default && props.imageDetails.default != '') {
        configDefaultImage = props.imageDetails.default;
        if (props.elementValue == '') {
            let image = {
                value: props.imageDetails.default
            }
            props.handleEnumChange(props.id, image);
        }
    } else {
        configDefaultImage = defaultImage;
        // setImagePreviewUrl(defaultImage);
    }

    const [file, setFile] = useState(null);
    const [imagePreviewUrl, setImagePreviewUrl] = useState(configDefaultImage);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showLoader, setShowLoader] = useState(false);
    const fileInputRef = useRef(null);

    // console.log(props.elementValue);
    if (props.elementValue && imagePreviewUrl != props.elementValue) {
        setImagePreviewUrl(props.elementValue);
    } else {
        // if(props.imageDetails && props.imageDetails.default && props.imageDetails.default!='' && imagePreviewUrl!=props.imageDetails.default){
        //   setImagePreviewUrl(props.imageDetails.default);
        // }
    }


    const handleImageChange = (e) => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        let fileValidated = true;
        if (file.size > 2048000) { //1024000
            fileValidated = false;
            setError(true);
            setErrorMessage('File Size is greater than 1MB');
        }

        let fileTypeArray = props.imageDetails.file_type.split(',');
        if (!fileTypeArray.includes(file.type)) {
            fileValidated = false;
            setError(true);
            setErrorMessage('Invalid image file. Only ' + props.imageDetails.file_type + ' supported');
            return false;
        }
        let contentType = file.type;
        // if(props.imageDetails.size){
        //   fileValidated = false;
        //   let imageWidth = props.imageDetails.size.split('x')[0];
        //   let imageHeight = props.imageDetails.size.split('x')[1];

        //   reader.onloadend = async (event) => {
        //     //for validation on size
        //     var image = new Image();
        //     image.src = reader.result;
        //     image.onload = function () {
        //         var height = this.height;
        //         var width = this.width;
        //         if (height != imageHeight && width != imageWidth) {
        //           fileValidated = true;
        //         }else{
        //             fileValidated = false;
        //             setError(true);
        //             setErrorMessage('Please upload image of '+props.imageDetails.size);
        //             return false;
        //         }
        //     };
        //     image.onerror = function() {
        //       fileValidated = false;
        //       setError(true);
        //       setErrorMessage('Invalid Image');
        //       return false;
        //     };

        //   };
        //   reader.readAsDataURL(file);
        // }

        if (fileValidated) {
            let uploadImage = false;
            reader.onload = async (event) => {
                var image = new Image();
                image.src = reader.result;
                image.onload = async function () {
                    var height = this.height;
                    var width = this.width;
                    if (props.imageDetails.size) {
                        let imageWidth = props.imageDetails.size.split('x')[0];
                        let imageHeight = props.imageDetails.size.split('x')[1];
                        if (height == imageHeight && width == imageWidth) {
                            uploadImage = true;
                        } else {
                            fileValidated = false;
                            setError(true);
                            setErrorMessage('Please upload image of ' + props.imageDetails.size);
                            return false;
                        }
                    } else {
                        uploadImage = true;
                    }

                    if (uploadImage) {
                        let fileData = event.target.result;
                        fileData = fileData.split(',')[1];
                        setShowLoader(true);
                        let uploadImageToServer = await customizationsService.uploadImage(projectId, props.id, fileData, contentType);
                        if (projectDetails.isContractExpired) {
                            setShowLoader(false);
                            setError(false);
                            projectDetails.setContractExpiredAlert(projectDetails);
                        }
                        else if (uploadImageToServer.status) {
                            setError(false);
                            setImagePreviewUrl(uploadImageToServer.data);
                            let image = {
                                value: uploadImageToServer.data
                            }
                            props.handleEnumChange(props.id, image);
                            setShowLoader(false);
                        } else {
                            setError(false);
                            setErrorMessage("There was an error. Please try again.");
                            setShowLoader(false);
                        }
                    }
                };
                image.onerror = function () {

                    fileValidated = false;
                    setError(true);
                    setErrorMessage('Invalid Image');
                    return false;
                };


            };

            reader.readAsDataURL(file);
        }
    }


    const handleImageChangeNew = (e) => {
        const file = e.target.files[0];

        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = (event) => {
                // console.log(event.target.result);
                resolve(event.target.result);
            };

            reader.onerror = (err) => {
                reject(err);
            };

            reader.readAsDataURL(file);
        });
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        // this.state.file is the file/image uploaded
        // in this function you can save the image (this.state.file) on form submit
        // you have to call it yourself
    }
    const handleClick = () => {
        fileInputRef.current.click();
    }
    const handleRemove = () => {
        setFile(null);
        setImagePreviewUrl(defaultImage);
        fileInputRef.current.value = null;
        let image = {
            value: ""
        }
        props.handleEnumChange(props.id, image);
    }


    return (
        <div className="fileinput text-center">
            <input type="file" onChange={handleImageChange} ref={fileInputRef} />
            <div className={"thumbnail"}>
                <img src={imagePreviewUrl} alt="..." />
            </div>
            <div>
                {(imagePreviewUrl === '' || imagePreviewUrl === defaultImage) ? (
                    <Button className="btn-round" onClick={() => handleClick()}>
                        {"Select image"}
                    </Button>
                ) : (
                    <span>
                        <Button className="btn-round" onClick={() => handleClick()}>
                            Change
                        </Button>

                        <Button
                            color="danger"
                            className="btn-round"
                            onClick={() => handleRemove()}
                        >
                            <i className="fa fa-times" />
                            Remove
                        </Button>
                    </span>
                )}
            </div>

            {showLoader ? <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
            </Spinner> : ''}

            {error ? <label className="error" style={{ color: 'red' }}>
                {errorMessage}
            </label> : ''}
        </div>
    );

}


export default UploadImage;

import React, { useEffect, useState } from "react";
import TimezoneSelect from "react-timezone-select";

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Input,
    Alert,
    Spinner,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col,
} from "reactstrap";

import Select from "react-select";
import subscriptionService from "../../services/api/subscription-service";
import moment from "moment";
import { Link } from "react-router-dom";
import { number } from "prop-types";
import {useCookies} from 'react-cookie';
import { faFileSignature } from "@fortawesome/free-solid-svg-icons";
const SubscriptionAlert = (props) => {
    
    const [cookies, setCookie] = useCookies([`subscriptionHaltedAlert_${props.project.slug}`])
    const [showAlert,setShowAlert]=useState(false);
    //console.log("Cookies",cookies.showPlanExpiryAlert);
    const [visible, setVisible] = useState(true);
    const hideAlert = () =>{
        if(cookies[`subscriptionHaltedAlert_${props.project.slug}`] === "hide") {
            setShowAlert(false)
        }else{ 
            setShowAlert(true);
        }
    }

    useEffect(()=>{
        hideAlert()
    },[])


    const onDismiss = () =>{ 
        setVisible(false);
        setCookie(`subscriptionHaltedAlert_${props.project.slug}`,"hide");
        setShowAlert(false);
    }
    const [alertMessage, setAlertMessage] = useState("");
    const [isPlanExtended,setIsPlanExtended] = useState(false);
    const [subscription,setSubscription] = useState(false);   


    useEffect(() => {
        (async () => {
            const isSubscribed = await subscriptionService.isActiveSubscriptionHalted(props.project.id);
            if(isSubscribed.status && isSubscribed.data) {
                setSubscription(isSubscribed.data);
                if(isSubscribed.data.status === "halted") {
                    setShowAlert(true);
                    return;
                }else {
                    onDismiss();
                }
            }else{
                onDismiss();
            }
        })();
    }, []);
    return <> 
               { showAlert? 
                        <div
                            style={{
                                position:"relative",
                                marginLeft: "40px",
                                marginRight: "40px",
                                marginTop: "5px",
                                marginBottom: "-1px",
                            }}
                        >
                            
                            <Alert  style={{"background-color":"#ed1a44"}} isOpen={visible} toggle={onDismiss}>
                                {alertMessage} 
                                <div>
                                 A final charge attempt towards your subscription has failed. Another charge will not be attempted for this billing cycle. To continue with this subscription, please pay manually.
                                 <Link
                                     to={`/subscriptions/pay?subscriptionid=${subscription.pg_subscription_id}&projectid=${props.project.id}&project=${props.project.slug}`}
                                 >
                                     Pay Now
                                 </Link>
                             </div>
                            </Alert>
                
                        </div>
                    : ""
            }
        
        </>
};

export default SubscriptionAlert;

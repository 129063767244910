import React, { useEffect, useState, useContext } from "react";
import planService from "../../services/api/plan-service";
import authService from "../../services/api/auth-service";
import { useHistory } from "react-router-dom";
import ProjectContext from "../../ProjectContext";
import ReactBSAlert from "react-bootstrap-sweetalert";
import "./plan.css";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  CardText,
  CardImg,
  Label,
  FormGroup,
  Form,
  Input,
  Alert,
  Spinner,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";
import PlanDetails from "./PlanDetails";
import PlanDetailsCard from "./PlanDetailsCard";
const Plan = (props) => {
  const projectDetails = useContext(ProjectContext);
  const history = new useHistory();
  if (projectDetails.id == "") {
    history.push("/project/allprojects");
  }
  let getProjectSlug = new URLSearchParams(props.location.search).get(
    "project"
  );
  if (!getProjectSlug) {
    history.push({
      search: "?project=" + projectDetails.slug,
    });
  }
  const projectId = projectDetails.id;
  const [planType, setPlanType] = useState("monthly");
  const [plans, setPlans] = useState("");
  const [planData, setPlanData] = useState();
  const [monthlyPlanData, setMonthlyPlanData] = useState(false);
  const [yearlyPlanData, setYearlyPlanData] = useState(false);
  const [currentPlan , setCurrentPlan] = useState(false);
  const [features, setFeatures] = useState(false);
  const [planGroups, setPlanGroups] = useState(false);
  const [showPopup, setShowPopup] = useState({ alert: null });
  const [showLoader, setShowLoader] = useState(false);
  let monthlyPlans = [];
  let yearlyPlans = [];
  let planTypes = ["Basic","Premium","Business","Enterprise"];
  const [planIndex , setPlanIndex ] = useState(0);
  const [formdetails, setFormDetails] = useState({projectId, coupon_code : ''});
  const [couponCodeState, setCouponCodeState] = useState("");
  const [couponCodeError, setCouponCodeError] = useState("");
  const [couponApplied, setCouponApplied] = useState(false);
  useEffect(() => {
    (async () => {
      //code to get Plan details comes here
      setShowLoader(true);
      let currentPlanDetails = await planService.getPlanDetails(projectId);
      if(currentPlanDetails.status && currentPlanDetails.data) {
        setCurrentPlan(currentPlanDetails.data);
        setPlanIndex(planTypes.indexOf(currentPlanDetails.data.plan_name));
      }
      const response = await planService.getActivePlans();
      if (response.status && response.data) {
        console.log({
          monthlyPlans:response.data.monthlyPlans
        })
        setMonthlyPlanData(response.data.monthlyPlans);
        setYearlyPlanData(response.data.yearlyPlans);
        console.log(response.data.yearlyPlans);
        setFeatures(response.data.features);
        setShowLoader(false);
      }
      // console.log("Monthly Plans:",monthlyPlanData.Basic.planFeatures);
      // setPlanType("monthly");

      const plan_groups = await planService.getPlanGroups();
      if (plan_groups.status) {
        console.log(plan_groups.data);
        setPlanGroups(plan_groups.data);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.async = true;
      document.body.appendChild(script);
    })();
  }, []);
  const hideAlert = () => {
    setShowPopup({
      alert: null,
    });
  };
  const handleSubmit = function(e){ 
    console.log("formdetails",formdetails);
     validateCoupon();
     e.preventDefault() 
    }
    const validateCoupon = async ()=> {
      setCouponApplied(false);
      if(formdetails.coupon_code==="") {
          setCouponCodeState('has-danger');
          setCouponCodeError("Please enter coupon code");
          return;
      }
      const coupon = await planService.validateCoupon(formdetails);
      if(coupon.status) {
        if(coupon.data) {
            setCouponApplied(coupon.data);
            setCouponCodeState('has-danger');
            setCouponCodeError("");
        }else{
          setCouponCodeState('has-danger');
          setCouponCodeError("Invalid Coupon");
        }
      }
    }
  const handleChange = function(event) {
    setFormDetails({
      ...formdetails,
      [event.target.id]  : event.target.value
    });
    setCouponCodeState('has-success');
    setCouponCodeError("");
  }
  const processPayment = async (projectId, planId) => {
    let userDetails = await authService.getLoggedInUser();
    console.log(userDetails);
    let planOrderData = await planService.createPlanOrder(
      projectId,
      planId,
      "Razorpay"
    );
    if (planOrderData.status && planOrderData.data) {
      let orderData = planOrderData.data;
      var options = {
        key: orderData.razorpay_key_id,
        amount: orderData.amount, // 2000 paise = INR 20, amount in paisa
        name: orderData.name,
        description: orderData.description,
        order_id: orderData.order_id,
        handler: async function (response) {
          var values = {
            razorpay_signature: response.razorpay_signature,
            razorpay_order_id: response.razorpay_order_id,
            razorpay_payment_id: response.razorpay_payment_id,
            amount: orderData.amount,
            transaction_id: orderData.transaction_id,
          };

          let planVerifyPayment = await planService.verifyPlanPayment(
            projectId,
            planId,
            values
          );
          if (planVerifyPayment.status && planVerifyPayment.data.verified) {
            // alert("success")
            setShowPopup({
              alert: (
                <ReactBSAlert
                  success
                  style={{ display: "block", marginTop: "-100px" }}
                  title="Payment Successfull!"
                  onConfirm={() => hideAlert()}
                  onCancel={() => hideAlert()}
                  confirmBtnBsStyle="info"
                  btnSize=""
                >
                  Payment Done successfully.
                </ReactBSAlert>
              ),
            });

            setTimeout(() => {
              hideAlert();
            }, 5000);
          } else {
            // alert("failure")
            setShowPopup({
              alert: (
                <ReactBSAlert
                  danger
                  style={{ display: "block", marginTop: "-100px" }}
                  title="Payment Failed!"
                  onConfirm={() => hideAlert()}
                  onCancel={() => hideAlert()}
                  confirmBtnBsStyle="info"
                  btnSize=""
                >
                  Payment Failed.
                </ReactBSAlert>
              ),
            });
          }
        },
        prefill: {
          name: userDetails.data.id,
          email: userDetails.data.email,
          contact: "",
        },
        notes: {
          address: "",
        },
        theme: {
          color: "#528ff0",
        },
        modal: {
          ondismiss: function () {
            setShowPopup({
              alert: (
                <ReactBSAlert
                  danger
                  style={{ display: "block", marginTop: "-100px" }}
                  title="Transaction Failed!"
                  onConfirm={() => hideAlert()}
                  onCancel={() => hideAlert()}
                  confirmBtnBsStyle="info"
                  btnSize=""
                >
                  Payment Failed.
                </ReactBSAlert>
              ),
            });
            setTimeout(() => {
              hideAlert();
            }, 3000);
          },
        },
      };

      console.log(options);
      var rzp1 = new window.Razorpay(options);
      rzp1.open();
      rzp1.on("payment.failed", async function (response) {
        // alert(response.error.code);
        // alert(response.error.description);
        // alert(response.error.source);
        // alert(response.error.step);
        // alert(response.error.reason);
        // alert(response.error.metadata.order_id);
        // alert(response.error.metadata.payment_id);

        setShowPopup({
          alert: (
            <ReactBSAlert
              danger
              style={{ display: "block", marginTop: "-100px" }}
              title="Transaction Failed!"
              onConfirm={() => hideAlert()}
              onCancel={() => hideAlert()}
              confirmBtnBsStyle="info"
              btnSize=""
            >
              Payment Failed.
            </ReactBSAlert>
          ),
        });
        var values = {
          razorpay_signature: "",
          razorpay_order_id: "",
          razorpay_payment_id: "",
          amount: "",
          transaction_id: orderData.transaction_id,
        };

        await planService.verifyPlanPayment(projectId, planId, values);

        setTimeout(() => {
          hideAlert();
        }, 3000);
      });
    }
  };

  let planDetails = [];

  // useEffect(() => {
  //     if(planType!="")
  //     {
  //         console.log("Inside useeffect");
  //     let design=[];
  //     let cards=[];
  //     if (planType === "monthly") {

  //             cards.push()
  //             cards.push(<PlanDetailsCard details={monthlyPlans["Premium"]} />)
  //             cards.push(<PlanDetailsCard details={monthlyPlans["EnterPrise"]} />)

  //         design.push(<Row>
  //         {cards}
  //         </Row>)
  //         setPlans(design);
  //     } else {
  //         if(planType==="yearly")
  //         {
  //         design.push(<Row>
  //             <PlanDetailsCard /><PlanDetailsCard />
  //             </Row>)
  //         setPlans(design);
  //         }
  //     }
  // }
  // }, [planType]);
  let planGroupElements = [];
  for (let i = 0; i < planGroups.length; i++) {
    planGroupElements.push(
      <th className="text-left" width="130px">
        {planGroups[i].name}
      </th>
    );
  }

  let featureElements = [];
  for (const featureGroup in features) {
    featureElements.push(
      <tr className="priceBx pdB2">
        <td className="bNm">{featureGroup}</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
    );
    for (let i = 0; i < features[featureGroup].length; i++) {
      // console.log("FeatureId:",features[featureGroup][i].id,monthlyPlanData.Enterprise.planFeatures.features[featureGroup][i].id);
      // console.log("data:",monthlyPlanData.Enterprise.planFeatures.features[featureGroup[i]].id);

      featureElements.push(
        <tr>
          <td>{features[featureGroup][i].name}</td>
          <td>
            {monthlyPlanData.Basic ? (
              <i
                class={
                  monthlyPlanData.Basic.planFeatures[
                    features[featureGroup][i].id
                  ]
                    ? "fa fa-check"
                    : "fa fa-times"
                }
                area-hidden="true"
              ></i>
            ) : (
              ""
            )}
          </td>
          <td>
            {monthlyPlanData.Premium ? (
              <i
                class={
                  monthlyPlanData.Premium.planFeatures[
                    features[featureGroup][i].id
                  ]
                    ? "fa fa-check"
                    : "fa fa-times"
                }
                area-hidden="true"
              ></i>
            ) : (
              <i class="fa fa-times" aria-hidden="true"></i>
            )}
          </td>
          <td>
            {monthlyPlanData.Business ? (
              <i
                class={
                  monthlyPlanData.Business.planFeatures[
                    features[featureGroup][i].id
                  ]
                    ? "fa fa-check"
                    : "fa fa-times"
                }
                area-hidden="true"
              ></i>
            ) : (
              <i class="fa fa-times" aria-hidden="true"></i>
            )}
          </td>
          <td>
            <i class="fa fa-check" aria-hidden="true"></i>
          </td>
        </tr>
      );
    }
  }

  return (
    <>
      <div className="content mt-30">
        {showPopup.alert}
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" md="12">
              <Card className="card-signup text-center dp-box">
                <div className="dfltDiv ">
                  <div className="pLanHead">
                    <CardTitle tag="h4">
                      Select Your Plan for Project {projectDetails.name}
                    </CardTitle>
                    <a href="mailto:helpdesk@sortd.mobi" target="_blank" className="cutmPlanBtn">
                      Contact us<span>For Enterprise Plan</span>
                    </a>
                  </div>
                </div>
                <div className="dfltDiv ">
                  <Button
                    className="btn-round"
                    color={
                      planType === "monthly"
                        ? "btn  btnBlue btn-wd"
                        : "btn btn-simple btn-wd"
                    }
                    type="button"
                    onClick={() => {
                      setPlanType("monthly");
                    }}
                  >
                    Monthly
                  </Button>
                  <Button
                    className="btn-round"
                    color={
                      planType === "yearly"
                        ? "btn btn-wd"
                        : "btn btn-simple btn-wd"
                    }
                    type="button"
                    onClick={() => {
                      setPlanType("yearly");
                    }}
                  >
                    Yearly
                  </Button>
                </div>
                <div className="plnCrd">
                  {showLoader ? (
                    <Spinner animation="border" role="status">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  ) : (
                    ""
                  )}
                  {planType === "monthly" ? (
                    monthlyPlanData ? (
                      <Row>
                        {monthlyPlanData.Basic ? (
                          <PlanDetailsCard
                            projectId={projectId}
                            processPayment={processPayment}
                            details={monthlyPlanData.Basic}
                            planType="Basic"
                            isCurrentPlan ={((currentPlan.plan_type==="monthly" || currentPlan.plan_type==="trial") && currentPlan.plan_name ==="Basic" )?true:false}
                            buttonLabel = "Purchase"
                            couponApplied  = {couponApplied}
                            setShowPopup = {setShowPopup}
                            hideAlert = {hideAlert}
                          />
                        ) : (
                          ""
                        )}
                        {monthlyPlanData.Premium ? (
                          <PlanDetailsCard
                            projectId={projectId}
                            processPayment={processPayment}
                            details={monthlyPlanData.Premium}
                            planType="Premium"
                            isCurrentPlan ={(currentPlan.plan_type==="monthly" &&  currentPlan.plan_name==="Premium")?true:false}
                            buttonLabel = {planIndex< 1?"Upgrade":"Purchase"}
                            couponApplied  = {couponApplied}
                            setShowPopup = {setShowPopup}
                            hideAlert = {hideAlert}
                          />
                        ) : (
                          ""
                        )}
                        {monthlyPlanData.Business ? (
                          <PlanDetailsCard
                            projectId={projectId}
                            processPayment={processPayment}
                            details={monthlyPlanData["Business"]}
                            planType="Business"
                            isCurrentPlan ={(currentPlan.plan_type==="monthly" && currentPlan.plan_name==="Business")?true:false}
                            buttonLabel = {planIndex< 2 ?"Upgrade":"Purchase"}
                            couponApplied  = {couponApplied}
                            setShowPopup = {setShowPopup}
                            hideAlert = {hideAlert}
                          />
                        ) : (
                          ""
                        )}

                        {monthlyPlanData.Enterprise ? (
                          <PlanDetailsCard
                            projectId={projectId}
                            processPayment={processPayment}
                            details={monthlyPlanData.Enterprise}
                            planType="Enterprise"
                            isCurrentPlan ={(currentPlan.plan_type==="monthly" && currentPlan.plan_name==="Enterprise")?true:false}
                            buttonLabel = {planIndex< 3 ?"Upgrade":"Purchase"}
                            couponApplied  = {couponApplied}
                            setShowPopup = {setShowPopup}
                            hideAlert = {hideAlert}
                          />
                        ) : (
                          ""
                        )}
                      </Row>
                    ) : (
                      ""
                    )
                  ) : yearlyPlanData ? (
                    <Row>
                      {yearlyPlanData.Basic ? (
                        <PlanDetailsCard
                          projectId={projectId}
                          processPayment={processPayment}
                          details={yearlyPlanData["Basic"]}
                          planType="Basic"
                          isCurrentPlan ={(currentPlan.plan_type==="yearly" && currentPlan.plan_name==="Basic")?true:false}
                          buttonLabel = "Purchase"
                          couponApplied  = {couponApplied}
                          setShowPopup = {setShowPopup}
                          hideAlert = {hideAlert}
                        />
                      ) : (
                        ""
                      )}
                      {yearlyPlanData.Premium ? (
                        <PlanDetailsCard
                          projectId={projectId}
                          processPayment={processPayment}
                          details={yearlyPlanData["Premium"]}
                          planType="Premium"
                          isCurrentPlan ={(currentPlan.plan_type==="yearly" &&currentPlan.plan_name==="Premium")?true:false}
                          buttonLabel = {planIndex< 1?"Upgrade":"Purchase"}
                          couponApplied  = {couponApplied}
                          setShowPopup = {setShowPopup}
                          hideAlert = {hideAlert}
                        />
                      ) : (
                        ""
                      )}
                      {yearlyPlanData.Business ? (
                        <PlanDetailsCard
                          projectId={projectId}
                          processPayment={processPayment}
                          details={yearlyPlanData["Business"]}
                          planType="Business"
                          isCurrentPlan ={(currentPlan.plan_type==="yearly" &&currentPlan.plan_name==="Business")?true:false}
                          buttonLabel = {planIndex< 2 ?"Upgrade":"Purchase"}
                          couponApplied  = {couponApplied}
                          setShowPopup = {setShowPopup}
                          hideAlert = {hideAlert}
                        />
                      ) : (
                        ""
                      )}

                      {yearlyPlanData.Enterprise ? (
                        <PlanDetailsCard
                          projectId={projectId}
                          processPayment={processPayment}
                          details={yearlyPlanData["Enterprise"]}
                          planType="Enterprise"
                          isCurrentPlan ={(currentPlan.plan_type==="yearly" &&currentPlan.plan_name==="Enterprise")?true:false}
                          buttonLabel = {planIndex< 3 ?"Upgrade":"Purchase"}
                          couponApplied  = {couponApplied}
                          setShowPopup = {setShowPopup}
                          hideAlert = {hideAlert}
                        />
                      ) : (
                        ""
                      )}
                    </Row>
                  ) : (
                    ""
                  )}
                </div>
                <div class="couponBox"><div></div>
                 <div><div>
                  <div class="couponTextWrapper">
                  <p class="couponText">Have a Coupon Code ?</p>
                  </div>
                  <div class="couponCodeInputWrapper">
                    <form class="form-container" onSubmit={handleSubmit}>
                      <input class="couponCodeInput" type="text"
                      onChange={handleChange}
                      name="coupon_code" id ="coupon_code" placeholder="Enter Coupon Code" 
                      value={formdetails.coupon_code}/>
                      

                        <button type="submit" class="couponCodeSubmitButton">
                        <span class="couponCodeButtonText">Apply</span>
                        </button>
                    </form>
                    
                    </div>
                    {couponCodeState ===
                            "has-danger" ? (
                                <label className="error" style={{color:"red"}}>
                                    {
                                        couponCodeError
                                    }
                                </label>
                            ) : null}
                    {couponApplied?
                    <div className="couponApplied">
                      <h2>Coupon Applied</h2>
                      {couponApplied.discount_type=='percentage' ?
                      <h3> Discount {couponApplied.discount_percentage}%
                      </h3>
                      :
                      <h3> Flat Discount <i className="fa fa-inr" style={{ fontSize: "13px" }} /> {couponApplied.discount_flat}
                      </h3>
                      }
                      {/* <h3>
                        Discount <i className="fa fa-inr" style={{ fontSize: "13px" }} />{couponApplied.}
                      </h3> */}
                    </div>
                    :""}
                    </div></div><div></div></div>
                <CardFooter>
                  * &nbsp;&nbsp;&nbsp;&nbsp; All pricing are inclusive of
                  GST(Goods & Services Tax)
                  <br />
                  ** &nbsp;&nbsp; Features having monthly limits.
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/* <div className="content mt0">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" md="12">
              <Card className="card-signup text-center dp-box-plan">
                <CardHeader>
                  <CardTitle tag="h4" className="heding_tab_h4">
                    Select Your Plan
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <Table>
                    <thead className="text-primary">
                      <th className="text-left"></th> */}
                      {/* {planGroupElements} */}
                      {/* <th className="text-left" width="130px">
                        BASIC
                      </th>
                      <th className="text-left" width="130px">
                        PREMIUM
                      </th>
                      <th className="text-left" width="130px">
                        BUSINESS
                      </th>
                      <th className="text-left" width="130px">
                        ENTERPRISE
                      </th>
                    </thead>
                    <tbody>
                      <tr className="prTg">
                        <td></td>
                        <td>
                          {planType === "monthly" ? (
                            monthlyPlanData && monthlyPlanData.Basic ? (
                              <>
                                <i
                                  className="fa fa-inr"
                                  style={{ fontSize: "14px" }}
                                />
                                &nbsp;&nbsp;
                                {monthlyPlanData.Basic.planPrice.base_price}
                              </>
                            ) : (
                              "-"
                            )
                          ) : yearlyPlanData && yearlyPlanData.Basic ? (
                            <>
                              <i
                                className="fa fa-inr"
                                style={{ fontSize: "14px" }}
                              />
                              &nbsp;&nbsp;
                              {yearlyPlanData.Basic.planPrice.base_price}
                            </>
                          ) : (
                            "-"
                          )}
                        </td>
                        <td>
                          {planType === "monthly" ? (
                            monthlyPlanData && monthlyPlanData.Premium ? (
                              <>
                                <i
                                  className="fa fa-inr"
                                  style={{ fontSize: "14px" }}
                                />
                                &nbsp;&nbsp;
                                {monthlyPlanData.Premium.planPrice.base_price}
                              </>
                            ) : (
                              "-"
                            )
                          ) : yearlyPlanData && yearlyPlanData.Premium ? (
                            <>
                              <i
                                className="fa fa-inr"
                                style={{ fontSize: "14px" }}
                              />
                              &nbsp;&nbsp;
                              {yearlyPlanData.Premium.planPrice.base_price}
                            </>
                          ) : (
                            "-"
                          )}
                        </td>
                        <td>
                          {planType === "monthly" ? (
                            monthlyPlanData && monthlyPlanData.Business ? (
                              <>
                                <i
                                  className="fa fa-inr"
                                  style={{ fontSize: "14px" }}
                                />
                                &nbsp;&nbsp;
                                {monthlyPlanData.Business.planPrice.base_price}
                              </>
                            ) : (
                              "-"
                            )
                          ) : yearlyPlanData && yearlyPlanData.Business ? (
                            <>
                              <i
                                className="fa fa-inr"
                                style={{ fontSize: "14px" }}
                              />
                              &nbsp;&nbsp;
                              {yearlyPlanData.Business.planPrice.base_price}
                            </>
                          ) : (
                            "-"
                          )}
                        </td>
                        <td>Custom Plan</td>
                      </tr> */}
                      {/* <tr className="priceBx">
                                        <td className="bNm">Basic Calculation</td>
                                        <td><button className="btn btn-smb">Start Trial</button></td>
                                        <td><button className="btn btn-smb">Start Trial</button></td>
                                        <td><button className="btn btn-smb">Start Trial</button></td>
                                        <td><button className="btn btn-smb">Contact Us</button></td>
                                    </tr> */}

                      {/* {featureElements} */}

                      {/* <tr>
                                        <td>Basic Astrological Details</td>
                                        <td><i class="fa fa-check" aria-hidden="true"></i></td>
                                        <td><i class="fa fa-times" aria-hidden="true"></i></td>
                                        <td><i class="fa fa-times" aria-hidden="true"></i></td>
                                        <td><i class="fa fa-times" aria-hidden="true"></i></td>
                                    </tr> */}
                      {/* <tr>
                                        <td>Basic Astrological Details</td>
                                        <td><i class="fa fa-check" aria-hidden="true"></i></td>
                                        <td><i class="fa fa-times" aria-hidden="true"></i></td>
                                        <td><i class="fa fa-check" aria-hidden="true"></i></td>
                                        <td><i class="fa fa-check" aria-hidden="true"></i></td>
                                    </tr> */}
                      {/* <tr>
                                        <td>Basic Astrological Details</td>
                                        <td><i class="fa fa-check" aria-hidden="true"></i></td>
                                        <td><i class="fa fa-check" aria-hidden="true"></i></td>
                                        <td><i class="fa fa-check" aria-hidden="true"></i></td>
                                        <td><i class="fa fa-check" aria-hidden="true"></i></td>
                                    </tr> */}
                      {/* <tr>
                                        <td>Basic Astrological Details</td>
                                        <td><i class="fa fa-check" aria-hidden="true"></i></td>
                                        <td><i class="fa fa-check" aria-hidden="true"></i></td>
                                        <td><i class="fa fa-check" aria-hidden="true"></i></td>
                                        <td><i class="fa fa-times" aria-hidden="true"></i></td>
                                    </tr> */}

                      {/* <tr className="priceBx pdB2">
                                        <td className="bNm">Advance Calculation</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr> */}
                      {/* 
                                    <tr>
                                        <td>Basic Astrological Details</td>
                                        <td><i class="fa fa-check" aria-hidden="true"></i></td>
                                        <td><i class="fa fa-check" aria-hidden="true"></i></td>
                                        <td><i class="fa fa-check" aria-hidden="true"></i></td>
                                        <td><i class="fa fa-check" aria-hidden="true"></i></td>
                                    </tr>
                                    <tr>
                                        <td>Basic Astrological Details</td>
                                        <td><i class="fa fa-check" aria-hidden="true"></i></td>
                                        <td><i class="fa fa-check" aria-hidden="true"></i></td>
                                        <td><i class="fa fa-check" aria-hidden="true"></i></td>
                                        <td><i class="fa fa-times" aria-hidden="true"></i></td>
                                    </tr>    */}
                    {/* </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div> */}
    </>
  );
};
export default Plan;

import axios from "axios";

const generateOtp = async(formdata) =>{
    const response = await axios.post('/api/auth/generate-otp',formdata);
    return response.data;
}

const resendOtp = async(formdata) =>{
    const response = await axios.post('/api/auth/resend-otp',formdata);
    return response.data;
}

const validateOtp = async(formdata) =>{
    const response = await axios.post('/api/auth/validate-otp',formdata);
    return response.data;
}


const updatePassword = async(formdata) =>{
    const response = await axios.post('/api/auth/update-password',formdata);
    return response.data;
}

const formLogin = async(formdata) =>{
    const response = await axios.post('/api/auth/form-login',formdata);
    return response.data;
}

const validateEmailAndSendOtp = async(formdata) => {
    const response = await axios.post('/api/auth/validate-email-send-otp',formdata);
    return response.data;
}

const getLoggedInUser = async () =>{
    const response = await axios.post('/api/auth/get-loggedin-user');
    return response.data;
}


export  default {
    generateOtp,
    validateOtp,
    resendOtp,
    updatePassword,
    formLogin,
    validateEmailAndSendOtp,
    getLoggedInUser
}
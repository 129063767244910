import React,{useState,useEffect,useContext} from 'react';
import { useHistory  } from 'react-router-dom';
import ConfigFormComponent from './ConfigFormComponent';
import {
    Label
} from 'reactstrap';
const FeatureConfigForm = (props) => {
    const config = {
        "level_id": 2,
        "label": "Paid Article",
        "dependency": {
            "feature_code": "paid_article",
            "default": false
        },
        "items": {
            "razorpay_key": {
                "level_id": 3,
                "label": "Razorpay Key",
                "type": "string",
                "default": "",
                "required": false
            },
            "razorpay_secret": {
                "level_id": 3,
                "label": "Razorpay Secret",
                "type": "string",
                "default": "",
                "required": false
            }
        }
    }
    return (<>
    
    {
        props.configFormSchema && props.configFormSchema.items?
            <div>
                {Object.keys(props.configFormSchema.items).map(elementName=>
                    <ConfigFormComponent 
                    formdetails ={props.formdetails}
                    setFormDetails = {props.setFormDetails}
                    elementName = {elementName}
                    featureCode = {props.featureCode}
                    fieldStates = {props.fieldStates}
                    data={props.configFormSchema.items[elementName]}/>)
                }
                {props.featureCode==="paid_article" || props.featureCode==="support_widget" ?
                <Label className="smLabl-txt">By saving the credentials, you are agreeing to our <a  target="_blank" href="https://www.sortd.mobi/terms-of-service?utm_source=console_header">terms</a> and <a target="_blank" href="https://www.sortd.mobi/privacy-policy?utm_source=console_header" >conditions</a>.</Label>
                :null}
            </div>
            : null        
    }

    </>)
}

export default FeatureConfigForm;
import React, { useEffect, useState, useContext } from "react";
import contentService from "../../services/api/content-service";
import SortableTree from 'react-sortable-tree';
import 'react-sortable-tree/style.css';
import {useHistory} from "react-router-dom";
import ProjectContext from '../../ProjectContext';
import './content.css';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Alert,
  Spinner,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

const ReorderCategories = (props)=> {

    
    const projectDetails = useContext(ProjectContext);
    const history = new useHistory();
    console.log(projectDetails.id);
    if(projectDetails.id==''){
      history.push("/project/allprojects");
    }
    const projectId = projectDetails.id;

    const [categories, setCategories] = useState(props.categories);
    const [showLoader, setShowLoader]   = useState(false);
    const [showSaveButton, setShowSaveButton]   = useState(true);
    const [alert, setAlert]   = useState({show:false,message:'',type:''});

    let categoryTreeData = [];
    if(categories && categories.length){
      categories.forEach(function(categoryDetails) {
          console.log(categoryDetails);
        let categoryChildren = [];
          if(categoryDetails.sub_categories && categoryDetails.sub_categories.length){
              let subCategories = categoryDetails.sub_categories;
              subCategories.forEach(function(subCategoryDetails) {
                  let title = <>{subCategoryDetails.content_type==5 ? <i class="fa fa-link fa-rotate-90" rel="tooltip" style={{"cursor":"pointer"}} title={subCategoryDetails.webpage_url} aria-hidden="true"></i>:""}&nbsp;&nbsp;&nbsp;{subCategoryDetails.name} </>
                  let child = {title: title, name: subCategoryDetails.name,categoryId:subCategoryDetails._id};
                  categoryChildren.push(child);
              })
          }
          let title = <>{categoryDetails.content_type==5 ? <i class="fa fa-link fa-rotate-90" rel="tooltip" style={{"cursor":"pointer"}} title={categoryDetails.webpage_url} aria-hidden="true"></i>:""}&nbsp;&nbsp;&nbsp;{categoryDetails.name} </>
          let treeCategory = {title:title, name: categoryDetails.name, categoryId:categoryDetails._id,children:categoryChildren};
          categoryTreeData.push(treeCategory);
      })
    }

  
  const [treeData, setTreeData] = useState(categoryTreeData);
  console.log(treeData);
  useEffect( () => {

    history.push({
      search: '?project='+projectDetails.slug
    })
    
    }, []);


  const updateTreeData = (treeData) => {
        console.log(treeData);
        setTreeData(treeData);
  }

  const saveCategoryOrder = async (event) => {
    setShowLoader(true)
    setShowSaveButton(false)
    let categoryTreeData = [];
    if(treeData && treeData.length){
      treeData.forEach(function(categoryDetails) {
          console.log(categoryDetails);
        let categoryChildren = [];
          if(categoryDetails.children && categoryDetails.children.length){
              let subCategories = categoryDetails.children;
              subCategories.forEach(function(subCategoryDetails) {
                  let child = {title: subCategoryDetails.name, categoryId:subCategoryDetails.categoryId};
                  categoryChildren.push(child);
              })
          }
          let treeCategory = {title: categoryDetails.name,categoryId:categoryDetails.categoryId ,children:categoryChildren};
          categoryTreeData.push(treeCategory);
      })
    }
      console.log("categoryTreeData",categoryTreeData);
      let formdata = {'categoryOrders':categoryTreeData,'projectId': projectId};
      let saveCategoryOrders = await contentService.reorderCategories(formdata);
      if(projectDetails.isContractExpired){
        setShowLoader(false)
        setShowSaveButton(true)
        projectDetails.setContractExpiredAlert(projectDetails);
      }
      else if(saveCategoryOrders.status){
        setTimeout(()=>{
          setShowLoader(false)
          setShowSaveButton(true)
          setAlert({show:true,type:'success',message:'Saved Successfully'});
        },1000);

        setTimeout(()=>{
          setAlert({show:false,type:'',message:''});
        },3000);
      }else{
        setTimeout(()=>{
            setShowLoader(false)
            setShowSaveButton(true)
            setAlert({show:true,type:'success',message:'Could Not Save. Please try again.'});
          },1000);
  
          setTimeout(()=>{
            setAlert({show:false,type:'',message:''});
          },3000);
      }
  }

    return (
      <div className="content mt-30">
        <Container>
          <Row>
          <Col className="ml-auto mr-auto"  md="12">
          {alert.show?<Alert color={alert.type}>
                    {alert.message}
                  </Alert>:''}
              <Card className="card-signup text-center dp-box">
                <CardHeader>
                  <CardTitle tag="h4" className="heding_tab_h4">Reorder Categories</CardTitle>

                  {showSaveButton?<Button
                    className="btn-smb "
                    style={{float: 'right'}}
                    type="button"
                    onClick={(event)=>  saveCategoryOrder(event) }
                >
                    Save
                </Button> :''} 

                <Button
                    className="btn-smb btnBlk"
                    style={{float: 'right'}}
                    type="button"
                    onClick={(event)=>  props.setReorderCategories(false) }
                >
                    Back
                </Button>
                
                </CardHeader>
                <CardBody>

                {showLoader?<Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>:''}
                <Col md="12">
                  <div >
                  <SortableTree
                      isVirtualized={false}
                      maxDepth="2"
                      treeData={treeData}
                      onChange={treeData => updateTreeData(treeData) }
                      />
                  </div>
                
                </Col>
               
                
                </CardBody>
                <CardFooter>
                  
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
          }}
        />
      </div>
    );
  
}

export default ReorderCategories;

import React, { useEffect, useState, useContext } from "react";
import contentService from "../../services/api/content-service";
import {useHistory} from "react-router-dom";
import ProjectContext from '../../ProjectContext';
import Pagination from "react-js-pagination";
import Moment from 'react-moment';
import ReactBSAlert from "react-bootstrap-sweetalert";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash,faTrashRestoreAlt,faForward, faExclamationCircle, faCheckCircle} from '@fortawesome/free-solid-svg-icons'
import projectsService from "../../services/api/projects-service";
import { Link } from "react-router-dom";
import './content.css';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Alert,
  Spinner,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

import Select from "react-select";

const Articles = (props)=> {
  const projectDetails = useContext(ProjectContext);
  const history = new useHistory();
  
  if(projectDetails.id==''){
    history.push("/project/allprojects");
  }
  const projectId = projectDetails.id;
  const [formdetails, setFormDetails] = useState({categoryids:[],title:'',order_by:''});
  const [title, setTitle] = useState("");
  const [selectedCategory,setSelectedCategory] = useState([]);
  const [articles, setArticles] = useState(false);
  const [totalArticles,setTotalArticles] = useState(0);
  const [categories, setCategories] = useState(false);
  const [activePage,setActivePage] = useState(1);
  const [renderComponent,setRenderComponent] = useState(false);
  const [search,setSearchDetails] = useState({categoryids:[],title:'',order_by:''});
  const [showPopup,setShowPopup] = useState({alert:null});
  const [reloadComponent,setReloadComponent] = useState(0);
  const [isNlpEnabled,setNlpEnabled] = useState(false);
  const records = 10;
  const orderByOptions = [
    {
      value : "published_on",
      label : "Published On"
    },
    {
      value : "modified_on",
      label : "Modified On"
    },
];

  let getProjectSlug = new URLSearchParams(props.location.search).get('project');
  if(!getProjectSlug){
    history.push({
      search: '?project='+projectDetails.slug
    })
  }
  
  useEffect( () => {
    (async () =>{
      
        let categoryData = await contentService.getCategories(projectId);
        if(categoryData.status){
            let allCategories = categoryData.data;
            let categoryArray = [];
            if(allCategories.length){
              allCategories.forEach(function(categoryDetails) {
                categoryArray.push({value:categoryDetails._id,label:categoryDetails.name});
                if(categoryDetails.sub_categories && categoryDetails.sub_categories.length){
                  let subCategories = categoryDetails.sub_categories;
                  subCategories.forEach(function(subCategoryDetails) {
                    categoryArray.push({value:subCategoryDetails._id,label:subCategoryDetails.name});
                  })
                }
              })
              setCategories(categoryArray);
            }
        }

        let getProjectDetails = await projectsService.getProjectDetails(projectId);
        if(getProjectDetails.status && getProjectDetails.data.settings.nlp_enabled){
          setNlpEnabled(true)
        }

      })();
    }, []);

  // useEffect( () => {
  //   (async () =>{
  //     let getTotalArticles = await contentService.getTotalArticles(projectId,formdetails);
  //     if(getTotalArticles.status){
  //         setTotalArticles(getTotalArticles.data.count);
  //     }
  //       })();
  //   }, [search,reloadComponent]);  

  useEffect( () => {
    (async () =>{
        let articlesData = await contentService.getArticles(projectId,formdetails,activePage,records);
            if(articlesData.status){
                setArticles(articlesData.data.articles);
                setTotalArticles(articlesData.data.count);
            }
            setRenderComponent(true);
        })();
    }, [activePage,search,reloadComponent]);

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
    }

    const onImageError = (event) => {
        // event.target.src = "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/React-icon.svg/1200px-React-icon.svg.png";
        event.target.src = "https://mcmscache.epapr.in/mcms/461/9e326070049c91c0a19855f334fe114b53f733e0.svg";

    }

    const deleteArticlePopup = (articleId,projectId) => {
      setShowPopup({
        alert: (
          <ReactBSAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title="Are you sure?"
            onConfirm={() => deleteArticle(articleId,projectId)}
            onCancel={() => hideAlert()}
            confirmBtnBsStyle="info"
            cancelBtnBsStyle="danger"
            confirmBtnText="Yes, delete it!"
            cancelBtnText="Cancel"
            showCancel
            btnSize=""
          >
            You want to delete this article!
          </ReactBSAlert>
        ),
      });
    }

    const restoreArticlePopup = (articleId,projectId) => {
      setShowPopup({
        alert: (
          <ReactBSAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title="Are you sure?"
            onConfirm={() => restoreArticle(articleId,projectId)}
            onCancel={() => hideAlert()}
            confirmBtnBsStyle="info"
            cancelBtnBsStyle="danger"
            confirmBtnText="Yes, restore it!"
            cancelBtnText="Cancel"
            showCancel
            btnSize=""
          >
            You want to restore this article!
          </ReactBSAlert>
        ),
      });
    }

    const deleteArticle = async (articleId,projectId) => {
      let delArticle = await contentService.deleteArticle(articleId,projectId);
      if(projectDetails.isContractExpired){
        setShowPopup({alert:null});
        projectDetails.setContractExpiredAlert(projectDetails);
      }
      else if(delArticle.status && delArticle.data.deleted){
        setShowPopup({
          alert: (
            <ReactBSAlert
              success
              style={{ display: "block", marginTop: "-100px" }}
              title="Deleted!"
              onConfirm={() => hideAlert()}
              onCancel={() => hideAlert()}
              confirmBtnBsStyle="info"
              btnSize=""
            >
              Article was deleted successfully.
            </ReactBSAlert>
          ),
        });
          setReloadComponent(Math.random());
      }else{
        setShowPopup({
          alert: (
            <ReactBSAlert
              danger
              style={{ display: "block", marginTop: "-100px" }}
              title="Please try again!"
              onConfirm={() => hideAlert()}
              onCancel={() => hideAlert()}
              confirmBtnBsStyle="info"
              btnSize=""
            >
              Article could not be deleted. Please try again.
            </ReactBSAlert>
          ),
        });
      }
    }

    const restoreArticle = async (articleId,projectId) => {
      let resArticle = await contentService.restoreArticle(articleId,projectId);
      if(projectDetails.isContractExpired){
        setShowPopup({alert:null});
        projectDetails.setContractExpiredAlert(projectDetails);
      }
      else if(resArticle.status && resArticle.data.restored){
        setShowPopup({
          alert: (
            <ReactBSAlert
              success
              style={{ display: "block", marginTop: "-100px" }}
              title="Restored!"
              onConfirm={() => hideAlert()}
              onCancel={() => hideAlert()}
              confirmBtnBsStyle="info"
              btnSize=""
            >
              Article was restored successfully.
            </ReactBSAlert>
          ),
        });
        setReloadComponent(Math.random());
      }else{
        setShowPopup({
          alert: (
            <ReactBSAlert
              danger
              style={{ display: "block", marginTop: "-100px" }}
              title="Please try again!"
              onConfirm={() => hideAlert()}
              onCancel={() => hideAlert()}
              confirmBtnBsStyle="info"
              btnSize=""
            >
              Article could not be restored. Please try again.
            </ReactBSAlert>
          ),
        });
      }
    }

    const hideAlert = () => {
      setShowPopup({
        alert: null,
      });
    };



  let articlesElements = [];
  let firstRecord = 0;
  let lastRecord  = 0;
  if(!articles || !articles.length){
    if(!renderComponent){
      articlesElements.push(
        <td colSpan="4" className="text-center">
            <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
        </td>
      );
    }else{
    articlesElements.push(
        <td colSpan="12" className="text-center">
            <CardTitle tag="h4" className="noRslt">No Article Found</CardTitle>
        </td>
    );
    }
  }else{
      let i=1;
      articles.forEach(function(articleDetails) {
          let className = '';
          if(!articleDetails.status){
            className = 'table-gray';
          }
          if(i==1){
            firstRecord = (activePage-1)*records+i;
          }
        articlesElements.push(
            <tr id={"row_"+articleDetails.id} className={className}>
              <td className="text-left">{(activePage-1)*10+i}</td>
               <td className="text-left imgTmb">
                {articleDetails.thumburl?
                <img src={articleDetails.thumburl} width="50" height="50" onError={onImageError}></img>
                :''}
               </td>
               <td className="text-left">
                    {
                      articleDetails.status ? 
                        <a href={`/contentsettings/article-details/${articleDetails.id}`}>{articleDetails.title}</a>
                      : articleDetails.title
                    }
               </td>
               <td className="text-left">
                   <Moment format="dddd DD MMM YYYY hh:mm:ss a">{articleDetails.article_published_on}</Moment>
               </td>
               <td className="text-left">
                   <Moment format="dddd DD MMM YYYY hh:mm:ss a">{articleDetails.article_modified_on}</Moment>
               </td>
                  {isNlpEnabled?
               <td className="text-left">
                   {articleDetails.entities?articleDetails.entities.join():''}
               </td>
               :''}

               <td className="text-left">
                   {articleDetails.status?'active':'deleted'}
               </td>
               <td>
                  <span title={articleDetails.failed_media ? 'Failed' : 'Success'}>
                      {articleDetails.failed_media ? 
                          <FontAwesomeIcon icon={faExclamationCircle} /> : 
                          <FontAwesomeIcon icon={faCheckCircle} />
                      }
                  </span>
                    {/* {articleDetails.failed_media? <FontAwesomeIcon icon={faExclamationCircle} />: <FontAwesomeIcon icon={faCheckCircle} />} */}

               </td>

               <td className="text-center">
                   {articleDetails.status?
                <span className="notActn">
                  <a title="Delete" onClick={(event)=>  deleteArticlePopup(articleDetails.id,articleDetails.project_id) } href="javascript:void(0)" class="dlIcn">
                      {/* <i class="nc-icon nc-simple-remove"></i> */}
                      {/* <FontAwesomeIcon icon={faTrash} /> */}
                      <i class="fa fa-trash-o" aria-hidden="true"></i>

                  </a>

                  <Link className="pshNot" title="Push Notification"
                    to={{
                      pathname: "/notifications/dashboard",
                      state: {
                        guid: articleDetails.guid,message:articleDetails.title,slug:articleDetails.slug
                      },
                    }}
                    //to="/notifications/dashboard"
                    //params={{ guid: articleDetails.guid,message:articleDetails.title,slug:articleDetails.slug }}
                    >
                    {/* <FontAwesomeIcon icon={faForward} /> */}
                    <i class="fa fa-bell-o" aria-hidden="true"></i>
                  </Link>
                </span>
                :
                <a title="Restore" onClick={(event)=>  restoreArticlePopup(articleDetails.id,articleDetails.project_id) } href="javascript:void(0)" class="nav-restr">
                    {/* <i class="nc-icon nc-refresh-69"></i> */}
                    <FontAwesomeIcon icon={faTrashRestoreAlt} />
                  </a> 
                  }
               </td>
            </tr>
        )
        if(i==articles.length){
          lastRecord = (activePage-1)*records+i;
        }
        i++;
        if(i==articles.length){
          lastRecord = (activePage-1)*records+i;
        }
    })
  }

  const handleCategoryChange = (value) => {
    let categoryIds = [];
    if(value && value.length > 0){
      value.forEach(function(categoryIdDetails){
        categoryIds.push(categoryIdDetails.value);
      })
    }
    setFormDetails({
      ...formdetails,
      categoryids: categoryIds,
    });
    setSelectedCategory(value);
  }

  const handleTitleChange = (event) => {
    setFormDetails({
      ...formdetails,
      title: event.target.value,
    });
    setTitle(event.target.value);
  }

  const handleSubmit = function(e){  searchArticles();e.preventDefault() }

  const searchArticles = () => {
      //if(formdetails.categoryids.length > 0 || formdetails.title.trim() != ''){
          setSearchDetails(formdetails);
      //}
  }
    const resetForm = () => {
    setTitle("");
    setSelectedCategory([]);
    setActivePage(1)
    setFormDetails({
      ...formdetails,
      title : "",
      categoryids : [],
      order_by : ''
    });
    setSearchDetails({
      title : "",
      categoryids : [],
      order_by : ''
    });
  }

  const handleOrderByChange = (event)=>{
    setFormDetails({
      ...formdetails,
      order_by: event.value,
    });
  }

    return (
      <div className="content mt-30">
        {showPopup.alert}
        <Container>
          <Row>
          <Col className="ml-auto mr-auto"  md="12">
              <Card className="card-signup text-centercdp-box dp-box">
                <CardHeader>
                  <Row>
                    <CardTitle tag="h4" className="heding_tab_h4 text-center">
                        Articles   
                    </CardTitle>
                  </Row>
                <Form id="articlefilters" action="" className="form" method="POST" onSubmit={handleSubmit}>
                  <div className="srchBox">
                  <Row>
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        placeholder="Select Categories"
                        name = "categoryids"
                        id="categoryids"
                        isMulti = "true"
                        value = {selectedCategory}
                        // value={currentMultiEnumValue()}
                        options={categories}
                        onChange={(value)=>  handleCategoryChange(value) }
                      />
                      <Input type="text" name="searchtitle" id="searchtitle" value={title} onChange={handleTitleChange} placeholder="Search in title"/>
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        placeholder="Order by"
                        name="order_by"
                        id="order_by"
                        options={orderByOptions}
                        value = {orderByOptions?orderByOptions.filter(option=> option.value == formdetails.order_by):""}
                        onChange={handleOrderByChange}
                      />
                      <div>
                      <Button className="btn-smb" type="submit">
                        Search
                      </Button>
                      <Button
                      className="btn-smb btn-rst"
                      type="button"
                      onClick={resetForm}
                    >
                      Reset &nbsp;&nbsp;&nbsp;
                    </Button>
                    </div>
                  </Row>
                  </div>

                {totalArticles > 0 ? 
                  <div className="pagNo">
                      {firstRecord} - {lastRecord} of {totalArticles}
                  </div>
                : null} 
                </Form>
              
                </CardHeader>
                <CardBody>
                  <Table responsive>
                    <thead className="text-primary">
                    {renderComponent?<>
                    <th className="text-left">#</th> 
                      <th className="text-left" width="50px" >Image</th>   
                        <th className="text-left" width="300px">Title</th>
                        <th className="text-left" width="230px">Published On</th>
                        <th className="text-left" width="230px">Modified On</th>
                        {isNlpEnabled?
                          <th className="text-left" width="110px">Entities</th>
                          :''
                        }
                        <th className="text-left">Status</th>
                        <th className="text-left">Media Status</th>
                        <th className="text-left">Action</th>
                        </>:''}
                    </thead>
                    <tbody>{articlesElements}</tbody>
                  
                  </Table>

                  {totalArticles > 0 ?
                <Row>
                  <div className="pagBox">
                    {/* <div className="pagNo">
                      {firstRecord} - {lastRecord} of {totalArticles}
                    </div> */}
                    <div className="pagDgt">
                      <Pagination
                      activePage={activePage}
                      itemsCountPerPage={records}
                      totalItemsCount={totalArticles}
                      pageRangeDisplayed={5}
                      onChange={handlePageChange.bind(this)}
                      itemClass="page-item"
                      linkClass="page-link"
                      />
                    </div>
                  </div>
                </Row>
                :''}
                </CardBody>
                
              
                
                {/* <CardFooter></CardFooter> */}

              </Card>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
          }}
        />
      </div>
    );
  
  
}

export default Articles;

import axios from 'axios';

const enableAndroidApp = async (formdata) => {
    const response = await axios.post('/api/sortd-apps/enable-android-app',formdata);
    return response.data;
}

const getAndroidAppInfo = async (projectId) => {
    let formdata = {
        projectId
    };
    const response = await axios.post('/api/sortd-apps/get-android-app-info',formdata);
    return response.data;
}
const saveAndroidAppInfo = async (formdata) => {
    const response = await axios.post('/api/sortd-apps/save-android-app-info',formdata);
    return response.data;
}

const createAppKeystore = async (formdata) =>{
    const response = await axios.post('/api/sortd-apps/create-android-app-keystore',formdata);
    return response.data;
}
const getAppKeystore = async (projectId) =>{
    let formdata = {
        projectId
    }
    const response = await axios.post('/api/sortd-apps/get-app-keystore',formdata);
    return response.data;
} 
const getAndroidDistributions = async(formdata) =>{
    const response = await axios.get('/api/sortd-apps/get-android-distributions',formdata);
    return response.data;
}

export default   {
    enableAndroidApp,
    getAndroidAppInfo,
    saveAndroidAppInfo,
    createAppKeystore,
    getAppKeystore,
    getAndroidDistributions
}
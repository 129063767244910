import React, { useEffect, useState, useContext } from "react";
import ProjectContext from '../../../ProjectContext';
import { useHistory } from "react-router-dom";

import {
    Card,
    CardTitle,
    CardHeader,
    CardBody,
    Row,
    Col,
    Button,
    Label
} from "reactstrap";

import '../ctv.css'
import Navbar from "../NavBar/Navbar";
import ManageCategories from "../ManageCategories";
import ContentLibrary from "../ContentLibrary";
import ImportVideosDashboard from "../ImportVideosDashboard";
import CTVAppManager from "../../ctv/CTVmanager";
import ctvAppService from '../../../services/api/ctv-app-service';

const Content = (props) => {
    const [isAppSettingsSaved, setIsAppSettingsSaved] = useState(false);
    const [category, setCategory] = useState([])
    const history = new useHistory();
    const hashValue = new URLSearchParams(props.location).get('hash').substring(1);
    let currentTab = "category";
    if (hashValue) {
        currentTab = hashValue;
    }
    const projectDetails = useContext(ProjectContext);
    const projectId = projectDetails.id;

    const [activeTab, setActiveTab] = useState(currentTab);

    useEffect(() => {
        (async () => {
            const settings = await ctvAppService.getCTVAppGeneralSettings({ project_id: projectId });
            if (settings.status && settings.data) {
                const appSettings = settings.data
                if (appSettings.ctv_ios_app_enabled || appSettings.ctv_android_app_enabled) {
                    setIsAppSettingsSaved(true);
                }
            }

        })();
    }, [])

    // useEffect(() => {
    //     (async () => {
    //         setCategory([])
    //     })()
    // }, [])

    const updateActiveTab = (event) => {
        if (event !== "videos") { setCategory([]) }
        setActiveTab(event);
    }

    const redirectUrl = (e, navigate, redirectPath, project) => {
        e.preventDefault()
        history.push("/" + navigate + "/" + redirectPath + "?project=" + project)
    }

    if (!isAppSettingsSaved) {
        return (
            <div className="container mt-30">
                <Card>
                    <CardTitle>
                        <h4 align="center">SORTD Connected TV</h4>
                    </CardTitle>
                    <CardBody>
                        <h4 align="center">Not Enabled For your project</h4>
                        <Row align="center">
                            <Col>
                                <Button
                                    type="button"
                                    className="btnBlue btn-primary"
                                    onClick={(e) => history.push(`/ctv/settings?project=${projectDetails.slug}`)}
                                >
                                    Enable Now
                                </Button>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>

            </div>
        )
    } else {
        return (
            // <div className="content mt-10">
            <div className="container mt-30">
                <Card>
                    <CardHeader>
                        <Row>
                            <Navbar activeTab={activeTab} updateActiveTab={updateActiveTab} />
                        </Row>
                    </CardHeader>
                    {
                        activeTab === "category" ?

                            <ManageCategories setCategory={setCategory} updateActiveTab={updateActiveTab} /> :

                            null
                    }
                    {
                        activeTab === "importer" ?
                            <ImportVideosDashboard /> :
                            null
                    }
                    {
                        activeTab === "videos" ?
                            <ContentLibrary category={category} /> :
                            null
                    }
                    {
                        activeTab === "design" ?
                            <CTVAppManager {...props} /> :
                            null
                    }
                </Card>

            </div>

            // </div>
        )

    }
}

export default Content;
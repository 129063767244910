import React, { useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";

const EmbedPopup = ({ showEmbed, toggleEmbed, data }) => {
    const embedData = {
        shorts: `<div style="position:relative;overflow:hidden;padding-bottom:177.78%"><iframe src="https://cdn.jwplayer.com/players/${data.id}-B4SnBcbA.html" width="100%" height="100%" frameborder="0" scrolling="auto" title=${data.name}style="position:absolute;" allowfullscreen></iframe></div>`,
        "16x9": `<div style="position:relative;overflow:hidden;padding-bottom:56.25%"><iframe src="https://cdn.jwplayer.com/players/${data.id}-TKDrgh1h.html" width="100%" height="100%" frameborder="0" scrolling="auto" title=${data.name} style="position:absolute;" allowfullscreen></iframe></div>`,
        "640x360": `<iframe src="https://cdn.jwplayer.com/players/${data.id}-N1kHxxk9.html" width="640" height="360" frameborder="0" scrolling="auto" title=${data.name} allowfullscreen></iframe>`,
    };
    const [copiedEmbed, setCopiedEmbed] = useState(null);
    const copyEmbed = (value) => {
        setCopiedEmbed(value);
        setTimeout(() => {
            setCopiedEmbed(null);
        }, 2000);
    };
    return (
        <div>
            <Modal isOpen={showEmbed} toggle={toggleEmbed}>
                <ModalHeader>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <p>Select player</p>
                        <p style={{ cursor: "pointer" }} onClick={toggleEmbed}>
                            x
                        </p>
                    </div>
                </ModalHeader>
                <ModalBody>
                    <h6 style={{ display: "inline", marginRight: "3rem" }}>
                        Shorts
                    </h6>
                    <CopyToClipboard
                        text={embedData["shorts"]}
                        onCopy={() => copyEmbed(1)}
                    >
                        {copiedEmbed === 1 ? (
                            <Button
                                className="btn btn-success btn-sm"
                                type="button"
                            >
                                copied
                            </Button>
                        ) : (
                            <Button className="copyTxt btn-sm" type="button">
                                copy
                            </Button>
                        )}
                    </CopyToClipboard>
                    <p className="mt-1 check ">{embedData["shorts"]}</p>
                </ModalBody>
                <ModalBody>
                    <h6 style={{ display: "inline", marginRight: "3rem" }}>
                        Shorts
                    </h6>
                    <CopyToClipboard
                        text={embedData["640x360"]}
                        onCopy={() => copyEmbed(2)}
                    >
                        {copiedEmbed === 2 ? (
                            <Button
                                className="btn btn-success btn-sm"
                                type="button"
                            >
                                copied
                            </Button>
                        ) : (
                            <Button className="copyTxt btn-sm" type="button">
                                copy
                            </Button>
                        )}
                    </CopyToClipboard>
                    <p className="mt-1 check ">{embedData["640x360"]}</p>
                </ModalBody>
                <ModalBody>
                    <h6 style={{ display: "inline", marginRight: "3rem" }}>
                        Shorts
                    </h6>
                    <CopyToClipboard
                        text={embedData["16x9"]}
                        onCopy={() => copyEmbed(3)}
                    >
                        {copiedEmbed === 3 ? (
                            <Button
                                className="btn btn-success btn-sm"
                                type="button"
                            >
                                copied
                            </Button>
                        ) : (
                            <Button className="copyTxt btn-sm" type="button">
                                copy
                            </Button>
                        )}
                    </CopyToClipboard>
                    <p className="mt-1 check ">{embedData["16x9"]}</p>
                </ModalBody>
            </Modal>
        </div>
    );
};

export default EmbedPopup;

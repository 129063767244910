import React, { useEffect, useState } from "react";
import formValidations from "../../variables/form-validations";
import {useHistory} from "react-router-dom";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";



const Register = (props)=> {

  const history = new useHistory();
  const [formdetails, setFormDetails] = useState({pubname:"",email:""});
  const [pubnamestate, setPubnameState] = useState("");
  const [emailstate, setEmailState] = useState("");
  const [emailerror, setEmailError] = useState("");
  const [pubnameerror, setPubNameError] = useState("");

  useEffect(() => {
    //document.body.classList.toggle("register-page");
    //document.body.className = "register-page";
    }, [])
  
  
  const handleChange = (event) => {
    props.setDetails({
      ...props.details,
      [event.target.id]: ( event.target.value ).trim(),
    });

    setFormDetails({
      ...formdetails,
      [event.target.id]: ( event.target.value ).trim(),
    });

    if(event.target.id=='pubname'){
      setPubnameState("has-success");
    }

    if(event.target.id=='email'){
      setEmailState("has-success");
    }
  }

  const handleBlur = (event) => {
    props.setDetails({
      ...props.details,
      [event.target.id]: ( event.target.value ).trim(),
    });

    setFormDetails({
      ...formdetails,
      [event.target.id]: ( event.target.value ).trim(),
    });

    // if(event.target.id=='pubname'){
    //   validatePublisherName();
    // }

    // if(event.target.id=='email'){
    //   validatePublisherEmail();
    // }

  }

  const validatePublisherEmail = () => {
      if(formdetails.email===''){
        setEmailState("has-danger");
        setEmailError("Email is required");
        return false;
      }

      if(!formValidations.verifyEmail(formdetails.email)){
        setEmailState("has-danger");
        setEmailError("Please enter a valid email.");
        return false;
      }

      setEmailState("has-success");
      return true;
  }

  const validatePublisherName = () => { 
    if (formdetails.pubname === '') {
      setPubnameState("has-danger");
      setPubNameError("Name is required");
      return false;
    }

    if(!formValidations.verifyAlphaNumeric(formdetails.pubname)){
      setPubnameState("has-danger");
      setPubNameError("Name can only be alphanumeric.");
      return false;
    }

    setPubnameState("has-success");
    return true;
  }

  const openLoginScreen = (e)=>{
    props.setCurrentState('login')
  }
  
  const handleSubmit = function(e){  validateForm();e.preventDefault() }
  
  const validateForm = () => {
    let namevalidation = validatePublisherName();
    let emailvalidation =  validatePublisherEmail();
    if(namevalidation && emailvalidation){
      props.requestOtp();
    }
  }

    return (
          <Row>
          <Col className="ms-auto me-auto" lg="10" md="10">
              <Card className="card-signup text-center">
                <CardHeader>
                  <CardTitle tag="h4">Register</CardTitle>
                  <div className="social">
                    {/* <Button className="btn-icon btn-round" color="google" href="/api/auth/google">
                      <i className="fa fa-google" />
                    </Button>
                    <Button className="btn-icon btn-round" color="facebook" href="/api/auth/facebook">
                      <i className="fa fa-facebook-f" />
                    </Button> */}
                    <a
                                        className="social_icons_round"
                                        
                                        
                                        href="/api/auth/google"
                                    >
                                        <i
                                            className="fa fa-google fa-10x"
                                            style={{
                                                background:
                                                    "conic-gradient(from -45deg, #ea4335 110deg, #4285f4 90deg 180deg, #34a853 180deg 270deg, #fbbc05 270deg) 73% 55%/150% 150% no-repeat",
                                                "-webkit-background-clip":
                                                    "text",
                                                "background-clip": "text",
                                                color: "transparent",
                                                "-webkit-text-fill-color":
                                                    "transparent",
                                            }}
                                        />
                                        &nbsp;&nbsp;Continue with Google
                                    </a>
                                    <br />
                                    <Button
                                        className="btn-round"
                                        style={{
                                            textTransform: "capitalize",
                                            width: "100%",
                                            "font-size": "15px",
                                            "font-weight": "500",
                                        }}
                                        color="facebook"
                                        href="/api/auth/facebook"
                                    >
                                        <i
                                            className="fa fa-facebook-f"
                                        />
                                        &nbsp;&nbsp;Continue with Facebook
                                    </Button>


                    <p className="card-description">- or -</p>
                  </div>
                </CardHeader>
                <CardBody>
                  <Form action="" id="registerForm" className="form" method="POST" onSubmit={handleSubmit}>

                    <InputGroup className={pubnamestate}>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-single-02" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input id="pubname" name="pubname" placeholder="Name" type="text"  onChange={handleChange} onBlur={handleBlur} />
                      {pubnamestate === "has-danger" ? (
                            <label className="error">
                              {pubnameerror}
                            </label>
                          ) : null} 
                    </InputGroup>

                    <InputGroup className={emailstate}>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-email-85" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input id="email" name="email" placeholder="Email" type="text"  onChange={handleChange} onBlur={handleBlur}/>
                      {emailstate === "has-danger" ? (
                            <label className="error">
                              {emailerror}
                            </label>
                          ) : null} 
                    </InputGroup>
                    <InputGroup className={props.errorstate}>
                    {props.errorstate === "has-danger" ? (
                            <label className="error">
                              {props.errormessage}
                            </label>
                          ) : null}   
                          </InputGroup>

                    <Button
                    className="btn-round"
                    color="success"
                    type="button"
                    onClick={validateForm}
                  >
                    Continue
                  </Button>
                  <div className="terms_and_condition">By continuing, you agree to Sortd's <a  target="_blank" href="https://www.sortd.mobi/privacy-policy">Terms of Service</a> and acknowledge that you've read our <a target="_blank" href="https://www.sortd.mobi/privacy-policy" >Privacy Policy</a></div>
                 
                 <FormGroup check className="text-left bottom_formsection">
                       

                        <a style={{'float':'right'}} href="#" onClick={(e) => openLoginScreen(e)}>
                         Already a member ? Log in
                        </a>
                    </FormGroup>
                    
                  </Form>
                </CardBody>
                <CardFooter>
                  
                </CardFooter>
              </Card>
            </Col>
          </Row>
        
    );
  
}

export default Register;

import axios from 'axios';


const getAppsGeneralDetails = async (projectId) => {
    let formdata = {
        projectId
    };
    const response  = await axios.post('/api/sortd-apps/get-app-general-details',formdata);
    return response.data;
}
const getProjectAppsGeneralSettings = async (projectId) =>{
    const formdata={
        projectId:projectId
    }
    const response=await axios.post('/api/sortd-apps/get-project-apps-general-settings',formdata)
    return response.data;
}

const updateLauncherIcon = async (launcherIconUrl) => {
    let formdata = {
        launcherIconUrl
    }
    const response = await axios.post('/api/sortd-apps/update-launcer-icon',formdata);
    return response.data;
}
const updateAppIcon =  async (appIconUrl) => {
    let formdata = {
        appIconUrl
    }
    const response = await axios.post('/api/sortd-apps/update-app-icon',formdata);
    return response.data;
}
const getAppBuildJobs = async(formdetails,page,records) =>{
    const formdata = {
        filters : formdetails,
        page : page,
        records : records
    }
    const response = await axios.post('/api/sortd-apps/get-app-build-jobs',formdata);
    return response.data;
}


const createAppBuildJob = async (formdata) =>{
    console.log(formdata)
    const response = await axios.post('/api/sortd-apps/create-app-build-job',formdata);

    return response.data;
}
const retryAppBuildJob = async(data)=>{
    const response = await axios.post('/api/sortd-apps/retry-app-build-job', data);
    return response.data;
}
const getJobData = async(jobId) => {
    const response = await axios.get(`/api/sortd-apps/job-data/${jobId}`);
    return response.data;
}

const getNewVersionName = async (formdata) =>{
    const response = await axios.post('/api/sortd-apps/get-new-version-name',formdata);
    return response.data;
} 

const createAppKeystore = async (formdata) =>{
    const response = await axios.post('/api/sortd-apps/create-android-app-keystore',formdata);
    return response.data;
}
const getAppKeystore = async (projectId) =>{
    let formdata = {
        projectId
    }
    const response = await axios.post('/api/sortd-apps/get-app-keystore',formdata);
    return response.data;
} 

export default {
    updateLauncherIcon,
    updateAppIcon,
    getAppBuildJobs,
    createAppBuildJob,
    retryAppBuildJob,
    getJobData,
    getNewVersionName,
    createAppKeystore,
    getAppKeystore,
    getAppsGeneralDetails,
    getProjectAppsGeneralSettings
}
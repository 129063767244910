import Setup from "./views/auth/Setup.js";
import Login from "./views/auth/Login.js";
import Password from "./views/auth/Password.js";
import ForgotPassword from "./views/auth/ForgotPassword.js";

var routes = [
  {
    path: "/register",
    name: "Register",
    mini: "R",
    component: Setup,
    layout: "/auth",
  },
  {
    path: "/login",
    name: "Login",
    mini: "R",
    component: Login,
    layout: "/auth",
  },
  {
    path: "/password",
    name: "Password",
    mini: "p",
    component: Password,
    layout: "/auth",
  },
  {
    path: "/forgotpassword",
    name: "Forgot Password",
    mini: "p",
    component: ForgotPassword,
    layout: "/auth",
  },
];
export default routes;

const verifyEmail = (value) => {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };
  // function that verifies if a string has a given length or not
const verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };

  const verifyExactLength = (value, length) => {
    
    if (value.length === length) {
      console.log(value.length);
      return true;
    }
    return false;
  };
  // function that verifies if two strings are equal
const compare = (string1, string2) => {
    if (string1 === string2) {
      return true;
    }
    return false;
  };
  // function that verifies if value contains only numbers
const verifyNumber = (value) => {
    var numberRex = new RegExp("^[0-9]+$");
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  };
  // verifies if value is a valid URL
const verifyUrl = (value) => {
    // try {
    //   new URL(value);
    //   return true;
    // } catch (_) {
    //   return false;
    // }
    // value = value.replace(/^http:\/\//i, '');
    // value = value.replace(/^https:\/\//i, '');
    console.log(value)
    var urlRex = /^(https?|ftp):\/\/[^\s\/$.?#].[^\s]*$/;
    if (urlRex.test(value)) {
      return true;
    }
    return false;
  };
  const verifyWebsiteUrl = (value) => {
    try{
      value = value.replace(/^http:\/\//i, '');
      value = value.replace(/^https:\/\//i, '');
      console.log(value)
      // var urlRex = /^[a-zA-Z0-9]{0,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+(\/[a-zA-Z0-9_]*)*$/;
      var urlRex = /^[a-zA-Z0-9-_]{0,61}[a-zA-Z0-9-_](?:\.[a-zA-Z0-9-_]{2,})+(\/[a-zA-Z0-9-_]*)*$/;
      if (urlRex.test(value)) {
        return true;
      }
      return false;
    }catch(error){
      return false;
    }
  };


  const verifyAlphaNumeric = (value) => {
    var numberRex = new RegExp("^[a-zA-Z0-9 ]+$");
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  };

  const checkValueInArray = (value, valueArray, fieldType) => {

    let validated = false;
    if(valueArray.length){
        if(fieldType=='array'){
            if(value && value.length){
              let optionsvalidated = 0;
              value.forEach(function(optionvalue){
                valueArray.forEach(function(option){
                  if(option.value == optionvalue){
                    optionsvalidated++;
                  }
                })
              })
              if(optionsvalidated==value.length){
                validated = true;
              }
              
            }else{
              validated = true;
            }
        }else{
          valueArray.forEach(function(option){
            if(option.value == value){
                validated = true;
              }
          })
        }
        
    }
    return validated;
  }
const validateCSS = (value) => {
    return true;
  };
  const validateVersion =(value)=>{
    var versionRex = /^\d+(\.\d+){0,2}$/;
    if (versionRex.test(value)) {
      return true;
    }
    return false;
  }
  const validateAppPackage = (value) => {
    console.log(value);
    var packageRex = /^[a-z][a-z0-9_]*(\.[a-z0-9_]+)+[0-9a-z_]$/;
    if (packageRex.test(value)) {
      console.log("Validate")
      return true;
    }
    return false;
  }
  const verifySpecialCharacters = (value) => {
    var specialCharacterRex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/;
    if (specialCharacterRex.test(value)) {
      return true;
    }
    return false;
  }
  const verifyPhoneNumber = (value) => {
    var phoneNumberRex = /^\d{10}$/;
    if (phoneNumberRex.test(value)) {
      return true;
    }
    return false;
  };
  
  const verifyPhone = (value) => {
    var phoneNumberRex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{0,6}$/;
    if (phoneNumberRex.test(value)) {
      return true;
    }
    return false;
  };

const verifyYoutubeUrl = (value) => {
  let ytRegex = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/;
  if(ytRegex.test(value)){
    return true;
  }
  return false;
}

const verifyVideoUrl = (value) => {
  let videoRegex = /^https?:\/\/(.+\/)+.+(\.(avi|mpg|rm|mov|wav|asf|3gp|mkv|rmvb|mp4|ogg|mp3|oga|aac|mpeg|webm|html|m3u8))$/;
  if(videoRegex.test(value)){
    return true;
  }
  return false;
}

const verifyCategoryAlias = (value) => {
  let regex = /[!@#$%^&*()_+=\[\]{};':"\\|,<>\/?\s]+/;
  if(regex.test(value)){
    return true;
  }
  return false;
}
export default {
      verifyEmail,
      verifyLength,
      compare,
      verifyNumber,
      verifyUrl,
      verifyWebsiteUrl,
      verifyExactLength,
      verifyAlphaNumeric,
      checkValueInArray,
      validateVersion,
      validateAppPackage,
      verifySpecialCharacters,
      verifyPhoneNumber,
      verifyPhone,
      verifyYoutubeUrl,
      verifyVideoUrl,
      verifyCategoryAlias,
  }
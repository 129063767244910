import React, { useEffect, useState, useContext } from "react";
import planService from "../../services/api/plan-service";
import { useHistory } from "react-router-dom";
import ProjectContext from "../../ProjectContext";
import ReactBSAlert from "react-bootstrap-sweetalert";
import Moment from "react-moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faTrashRestoreAlt } from "@fortawesome/free-solid-svg-icons";
import SubscriptionDetails from './SubscriptionDetails'
import './plan.css';

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Input,
    Alert,
    Spinner,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Table,
    Container,
    Row,
    Col,
} from "reactstrap";
import { Link } from "react-router-dom";
//Moment.globalTimezone = 'America/Los_Angeles';
const PlanDetails = (props) => {
   
    const history = new useHistory();
    const projectDetails = useContext(ProjectContext);
    if (projectDetails.id == "") {
        history.push("/project/allprojects");
    }
    let getProjectSlug = new URLSearchParams(props.location.search).get(
        "project"
    );
    if (!getProjectSlug) {
        history.push({
            search: "?project=" + projectDetails.slug,
        });
    }
    const projectId = projectDetails.id;
    const [planDetails, setPlanDetails] = useState(false);
    const [renderComponent, setRenderComponent] = useState(false);
    const [reloadComponent, setReloadComponent] = useState(0);
    const [showLoader, setShowLoader]   = useState(false);
    const [planId, setPlanId] = useState("");
    const [activeSubscirption,setActiveSubscirption] = useState(null);
    
    useEffect(() => {
        (async () => {
            const response = await planService.getActiveSubscription({projectId});
            console.log("Subscription response ", response);
            if(response.data){
                setActiveSubscirption(response.data);
            }
        })()
        

    },[])
    
    useEffect(() => {
        (async () => {
            setShowLoader(true);
            let planData = await planService.getPlanDetails(projectId);
            if (planData.status && planData.data) {

                setPlanDetails(planData.data);
                const response = await planService.getActivePlanId(
                    planData.data.plan_id,
                    planData.data.plan_name,
                    planData.data.plan_type
                );  
                if (response.status && response.data) {
                    setPlanId(response.data.plan_id);
                }else{
                    setPlanId(planData.data.plan_id);
                }
            }
            setShowLoader(false);
            setRenderComponent(true);
        })();
    }, [reloadComponent]);
    const formatBytes = (kilobytes, decimals = 2) =>{
        if (kilobytes === 0) return '0 KB';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(kilobytes) / Math.log(k));
        return parseFloat((kilobytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
    const getFeatureQuotaWithUnit = (feature) => {
        if(feature.quota){
            if(feature.code==="media_storage") {
                return formatBytes(feature.quota_used);
            }
            if(feature.code==="cdn_bandwidth") {
                return formatBytes(feature.quota_used/1024);
            }
            return feature.quota_used;
        }else {
            return "NA";
        }
        
    }
    let featureElements = [];
    if (planDetails.features) {
        let planFeatures = planDetails.features;

        for (const featureGroup in planFeatures) {
            featureElements.push(
                <td className="lftTblelm">
                    {" "}
                    {featureGroup}{" "}
                </td>
            );

            for (let i = 0; i < planFeatures[featureGroup].length; i++) {
                featureElements.push(
                    <tr>
                        <td></td>
                        <td className="text-left">
                            {planFeatures[featureGroup][i].name}
                        </td>
                        <td className="text-left">
                            {planFeatures[featureGroup][i].quota? planFeatures[featureGroup][i].quota : "NA"}
                            {planFeatures[featureGroup][i].code==="media_storage"?" GB":""}
                            {planFeatures[featureGroup][i].code==="cdn_bandwidth"?" GB":""}
                            {planFeatures[featureGroup][i].quota_duration==="monthly"?<>&nbsp;&nbsp;(Monthly)</>:""}
                        </td>
                        <td className="text-left">
                            {planFeatures[featureGroup][i].quota ? 
                                planFeatures[featureGroup][i].status==0 ?
                                    <>
                                        <i className="fa fa-exclamation-triangle fa-2x" style = {{"color":"#C00000"}}/>
                                        &nbsp;&nbsp;&nbsp;Quota Exausted.
                                    </>
                                :
                                planFeatures[featureGroup][i].status==2? 
                                    <>
                                        <i className="fa fa-hourglass-o fa-2x" style = {{"color":"#C00000"}}/>
                                            &nbsp;&nbsp;&nbsp;Quota limit reached. You are on Grace Period.
                                    </>
                                    :
                                    getFeatureQuotaWithUnit(planFeatures[featureGroup][i])
                            : "NA"}
                        </td>
                    </tr>
                );
            }
        }
    }

    let checkoutLink = "/plan/checkout?projectId=" +
    projectDetails.id +
    "&planId=" +
    planId +
    "&project=" +
    projectDetails.slug;

    return (
        <div className="content mt-30">
            <Container>
                <Row>
                    {activeSubscirption ?  <SubscriptionDetails
                        activeSubscirption={activeSubscirption}
                    />: ""}
                </Row>
                <Row>
                    <Col className="ml-auto mr-auto" md="12">

                        <Card className="card-signup text-center dp-box">

                            <CardHeader>
                                <CardTitle tag="h4" className="heding_tab_h4">
                                    Active Plan Details of {projectDetails.name}
                                </CardTitle>
                            </CardHeader>
                            <CardBody>
                            {showLoader?<Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>:
                            <>
                                <Table responsive>
                                    <thead className="text-primary">
                                        <th className="text-left">Plan Name</th>
                                        <th className="text-left">Plan Duration</th>
                                        <th className="text-left">Plan Start Date</th>
                                        <th className="text-left">Plan Expire Date</th>
                                    </thead>
                                    {planDetails ? (
                                        <tbody>
                                            <tr>
                                                <td className="text-left">
                                                    {planDetails.plan_name}
                                                </td>
                                                <td className="text-left">
                                                    {planDetails.plan_type}
                                                </td>
                                                <td className="text-left">
                                                    <Moment  format="DD MMM YYYY" >
                                                        {
                                                            planDetails.plan_start_date
                                                        }
                                                    </Moment>
                                                </td>
                                                <td className="text-left">
                                                    <Moment format="DD MMM YYYY" utc>
                                                        {
                                                            planDetails.plan_expire_date
                                                        }
                                                    </Moment>
                                                </td>
                                            </tr>
                                            {planDetails.is_extended?<tr>

                                                <td colspan="4"><b>Your plan has been extended till  <Moment  format="DD MMM YYYY" >{planDetails.extended_date}</Moment></b></td>
                                            </tr>:""}
                                        </tbody>
                                    ) : renderComponent ? (
                                        <tr>
                                            <td>No Active Plan Found</td>
                                        </tr>
                                    ) : (
                                        ""
                                    )}
                                </Table>
                                <Row>
                                    <CardTitle tag="h4" className="heding_tab_h4 mb-25">Features</CardTitle>
                                </Row>

                                <Table responsive>
                                    <thead>
                                        <th></th>
                                        <th>Name</th>
                                        <th>Quota</th>
                                        <th>Quota Used</th>
                                    </thead>
                                    <tbody>{featureElements}</tbody>
                                </Table>
                                </>}
                            </CardBody>
                            <CardFooter>
                                {planDetails.expire_status > 0?<>        
                                {planId ? (
                                    planDetails.plan_type === "trial" ? (
                                        <Link
                                            to={{
                                                pathname: "/dashboard/plans",
                                            }}
                                            className="btn btnBlue btn-round"
                                        >
                                            Buy Plan
                                        </Link>
                                    ) : planDetails.plan_type ===
                                      "enterprise" ? (
                                        <Link
                                            to="mailto:helpdesk@sortd.mobi"
                                            className="btn btn-round"
                                        >
                                            Contact Us
                                        </Link>
                                    ) : (
                                        <Link
                                            to={checkoutLink}
                                            className="btn btn-round"
                                        >
                                        {planDetails.expire_status > 1?"Renew Plan":"Extend Plan"}    
                                        </Link>
                                    )
                                ) : (
                                    ""
                                )}</>:
                                <>        
                                {planId ? (
                                    planDetails.plan_type === "trial" ? (
                                        <Link
                                            to={{
                                                pathname: "/dashboard/plans",
                                            }}
                                            className="btn btnBlue btn-round"
                                        >
                                            Buy Plan
                                        </Link>
                                    ) :""):""}</>
                                }
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </Container>

            <div
                className="full-page-background"
                style={{
                    backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
                }}
            />
        </div>
    );
};

export default PlanDetails;

import React, { useState, useEffect, useContext } from "react";
import JwplayerAnalysis from "./JwplayerAnalysis";
import VideoUploadForm from "./VideoUploadForm";
import { useHistory } from "react-router-dom";
import JwplayerUsage from "./JwplayerUsage";
import ProjectContext from "../../ProjectContext";
import allListingService from "../../services/api/Jwplayer/allListing-service";
import './VideoUploadForm.css'
import {
  Navbar,
  NavItem,
  NavbarToggler,
  Collapse,
  NavLink,
  Nav,
  Card,
  CardTitle,
  CardBody,
} from "reactstrap";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import SingleMediaAnalytics from "./SingleMediaAnalytics";


const JwplayerNavForSingleMedia = (props) => {
  const [currentState, setCurrentState] = useState(2);
const [isOpen, setIsOpen] = useState(false);
const [jwplayerStatus, setJwplayerStatus] = useState(false);
const [siteId, setSiteId] = useState("");
const [bucketName, setBucketName] = useState("");
const [identityPoolId, setIdentitypoolId] = useState("");
const [videoUrl,setVideoUrl]=useState("");
const [region, setRegion] = useState("");
const [gcpVideoUrl, setGCPVideoUrl] = useState("");
const [gcpRegion, setGCPRegion] = useState("");
const [parallelSyncingAllowed, setParallelSyncingAllowed] = useState(false);
const [active1,setActive1] =useState(false);
const [active2,setActive2] =useState(true);
const [active3,setActive3] =useState(false);
  const history = useHistory();
  const projectDetails = useContext(ProjectContext);
  if (!projectDetails.id) {
    history.push("/project/allprojects");
  }

  const fetchStatus = async () => {
    const { data } = await allListingService.jwplyerEnableStatus(
      projectDetails.id
    );
    if (data) {
      if (data.data.enablejwplayer) {
        const jwplayerCredentials =
          await allListingService.getJwplayerConfigCredentials({projectId:projectDetails.id});
        if(jwplayerCredentials){
          setBucketName(jwplayerCredentials.data.data.bucketName);
          setIdentitypoolId(jwplayerCredentials.data.data.IdentityId);
          setRegion(jwplayerCredentials.data.data.region);
          setVideoUrl(jwplayerCredentials.data.data.cdnUrl)
          setGCPRegion(jwplayerCredentials.data.data['gcpRegion']);
          setGCPVideoUrl(jwplayerCredentials.data.data['gcpCdnUrl']);
          setParallelSyncingAllowed(jwplayerCredentials.data.data.parallelSyncingAllowed)
        }
        
      }
      setJwplayerStatus(data.data.enablejwplayer);
      setSiteId(data.data.site_id);
    }
  };
  useEffect(() => {
   
    fetchStatus();
  }, []);
  const projectId = projectDetails.id;

  const toggle = () => setIsOpen(!isOpen);

  const callFirst = () => {
    setActive1(true);
    setActive2(false);
    setActive3(false);
    setCurrentState(1);
  };
  const callSecond = () => {
    setActive1(false);
    setActive2(true);
    setActive3(false);
    setCurrentState(2);
  };
  const callThird = () => {
    setActive1(false);
    setActive2(false);
    setActive3(true);
    setCurrentState(3);
  };

  return (
    <>
      {jwplayerStatus ? (
        <Navbar color='white' light expand="md"
        >
          <NavbarToggler className='rm-border' onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="mr-auto align-items-center" navbar>
              <NavItem >
                <NavLink  style={{color: active1?'black':"grey"}} href="#" onClick={callFirst}>
                  DASHBOARD
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink   style={{color: active2?'black':"grey"}}  href="#" onClick={callSecond}>
                  ANALYTICS
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink   style={{color: active3?'black':"grey"}}  href="#" onClick={callThird}>
                  USAGE
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
//         <nav class="navbar navbar-expand-lg navbar-light bg-light">

//   <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
//     <span class="navbar-toggler-icon"></span>
//   </button>

//   <div class="collapse navbar-collapse" id="navbarSupportedContent">
//     <ul class="navbar-nav mr-auto">
//       <li class="nav-item">
//         <p class="nav-link"  onClick={callFirst}>DASHBOARD</p>
//       </li>
//       <li class="nav-item">
//         <p class="nav-link"  onClick={callSecond}>ANALYTICS</p>
//       </li>
//       <li class="nav-item">
//         <p class="nav-link"  onClick={callThird}>USAGE</p>
//       </li>
      
//     </ul>
    
//   </div>
// </nav>

      ) : (
        <div className="content mt-30">
          <Card>
            <CardTitle>
              <h4 align="center">Videos</h4>
            </CardTitle>
            <CardBody className="mainCard">
              <h4 align="center">Not Enabled For Your Project</h4>
            </CardBody>
          </Card>
        </div>
      )}

      {currentState === 1 && jwplayerStatus ? (
        <VideoUploadForm siteId={siteId} projectId={projectDetails.id} bucketName={bucketName} videoUrl={videoUrl} region={region} identityPoolId={identityPoolId} gcpRegion={gcpRegion} gcpVideoUrl={gcpVideoUrl} parallelSyncingAllowed={parallelSyncingAllowed}/>
      ) : (
        ""
      )}
      {currentState === 2 && jwplayerStatus ? <SingleMediaAnalytics projectId={projectDetails.id}/> : ""}
      {currentState === 3 && jwplayerStatus ? <JwplayerUsage projectId={projectDetails.id}/> : ""}
    </>
  );
};

export default JwplayerNavForSingleMedia;

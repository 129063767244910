import React, { useState } from 'react';
import projectService from '../../services/api/projects-service';

function App() {
  const [url, setUrl] = useState('');
  const [headers, setHeaders] = useState({});
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [buttonText, setButtonText] = useState('Start Analysis');
  const [showHeaders, setShowHeaders] = useState(false);
  const [cacheControlInfo, setCacheControlInfo] = useState('');

  const handleChange = (e) => {
    setUrl(e.target.value);
    if (error) setError('');
    if (cacheControlInfo) setCacheControlInfo('');
  };

  const handleClear = () => {
    setUrl('');
    setHeaders({});
    setShowHeaders(false);
    setCacheControlInfo('');
    setError('');
  };

  const validateUrl = (url) => {
    const urlPattern = new RegExp(
      /^(https?:\/\/)?([a-zA-Z0-9.-]+)(:[0-9]{1,5})?(\/[^\s]*)?(\?[^\s]*)?(#[^\s]*)?$/
    );
    return urlPattern.test(url);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setHeaders({});
    setError('');
    setCacheControlInfo('');
    setShowHeaders(false);

    if (!url.trim()) {
      setError('URL cannot be empty');
      return;
    }
    if (!validateUrl(url)) {
      setError('Invalid URL');
      return;
    }
    setLoading(true);
    setButtonText('Analyzing...');
    try {
      const response = await projectService.analyseUrl(url);
      if (response.status && response.data && response.data.success === true) {
        const data = response.data.data;
        if (data.status === 403) {
          setError('We are not authorized to view the page. Please allow Header `Sec-Ch-Ua : Sortd` to view the page details.');
        } else {
          setHeaders(data.headers);
          setShowHeaders(true);

          // Process Cache-Control header
          const cacheControl = data.headers['cache-control'] || '';
          const maxAgeMatch = cacheControl.match(/max-age=(\d+)/);
          const age = parseInt(data.headers['age'], 10) || 0;
          let cacheControlText = 'Cache-Control information is not available for this page.';

          if (maxAgeMatch) {
            const maxAge = parseInt(maxAgeMatch[1], 10);
            const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
            const generatedTime = currentTime - age;
            const minutes = (maxAge / 60).toFixed(1);
            const expirationTime = generatedTime + maxAge;

            const generatedTimeFormatted = new Date(generatedTime * 1000).toLocaleString();
            const expirationTimeFormatted = new Date(expirationTime * 1000).toLocaleString();

            cacheControlText = maxAge > 60
              ? `The page can stay fresh for up to <strong>${minutes} minutes</strong> before it needs to be reloaded. It was generated on <strong>${generatedTimeFormatted}</strong> and will be considered stale after <strong>${expirationTimeFormatted}</strong>.`
              : `The page is fresh for <strong>${maxAge} seconds</strong> before it might need to be reloaded. It was generated on <strong>${generatedTimeFormatted}</strong> and will be considered stale after <strong>${expirationTimeFormatted}</strong>.`;
          }

          // Check for Sortd header
          const sortdCacheTime = data.headers['x-sortd-cache-time'];
          const sortdText = sortdCacheTime
            ? 'This site is managed by <strong>Sortd</strong>.'
            : 'We have not recognized this website on Sortd.';

          setCacheControlInfo({
            cacheControlText,
            sortdText
          });
        }
      } else {
        setError('Error fetching the URL');
      }
    } catch (err) {
      setError('Error fetching the URL');
    } finally {
      setLoading(false);
      setButtonText('Start Analysis');
    }
  };

  const sortedHeaders = Object.entries(headers)
    .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
    .reduce((obj, [key, value]) => {
      obj[key] = value;
      return obj;
    }, {});

  const styles = {
    appContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      minHeight: '100vh',
      backgroundColor: '#f0f2f5',
      padding: '20px',
    },
    card: {
      backgroundColor: '#fff',
      border: '1px solid #ddd',
      borderRadius: '8px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      padding: '20px',
      width: '100%',
      maxWidth: '1200px', // Adjust as needed to make it wider
      boxSizing: 'border-box',
      margin: '0 auto', // Center card horizontally if needed
    },
    appTitle: {
      fontSize: '1.8em',
      marginBottom: '20px',
      color: '#333',
      textAlign: 'left',
      fontWeight: 'bold',
    },
    urlForm: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    urlInputContainer: {
      position: 'relative',
      width: '100%',
    },
    urlInput: {
      padding: '12px 20px',
      margin: '10px 0',
      border: '2px solid #ddd',
      borderRadius: '5px',
      width: '100%',
      fontSize: '1em',
      transition: 'border-color 0.3s',
    },
    urlInputFocus: {
      borderColor: '#007bff',
      outline: 'none',
    },
    clearButton: {
      position: 'absolute',
      right: '10px',
      top: '50%',
      transform: 'translateY(-50%)',
      background: 'none',
      border: 'none',
      cursor: 'pointer',
      fontSize: '1.2em',
      color: '#aaa',
      transition: 'color 0.3s',
    },
    clearButtonHover: {
      color: '#333',
    },
    analyzeButton: {
      padding: '12px 24px',
      fontSize: '1em',
      color: '#fff',
      backgroundColor: '#007bff',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
      transition: 'background-color 0.3s',
      marginTop: '10px',
    },
    analyzeButtonHover: {
      backgroundColor: '#0056b3',
    },
    errorMessage: {
      color: '#d9534f',
      fontWeight: 'bold',
      marginTop: '10px',
      textAlign: 'left',
    },
    resultContainer: {
      marginTop: '20px',
    },
    cardTitle: {
      fontSize: '1.4em',
      marginBottom: '10px',
      color: '#333',
      fontWeight: 'bold',
    },
    cardContent: {
      fontSize: '1em',
      color: '#333',
      marginBottom: '20px',
    },
    headersTable: {
      width: '100%',
      borderCollapse: 'collapse',
    },
    headersTitle: {
      fontSize: '1.4em',
      marginBottom: '10px',
      color: '#333',
      fontWeight: 'bold',
    },
    table: {
      width: '100%',
      borderCollapse: 'collapse',
      marginTop: '10px',
    },
    tableHeader: {
      backgroundColor: '#007bff',
      color: '#fff',
      padding: '10px',
      textAlign: 'left',
      fontWeight: 'bold',
    },
    tableCell: {
      border: '1px solid #ddd',
      padding: '8px',
      wordBreak: 'break-word',
    },
    loader: {
      border: '4px solid #f3f3f3',
      borderTop: '4px solid #007bff',
      borderRadius: '50%',
      width: '30px',
      height: '30px',
      animation: 'spin 1s linear infinite',
      marginTop: '10px',
      marginLeft: 'auto',
      marginRight: 'auto',
      display: 'block',
    },
    '@keyframes spin': {
      '0%': { transform: 'rotate(0deg)' },
      '100%': { transform: 'rotate(360deg)' },
    },
  };

  return (
    <div style={styles.appContainer}>
      <div style={styles.card}>
        <h1 style={styles.appTitle}>Test Your Webpage Cache Headers</h1>
        <form style={styles.urlForm} onSubmit={handleSubmit}>
          <div style={styles.urlInputContainer}>
            <input
              type="text"
              value={url}
              onChange={handleChange}
              placeholder="Enter URL"
              style={{
                ...styles.urlInput,
                ...(document.activeElement === document.querySelector('input') ? styles.urlInputFocus : {}),
              }}
            />
            {url && (
              <button
                type="button"
                style={{
                  ...styles.clearButton,
                  ...(document.activeElement === document.querySelector('input') ? styles.clearButtonHover : {}),
                }}
                onClick={handleClear}
              >
                &times;
              </button>
            )}
          </div>
          <button
            type="submit"
            style={styles.analyzeButton}
            onMouseOver={(e) => e.currentTarget.style.backgroundColor = styles.analyzeButtonHover.backgroundColor}
            onMouseOut={(e) => e.currentTarget.style.backgroundColor = styles.analyzeButton.backgroundColor}
          >
            {buttonText}
          </button>
          {loading && <div style={styles.loader}></div>}
        </form>
        {error && <div style={styles.errorMessage}>{error}</div>}
        {cacheControlInfo && (
          <div style={styles.resultContainer}>
            <div style={styles.cardContent}>
              <h2 style={styles.cardTitle}>Cache Control Information</h2>
              <p dangerouslySetInnerHTML={{ __html: cacheControlInfo.cacheControlText }}></p>
              <p dangerouslySetInnerHTML={{ __html: cacheControlInfo.sortdText }}></p>
            </div>
            {showHeaders && (
              <div>
                <h2 style={styles.headersTitle}>Response Headers</h2>
                <table style={styles.table}>
                  <thead>
                    <tr>
                      <th style={styles.tableHeader}>Header</th>
                      <th style={styles.tableHeader}>Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.entries(sortedHeaders).map(([key, value]) => (
                      <tr key={key}>
                        <td style={styles.tableCell}>{key}</td>
                        <td style={styles.tableCell}>{value}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default App;


import React, { useEffect, useState, useContext } from "react";
import alertService from "../../../services/api/alert-service";
import './AdminNavbar.css';
import moment from "moment";
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Form,
  Label,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Card,
  CardBody,
  CardTitle,
  CardText,
  CardImg,Spinner
} from "reactstrap";
import { Link } from "react-router-dom";
import {useHistory} from 'react-router-dom';
const Alert = (props) => {
    const history=new useHistory();
    const [showAlert,setShowAlert]=useState(false);
    const [alertsData,setAlertData]=useState(false);
    const [componentRendered, setComponentRendered] = useState(false);
    const [clrAlertBtnCnt, setClrAlertBtnCnt] = useState("Clear All");
    
    useEffect(()=>{
        (async ()=>{
            const response=await alertService.getAlert({project_id:props.projectDetails.id});
            if(response.status && response.data){
                let alerts = response.data.alerts;
                setAlertData({alerts:alerts,unReadAlerts:response.data.unReadAlertsCount}); 
            }
            setComponentRendered(true);
        })()
    },[]);
    
    const markAlertAsRead = async (e,alert)=>{
        //  e.preventDefault();
        if(alert.read_at){
          return;
        }
        const response=await alertService.markAlertAsRead({alertId : alert.id, projectId : alert.project_id});
        if(response.status){
          alert.read_at=moment().utc();
          let temp = alertsData.alerts;
          temp.forEach((data)=>{
            if(data.id === alert.id){
              data.read_at = alert.read_at;
            }
          })
          setAlertData({alerts:temp,unReadAlerts:response.data.unReadAlertsCount})
        }
        // console.log(alertsData);
      }
    
    const  handleAlert = () => {
        if(showAlert){
            setShowAlert(false);
        }else{
            setShowAlert(true);
        }
        props.toggleAlert();
      };
      
    const  closePopAlert  = () => {
        setShowAlert(false);
        props.toggleAlert();
    };
    const clearAllAlert = async() =>{
      console.log("in clear all alert: ",props);
      // setIsDisabled(true);
      setClrAlertBtnCnt(
        <div class="spinner-border" role="status">
					<span class="visually-hidden">Loading...</span>
				</div>
      )
      const response = await alertService.markAllAlertAsRead({projectId: props.projectDetails.id});
      if(response.status && response.data){
        setTimeout(()=>{
          setClrAlertBtnCnt("Clear All");
          window.location.href = '/dashboard/home'
        },2000);
      }else{
        setTimeout(()=>{
          setClrAlertBtnCnt("Clear All");
        },2000);
      }
    }
    const handleBlur =() =>{
        setShowAlert(false);
        props.toggleAlert();
    }
    const handleLinkClick =(e,alert) =>{
      markAlertAsRead(e,alert);
      closePopAlert();
      if(alert.message_details.navigate_url) {
        window.location = alert.message_details.navigate_url;
       }
       
    }
    
    return <UncontrolledDropdown  className={showAlert?"alrtBl show":"alrtBl"} >
              <DropdownToggle
                aria-haspopup={true}
                caret
                color="default"
                // data-toggle="dropdown"
                id="navbarDropdownMenuLink"
                onClick={handleAlert}
                nav
              >
                <i className="nc-icon nc-bell-55" >
                    {
                      alertsData.unReadAlerts?
                        <span class="rw-number-notification">
                          {
                            alertsData.unReadAlerts<100?
                              alertsData.unReadAlerts
                            :"99+"
                          }
                        </span>
                     :""
                    }
                </i>
                {/* <p>
                  <span className="d-lg-none d-md-block">Some Actions</span>
                </p> */}
              </DropdownToggle>
          
              {showAlert?
                
                <DropdownMenu
                  id="scrollStyle"
                  className="notAlrtDv"
                   aria-labelledby="navbarDropdownMenuLink"
                   
                >
                  <div className="drop" tabIndex={0} onBlur={closePopAlert}>
                      <span class="clsPopAlrt" onClick={closePopAlert}><i class="fa fa-times" aria-hidden="true"></i></span>
                      <Button className="clrAllAlert" onClick={clearAllAlert} >{clrAlertBtnCnt}</Button>
                      <DropdownItem        
                        header
                        style={{backgroundColor:"#6a6a6a",color:"#FFFFFF"}}
                      >
                            Alerts
                      </DropdownItem>
                        {!alertsData || !alertsData.alerts.length ?
                           (!componentRendered ? (
                                    <Spinner className="alertLoader" type="grow">
                                      <span className="alertLoader sr-only">Loading...</span>
                                    </Spinner> 
                           ):<h4 className="noAlertMsg">No Alert Found</h4>)
                        :
                            <>
                              
                          {alertsData.alerts.map((alertDetails) => 
                                <Card 
                                  className="alertCard"
                                >
                                    <CardBody onClick={(e)=>{handleLinkClick(e,alertDetails)}} className={alertDetails.read_at?"readAlert":"unreadAlert"} >
                                          <CardText  >{alertDetails.message}</CardText>
                                          <CardText>
                                              <small className="text-muted">{moment(alertDetails.createdat).format("DD-MM-yyyy  hh:mm:ss a")}</small>
                                          </CardText>
                                    </CardBody>
                                </Card>
                            )}
                            </>}
                        </div>
                </DropdownMenu>
                :""}
            </UncontrolledDropdown>

}

export default Alert;
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import allListingService from "../../services/api/Jwplayer/allListing-service";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

const { useEffect, useState } = require("react");
const { useHistory } = require("react-router-dom/cjs/react-router-dom");

const SlidingChapterScreen = ({ projectId, mediaId, duration }) => {
    const history = useHistory();
    const [chapterTracks, setChapterTracks] = useState([]);
    const fetchChapterTracks = async () => {
        const data = await allListingService.getChapterTracks(
            projectId,
            mediaId
        );

        setChapterTracks(data.text_tracks);
    };

    function convertToIST(utcTime) {
        console.log(utcTime);
        const utcDate = new Date(utcTime);
        return utcDate.toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' });
    }

    const handleDeleteChapterTrack = async (trackId) => {
        const response = await allListingService.deleteChapterTrack(
            projectId,
            mediaId,
            trackId
        );

        const updatedChapterTracks = chapterTracks.filter(
            (track) => track.id !== trackId
        );
        setChapterTracks(updatedChapterTracks);
    };

    useEffect(() => {
        fetchChapterTracks();
    }, []);
    return (
        <div className="app-container">
            <div className="sliding-screen">
                <h5>Chapter Tracks</h5>
                <div className="description">
                    <p>
                        Markers to segment content and convey viewing milestones
                    </p>

                    <button
                        onClick={() => {
                            history.push({
                                pathname:`/video/${mediaId}/createchapter`,
                                duration
                            }
                            );
                        }}
                    >
                        Create
                    </button>
                </div>
                <div>
                    <table className="chapter-tracks-table">
                        <thead>
                            <tr>
                                <th>Label</th>
                                <th>ID</th>
                                <th>Created On</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {chapterTracks.length === 0 ? (
                                <tr>
                                    <td
                                        colSpan="4"
                                        className="no-chapters-message"
                                    >
                                        No chapters tracks yet
                                    </td>
                                </tr>
                            ) : (
                                chapterTracks.map((item, key) => (
                                    <tr
                                        key={item.key}
                                        className="chapter-track-list"
                                    >
                                        <td
                                            className="chapter-track-list-label"
                                            onClick={() =>
                                                history.push(
                                                    `/video/${mediaId}/createchapter/${item.id}`,
                                                    duration
                                                )
                                            }
                                        >
                                            {item.metadata.label}
                                        </td>
                                        <td>{item.id}</td>
                                        <td>{convertToIST(item.created)}</td>
                                        <td>{item.status}</td>
                                        <td>
                                            <FontAwesomeIcon
                                                title="Delete"
                                                icon={faTrash}
                                                onClick={() => {
                                                    handleDeleteChapterTrack(
                                                        item.id
                                                    );
                                                }}
                                                className="delete-icon"
                                            />
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default SlidingChapterScreen;

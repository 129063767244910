import React, { useEffect, useState, useContext } from "react";
import projectService from "../../services/api/projects-service";
import authorService from "../../services/api/author-service";
import {useHistory} from "react-router-dom";
import ProjectContext from '../../ProjectContext'
import ReactBSAlert from "react-bootstrap-sweetalert";
import defaultImage from "../../assets/img/image_placeholder.jpg";
import {Link} from 'react-router-dom'
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Alert,
  Spinner,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";
import './Authors.css'

const Authors = (props)=> {
  const history = new useHistory();
  const projectDetails = useContext(ProjectContext);
  if(projectDetails.id==''){
    history.push("/project/allprojects");
  }

  const projectId = projectDetails.id;
  const [authors, setAuthors] = useState(false);
  const [authorCount,setAuthorCount] = useState(false);
  const [addAuthor, setShowAddAuthor] = useState(false);
  const [renderComponent,setRenderComponent] = useState(false);
  const [showPopup,setShowPopup] = useState({alert:null});
  const [reloadComponent,setReloadComponent] = useState(0);
  const [formdetails, setFormDetails] = useState({projectId,linkedin_url: '', facebook_url: '', twitter_url: '',instagram_url : ''});
  const [showLoader, setShowLoader]   = useState(false);
  const [showSaveButton, setShowSaveButton]   = useState(true);
  const [alert, setAlert]   = useState({show:false,message:'',type:''});
  let authorForEdit = {};
  useEffect( () => {
    (async () =>{
        let authorsData = await authorService.getProjectAuthors(projectId);
            if(authorsData.status && authorsData.data){
                setAuthors(authorsData.data.authors);
                setAuthorCount(authorsData.data.count);
            }
            setRenderComponent(true);
        })();
    }, [reloadComponent]);

    

    const resendInvitePopup = (inviteId) => {
      setShowPopup({
        alert: ( 
          <ReactBSAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title="Are you sure?"
            onConfirm={() => resendInvite(inviteId)}
            onCancel={() => hideAlert()}
            confirmBtnBsStyle="info"
            cancelBtnBsStyle="danger"
            confirmBtnText="Yes, resend!"
            cancelBtnText="Cancel"
            showCancel
            btnSize=""
          >
            You want to resend the invitation!
          </ReactBSAlert>
        ),
      });
    }
    const resendInvite = async (inviteId) => {
      let resAuthor = await projectService.resendInviteToProject(inviteId);
      if(projectDetails.isContractExpired){
        setShowPopup({alert:null});
        projectDetails.setContractExpiredAlert(projectDetails);
      }
      else if(resAuthor.status && resAuthor.data.invited){
        setShowPopup({
          alert: (
            <ReactBSAlert
              success
              style={{ display: "block", marginTop: "-100px" }}
              title="Invitation Resent!"
              onConfirm={() => hideAlert()}
              onCancel={() => hideAlert()}
              confirmBtnBsStyle="info"
              btnSize=""
            >
              Invitation was resent successfully.
            </ReactBSAlert>
          ),
        });
        setReloadComponent(Math.random());
      }else{
        setShowPopup({
          alert: (
            <ReactBSAlert
              danger
              style={{ display: "block", marginTop: "-100px" }}
              title="Please try again!"
              onConfirm={() => hideAlert()}
              onCancel={() => hideAlert()}
              confirmBtnBsStyle="info"
              btnSize=""
            >
              Invitation could not be resent. Please try again.
            </ReactBSAlert>
          ),
        });
      }
    }

    const hideAlert = () => {
      setShowPopup({
        alert: null,
      });
    };  
    const onImageError = (event) => {
      event.target.src = defaultImage;

    }
    const detailsViewOfAuthor = (details) => {
      setShowPopup({
        alert: (
                <ReactBSAlert
                    Success
                    onCancel={() => hideAlert()}
                    style ={{"display":"block","position":"absolute","height" : "auto","width": "auto"}}
                    closeOnClickOutside={true}
                    showCancel={false}
                    showConfirm={false}
                    btnSize=""
                >
                    <Row>
                        <Col>
                        <CardTitle tag="h4">
                            <img className="roundImg center" onError={onImageError  } src={details.image} />
                            </CardTitle>
                        </Col>
                        <Col md="1" align="right">
                            <Button onClick = {() => hideAlert()} className ="closeBtn">X</Button>
                        </Col>   
                    </Row>
                    <Table size="lg" bordered responsive="sm" className="detailsTable">
                        {/* <tr>
                            <td >
                                Author GUID
                            </td>
                            <td>
                                {details.author_guid}
                            </td>
                        </tr> */}
                        <tr>
                            <td>
                                Name
                            </td>
                            <td>
                                {details.display_name}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Email
                            </td>
                            <td>
                                {details.user_email}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Description
                            </td>
                            <td>
                                {details.description}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Type
                            </td>
                            <td>
                                {details.type}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Author Page URL
                            </td>
                            <td>
                                {details.author_page_url}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                LinkedIn
                            </td>
                            <td>
                                {details.linkedin_url}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Facebook
                            </td>
                            <td>
                                {details.facebook_url}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Twitter
                            </td>
                            <td>
                                {details.twitter_url}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Instagram
                            </td>
                            <td>
                                {details.instagram_url}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Image
                            </td>
                            <td>
                                {details.image}
                            </td>
                        </tr>
                    </Table>
            </ReactBSAlert> 
        )
      });
    }
    const editAuthor = async () => {

        setShowLoader(true);
        setShowSaveButton(false);
        let response = await authorService.updateAuthor(projectId, authorForEdit);
        if(projectDetails.isContractExpired){
          setShowLoader(false)
          setShowSaveButton(true)
          projectDetails.setContractExpiredAlert(projectDetails);
        }
        else if(response.status && response.data){
            setTimeout(()=>{
            setShowLoader(false)
            setAlert({show:true,type:'success',message:'Author has updated Successfully'});
            },1000);
            
            setTimeout(()=>{
              setAlert({show:false,type:'',message:''});
            },3000);
            
        }else{
            setTimeout(()=>{
            setShowLoader(false)
            setShowSaveButton(true)
            setAlert({show:true,type:'danger',message:'Author could not be update, Please try again.'});
            },1000);

            setTimeout(()=>{
            setAlert({show:false,type:'',message:''});
            },3000);
        }
    }
  let authorElements = [];
  if(!authors || !authors.length){
    if(!renderComponent){
        authorElements.push(
        <td colSpan="4" className="text-center">
            <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
        </td>
      );
    }else{
        authorElements.push(
        <td colSpan="6" className="text-center">
            <CardTitle tag="h4" className="noRslt">No author found</CardTitle>
        </td>
    );
    }
  }else{
      let i=1;
      authors.forEach(function(authorDetails) {
        let className = '';
        if(authorDetails.status==false){
            className = 'table-danger';
        } 
        authorElements.push(
            <tr className={className}>
               <td className="text-left">{i}</td>
               <td className="text-left">
                    {authorDetails.display_name}
               </td>
               <td className="text-left">
                   {authorDetails.user_email}
               </td>
               
               <td className="text-left">
                    {authorDetails.type}
               </td>
               <td className="text-left">
                    <Button className="btn btn-smb" onClick={(event)=> detailsViewOfAuthor(authorDetails)}> Details</Button>
                    <Link
                        to={`/contentsettings/editauthor?authorid=${authorDetails._id}&project=${projectDetails.slug}`}
                    className="btn btn-smb"
                >Edit</Link>
               </td>
            </tr>
        )
        i++;
    })
  }
    return (
      <div className="content mt-30">
         {showPopup.alert}
        <Container>
          <Row>
          <Col className="ml-auto mr-auto"  md="12">
              <Card className="card-signup text-center dp-box">
                <CardHeader>
                <CardTitle tag="h4" className="heding_tab_h4"> 
                    Authors 
                </CardTitle>

                </CardHeader>
                <CardBody>
                <Table responsive>
                    <thead className="text-primary">
                      <th className="text-left">#</th>   
                        <th className="text-left">Name</th>
                        <th className="text-left">Email</th>
                        <th className="text-left">Type</th>
                        <th className="text-left">Action</th>
                    </thead>
                    <tbody>
                        {authorElements}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
          }}
        />
      </div>
    );
  
}

export default Authors;

import axios from "axios";

const getProvider = async(projectId) =>{
    const formdata = {
        projectId : projectId
    }
    const response = await axios.post('/api/notifications/get-provider',formdata);
    return response.data;
}

const saveProvider = async(formdata) =>{
    const response = await axios.post('/api/notifications/save-provider',formdata);
    return response.data;
}

const sendNotification = async(formdata) =>{
    console.log("in sendNotification",formdata);
    const response = await axios.post('/api/notifications/send-notification',formdata);
    return response.data;
}

const getPlatforms = async(projectId) =>{
    const formdata = {
        projectId : projectId
    }
    const response = await axios.post('/api/notifications/get-platforms',formdata);
    return response.data;
}

const getProjectNotifications = async(projectId,page,records) =>{
    const formdata = {
        projectId : projectId,
        page : page,
        records : records
    }
    const response = await axios.post('/api/notifications/get-project-notifications',formdata);
    return response.data;
}
const getCategories =async(projectId)=>{
    const formdata ={
        projectId : projectId
    }
    const response  = await axios.post('/api/notifications/get-categories',formdata)
    return response.data;

}
const getProjectTotalNotifications = async(projectId) =>{
    const formdata = {
        projectId : projectId
    }
    const response = await axios.post('/api/notifications/get-project-total-notifications',formdata);
    return response.data;
}


const getNotificationStats = async(projectId) =>{
    const formdata = {
        projectId : projectId
    }
    const response = await axios.post('/api/notifications/get-notification-stats',formdata);
    return response.data;
}
const getDbIzootoSegments = async (projectId,platform) => {
    const formdata = {
        projectId: projectId,
        platform : platform
    }
    console.log("formData : ", formdata);
    const response = await axios.post('/api/notifications/get-db-izooto-segments', formdata)
    return response.data;
}
const insertDbIzootoSegments = async (formdata) => {
  
    console.log("formData : ", formdata);
    const response = await axios.post('/api/notifications/insert-db-izooto-segments', formdata)
    return response.data;
}
const getDashboardIzootoSegments = async (projectId,platform) => {
    const formdata = {
        projectId: projectId,
        platform : platform

    }
    const response = await axios.post('/api/notifications/get-dashboard-izooto-segments',formdata);
    return response.data;
}

const getNotificationMessageService = async (projectId) => {
    const formdata = {
        projectId: projectId,
    }
    console.log("",formdata);
    const response = await axios.post('/api/notifications/get-notification-message-service',formdata);
    return response.data;
}
const removeDbIzootoSegmets = async(formdata)=>{
    const response = await axios.post('/api/notifications/remove-db-izooto-segments',formdata);
    return response.data;
}
const updateDefaultIzootoSegments = async(formdata)=>{
    console.log("formdata of updateDefaultIzootoSegments", formdata );
    const response  = await axios.post('/api/notifications/update-default-izooto-segment',formdata)
    return response.data;
}


export  default {
    getProvider,
    saveProvider,
    sendNotification,
    getPlatforms,
    getProjectNotifications,
    getProjectTotalNotifications,
    getNotificationStats,
    getDbIzootoSegments,
    insertDbIzootoSegments,
    getDashboardIzootoSegments,
    getNotificationMessageService,
    removeDbIzootoSegmets,
    updateDefaultIzootoSegments,
    getCategories
}
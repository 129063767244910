import React, { useEffect, useState } from 'react';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col,
    NavItem,
    NavLink,
    Nav,
    TabContent,
    TabPane,
} from "reactstrap";

import Switch from "react-bootstrap-switch";
import Select from "react-select";

const field = {
    "razorpay_key": {
        "level_id":3,
        "label":"Razorpay Key",
        "type":"string",
        "default":"",
        "required":false
    }}
const ConfigFormComponent = (props) => {

    const handleChange = (event) => {
        props.setFormDetails({
            ...props.formdetails,
            [event.target.id] : event.target.value
        });
    }
    const handleEnumChange = (fieldName, fieldValue) => {
        props.setFormDetails({
            ...props.formdetails,
            [fieldName] : fieldValue
        });
    }
    const defaultValue = () => {
        let fieldConfig = props.data;
        let fieldName = props.elementName;
        let fieldValue = fieldConfig.default
        props.setFormDetails({
            ...props.formdetails,
            [fieldName] : fieldValue
        });
        return fieldValue;
    }
    return (
            props.data.type == "string" ?
                <>
                    <Row>
                        <Col sm="6">
                            <div className="designLabel">
                                <small className="text-default">
                                    {props.data.label}{props.data.required?<span class="text-danger"> * </span>:""}
                                </small>
                            </div>
                            {props.data.helpText != "" ? (
                                <Label className="smLabl-txt">{props.data.helpText}</Label>
                            ) : (
                                ""
                            )}
                        </Col>

                        <Col sm="6">
                            <div className="inptFom1">
                                <FormGroup
                                    className={props.fieldStates["error_" + props.elementName]}
                                    >
                                    <Input
                                        type="text"
                                        name={props.elementName}
                                        id={props.elementName}
                                        value={props.formdetails[props.elementName]===undefined? defaultValue() : props.formdetails[props.elementName]}
                                        onChange={handleChange}
                                    />

                                    {props.fieldStates["error_" + props.elementName] ===
                                    "has-danger" ? (
                                        <label className="error" id={props.elementName}>
                                            {
                                                props.fieldStates[
                                                    "error_message_" + props.elementName
                                                ]
                                            }
                                        </label>
                                    ) : null}
                                </FormGroup>
                            </div>
                        </Col>
                    </Row>
                </> :
            props.data.type == "hidden" ?
                <>
                    <Row>
                        <Col sm="12">
                            <div className="inptFom1">
                                <FormGroup
                                    className={props.fieldStates["error_" + props.elementName]}
                                    >
                                    <Input
                                        type="hidden"
                                        name={props.elementName}
                                        id={props.elementName}
                                        value={props.formdetails[props.elementName]===undefined? defaultValue() : props.formdetails[props.elementName]}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                            </div>
                        </Col>
                    </Row>
                </> :
            props.data.type == "boolean" ? 
                <>
                    <Row>
                        <Col sm="6" >
                        <div className="designLabel">
                                <small className="text-default">
                                    {props.data.label}{props.data.required?<span className="text-danger"> * </span>:""}
                                </small>
                            </div>
                            {props.data.helpText != "" ? <Label className="smLabl-txt">{props.data.helpText}</Label> : ""}
                        </Col>
                        <Col sm="6">
                            <div className="inptFom1">
                                <FormGroup>
                                    <Switch
                                        name={props.elementName}
                                        offColor="success"
                                        offText={
                                            <i className="nc-icon nc-simple-remove" />
                                        }
                                        onColor="success"
                                        onText={<i className="nc-icon nc-check-2" />}
                                        value={props.formdetails[props.elementName]===undefined? defaultValue() : props.formdetails[props.elementName]}
                                        onChange={(value, enabled) =>
                                            handleEnumChange(props.elementName, enabled,
                                        )
                                        }
                                    />{" "}
                                </FormGroup>
                            </div>
                        </Col>
                    </Row>
                </> :
            props.data.type == "hex_color" ?
                <>
                    <Row>
                        <Col sm="6">
                            <div className="designLabel">
                                <small className="text-default">
                                    {props.data.label}{props.data.required?<span class="text-danger"> * </span>:""}
                                </small>
                            </div>
                            {props.data.helpText != "" ? <Label className="smLabl-txt">{props.data.helpText}</Label> : ""}
                        </Col>
                        <Col sm="6"  >
                            <div className="inptFomColr">
                                <div className="clrChng" style={{display:"inline-block", border:"none", }}>
                                <input
                                    style={{ background: props.formdetails[props.elementName]}}
                                    type="color"
                                    name={props.elementName}
                                    id={props.elementName}
                                    value={props.formdetails[props.elementName]===undefined? defaultValue() : props.formdetails[props.elementName]}
                                    onChange={handleChange}
                                />    
                                </div>
                                <div className="clrTxt" style={{display:"inline-block"}}>
                                <input 
                                //   style={{border:"none" }}
                                type="text"
                                name={props.elementName}
                                id={props.elementName}
                                value={props.formdetails[props.elementName]===undefined? defaultValue() : props.formdetails[props.elementName]}
                                onChange={handleChange}
                                />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </> :
            props.data.type == "enum" ?
                <>
                    <Row>
                        <Col sm="6">
                        <div className="designLabel">
                                <small className="text-default">
                                    {props.data.label}{props.data.required?<span class="text-danger"> * </span>:""}
                                </small>
                            </div>
                            {props.data.helpText != "" ? <Label className="smLabl-txt">{props.data.helpText}</Label> : ""}
                        </Col>
                        <Col sm="6">
                            <div className="inptFom1">
                                <FormGroup
                                    className={props.fieldStates["error_" + props.elementName]}
                                    >
                                    <Select
                                        className="react-select primary"
                                        classNamePrefix="react-select"
                                        name={props.elementName}
                                        // id={props.elementName}
                                        // value={currentEnumValue()}
                                        options={props.data.type_items}
                                        onChange={(value) =>
                                            handleEnumChange(props.elementName, value)
                                        }
                                    />
                                    {props.fieldStates["error_" + props.elementName] ===
                                    "has-danger" ? (
                                        <label className="error" id={props.elementName}>
                                            {
                                                props.fieldStates[
                                                    "error_message_" + props.elementName
                                                ]
                                            }
                                        </label>
                                    ) : null}
                                    <div id={props.elementName} tabIndex="0"></div>
                                </FormGroup>
                            </div>
                        </Col>
                    </Row>
                </> :
            props.data.type == "html" ?
                <>
                    <Row>
                        <Col sm="6">
                            <div className="designLabel">
                                <small className="text-default">
                                    {props.data.label}{props.data.required?<span class="text-danger"> * </span>:""}
                                </small>
                            </div>
                            {props.data.helpText != "" ? <Label className="smLabl-txt">{props.data.helpText}</Label> : ""}
                        </Col>

                        <Col sm="6">
                            <div className="inptFom1">
                            <FormGroup
                                className={props.fieldStates["error_" + props.elementName]}
                                >
                                <Input
                                    type="textarea"
                                    name={props.elementName}
                                    id={props.elementName}
                                    value={props.formdetails[props.elementName]===undefined? defaultValue() : props.formdetails[props.elementName]}
                                    onChange={handleChange}
                                    // onBlur={handleBlur}
                                />
                                {props.fieldStates["error_" + props.elementName] === "has-danger" ? (
                                <label className="error" id={props.elementName}>
                                    {
                                        props.fieldStates[
                                            "error_message_" + props.elementName
                                        ]
                                    }
                                </label>
                            ) : null}
                            </FormGroup>
                            
                            </div>
                        </Col>
                    </Row>
                </> :
            <>
                <Row>
                    <Col sm="6">
                        <div className="designLabel">
                            <small className="text-default">
                                {props.data.label}
                            </small>
                        </div>
                        {props.data.helpText != "" ? <Label className="smLabl-txt">{props.data.helpText}</Label> : ""}
                    </Col>

                    <Col sm="6">
                        <div className="inptFom1">
                        <FormGroup
                            // className={props.fieldStates["error_" + props.elementName]}
                            >
                            <Input
                                type="text"
                                name={props.elementName}
                                id={props.elementName}
                                value={props.formdetails[props.elementName]===undefined? defaultValue() : props.formdetails[props.elementName]}
                                onChange={handleChange}
                            />
                        </FormGroup>
                        {props.fieldStates["error_" + props.elementName] ===
                        "has-danger" ? (
                            <label className="error" id={props.parent+props.elementName}>
                                {
                                    props.fieldStates[
                                        "error_message_" + props.elementName
                                    ]
                                }
                            </label>
                        ) : null}
                        </div>
                    </Col>
                </Row>
            </>
    )
}


export default ConfigFormComponent;
import axios from "axios";

const sendOTPforVerification = async(phone_number,country_code)=>{
    const formdata = {
        phone_number,country_code
    }
    const response = await axios.post('/api/verification/send-otp-for-verification',formdata)
    return response.data;
}

const verifyOTP = async(phone_number,country_code, otp) => {
    const formdata = {
        phone_number,country_code, otp
    }
    const response = await axios.post("/api/verification/verify-otp",formdata);
    return response.data;
}

const saveContactDetails = async(publishers_id,country_code,phone_number,whastapp_contact_number) => {
    const formdata = {
        publishers_id,country_code,phone_number,whastapp_contact_number
    }
    const response = await axios.post("/api/verification/save-contact-details",formdata)
    return response.data;
}
export default{
    sendOTPforVerification,
    verifyOTP,
    saveContactDetails
}
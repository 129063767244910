import React, { useContext, useEffect, useState } from 'react'
import { Button, Alert, Spinner, Card, CardBody, CardHeader, CardTitle, FormGroup, Input, Label, Col, Row, Table, Form } from 'reactstrap'
import ProjectContext from '../../ProjectContext'
import notificationsService from "../../services/api/notifications-service";
import Select from 'react-select';
import SweetAlert from "react-bootstrap-sweetalert";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const IzootoCard = (props) => {
  let message = '';
  let guid = '';
  let slug = '';
  let type = 'general';

  if (props.location.state && props.location.state.guid) {
    message = props.location.state.message;
    guid = props.location.state.guid;
    slug = props.location.state.slug;
    type = 'article_promotion';
  }
  // if(props.location.state && props.location.state.category_id){
  //   message = props.location.state.category_id
  // }
  const platformLookup = {
    WEB: '1',
    ANDROID: '2',
    IOS: '3',
    all: "all"
  }
  const projectDetails = useContext(ProjectContext);
  const projectId = projectDetails.id;
  const [messageState, setMessageState] = useState("");
  const [messageerror, setMessageError] = useState("");
  const [segmentState, setSegmentState] = useState("");
  const [segmentError, setSegmentError] = useState("");
  const [categoryState,setCategoryState] = useState("");
  const [categoryError,setCategoryError] = useState("");
  const [alert, setAlert] = useState({ show: false, message: '', type: '' });
  const [showLoader, setShowLoader] = useState(false);
  const [formdetails, setFormDetails] = useState({ title: projectDetails.name, message: message, projectid: projectId, guid: guid, slug: slug, type: type, platform: "", segmentId: "", messageService: 'izooto',cat_guid:""});
  const [checkedPlatforms, setCheckedPlatforms] = useState([]);
  const [showSaveButton, setShowSaveButton] = useState(true);
  const [segmentRadioButton, setSegmentRadioButton] = useState("all")
  const [showSegmentDropDown, setShowSegmentDropDown] = useState(false);
  const [dbSegmentsLoader, setDbsegmentsLoader] = useState(false)
  const [dbSegments, setDbSegments] = useState([])
  const [selectedSegment, setSelectedSegment] = useState("")
  const [manageSegmentLoader, setManageSegmentLoader] = useState(false)
  const [savedSegments, setsavedSegments] = useState([])
  const [newSegments, setnewSegments] = useState([])
  const [deletedSegments, setDeletedSegments] = useState([])
  const [showSegmentPopup, setShowSegmentPopup] = useState(false);
  const [showPopup, setShowPopup] = useState({ alert: null })
  const [defaultSegmentId, setDefaultSegmentId] = useState(null);


  useEffect(() => {
    (async () => {
      if (props.platform) {
        const availablePlatforms = props.platform;
        setCheckedPlatforms([availablePlatforms[0]])
        setFormDetails({
          ...formdetails,
          platform: availablePlatforms[0]
        })
      }
    })()
  }, [])
  useEffect(() => {
    const defaultSegment = dbSegments.find(segment => segment.set_default);
    if (defaultSegment) {
      setSelectedSegment(defaultSegment);
      setDefaultSegmentId(defaultSegment.id)
      setFormDetails({
        ...formdetails,
        segmentId: defaultSegment._id
      })
    }
  }, [dbSegments]);

  const getDbSegments = async () => {
    try {
      setDbsegmentsLoader(true);
      const platform = checkedPlatforms[0];
      const response = await notificationsService.getDbIzootoSegments(projectId, platform)
      if (response.status && response.data) {
        setDbSegments(response.data)
      }
      setDbsegmentsLoader(false);
      setShowSegmentPopup(false)
    } catch (error) {
      toast.error(error)
      console.log(error);
      setDbsegmentsLoader(false);
    }
  }
  const addDashboardSegment = async () => {
    try {
      const platform = checkedPlatforms[0];
      setManageSegmentLoader(true);

      const response = await notificationsService.getDashboardIzootoSegments(projectId, platform);
      if (response.status && response.data) {
        console.log("dashboard segments : ", response.data);
        let dashboardSegments = response.data
        let dashboardIds = {};
        let dashboardArray = [];
        let dbArray = [];
        let deletedElementArray = []
        // dashboardIds is the  hash map
        dashboardSegments.forEach(segment => {
          dashboardIds[segment.id] = true;
        });
        console.log("db Segments : ", dbSegments);
        dbSegments.forEach(segment => {
          if (dashboardIds[segment.id]) {
            dbArray.push(segment);
            delete dashboardIds[segment.id];
          } else {
            segment.status = '-1';
            deletedElementArray.push(segment.id);
          }
        });
        console.log(deletedElementArray);
        if (deletedElementArray.length) {
          const deleteResponse = await notificationsService.removeDbIzootoSegmets({
            project_id: projectId,
            platform: platform,
            segment_ids: deletedElementArray
          })
          console.log("deleteResponse : ", deleteResponse);
          setDeletedSegments(deletedElementArray)
        }
        for (const id in dashboardIds) {
          if (dashboardIds.hasOwnProperty(id)) {
            dashboardArray.push(dashboardSegments.find(segment => segment.id === parseInt(id)));
          }
        }
        setsavedSegments(dbArray);
        setnewSegments(dashboardArray);
        setShowSegmentPopup(true);
      }
      else {
        toast.error(response.error.message)
      }
      setSelectedSegment("")
      setManageSegmentLoader(false)
    }
    catch (error) {
      console.log(error);
      toast.error(error)
      setManageSegmentLoader(false)
    }
  }

  const removeSegment = (event, segmentDetails) => {
    try {
      const response = notificationsService.insertDbIzootoSegments({
        segment_id: segmentDetails.id,
        segment_name: segmentDetails.name,
        project_id: projectId,
        status: '-1',
        platform: checkedPlatforms[0]
      })
      if (response) {
        const data = [...newSegments]
        data.push(segmentDetails);
        setnewSegments(data);

        const newData = [...savedSegments]
        const idx = newData.findIndex((obj) => obj.id === segmentDetails.id)
        newData.splice(idx, 1);
        setsavedSegments(newData)
        toast.success("Removed Successfully")
      }
    } catch (error) {
      console.log(error);
    }

  }
  const addSegment = (event, segmentDetails) => {
    console.log("add Segment clicked : ", segmentDetails);
    try {

      const response = notificationsService.insertDbIzootoSegments({
        segment_id: segmentDetails.id,
        segment_name: segmentDetails.name,
        project_id: projectId,
        status: '1',
        platform: checkedPlatforms[0],
      })
      console.log("response from add segments", response);
      if (response) {

        const data = [...savedSegments]
        console.log("data: ", data);
        data.push(segmentDetails);
        setsavedSegments(data);

        const newData = [...newSegments]
        const idx = newData.findIndex((obj) => obj.id === segmentDetails.id)
        newData.splice(idx, 1);
        setnewSegments(newData)

        toast.success("Added Successfully")
      }


    } catch (error) {
      console.log(error);
    }

  }
  const hideAlert = () => {
    setShowPopup({
      alert: null
    })
  }

  const handledefaultSegmentChange = async (segmentDetails) => {
    const confirmation = await defaultSegmentPopup(segmentDetails);
    if (confirmation) {
      await defaultSegment(segmentDetails)
      if (defaultSegmentId === segmentDetails.id) {
        setDefaultSegmentId(null);
      } else {
        setDefaultSegmentId(segmentDetails.id);
        setSelectedSegment(segmentDetails)
      }
    }
    else {
      hideAlert()
    }
  }
  const defaultSegmentPopup = async (segment) => {
    return new Promise(resolve => {
      setShowPopup({
        alert: (
          <SweetAlert
            title="Are you Sure?"
            onConfirm={() => resolve(true)}
            onCancel={() => resolve(false)}
            cancelBtnBsStyle="danger"
            confirmBtnText="Yes"
            cancelBtnText="Cancel"
            showCancel
            closeOnClickOutside={false}
            style={{ zIndex: '999' }}
          >
            Do you want to set/unset '{segment.name}' as default?
          </SweetAlert>
        )
      });
    });
  }
  const defaultSegment = async (segmentDetails) => {
    try {
      const response = await notificationsService.updateDefaultIzootoSegments({
        segment_id: segmentDetails.id,
        segment_name: segmentDetails.name,
        project_id: projectId,
        status: "1",
        platform: checkedPlatforms[0].toUpperCase(),
        set_default: segmentDetails.set_default
      })
      if (response.status && response.data) {
        await getDbSegments();
        toast.success("Segment Updated Successfully")
      }
      hideAlert();
    } catch (error) {
      console.log("error in defaultSegment : ", error);
      toast.error(error.message)
      hideAlert();
    }
  }
  let newDashboardElements = [];

  {
    newSegments.forEach(function (segmentDetails) {
      const keyValue = segmentDetails.id
      newDashboardElements.push(<tr>
        <td className="text-left">{segmentDetails.name}</td>
        <td><Button className="btn-smb" type="button" onClick={(event) => addSegment(event, segmentDetails)}>Add</Button>
        </td>

      </tr>)
    })
  }
  let savedDbElements = [];
  {
    savedSegments.forEach(function (segmentDetails) {
      savedDbElements.push(<tr>
        <td className="text-left">{segmentDetails.name}</td>
        <td><Button className="btn-smb" type="button" onClick={(event) => removeSegment(event, segmentDetails)}>Remove</Button></td>
        {(checkedPlatforms[0] === 'ANDROID' || checkedPlatforms[0] === "IOS") && (
          <td>
            <input
              type="checkbox"
              name={`defaultSegment`}
              checked={defaultSegmentId === segmentDetails.id}
              onChange={() => handledefaultSegmentChange(segmentDetails)}
            />
          </td>
        )}
      </tr>)
    })
  }

  const handleSegmentChange = (selectedOption) => {
    setSelectedSegment(selectedOption);
    setFormDetails({
      ...formdetails,
      segmentId: selectedOption._id
    })
    setSegmentError("")
    setSegmentState("")
  };

  const handleCategoriesChange = (category)=>{
    console.log(category)
    setFormDetails({
      ...formdetails,
      cat_guid : category.id,
      message: category.desc?category.desc:category.name
    })
    setMessageError("")
    setMessageState("")
    setCategoryState("");
    setCategoryError("")
  }
  const cancelPopup = async () => {
    setShowSegmentPopup(false)
  }


  const handleSegmentButtonClick = async (segmentValue) => {
    console.log("segment button ki value : ", segmentValue);
    if (segmentValue === "All") {
      setShowSegmentDropDown(false)
      setSegmentRadioButton('all')
    }
    if (segmentValue === "segment") {
      setShowSegmentDropDown(true)
      setSegmentRadioButton('')
      getDbSegments();
    }
  }
  const handleChange = (event) => {
    setFormDetails({
      ...formdetails,
      [event.target.id]: (event.target.value),
    });
    setMessageError("")
    setMessageState("")
  }

  const validateForm = async () => {
    let validated = true;
    if (formdetails.message.trim() === '') {
      setMessageState("has-danger");
      setMessageError("This field is required");
      validated = false;
    } else {

      if (formdetails.message.length > 160) {
        setMessageState("has-danger");
        setMessageError("Maximum 160 characters are allowed.");
        validated = false;
      } else {
        setMessageState("has-success");
        setMessageError("");
      }
    }
    if (showSegmentDropDown && selectedSegment === "") {
      setSegmentState("has-danger");
      setSegmentError("Please Select Segment");
      validated = false
    }
    if(formdetails.type==='category' && formdetails.cat_guid ===""){
      setCategoryState("has-danger");
      setCategoryError("Please Select Category")
    }
    if (!validated) { return false; }


    let platformString = "";
    // console.log(checkedPlatforms);
    for (let i in checkedPlatforms) {
      platformString += `${platformLookup[checkedPlatforms[i]]},`
    }
    // console.log("platform string : ", platformString);
    const formdata = {
      ...formdetails,
      platform: platformString
    }
    let sendNotification = await notificationsService.sendNotification(formdata);
    setShowLoader(true);
    setShowSaveButton(false);
    if (projectDetails.isContractExpired) {
      setShowLoader(false)
      setShowSaveButton(true)
      projectDetails.setContractExpiredAlert(projectDetails);
    }
    else if (sendNotification.status && sendNotification.data) {
      setTimeout(() => {
        setShowLoader(false)
        setAlert({ show: true, type: 'success', message: 'Notification was sent Successfully' });
      }, 1000);

      setTimeout(() => {
        setAlert({ show: false, type: '', message: '' });
        window.location.href = "/notifications/dashboard";
      }, 5000);


    } else {
      if (sendNotification.error) {
        setTimeout(() => {
          setShowLoader(false)
          setShowSaveButton(true)
          setAlert({ show: true, type: 'danger', message: 'Due to Quota limit reached.Notification could not be sent.Upgrade plan.' });
        }, 1000);
      } else {
        setTimeout(() => {
          setShowLoader(false)
          setShowSaveButton(true)
          setAlert({ show: true, type: 'danger', message: 'Notification could not be sent, Please try again.' });
        }, 1000);
      }
      setTimeout(() => {
        setAlert({ show: false, type: '', message: '' });
      }, 5000);
    }
  }
  const handleSubmit = function (e) { validateForm(); e.preventDefault() }

  const handlePlatformChange = (value, checked) => {
    setSegmentRadioButton("all")
    setShowSegmentDropDown(false)
    setSelectedSegment("")

    if (value === 'all') {
      if (checked) {
        setCheckedPlatforms(props.platform);
      }
    } else {
      let newPlatforms;
      if (checked) {
        newPlatforms = [...checkedPlatforms, value];
      } else {
        newPlatforms = checkedPlatforms.filter(item => item !== value && item !== "all");
      }
      if (newPlatforms.length) {
        setCheckedPlatforms(newPlatforms)
      }
    }
  }
  const handleScreenTypeChange = (value) => {
    setFormDetails({
      ...formdetails,
      type:value,
      message:"",
      guid:"",
      slug:"",
      cat_guid:"",
    })
    setMessageError("")
    setMessageState("")
    setCategoryState("");
    setCategoryError("")
  }
  let platFormElements = [];
  if (props.platform) {
    const availablePlatforms = props.platform;
    for (const key of availablePlatforms) {
      platFormElements.push(
        <div className='form-check fs'>
          <Label check key={key}>
            <Input
              checked={checkedPlatforms.includes(key)}
              value={key}
              id="platform"
              name="platform"
              type="checkbox"
              onChange={(e) => handlePlatformChange(key, e.target.checked)}
            />
            {key} <span className="form-check-sign" />
          </Label>
        </div>
      )
    }
  }

  const screenTypeArray = [
    {
      label: "General",
      value: "general"
    },
   
    {
      label: "Live TV",
      value: "live_tv"
    },
    {
      label: "Unread Screen",
      value: "unread_screen"
    },
    {
      label: "Category Screen",
      value: "category"
    },
  ]

  let categoryElements = [];
  for (let category of screenTypeArray) {
    categoryElements.push(
      <div className='form-check-radio fs custom-radio'>
          <Label check >
            <Input
              checked={formdetails.type === (category.value)}
              value={formdetails.type}
              id="category"
              name="category"
              type="radio"
              onChange={(e) => handleScreenTypeChange(category.value)}
            />
            {category.label} <span className="form-check-sign" />
          </Label>
          
        </div>
    )
  }

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle tag="h4" className="heding_dsh_h4">Send Push Notification via iZooto</CardTitle>
        </CardHeader>
        <CardBody>
          <Form className="form-horizontol form" action="" id="sendNotification" method="POST" onSubmit={handleSubmit}>
            <div className='consFom fulconsFom'>
              <Label className="lblNme">Platform</Label>
              <div className="inptFom mb0">
                <FormGroup>
                  <div className='form-check fs'>
                    <Label check key="all">
                      <Input
                        value="all"
                        id="platform"
                        name="platform"
                        type="checkbox"
                        onChange={(e) => handlePlatformChange("all", e.target.checked)}
                        checked={checkedPlatforms.length === props.platform.length}
                      />
                      ALL <span className="form-check-sign" />
                    </Label>
                  </div>
                  {platFormElements}
                </FormGroup>
              </div>
            </div>

            {!(checkedPlatforms.includes("WEB")) && (
              <div className='consFom fulconsFom'>
              <Label className="lblNme">Target Screen</Label>
              <div className="inptFom mb0">
                <FormGroup>
                  {categoryElements}
                  {formdetails.type === 'category' && (
                    <div>
                      <Select
                        options={props.categories}
                        placeholder="Select Category"
                        getOptionValue={(option) => option.id}
                        getOptionLabel={(option) => option.name}
                        onChange={handleCategoriesChange}
                      />
                      {categoryState === "has-danger" && (
                        <Label className="error" style={{ color: 'red' }}>
                          {categoryError}
                        </Label>
                      )}
                    </div>
                  )}
                </FormGroup>
              </div>
            </div>
            )}
            
            <div className='consFom fulconsFom'>
              <Label className="lblNme">Message</Label>
              <div className="inptFom">
                <FormGroup className={messageState}>
                  <Input
                    name="message"
                    id="message"
                    type="textarea"
                    onChange={handleChange}
                    value={formdetails.message}
                  />
                  {messageState === "has-danger" ? (
                    <Label className="error" style={{ color: 'red' }}>
                      {messageerror}
                    </Label>
                  ) : null}
                </FormGroup>
              </div>
            </div>

            <div className='consFom fulconsFom'>
              <Label className="lblName">Segments</Label>
              <div className='inputFom'>
                <div className='form-check-radio fs custom-radio'>
                  <Label check>
                    <Input
                      checked={segmentRadioButton === "all"}
                      value="All"
                      name="segment"
                      type="radio"
                      onClick={(e) => handleSegmentButtonClick("All")}
                    />
                    Broadcast (ALL Subscribers)
                    <span className="form-check-sign" />
                  </Label>
                </div>
                {checkedPlatforms.length === 1 && checkedPlatforms[0] !== 'all' &&
                  <div className='form-check-radio fs custom-radio'>
                    <Label check>
                      <Input
                        value="segment"
                        name="segment"
                        type="radio"
                        onClick={(e) => handleSegmentButtonClick("segment")}
                      />
                      Send to particular Segment
                      <span className="form-check-sign" />
                    </Label>
                    {showSegmentDropDown && (dbSegmentsLoader ? <Spinner /> :
                      <div className='inputFom'>
                        <FormGroup>
                          <Select
                            options={dbSegments}
                            getOptionValue={(option) => option.id}
                            getOptionLabel={(option) => option.name}
                            onChange={handleSegmentChange}
                            value={selectedSegment}
                          />
                          {segmentState === "has-danger" ? (
                            <Label className="error" style={{ color: 'red' }}>
                              {segmentError}
                            </Label>
                          ) : null}
                        </FormGroup>
                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                          {manageSegmentLoader ? <Spinner /> :
                            <Button className="btnBlue btn-round"
                              type="button"
                              onClick={() => addDashboardSegment()}
                            >Manage Segments
                            </Button>
                          }
                        </div>
                      </div>
                    )}
                  </div>
                }
              </div>
            </div> 
            <div className="dflLft">
              {showSaveButton ? <Button
                className="btn-round btnBlue"
                type="button"
                onClick={validateForm}
              >
                Send Notification
              </Button> : ''}
              {alert.show ? <Alert color={alert.type}>
                {alert.message}
              </Alert> : ''}
              {showLoader ? <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner> : ''}
            </div>
          </Form>
        </CardBody>

        {showSegmentPopup && (
          <SweetAlert
            title="Manage Segments"
            onConfirm={() => getDbSegments()}
            onCancel={() => cancelPopup()}
            showCancel={false}
            confirmBtnText="OK"
            btnSize=""
            confirmBtnBsStyle="info"
            style={{ width: '50%', height: '70%', overflowY: "auto", zIndex: "0" }}
            closeOnClickOutside={false}
          >
            <Row>
              <Col className="ml-auto mr-auto">
                <Table responsive>
                  <thead>
                    <tr>
                      <td>
                        <h6>Segments From Dashboard</h6>
                      </td>
                      <td>
                        <h6>Segments From Database</h6>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <Row>
                          <Col className="ml-auto mr-auto" style={{ maxHeight: "35vh", overflowY: "auto" }}>
                            <Table responsive>
                              <tbody>{newDashboardElements}</tbody>
                            </Table>
                          </Col>
                        </Row>
                      </td>
                      <td>
                        <Row>
                          <Col className="ml-auto mr-auto" style={{ maxHeight: "35vh", overflowY: "auto" }}>
                            <Table responsive>
                              <tbody>{savedDbElements}</tbody>
                            </Table>
                          </Col>
                        </Row>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </SweetAlert>
        )}
      </Card>
      {showPopup.alert}
      <ToastContainer autoClose={1000} position='bottom-right' />
    </>
  )
}

export default IzootoCard
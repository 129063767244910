const customStyles = {
    control: (provided, state) => ({
      ...provided,
      // backgroundColor: state.isFocused ? 'rgba(75,192,192,0.2)' : 'white',
      borderColor:  'rgba(75,192,192,1)',
      borderRadius: '8px',
      '&:hover': {
        borderColor: 'rgba(75,192,192,1)', 
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      fontWeight: 'bold',
      
    }),
    option : (provided,state)=>({
      ...provided,
      borderColor : state.isSelected? 'rgba(150, 220, 220, 0.5)' : '#ccc',
      backgroundColor: state.isSelected ? 'rgba(75,192,192,1)' : state.isFocused?'rgba(75,192,192,0.2)': 'white',
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      backgroundColor: 'rgba(75,192,192,1)', // Color of the indicator separator
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: state.isFocused ? 'rgba(75,192,192,1)' : 'black', // Color of the dropdown indicator
      '&:hover': {
        color: 'rgba(75,192,192,1)', // Color of the dropdown indicator on hover
      },
    }),
  }

 export default customStyles;

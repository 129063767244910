import React, { useEffect, useState } from "react";

const TimerBox = ({ time, onChange, maxTime }) => {
    const [hours, setHours] = useState("00");
    const [minutes, setMinutes] = useState("00");
    const [seconds, setSeconds] = useState("00");
    const [milliseconds, setMilliseconds] = useState("000");

    const validateInput = (value, field) => {
        // Use regex to ensure valid input (two digits for each field)
        const regex = /^\d*$/;
        const maxValue = field === "milliseconds" ? 999 : 59;

        if (!regex.test(value)) {
            // Return the current value if it doesn't pass the regex test
            switch (field) {
                case "hours":
                    return hours;
                case "minutes":
                    return minutes;
                case "seconds":
                    return seconds;
                case "milliseconds":
                    return milliseconds;
                default:
                    return "";
            }
        }

        if (value === "") {
            return "";
        }

        if (field === "hours") {
            return value;
        }
        return parseInt(value, 10) <= maxValue ? value : maxValue;
    };
    function secondsToHMSM(t) {
        const totalMilliseconds = t * 1000;

        const hours1 = Math.floor(totalMilliseconds / 3600000); // 1 hour = 3600000 milliseconds
        const remainingMillisecondsAfterHours = totalMilliseconds % 3600000;

        const minutes1 = Math.floor(
            remainingMillisecondsAfterHours / 60000
        ); // 1 minute = 60000 milliseconds
        const remainingMillisecondsAfterMinutes =
            remainingMillisecondsAfterHours % 60000;

        const seconds1 = Math.floor(
            remainingMillisecondsAfterMinutes / 1000
        ); // 1 second = 1000 milliseconds
        const milliseconds1 = remainingMillisecondsAfterMinutes % 1000;

        setHours(hours1);
        setMinutes(minutes1);
        setSeconds(seconds1);
        setMilliseconds(milliseconds1);
    }

    const handleInputChange = (e, field) => {
        const value = e.target.value;

        let validValue = validateInput(value, field);

        // Update state based on the field
        switch (field) {
            case "hours":
                setHours(validValue);
                break;
            case "minutes":
                setMinutes(validValue);
                break;
            case "seconds":
                setSeconds(validValue);
                break;
            case "milliseconds":
                setMilliseconds(validValue);
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        const newTimeInSeconds =
            parseInt(hours || 0) * 3600 +
            parseInt(minutes || 0) * 60 +
            parseInt(seconds || 0) +
            parseInt(milliseconds || 0) / 1000;
        if (time !== newTimeInSeconds) {
            if (newTimeInSeconds > maxTime) {
                console.log("new" + newTimeInSeconds);
                console.log("max" + maxTime);
            }
            console.log("newTimeInSeconds");
            const newTime=newTimeInSeconds > maxTime ? maxTime : newTimeInSeconds;
            secondsToHMSM(newTime);
            onChange(newTimeInSeconds > maxTime ? maxTime : newTimeInSeconds);
        }
    }, [hours, minutes, seconds, milliseconds]);

    useEffect(() => {
      
        secondsToHMSM(time);
    }, [time]);

    return (
        <div className="timer-input-box">
            <div className="timer-symbol-container">
                <input
                    type="text"
                    value={hours}
                    onChange={(e) => handleInputChange(e, "hours")}
                    placeholder="00"
                    className="timer-input"
                />
                hr
            </div>
            :
            <div className="timer-symbol-container">
                <input
                    type="text"
                    value={minutes}
                    onChange={(e) => handleInputChange(e, "minutes")}
                    placeholder="00"
                    maxLength="2"
                    className="timer-input"
                />
                min
            </div>
            :
            <div className="timer-symbol-container">
                <input
                    type="text"
                    value={seconds}
                    onChange={(e) => handleInputChange(e, "seconds")}
                    placeholder="00"
                    maxLength="2"
                    className="timer-input"
                />
                sec
            </div>
            :
            <div className="timer-symbol-container">
                <input
                    type="text"
                    value={milliseconds}
                    onChange={(e) => handleInputChange(e, "milliseconds")}
                    placeholder="000"
                    maxLength="3"
                    className="timer-input"
                />
                ms
            </div>
        </div>
    );
};

export default TimerBox;

const axios = require("axios");

const allListingVideo = async (page, projectId) => {
    try {
        const response = await axios.post("/api/jwplayer/getalllist", {
            pageNo: page,
            projectId: projectId,
        });
        return response;
    } catch (error) {
        console.log(error);
        throw error;
    }
};
const jwplayerUsage = async (date1, date2, projectId) => {
    try {
        const response = await axios.post("/api/jwplayer/getjwplayerusage", {
            date1: date1,
            date2: date2,
            projectId: projectId,
        });
        return response;
    } catch (error) {
        console.log(error);
        throw error;
    }
};

const jwplayerAnalyticsData = async (
    field1,
    field2,
    date1,
    date2,
    projectId,
    singleMediaId
) => {
    try {
        const jsonData = {
            field1: field1,
            field2: field2,
            date1: date1,
            date2: date2,
            projectId: projectId,
            singleMediaId: singleMediaId,
        };
        console.log("single media id is:", singleMediaId);
        const response = await axios.post(
            "/api/jwplayer/getjwplayeranalyticsdata",
            jsonData
        );
        return response;
    } catch (error) {
        console.log(error);
        throw error;
    }
};

const jwplayerVideoPlaysData = async (date1, date2, projectId) => {
    try {
        const response = await axios.post(
            "/api/jwplayer/getjwplayervideoplaysdata",
            { date1: date1, date2: date2, projectId: projectId }
        );

        return response;
    } catch (error) {
        console.log(error);
        throw error;
    }
};

const jwplayerCountryPlaysData = async (date1, date2, projectId) => {
    try {
        const response = await axios.post(
            "/api/jwplayer/getjwplayercountryplaysdata",
            { date1: date1, date2: date2, projectId: projectId }
        );
        return response;
    } catch (error) {
        throw error;
    }
};

const jwplayerDomainPlaysData = async (date1, date2, projectId) => {
    try {
        const response = await axios.post(
            "/api/jwplayer/getjwplayerdomainplaysdata",
            { date1: date1, date2: date2, projectId: projectId }
        );
        return response;
    } catch (error) {
        throw error;
    }
};

const jwplayerDevicePlaysData = async (date1, date2, projectId) => {
    try {
        const response = await axios.post(
            "/api/jwplayer/getjwplayerdeviceplaysdata",
            { date1: date1, date2: date2, projectId: projectId }
        );
        return response;
    } catch (error) {
        throw error;
    }
};
const saveJwplayerVideoData = async (formdata) => {
    try {
        const response = await axios.post(
            "/api/jwplayer/save-upload-video-data",
            formdata
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};
const getAllMediaData = async (projectId) => {
    try {
        const response = await axios.post("/api/jwplayer/getallmediadata", {
            projectId: projectId,
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

const deleteSelectedMedia = async (mediaId, projectId) => {
    try {
        const response = await axios.post("/api/jwplayer/deleteselectedmedia", {
            mediaId: mediaId,
            projectId: projectId,
        });
        return response;
    } catch (error) {
        throw error;
    }
};

const searchByTitle = async (title, projectId) => {
    try {
        const response = await axios.post("/api/jwplayer/getmediabytitle", {
            title: title,
            projectId: projectId,
        });
        return response;
    } catch (error) {
        throw error;
    }
};
const searchByMedia = async (media, projectId) => {
    try {
        const response = await axios.post("/api/jwplayer/getmediabymedia", {
            media: media,
            projectId: projectId,
        });
        return response;
    } catch (error) {
        throw error;
    }
};
const titleStatusFilter = async (title, status, projectId) => {
    try {
        const response = await axios.post("/api/jwplayer/gettitlestatusmedia", {
            title: title,
            status: status,
            projectId: projectId,
        });
        return response;
    } catch (error) {
        throw error;
    }
};

const onlyStatusFilter = async (status, projectId) => {
    try {
        const response = await axios.post(
            "/api/jwplayer/getmediabystatusonly",
            { status: status, projectId: projectId }
        );
        return response;
    } catch (error) {
        throw error;
    }
};

const jwplyerEnableStatus = async (projectId) => {
    try {
        const response = await axios.post(
            "/api/jwplayer/getjwplayerenablestatus",
            { projectId: projectId }
        );
        return response;
    } catch (error) {
        throw error;
    }
};

const getJwplayerConfigCredentials = async (formdata) => {
    try {
        const response = await axios.post(
            "/api/jwplayer/getjwplayerconfigcredentials",formdata
        );
        return response;
    } catch (error) {
        throw error;
    }
};
const getMetadata = async (mediaId, projectId) => {
    try {
        const response = await axios.post("/api/jwplayer/getmetadata", {
            mediaId: mediaId,
            projectId: projectId,
        });
        return response;
    } catch (error) {
        throw error;
    }
};
const updateData = async (formData, projectId, mediaId, url) => {
    try {
        const response = await axios.post("/api/jwplayer/updatedata", {
            formData,
            projectId: projectId,
            mediaId: mediaId,
            url: url,
        });
        return response;
    } catch (error) {
        throw error;
    }
};

const updatedThumbnail = async (formData, projectId, thumbnailId, mediaId) => {
    try {
        const response = await axios.post("/api/jwplayer/updatethumbnail", {
            formData,
            projectId: projectId,
            thumbnailId: thumbnailId,
            mediaId: mediaId,
        });
        return response;
    } catch (error) {
        throw error;
    }
};
const updatedMetaDataOnly = async (formData, projectId, mediaId) => {
    try {
        console.log("list service is", mediaId);
        const response = await axios.post("/api/jwplayer/updatemetadataonly", {
            formData,
            projectId: projectId,
            mediaId: mediaId,
        });
        return response;
    } catch (error) {
        throw error;
    }
};

const getAllPlaylists = async (page, projectId) => {
    try {
        const response = await axios.post("/api/jwplayer/getallplaylists", {
            page,
            projectId: projectId,
        });
        return response;
    } catch (error) {
        throw error;
    }
};

const createChapter = async (projectId, mediaId, vttFile, label) => {
    try {
        const response = await axios.post("/api/jwplayer/createchapters", {
            projectId,
            mediaId,
            vttFile,
            label,
        });
        return response;
    } catch (error) {
        throw error;
    }
};

const getChapterTracks = async (projectId, mediaId) => {
    try {
        const response = await axios.post("/api/jwplayer/getchaptertracks", {
            projectId,
            mediaId,
        });
        return response.data.data;
    } catch (error) {
        throw error;
    }
};

const getChapterTrack = async (projectId, mediaId, trackId) => {
    try {
        const response = await axios.post("/api/jwplayer/getchaptertrack", {
            projectId,
            mediaId,
            trackId,
        });
        return response.data;
    } catch (error) {}
};

const updateChapterTrack = async (
    projectId,
    mediaId,
    trackId,
    vttFile,
    label
) => {
    try {
        const response = await axios.post("/api/jwplayer/updatechaptertrack", {
            projectId,
            mediaId,
            trackId,
            vttFile,
            label,
        });
        console.log(response);
    } catch (error) {
        throw error;
    }
};

const deleteChapterTrack = async (projectId, mediaId, trackId) => {
    try {
        const response = await axios.post("/api/jwplayer/deletechaptertrack", {
            projectId,
            mediaId,
            trackId,
        });
        console.log(response);
    } catch (error) {
        throw error;
    }
};

const trimVideo = async (projectId, mediaId, trimPoints, videoLink) => {
    try {
        const response = await axios.post("/api/jwplayer/trimvideo", {
            projectId,
            mediaId,
            trimPoints,
            videoLink,
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

const clipVideo = async (projectId, trimPoints, videoLink, metadata) => {
    try {
        const response = await axios.post("/api/jwplayer/clipvideo", {
            projectId,
            trimPoints,
            videoLink,
            metadata,
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getMp4Link = async (mediaId) => {
    try {
        const response = await axios.post("/api/jwplayer/getmp4link", {
            mediaId,
        });
        return response.data.data;
    } catch (err) {
        console.log("Error getting Mp4Link");
        throw err;
    }
};

const generateSignedUrl = async(formdata) => {
    const response = await axios.post("/api/jwplayer/generate-signed-url",formdata);
    return response.data;
}

export default {
    allListingVideo,
    jwplayerUsage,
    jwplayerAnalyticsData,
    jwplayerVideoPlaysData,
    jwplayerCountryPlaysData,
    jwplayerDomainPlaysData,
    saveJwplayerVideoData,
    getAllMediaData,
    deleteSelectedMedia,
    searchByTitle,
    searchByMedia,
    titleStatusFilter,
    onlyStatusFilter,
    jwplyerEnableStatus,
    getJwplayerConfigCredentials,
    getMetadata,
    updateData,
    updatedThumbnail,
    updatedMetaDataOnly,
    jwplayerDevicePlaysData,
    getAllPlaylists,
    createChapter,
    getChapterTracks,
    getChapterTrack,
    updateChapterTrack,
    deleteChapterTrack,
    trimVideo,
    clipVideo,
    getMp4Link,
    generateSignedUrl,
};

import React, { useEffect, useState, useContext } from "react";
import { useHistory } from 'react-router-dom'
import ProjectContext from "../../ProjectContext";
import {
    Card,
    CardTitle,
    CardHeader,
    CardBody,
    Row,
    Col,
    Button,
    Label,
    Table,
    Input,
    Container,
    Form,
    FormGroup,
    Alert,
    Spinner
} from "reactstrap";

import './ctv.css'
import Select from 'react-select';
import ImageUplaod from '../ctv/UploadImage';
import formValidations from "../../variables/form-validations";
import ctvAppService from "../../services/api/ctv-app-service";

const EditVideo = (props) => {
    const projectDetails = useContext(ProjectContext);
    const history = useHistory();
    if (projectDetails.id == '') {
        history.push("/project/allprojects");
    }
    const projectId = projectDetails.id;

    const videoId = new URLSearchParams(props.location.search).get("video");
    if (!videoId) {
        history.push(`/ctv/dashboard?project=${projectDetails.slug}#videos`);
    }
    const [formdetails, setFormDetails] = useState({ _id: videoId, project_id: projectId, video_id: "", description: "", playlist_id: "", title: "", thumbnail: "", video_url: "", categories: [] });
    const [catList, setCatList] = useState([]);
    const [categories, setCategories] = useState([]);
    const [showLoader, setShowLoader] = useState(false);
    const [showSaveButton, setShowSaveButton] = useState(true);
    const [alert, setAlert] = useState({ show: false, message: '', type: '' });
    const [fieldStates, setFieldStates] = useState({});

    const imageDetails = {
        label: "Thumbnail",
        file_type: "image/png,image/jpeg,image/jpg,image/webp,image/tiff,image/x-icon"
    }

    useEffect(() => {
        (async () => {
            let response = await ctvAppService.getVideoDetails({ _id: videoId, project_id: projectId, });
            if (response.status && response.data) {
                let video = response.data;
                setFormDetails({
                    ...formdetails,
                    description: video.description,
                    playlist_id: video.playlist_id,
                    title: video.title,
                    thumbnail: video.thumbnail,
                    video_url: video.video_url,
                    categories: video.categories,
                    _id: video._id
                })
            }
        })();
    }, [])
    useEffect(() => {
        (async () => {
            let catData = await ctvAppService.getVideoCategories({ projectId, status: 1 });
            if (catData.status && catData.data) {
                setCategories(catData.data);
                let catArr = [];
                Object.keys(catData.data.catList).forEach(function (key) {
                    console.log("in objectkyes: ", catData.data.catList[key]);
                    let category = {};
                    category["label"] = catData.data.catList[key].name;
                    category["value"] = catData.data.catList[key]._id;
                    catArr.push(category);
                })
                setCatList(catArr);
            }
        })()
    }, [])

    const handleChange = (event) => {
        setFormDetails({
            ...formdetails,
            [event.target.id]: event.target.value,
        });
        fieldStates['error_' + event.target.id] = 'has-success';
        fieldStates['error_msg_' + event.target.id] = "";
        setFieldStates({
            ...fieldStates,
            fieldStates,
        })
    }

    const handleImageChange = async (id, image) => {
        setFormDetails({
            ...formdetails,
            thumbnail: image.value
        })

        fieldStates['error_thumbnail'] = 'has-success';
        fieldStates['error_msg_thumbnail'] = "";
        setFieldStates({
            ...fieldStates,
            fieldStates,
        })
    }
    const handleSelectChange = (event) => {
        if (event) {
            const categoryIds = event.map(option => option.value);
            setFormDetails({ ...formdetails, categories: categoryIds });
        }
        else {
            setFormDetails({ ...formdetails, categories: [] });
        }

        fieldStates['error_categories'] = 'has-success';
        fieldStates['error_msg_categories'] = "";
        setFieldStates({
            ...fieldStates,
            fieldStates,
        })
    }

    const handleSubmit = function (e) { validateForm(); e.preventDefault() }

    const validateForm = () => {
        let validations = true;
        console.log("formdetails.title.trim(): ", formdetails.title.trim() === "")
        if (formdetails.title.trim() === "") {
            fieldStates["error_title"] = "has-danger";
            fieldStates["error_msg_title"] = "This field is required";
            validations = false;
        } else {
            fieldStates["error_title"] = "has-success";
            fieldStates["error_msg_title"] = "";
        }

        if (formdetails.description.trim() === "") {
            fieldStates["error_desc"] = "has-danger";
            fieldStates["error_msg_desc"] = "Please enter a valid description";
            validations = false;
        } else {
            fieldStates["error_desc"] = "has-success";
            fieldStates["error_msg_desc"] = "";
        }

        if (formdetails.categories.length === 0) {
            fieldStates['error_categories'] = 'has-danger';
            fieldStates['error_msg_categories'] = 'Please select at least one category';
            validations = false;
        } else {
            fieldStates["error_categories"] = "has-success";
            fieldStates["error_msg_categories"] = "";
        }

        if (formdetails.thumbnail.trim() === "") {
            fieldStates["error_thumbnail"] = "has-danger"
            fieldStates['error_msg_thumbnail'] = "Please upload thumbnail"
            validations = false;
        }
        else {
            fieldStates["error_thumbnail"] = "has-success"
            fieldStates['error_msg_thumbnail'] = ""
        }

        setFieldStates({
            ...fieldStates,
            fieldStates,
        })
        return validations;
    }

    const saveVideo = async () => {
        if (validateForm()) {
            setShowLoader(true);
            setShowSaveButton(false);
            const videoSaved = await ctvAppService.saveVideo(formdetails);
            if (videoSaved.status && videoSaved.data) {
                setTimeout(() => {
                    setShowLoader(false);
                    // setShowSaveButton(true);
                    setAlert({ show: true, type: "success", message: "Video updated Successfully" })
                }, 1000);
                setTimeout(() => {
                    setAlert({ show: false, type: "", message: "" });
                    history.push(`/ctv/dashboard?project=${projectDetails.slug}#videos`)
                }, 3000)
            } else {
                setTimeout(() => {
                    setShowLoader(false);
                    setShowSaveButton(true);
                    setAlert({ show: true, type: "danger", message: "Video Could not be updated" });
                }, 1000)
            }
        }
    }

    return (
        <div className="container mt-30">
            <Container>
                <Row>
                    <Col className="ml-auto mr-auto" md="12">
                        <Card className="card-signup text-center">
                            <CardHeader>
                                <CardTitle tag="h4">
                                    <Row>
                                        <Col>
                                            Edit Video
                                        </Col>
                                    </Row>
                                </CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Form className="form-horizontal" action="" id="ctvVideosForm" method="POST" onSubmit={handleSubmit}>
                                    <Row>
                                        <Label sm="2">Title<span className="text-danger"> * </span></Label>
                                        <Col sm="7">
                                            <FormGroup className={fieldStates["error_title"]}>
                                                <Input
                                                    type="text"
                                                    id="title"
                                                    name="title"
                                                    value={formdetails.title}
                                                    onChange={handleChange}
                                                />
                                                {
                                                    fieldStates['error_title'] === 'has-danger' ?
                                                        <label className="error">{fieldStates['error_msg_title']}</label>
                                                        :
                                                        null
                                                }
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Label sm="2">Description<span className="text-danger"> * </span></Label>
                                        <Col sm="7">
                                            <FormGroup className={fieldStates["error_desc"]}>
                                                <Input
                                                    type="textarea"
                                                    id="description"
                                                    name="description"
                                                    value={formdetails.description}
                                                    onChange={handleChange}
                                                />
                                                {
                                                    fieldStates['error_desc'] === 'has-danger' ?
                                                        <label className="error">{fieldStates['error_msg_desc']}</label>
                                                        :
                                                        null
                                                }
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Label sm="2">Select Categories</Label>
                                        <Col sm="7">
                                            <FormGroup className={fieldStates["error_categories"]}>
                                                <Select
                                                    className="react-select primary"
                                                    classNamePrefix="react-select"
                                                    id="categories"
                                                    name="categories"
                                                    options={catList}
                                                    value={catList.filter(option => formdetails.categories.includes(option.value))}
                                                    onChange={handleSelectChange}
                                                    isMulti
                                                />
                                                {
                                                    fieldStates["error_categories"] === 'has-danger' ?
                                                        <label className="error">{fieldStates['error_msg_categories']}</label>
                                                        :
                                                        null
                                                }
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Label sm="2">Video Url</Label>
                                        <Col sm="7">
                                            <FormGroup className={fieldStates["error_video_url"]}>
                                                <Input
                                                    type="url"
                                                    id="video_url"
                                                    name="video_url"
                                                    value={formdetails.video_url}
                                                    onChange={handleChange}
                                                    readOnly
                                                />
                                                {
                                                    fieldStates["error_video_url"] === 'has-danger' ?
                                                        <label className="error">{fieldStates['error_msg_video_url']}</label>
                                                        :
                                                        null
                                                }
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Label sm="2">Thumbnail</Label>
                                        <Col sm="7">
                                            {/* <img src={formdetails.thumbnail}/> */}
                                            <FormGroup className={fieldStates["error_thumbnail"]}>
                                                <ImageUplaod
                                                    projectId={projectId}
                                                    imageDetails={imageDetails}
                                                    elementValue={formdetails.thumbnail}
                                                    id="thumbnail"
                                                    handleEnumChange={(id, image) => handleImageChange(id, image)}
                                                />
                                                <Input
                                                    type="hidden"
                                                    id="thumbnail"
                                                    name="thumbnail"
                                                    value={formdetails.thumbnail}
                                                    onChange={handleChange}
                                                />
                                                {fieldStates['error_thumbnail'] === "has-danger" ?
                                                    <label className="error" style={{float:"left"}}>{fieldStates["error_msg_thumbnail"]}</label>
                                                    : null}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    {
                                        showSaveButton ?
                                            <div>
                                                <Button
                                                    className="btn-round"
                                                    color="info"
                                                    type="button"
                                                    onClick={saveVideo}
                                                >
                                                    Save
                                                </Button>
                                                <Button
                                                    className="btn-round"
                                                    color="danger"
                                                    type="button"
                                                    onClick={(event) => history.push(`/ctv/dashboard?project=${projectDetails.slug}#videos`)}
                                                >Cancel</Button>
                                            </div>
                                            : null
                                    }
                                    {
                                        alert.show ?
                                            <Alert color={alert.type}>
                                                {alert.message}
                                            </Alert>
                                            :
                                            ''
                                    }
                                    {
                                        showLoader ?
                                            <Spinner animation="border" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </Spinner>
                                            :
                                            ''
                                    }
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default EditVideo;
import React, {useEffect,useState,useContext} from 'react';
import ProjectContext from '../../ProjectContext.js'

import {useHistory} from 'react-router-dom';
import planService from "../../services/api/plan-service";
import projectService from '../../services/api/projects-service.js';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardImg,
  CardFooter,
  CardTitle,
  CardText,
  Label,
  FormGroup,
  Form,
  Input,
  Alert,
  Spinner,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";
import Select from 'react-select';
import Pagination from 'react-js-pagination';
import moment from 'moment';
import ReactDatetime from 'react-datetime';
import ReactBSAlert from "react-bootstrap-sweetalert";
const closeBtnStyle={
  "border-radius": "50%",
  "padding": "0.5em",
  "width": "30px",
  "height": "30px",
  "border": "1px solid black",
  "color": "black",
  "position": "absolute",
  "background-color" :"#ffffff",
  "pointer-events":"none"
}
const Subscriptions = (props) => {
    const projectDetails = useContext(ProjectContext);
    const history = useHistory();
    if(projectDetails.id==''){
        history.push("/project/allprojects");
    }
    const project_id = projectDetails.id;
    const [formdetails, setFormDetails] = useState({project_id ,from_date :'',to_date:''});
    const [subscriptions, setSubscriptions] = useState([]);
    const [totalSubscriptions, setTotalSubscriptions] = useState(0);
    const [renderComponent , setRenderComponent] = useState(false);
    const [reloadComponent, setReloadComponent] = useState(false);
    const [activePage, setActivePage] = useState(1);
    const [firstRecord, setFirstRecord] = useState(0);
    const [lastRecord, setLastRecord] = useState(0);
    const [showPopup,setShowPopup] = useState({alert:null});
    const [paidArticleEnabled , setPaidArticleEnabled] = useState(false);
    const records = 10;
    useEffect (()=> {
        (async ()=> {
            // const projectDetails = await projectService.getProjectDetails(project_id);
            // if(projectDetails.status && projectDetails.data) {
            //     if(projectDetails.data.settings && projectDetails.data.settings.paid_article === false) {
            //         paidArticlesNotEnabledPopup();
            //     }else{
            //         setPaidArticleEnabled(true);
            //     }
            // }
        })();
    },[]);
    useEffect(()=>{
        (async ()=> {
                const response = await planService.getSubscriptions(project_id, formdetails,activePage,records);
                if(response.status && response.data) {
                    let subscriptions = response.data.subscriptions;
                    setSubscriptions(response.data.subscriptions);
                    setTotalSubscriptions(response.data.count);
                    if(subscriptions && subscriptions.length) {
                        setFirstRecord((activePage-1)*records+1);
                        setLastRecord((activePage-1)*records+ subscriptions.length);
                    }else{
                        setFirstRecord((activePage-1)*records);
                        setLastRecord((activePage-1)*records+ subscriptions.length);
                    }
                }
                setRenderComponent(true);
        })();
    },[reloadComponent]);
    const hidePaidArticlesNotEnabledPopup = () => {
        hideAlert();
        history.push("/dashboard/home");
    }
    const paidArticlesNotEnabledPopup = () => {
        setShowPopup({
        alert: (
          <ReactBSAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title="Paid Articles"
            onConfirm={hidePaidArticlesNotEnabledPopup}
            showCancel={false}
            btnSize=""
          >
            <Label>This feature is not enabled for your project.If you want to enable please contact to Sortd team.</Label>
          </ReactBSAlert>
        ),
      })
    }
    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
        setReloadComponent(Math.random());
    }
    const handleSubmit = function(e){  searchSubscription();e.preventDefault() }

    const searchSubscription = () => {
            setActivePage(1);
            setReloadComponent(Math.random());
    }
    const resetForm = () => {
        setActivePage(1);
        setFormDetails({
        ...formdetails,
        subscription_id :'',
        from_date : '',
        to_date : '',
        });
        setReloadComponent(Math.random());
    }

    const handleChange = (event)=>{
        setFormDetails({
        ...formdetails,
        [event.target.id]: event.target.value,
        });
    }
    const handleDateChange = (event,id) => {
        setFormDetails({
        ...formdetails,
        [id]: event._d,
        });
  }
  const hideAlert = ()=>{
    setShowPopup({alert:null});
  }
  const reviewSubscriptionPopup = (details) => {
      setShowPopup({
        alert: (
                <ReactBSAlert
                    Success
                    onCancel={() => hideAlert()}
                    style ={{"display":"block","position":"absolute","height" : "auto","width": "auto"}}
                    closeOnClickOutside={true}
                    showCancel={false}
                    showConfirm={false}
                    btnSize=""
                >
                    <Row>
                        <Col>
                        <CardTitle tag="h4" align="left">
                            Subscription details
                            </CardTitle>
                        </Col>
                        <Col align="right">
                            <Button onClick = {() => hideAlert()} className ="closeBtn">X</Button>
                        </Col>   
                    </Row>
                    <Table size="lg" bordered responsive="sm" className="detailsTable">
                        <tr>
                            <td >
                                Subscription Id
                            </td>
                            <td>
                                {details.pg_subscription_id}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Status
                            </td>
                            <td>
                                {details.status}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Paid count
                            </td>
                            <td>
                                {details.paid_count}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Total count
                            </td>
                            <td>
                                {details.total_count}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Recurring Amount (INR)
                            </td>
                            <td>
                                {details.rz_plan_price}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Start date
                            </td>
                            <td>
                                {details.start_at ?
                                moment(details.start_at *1000).local().format('dddd DD MMM YYYY hh:mm:ss a')
                                :""}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                End date
                            </td>
                            <td>
                                {details.end_at ? 
                                moment(details.end_at *1000).local().format('dddd DD MMM YYYY hh:mm:ss a')
                                :""}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                 Current start date
                            </td>
                            <td>
                                {details.current_start ? 
                                 moment(details.current_start *1000).local().format('dddd DD MMM YYYY hh:mm:ss a')
                                 :""}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Current end date
                            </td>
                            <td>
                                { details.current_end ?
                                moment(details.current_end *1000).local().format('dddd DD MMM YYYY hh:mm:ss a')
                                :""}
                            </td>
                        </tr>

                        <tr>
                            <td>
                                Subscribed On
                            </td>
                            <td>
                                {moment(details.createdAt).local().format('dddd DD MMM YYYY hh:mm:ss a')}
                            </td>
                        </tr>
                    </Table>
            </ReactBSAlert> 
        )
      });
    }

    return (
        <div className="content mt-30">
            {showPopup.alert}
            <Container>
                <Card className="text-centercdp-box dp-box">
                    <CardHeader>
                        <CardTitle tag="h4" className="text-center">
                            Subscriptions 
                        </CardTitle>
                        
                    <Form
                        id="subscriptionFilters"
                        action=""
                        className="form"
                        method="POST"
                        onSubmit={handleSubmit}
                        >
                            <Row>
                                <Col>
                                    <Input
                                        className="mrgin"
                                        value={formdetails.subscription_id}
                                        type="text"
                                        name="subscription_id"
                                        id="subscription_id"
                                        onChange={handleChange}
                                        placeholder="Subscription Id"
                                    />
                                </Col>
                                {/* <Col>
                                    <Input
                                        className="mrgin"
                                        value={formdetails.order_id}
                                        type="text"
                                        name="order_id"
                                        id="order_id"
                                        onChange={handleChange}
                                        placeholder="Order Id"
                                    />
                                </Col> */}
                                {/* <Col>
                                    <Input
                                        className="mrgin"
                                        value={formdetails.amount}
                                        type="text"
                                        name="amount"
                                        id="amount"
                                        onChange={handleChange}
                                        placeholder="Amount"
                                    />
                                </Col> */}
                                <Col className="text-center">
                                    <ReactDatetime
                                        className = "mrgin"
                                        value={formdetails.from_date}
                                        onChange={(event)=>handleDateChange(event , "from_date")}
                                        inputProps={{
                                            className: "form-control",
                                            placeholder: "From Date",
                                            id:"from_date",
                                        }
                                        }
                                    />
                                </Col>
                                <Col>
                                    <ReactDatetime
                                        className="mrgin"
                                        value={formdetails.to_date}
                                        onChange={(event)=>handleDateChange(event , "to_date")}
                                        inputProps={{
                                            className: "form-control",
                                            placeholder: "To Date",
                                            id:"to_date",
                                        }}
                                    />
                                </Col>
                                
                                <Col>
                                <Button className="btn-smb" type="submit">
                                    Search
                                </Button>
                                <Button
                                    className="btn-smb"
                                    type="button"
                                    onClick={resetForm}
                                    >
                                Reset 
                                </Button>
                                </Col>
                                
                            </Row>
                    </Form>
                    {totalSubscriptions > 0 ?
                        <div className="pagBox">
                            
                            <div className="pagNo">
                            {firstRecord} - {lastRecord} of {totalSubscriptions}
                            </div>
                            <div className="pagDgt">
                                
                            <Pagination
                            activePage={activePage}
                            itemsCountPerPage={records}
                            totalItemsCount={totalSubscriptions}
                            pageRangeDisplayed={5}
                            onChange={handlePageChange.bind(this)}
                            itemClass="page-item"
                            linkClass="page-link"
                            />
                            </div>
                        </div>
                    : null}
                    </CardHeader>
                    
                    {!renderComponent?
                        <div className="text-center">
                            <Spinner className="text-center" animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                        </div>
                    :null}
                <CardBody>
                  <Table responsive>
                    <thead className="text-primary">
                    {renderComponent?
                        <>
                            <tr>
                            <th className="text-center">#</th> 
                            <th className="text-center" width="200px" >Subscription Id</th>   
                            <th className="text-center" width="150px">Recurring Amount(INR)</th>
                            <th className="text-center" width="150px">Status</th>
                            <th className="text-center" width="230px">Paid Count</th>
                            <th className="text-center"  width="300px">Subscribed On</th>
                            <th className="text-center">Action</th>
                            </tr>
                        </>:''}
                    </thead>
                    <tbody>

                        { subscriptions && subscriptions.length?subscriptions.map((details, index)=>(
                        <tr className={details.status==="cancelled"?'table-danger':""}>
                            <td className="text-left">{firstRecord+index}</td>
                            <td className="text-center">
                                {details.pg_subscription_id}
                            </td>
                            <td className="text-center">
                                {details.rz_plan_price}
                            </td> 
                           
                            <td className="text-center">
                                {details.status}
                            </td>
                            <td className="text-center">
                                {details.paid_count}
                            </td>
                            <td className="text-center">
                                {moment(details.createdAt).format('dddd DD MMM YYYY hh:mm:ss a')}
                            </td>
                            <td className="text-center">
                                <Button className="btn-smb" onClick={(event)=>reviewSubscriptionPopup(details)}> Review</Button>
                            </td>
                        </tr>
                        ))
                        :
                        null
                        }
                        {renderComponent && (!subscriptions|| !subscriptions.length)?
                        <tr>
                        <td colSpan="12" className="text-center">
                            <CardTitle tag="h4" >No Subscription Found</CardTitle>
                        </td>
                        </tr>: 
                        null
                    }
                    </tbody>
                  
                  </Table>

                  
                </CardBody>





                    
                    



                </Card>
                 <div
            className="full-page-background"
            style={{
                backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
            }}
            />
            </Container>
           
        </div>
    );
}

export default Subscriptions;
import React, { useEffect, useState, useContext } from "react";
import ProjectContext from '../../ProjectContext'


import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Input,
    Alert,
    Spinner,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col,
  } from "reactstrap";


const ProjectSuspended = ()=>{
    return (<div className="content">
    <Row>
        <Col className="ml-auto mr-auto">
            <Card className="card-signup text-center">
                <CardHeader>
                     <CardTitle tag="h4">Project Suspended</CardTitle>
                 </CardHeader>
                 <CardBody>This project has been suspended please connect our team at support@sortd.mobi</CardBody>
           </Card>
        </Col>
    </Row>
</div>)
}


export default ProjectSuspended;
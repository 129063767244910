import React, { useState,useEffect } from "react";
import {useHistory} from "react-router-dom";
import Register from "./Register.js";
import RwPopup from "./RwPopup.js";
import Otp from "./Otp.js";
import authService from "../../services/api/auth-service.js";

const Setup = (props)=> {
  
  const history = new useHistory();
  const [status, setStatus] = useState("register");
  const [details, setDetails] = useState({pubname:"",email:"",otp:"",authtoken:""});

  const [importrwdata, setImportrwdata] = useState(false);
  
  const [errorstate, setErrorState] = useState('has-success');
  const [errormessage, setErrorMessage] = useState('There was an error. Please try again.');

  const requestOtp = async () => {
    try {
      
      const response = await authService.generateOtp(details);
      
      if(response.status && response.data.otp_sent){
        setDetails({
          ...details,
          authtoken: response.data.auth_token,
        });
        setErrorState("has-success");
        setStatus("otp");
      }else{
        setErrorState("has-danger");
        setErrorMessage(response.error.message);
      }
      
    } catch (err) {}
  }

  const validateOtp = async () => {
  
    try {
      const response = await authService.validateOtp(details);
      if(response.status && response.data.otp_verified){
        //history.push("/auth/password");
        props.setCurrentState('password')
      }else{
        setErrorState("has-danger");
        setErrorMessage(response.error.message);

        setTimeout(()=>{
          setErrorState("has-success");
        },2000);
      }
      
    } catch (err) {}
  }

  const resendOtp = async () => {
  
    try {
      const response = await authService.resendOtp(details);
      if(response.status){
        setErrorState("has-success");
      }else{
        setErrorState("has-danger");
        setErrorMessage(response.error.message);
      }
      
    } catch (err) {}
  }
  
  const submitRwdata = async(event) => {
    event.preventDefault();
    try {
      history.push("/auth/password");
    } catch (err) {}
  }

  

  return ( 
      <>
        { 
          status ==="register"? <Register setCurrentState={props.setCurrentState} requestOtp={requestOtp} details={details} setDetails={setDetails} errorstate={errorstate} errormessage={errormessage}/> :
          status ==="otp"? <Otp setCurrentState={props.setCurrentState} validateOtp={validateOtp} resendOtp={resendOtp} details={details} setDetails={setDetails} errorstate={errorstate} errormessage={errormessage}/> :
          status === "rwpopup" ? <RwPopup submitRwdata={submitRwdata} importrwdata={importrwdata} setImportrwdata={setImportrwdata} errorstate={errorstate} errormessage={errormessage}/>: null
        }
      </>
      
 
  )
}

export default Setup;

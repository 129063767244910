import React, { useEffect, useState, useContext } from "react";
import projectService from "../../services/api/projects-service";
import {useHistory} from "react-router-dom";
import ProjectContext from '../../ProjectContext';

// reactstrap components
import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Spinner,
  Row,
  Col,
} from "reactstrap";

const SubuserCountWidget = (props)=> {
  const projectDetails = useContext(ProjectContext);
  const history = new useHistory();
  
  if(projectDetails.id==''){
    history.push("/project/allprojects");
  }
  const projectId = projectDetails.id;
  const [totalSubusers,setTotalSubusers] = useState(0);
  const [renderComponent,setRenderComponent] = useState(false);
 
  useEffect( () => {
    (async () =>{
        let usersData = await projectService.getProjectUsers(projectId);
            if(usersData.status && usersData.data){
                if(usersData.data.length>1){
                    setTotalSubusers(usersData.data.length-1);
                }else{
                    setTotalSubusers(0);
                }
                
            }
            setRenderComponent(true);
        })();
    }, []);  
 

    return (
        <Card className="card-stats zooM">
              <div className="icnBx">
              <i className="fa fa-user " />
              </div>
              <div className="numbers">
                <h2 className="card-category">Subusers</h2>
                <p>
                    {!renderComponent?
                    <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                  :totalSubusers}
                </p>
              </div>
      </Card>
    );
  
  
}

export default SubuserCountWidget;

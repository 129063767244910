import React, { useEffect, useState, useContext } from "react";
import projectService from "../../services/api/projects-service";
import {useHistory} from "react-router-dom";
import AddUser from './AddUser';
import ProjectContext from '../../ProjectContext'
import ReactBSAlert from "react-bootstrap-sweetalert";
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash,faTrashRestoreAlt } from '@fortawesome/free-solid-svg-icons'
import './settings.css';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Alert,
  Spinner,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";


const InvitedUsers = (props)=> {
  const history = new useHistory();
  const projectDetails = useContext(ProjectContext);
  if(projectDetails.id==''){
    history.push("/project/allprojects");
  }

  const projectId = projectDetails.id;
  const [users, setUsers] = useState(false);
  const [addUser, setShowAddUser] = useState(false);
  const [renderComponent,setRenderComponent] = useState(false);
  const [showPopup,setShowPopup] = useState({alert:null});
  const [reloadComponent,setReloadComponent] = useState(0);
  
  useEffect( () => {
    (async () =>{
        let usersData = await projectService.getProjectInvitedUsers(projectId);
            if(usersData.status && usersData.data){
                setUsers(usersData.data);
            }
            setRenderComponent(true);
        })();
    }, [reloadComponent]);

    

    const resendInvitePopup = (inviteId) => {
      setShowPopup({
        alert: ( 
          <ReactBSAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title="Are you sure?"
            onConfirm={() => resendInvite(inviteId)}
            onCancel={() => hideAlert()}
            confirmBtnBsStyle="info"
            cancelBtnBsStyle="danger"
            confirmBtnText="Yes, resend!"
            cancelBtnText="Cancel"
            showCancel
            btnSize=""
          >
            You want to resend the invitation!
          </ReactBSAlert>
        ),
      });
    }

   
    const resendInvite = async (inviteId) => {
      let resUser = await projectService.resendInviteToProject(inviteId,projectId);
      if(projectDetails.isContractExpired){
        setShowPopup({alert:null});
        projectDetails.setContractExpiredAlert(projectDetails);
      }
      else if(resUser.status && resUser.data.invited){
        setShowPopup({
          alert: (
            <ReactBSAlert
              success
              style={{ display: "block", marginTop: "-100px" }}
              title="Invitation Resent!"
              onConfirm={() => hideAlert()}
              onCancel={() => hideAlert()}
              confirmBtnBsStyle="info"
              btnSize=""
            >
              Invitation was resent successfully.
            </ReactBSAlert>
          ),
        });
        setReloadComponent(Math.random());
      }else{
        setShowPopup({
          alert: (
            <ReactBSAlert
              danger
              style={{ display: "block", marginTop: "-100px" }}
              title="Please try again!"
              onConfirm={() => hideAlert()}
              onCancel={() => hideAlert()}
              confirmBtnBsStyle="info"
              btnSize=""
            >
              Invitation could not be resent. Please try again.
            </ReactBSAlert>
          ),
        });
      }
    }

    const hideAlert = () => {
      setShowPopup({
        alert: null,
      });
    };  
 

  let userElements = [];
  if(!users || !users.length){
    if(!renderComponent){
        userElements.push(
        <td colSpan="4" className="text-center">
            <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
        </td>
      );
    }else{
        userElements.push(
        <td colSpan="6" className="text-center">
            <CardTitle tag="h4" className="noRslt">No user found</CardTitle>
        </td>
    );
    }
  }else{
      let i=1;
      users.forEach(function(userDetails) {
        let className = '';
        
        let now = moment(new Date());//now
        let created = moment(userDetails.createdat);
        let diffHours = now.diff(created, 'hours');
        let timeCreated = moment(userDetails.createdat).fromNow();

        let expired = false;
        if(userDetails.status==0 && diffHours >= 1){
            className = 'table-danger';
            expired = true;
        }
        
        userElements.push(
            <tr className={className}>
               <td className="text-left">{i}</td>
               <td className="text-left">
                   {userDetails.email}
               </td>
               <td className="text-left">
                   {userDetails.roles.join(', ')}
               </td>
               <td className="text-left">
                   {timeCreated}
               </td>
               <td className="text-left">
                   {expired?'Expired'
                   :userDetails.status==0?'Invited'
                    :userDetails.status==-1?'Deleted'
                    :userDetails.status==1?'Accepted'
                    :userDetails.status==-2?'Reinvited':''}
               </td>
               <td className="text-left">
                  {expired?
                  <a title="Resend Invite" onClick={(event)=>  resendInvitePopup(userDetails.id) } href="javascript:void(0)" class="nav-link">
                  Resend Invite
                </a>
                :''}
               </td>
            </tr>
        )
        i++;
    })
  }

  if(addUser){
    return (
      <AddUser projectId={projectId} projectName={projectDetails.name} setShowAddUser={setShowAddUser}/>
    );
  }else{
    return (
      <div className="content mt-30">
         {showPopup.alert}
        <Container>
          <Row>
          <Col className="ml-auto mr-auto"  md="12">
              <Card className="card-signup text-center dp-box">
                <CardHeader>
                <CardTitle tag="h4" className="heding_tab_h4"> 
                    List of Invited Users to {projectDetails.name}
                </CardTitle>

                 <Button
                      style={{float: 'right'}}
                      className="btn-smb adUsr"
                      type="button"
                      onClick={(event)=>  setShowAddUser(true) }
                  >Add User</Button>

                <Button
                  style={{float: 'right'}}
                    className="btn-smb btnBlk"
                    type="button"
                    onClick={(event)=>  history.push("/project/users") }
                  >
                    Back
                  </Button>
                </CardHeader>
                <CardBody>
                <Table responsive>
                    <thead className="text-primary">
                    
                      <th className="text-left">#</th>   
                        <th className="text-left">Email</th>
                        <th className="text-left">Roles</th>
                        <th className="text-left">Invited</th>
                        <th className="text-left">Status</th>
                        <th className="text-left">Action</th>
                    </thead>
                    <tbody>{userElements}</tbody>
                  
                  </Table>
                </CardBody>
                {/* <CardFooter>
                </CardFooter> */}
              </Card>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
          }}
        />
      </div>
    );
  }
  
}

export default InvitedUsers;

import React,{useState,useEffect,useContext} from 'react';
import { useHistory  } from 'react-router-dom';
import FeatureConfigForm from './FeatureConfigForm';
import ProjectContext from '../../ProjectContext'
import featuresService from '../../services/api/features-service';
import formValidations from "../../variables/form-validations";

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Input,
    Alert,
    Spinner,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col,
} from "reactstrap";
import './Features.css'
const FeatureConfig = (props)=>{
    const projectDetails = useContext(ProjectContext);
    const history = new useHistory();
    if (projectDetails.id == "") {
        history.push("/project/allprojects");
    }
    const projectId = projectDetails.id;
    const [fieldSchema, setFieldSchema] = useState(false);
    const [fieldPath, setFieldPath] = useState([]);
    const [formdetails, setFormDetails] = useState({});
    const [fieldStates,setFieldStates] = useState([]);
    const [displayForm, setDisplayForm] = useState(false);
    useEffect(()=>{
        (async  ()=>{
          if(props.featureCode){
            setFormDetails({});
            const response = await  featuresService.getFeatureDependentFieldSchema(props.featureCode);
            if(response.status){
                if(Object.keys(response.data).length) {
                    console.log("found");
                    setFieldSchema(response.data.feature_config);
                    setFieldPath(response.data.feature_field_path);
                    setDisplayForm(true);
                }else{
                    console.log("Not found");
                    props.handleFeatureConfigSave(false);
                }
            }
          }
        })();
    },[props.featureCode]);

    const handleSubmit = async (e) => {
        console.log("FormDetails",formdetails);
        let isValid= true;
        isValid = validateAllFields();
        if(isValid){
            const response = await featuresService.saveFeatureDependentFieldConfig(projectId,fieldPath,formdetails);
            if(projectDetails.isContractExpired){
              projectDetails.setContractExpiredAlert(projectDetails);
            }
            else if(response && response.data) {
                console.log("Saved",response.data);
                props.handleFeatureConfigSave(true);
            }else{
                console.log("Unable to save config");
            }
        }else{
            console.log("Invalid Fields");
        }
    }
    const handleCancel = async (e) => {
      props.onCancel();
    }
    const validateAllFields = () => {
        let isValid = true;
        let error = {};
        Object.keys(fieldSchema.items).forEach(function (elementName ){
            console.log("Element Name:",elementName);
            let fieldValue = formdetails[elementName] && fieldSchema.items[elementName].type==="string"? formdetails[elementName].trim(): formdetails[elementName];
            let validationResult = validateConfigField(elementName,fieldValue || "",fieldSchema.items[elementName],"field");
            
            if(validationResult && validationResult.isValid=== false) {
                isValid = false;
                
            }
            error = {
                    ...error,
                    ...validationResult.data
                }
            console.log(error);
        })
          if(!isValid) {
            setFieldStates({
            ...fieldStates,
            ...error
        }); 
          }
        return isValid;   
    }
    const validateConfigField = (fieldId, fieldValue, fieldDesign, validationType) =>{
        let isValid = true;
        if((fieldDesign.type=='string' || fieldDesign.type=='integer' || fieldDesign.type=='url' || fieldDesign.type=='enum' || fieldDesign.type=='file_upload' || fieldDesign.type=='html') && fieldDesign.required){
            if(fieldValue==''){
              if(validationType=='form'){
                fieldStates['error_'+fieldId] = 'has-danger';
                fieldStates['error_message_'+fieldId] = 'This field is required.';
              }else{
                //   setFieldStates({
                //     ...fieldStates,
                //     ['error_'+fieldId]: 'has-danger',
                //     ['error_message_'+fieldId]: 'This field is required.',
                //   });
                  return {
                    isValid : false,
                    data : {
                    ['error_'+fieldId]: 'has-danger',
                    ['error_message_'+fieldId]: 'This field is required.',
                  }
                }
              }
              isValid = true;
            }else{
              if(validationType=='form'){
                fieldStates['error_'+fieldId] = 'has-success';
                fieldStates['error_message_'+fieldId] = '';
              }else{
                // setFieldStates({
                //   ...fieldStates,
                //   ['error_'+fieldId]: 'has-success',
                //   ['error_message_'+fieldId]: '',
                // });
                return {
                    isValid : true,
                    data : {
                    ['error_'+fieldId]: 'has-success',
                    ['error_message_'+fieldId]: '',
                  }
                }
              }
            }
        }

        if(isValid && fieldDesign.type=='url'){
            if(!fieldValue){
                fieldValue = ''
            }
            if(fieldValue!='' && !formValidations.verifyUrl(fieldValue)){
              if(validationType=='form'){
                fieldStates['error_'+fieldId] = 'has-danger';
                fieldStates['error_message_'+fieldId] = 'Please enter a valid url. For example.(https://example.com).';
              }else{
                  setFieldStates({
                    ...fieldStates,
                    ['error_'+fieldId]: 'has-danger',
                    ['error_message_'+fieldId]: 'Please enter a valid url. For example.(https://example.com).',
                  });
              }
              isValid = false;
            }else{
              if(validationType=='form'){
                fieldStates['error_'+fieldId] = 'has-success';
                fieldStates['error_message_'+fieldId] = '';
              }else{
                setFieldStates({
                  ...fieldStates,
                  ['error_'+fieldId]: 'has-success',
                  ['error_message_'+fieldId]: '',
                });
              }
            }
        }

        if(isValid && fieldDesign.type=='integer'){
          if(fieldValue!='' && !formValidations.verifyNumber(fieldValue)){
            if(validationType=='form'){
              fieldStates['error_'+fieldId] = 'has-danger';
              fieldStates['error_message_'+fieldId] = 'Please enter a valid number.';
            }else{
                setFieldStates({
                  ...fieldStates,
                  ['error_'+fieldId]: 'has-danger',
                  ['error_message_'+fieldId]: 'Please enter a valid number.',
                });

            }
            isValid = false;
          }else{
            if(validationType=='form'){
              fieldStates['error_'+fieldId] = 'has-success';
              fieldStates['error_message_'+fieldId] = '';
            }else{
              setFieldStates({
                ...fieldStates,
                ['error_'+fieldId]: 'has-success',
                ['error_message_'+fieldId]: '',
              });
            }
          }
        }

      if(isValid && fieldDesign.type=='enum'){
        console.log(fieldValue);
        console.log(fieldDesign.type_items);
        if(!fieldValue){
                fieldValue = ''
        }
        if(fieldValue!='' && !formValidations.checkValueInArray(fieldValue, fieldDesign.type_items, fieldDesign.type)){
          if(validationType=='form'){
            fieldStates['error_'+fieldId] = 'has-danger';
            fieldStates['error_message_'+fieldId] = 'Please enter a valid value.';
          }else{
              setFieldStates({
                ...fieldStates,
                ['error_'+fieldId]: 'has-danger',
                ['error_message_'+fieldId]: 'Please enter a valid value.',
              });
          }
          isValid = false;
        }else{
          if(validationType=='form'){
            fieldStates['error_'+fieldId] = 'has-success';
            fieldStates['error_message_'+fieldId] = '';
          }else{
            setFieldStates({
              ...fieldStates,
              ['error_'+fieldId]: 'has-success',
              ['error_message_'+fieldId]: '',
            });
          }
        }
      }

      if(isValid && fieldDesign.source=='category_list'){
        console.log(fieldValue);
        console.log(fieldDesign.type_items);
        if(fieldValue!='' && !formValidations.checkValueInArray(fieldValue, fieldDesign.type_items, fieldDesign.type)){
          if(validationType=='form'){
            fieldStates['error_'+fieldId] = 'has-danger';
            fieldStates['error_message_'+fieldId] = 'Please enter a valid value.';
          }else{
              setFieldStates({
                ...fieldStates,
                ['error_'+fieldId]: 'has-danger',
                ['error_message_'+fieldId]: 'Please enter a valid value.',
              });
          }
          isValid = false;
        }else{
          if(validationType=='form'){
            fieldStates['error_'+fieldId] = 'has-success';
            fieldStates['error_message_'+fieldId] = '';
          }else{
            setFieldStates({
              ...fieldStates,
              ['error_'+fieldId]: 'has-success',
              ['error_message_'+fieldId]: '',
            });
          }
        }
      }

        return isValid;
    }
    return <>
    <div className='content'>
    
    {fieldSchema  && props.featureCode?
        <Card className="card-signup text-center sub-card">
                  <CardHeader>
                    <CardTitle tag="h4">{props.featureName}</CardTitle>
                  </CardHeader>
                  <CardBody>
                    {/* <div> */}
                      <FeatureConfigForm 
                          configFormSchema = {fieldSchema}
                          formdetails ={formdetails}
                          setFormDetails = {setFormDetails}
                          featureCode = {props.featureCode}
                          fieldStates = {fieldStates}

                    />
                   
                        
                    {/* </div> */}
                  </CardBody>
                  <CardFooter>
                    <Row align="center">
                      
                       <Col md="12" >
                        
                        <Button className="btn-round btn-smb" onClick={handleSubmit}>Save</Button> &nbsp;&nbsp;
                        <Button className="btn-round  formCancelBtn" onClick={handleCancel}>Cancel</Button>
                      </Col>
                   </Row>
                      
                  </CardFooter>
                </Card>
       
       :""}
       
    </div>
    
    </>
}

export default FeatureConfig;
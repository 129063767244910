import axios from "axios";

const createWebpageCategory = async (formdata) => {
    const response = await axios.post('/api/content/create-webpage-category', formdata);
    return response.data;
}
const updateCategory = async(formdata) =>{
    const response = await axios.post('/api/content/update-category',formdata);
    return response.data;
}
const updateCategoryMeta = async (formdata) =>{
    const response = await axios.post('/api/content/update-category-meta',formdata);
    return response.data;
}

const getCategories = async(projectId) =>{
    const formdata = {
        projectId : projectId
    }
    const response = await axios.post('/api/content/get-categories',formdata);
    return response.data;
}

const getTaxonomies = async(formdetails,projectId,activePage, records) => {
    const formdata = {
        formdetails,
        projectId : projectId,
        activePage, 
        records
    }
    const response = await axios.post('/api/content/get-taxonomies',formdata);
    return response.data;
}
const getCategoryDetails= async(categoryId) =>{
    const formdata = {
        categoryId : categoryId
    }
    const response = await axios.post('/api/content/get-category-details',formdata);
    return response.data;
}

const reorderCategories = async(formdata) =>{
    const response = await axios.post('/api/content/reorder-categories',formdata);
    return response.data;
}
const removeCategory = async(formdata) =>{
    const response = await axios.post('/api/content/remove-category',formdata);
    return response.data;
}

const getArticles = async(projectId,formdetails,page,records) =>{
    const formdata = {
        projectId : projectId,
        filters : formdetails,
        page : page,
        records : records
    }
    const response = await axios.post('/api/content/get-articles',formdata);
    return response.data;
}

const getTotalArticles = async(projectId, formdetails) =>{
    const formdata = {
        projectId : projectId,
        filters : formdetails
    }
    const response = await axios.post('/api/content/get-total-articles',formdata);
    return response.data;
}

const deleteArticle = async(articleId,projectId) =>{
    const formdata = {
        articleId : articleId,
        projectId : projectId
    }
    const response = await axios.post('/api/content/delete-article',formdata);
    return response.data;
}

const restoreArticle = async(articleId,projectId) =>{
    const formdata = {
        articleId : articleId,
        projectId : projectId
    }
    const response = await axios.post('/api/content/restore-article',formdata);
    return response.data;
}

const getDailyInsertedArticles = async(projectId) =>{
    const formdata = {
        projectId : projectId
    }
    const response = await axios.post('/api/content/get-daily-inserted-articles',formdata);
    return response.data;
}

const getArticlesTypeCount = async(projectId) =>{
    const formdata = {
        projectId : projectId
    }
    const response = await axios.post('/api/content/get-articles-type-count',formdata);
    return response.data;
}
const getWPWebstories =  async(filters,page,records) =>{
    const formdata = {
        filters,
        page,
        records
    }
    const response = await axios.post('/api/content/get-wp-webstories',formdata);
    return response.data;
}

const getArticleDetail = async(data)=>{
    const response = await axios.post('/api/content/get-article-details', data);
    return response.data;
}

const resyncArticle = async (data)=>{
    const response = await axios.post('/api/content/resync-article',data);
    return response.data;
}

export  default {
    createWebpageCategory,
    getCategories,
    getCategoryDetails,
    updateCategory,
    updateCategoryMeta,
    reorderCategories,
    removeCategory,
    getArticles,
    getTotalArticles,
    deleteArticle,
    restoreArticle,
    getDailyInsertedArticles,
    getArticlesTypeCount,
    getWPWebstories,
    getArticleDetail,
    resyncArticle,
    getTaxonomies,
}
import React, { useContext, useEffect, useState } from 'react'
import ProjectContext from '../../ProjectContext'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ctvAppService from '../../services/api/ctv-app-service';
import { CardBody, CardHeader, CardTitle, Spinner, Form, Row, Col, Table } from 'reactstrap';
import moment from "moment";
import Select from 'react-select'
import customStyles from '../../components/CustomStyles';
import Pagination from "react-js-pagination";

const ImporterLogs = (props) => {
    const projectDetails = useContext(ProjectContext);
    const history = useHistory();
    if (projectDetails.id == "") {
        history.push("/project/allprojects");
    }
    const projectId = projectDetails.id;
    const importerId = new URLSearchParams(props.location.search).get("importer-id")
    if (!importerId) {
        history.push(`/ctv/dashboard?project=${projectDetails.slug}#importer`);
    }
    const [formdetails, setFormDetails] = useState({ project_id: projectId, status: "", importer_id: importerId })
    const [activePage, setActivePage] = useState(1)
    const records = 10;
    const [renderComponent, setRenderComponent] = useState(0)
    const [logsData, setLogsData] = useState([])
    const [totalLogs, setTotalLogs] = useState(0);

    const statusOptions = [
        { value: "", label: "All" },
        { value: "2", label: "Success" },
        { value: '-1', label: "Fail" },
    ]
    const statusLookUp = {
        0: "start",
        1: "processing",
        2: "success",
        [-1]: "fail"
    }
    useEffect(() => {
        (async () => {
            setRenderComponent(false)
            const logsList = await ctvAppService.getImporterLogsList({
                ...formdetails,
                activePage,
                records
            })
            if (logsList.status && logsList.data) {
                setLogsData(logsList.data.logsList)
                setTotalLogs(logsList.data.count)
            }
            setRenderComponent(true)
        })();
    }, [activePage, formdetails.status])


    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
    }

    let logElements = []
    let firstRecord = 0;
    let lastRecord = 0;
    if (!logsData || !logsData.length) {
        if (!renderComponent) {
            logElements.push(
                <tr>
                    <td colSpan="12" className="text-center">
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </td>
                </tr>
            )
        }
        else {
            logElements.push(
                <td colSpan="12" className="text-center">
                    <CardTitle tag="h4" className="boRslt">No Logs Found</CardTitle>
                </td>
            )
        }
    }
    else {
        let i = 1;
        logsData.forEach(function (logDetails) {
            const createdAtTime = moment(logDetails.createdAt)
            let className = "";
            if (!logDetails.status) {
                className = "table-danger"
            }

            if (i == 1) {
                firstRecord = (activePage - 1) * records + i;
            }
            logElements.push(
                <tr className={className}>
                    <td className="text-left">{firstRecord - 1 + i}</td>
                    <td className='text-left'>{logDetails.importer_id.name}</td>

                    <td className='text-left'>{logDetails.created_by}</td>
                    <td className='text-left'>
                        {logDetails.categories.map(data => (
                            <>
                                {data.name}<br />
                            </>

                        ))}
                    </td>
                    <td className='text-left'>{logDetails.total_videos}</td>
                    <td className='text-left'>{logDetails.inserted_videos}</td>
                    <td className='text-left'>{logDetails.updated_videos}</td>
                    <td className='text-left'>
                        {createdAtTime.format('h:mm:ss a')}<br />
                        {createdAtTime.format('Do MMM YYYY')}
                    </td>
                    <td className='text-left'>{statusLookUp[logDetails.status]}</td>

                </tr>
            )
            if (i === logsData.length) {
                lastRecord = (activePage - 1) * records + i;
            }
            i++;
            if (i === logsData.length) {
                lastRecord = (activePage - 1) * records + i;
            }

        })
    }
    return (
        <div className='container mt-30'>
            <CardHeader>
                <div className="ctvLibaryHead">
                    <h4>Importer Logs </h4>
                    {/* <div class="underLine"></div> */}
                </div>
            </CardHeader>
            <CardBody>
                <Form className="form" methods="POST" onSubmit={(e) => e.preventDefault}>
                    <Row>
                        <Col md="3">
                            <Select
                                className="react-select primary"
                                value={statusOptions.filter(option => option.value == formdetails.status)}
                                onChange={(e) => setFormDetails({ ...formdetails, status: e.value })}
                                placeholder="Select Status"
                                options={statusOptions}
                                styles={customStyles}
                            />
                        </Col>
                    </Row>
                </Form>
                <hr />
                <Row>
                    <Col>
                        <div className='pagNo'>
                            <b>
                                {firstRecord} - {lastRecord} of {totalLogs}
                            </b>
                        </div>
                    </Col>
                    <Col>
                        <div className="pagBox">
                            <div className="pagDt">
                                <Pagination
                                    activePage={activePage}
                                    itemsCountPerPage={records}
                                    totalItemsCount={totalLogs}
                                    pageRangeDisplayed={3}
                                    onChange={handlePageChange.bind(this)}
                                    itemClass="page-item"
                                    linkClass="page-link"
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Table responsive hover>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>Created By</th>
                                    <th>Categories</th>
                                    <th>Total Videos</th>
                                    <th>New Videos</th>
                                    <th>Updated Videos</th>
                                    <th>Created On</th>
                                    <th>Status</th>

                                </tr>
                            </thead>
                            <tbody>
                                {logElements}
                            </tbody>
                        </Table>

                    </Col>
                </Row>

            </CardBody>
        </div>

    )
}

export default ImporterLogs
const config = {
    home: {
        level_id: 0,
        label: "Home Screen",
        type_items: [{
            type: {
                level_id: 2,
                type: "string",
                label: "Type",
                default: "",
                required: true
            },
            title: {
                level_id: 2,
                type: "string",
                label: "Title",
                default: "",
                required: true,
            },
            url: {
                level_id: 2,
                type: "url",
                label: "URL",
                default: "",
                required: true,
            },
            thumbnail: {
                level_id: 2,
                type: "file_upload",
                label: "Thumbnail",
                file_type: "image/png,image/jpeg,image/jpg,image/webp,image/tiff,image/x-icon"
            }
        }]
    }
}

module.exports = {
    config,
}
import GetStarted from "./views/onboard/GetStarted";
import CreateProject from "./views/onboard/CreateProject";
import LicenseInfo from "./views/onboard/LicenseInfo";

import Plan from "./views/plan/Plans";
var routes = [
  {
    path: "/get-started",
    name: "Welcome",
    mini: "R",
    component: GetStarted,
    layout: "/onboard",
  },
  {
    path: "/welcome",
    name: "Welcome",
    mini: "R",
    component: GetStarted,
    layout: "/onboard",
  },
  {
    path: "/plan-details",
    name: "Plan Details",
    mini: "R",
    component: Plan,
    layout: "/plans",
  },
  
  
  // {
  //   path: "/create-project",
  //   name: "Create Project",
  //   mini: "R",
  //   component: CreateProject,
  //   layout: "/onboard",
  // },
  // {
  //   path: "/license-details",
  //   name: "License",
  //   mini: "R",
  //   component: LicenseInfo,
  //   layout: "/onboard",
  // }
];
export default routes;

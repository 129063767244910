import React, { useEffect, useState } from "react";
import formValidations from "../../variables/form-validations";
import authService from "../../services/api/auth-service.js";
import {useHistory} from "react-router-dom";
import Otp from "./Otp.js";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row,
} from "reactstrap";

const ForgotPassword = (props)=> {

    const history = new useHistory();
    const [status, setStatus] = useState("forgotpassword");
    const [details, setDetails] = useState({email:"",authtoken:"",otp:""});
    const [emailstate, setEmailState] = useState("");
    const [emailerror, setEmailError] = useState("");
    const [errorstate, setErrorState] = useState('has-success');
    const [errormessage, setErrorMessage] = useState('There was an error. Please try again.');


    useEffect(() => {
        //document.body.classList.toggle("login-page");
        document.body.className = "register-page";
    }, []);
  
    const handleChange = (event) => {
        setDetails({
            ...details,
            [event.target.id]: ( event.target.value ).trim(),
        });
    }
    
    const handleBlur = (event) => {
        setDetails({
            ...details,
            [event.target.id]: ( event.target.value ).trim(),
        });
    
        // if(event.target.id=='email'){
        //     validatePublisherEmail();
        // }
    
    }

    const validatePublisherEmail = () => {
        if(details.email===''){
          setEmailState("has-danger");
          setEmailError("This field is required");
          return false;
        }
  
        if(!formValidations.verifyEmail(details.email)){
          setEmailState("has-danger");
          setEmailError("Please enter a valid email.");
          return false;
        }
  
        setEmailState("has-success");
        return true;
    }

    const validateOtp = async () => {
  
        try {
          const response = await authService.validateOtp(details);
          if(response.status && response.data.otp_verified){
            //history.push("/auth/password");
            props.setCurrentState("password");
          }else{
            setErrorState("has-danger");
            setErrorMessage(response.error.message);
          }
          
        } catch (err) {}
      }
    
      const resendOtp = async () => {
      
        try {
          const response = await authService.resendOtp(details);
          if(response.status){
            setErrorState("has-success");
          }else{
            setErrorState("has-danger");
            setErrorMessage(response.error.message);
          }
          
        } catch (err) {}
      }

    const handleSubmit = function(e){  validateForm();e.preventDefault() }
    
    const validateForm= async () =>{
        let emailvalidation =  validatePublisherEmail();
        if(emailvalidation){
          try {
            const response = await authService.validateEmailAndSendOtp(details);
            if(response.status){
              setDetails({
                    ...details,
                    authtoken: response.data.auth_token,
                });
                setErrorState("has-success");
                setStatus("otp");
            }else{
              setErrorState("has-danger");
              setErrorMessage(response.error.message);
            }
            
          } catch (err) {}
        }
        return false;
    }

    if(status=='otp'){
        return (
            <Otp validateOtp={validateOtp} resendOtp={resendOtp} details={details} setDetails={setDetails} errorstate={errorstate} errormessage={errormessage}/>
          );
    }else{
        return (
            <Row>
                <Col className="ms-auto me-auto" lg="10" md="10">
                <Form id="loginform" action="" className="form" method="POST" onSubmit={handleSubmit}>
                    <Card className="card-login text-center">
                    <CardHeader>
                        <CardHeader>
                        <h3 className="header">Enter email to reset password</h3>
                        </CardHeader>
                    </CardHeader>
                    <CardBody>
                    <InputGroup className={emailstate}>
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                            <i className="nc-icon nc-email-85" />
                            </InputGroupText>
                        </InputGroupAddon>
                        <Input id="email" name="email" placeholder="Email..." type="text"  onChange={handleChange} onBlur={handleBlur}/>
                        {emailstate === "has-danger" ? (
                                <label className="error">
                                {emailerror}
                                </label>
                            ) : null} 
                        </InputGroup>


                        <InputGroup className={errorstate}>
                        {errorstate === "has-danger" ? (
                                <label className="error">
                                {errormessage}
                                </label>
                            ) : null}   
                            </InputGroup>  

                        <br />

                    </CardBody>
                    <CardFooter>
                        <Button
                        block
                        className="btn-round mb-3"
                        color="warning"
                        type="button"
                        onClick={validateForm}
                        > 
                        Reset Password
                        </Button>
                    </CardFooter>
                    </Card>
                </Form>
                </Col>
            </Row>
            
        );
    }
  
}

export default ForgotPassword;

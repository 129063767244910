import React, { useEffect, useState, useContext } from "react";
import featuresService from '../../../services/api/features-service';
import ProjectContext from '../../../ProjectContext';
import { useHistory } from "react-router-dom";

import {
    Card,
    CardTitle,
    CardHeader,
    CardBody,
    Row,
    Col,
    Button,
    Label,
    Nav,
    NavItem,
    NavLink,
} from "reactstrap";

import '../ctv.css'

const Navbar = (props) => {
    const [isCTVAppsEnabled, setIsCTVAppsEnabeld] = useState(false);
    const history = new useHistory();
    const projectDetails = useContext(ProjectContext);
    const projectId = projectDetails.id;
    const activeTab = props.activeTab;
    
    return (
        // <div className="content mt-10">
        <div className="container mt-30">
            <div className="ctvnavbar">
                <Nav>
                    <NavItem>
                        <NavLink className={`btnBlue ${activeTab === "category" ? 'active': ""}`} href="#category" onClick={(event) => props.updateActiveTab("category")}>
                            Manage Categories
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={`btnBlue ${activeTab === "importer" ? 'active': ""}`} href="#importer" onClick={(event) => props.updateActiveTab("importer")}>
                            Videos Importer
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={`btnBlue ${activeTab === "videos" ? 'active': ""}`} href="#videos" onClick={(event) => props.updateActiveTab("videos")}>
                            Videos
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={`btnBlue ${activeTab === "design" ? 'active': ""}`} href="#design" onClick={(event) => props.updateActiveTab("design")}>
                            Manage Design
                        </NavLink>
                    </NavItem>
                </Nav>

            </div>

        </div>

        // </div>
    )
}

export default Navbar;
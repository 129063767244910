import React, { useEffect, useState, useContext } from "react";
import ProjectContext from "../../../ProjectContext";
import ConfigVerticalMenu from "./ConfigVerticalMenu";
import customizationsService from "../../../services/api/customizations-service";
import projectsService from "../../../services/api/projects-service";
import contentService from "../../../services/api/content-service";
import generalService from '../../../services/api/sortd-apps/general-service';
import ConfigForm from "./ConfigForm";
import {useHistory} from "react-router-dom";
import formValidations from "../../../variables/form-validations";
import QRCode from 'qrcode';
import ReactBSAlert from "react-bootstrap-sweetalert";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Spinner,
  Alert,
  Row,
  Col,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
} from "reactstrap";




const Customizations = (props)=> {
    const projectDetails = useContext(ProjectContext);
    const history = new useHistory();
    if (projectDetails.id == "") {
        history.push("/project/allprojects");
    }
    const projectId = projectDetails.id;

    // console.log("Props.location",props.location);
    const version = new URLSearchParams(props.location.search).get('version');
    // console.log("Version:",version);
    if(!version) {
      history.push(`/apps/config/versions?project=${projectDetails.slug}`);
    }
    const [projectName, setProjectName] = useState("");
    const [horizontalTab, setHorizontalTab] = useState('');
    const [configSchema, setConfigSchema]   = useState(false);
    const [currentTabConfig, setCurrentTabConfig]   = useState(false);
    const [savedConfig, setSavedConfig]   = useState(false);
    const [showLoader, setShowLoader]   = useState(false);
    const [showSaveButton, setShowSaveButton]   = useState(true);
    const [alert, setAlert]   = useState({show:false,message:'',type:''});
    const [categories, setCategories] = useState([]);
    const [projects,setProjects] = useState([]); 
    const [fieldStates,setFieldStates] = useState(false);
    const [allFieldStates,setAllFieldStates] = useState(false);
    const [formFields,setFormFields] = useState(false);
    const [currentFormFields,setCurrentFormFields] = useState(false);
    const [qrcodeLink , setQRCodeLink] = useState("");
    const [packageName, setPackageName] = useState("");
    const [showQRPopup, setShowQRPopup] = useState(false);
    const generateQR = async text => {
      try {
        setQRCodeLink(await QRCode.toDataURL(text,{ errorCorrectionLevel: 'H' }));
      } catch (err) {
        console.error(err)
      }
    }
    const urlParams = new URLSearchParams(window.location.search);
    const initialTab = urlParams.get('tab');
    useEffect(()=>{
      if(initialTab){
        setHorizontalTab(initialTab)
      }else{
        setHorizontalTab('app')
      }
    },[])

    useEffect (()=>{
      (async ()=>{
        let getProjectDetails = await projectsService.getProjectDetails(projectId);
            if(getProjectDetails.status && getProjectDetails.data){
                setProjectName(getProjectDetails.data.name);
            }
        let getAppsGeneralSettingsDetails = await generalService.getProjectAppsGeneralSettings(projectId);
        if(getAppsGeneralSettingsDetails.status){
            if(getAppsGeneralSettingsDetails.data){
              setPackageName(getAppsGeneralSettingsDetails.data.android_app_package_name);
            }
        }

        let projectData  = await projectsService.getPublisherMultiProjects(projectId);
          if(projectData.status){
            let allProjectData = projectData.data;
            let projectArray =[];
            if(allProjectData.length){
              allProjectData.forEach(function(projectDetails){
                projectArray.push({value:projectDetails.slug,label:`${projectDetails.name} (${projectDetails.slug})`})
              })
              setProjects(projectArray)
            }
          }

      })();
    },[])

    useEffect(()=>{
      if(packageName  && savedConfig.data && savedConfig.data.version) {
        generateQR(packageName+"/"+savedConfig.data.version);
        // console.log(packageName+"/"+savedConfig.data.version);
      }
    },[packageName,savedConfig]);

    useEffect( () => {
      (async () =>{
        
        if(projectId && horizontalTab){
          let schema = await customizationsService.getAppConfigSchema();
          setConfigSchema(schema.data);
          if (!(schema.data.config).hasOwnProperty(horizontalTab)) {
            setHorizontalTab('app')
          };

          let config = await customizationsService.getAppSavedConfig(projectId,version);
          if(config.status && Object.keys(config.data).length !== 0) {
              setSavedConfig(config);
              let currentConfig = JSON.parse(config.data[horizontalTab]?config.data[horizontalTab]:null);
              setCurrentTabConfig(currentConfig);
              let categoryData = await contentService.getCategories(projectId);
              if(categoryData.status){
                  let allCategories = categoryData.data;
                  let categoryArray = [];
                  if(allCategories.length){
                    allCategories.forEach(function(categoryDetails) {
                      categoryArray.push({value:categoryDetails.cat_guid,label:categoryDetails.name});
                      if(categoryDetails.sub_categories && categoryDetails.sub_categories.length){
                        let subCategories = categoryDetails.sub_categories;
                        subCategories.forEach(function(subCategoryDetails) {
                          categoryArray.push({value:subCategoryDetails.cat_guid,label:subCategoryDetails.name});
                        })
                      }
                    })
                    setCategories(categoryArray);
                  }
              }
        }else{
          console.log("Invalid config Version or you can not edit this config version");
        }
      }

      })();
    }, [horizontalTab]);


    //for setting form fields validations
    useEffect(()=>{
      // console.log("horizontol");
      // console.log(currentTabConfig);
      
      if(configSchema && savedConfig){
            let fieldStatesData = {};let formFieldsData = {};
            Object.keys(configSchema).forEach(function(key) {
              Object.keys(configSchema[key]).forEach(function(horizontolmenukey) {
                  let menuStateData = {};
                  let allFormFields = {};
                  var submenu = configSchema[key][horizontolmenukey].items;
                  Object.keys(submenu).forEach(function(submenukey) {
                    if(submenu[submenukey].items){
                      let submenufields = submenu[submenukey].items;
                      Object.keys(submenufields).forEach(function(submenufieldkey) {
                          if(submenufields[submenufieldkey].items){
                            let subheads = submenufields[submenufieldkey].items;
                            Object.keys(subheads).forEach(function(subheadkey) {
                              let elementErrorClass = "error_"+submenukey+":"+submenufieldkey+":"+subheadkey;
                              let elementErrorMessageClass =  "error_message_"+submenukey+":"+submenufieldkey+":"+subheadkey;
                              menuStateData[elementErrorClass] = 'has-success';
                              menuStateData[elementErrorMessageClass] = '';
                              allFormFields[submenukey+":"+submenufieldkey+":"+subheadkey] = subheads[subheadkey];
                              let fieldDesign = subheads[subheadkey];
                              if(fieldDesign.type=='multi-input' && horizontolmenukey==horizontalTab){
                                  if(currentTabConfig[submenukey][submenufieldkey][subheadkey]){
                                    let multiInputFieldValue = currentTabConfig[submenukey][submenufieldkey][subheadkey];
                                    Object.keys(multiInputFieldValue).forEach(function(multiInputFieldIndex) {
                                      Object.keys(multiInputFieldValue[multiInputFieldIndex]).forEach(function(multiInputFieldName) {
                                        let multiInputValue = multiInputFieldValue[multiInputFieldIndex][multiInputFieldName];
                                        let multiElementErrorClass = elementErrorClass+":"+multiInputFieldIndex+":"+multiInputFieldName;
                                        let multiElementErrorMessageClass =  elementErrorMessageClass+":"+multiInputFieldIndex+":"+multiInputFieldName;
                                        if(!fieldStates[multiElementErrorClass]){
                                          menuStateData[multiElementErrorClass] = 'has-success';
                                          menuStateData[multiElementErrorMessageClass] = '';
                                        }else{
                                          menuStateData[multiElementErrorClass] = fieldStates[multiElementErrorClass];
                                          menuStateData[multiElementErrorMessageClass] = fieldStates[multiElementErrorMessageClass];
                                        }
                                        allFormFields[submenukey+":"+submenufieldkey+":"+subheadkey+":"+multiInputFieldIndex+":"+multiInputFieldName] = fieldDesign.type_items[0][multiInputFieldName];
                                      })
                                    })
                                  }
                              }
                            })

                          }else{
                            let elementErrorClass = "error_"+submenukey+":"+submenufieldkey;
                            let elementErrorMessageClass =  "error_message_"+submenukey+":"+submenufieldkey;
                            menuStateData[elementErrorClass] = 'has-success';
                            menuStateData[elementErrorMessageClass] = '';
                            allFormFields[submenukey+":"+submenufieldkey] = submenufields[submenufieldkey];
                            let fieldDesign = submenufields[submenufieldkey];
                            if(fieldDesign.type=='multi-input' && horizontolmenukey==horizontalTab){
                                if(currentTabConfig[submenukey] && currentTabConfig[submenukey][submenufieldkey]){
                                  let multiInputFieldValue = currentTabConfig[submenukey][submenufieldkey];
                                  Object.keys(multiInputFieldValue).forEach(function(multiInputFieldIndex) {
                                    Object.keys(multiInputFieldValue[multiInputFieldIndex]).forEach(function(multiInputFieldName) {
                                      let multiInputValue = multiInputFieldValue[multiInputFieldIndex][multiInputFieldName];
                                      let multiElementErrorClass = elementErrorClass+":"+multiInputFieldIndex+":"+multiInputFieldName;
                                      let multiElementErrorMessageClass =  elementErrorMessageClass+":"+multiInputFieldIndex+":"+multiInputFieldName;
                                      
                                      if(!fieldStates[multiElementErrorClass]){
                                        menuStateData[multiElementErrorClass] = 'has-success';
                                        menuStateData[multiElementErrorMessageClass] = '';
                                      }else{
                                        menuStateData[multiElementErrorClass] = fieldStates[multiElementErrorClass];
                                        menuStateData[multiElementErrorMessageClass] = fieldStates[multiElementErrorMessageClass];
                                      }
                                      
                                      allFormFields[submenukey+":"+submenufieldkey+":"+multiInputFieldIndex+":"+multiInputFieldName] = fieldDesign.type_items[0][multiInputFieldName];
                                    })
                                  })
                                }
                            }
                          }
                      })
                    }else{
                        let elementErrorClass = "error_"+submenukey;
                        let elementErrorMessageClass =  "error_message_"+submenukey;
                        menuStateData[elementErrorClass] = 'has-success';
                        menuStateData[elementErrorMessageClass] = '';
                        allFormFields[submenukey] = submenu[submenukey];
                        let fieldDesign = submenu[submenukey];
                        if(fieldDesign.type=='multi-input' && horizontolmenukey==horizontalTab){
                            if(currentTabConfig[submenukey]){
                              let multiInputFieldValue = currentTabConfig[submenukey];
                              Object.keys(multiInputFieldValue).forEach(function(multiInputFieldIndex) {
                                Object.keys(multiInputFieldValue[multiInputFieldIndex]).forEach(function(multiInputFieldName) {
                                  let multiInputValue = multiInputFieldValue[multiInputFieldIndex][multiInputFieldName];
                                  let multiElementErrorClass = elementErrorClass+":"+multiInputFieldIndex+":"+multiInputFieldName;
                                  let multiElementErrorMessageClass =  elementErrorMessageClass+":"+multiInputFieldIndex+":"+multiInputFieldName;
                                  if(!fieldStates[multiElementErrorClass]){
                                    menuStateData[multiElementErrorClass] = 'has-success';
                                    menuStateData[multiElementErrorMessageClass] = '';
                                  }else{
                                    menuStateData[multiElementErrorClass] = fieldStates[multiElementErrorClass];
                                    menuStateData[multiElementErrorMessageClass] = fieldStates[multiElementErrorMessageClass];
                                  }
                                  allFormFields[submenukey+":"+multiInputFieldIndex+":"+multiInputFieldName] = fieldDesign.type_items[0][multiInputFieldName];
                                })
                              })
                            }
                        }
                    }
                  });
                  fieldStatesData[horizontolmenukey] = menuStateData;
                  formFieldsData[horizontolmenukey] = allFormFields;
              })
            })
            setFieldStates(fieldStatesData[horizontalTab]);
            setAllFieldStates(fieldStatesData);
            setFormFields(formFieldsData);
            setCurrentFormFields(formFieldsData[horizontalTab]);
      }
    },[currentTabConfig])


    // console.log(currentFormFields);
    const storeConfig = async () => {
      if(validateConfig()){
        setShowLoader(true);
        setShowSaveButton(false);
        // console.log(currentTabConfig);
        let saveConfig = await customizationsService.storeAppConfig(projectId, version ,horizontalTab, currentTabConfig);
        if(projectDetails.isContractExpired){
          setShowLoader(false);
          setShowSaveButton(true);
          projectDetails.setContractExpiredAlert(projectDetails);
        }
        else if(saveConfig.status){
          setTimeout(()=>{
            setShowLoader(false)
            setShowSaveButton(true)
            setAlert({show:true,type:'success',message:'Saved Successfully'});
          },1000);
  
          setTimeout(()=>{
            setAlert({show:false,type:'',message:''});
          },3000);
        }
      }
    }

    const validateConfig = () => {
      let validated = true;
      let firstInvalidFieldId = '';
      Object.keys(currentFormFields).forEach(function(fieldId) {
        let fieldIdArray = fieldId.split(":");
        let fieldValue = '';
        // console.log("currentTabConfig:",currentTabConfig);
        if(fieldIdArray.length==5){
          // console.log("5")
          // if(!currentTabConfig[fieldIdArray[0]]){
          //     currentTabConfig[fieldIdArray[0]] = {}
          // }
          // if(!currentTabConfig[fieldIdArray[0]][fieldIdArray[1]]){
          //     currentTabConfig[fieldIdArray[0]][fieldIdArray[1]] ={}
          // }
          // if(!currentTabConfig[fieldIdArray[0]][fieldIdArray[1]][fieldIdArray[2]]){
          //     currentTabConfig[fieldIdArray[0]][fieldIdArray[1]][fieldIdArray[2]] ={}
          // }
          // if(!currentTabConfig[fieldIdArray[0]][fieldIdArray[1]][fieldIdArray[2]][fieldIdArray[3]]){
          //     currentTabConfig[fieldIdArray[0]][fieldIdArray[1]][fieldIdArray[2]][fieldIdArray[3]] ={}
          // }
          fieldValue = currentTabConfig[fieldIdArray[0]][fieldIdArray[1]][fieldIdArray[2]][fieldIdArray[3]][fieldIdArray[4]];
        }else if(fieldIdArray.length==4){
          // console.log("4")

          // if(!currentTabConfig[fieldIdArray[0]]){
          //     currentTabConfig[fieldIdArray[0]] = {}
          // }
          // if(!currentTabConfig[fieldIdArray[0]][fieldIdArray[1]]){
          //     currentTabConfig[fieldIdArray[0]][fieldIdArray[1]] ={}
          // }
          // if(!currentTabConfig[fieldIdArray[0]][fieldIdArray[1]][fieldIdArray[2]]){
          //     currentTabConfig[fieldIdArray[0]][fieldIdArray[1]][fieldIdArray[2]] ={}
          // }
          fieldValue = currentTabConfig[fieldIdArray[0]][fieldIdArray[1]][fieldIdArray[2]][fieldIdArray[3]];
        }else if(fieldIdArray.length==3){
          // console.log("3", fieldIdArray )

          if(!currentTabConfig[fieldIdArray[0]]){
              currentTabConfig[fieldIdArray[0]] = {}
          }
          if(!currentTabConfig[fieldIdArray[0]][fieldIdArray[1]]){
              currentTabConfig[fieldIdArray[0]][fieldIdArray[1]] ={}
          }

          fieldValue = currentTabConfig[fieldIdArray[0]][fieldIdArray[1]][fieldIdArray[2]];
        }else if(fieldIdArray.length==2 && currentTabConfig[fieldIdArray[0]]){
          // console.log("2")
          // if(!currentTabConfig[fieldIdArray[0]]){
          //   currentTabConfig[fieldIdArray[0]]={};
          // }
          fieldValue = currentTabConfig[fieldIdArray[0]][fieldIdArray[1]];
        }else{
          fieldValue = currentTabConfig[fieldId];
        }
        if(!validateConfigField(fieldId, fieldValue, currentFormFields[fieldId],'form')){
          if(firstInvalidFieldId==''){
            firstInvalidFieldId = fieldId;
          }
          validated = false;
        }
      });
      if(!validated){
        setFieldStates({
                ...fieldStates,
                fieldStates
        });

        // console.log("firstInvalidFieldId",firstInvalidFieldId)

        // console.log(document.getElementById(firstInvalidFieldId))
        if(document.getElementById(firstInvalidFieldId)){
          document.getElementById(firstInvalidFieldId).scrollIntoView({ behavior: "auto" });;
        }
      }
      return validated;
    }
    const validateEmail = (fieldId, fieldValue, fieldDesign, validationType)=>{
      let isValid = true;
      if(fieldValue!='' && !formValidations.verifyEmail(fieldValue)){
              if(validationType=='form'){
                fieldStates['error_'+fieldId] = 'has-danger';
                fieldStates['error_message_'+fieldId] = 'Please enter a valid email.';
              }else{
                  setFieldStates({
                    ...fieldStates,
                    ['error_'+fieldId]: 'has-danger',
                    ['error_message_'+fieldId]: 'Please enter a valid email.',
                  });
              }
              isValid = false;
            }else{
              if(validationType=='form'){
                fieldStates['error_'+fieldId] = 'has-success';
                fieldStates['error_message_'+fieldId] = '';
              }else{
                setFieldStates({
                  ...fieldStates,
                  ['error_'+fieldId]: 'has-success',
                  ['error_message_'+fieldId]: '',
                });
              }
            }
            return isValid;
    }
    const validatePhone = (fieldId, fieldValue, fieldDesign, validationType)=>{
      let isValid = true;
      if(fieldValue!='' && !formValidations.verifyPhoneNumber(fieldValue)){
              if(validationType=='form'){
                fieldStates['error_'+fieldId] = 'has-danger';
                fieldStates['error_message_'+fieldId] = 'Please enter a valid phone number.';
              }else{
                  setFieldStates({
                    ...fieldStates,
                    ['error_'+fieldId]: 'has-danger',
                    ['error_message_'+fieldId]: 'Please enter a valid phone number.',
                  });
              }
              isValid = false;
            }else{
              if(validationType=='form'){
                fieldStates['error_'+fieldId] = 'has-success';
                fieldStates['error_message_'+fieldId] = '';
              }else{
                setFieldStates({
                  ...fieldStates,
                  ['error_'+fieldId]: 'has-success',
                  ['error_message_'+fieldId]: '',
                });
              }
            }
            return isValid;
    
    }
    
    const validateConfigField = (fieldId, fieldValue, fieldDesign, validationType) =>{
        let isValid = true;
        let fieldIdArray = fieldId.split(":");      
      if(fieldDesign.parent_field && (currentTabConfig[fieldIdArray[0]]?.[fieldIdArray[1]]?.[fieldIdArray[2]]?.[fieldDesign.parent_field])!==(fieldDesign.parent_value))
      {
        return isValid;
        }else{
        
        // console.log("validateConfigField",fieldId, fieldValue, fieldDesign, validationType);
        if(fieldDesign && fieldDesign.type==='multi-input' && fieldDesign.total_items){
          if(fieldValue && fieldValue.length < fieldDesign.total_items){
            if(validationType=='form'){
              fieldStates['error_'+fieldId] = 'has-danger';
              fieldStates['error_message_'+fieldId] = `Please Select at least ${fieldDesign.total_items} values`;
            }else{
                setFieldStates({
                  ...fieldStates,
                  ['error_'+fieldId]: 'has-danger',
                  ['error_message_'+fieldId]: `Please Select at least ${fieldDesign.total_items} values`,
                });
            }
            isValid = false;
          }
        }
        if((fieldDesign.type=='string' || fieldDesign.type=='integer' || fieldDesign.type=='url' || fieldDesign.type=='enum' || fieldDesign.type=='file_upload') && fieldDesign.required){
            if(fieldValue=='' || fieldValue===undefined){
              if(validationType=='form'){
                fieldStates['error_'+fieldId] = 'has-danger';
                fieldStates['error_message_'+fieldId] = 'This field is required.';
              }else{
                  setFieldStates({
                    ...fieldStates,
                    ['error_'+fieldId]: 'has-danger',
                    ['error_message_'+fieldId]: 'This field is required.',
                  });
              }
              isValid = false;
            }else{
              if(validationType=='form'){
                fieldStates['error_'+fieldId] = 'has-success';
                fieldStates['error_message_'+fieldId] = '';
              }else{
                setFieldStates({
                  ...fieldStates,
                  ['error_'+fieldId]: 'has-success',
                  ['error_message_'+fieldId]: '',
                });
              }
            }
        }
        if(isValid && fieldDesign.type=='string' && fieldDesign.validations){

            for (const value of fieldDesign.validations) {
                switch (value) {
                  case "email":
                      isValid = validateEmail(fieldId, fieldValue, fieldDesign, validationType);
                      break;
                  case "phone":
                      isValid = validatePhone(fieldId, fieldValue, fieldDesign, validationType);
                      break;
                }
                  
            }
        }
        if(isValid && fieldDesign.type=='url'){        
            if(fieldValue && !formValidations.verifyUrl(fieldValue)){
              if(validationType=='form'){
                fieldStates['error_'+fieldId] = 'has-danger';
                fieldStates['error_message_'+fieldId] = 'Please enter a valid url.';
              }else{
                  setFieldStates({
                    ...fieldStates,
                    ['error_'+fieldId]: 'has-danger',
                    ['error_message_'+fieldId]: 'Please enter a valid url.',
                  });
              }
              isValid = false;
            }else{
              if(validationType=='form'){
                fieldStates['error_'+fieldId] = 'has-success';
                fieldStates['error_message_'+fieldId] = '';
              }else{
                setFieldStates({
                  ...fieldStates,
                  ['error_'+fieldId]: 'has-success',
                  ['error_message_'+fieldId]: '',
                });
              }
            }
        }

        if(isValid && fieldDesign.type=='integer'){
          if(fieldValue!='' && !formValidations.verifyNumber(fieldValue)){
            if(validationType=='form'){
              fieldStates['error_'+fieldId] = 'has-danger';
              fieldStates['error_message_'+fieldId] = 'Please enter a valid number.';
            }else{
                setFieldStates({
                  ...fieldStates,
                  ['error_'+fieldId]: 'has-danger',
                  ['error_message_'+fieldId]: 'Please enter a valid number.',
                });
            }
            isValid = false;
          }else{
            if(validationType=='form'){
              fieldStates['error_'+fieldId] = 'has-success';
              fieldStates['error_message_'+fieldId] = '';
            }else{
              setFieldStates({
                ...fieldStates,
                ['error_'+fieldId]: 'has-success',
                ['error_message_'+fieldId]: '',
              });
            }
          }
        }

      if(isValid && fieldDesign.required && fieldDesign.type=='enum'){
        // console.log(fieldDesign.type_items);
        if(fieldValue!='' && !formValidations.checkValueInArray(fieldValue, fieldDesign.type_items, fieldDesign.type)){
          if(validationType=='form'){
            fieldStates['error_'+fieldId] = 'has-danger';
            fieldStates['error_message_'+fieldId] = 'Please enter a valid value.';
          }else{
              setFieldStates({
                ...fieldStates,
                ['error_'+fieldId]: 'has-danger',
                ['error_message_'+fieldId]: 'Please enter a valid value.',
              });
          }
          isValid = false;
        }else{
          if(validationType=='form'){
            fieldStates['error_'+fieldId] = 'has-success';
            fieldStates['error_message_'+fieldId] = '';
          }else{
            setFieldStates({
              ...fieldStates,
              ['error_'+fieldId]: 'has-success',
              ['error_message_'+fieldId]: '',
            });
          }
        }
      }

      if(isValid && fieldDesign.source=='category_list'){
        // console.log(fieldValue);
        // console.log(fieldDesign.type_items);
        if(fieldValue!='' && !formValidations.checkValueInArray(fieldValue, fieldDesign.type_items, fieldDesign.type)){
          if(validationType=='form'){
            fieldStates['error_'+fieldId] = 'has-danger';
            fieldStates['error_message_'+fieldId] = 'Please enter a valid value.';
          }else{
              setFieldStates({
                ...fieldStates,
                ['error_'+fieldId]: 'has-danger',
                ['error_message_'+fieldId]: 'Please enter a valid value.',
              });
          }
          isValid = false;
        }else{
          if(validationType=='form'){
            fieldStates['error_'+fieldId] = 'has-success';
            fieldStates['error_message_'+fieldId] = '';
          }else{
            setFieldStates({
              ...fieldStates,
              ['error_'+fieldId]: 'has-success',
              ['error_message_'+fieldId]: '',
            });
          }
        }
      }

        return isValid;
    }
  }

    var navItems = [];
    var tabPanes = [];
    
    Object.keys(configSchema).forEach(function(key) {
        Object.keys(configSchema[key]).forEach(function(configkey) {
        //console.log(configSchema[key][configkey]);
        navItems.push(<NavItem>
            <NavLink
              aria-expanded={
                horizontalTab === configkey
              }
              data-toggle="tab"
              href="#"
              role="tab"
              className={
                horizontalTab === configkey
                  ? "active"
                  : ""
              }
              onClick={() => {
                  history.push({
                  search: `?version=${version}&project=${projectDetails.slug}&tab=${configkey}`
                });
                setHorizontalTab(configkey)
                let currentConfig = JSON.parse(savedConfig.data[configkey]);
                setCurrentTabConfig(currentConfig)
                setFieldStates(allFieldStates[configkey])
                setCurrentFormFields(formFields[configkey]);
                }
              }
            >
              {configSchema[key][configkey].label}
            </NavLink>
          </NavItem>);
          var showVerticalMenu = false;
          var submenu = configSchema[key][configkey].items;
          Object.keys(submenu).forEach(function(submenukey) {
            if(submenu[submenukey].items){
              showVerticalMenu = true;
            }
            //console.log(submenu[submenukey]);
          });
//console.log(configSchema[key][configkey]);
          tabPanes.push(<TabPane tabId={configkey} role="tabpanel">
              <Row>
                  <Col md="12">
                  <div className="dflLft">
                  <Form action="" className="form-horizontal" method="POST">
                    
                  {horizontalTab === configkey ? showVerticalMenu?<ConfigVerticalMenu projectId={projectId} fieldStates={fieldStates} setFieldStates={setFieldStates} validateConfigField={validateConfigField} categories={categories} projects={projects}setCurrentTabConfig={setCurrentTabConfig} currentTabConfig={currentTabConfig} data={configSchema[key][configkey]} menutype={configkey} />
                  :<ConfigForm projectId={projectId} fieldStates={fieldStates} setFieldStates={setFieldStates} validateConfigField={validateConfigField} categories={categories} projects={projects} setCurrentTabConfig={setCurrentTabConfig} currentTabConfig={currentTabConfig} data={configSchema[key][configkey]} parent={configkey} subparent='' />:''}
                  
                  {showSaveButton?<Button
                    className="btnBlue btn-round"
                    type="button"
                    onClick={storeConfig}
                  >
                    Save {configSchema[key][configkey].label}
                  </Button>:''}

                  {showLoader?<Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>:''}

                  {alert.show?<Alert variant={alert.type}>
                    {alert.message}
                  </Alert>:''}
                  

                  </Form>
                  </div>
                  </Col>
              </Row>
              

          </TabPane>);
        });
    });

    if(!projectId){
      return null;
    }else{
  
    return (
      <div className="content mt-30">
        {showQRPopup}
          <Row>
          <Col className="col-md-12">
              <Card border="primary" >
    <CardHeader>
                <CardTitle tag="h4"  className="heding_tab_h4">Customizations </CardTitle>
                
    </CardHeader>
    <CardBody>
      <Row>
        <Col>
        <CardTitle>Config : {savedConfig?savedConfig.data.name:""}</CardTitle>
      <CardTitle>Project : {projectName}</CardTitle>
        </Col>
        <Col>
              <div style={{
                  float: "right",
                  "margin-top": "-50px",
                }}>
                <img  src={qrcodeLink} onClick = {()=>{setShowQRPopup(
                    
                        <ReactBSAlert
                            title="Scan & enter into debug mode "
                            onCancel={()=>{setShowQRPopup(false)}}
                            openAnim = {{ name: 'showSweetAlert', duration: 700 }}
                            showConfirm={false}
                            closeOnClickOutside
                        >
                            <img src={qrcodeLink} >
                            
                            </img>
                        </ReactBSAlert>
                    
                    )}}>
                
                </img><br/>
                <Label align="left">Scan & enter into debug mode </Label>
                </div>
        </Col>
      </Row>
      

    </CardBody>
  </Card>
          <Card>
                <CardHeader>
                {/* <CardTitle tag="h4"  className="heding_tab_h4">Customizations of  {savedConfig?savedConfig.data.name:""} of project {projectName}</CardTitle> */}
                
                </CardHeader>
                {savedConfig?
                <CardBody>
                  <div className="nav-tabs-navigation">
                    <div className="nav-tabs-wrapper">
                      <Nav id="tabs" role="tablist" tabs>
                      {navItems}
                        
                      </Nav>
                    </div>
                  </div>
                  <TabContent
                    className="text-center"
                    id="my-tab-content"
                    activeTab={horizontalTab}
                  >
                    {tabPanes}
                  </TabContent>
                </CardBody>:
                "Invalid Version or You can't edit this version"}
              </Card>
            </Col>
          </Row>
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("../../../assets/img/bg/jan-sendereks.jpg")})`,
          }}
        />
      </div>
    );
  }
  
}

export default Customizations;

import React, { useEffect, useState, useContext } from "react";
import {useHistory} from "react-router-dom";
import notificationsService from "../../services/api/notifications-service";
import ProjectContext from '../../ProjectContext'
import './notificaton.css';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Alert,
  Spinner,
  Container,
  Row,
  Col,
} from "reactstrap";


const Provider = (props)=> {

  const projectDetails = useContext(ProjectContext);
  const history = new useHistory();
  if(projectDetails.id==''){
    history.push("/project/allprojects");
  }
  let getProjectSlug = new URLSearchParams(props.location.search).get('project');
  if(!getProjectSlug){
    history.push({
      search: '?project='+projectDetails.slug
    })
  }
  const projectId = projectDetails.id;
  const [formdetails, setFormDetails] = useState({provider:"",version:"",projectid:projectId,snippet_config:"",sender_config:""});
  const [provider,setProvider] = useState('')
  const [providerVersionState, setProviderVersionState] = useState("");
  const [providerversionerror, setProviderVersionError] = useState("");
  const [snippetConfigState, setSnippetConfigState] = useState("");
  const [snippetconfigerror, setSnippetConfigError] = useState("");
  const [senderConfigState, setSenderConfigState] = useState("");
  const [senderconfigerror, setSenderConfigError] = useState("");
  const [showLoader, setShowLoader]   = useState(false);
  const [showSaveButton, setShowSaveButton]   = useState(true);
  const [alert, setAlert]   = useState({show:false,message:'',type:''});
  const firebaseVersions = [
    { value: "7", label: "7" },
    { value: "8", label: "8" },
  ]

  useEffect( () => {
    (async () =>{
      let getProvider = await notificationsService.getProvider(projectId);
      if(getProvider.status && getProvider.data){
        setProvider(getProvider.data.provider)
        setFormDetails({
          ...formdetails,
          provider:getProvider.data.provider,
          version:getProvider.data.version,
          snippet_config:getProvider.data.snippet_config,
          sender_config:getProvider.data.sender_config,
        })
      }else{
        setProvider('firebase')
        setFormDetails({
          ...formdetails,
          provider:'firebase'
        })
      }
    })();
  }, []);
  
  
  const handleChange = (event) => {
    setFormDetails({
      ...formdetails,
      [event.target.id]: ( event.target.value ).trim(),
    });
  }

  const changeVersion = (event) => {
    console.log(event)
    setFormDetails({
      ...formdetails,
      version: event.value,
    });
  }

  const changeProvider = (event) => {
    
    setFormDetails({
      ...formdetails,
      provider: event.target.value,
    });
    setProvider(event.target.value)
  }

  const getVersionValue = () => {
    let versionValue = firebaseVersions[0];
    if(formdetails.version){
      firebaseVersions.forEach(function(ldetails) {
          if(ldetails.value==formdetails.version){
            versionValue = ldetails;
          }
      });
    }
    console.log(versionValue)
    return versionValue;
  }

  const handleSubmit = function(e){  validateForm();e.preventDefault() }
  
  
  const validateForm = async () => {
    let validated = true;
    // if (formdetails.provider!='ownsnippet' && formdetails.version === '') {
    //     setProviderVersionState("has-danger");
    //     setProviderVersionError("This field is required");
    //     validated = false;
    // }else{
    //   setProviderVersionState("has-success");
    //   setProviderVersionError("");
    // }

    if (formdetails.provider=='ownsnippet' && formdetails.snippet_config === '') {
        setSnippetConfigState("has-danger");
        setSnippetConfigError("This field is required");
        validated = false;
    }else{
      setSnippetConfigState("has-success");
      setSnippetConfigError("");
    }

    // if (formdetails.provider!='ownsnippet' && formdetails.sender_config === '') {
    //     setSenderConfigState("has-danger");
    //     setSenderConfigError("This field is required");
    //     validated = false;
    // }else{
    //   setSenderConfigState("has-success");
    //   setSenderConfigError("");
    // }

    if(!validated){ return false; }
     
      let saveProvider = await notificationsService.saveProvider(formdetails);
      setShowLoader(true);
      setShowSaveButton(false);
      if(projectDetails.isContractExpired){
        setShowLoader(false)
        setShowSaveButton(true)
        projectDetails.setContractExpiredAlert(projectDetails);
      }
      else if(saveProvider.status && saveProvider.data){
        setTimeout(()=>{
          setShowLoader(false)
          setAlert({show:true,type:'success',message:'Provider details were saved Successfully'});
        },1000);
        
        setTimeout(()=>{
          setAlert({show:false,type:'',message:''});
          window.location.href = "/notifications/dashboard";
        },5000);

        
      }else{
        setTimeout(()=>{
          setShowLoader(false)
          setShowSaveButton(true)
          setAlert({show:true,type:'danger',message:'Provider details could not be saved, Please try again.'});
        },1000);

        setTimeout(()=>{
          setAlert({show:false,type:'',message:''});
        },3000);
      }
    
  }

    return (
      <div className="content mt-30">
        <Container>
          <Row>
          <Col className="ml-auto mr-auto"  md="12">
              <Card className="card-signup text-center dp-box">
                <CardHeader>
                  <CardTitle tag="h4" className="heding_tab_h4">Select Notification Provider</CardTitle>
                </CardHeader>
                <CardBody>
                  <Form className="form-horizontol form" action="" id="createProject" method="POST" onSubmit={handleSubmit}>

                  <div className="consFom">
                    {/* <Label className="lblNme">Provider</Label> */}
                      <div className="inptFom">
                        <FormGroup>
                        <div className="form-check-radio">
                        <Label className="lblNme" check>
                            {(provider=='' || provider=='firebase')?
                            <Input
                              defaultChecked
                              value="firebase"
                              id="provider"
                              name="provider"
                              type="radio"
                              onChange={changeProvider}
                              onClick={changeProvider}
                            />:
                            <Input
                              value="firebase"
                              id="provider"
                              name="provider"
                              type="radio"
                              onChange={changeProvider}
                              onClick={changeProvider}
                            />
                              }
                            Firebase <span className="form-check-sign" />
                          </Label>
                        </div>
                        </FormGroup>
                      </div>
                    </div>

                    <div className="consFom">
                      <div className="inptFom">
                      <FormGroup>
                        <div className="form-check-radio">
                        <Label className="lblNme" check>
                          {provider=='ownsnippet'?
                            <Input
                              defaultChecked
                              value="ownsnippet"
                              id="provider"
                              name="provider"
                              type="radio"
                              onChange={changeProvider}
                              onClick={changeProvider}
                            />:
                            <Input
                            value="ownsnippet"
                            id="provider"
                            name="provider"
                            type="radio"
                            onChange={changeProvider}
                            onClick={changeProvider}
                          />
                            }
                            Own Snippet <span className="form-check-sign" />
                          </Label>
                        </div>
                        </FormGroup>
                      </div>
                    </div>

                {/* {provider!='ownsnippet'?
                  <Row>
                      <Label sm="2">Version</Label>
                      <Col sm="7">
                        <FormGroup className={providerVersionState}>
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="version"
                            value={getVersionValue()}
                            onChange={changeVersion}
                            options={firebaseVersions}
                          />
                          
                          {providerVersionState === "has-danger" ? (
                            <label className="error">
                              {providerversionerror}
                            </label>
                          ) : null}
                        </FormGroup>
                      </Col>
                  </Row>
                  :''} */}

              {provider=='ownsnippet'?
              <div className="consFom fulconsFom">
                <Label className="lblNme">Snippet Code</Label>
                <div className="inptFom">
                      <FormGroup className={snippetConfigState}>
                        <Input
                          name="snippet_config"
                          id="snippet_config"
                          type="textarea"
                          onChange={handleChange}
                          value={formdetails.snippet_config}
                        />
                        {snippetConfigState === "has-danger" ? (
                          <label className="error">
                            {snippetconfigerror}
                          </label>
                        ) : null}
                      </FormGroup>
                </div>
              </div>
                  :''}

                  {provider=='ownbbbsnippet'?
                  <Row>
                      <Label sm="2">Sender Config</Label>
                      <Col sm="7">
                        <FormGroup className={senderConfigState}>
                          <Input
                            name="sender_config"
                            id="sender_config"
                            type="textarea"
                            onChange={handleChange}
                            value={formdetails.sender_config}
                          />
                          {senderConfigState === "has-danger" ? (
                            <label className="error">
                              {senderconfigerror}
                            </label>
                          ) : null}
                        </FormGroup>
                      </Col>
                  </Row>
                  :''}

              <div className="dflLft">

                  {showSaveButton?<Button
                    className="btn-round btnBlue"
                    type="button"
                    onClick={validateForm}
                  >
                    Save
                  </Button>:''}
                  {alert.show?<Alert color={alert.type}>
                    {alert.message}
                  </Alert>:''}

                  {showLoader?<Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>:''}
              </div>
                  </Form>
                </CardBody>
                {/* <CardFooter>
                  
                </CardFooter> */}
              </Card>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
          }}
        />
      </div>
    );
  
}

export default Provider;

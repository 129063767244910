import React, { useEffect, useState, useContext } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Alert,
  Spinner,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";


const LicenseInfo = (props)=> {
    return (
      <div className="content">
          <h1> License Details</h1>
      </div>
    );
}

export default LicenseInfo;
